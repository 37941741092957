import {
    PORT_WISE_STATUS,
    SIMPLE_TRACK_STATUS,
    FULL_TRACK_STATUS,
    TRACK_STATUS_KEYS,
} from "../types";

const INIT_STATE = {
    portWiseStatus: {},
    simpleTrackStatus: {},
    fullTrackStatus: {},
    trackStatusKeys: {}
};

export default (state = INIT_STATE, action) => {
    // console.log('action redux', action)
    switch (action.type) {
        case PORT_WISE_STATUS: return { ...state, portWiseStatus: action.payload }
        case SIMPLE_TRACK_STATUS: return { ...state, simpleTrackStatus: action.payload }
        case FULL_TRACK_STATUS: return { ...state, fullTrackStatus: action.payload }
        case TRACK_STATUS_KEYS : return { ...state, trackStatusKeys: action.payload }
        default:
            return state;
    }
}