import { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import { ReactComponent as EyeOpenIcon } from "../../../icons/EyeOpen.svg";
import { ReactComponent as EyeCloseIcon } from "../../../icons/closeEye.svg";
import { ReactComponent as AddCustomFieldIcon } from "../../../icons/add.svg";
import { ReactComponent as DeleteIcon } from "../../../icons/cross2.svg";
import { ReactComponent as ShiftIcon } from "../../../icons/Reflect.svg";
import { ReactComponent as TickIcon } from "../../../icons/check.svg";
// import SearchBar from "../../../../util/searchbar/SearchBar";
// import CustomSelect from "../../../../util/customSelect/customSelect";
import { useSelector } from "react-redux";
// import {
//   editMonitoringData,
//   getMonitoringData,
//   selectMonitoringData,
// } from "../../../../../redux/monitoring/monitoringSlice";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Table,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  Close,
  Done,
  Repeat,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  editMonitoringData,
  getMonitoringData,
} from "../../../../../../redux/actions/Monitoring";
// import Loader from "../../../../../layout/loader";

const Settings = ({ isModal, onToggle, themeType, isDark }) => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Monitoring");
  const [tabIndex, setTabIndex] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [isEditing, setIsEditing] = useState({});
  const [isSwitchModal, setIsSwitchModal] = useState({});
  const [loader, showLoader] = useState(false);

  const { monitoringData, loading } = useSelector(
    (content) => content.monitoring
  );

  const { inputClasses } = themeType;

  const currentTabData = useMemo(
    () =>
      monitoringData.filter(
        //_id here is the section header name
        (section) => section._id === tab
      )[0],
    [tab]
  ); //does it also need monitoring data as it deps?

  const [tableData, setTableData] = useState(currentTabData);

  useEffect(() => {
    setTableData(currentTabData);
  }, [tab]);

  useEffect(() => {
    showLoader(false);
  }, [loading]);

  const handleInputChange = (e, id) => {
    let { name, value } = e.target;

    // const eyeClose = getElem(e, ".eyeClose-icon");
    // const eyeOpen = getElem(e, ".eyeOpen-icon");
    // if (eyeClose) {
    //   value = true;
    //   name = "isVisible";
    // }
    // if (eyeOpen) {
    //   value = false;
    //   name = "isVisible";
    // }

    setTableData((td) => ({
      ...td,
      data: td.data.map((row) => {
        if (row._id === id) return { ...row, [name]: value };
        return row;
      }),
    }));
    setIsEditing((ie) => ({ ...ie, [id]: id }));
  };
  const handleRowSave = (e, id) => {
    const rowData = tableData.data.filter((row) => row._id === id)[0];
    const body = {
      name: rowData.name,
      subText: rowData.subText,
      text: rowData.text,
      isVisible: rowData.isVisible,
      borderColor: rowData.borderColor,
      oldHeader: rowData.header,
      newHeader: rowData.newHeader || rowData.header,
    };
    console.log("edit payload body", body);
    showLoader(true);
    dispatch(
      editMonitoringData(body, (res) => {
        if (res && res?.success) {
          dispatch(getMonitoringData());
        }
      })
    );
    const tempIsEditing = { ...isEditing };
    delete tempIsEditing[id];
    setIsEditing(tempIsEditing);
    //remove this row from the tableData
    if (rowData.newHeader)
      setTableData((td) => ({
        ...td,
        data: td.data.filter((row) => row._id !== id),
      }));
  };
  const handleSwitchOpen = (e, id) => {
    setIsSwitchModal((ie) => ({ ...ie, [id]: id }));
    setIsEditing((ie) => ({ ...ie, [id]: id }));
  };
  const handleSwitchClose = (id) => {
    const tempIsSwitch = { ...isSwitchModal };
    delete tempIsSwitch[id];
    setIsSwitchModal(tempIsSwitch);
  };
  const handleSwitchSave = (newHeader, id) => {
    setTableData((td) => ({
      ...td,
      data: td.data.map((row) => {
        if (row._id === id) return { ...row, newHeader };
        return row;
      }),
    }));
  };

  const handleCustomFieldDelete = (e) => {
    const id = e.target.closest(".settingsModal__custom-field").dataset.id;
    // console.log(id);
    setCustomFields((oldCF) => oldCF.filter((cf, idx) => idx != id));
  };
  const getFilterOptionsByDataType = (e) => {};
  // useEffect(() => {
  //   console.log({ tableData });
  // }, [tableData]);
  const closeBtn = (
    <button className="close" onClick={onToggle}>
      <span className="closeBtn"></span>
    </button>
  );

  const handleChange = (event, newVal) => {
    setTabIndex(newVal);
    switch (newVal) {
      case 0:
        {
          setTab("Monitoring");
        }
        break;
      case 1:
        setTab("Analytics");
        break;
      case 2:
        setTab("Alerts");
    }
  };

  return (
    <>
      {/* <Loader showOn={loader} /> */}
      <Dialog open={isModal} fullWidth maxWidth="md" onClose={onToggle}>
        <DialogTitle>Select Filter</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onToggle}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Monitoring" />
            <Tab label="Analytics" />
            <Tab label="Alerts" />
          </Tabs>
          <Box my={2}></Box>
          <Card className="card-absolute bg-darkblue">
            <CardContent className="p-0">
              <Table className="settingsModal__table text-center mb-3">
                <thead>
                  <tr>
                    <th
                      style={{
                        background: isDark
                          ? "#141414"
                          : themeType?.default?.themeGray,
                      }}
                    >
                      S. No
                    </th>
                    <th
                      style={{
                        background: isDark
                          ? "#141414"
                          : themeType?.default?.themeGray,
                      }}
                    >
                      Field Type
                    </th>
                    <th
                      style={{
                        background: isDark
                          ? "#141414"
                          : themeType?.default?.themeGray,
                      }}
                    >
                      Description
                    </th>
                    <th
                      style={{
                        background: isDark
                          ? "#141414"
                          : themeType?.default?.themeGray,
                      }}
                    >
                      Limit
                    </th>
                    <th
                      style={{
                        background: isDark
                          ? "#141414"
                          : themeType?.default?.themeGray,
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.data.map((row, index) => {
                      return (
                        <tr key={row._id}>
                          <td>{index + 1}</td>
                          <td>{row.name}</td>
                          <td>
                            <FormGroup>
                              {/* <Input
                                type="textarea"
                                name="subText"
                                style={{ background: "white" }}
                                placeholder="Description (optional)"
                                value={row.subText}
                                onChange={(e) => handleInputChange(e, row._id)}
                              /> */}
                              <TextField
                                multiline
                                maxRows={2}
                                sx={{
                                  ...inputClasses.textField,
                                  width: "100%",
                                }}
                                value={row.subText}
                                onChange={(e) => {
                                  setTableData((td) => ({
                                    ...td,
                                    data: td.data.map((tr) => {
                                      if (tr._id === row?._id)
                                        return {
                                          ...tr,
                                          subText: e.target.value,
                                        };
                                      return tr;
                                    }),
                                  }));
                                  setIsEditing((ie) => ({
                                    ...ie,
                                    [row?._id]: row?._id,
                                  }));
                                  // handleInputChange(val, row._id)
                                }}
                              />
                            </FormGroup>
                          </td>
                          <td>{row.limit || "NA"}</td>
                          <td>
                            <div className=" d-flex align-items-center justify-content-center">
                              <label
                                htmlFor={`color-input-${row._id}`}
                                className="mb-0 colorPicker"
                                style={{
                                  backgroundColor: row.borderColor,
                                  marginRight: "7px",
                                }}
                              >
                                <Input
                                  type="color"
                                  id={`color-input-${row._id}`}
                                  name="borderColor"
                                  onChange={(e) => {
                                    setTableData((td) => ({
                                      ...td,
                                      data: td.data.map((tr) => {
                                        if (tr._id === row?._id)
                                          return {
                                            ...tr,
                                            borderColor: e.target.value,
                                          };
                                        return tr;
                                      }),
                                    }));
                                    setIsEditing((ie) => ({
                                      ...ie,
                                      [row?._id]: row?._id,
                                    }));
                                  }}
                                  value={row.borderColor}
                                  className="visi-hidden"
                                  hidden
                                />
                              </label>
                              {row.isVisible ? (
                                <IconButton
                                  className="eyeClose-icon"
                                  onClick={(e) => {
                                    setTableData((td) => ({
                                      ...td,
                                      data: td.data.map((tr) => {
                                        if (tr._id === row?._id)
                                          return {
                                            ...tr,
                                            isVisible: false,
                                          };
                                        return tr;
                                      }),
                                    }));
                                    setIsEditing((ie) => ({
                                      ...ie,
                                      [row?._id]: row?._id,
                                    }));
                                    // handleInputChange(e, row._id)
                                  }}
                                  color="warning"
                                >
                                  <Visibility />
                                </IconButton>
                              ) : (
                                // <EyeOpenIcon
                                //   className="eyeOpen-icon icon-fill-primary cursor-pointer"
                                //   onClick={(e) => handleInputChange(e, row._id)}
                                // />
                                // <EyeCloseIcon
                                //   className="eyeClose-icon cursor-pointer"
                                //   onClick={(e) => handleInputChange(e, row._id)}
                                // />
                                <IconButton
                                  className="eyeOpen-icon"
                                  onClick={(e) => {
                                    setTableData((td) => ({
                                      ...td,
                                      data: td.data.map((tr) => {
                                        if (tr._id === row?._id)
                                          return {
                                            ...tr,
                                            isVisible: true,
                                          };
                                        return tr;
                                      }),
                                    }));
                                    setIsEditing((ie) => ({
                                      ...ie,
                                      [row?._id]: row?._id,
                                    }));
                                  }}
                                >
                                  <VisibilityOff />
                                </IconButton>
                              )}
                              <Tooltip
                                placement="bottom"
                                title="Switch Monitoring Type"
                              >
                                <span>
                                  <IconButton
                                    onClick={(e) =>
                                      handleSwitchOpen(e, row._id)
                                    }
                                  >
                                    <Repeat />
                                  </IconButton>
                                </span>
                              </Tooltip>

                              {isSwitchModal[row._id] ? (
                                <ShowSwitchModal
                                  isModal={
                                    isSwitchModal[row._id] ? true : false
                                  }
                                  onToggle={() => handleSwitchClose(row._id)}
                                  handleSave={(data) =>
                                    handleSwitchSave(data, row._id)
                                  }
                                  currentHeader={row.header}
                                />
                              ) : null}
                              {isEditing[row._id] ? (
                                <Tooltip placement="bottom" title="Save">
                                  <span>
                                    <IconButton
                                      onClick={(e) => handleRowSave(e, row._id)}
                                      color="success"
                                    >
                                      <Done />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Settings;

function getElem(e, className) {
  return e.target.closest(className);
}

function ShowSwitchModal({ isModal, onToggle, handleSave, currentHeader }) {
  return (
    <Modal isOpen={isModal} toggle={onToggle} centered={true} size="sm">
      <ModalHeader toggle={onToggle} className="switch-monitoring-modal-header">
        Switch Monitoring Type
      </ModalHeader>
      <ModalBody>
        <ul className="d-flex gap-one justify-center switch-monitoring-list">
          <li
            className={`${
              currentHeader === "Monitoring" ? "current-monitoring-type" : ""
            }`}
            onClick={() => {
              handleSave("Monitoring");
              onToggle();
            }}
          >
            Monitoring
          </li>
          <li
            className={`${
              currentHeader === "Analytics" ? "current-monitoring-type" : ""
            }`}
            onClick={() => {
              handleSave("Analytics");
              onToggle();
            }}
          >
            Analytics
          </li>
          <li
            className={`${
              currentHeader === "Alerts" ? "current-monitoring-type" : ""
            }`}
            onClick={() => {
              handleSave("Alerts");
              onToggle();
            }}
          >
            Alerts
          </li>
        </ul>
      </ModalBody>
    </Modal>
  );
}
