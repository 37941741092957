import React, { useEffect, useState } from 'react'
import samsung from '../../assets/homeimg/img1_samsung.jpg'

import Group from '../../assets/homeimg/img2_group.jpg'
import Bharat from '../../assets/homeimg/bharat_petroleum.jpg'
import Dixon from '../../assets/homeimg/img4_dixon.jpg'
import Sleepwell from '../../assets/homeimg/img5_sleepwell.jpg'
import BlueDart from '../../assets/homeimg/img6_blueDart.jpg'
import Delhivery from '../../assets/homeimg/img7_delhivery.jpg'
import Mahindra from '../../assets/homeimg/mahindraLogistics.png'
import Landmark from '../../assets/homeimg/img8_landmark.jfif'
import Flipkart from '../../assets/homeimg/img9_flipkart.jpg'
import Pepfules from '../../assets/homeimg/img10_pepfules.png'
import Adiyabirla from '../../assets/homeimg/img11_adiyabirla.jpg'
import LG from '../../assets/homeimg/img12_lg.jpg'
import Cmacga from '../../assets/homeimg/img13_cmacga.jfif'
import ADB from '../../assets/homeimg/img14_ADB.png'
import platform1 from '../../assets/landing/img/our_plateform_1.png'
import logo from '../../assets/landing/img/logo.png'
import optimised from '../../assets/landing/img/optimised.png'
import patient_tracking from '../../assets/landing/img/patient_tracking.png'
import high_security_solution from '../../assets/landing/img/high_security_solution.png'
import asset_tracking from '../../assets/landing/img/asset_tracking.png'
import bluetooth_1 from '../../assets/landing/img/bluetooth_1.png'
import hardware_bg from '../../assets/landing/img/hardware_bg.png'
import rfid from '../../assets/landing/img/rfid.png'
import rfid_tag from '../../assets/landing/img/rfid-tag.png'
import bt from '../../assets/landing/img/bt.png'
import watch_1_1 from '../../assets/homeimg/watch_1_1.png'
import assett from '../../assets/landing/img/asset.png'
import fixed from '../../assets/landing/img/fixed.png'
import elock from '../../assets/landing/img/elock.png'
import about_us_new from '../../assets/homeimg/about_us_new.png'
import border_down from '../../assets/landing/img/border-down.png'
import fb from '../../assets/landing/img/fb.png'
import twitter from '../../assets/landing/img/twitter.png'
import linkdin from '../../assets/landing/img/linkdin.png'
import history from "../../services/history";
import { useDispatch, useSelector } from "react-redux";
// import FrameBuster from './FrameBuster'


const HomeScreen = () => {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useState(['yourCookieName']);
    const [registerFlag, setRegisterFlag] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    const setRegisterFlag2 = () => {
        setRegisterFlag(false);
    };

    const showHeader = (id) => {
        for (let index = 1; index <= 3; index++) {
            const header = document.getElementById('header-' + index);
            const arrow = document.getElementById('arrow-' + index);
            const subHeader = document.getElementById('sub-header-' + index);

            if (header.classList.contains('feature-head-active')) {
                header.classList.remove('feature-head-active');
                arrow.classList.remove('feature-arrow-active');
                subHeader.classList.remove('d-show');
                subHeader.classList.add('d-none');
            }
        }

        const header = document.getElementById('header-' + id);
        const arrow = document.getElementById('arrow-' + id);
        const subHeader = document.getElementById('sub-header-' + id);

        if (header) header.classList.add('feature-head-active');
        if (arrow) arrow.classList.add('feature-arrow-active');
        if (subHeader) subHeader.classList.add('d-show');
    };

    const showHeaderHard = (id) => {
        for (let index = 7; index <= 12; index++) {
            const header = document.getElementById('header-' + index);
            const arrow = document.getElementById('arrow-' + index);
            const subHeader = document.getElementById('sub-header-' + index);

            if (header && header.classList.contains('feature-head-active')) {
                header.classList.remove('feature-head-active');
                if (arrow) arrow.classList.remove('feature-arrow-active');
                if (subHeader) {
                    subHeader.classList.remove('d-show');
                    subHeader.classList.add('d-none');
                }
            }
        }

        const header = document.getElementById('header-' + id);
        const arrow = document.getElementById('arrow-' + id);
        const subHeader = document.getElementById('sub-header-' + id);

        if (header) header.classList.add('feature-head-active');
        if (arrow) arrow.classList.add('feature-arrow-active');
        if (subHeader) subHeader.classList.add('d-show');
    };

    function setColor(id) {
        for (let index = 1; index <= 7; index++) {
            const divElement = document.getElementById('div-' + index);
            const textElement = document.getElementById('text-' + index);

            if (divElement) divElement.style.background = 'white';
            if (textElement) textElement.classList.add('d-none');
        }

        const selectedDivElement = document.getElementById('div-' + id);
        const selectedTextElement = document.getElementById('text-' + id);

        if (selectedDivElement) selectedDivElement.style.background = '#ff7200';
        if (selectedTextElement) selectedTextElement.classList.remove('d-none');
    }



    useEffect(() => {
        // Check cookie
        if (cookies.yourCookieName !== "active") {
            document.getElementById('cookieAcceptBar').style.display = 'block';
        }
    }, [cookies]);

    const handleAccept = () => {
        // Set cookie and hide the bar
        setCookie('yourCookieName', 'active', { path: '/', expires: new Date(Date.now() + 86400000) }); // expires in one day
        document.getElementById('cookieAcceptBar').style.display = 'none';
    };

    const handleAccept1 = () => {
        // Set cookie and hide the bar
        setCookie('yourCookieName', 'active', { path: '/', expires: new Date(Date.now() + 86400000) }); // expires in one day
        document.getElementById('cookieAcceptBar1').style.display = 'none';
    };
    const moveTo = () => {
        history.push("/login");
    }
      useEffect(() => {
        const isInIframe = () => {
          try {
            return window.self !== window.top;
          } catch (e) {
            return true;
          }
        };
    
        if (isInIframe()) {
          window.location.href = 'https://www.google.com/' ; 
        }
      }, []);
    
   





    return (
        <>
        {/* <FrameBuster/> */}
            <div class="laptop-display" id="lp">
                <section>
                    <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{ backgroundColor: 'black', zIndex: '2010' }}>
                        <a className="navbar-brand p-2" href="#">
                            <img onClick={setRegisterFlag2} style={{ marginLeft: '12px' }} className="img-fluid logo" id="logo" src={logo} alt="logo" />
                        </a>

                        <button className="navbar-toggler p-2" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div name="home" style={{ marginRight: '14px' }} className="collapse navbar-collapse p-2" id="collapsibleNavbar">
                            <ul className="navbar-nav" style={{ marginLeft: 'auto' }}>
                                <li className="nav-item"><a onClick={setRegisterFlag2} className="nav-link js-scroll-trigger" href="#">Home</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#features">Features</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#hardware">Hardware</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">About</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Contact</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger aHover" onClick={() => moveTo()}>Login</a></li>
                            </ul>
                        </div>
                    </nav>

                    <video className="wow animated fadeInUp" playsInline data-wow-delay="0.1s" autoPlay muted loop id="myVideo1">
                        <source id='mp4' src={require("../../assets/landing/img/straight_video.webm")} type='video/webm' />
                        <source id='mp4' src={require("../../assets/landing/img/straight_video.mp4")} type='video/mp4' />
                        {/* Your browser does not support the HTML5 Video element. */}
                    </video>
                    <div className="wlcm wow animated p-5 fadeInUp" data-wow-delay="0.1s">
                        <div style={{ marginTop: '13vh', letterSpacing: '0px' }}>
                            <div>
                                <span className="wlcm1">More </span>
                                <span className="wlcm2">VISIBILITY,</span>
                            </div>
                            {/* <br> */}
                            <div style={{ marginLeft: '22vh' }}>
                                <span className="wlcm1">Higher </span>
                                <span className="wlcm2">SECURITY.</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="mt-5 container-fluid section-two row feature" id="features">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInLeft feature-col-left" data-wow-delay="0.3s">
                        <div className="feature-left-inner">
                            <div className="d-flex">
                                <h3 className="feature-head feature-head-active" id="header-1" onClick={() => showHeader(1)}>Our Platform</h3>
                                <i className="fa fa-arrow-right feature-arrow feature-arrow-active" id="arrow-1" aria-hidden="true"></i>
                            </div>
                            <div className="d-flex">
                                <h3 className="feature-head" id="header-2" onClick={() => showHeader(2)}>Optimise Logistics Performance</h3>
                                <i className="fa fa-arrow-right feature-arrow" id="arrow-2" aria-hidden="true"></i>
                            </div>
                            <div className="d-flex">
                                <h3 className="feature-head" id="header-3" onClick={() => showHeader(3)}>Field staff and patient tracking</h3>
                                <i className="fa fa-arrow-right feature-arrow" id="arrow-3" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInRight feature-col-right" data-wow-delay="0.35s">
                        <div className="row mt-4 d-show" id="sub-header-1">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    Lynktrac offers logistics tracking and security solutions for your cargo as well as field staff and
                                    patient monitoring solutions. We specialise in providing you real time and periodic analytics for
                                    better planning your delivery routes or field staff visits through our route optimisation algorithms.
                                    Our platform can be configured to work your existing devices as well. Lynktrac also “lynks'' with our
                                    Yard Management System and Terminal Coordination Platform,
                                    <a className="text-danger" href="https://www.lynkgrid.com" target="_blank" rel="noopener noreferrer"> Lynkgrid </a>
                                    giving our clients end-to-end visibility.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={platform1} alt="Our Platform 1" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-2">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    We provide mobile and web-based real-time cargo tracking system solutions using GPS and RFID &
                                    Bluetooth. Our platform allows you to selectively share data with your partners as well as link
                                    journeys and devices with necessary supply chain and transportation documentation. Our platform
                                    integrates with all ERPs like SAP, Oracle’s TMS etc. Customised analytics can be seen for particular
                                    drivers, vehicles and devices.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={optimised} alt="Optimized" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-3">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    Our solution allows for tracking of patients and individuals inside hospitals, offices or in quarantined
                                    areas through the use of specially built bluetooth and GPS enabled bands and indoor beacon
                                    infrastructures. Solutions are also built for monitoring the movement of field staff and health workers
                                    to optimise their daily visits and reduce costs and provide our users with meaningful analytics for
                                    better decision making.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={patient_tracking} alt="Patient Tracking" />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="container-fluid section-two row feature xs-none" id="features-1">

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInRight feature-col-right" data-wow-delay="0.25s">
                        <div className="row mt-4 d-show" id="sub-header-7">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    Lynkit pioneers the use of new age 4.0 technologies such as Real Time Kinematics, LoRaWAN and Blockchain
                                    technology in our suite of products. We makes the use these technologies to give our users centimeter level
                                    accuracies and uninterrupted packet transmissions for the location detection of assets moving or stationary,
                                    both indoors and outdoors. Blockchain compliments our solutions by providing enterprise clients with a
                                    single
                                    source of truth for all their operations.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={high_security_solution} alt="High Security Solution" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-8">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    Use GPS and Bluetooth e-locks to enjoy pilferage free transport - create your own geo fences and route
                                    corridors and configure alerts for device tampering and route deviation and more. We come with experience of
                                    over 500 million kms of high security cargo tracking. Smart e-lock security devices can be used in supply
                                    chain and asset management to protect pilferage of material with 360 degree visibility and never having the
                                    fear of losing your keys, or someone duplicating your key, no matter who and when the lock was opened, it
                                    will leave a trace in the system.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={asset_tracking} alt="Asset Tracking" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-9">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail mt-5">
                                    We are using bluetooth as a standalone technology as well as a complimenting tech to our GPS devices for
                                    mobile app integrations. Lynkit uses bluetooth for contact tracing as well as distance and location
                                    measurement through triangulation algorithms. Bluetooth is also being used for warehouse solution
                                    implementation in indoor areas where satellite and mobile data penetration is low.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img mt-5" src={bluetooth_1} alt="Bluetooth 1" />
                            </div>
                        </div>
                    </div>

                    {/* <div className="wow animated fadeInRight xs-none" data-wow-delay="0.3s">
            <img src="assets/landing/img/border-up.png" className="feature-border" alt="Border Up"/>
        </div> */}

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInLeft feature-col-left" data-wow-delay="0.2s">
                        <div className="feature-left-inner">
                            <div className="d-flex">
                                <i className="fa fa-arrow-left feature-arrow feature-arrow-active" id="arrow-7" aria-hidden="true"></i>
                                <h3 className="feature-head feature-head-active" id="header-7" onClick={() => showHeaderHard(7)}>Asset Tracking </h3>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-arrow-left feature-arrow" id="arrow-8" aria-hidden="true"></i>
                                <h3 className="feature-head" id="header-8" onClick={() => showHeaderHard(8)}>High Security Solutions</h3>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-arrow-left feature-arrow" id="arrow-9" aria-hidden="true"></i>
                                <h3 className="feature-head" id="header-9" onClick={() => showHeaderHard(9)}>Bluetooth Solutions </h3>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="container-fluid section-two row feature lg-none" id="features-1">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInLeft feature-col-left" data-wow-delay="0.3s">
                        <div className="feature-left-inner">
                            <div className="d-flex">
                                <h3 className="feature-head feature-head-active" id="header-10" onClick={() => showHeaderHard(10)}>Asset Tracking </h3>
                                <i className="fa fa-arrow-right feature-arrow feature-arrow-active" id="arrow-10" aria-hidden="true"></i>
                            </div>
                            <div className="d-flex">
                                <h3 className="feature-head" id="header-11" onClick={() => showHeaderHard(11)}>High Security Solutions</h3>
                                <i className="fa fa-arrow-right feature-arrow" id="arrow-11" aria-hidden="true"></i>
                            </div>
                            <div className="d-flex">
                                <h3 className="feature-head" id="header-12" onClick={() => showHeaderHard(12)}>Bluetooth Solutions </h3>
                                <i className="fa fa-arrow-right feature-arrow" id="arrow-12" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="wow animated fadeInRight xs-none" data-wow-delay="0.3s">
                        <img src="assets/landing/img/border-up.png" className="feature-border" alt="Feature Border" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInRight feature-col-right" data-wow-delay="0.35s">
                        <div className="row mt-4 d-show" id="sub-header-10">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail">
                                    Lynkit pioneers the use of new age 4.0 technologies such as Real Time Kinematics, LoRaWAN and Blockchain
                                    technology in our suite of products. We makes the use these technologies to give our users centimeter level
                                    accuracies and uninterrupted packet transmissions for the location detection of assets moving or stationary,
                                    both indoors and outdoors. Blockchain compliments our solutions by providing enterprise clients with a
                                    single
                                    source of truth for all their operations.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img" src="assets/landing/img/high_security_solution.png" alt="High Security Solution" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-11">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail">
                                    Use GPS and Bluetooth e-locks to enjoy pilferage free transport - create your own geo fences and route
                                    corridors and configure alerts for device tampering and route deviation and more. We come with experience of
                                    over 500 million kms of high security cargo tracking. Smart e-lock security devices can be used in supply
                                    chain and asset management to protect pilferage of material with 360 degree visibility and never having the
                                    fear of losing your keys, or someone duplicating your key, no matter who and when the lock was opened, it
                                    will leave a trace in the system.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img" src="assets/landing/img/asset_tracking.png" alt="Asset Tracking" />
                            </div>
                        </div>
                        <div className="row mt-4 d-none" id="sub-header-12">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mr-3 mt-4">
                                <p className="mb-2 feature-detail">
                                    We are using bluetooth as a standalone technology as well as a complimenting tech to our GPS devices for
                                    mobile app integrations. Lynkit uses bluetooth for contact tracing as well as distance and location
                                    measurement through triangulation algorithms. Bluetooth is also being used for warehouse solution
                                    implementation in indoor areas where satellite and mobile data penetration is low.
                                </p>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img" src="assets/landing/img/bluetooth_1.png" alt="Bluetooth Solutions" />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="xs-none" id="hardware">
                    <img src={hardware_bg} alt="hardware" id="hardware-image" />
                    <div class="circle big-circle">
                        <div class="big-circle-text">
                            Our Hardware
                        </div>
                    </div>

                    <div className="rfid-circle" onClick={() => setColor(1)}>
                        <span className="img-text rfid-text">RFID Seals</span>
                        <div className="circle small-cirlce" id="div-1">
                            <img src={rfid} alt="rfid" id="rfid-img" />
                        </div>
                        <div className="rfid-line"></div>
                    </div>

                    <div className="rfid-tag-circle" onClick={() => setColor(2)}>
                        <span className="img-text rfid-tag-text">RFID Tags </span>
                        <div className="circle small-cirlce" id="div-2">
                            <img src={rfid_tag} alt="rfid-tag" id="rfid-tag-img" />
                        </div>
                        <div className="rfid-tag-line"></div>
                    </div>

                    {/* Bluetooth e-Lock Circle */}
                    <div className="bt-circle" onClick={() => setColor(3)}>
                        <span className="img-text bt-text">Bluetooth e-Lock</span>
                        <div className="circle small-cirlce" id="div-3">
                            <img src={bt} alt="bt" id="bt-img" />
                        </div>
                        <div className="bt-line"></div>
                    </div>

                    {/* Lynkband Circle */}
                    <div className="wrist-circle" onClick={() => setColor(4)}>
                        <span className="img-text wrist-text">Lynkband</span>
                        <div className="circle small-cirlce" id="div-4">
                            <img src={watch_1_1} alt="wrist" id="wrist-img" />
                        </div>
                        <div className="wrist-line"></div>
                    </div>

                    <div className="asset-circle" onClick={() => setColor(5)}>
                        <span className="img-text asset-text">GPS Asset Tracker</span>
                        <div className="circle small-cirlce" id="div-5">
                            <img src={assett} alt="asset" id="asset-img" />
                        </div>
                        <div className="asset-line"></div>
                    </div>

                    {/* GPS Fixed Tracker Circle */}
                    <div className="fixed-circle" onClick={() => setColor(6)}>
                        <span className="img-text fixed-text">GPS Fixed Tracker</span>
                        <div className="circle small-cirlce" id="div-6">
                            <img src={fixed} alt="fixed" id="fixed-img" />
                        </div>
                        <div className="fixed-line"></div>
                    </div>

                    {/* GPS e-Lock Circle */}
                    <div className="elock-circle" onClick={() => setColor(7)}>
                        <span className="img-text elock-text">GPS e-Lock</span>
                        <div className="circle small-cirlce" id="div-7">
                            <img src={elock} alt="elock" id="elock-img" />
                        </div>
                        <div className="elock-line"></div>
                    </div>

                </section>

                <section className="lg-none" id="hardware">
                    <div className="col-12">
                        <h3
                            className="feature-head feature-head-active"
                            style={{ fontSize: '48px', textAlign: 'center', padding: '12px' }}
                        >
                            Our Hardware
                        </h3>
                        <div className="your-class">
                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/rfid.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">RFID Seals</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                This seal tag can be applied to secure and protect
                                                                pilferage as well as track in and out movement, date
                                                                of expiry, weight, supplier, location, etc. Tags are
                                                                tamper evident and indicate whether they have been
                                                                opened or cut. This tag has a wide read range of up to
                                                                8 mt.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/rfid-tag.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">RFID Tags </a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                RFID tags allow real-time tracking and efficient
                                                                management of assets, inventory, equipment, vehicles
                                                                and other objects allowing you to save time and money
                                                                by giving each object a unique identity allowing for
                                                                stock taking and location identification in seconds.
                                                                Our RFIDs come in the form of tags, labels, seals and
                                                                are available as one time or reusable products.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/bt.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">Bluetooth e-Lock</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                All devices are the IP67 with a built-in battery with
                                                                200 days standby time and USB charging port. These
                                                                locks enable keyless access through our mobile app and
                                                                leave a digital trace of user ID and time stamp for
                                                                every locking and unlocking operations. You can enable
                                                                lock and unlock permissions based on time of day,
                                                                geolocation and OTPs.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/img/watch_1_1.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">Lynkband</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                Our personal trackers help to track the location of
                                                                users in open or close environments. For areas where
                                                                GPS signal is weak our devices rely on bluetooth
                                                                beaconing systems to detect and monitor location
                                                                activity. Other sensors such as temperature, heart
                                                                rate and tamper detectors can also be built in.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/asset.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">GPS Asset Tracker</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                These devices have their power source and are used by
                                                                our clients to track containers, trains, or even
                                                                trucks in which the wired device cannot be installed.
                                                                These asset trackers are also used to track movement
                                                                of individuals to track their movement to optimize
                                                                field routes or even to automate attendance
                                                                validation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/fixed.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">GPS Fixed Tracker</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                The devices can be either by OBD port trackers or for
                                                                the older vehicles a regular wired tracker. Lynkit
                                                                also supplies AIS140 trackers which are compliant with
                                                                the latest regulations by Ministry Of Road
                                                                Transportation and Highways. The advanced telematics
                                                                instruments are capable of giving readings such as
                                                                fuel consumption, engine temperature and enable remote
                                                                immobilisation of the vehicle as well.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src="assets/landing/img/fixed.png" alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark">
                                                                <a href="javascript:void(0)">GPS Fixed Tracker</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                The devices can be either by OBD port trackers or for
                                                                the older vehicles a regular wired tracker. Lynkit
                                                                also supplies AIS140 trackers which are compliant with
                                                                the latest regulations by Ministry Of Road
                                                                Transportation and Highways. The advanced telematics
                                                                instruments are capable of giving readings such as
                                                                fuel consumption, engine temperature and enable remote
                                                                immobilisation of the vehicle as well.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
















                        </div>
                    </div>
                </section>



                <section className="container-fluid section-two row feature" id="about" style={{ height: '84vh' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInLeft feature-col-left" data-wow-delay="0.3s">
                            <h3 className="feature-head feature-head-active" style={{ letterSpacing: '0px!important', fontSize: '48px', textAlign: 'center', padding: '12px' }}>About Us</h3>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                <img className="feature-img" src={about_us_new} style={{ width: '60%', marginTop: '0px' }} alt="About Us" />
                            </div>
                        </div>
                        <div className="wow animated fadeInRight xs-none" data-wow-delay="0.4s">
                            <img src={border_down} className="feature-border1" alt="Border Down" />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 wow animated fadeInRight feature-col-right" data-wow-delay="0.35s">
                            <div className="row mt-4 d-show" id="sub-header-1">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ml-3 mt-4">
                                    <p className="mb-2 feature-detail">
                                        We at Lynkit believe in orchestrating efficiencies for our clients in every aspect of their supply chain with technology as the cornerstone for their businesses. Our mission is to help our clients excel at every possible touch point of the supply chain and Our motto is "orchestrating efficiency", which is what we set out to do in each of our projects by "lynking" every stakeholder, process and action into a seamless and integrated process flow.

                                        Lynkit's unique application suite covers the entire spectrum of the supply chain uses in the industry. All our products and services have been developed after extensive research and scalability as the focus.

                                        With sheer devotion to technology, the founders have handpicked the technology stack for our products, which complements the domains we operate in. Our two main divisons are working on applications based on Blockchain and IoT domains and we have fully stacked B2B supply chain management and optmisation solutions spanning across blockchain based transportation management systems, IoT based trace and track solutions, process and document automation and e-governance solutions, which have been deployed in a diverse set of industries such as logistics & transportation, manufacturing and e-commerce.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="container-fluid section-two customer1 justify-content-center d-flex flex-wrap">
                    <div className="col-lg-12 col-md-12 heading-div">
                        <h3 className="feature-head feature-head-active part" style={{ padding: '0rem 0', fontSize: '40px', textAlign: 'center' }}>
                            Lynkit's Client
                        </h3>
                    </div>
                    <div className="row1 col-lg-12 col-md-12 pr-0 m-4">
                        <div className="row justify-content-center pl-0 pr-0 mx-0">
                            <div className="div-size1"><img className="customer_images2" id="" src={samsung} alt="Samsung" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={Group} alt="Group" /></div>
                            <div className="div-size1"><img className="customer_images2" id="bharat" src={Bharat} alt="Bharat Petroleum" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={Dixon} alt="Dixon" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={Sleepwell} alt="Sleepwell" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={BlueDart} alt="BlueDart" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={Delhivery} alt="Delhivery" /></div>
                            <div className="div-size1"><img className="customer_images2" id="" src={Mahindra} alt="Mahindra" /></div>
                        </div>
                    </div>
                    <div className="row2 col-lg-12 col-md-12 pr-0 pl-0 m-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="row justify-content-center pl-0 pr-0 mx-0">
                            <div className="div-size1"><img className="customer_images2" src={Landmark} alt="Landmark" /></div>
                            <div className="div-size1"><img className="customer_images2" src={Flipkart} alt="Flipkart" /></div>
                            <div className="div-size1"><img className="customer_images2" id="pepfules" src={Pepfules} alt="Pepfules" /></div>
                            <div className="div-size1"><img className="customer_images2" src={Adiyabirla} alt="Adiyabirla" /></div>
                            <div className="div-size1"><img className="customer_images2" src={LG} alt="LG" /></div>
                            <div className="div-size1"><img className="customer_images2" src={Cmacga} alt="Cmacga" /></div>
                            <div className="div-size1"><img className="customer_images2" src={ADB} alt="ADB" /></div>
                            {/* <div className="div-size1"><img className="customer_images2" src="../../../assets/img/img14_ADB.png" alt="ADB" /></div> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-12  heading-div1">
        <div className="row justify-content-center">
          <h1 id="more_3500">+3,500 more</h1>
        </div>
      </div> */}
                </section>


                <section className="container-fluid section-two row feature" id="partners" style={{ height: '72vh' }}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3 className="feature-head feature-head-active part" style={{ padding: '1rem 0', fontSize: '40px', textAlign: 'center' }}>
                            Partners With
                        </h3>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <div className="d-flex xs-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b1"> </div>
                            <div className="img-partner b3"> </div>
                            <div className="img-partner b4"> </div>
                            <div className="img-partner b5"> </div>
                            <div className="img-partner b6"> </div>
                        </div>
                        <div className="d-flex xs-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b7"> </div>
                            <div className="img-partner b8"> </div>
                            <div className="img-partner b9"> </div>
                            <div className="img-partner b10"> </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="d-flex lg-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b1"> </div>
                            <div className="img-partner b3"> </div>
                        </div>
                        <div className="d-flex lg-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b4"> </div>
                            <div className="img-partner b5"> </div>
                            <div className="img-partner b6"> </div>
                        </div>
                        <div className="d-flex lg-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b7"> </div>
                            <div className="img-partner b8"> </div>
                            <div className="img-partner b9"> </div>
                        </div>
                        <div className="d-flex lg-none" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b10"> </div>
                        </div>
                    </div>
                </section>

                {/* <section className="container-fluid section-two row feature" id="touch">
                    <div style={{ display: 'block' }}>
                        <div>
                            <h1 className="text-white stay">Stay Ahead. Start Today</h1>
                        </div>
                        <div className="mt-1">
                            <button className="cursor-pointer trial-text" data-target="#registerModal" data-toggle="modal"
                                >Get 7-Days free trial</button>
                        </div>
                    </div>
                </section> */}

                <section className="container-fluid section-two row feature" id="contact" name="contact" style={{ background: 'black', padding: 0, height: '72vh' }}>
                    <div className="d-check">
                        <div className="d1">
                            <h3 className="text-white contact_us">Our Products</h3>
                            <div className="address">
                                <span className="text-white">
                                    <a className="text-white a-l" href="#home">Lynktrac</a>
                                </span>
                            </div>
                            <div className="call">
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.lynkit.io" target="_blank" rel="noopener noreferrer">Lynkit.io</a>
                                </span>
                            </div>
                            <div className="call">
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.lynkgrid.com" target="_blank" rel="noopener noreferrer">Lynkgrid</a>
                                </span>
                            </div>
                            <div className="call">
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.pikmybox.com" target="_blank" rel="noopener noreferrer">PikMyBox</a>
                                </span>
                            </div>
                        </div>

                        <div className="d2">
                            <h3 className="text-white contact_us">Contact Us</h3>
                            <div className="address">
                                <i className="fa fa-map-marker text-white"></i>
                                <span className="text-white" style={{ marginLeft: '10px' }}>246, Block A, Okhla Industrial Area Phase I, New Delhi-110020</span>
                            </div>
                            <div className="call">
                                <i className="fa fa-phone text-white"></i>
                                <span style={{ marginLeft: '10px', cursor: 'pointer' }} className="text-white">+91 11-4117-9940</span>
                            </div>
                            <div className="mail">
                                <i className="fa fa-envelope text-white"></i>
                                <span className="text-white" style={{ marginLeft: '10px', cursor: 'pointer' }}>support[at]lynktrac[dot]io</span>
                            </div>
                            <div className="pt-3 google">
                                <a href="https://play.google.com/store/apps/details?id=com.lynkit.lynktrac&hl=en" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '144px' }} src="https://lynkit.io/assets/img/landing-page/new/google-play-store.png" alt="Google Play Store" />
                                </a>
                            </div>
                            <div className="pt-3 apple">
                                <a href="https://apps.apple.com/us/app/lynktrac/id1477021431#?platform=iphone" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '144px' }} src="https://lynkit.io/assets/img/landing-page/new/apple-app-store.png" alt="Apple App Store" />
                                </a>
                            </div>
                        </div>
                        <div className="d3">
                            <div id="mapContainer">
                                <iframe
                                    style={{ borderRadius: '20px' }}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.473877968483!2d77.27330981554869!3d28.525472882460218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1656fbb7b65%3A0x7f0e4b2c21935a5c!2sLynkit%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1641473837461!5m2!1sen!2sin"
                                    width="600"
                                    height="420"
                                    allowFullScreen=""
                                    loading="lazy">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div className="d-flex justify-content-between align-items-center xs-none">
                        <span>Follow Us
                            <a href="https://www.facebook.com/LynkitIndia/" target="_blank" rel="noopener noreferrer">
                                <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={fb} alt="Facebook" />
                            </a>
                            <a href="https://twitter.com/lynkitindia?lang=en" target="_blank" rel="noopener noreferrer">
                                <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={twitter} alt="Twitter" />
                            </a>
                            <a href="https://www.linkedin.com/company/lynkit-india/" target="_blank" rel="noopener noreferrer">
                                <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={linkdin} alt="LinkedIn" />
                            </a>
                        </span>
                        <span>
                            <p className="mt-0 mb-0" style={{ cursor: 'pointer' }}><strong>Privacy Policy</strong></p>
                        </span>
                        <span>Copyright © 2020 Lynkit. All Rights Reserved</span>
                    </div>

                    <div className="lg-none">
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <div className="mb-1">
                                Follow Us
                                <a href="https://www.facebook.com/LynkitIndia/" target="_blank" rel="noopener noreferrer">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={fb} alt="Facebook" />
                                </a>
                                <a href="https://twitter.com/lynkitindia?lang=en" target="_blank" rel="noopener noreferrer">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={twitter} alt="Twitter" />
                                </a>
                                <a href="https://www.linkedin.com/company/lynkit-india/" target="_blank" rel="noopener noreferrer">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={linkdin} alt="LinkedIn" />
                                </a>
                            </div>
                            <div className="mb-1">Copyright © 2020 Lynkit. All Rights Reserved</div>
                            <span>
                                <p className="mt-0 mb-0" style={{ cursor: 'pointer' }}><strong>Privacy Policy</strong></p>
                            </span>
                        </div>
                    </div>
                </footer>

                <div id="cookieAcceptBar" className="cookieAcceptBar">
                    <p className="text-white">
                        We use cookies on our website.
                        Cookies are used to improve the functionality and use of our internet site, as well as for analytic and
                        advertising purposes. By continuing to use this site without changing your settings you consent to our use of cookies.
                        <button id="cookieAcceptBarConfirm" className="btn btn-outline-white" onClick={handleAccept}>Accept</button>
                    </p>
                </div>



            </div>

            <div class="mobile-display" id="mb">
                <section>
                    <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{ backgroundColor: 'black', zIndex: '2010' }}>
                        <a className="navbar-brand p-2" href="#">
                            <img className="img-fluid logo" src={logo} alt="logo" />
                        </a>

                        <button className="navbar-toggler p-2" type="button" data-toggle="collapse" onClick={handleToggle} data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className={`collapse navbar-collapse p-2 ${isCollapsed ? 'show' : ''}`}

                            id="collapsibleNavbar">
                            <ul className="ml-auto navbar-nav" style={{ marginLeft: 'auto' }}>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#">Home</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#Features">Features</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#Hardware">Hardware</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#About">About</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#Contact">Contact</a></li>
                                <li className="nav-item"><a className="nav-link js-scroll-trigger" onClick={() => moveTo()}>Login</a></li>
                            </ul>
                        </div>
                    </nav>

                    <div class="position-relative">
                        <video className="mb-5 wow animated fadeInUp" playsInline autoPlay muted loop id="myVideo1">
                            <source src={require("../../assets/landing/img/straight_video.webm")} type='video/webm' />
                            <source src={require("../../assets/landing/img/straight_video.mp4")} type='video/mp4' />
                            {/* Your user agent does not support the HTML5 Video element. */}
                        </video>
                    </div>
                    <div className="wlcmtop p-3 wow animated fadeInUp" data-wow-delay="0.1s">
                        <div style={{ letterSpacing: '0px' }}>
                            <div>
                                <span className="wlcm1">More </span>
                                <span className="wlcm2">VISIBILITY,</span>
                            </div>
                            {/* <br> */}
                            <div className="m">
                                <span className="wlcm1">Higher </span>
                                <span className="wlcm2">SECURITY.</span>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-two row feature" id="features1">
                    <div className="wow animated fadeInLeft feature-col-left" data-wow-delay="0.3s">
                        <div className="feature-left-inner">

                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" style={{ letterSpacing: '0px !important' }}>Our Platform </h3>
                                    <i className="fa fa-arrow-right arrow color" aria-hidden="true"></i>
                                </div>
                                <div className="text-center ">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        Lynktrac offers logistics tracking and security solutions for your cargo as well as field staff and
                                        patient monitoring solutions. We specialize in providing you real-time and periodic analytics for
                                        better planning your delivery routes or field staff visits through our route optimization algorithms.
                                        Our platform can be configured to work with your existing devices as well. Lynktrac also "lynks" with our
                                        Yard Management System and Terminal Coordination Platform,
                                        <a className="text-danger" href="https://www.lynkgrid.com" target="_blank" rel="noreferrer"> Lynkgrid </a>
                                        giving our clients end-to-end visibility.
                                    </p>
                                    <img className="feature-img" src={platform1} alt="Our Platform" />
                                </div>
                            </div>

                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" style={{ letterSpacing: '0px !important' }}>Optimize Logistics Performance</h3>
                                    <i className="fa fa-arrow-right arrow color" id="arrow-2" aria-hidden="true"></i>
                                </div>
                                <div className="text-center">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        We provide mobile and web-based real-time cargo tracking system solutions using GPS and RFID &
                                        Bluetooth. Our platform allows you to selectively share data with your partners as well as link
                                        journeys and devices with necessary supply chain and transportation documentation. Our platform
                                        integrates with all ERPs like SAP, Oracle’s TMS etc. Customized analytics can be seen for particular
                                        drivers, vehicles, and devices.
                                    </p>
                                    <img className="feature-img" src={optimised} alt="Optimized Logistics Performance" />
                                </div>
                            </div>

                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" id="header-3" style={{ letterSpacing: '0px !important' }}>Field staff and patient tracking
                                    </h3>
                                    <i className="fa fa-arrow-right arrow color" id="arrow-3" aria-hidden="true"></i>
                                </div>
                                <div className="text-center">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        Our solution allows for tracking of patients and individuals inside hospitals, offices, or in quarantined
                                        areas through the use of specially built Bluetooth and GPS enabled bands and indoor beacon
                                        infrastructures. Solutions are also built for monitoring the movement of field staff and health workers
                                        to optimize their daily visits and reduce costs and provide our users with meaningful analytics for
                                        better decision making.
                                    </p>
                                    <img className="feature-img" src={patient_tracking} alt="Field staff and patient tracking" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-two row feature" id="Features">
                    <div className="wow animated fadeInRight feature-col-right" data-wow-delay="0.25s">
                        <div className="feature-left-inner">

                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" id="header-1" style={{ letterSpacing: '0px !important' }}>Asset Tracking </h3>
                                    <i className="fa fa-arrow-right color arrow" id="arrow-1" aria-hidden="true"></i>
                                </div>
                                <div className="text-center">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        Lynkit pioneers the use of new age 4.0 technologies such as Real Time Kinematics, LoRaWAN and Blockchain
                                        technology in our suite of products. We makes the use these technologies to give our users centimeter
                                        level
                                        accuracies and uninterrupted packet transmissions for the location detection of assets moving or
                                        stationary,
                                        both indoors and outdoors. Blockchain compliments our solutions by providing enterprise clients with a
                                        single
                                        source of truth for all their operations.
                                    </p>
                                    <img className="feature-img" src={high_security_solution} alt="Asset Tracking" />
                                </div>
                            </div>


                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" id="header-2" style={{ letterSpacing: '0px !important' }}>High Security Solutions</h3>
                                    <i className="fa fa-arrow-right color arrow" id="arrow-2" aria-hidden="true"></i>
                                </div>
                                <div className="text-center">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        Use GPS and Bluetooth e-locks to enjoy pilferage free transport - create your own geo fences and route
                                        corridors and configure alerts for device tampering and route deviation and more. We come with experience
                                        of
                                        over 500 million kms of high security cargo tracking. Smart e-lock security devices can be used in supply
                                        chain and asset management to protect pilferage of material with 360 degree visibility and never having
                                        the
                                        fear of losing your keys, or someone duplicating your key, no matter who and when the lock was opened, it
                                        will leave a trace in the system.
                                    </p>
                                    <img className="feature-img" src={asset_tracking} alt="High Security Solutions" />
                                </div>
                            </div>

                            <div className="p-5">
                                <div className="d-flex">
                                    <h3 className="color" id="header-3" style={{ letterSpacing: '0px !important' }}>Bluetooth Solutions</h3>
                                    <i className="fa fa-arrow-right color arrow" id="arrow-3" aria-hidden="true"></i>
                                </div>
                                <div className="text-center">
                                    <p className="mb-2" style={{ textAlign: 'justify' }}>
                                        We are using bluetooth as a standalone technology as well as a complimenting tech to our GPS devices for
                                        mobile app integrations. Lynkit uses bluetooth for contact tracing as well as distance and location
                                        measurement through triangulation algorithms. Bluetooth is also being used for warehouse solution
                                        implementation in indoor areas where satellite and mobile data penetration is low.
                                    </p>
                                    <img className="feature-img" src={bluetooth_1} alt="Bluetooth Solutions" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="Hardware">
                    <div className="col-12">
                        <h3 className="feature-head feature-head-active font-weight-bold"
                            style={{ fontSize: '48px', textAlign: 'center', padding: '12px', letterSpacing: '0px !important' }}>
                            Our Hardware
                        </h3>
                        <div className="your-class">
                            <div>
                                <div className=" p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={rfid} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">RFID Seals</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                This seal tag can be applied to secure and protect pilferage as well as track in and out
                                                                movement, date of
                                                                expiry, weight, supplier, location, etc. Tags are tamper evident and indicate whether
                                                                they have
                                                                been opened or
                                                                cut. This tag has a wide read range of up to 8 mt.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={rfid_tag} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">RFID Tags </a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                RFID tags allow real-time tracking and efficient management of assets, inventory, equipment,
                                                                vehicles and other
                                                                objects allowing you to save time and money by giving each object a unique identity allowing
                                                                for
                                                                stock taking
                                                                and location identification in seconds. Our RFIDs come in the form of tags, labels, seals and
                                                                are available as
                                                                one time or reusable products.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={bt} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">Bluetooth e-Lock</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                All devices are the IP67 with a built-in battery with 200 days standby time and USB charging
                                                                port. These locks
                                                                enable keyless access through our mobile app and leave a digital trace of user ID and time
                                                                stamp for every
                                                                locking
                                                                and unlocking operations. You can enable lock and unlock permissions based on time of day,
                                                                geolocation and OTPs.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={watch_1_1} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">Lynkband</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                Our personal trackers help to track the location of users in open or close environments. For
                                                                areas where GPS
                                                                signal is weak our devices rely on bluetooth beaconing systems to detect and monitor location
                                                                activity. Other
                                                                sensors such as temperature, heart rate and tamper detectors can also be built in.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={assett} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">GPS Asset Tracker</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                These devices have their power source and are used by our clients to track containers, trains,
                                                                or even trucks in
                                                                which the wired device cannot be installed. These asset trackers are also used to track
                                                                movement
                                                                of individuals
                                                                to track their movement to optimize field routes or even to automate attendance validation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={fixed} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">GPS Fixed Tracker</a>
                                                            </h3>
                                                            <p className="text-dark">
                                                                The devices can be either by OBD port trackers or for the older vehicles a regular wired
                                                                tracker. Lynkit also
                                                                supplies AIS140 trackers which are compliant with the latest regulations by Ministry Of Road
                                                                Transportation and
                                                                Highways. The advanced telematics instruments are capable of giving readings such as fuel
                                                                consumption, engine
                                                                temperature and enable remote immobilization of the vehicle as well.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="p-3 mb-0">
                                    <div className="card-body">
                                        <div className="cat-item text-center">
                                            <div className="product-singleinfo">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div className="product-item text-center">
                                                            <img src={elock} alt="img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="product-item2-desc mt-2">
                                                            <h3 className="font-weight-semibold text-dark mt-4">
                                                                <a style={{ textDecoration: 'none', color: '#3f345f', transition: 'all .3s ease 0s', fontWeight: '600' }} href="javascript:void(0)">GPS e-Lock</a>
                                                            </h3>


                                                            <p className="text-dark">
                                                                This is the most advanced method for prevention of pilferage. GPS e-locks give you real-time
                                                                tracking as well as
                                                                real-time tamper alerts. The device records the exact time, location and user who has locked
                                                                and
                                                                unlocked the
                                                                device giving you a full trace
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </section>







































                <section className="container-fluid section-two row feature" id="About" style={{ height: '100%' }}>
                    <div className="col-12 wow animated fadeInLeft feature-col-left" data-wow-delay="0.3s">
                        <h3 className="feature-head feature-head-active font-weight-bold mb-2"
                            style={{ fontSize: '48px', textAlign: 'center', padding: '12px', letterSpacing: '0px !important' }}>
                            About Us
                        </h3>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <img className="feature-img" src={about_us_new} style={{ width: '60%', marginTop: '0px' }} />
                        </div>
                    </div>

                    <div className="col-12 wow animated fadeInRight feature-col-right" data-wow-delay="0.35s">
                        <div className="mt-4 d-show" id="sub-header-1">
                            <div className="col-12 mt-4">
                                <p className="mb-2 feature-detail1 p-3">
                                    We at Lynkit believe in orchestrating efficiencies for our clients in every aspect of their supply chain
                                    with technology as the cornerstone for their businesses. Our mission is to help our clients excel at every
                                    possible touch point of the supply chain and Our motto is "orchestrating efficiency", which is what we set
                                    out to do in each of our projects by "lynking" every stakeholder, process and action into a seamless and
                                    integrated process flow.

                                    Lynkit's unique application suite covers the entire spectrum of the supply chain uses in the industry. All
                                    our products and services have been developed after extensive research and scalability as the focus.

                                    With sheer devotion to technology, the founders have handpicked the technology stack for our products, which
                                    complements the domains we operate in. Our two main divisons are working on applications based on Blockchain
                                    and IoT domains and we have fully stacked B2B supply chain management and optmisation solutions spanning
                                    across blockchain based transportation management systems, IoT based trace and track solutions, process and
                                    document automation and e-governance solutions, which have been deployed in a diverse set of industries such
                                    as logistics & transportation, manufacturing and e-commerce.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-two row feature mt-5" style={{ height: '100%' }}>
                    <div className="col-12">
                        <h3 className="feature-head feature-head-active font-weight-bold mb-5"
                            style={{ fontSize: '48px', textAlign: 'center', padding: '12px', letterSpacing: '0px !important' }}>
                            Lynkit's client
                        </h3>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                            <div className="img-partner1 b11"> </div>
                            <div className="img-partner1 b12"> </div>
                            <div className="img-partner1 b13"> </div>
                            <div className="img-partner1 b14"> </div>
                        </div>

                        <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                            <div className="img-partner1 b15"> </div>
                            <div className="img-partner1 b16"> </div>
                            <div className="img-partner1 b17"> </div>
                            <div className="img-partner1 b18"> </div>
                        </div>

                        <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                            <div className="img-partner1 b19"> </div>
                            <div className="img-partner1 b20"> </div>
                            <div className="img-partner1 b21"> </div>
                            <div className="img-partner1 b22"> </div>
                        </div>

                        <div className="d-flex" style={{ justifyContent: 'space-around' }}>
                            <div className="img-partner1 b23"> </div>
                            <div className="img-partner1 b24"> </div>
                            <div className="img-partner1 b25"> </div>
                        </div>
                    </div>
                </section>

                <section className="section-two row feature mt-5" id="partners1" style={{ height: '100%' }}>
                    <div className="col-12">
                        <h3 className="feature-head feature-head-active font-weight-bold mb-2"
                            style={{ fontSize: '48px', textAlign: 'center', padding: '12px', letterSpacing: '0px !important' }}>
                            Partners With
                        </h3>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b1"> </div>
                            <div className="img-partner b3"> </div>
                            <div className="img-partner b4"> </div>
                        </div>

                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b8"> </div>
                            <div className="img-partner b9"> </div>
                            <div className="img-partner b10"> </div>
                        </div>

                        <div className="d-flex" style={{ justifyContent: 'center' }}>
                            <div className="img-partner b7"> </div>
                            <div className="img-partner b5"> </div>
                            <div className="img-partner b6"> </div>
                        </div>
                    </div>
                </section>

                {/* <section className="section-two row" id="touch" style={{ height: '100%' }}>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <h3 className="text-white mt-2 mb-2">Stay Ahead. Start Today</h3>
                        <div className="mt-1 mb-3">
                            <button className="cursor-pointer trial-text" data-target="#registerModal1" data-toggle="modal">Get 7-Days free trial</button>
                        </div>
                    </div>
                </section> */}


                <section className="container-fluid section-two feature" id="contact1" style={{ background: 'black' }}>
                    <div className="d-check">
                        <div className="p-5">
                            <h3 className="text-white  font-weight-bold" style={{ letterSpacing: '0px !important' }}>Our Products</h3>
                            <div>
                                <span className="text-white">
                                    <a className="text-white a-l" href="#home">Lynktrac</a>
                                </span>
                            </div>
                            <div>
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.lynkit.io" target="_blank">Lynkit.io</a>
                                </span>
                            </div>
                            <div>
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.lynkgrid.com" target="_blank">Lynkgrid</a>
                                </span>
                            </div>
                            <div>
                                <span className="text-white">
                                    <a className="text-white a-l" href="https://www.pikmybox.com" target="_blank">PikMyBox</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid section-two feature" id="contact1" style={{ background: 'black' }}>
                    <div className="d-check">
                        <div className="p-5">
                            <h3 className="text-white font-weight-bold">Contact Us</h3>
                            <div className="">
                                <i className="fa fa-map-marker text-white"></i>
                                <span className="text-white" style={{ marginLeft: '10px' }}>246, Block A, Okhla Industrial Area Phase I, New Delhi-110020</span>
                            </div>
                            <div className="">
                                <i className="fa fa-phone text-white"></i>
                                <span style={{ marginLeft: '10px', cursor: 'pointer' }} className="text-white">+91 11-4117-9940</span>
                            </div>
                            <div className="">
                                <i className="fa fa-envelope text-white"></i>
                                <span className="text-white" style={{ marginLeft: '10px', cursor: 'pointer' }} href="mailto:support@lynktrac.io">support@lynktrac.io</span>
                            </div>
                            <div className="pt-3 google">
                                <a href="https://play.google.com/store/apps/details?id=com.lynkit.lynktrac&hl=en" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '144px' }} src="https://lynkit.io/assets/img/landing-page/new/google-play-store.png" alt="Google Play Store" />
                                </a>
                            </div>
                            <div className="pt-3 apple">
                                <a href="https://apps.apple.com/us/app/lynktrac/id1477021431#?platform=iphone" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '144px' }} src="https://lynkit.io/assets/img/landing-page/new/apple-app-store.png" alt="Apple App Store" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid section-two feature" id="Contact" style={{ background: 'black' }}>
                    <div className="d-flex justify-content-center pl-4 pr-4 pb-5">
                        <iframe
                            style={{ borderRadius: '20px' }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.473877968483!2d77.27330981554869!3d28.525472882460218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1656fbb7b65%3A0x7f0e4b2c21935a5c!2sLynkit%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1641473837461!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            allowFullScreen=""
                            loading="lazy">
                        </iframe>
                    </div>
                </section>

                <footer>
                    <div>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <div className="mb-1">
                                Follow Us
                                <a href="https://www.facebook.com/LynkitIndia/" target="_blank">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={fb} alt="Facebook" />
                                </a>
                                <a href="https://twitter.com/lynkitindia?lang=en" target="_blank">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={twitter} alt="Twitter" />
                                </a>
                                <a href="https://www.linkedin.com/company/lynkit-india/" target="_blank">
                                    <img style={{ marginLeft: '10px', cursor: 'pointer' }} src={linkdin} alt="LinkedIn" />
                                </a>
                            </div>
                            <div className="mb-1">Copyright © 2020 Lynkit. All Rights Reserved</div>
                            <span>
                                <p className="mt-0 mb-0" style={{ cursor: 'pointer' }} routerLink="/privacy-policy"><strong>Privacy Policy</strong></p>
                            </span>
                        </div>
                    </div>
                </footer>

                <div id="cookieAcceptBar1" className="cookieAcceptBar">
                    <p className="text-white">
                        We use cookies on our website.
                        Cookies are used to improve the functionality and use of our internet site, as well as for analytic and advertising purposes. By continuing to use this site without changing your settings you consent to our use of cookies.
                        <button id="cookieAcceptBarConfirm1" className="btn btn-outline-white" onClick={handleAccept1}>Accept</button>
                    </p>
                </div>
            </div>



        </>
    )
}

export default HomeScreen;