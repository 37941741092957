import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Button, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import {
  getAdminFromAccessKey,
  getAdminsForMonitoringOnly,
  getGeofenceMonitoring,
  getMonitoringData,
  setAdminValue,
} from "../../../../../../redux/actions/Monitoring";
import { DateTimePicker } from "@mui/x-date-pickers";
import CustomSelect from "../../../../../../components/FormUI/CustomSelect";
import { selectStylesOverride } from "../../../../../../utils/util";
import { dateTimePickerSlotProps } from "../../../../../../utils/util";
// import { getGeofenceMonitoring,getAdminsForMonitoringOnly,getMonitoringData,setAdminValue,
//   getAdminFromAccessKey } from "../../../../../redux/monitoring/monitoringSlice";
// import { token } from "../../../../../util/constant";

var tempArr = [];
const alertOptions = [
  { label: "Tampered", value: "tampered" },
  { label: "Unauthorised Unsealed", value: "unlocked" },
];

const FilterForm = ({ onSubmit, onChange, themeType }) => {
  const dispatch = useDispatch();
  const { zoneList, userDetail, admins } = useSelector(
    (content) => content.monitoring
  );
  const [form, setForm] = useState({
    alertType: "",
    zoneName: "",
    fromDate: moment().format("YYYY-MM-DDTHH:MM"),
    toDate: moment().format("YYYY-MM-DDTHH:MM"),
  });
  const [zoneListArr, setZoneListArray] = useState([]);
  const [isZoneSelected, setIsZoneSelected] = useState(false);
  const [userTimezone, setUserTimezone] = useState('');
  const [admin, setAdmin] = useState(null);
  const { buttonClasses, inputClasses } = themeType;

  useEffect(() => {
    const getUserTimezone = () => {
      setUserTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    };

    getUserTimezone();
  }, []);

  // useEffect(() =>
  // {
  //   console.log(userDetail, admins);
  // }, [userDetail, admins]);

  useEffect(() => {
    if (zoneList.length > 1) {
      setZoneListArray(zoneList);
    }
  }, [zoneList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(form, zoneList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value == "zone") {
      setIsZoneSelected(true);
      dispatch(
        getGeofenceMonitoring({
          type: "",
          searchType: "",
          searchValue: "",
          projection: { name: 1 },
        })
      );
      // console.log(value);
    } else {
      var type = form.alertType;
      setForm({
        alertType: type,
        zoneName: "",
        fromDate: moment().format("YYYY-MM-DDTHH:MM"),
        toDate: moment().format("YYYY-MM-DDTHH:MM"),
      });
      setIsZoneSelected(false);
      onChange(isZoneSelected);
    }
    setForm((f) => ({ ...f, [name]: value }));
  };

  const handleAdmin = (e) => {
    const { name, value } = e.target;
    setAdmin(value);

    // console.log(e.target);

    var user = admins.filter((i) => i._id == value);

    user && user[0] && dispatch(setAdminValue(user[0]));
    dispatch(getMonitoringData({ userId: value }));
  };

  return (
    <>
      <Col md>
        <Form
          className="d-flex align-items-center"
          style={{gap: "8px"}}
          onSubmit={handleSubmit}
        >
          {userDetail && userDetail.userType == "4" && (
            // <FormGroup className={`pRel -filter-select mb-0 `}>
            //   <Input
            //     id="monitoring-dashboard__type-filter"
            //     name="alertType"
            //     type="select"
            //     bsSize="sm"
            //     className="border "
            //     value={admin}
            //     onChange={handleAdmin}
            //   >
            //     <option value="">Select Admin</option>
            //     {admins.map((ele) => {
            //       return (
            //         <option key={ele.accessKey} value={ele._id}>
            //           {ele.userName} ({ele.email})
            //         </option>
            //       );
            //     })}
            //   </Input>
            // </FormGroup>
            <CustomSelect
              label="Select Admin"
              selectStylesOverride={selectStylesOverride}
              options={admins ? admins : []}
              labelKey="userName"
              sx={{ width: 250 }}
            />
          )}
          {/* <FormGroup className={`pRel -filter-select mb-0 `}>
            <Input
              id="monitoring-dashboard__type-filter"
              name="alertType"
              type="select"
              bsSize="sm"
              className="border "
              value={form.alertType}
              onChange={handleChange}
            >
              <option value="">Select Alert Type</option>
              <option value="tampered">Tampered</option>
              <option value="unlocked">Unauthorised Unsealed</option>
              
            </Input>
          </FormGroup> */}
          <CustomSelect
            label="Select Alert Type"
            selectStylesOverride={selectStylesOverride}
            options={alertOptions}
            sx={{ width: 250 }}
            value={form.alertType}
            onChange={(e, newVal) => handleChange({target:{name: "alertType", value: newVal?.value}})}
          />
          {form.alertType && form.alertType == "zone" && (
            <FormGroup className={`pRel -filter-select mb-0 `}>
              {/* filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} */}
              <Input
                type="select"
                id="zoneFilter"
                name="zoneName"
                className="border clr-primary "
                bsSize="sm"
                value={form.zoneName}
                placeholder="Select Zone"
                onChange={handleChange}
              >
                <option value={""}>Please Select Zone</option>
                {zoneListArr.map((ele) => {
                  return (
                    <option key={ele._id + "i"} value={ele.name}>
                      {ele.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          )}
          {form.alertType &&
            (form.alertType == "tampered" || form.alertType == "unlocked") && (
              <DateTimePicker
                value={new Date(form.fromDate)}
                timeZone={userTimezone}
                label="From"
                onChange={(date) => console.log("from date: ", date)}
                className="customDatePicker"
                sx={{
                  mx: 2,
                  ...selectStylesOverride,
                }}
                slotProps={dateTimePickerSlotProps(inputClasses)}
              />
              // <FormGroup className="d-flex gap-half aItemsCenter mb-0 ">
              //   <Label for="exampleDatetime" className="mb-0 clr-darkgrey">
              //     From
              //   </Label>
              //   <Input
              //     id="exampleDatetime"
              //     name="fromDate"
              //     placeholder="datetime placeholder"
              //     type="datetime-local"
              //     bsSize="sm"
              //     className=" border clr-primary "
              //     value={form.fromDate}
              //     onChange={handleChange}
              //   />
              // </FormGroup>
            )}
          {form.alertType &&
            (form.alertType == "tampered" || form.alertType == "unlocked") && (
              <DateTimePicker
                value={new Date(form.toDate)}
                label="To"
                timeZone={userTimezone}
                // onChange={handleChange}
                className="customDatePicker"
                sx={{
                  mr: 2,
                  ...selectStylesOverride,
                }}
                slotProps={dateTimePickerSlotProps(inputClasses)}
              />
            )}
          {form.alertType && form.alertType == "zone" && (
            <Button
              variant="contained"
              sx={{ ...buttonClasses?.lynkitOrangeFill, maxHeight: "35px" }}
              disabled={!form.zoneName}
              onClick={handleSubmit}
            >
              Apply
            </Button>
          )}
          {form.alertType && form.alertType != "zone" && (
            <Button
              variant="contained"
              sx={{ ...buttonClasses?.lynkitOrangeFill, maxHeight: "35px" }}
              disabled={!(form.fromDate || form.toDate)}
              onClick={handleSubmit}
            >
              Apply
            </Button>
          )}
        </Form>
      </Col>
    </>
  );
};
export default FilterForm;

function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
