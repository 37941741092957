import {
  USERS_LIST,
  FETCH_START,
  FETCH_SUCCESS,
  USER_DETAIL,
  ADD_USER,
  FETCH_ERROR,
  USERS_COUNT,
  SHOW_MESSAGE,
  ORGANIZATIONS_LIST,
} from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getAllUsers = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/listUsers", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: USERS_LIST, payload: { rows: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: USERS_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: USERS_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};

export const addUser = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/addUser", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data?.userId, data?.data?.orgId, data?.data?.orgName, data?.data?.name);
          }
        }
      })
      .catch(function (error) {
        showToast(error.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const updateUser = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/updateUser", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        showToast(error.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const userDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/userDetails", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: USER_DETAIL, payload: { rows: [] } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          localStorage.setItem("orgId", data?.data?.orgId);
          const isSuperAdmin = data.data?.adminSpecificPermissions
            ? true
            : false;
          localStorage.setItem("isSuperAdmin", isSuperAdmin);
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DETAIL, payload: { rows: data.data } });

          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};

export const getBulkUsersDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/bulkUsersDetails", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          // if (cb) {
          //   cb(data?.data);
          // }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getAllOrganizations = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/listOrganizations", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({
            type: ORGANIZATIONS_LIST,
            payload: { rows: [], count: 0 },
          });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: ORGANIZATIONS_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: ORGANIZATIONS_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};

export const deleteUser = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/deleteUser", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("User deleted Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const deleteUsers = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/deleteUsers", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("User deleted Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const updateUsers = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/updateUser", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("User updated Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const disableEnableUsers = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/disableEnableUsers", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("Users updated Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const deleteOrganization = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/deleteOrganization", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("Organization deleted Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const updateOrganization = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/updateOrganization", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("Organization updated Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const disableEnableOrganizations = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/disableEnableOrganizations", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });

          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
          showToast("Organization updated Successfully", false);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getUsersStatusWiseCount = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/usersStatusWiseCount", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: USERS_COUNT, payload: {} });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USERS_COUNT, payload: data.data });

          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};

export const flushUsersList = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_USERS_LIST" });
  };
};

export const flushUserDetails = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_USERS_DETAILS" });
  };
};