// import { Card, CardBody } from "reactstrap";
import DataBox from "./Sub-Components/DataBox";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getMonitoringData } from "../../../../../redux/actions/Monitoring";
import { Card, CardContent } from "@mui/material";
import loaderGif from "../../../../../static/images/drawer/loader.gif";
// import { getMonitoringData } from "../../../../redux/monitoring/monitoringSlice";
// import AlerWithTripIcon from "../../../../assets/images/icons/alert_with_trip.png";
// import AlerWithoutTripIcon from "../../../../assets/images/icons/alert_without_trip.png";

const DashboardSection = ({ onBoxClick, handleSideClose, themeType }) => {
  const { monitoringData, deviceCount, alertCount, tripCount, monitoringLoading } = useSelector((state) => state.monitoring);
  const mergedCounts = {
  ...(deviceCount ? deviceCount : {}),
  ...(tripCount ? tripCount : {}),
  ...(alertCount ? alertCount : {}),
  };

  return (
    <>
      {monitoringLoading ? (
        <div className="pt-3" style={{height: "500px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img data-testId="loaderImg" src={loaderGif} />
        </div>
      ) : (
        <div className="pt-3">
          {monitoringData &&
            monitoringData?.map((section, index) => {
              const clr = section.data && section.data[0]?.borderColor;
              let dataType = section.data && section.data[0]?.dataType;
              return (
                <Card
                  key={index}
                  className={`${
                    index === monitoringData.length - 1 ? "" : "mb-4"
                  }`}
                >
                  <CardContent
                    sx={{ padding: 0 }}
                    className="p-0"
                    aria-label="monitoring section"
                  >
                    <div
                      className="monitorDashboard__section-header"
                      aria-label="monitoring section heading"
                      style={{background: themeType?.default.themeGray}}
                    >
                      <span>{section._id}</span>
                      {/* {section._id === "Alerts" ? (
                        <AlertSwitch
                          dataType={dataType}
                          clr={clr}
                          handleSideClose={handleSideClose}
                          themeType={themeType}
                        />
                      ) : null} */}
                    </div>
                    {/* f-wrap d-flex gap-one */}
                    <div className="monitorDashboard__section-body  p-3">
                      <DataBox sectionName={section?._id} data={section.data} allCounts={mergedCounts} onBoxClick={onBoxClick} />
                    </div>
                  </CardContent>
                </Card>
              );
            })}
        </div>
      )}
    </>
  );
};
export default DashboardSection;

function AlertSwitch({ clr, dataType, handleSideClose, themeType }) {
  const dispatch = useDispatch();
  const isWithTrip = dataType === "trip";
  // console.log({ dataType });
  const handleAlertSwitch = () => {
    handleSideClose();
    dispatch(
      getMonitoringData({
        dataType: dataType === "trip" ? "device" : "trip",
      })
    );
  };
  return (
    <div className="alert_switch">
      <button
        style={{
          fontSize: "14px",
          padding: "2px 5px",
          border: `1px solid #f57c00`,
          borderTopLeftRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          backgroundColor: isWithTrip ? themeType?.default?.themeOrange : themeType?.default?.themeGray,
          color: isWithTrip ? themeType?.default?.themeGray : themeType?.default?.themeOrange
        }}
        onClick={handleAlertSwitch}
        disabled={dataType === "trip"}
      >
        {/* <img src={AlerWithTripIcon} alt="" /> */}
        Alerts With Trip
      </button>
      <button
        style={{
          fontSize: "14px",
          padding: "2px 5px",
          border: `1px solid #f57c00`,
          borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
          backgroundColor: !isWithTrip ? themeType?.default?.themeOrange : themeType?.default?.themeGray,
          color: !isWithTrip ? themeType?.default?.themeGray : themeType?.default?.themeOrange
        }}
        onClick={handleAlertSwitch}
        disabled={dataType === "device"}
      >
        {/* <img src={AlerWithoutTripIcon} alt="" /> */}
        Alerts Without Trip
      </button>
    </div>
  );
}
