import { useState, useMemo, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import history from "../../../services/history";
import Panel, { PanelAction, FilterForm } from "./Components/Panel";
import Map from "./Components/Map/index";
import DashboardSection from "./Components/DashboardSection";
// import Loader from "../../layout/loader";
import EmptyPage from "../EmptyPage";

import "./styles.scss";
import { DetailPanel } from "./Components/DetailPanel";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import {
  createMonitoringBoxes,
  flushAllCounts,
  flushAllMonitoringData,
  getAssetsObservation,
  getGeofenceMonitoring,
  getMapviewAlerts,
  getMonitoringAlertCount,
  getMonitoringData,
  getMonitoringDeviceCount,
  getMonitoringTripCount,
  resetUserId,
  setUserId,
} from "../../../redux/actions/Monitoring";
import {
  changePassword,
  userLogout,
  flushCurrentUserDetail,
  logoutFromSocket,
  disconnectFromSocket,
  flushUserId,
} from "../../../redux/actions";
import { selectAlertData } from "../../../redux/reducers/Monitoring";
import { selectStylesOverride } from "../../../utils/util";
import ChangePasswordModal from "../../Components/ChangePasswordModal";

import {
  flushUsersList,
  getAllOrganizations,
  getAllUsers,
} from "../../../redux/actions";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { showToast } from "../../../services/functions";

const MonitoringDashboard = () => {
  const dashboardSectionRef = useRef();
  const dispatch = useDispatch();
  // const { alerts } = useSelector(selectAlertData);
  var { currentUserDetail } = useSelector((state) => state.auth);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  var {
    mapviewAlerts,
    loading,
    monitoringData,
    monitoringAnalyticsDetails,
    monitoringAlertDetails,
    devicesUnderObservation,
    activeUserId,
  } = useSelector((state) => state.monitoring);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses } = themeType;

  const themeBorderColor = isDarkThemeEnabledSelector ? "#282828" : "#D9D9D9";

  const [isMap, setIsMap] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sideToOpen, setSideToOpen] = useState("");
  const [alertType, setAlertType] = useState("");
  const [latLng, setLatLng] = useState(null);
  const [changeFilter, setChangeFilter] = useState(false);
  const [filterObj, setFilterObj] = useState(null);
  const [alertTypeSwitch, setAlertTypeSwitch] = useState("trip");
  const [newLat, setNewLat] = useState(null)
  const [newLng, setNewLng] = useState(null)
  const [searchFields, setSearchFields] = useState({
    users: null,
    org: null,
  });
  const boxDataRef = useRef({});
  const sidePanelRef = useRef(null);

  // console.log({monitoringData})
  const mapToggle = () => {
    setIsMap(!isMap);
  };

  useEffect(()=>{
    if(latLng){
    setNewLat(latLng?.lat)
    setNewLng(latLng?.lng)
    }
  },[latLng])

  function fetchData() {
    setIsLoading(true);
    dispatch(
      getMonitoringData({}, (res) => {
        if (res && res?.length > 0) {
          dispatch(getMonitoringTripCount());
          dispatch(getMonitoringAlertCount());
          dispatch(getMonitoringDeviceCount());
        }
        setIsLoading(false);
      })
    );
  }
  const handleLogout = () => {
    dispatch(
      userLogout((res) => {
        if (res) {
          localStorage.removeItem("token");
          localStorage.removeItem("orgId");
          localStorage.removeItem("isSuperAdmin");
          dispatch(logoutFromSocket(currentUserDetail?._id));
          dispatch(disconnectFromSocket());
          dispatch(flushUserId());
          dispatch(flushCurrentUserDetail());
          setTimeout(() => {
            history.push("/login");
          }, 100);
        }
      })
    );
  };

  const onFilterFormSubmit = (alertForm, zoneList) => {
    setAlertType(alertForm.alertType);
    if (alertForm.zoneName && alertForm.zoneName != "") {
      dispatch(
        getGeofenceMonitoring({
          type: "",
          searchType: "name",
          searchValue: alertForm.zoneName,
        })
      );
    } else {
      dispatch(getMapviewAlerts(alertForm));
    }
  };

  const onChangeFilter = (isZoneSelected) => {
    // console.log("d-Index isZoneSelected", isZoneSelected);
    setChangeFilter(isZoneSelected);
  };

  const handleSideClose = () => {
    setSideToOpen("");
  };

  const handleCreateBoxesForUser = () => {
    // if superadmin, show selected user's data else show current user's data.
    if (filterObj?.email && filterObj?.orgId && filterObj?.userId) {
      dispatch(
        createMonitoringBoxes(
          {
            email: filterObj?.email || "",
            orgId: filterObj?.orgId || "",
            userId: filterObj?.userId || "",
          },
          (res) => {
            if (res?.success) {
              dispatch(
                getMonitoringData({ userId: filterObj?.userId }, (res) => {
                  if (res && res?.length > 0) {
                    dispatch(
                      getMonitoringTripCount({ userId: filterObj?.userId })
                    );
                    dispatch(
                      getMonitoringAlertCount({ userId: filterObj?.userId })
                    );
                    dispatch(
                      getMonitoringDeviceCount({ userId: filterObj?.userId })
                    );
                  }
                })
              );
            }
          }
        )
      );
    } else {
      showToast("Please select organization and user first.", true);
    }
  };

  const handleBoxClick = (box) => {
    setSideToOpen(box?._id);
    boxDataRef.current = box;
  };

  const handleChangePassword = (payload) => {
    setChangePasswordLoading(true);
    dispatch(
      changePassword(
        payload,
        (res) => {
          if (res) {
            setOpenChangePassword(false);
            setChangePasswordLoading(false);
            if (currentUserDetail?.isFirstLogin) {
              handleLogout();
            }
          }
        },
        () => {
          setChangePasswordLoading(false);
        }
      )
    );
  };

  const handleDevicesUnderObservation = () => {
    dispatch(getAssetsObservation());
    setSideToOpen("devicesUnderObservation");
    boxDataRef.current = {
      name: "Under Observation",
      isUnderObservation: true,
      borderColor: "#f57c00",
    };
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "email", "orgName"],
          searchValue: "",
          orgId: filterObj?.orgId ? filterObj?.orgId : "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "email", "orgName"],
            searchValue: searchFields?.users,
            orgId: filterObj?.orgId ? filterObj?.orgId : "",
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  useEffect(() => {
    setFilterObj(null);
    setLatLng(null);
    dispatch(resetUserId());
  }, []);

  useEffect(() => {
    if (currentUserDetail && !currentUserDetail?.adminSpecificPermissions) {
      fetchData();
    } else if (
      currentUserDetail &&
      currentUserDetail?.adminSpecificPermissions
    ) {
      dispatch(getAllOrganizations());
    }

    return () => {
      dispatch(flushAllMonitoringData());
      dispatch(flushAllCounts());
      dispatch(resetUserId());
    };
  }, [currentUserDetail]);

  useEffect(() => {
    if (filterObj?.userId) {
      dispatch(setUserId(filterObj?.userId));
      setIsLoading(true);
      dispatch(
        getMonitoringData({ userId: filterObj?.userId }, (res) => {
          if (res && res?.length > 0) {
            dispatch(getMonitoringTripCount({ userId: filterObj?.userId }));
            dispatch(getMonitoringAlertCount({ userId: filterObj?.userId }));
            dispatch(getMonitoringDeviceCount({ userId: filterObj?.userId }));
          }
          setIsLoading(false);
        })
      );
    } else {
      dispatch(flushAllMonitoringData());
      dispatch(flushAllCounts());
      dispatch(resetUserId());
    }
  }, [filterObj?.userId]);

  useEffect(() => {
    setTimeout(() => {
      if (currentUserDetail && currentUserDetail?.isFirstLogin) {
        setOpenChangePassword(true);
      }
    }, 100);
  }, [currentUserDetail]);

  return (
    <>
      {/* <Loader showOn={loading} /> */}
      <Grid
        container
        id="settingsDashboard__container"
        className="monitoring_wrapper"
      >
        {currentUserDetail && currentUserDetail?.adminSpecificPermissions && (
          <Grid item container xs={12} mb={1.5} columnGap={1}>
            <Grid item>
              <CustomSelect
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                label="Select Organization"
                labelKey="name"
                searchValue={
                  searchFields?.org && searchFields?.org !== ""
                    ? searchFields?.org
                    : null
                }
                sx={{ width: 250 }}
                value={filterObj?.orgId || null}
                onChange={(e, newVal) => {
                  if (newVal) {
                    dispatch(
                      getAllUsers({
                        orgId: newVal?._id,
                        selection: ["name", "email", "orgName"],
                      })
                    );
                    setFilterObj((prev) => ({
                      ...prev,
                      orgId: newVal?._id,
                    }));
                  } else {
                    dispatch(flushUsersList());
                    let temp = { ...filterObj };
                    delete temp["orgId"];
                    setFilterObj(temp);
                  }
                }}
                onSearch={handleSearchOrg}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchOrg(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      org: e?.target?.value?.trim(),
                    }));
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return (
                      allOrganizations?.find((_) => _?._id == filterObj?.orgId)
                        ?.name || ""
                    );
                  } else {
                    return option?.name || "";
                  }
                }}
                options={allOrganizations && allOrganizations}
              />
            </Grid>
            <Grid item>
              <CustomSelect
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                label="Select Users"
                labelKey="name"
                renderOptions={(props, option, { selected }) => {
                  return (
                    <MenuItem
                      value={filterObj?.userId || ""}
                      selected={selected}
                      {...props}
                    >
                      <Typography
                        sx={{
                          fontSize: ".8rem",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${option?.name} - ${option?.orgName}` || ""}
                      </Typography>
                    </MenuItem>
                  );
                }}
                sx={{ width: 250 }}
                disabled={!filterObj?.orgId}
                searchValue={
                  searchFields?.users && searchFields?.users !== ""
                    ? searchFields?.users
                    : null
                }
                value={filterObj?.userId || null}
                onChange={(e, newVal) => {
                  if (newVal) {
                    setFilterObj((prev) => ({
                      ...prev,
                      userId: newVal?._id,
                      email: newVal?.email,
                    }));
                  } else {
                    let temp = { ...filterObj };
                    delete temp["userId"];
                    delete temp["email"];
                    setFilterObj(temp);
                  }
                }}
                onSearch={handleSearchUsers}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchUsers(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      users: e?.target?.value?.trim(),
                    }));
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return (
                      allUsers?.find((_) => _?._id == filterObj?.userId)
                        ?.name || ""
                    );
                  } else {
                    return option?.name || "";
                  }
                }}
                options={allUsers && allUsers}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Panel>
            <FilterForm
              onSubmit={onFilterFormSubmit}
              onChange={onChangeFilter}
              themeType={themeType}
            />
            <PanelAction
              isMap={isMap}
              mapToggle={mapToggle}
              fetchData={fetchData}
              onViewUnderObserve={handleDevicesUnderObservation}
              themeType={themeType}
              isDark={isDarkThemeEnabledSelector}
              filterObj={filterObj}
              currentUserDetail={currentUserDetail}
              handleCreateBoxesForUser={handleCreateBoxesForUser}
            />
          </Panel>
        </Grid>
        <Grid item xs={12}>
          <div className="dashboard">
            <div className="dashboard__main_content" ref={dashboardSectionRef}>
              <Map
                isMap={isMap}
                markers={mapviewAlerts}
                alertType={alertType}
                changeFilter={changeFilter}
                themeType={themeType}
                isDark={isDarkThemeEnabledSelector}
                latLng={latLng}
                dashboardSectionRef={dashboardSectionRef}
                newLat={newLat}
                newLng={newLng}
                isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
              />
              {monitoringData && monitoringData.length ? (
                <DashboardSection
                  onBoxClick={handleBoxClick}
                  handleSideClose={handleSideClose}
                  themeType={themeType}
                />
              ) : (
                <EmptyPage body="No Data Found.." />
              )}
            </div>
            <div
              className={`dashboard__side_content  ${sideToOpen ? "open" : ""}`}
              // className={`dashboard__side_content  ${
              //   sideToOpen &&
              //   ((monitoringAnalyticsDetails &&
              //     monitoringAnalyticsDetails?.length) ||
              //     (monitoringAlertDetails && monitoringAlertDetails?.length) ||
              //     (devicesUnderObservation && devicesUnderObservation?.length))
              //     ? "open"
              //     : ""
              // }`}
              ref={sidePanelRef}
            >
              <DetailPanel
                sideToOpen={sideToOpen}
                onClose={handleSideClose}
                data={boxDataRef.current}
                themeType={themeType}
                setLatLng={setLatLng}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <ChangePasswordModal
        open={openChangePassword}
        hide={() => setOpenChangePassword(false)}
        loading={changePasswordLoading}
        handleChangePassword={handleChangePassword}
        currentUserDetail={currentUserDetail}
      />
    </>
  );
};

export default MonitoringDashboard;

function capitalize(str) {
  return str[0].toUpperCase().concat(str.slice(1));
}
