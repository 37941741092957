import {
  Box,
  Checkbox,
  Grid,
  Typography,
  Button,
  Tabs,
  IconButton,
  Tab,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { CustomInput } from "../../../../components/FormUI";
import { Controller, useForm } from "react-hook-form";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import AddIcon from "@mui/icons-material/Add";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { GoogleMapsAPIKey } from "../../../../services/constants";
import { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import moment from "moment";
import { format, setHours, setMinutes } from "date-fns";
import { CustomTimeField } from "../../../../components/FormUI/CustomTimeField";
import DynamicFormRender from "../../../Components/DynamicFormRender";
import SourceImg from "../../../../static/images/routes/source_icon.svg";
import ViaPointImg from "../../../../static/images/routes/viapoint_icon.svg";
import DestinationImg from "../../../../static/images/routes/destination_icon.svg";
import IconWithArrow from "./IconWithArrow";
import { havePermission } from "../../../../utils/permissionCheck";
import {
  getAllGeofences,
  currentUserDetails,
  userDetails
} from "../../../../redux/actions";


const RouteForm = ({
  addMore,
  batterPercentageOptions,
  control,
  dayOptions,
  destinationGeofenceList,
  destinationObject,
  destinationRef,
  errors,
  formSetValue,
  geofenceList,
  getDirectionsData,
  getValues,
  handleAddMore,
  handleCancel,
  handleEdit,
  handleFlush,
  handleOrganisationSearch,
  handleSearchGeofence,
  handleSubmit,
  handleUserSearch,
  isEditRoute,
  isSuperAdmin,
  noGeofenceMessage,
  onChangeViaPoints,
  onSubmit,
  organisationList,
  organizations,
  routeDestination,
  routeDetails,
  routesData,
  routeSource,
  schedules,
  searchObject,
  selectStylesOverride,
  setDestinationGeofenceList,
  setDestinationObject,
  setRouteDestination,
  setRouteSource,
  setSchedules,
  setSearchObject,
  setSourceObject,
  setUser,
  setViaPointsSearchObject,
  sourceGeofenceList,
  sourceObject,
  sourceRef,
  themeType,
  user,
  userList,
  Users,
  templateExistence,
  register,
  reset,
  setValue,
  viaPoints,
  viaPointsDropdownGeofenceList,
  viaPointsSearchObject,
  setNoGeofenceMessage,
  userDetailsRes
}) => {
  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;
  const dispatch = useDispatch();
  const [scheduleCustomDay, setScheduleCustomDay] = useState({});
  const [customDayArrival, setCustomDayArrival] = useState(false)
  const [customDayDeparture, setCustomDayDeparture] = useState(false)
  const [scheduleDestinationCustomDay, setScheduleDestinationCustomDay] =
    useState({});
  const [tabValue, setTabValue] = useState(0);
  const [destinationSpeed, setDestinationSpeed] = useState(40);
  const [destinationTime, setDestinationTime] = useState();
  const selectedOrganisation = getValues("organisation");

  const userPermissions =
    useSelector((state) => state.auth?.currentUserDetail) || {};
  const [haltAtViaPoints, setHaltAtViaPoints] = useState([]);

  const calcTime = useCallback((distance, speed) => {
    const timeHours = distance / speed;
    const hours = Math.floor(timeHours);
    const minutes = Math.round((timeHours - hours) * 60);


    // Format the time as "hh:mm"
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return formattedTime;
  }, []);

  // 
  const convertDecimalToTime = (decimalHours) => {
    // Extract the whole number part (hours) and the fractional part (minutes)
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);

    // Format the result
    var result = hours + ":";
    if (minutes >= 0) {
      result += minutes;
    }
    return result;
  }

  // ***********************
  const calcSpeed = useCallback((distance, time) => {
    const date = new Date(time);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const timeInHours = hours + minutes / 60; // Convert minutes to a fraction of an hour
    const speed = distance / timeInHours;

    return Math.round(speed);
  }, []);

  {
    /* Set states while edit route for custom day states for schedules */
  }
  useEffect(() => {
    if (isEditRoute) {
      schedules?.map((schedule, index) => {
        let dayArrival;
        if (schedule?.dayArrival === "sameDay") {
          dayArrival = "sameDay";
        } else if (schedule?.dayArrival === "nextDay") {
          dayArrival = "nextDay";
        } else if (!!schedule?.dayArrival) {
          dayArrival = "custom";
          formSetValue(
            `scheduleCustomArrivalDay${index}`,
            schedule?.dayArrival
          );
        }
        formSetValue(
          `scheduleArrivalDay${index}`,
          schedule?.dayArrival
            ? dayOptions?.find(
              (option) => option?.value === schedule?.dayArrival
            ) ?? dayOptions?.find((option) => option?.value === "custom")
            : null
        );

        const arrivalDay = {
          [`scheduleArrivalDay${index}`]: dayArrival,
        };

        setScheduleDestinationCustomDay((prev) => ({ ...prev, ...arrivalDay }));

        if (schedule?.scheduleVia?.length) {
          schedule?.scheduleVia?.forEach((scheduleVia, viaIndex) => {
            let viaDayArrival, viaDayDeparture;
            if (scheduleVia?.dayArrival === "sameDay") {
              viaDayArrival = "sameDay";
            } else if (scheduleVia?.dayArrival === "nextDay") {
              viaDayArrival = "nextDay";
            } else if (!!scheduleVia?.dayArrival) {
              viaDayArrival = "custom";
              formSetValue(
                `scheduleViaPointCustomArrivalDay${index}_${viaIndex}`,
                scheduleVia?.dayArrival
              );
            }
            formSetValue(
              `scheduleViaPointArrivalDay${index}_${viaIndex}`,
              scheduleVia?.dayArrival
                ? dayOptions?.find(
                  (option) => option?.value === scheduleVia?.dayArrival
                ) ?? dayOptions?.find((option) => option?.value === "custom")
                : null
            );
            if (scheduleVia?.dayDeparture === "sameDay") {
              viaDayDeparture = "sameDay";
            } else if (scheduleVia?.dayDeparture === "nextDay") {
              viaDayDeparture = "nextDay";
            } else if (!!scheduleVia?.dayDeparture) {
              viaDayDeparture = "custom";
              formSetValue(
                `scheduleViaPointCustomDepartureDay${index}_${viaIndex}`,
                scheduleVia?.dayDeparture
              );
            }
            formSetValue(
              `scheduleViaPointDepartureDay${index}_${viaIndex}`,
              scheduleVia?.dayDeparture
                ? dayOptions?.find(
                  (option) => option?.value === scheduleVia?.dayDeparture
                ) ?? dayOptions?.find((option) => option?.value === "custom")
                : null
            );
            setScheduleCustomDay((prev) => ({
              ...prev,
              [`scheduleViaPointArrivalDay${index}_${viaIndex}`]: viaDayArrival,
              [`scheduleViaPointDepartureDay${index}_${viaIndex}`]:
                viaDayDeparture,
            }));
          });
        }
      });
    }
  }, [
    schedules,
    setScheduleCustomDay,
    setScheduleDestinationCustomDay,
    formSetValue,
    dayOptions,
    isEditRoute,
  ]);

  useEffect(() => {
    if (routesData?.length > 0) {
      viaPoints?.forEach((viaPoint) => {
        const obj = routesData?.findLast(
          (data) => data?.rows?.destinationObj?._id === viaPoint._id
        );
        viaPoint["totalDistance"] = obj?.rows?.distance ?? "";
        formSetValue(
          `viapointDistance${viaPoint?.key}`,
          obj?.rows?.distance ?? ""
        );
        const arrivalTime = calcTime(obj?.rows?.distance, viaPoint?.speed);
        viaPoint["etaTime"] = arrivalTime;
        formSetValue(
          `viapointTime${viaPoint?.key}`,
          convertHHMMToDate(arrivalTime)
        );
        viaPoint["speed"] = viaPoint?.speed;
        formSetValue(`viapointSpeed${viaPoint?.key}`, viaPoint?.speed);
      });
      const destinationFieldData = getValues("destination");
      const destinationObj = routesData?.findLast(
        (data) => data?.rows?.destinationObj?._id === destinationFieldData?._id
      );
      routeDestination["totalDistance"] = destinationObj?.rows?.distance ?? "";
      if (schedules.length === 0 && destinationObj?.rows?.distance) {
        formSetValue("distance", destinationObj?.rows?.distance ?? "");
      }

      const arrivalTime = calcTime(
        destinationObj?.rows?.distance,
        destinationSpeed
      );

      formSetValue("time", convertHHMMToDate(arrivalTime));
      formSetValue("speed", destinationSpeed ?? "");
      routeDestination["speed"] = destinationSpeed ?? "";
      routeDestination["etaTime"] = arrivalTime ?? null;
    }
    // } else {
    //   viaPoints?.forEach((viaPoint, index) => {
    //     viaPoint["totalDistance"] = "";
    //     formSetValue(`viapointDistance${viaPoint?.key}`, "");
    //     viaPoint["etaTime"] = null;
    //     formSetValue(`viapointTime${viaPoint?.key}`, null);
    //     viaPoint["speed"] = destinationSpeed;
    //     formSetValue(`viapointSpeed${viaPoint?.key}`, destinationSpeed);
    //   });
    //   routeDestination["totalDistance"] = "";
    //   formSetValue("distance", "");
    //   formSetValue("time", null);
    //   routeDestination["speed"] = "";
    //   routeDestination["time"] = null;
    // }
  }, [
    routesData,
    viaPoints,
    routeDestination,
    getValues,
    formSetValue,
    schedules,
    destinationSpeed,
    calcTime,
  ]);

  const handleScheduling = useCallback(
    (e) => {
      if (e.target.checked) {
        setSchedules((prev) => {
          const newSchedule = {
            key: prev.length,
          };
          return [...prev, newSchedule];
        });
        setTabValue(0);
      } else {
        setSchedules([]);
      }
    },
    [setSchedules, setTabValue]
  );

  // function convertHHMMToDate(hhmm = "") {
  //   const [hours, minutes] = hhmm?.split(":").map(Number);
  //   const now = new Date();
  //   const resultDate = setMinutes(setHours(now, hours), minutes);
  //   return resultDate;
  // }
  function convertHHMMToDate(hhmm = "") {
    if (typeof hhmm !== 'string') {
      // Handle the case where hhmm is not a string, maybe return null or throw an error
      return null;
    }
    const [hours, minutes] = hhmm?.split(":").map(Number);
    const now = new Date();
    const resultDate = new Date(setMinutes(setHours(now, hours), minutes));
    return resultDate;
  }


  const handleDestinationTimeChange = useCallback(
    (time) => {
      const distance = getValues("distance");
      const calculatedSpeed = calcSpeed(distance, time);
      routeDestination["speed"] = calculatedSpeed;
      formSetValue("speed", calculatedSpeed);
    },
    [routeDestination]
  );

  // handler to change the value of time when changing speed for respective viaPoint
  const handleChangeViaPointSpeed = useCallback(
    (speed, index) => {
      const distance = viaPoints?.find(
        (viaPoint) => viaPoint?.key === index
      )?.totalDistance;
      const calculatedTime = calcTime(distance, speed);
      formSetValue(`viapointTime${index}`, convertHHMMToDate(calculatedTime));
      const tempViaPoints = viaPoints;
      const selectedViaPoint = tempViaPoints?.find(
        (viaPoint) => viaPoint?.key === index
      );
      selectedViaPoint["etaTime"] = calculatedTime;
    },
    [viaPoints]
  );

  // handler to change the value of speed when changing time for respective viaPoint
  const handleChangeViaPointTime = useCallback(
    (time, index) => {
      const distance = viaPoints?.find(
        (viaPoint) => viaPoint?.key === index
      )?.totalDistance;
      const calculatedSpeed = calcSpeed(distance, time);
      formSetValue(`viapointSpeed${index}`, calculatedSpeed);
      const tempViaPoints = viaPoints;
      const selectedViaPoint = tempViaPoints?.find(
        (viaPoint) => viaPoint?.key === index
      );
      selectedViaPoint["speed"] = calculatedSpeed;
    },
    [viaPoints]
  );

  const handleAddSchedule = useCallback(() => {
    setSchedules((prev) => {
      const newSchedule = {
        key: prev.length,
      };
      return [...prev, newSchedule];
    });
    setTabValue((prev) => prev + 1);
  }, [setSchedules, setTabValue]);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function calculateHalt(arrivalTime, departureTime, data) {
    // Parse the arrival and departure times into Date objects
    // console.log('run calculateHalt', data);

    const firstDate = () => {
      if (data.dayArrival === "sameDay") {
        const originalDate = new Date();
        const [hours, minutes] = data.STA.split(':').map(Number);
        const newDate = new Date(originalDate);
        // console.log('1st-hour-min', hours, minutes)
        newDate.setHours(hours, minutes, 0, 0);
        return newDate;
      } else if (data.dayArrival === "nextDay") {
        const originalDate = new Date();
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + 1);
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      } else {
        const originalDate = new Date();
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      }
    };

    const secondDate = () => {
      if (data.dayDeparture === "sameDay") {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const [hours, minutes] = data.STD.split(':').map(Number);
          // console.log('2st-hour-min', hours, minutes)
          const newDate = new Date(originalDate);
          newDate.setHours(hours, minutes, 0, 0);
          return newDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }
      } else if (data.dayDeparture === "nextDay") {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 2);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }
      } else {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 2 + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }

      }
    }

    // console.log('first date', firstDate());
    // console.log('second date', secondDate())

    const timeDifferenceInMilliseconds = secondDate().getTime() - firstDate().getTime();

    // // Convert the difference to hours
    const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);
    // console.log('diff', timeDifferenceInHours)
    return (convertDecimalToTime(timeDifferenceInHours)).toString();
  }

  const handleRemoveSchedule = useCallback(
    (key) => {
      const updatedSchedules = schedules?.filter((schedule) => {
        return schedule.key !== key;
      });
      setSchedules(updatedSchedules);
      setTabValue((prev) => prev - 1);
    },
    [setSchedules, schedules, setTabValue]
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const deleteViapoint = (index) => {
    let temp = [...viaPoints];
    temp.splice(index, 1);

    onChangeViaPoints(temp);
    setValue(`viapoint${index}`, undefined);
    setValue(`viapointDistance${index}`, undefined);
    setValue(`viapointTime${index}`, undefined);
    setValue(`viapointSpeed${index}`, undefined);

    let tempSchedules = [...schedules];
    tempSchedules[0]?.scheduleVia?.splice(index, 1);
    setSchedules(tempSchedules);

    temp?.map((viaPoint, i) => {
      const etaTimeVP = convertHHMMToDate(viaPoint?.etaTime);
      setValue(`viapoint${i}`, viaPoint);
      setValue(`viapointDistance${i}`, viaPoint?.totalDistance);
      setValue(`viapointTime${i}`, etaTimeVP);
      setValue(`viapointSpeed${i}`, viaPoint?.speed);
      viaPoint.key = i;
    });
  };

  useEffect(() => {
    if (viaPoints.length || destinationObject || sourceObject) {
      getDirectionsData();
    }
  }, [viaPoints, destinationObject, sourceObject]);

  useEffect(() => {
    if (!isSuperAdmin) {
      dispatch(getAllGeofences({
        selection: [
          "name",
          "centerCoordinates",
        ],
        // filter:{

        // userId: 'newVal?._id'
        // }
      },
        (success, error) => {
          if (success && success.count === 0) {
            setNoGeofenceMessage(success?.message)
          }

        }
      )
      );
    }
  }, [isSuperAdmin, dispatch]);

  const calculateDistanceTime = (distValue, arrayIndex) => {
    let temp = viaPoints;
    let speed = temp[arrayIndex]?.speed;

    const calculatedTime = calcTime(distValue, speed);
    formSetValue(`viapointTime${arrayIndex}`, convertHHMMToDate(calculatedTime));
    temp[arrayIndex].etaTime = calculatedTime;
    // onChangeViaPoints([...temp])
  }
  
  const calculateDistanceTimeForDestination = (distValue, speed) => {
    const temp = routeDestination;

    const calculatedTime = calcTime(distValue, speed);
    formSetValue(`time`, convertHHMMToDate(calculatedTime));
    temp.totalDistance = distValue;
    temp.etaTime = calculatedTime;
    setRouteDestination(temp);
  }

  return (
    <>
      <Grid>
        <Grid container spacing={2}>
          {isSuperAdmin && (
            <Grid item md={6}>
              <Controller
                name="organisation"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={selectStylesOverride}
                      //disabled={true}
                      label="Select Organization"
                      value={value || null}
                      onChange={(e, newVal) => {
                        onChange(newVal?.name);

                        setUser((prev) => {
                          const updateUser = { ...prev };
                          updateUser["orgId"] = newVal?._id;
                          updateUser["orgName"] = newVal?.name;
                          return updateUser;
                        });
                      }}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          setSearchObject((prev) => ({
                            ...prev,
                            organisation: e?.target?.value?.trim(),
                          }));
                          if (e.target.value === "") {
                            handleOrganisationSearch("reset");
                          }
                        }
                      }}
                      searchValue={searchObject?.organisation}
                      onSearch={() => handleOrganisationSearch()}
                      name={name}
                      errors={errors}
                      filterOptions={(options) => options}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return (
                            organizations?.find((_) => _?.name == value)?.name ||
                            ""
                          );
                        } else {
                          return option?.name || "";
                        }
                      }}
                      labelKey="name"
                      options={organisationList ?? organizations}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {isSuperAdmin && (
            <Grid item md={6}>
              <Controller
                name="user"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      disabled={!selectedOrganisation}
                      selectStylesOverride={selectStylesOverride}
                      filterOptions={(options) => options}
                      label="Select User"
                      value={value || null}
                      onChange={(e, newVal) => {
                        if (newVal) {
                          // console.log(newVal)
                          onChange(newVal?.name);
                          setUser((prev) => {
                            const updateUser = { ...prev };
                            updateUser["id"] = newVal?._id;
                            updateUser["name"] = newVal?.name;
                            return updateUser;
                          });

                          dispatch(userDetails({ id: newVal?._id }, (res) => {
                            console.log(res);
                            if (res) {
                              dispatch(getAllGeofences(
                                {
                                  selection: [
                                    "name",
                                    "centerCoordinates",
                                  ],
                                  user: {
                                    userId: newVal?._id,
                                    addedBy: newVal?.addedBy?.id,
                                    assignGeofence: res?.assignGeofence
                                  }
                                }, (success, error) => {
                                  if (success && success.count === 0) {
                                    setNoGeofenceMessage(success?.message)
                                  }

                                }


                              )
                              );
                            }
                          }))

                        }
                      }}
                      searchValue={searchObject?.user}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          setSearchObject((prev) => ({
                            ...prev,
                            user: e?.target?.value?.trim(),
                          }));
                          if (e.target.value === "") {
                            handleUserSearch("reset");
                          }
                        }
                      }}
                      onSearch={() => handleUserSearch()}
                      name={name}
                      errors={errors}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return Users?.find((_) => _?.name == value)?.name || "";
                        } else {
                          return option?.name || "";
                        }
                      }}
                      labelKey="name"
                      options={userList ?? Users}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item md={6}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Name is required",
                },
              }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    placeholder="Route Name"
                    selectStylesOverride={selectStylesOverride}
                    inputClasses={inputClasses}
                    name={name}
                    value={value || ""}
                    label="Name"
                    required
                    onChange={onChange}
                    errors={errors}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    placeholder="Route description"
                    selectStylesOverride={selectStylesOverride}
                    inputClasses={inputClasses}
                    name={name}
                    value={value || ""}
                    label="Route description"
                    // required
                    onChange={onChange}
                    errors={errors}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="battery"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Battery Percentage is required",
                },
              }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride}
                    value={value || null}
                    // disabled={isEditUser}
                    onChange={(e, newVal) => {
                      onChange(newVal);
                    }}
                    name={name}
                    required
                    label="Minimum Battery Percentage"
                    errors={errors}
                    getOptionLabel={(option) => {
                      return option?.label;
                    }}
                    filterSelectedOptions
                    options={batterPercentageOptions}
                  />
                );
              }}
            />
          </Grid>
        </Grid>


        <form>
          <Grid
            mt={0}
            item
            sm={12}
            minHeight="115px"
            xs={12}
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {templateExistence && Object.keys(templateExistence).length ? (
              <DynamicFormRender
                formHooks={{
                  register,
                  control,
                  errors,
                  handleSubmit,
                  reset,
                  setValue,
                }}
                templateId={(templateExistence && templateExistence?._id) || ""}
                widthProp={6}
              />
            ) : null}
          </Grid>
        </form>

        {/* Route scheduling check  + via point add btn */}
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          mt={0.5}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Checkbox
              checked={schedules?.length > 0 ? true : false}
              onChange={(e) => handleScheduling(e)}
              sx={{
                "&, &.Mui-checked": { color: themeType.default.themeOrange },
                marginBottom: "4px",
              }}
            />
            <Typography sx={{ ...textClasses.normalText, paddingBottom: "4px" }}>
              Enable Route Scheduling
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              ...buttonClasses.small,
              minHeight: "36px",
              borderColor: themeType.default.themeOrange,
              color: buttonClasses.lynkitOrangeEmpty,
            }}
            startIcon={<AddIcon />}
            onClick={() => {
              handleFlush();
              onChangeViaPoints((prev) => {
                const newKey = prev.length;
                const newItem = {
                  key: newKey,
                  _id: "",
                  itemName: "",
                  speed: 40,
                  etaTime: "00:00",
                  totalDistance: "",
                };
                setValue(`viapoint${newKey}`, undefined);
                setValue(`viapointDistance${newKey}`, undefined);
                setValue(`viapointTime${newKey}`, undefined);
                setValue(`viapointSpeed${newKey}`, undefined);
                const updatedViaPoints = [...prev, newItem];
                return updatedViaPoints;
              });
              sendCustomEventAnalytics("routes", "added-viapoint", "Viapoint Added");
            }}
          >
            Add Via Point
          </Button>
        </Grid>

        {schedules.length > 0 && (
          <Grid container justifyContent="end" mt={-1}>
            <Grid item xs={11} container justifyContent="end">
              <Grid
                xs={12}
                display={"flex"}
                sx={{ maxWidth: { xs: 280, sm: 414 }, height: "24px" }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  variant="scrollable"
                  sx={{ minHeight: "16px" }}
                  scrollButtons="auto"
                  indicatorColor=""
                  textColor="inherit"
                >
                  {schedules?.map((schedule, i) => {
                    return (
                      <Tab
                        sx={{
                          bgcolor: "#E0E0E0",
                          minHeight: "24px",
                          borderRadius: "4px 4px 0 0",
                          padding: 0,
                          marginRight: "4px",
                          fontSize: "11px",
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            bgcolor: "#FF7200",
                            color: "#fff",
                          },
                        }}
                        label={
                          <span>
                            {`Schedule ${i + 1}`}
                            <IconButton
                              size="small"
                              sx={{ padding: 0 }}
                              onClick={() => {
                                handleRemoveSchedule(schedule?.key);
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  width: "15px",
                                  position: "relative",
                                  left: "6px",
                                }}
                              />
                            </IconButton>
                          </span>
                        }
                        {...a11yProps(schedule?.key)}
                      />
                    );
                  })}
                </Tabs>
                {schedules.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    data-testid="add-new-button"
                    onClick={() => handleAddSchedule()}
                    sx={{
                      minWidth: "24px",
                      height: "24px",
                      padding: "4px",
                      boxShadow: "none",
                      bgcolor: "#fff",
                      border: "1px solid green",
                      "&:hover": {
                        bgcolor: "#fff",
                      },
                    }}
                  // startIcon={}
                  >
                    <AddIcon fontSize="12px" sx={{ color: "green" }} />
                  </Button>
                )}
              </Grid>

              {/* Your content goes here */}
            </Grid>
          </Grid>
        )}

        <Box
          sx={{ minHeight: "34%", maxHeight: "auto", overflow: 'auto' }}
          border={"0.25px solid #E8E8E8"}
          mt={schedules.length > 0 ? 0 : 1}
        >
          <Grid container sx={{ height: "100%" }}>
            <Grid container item md={12}>
              <Grid
                container
                item
                xs={12}
                mt={0}

              >
                <Grid item md={1} >
                  <IconWithArrow name="source" src={SourceImg} />
                </Grid>
                <Grid item container md={10.5} mt={1} border={"0.1px solid #E8E8E8"}>
                  <Grid item xs={schedules?.length > 0 ? 6 : 12} p={1}>
                    <Typography
                      sx={{ ...textClasses.normalText, paddingBottom: "16px" }}
                    >
                      Source Address*
                    </Typography>
                    <Controller
                      name="source"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Source is required",
                        },
                      }}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            noOptionsText={noGeofenceMessage}
                            ref={sourceRef}
                            selectStylesOverride={selectStylesOverride}
                            defaultValue={routeSource}
                            value={value || null}
                            // disabled={isEditUser}
                            onChange={(e, newVal) => {
                              setRouteSource({
                                _id: newVal?._id,
                                name: newVal?.name,
                              });
                              setSourceObject(newVal);
                              onChange(newVal);
                              // getDirectionsData();
                            }}
                            searchValue={searchObject?.source}
                            onInputChange={(e) => {
                              if (e?.type === "change") {
                                setSearchObject((prev) => ({
                                  ...prev,
                                  source: e?.target?.value?.trim(),
                                }));
                                if (e.target.value === "") {
                                  handleSearchGeofence("source", null, "reset");
                                }
                              }
                            }}
                            onSearch={() => handleSearchGeofence("source")}
                            name={name}
                            required
                            label="Select Geofence"
                            errors={errors}
                            getOptionLabel={(option) => {
                              return option?.name;
                            }}
                            filterSelectedOptions
                            filterOptions={(options) => options}
                            labelKey="name"
                            options={sourceGeofenceList?.length > 0 ? sourceGeofenceList : geofenceList}
                          />
                        );
                      }}
                    />
                  </Grid>
                  {/* --- SOURCE SCHEDULES --- */}
                  {schedules.length > 0 && (
                    <Grid item md={6} pb={3} pl={3} pr={3} borderLeft={"0.1px solid #E8E8E8"}>
                      <Box pb={2}>
                        <Typography
                          sx={{ ...textClasses.normalText, paddingBottom: "9px", paddingTop: '3px' }}
                        >
                          Schedule Name*
                        </Typography>
                        <Controller
                          name={`schedule${tabValue}`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                placeholder="Enter Schedule Name"
                                selectStylesOverride={selectStylesOverride}
                                inputClasses={inputClasses}
                                name={name}
                                value={schedules[tabValue]?.schedule || ""}
                                label="Name"
                                required
                                onChange={(e) => {
                                  const updatedSchedules = [...schedules]; // Create a copy of the original array
                                  const scheduleIndex =
                                    updatedSchedules.findIndex(
                                      (schedule) => schedule.key === tabValue
                                    );

                                  if (scheduleIndex !== -1) {
                                    updatedSchedules[scheduleIndex].schedule =
                                      e.target.value;
                                  }
                                  onChange(e.target.value);
                                }}
                                errors={errors}
                              />
                            );
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ ...textClasses.normalText, paddingBottom: "3px" }}
                        >
                          Schedule Time of Departure*
                        </Typography>
                        <Controller
                          name={`scheduleDeparture${tabValue}`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Time is required",
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomTimeField
                                label="Time"
                                name={name}
                                selectStylesOverride={selectStylesOverride}
                                value={
                                  schedules[tabValue]?.STD
                                    ? convertHHMMToDate(schedules[tabValue]?.STD)
                                    : null
                                }
                                onChange={(time) => {
                                  if (
                                    new Date(time).toString() !== "Invalid Date"
                                  ) {
                                    const formattedTime =
                                      time && format(time, "HH:mm");
                                    const updatedSchedules = [...schedules]; // Create a copy of the original array
                                    const scheduleIndex =
                                      updatedSchedules.findIndex(
                                        (schedule) => schedule.key === tabValue
                                      );

                                    if (scheduleIndex !== -1) {
                                      updatedSchedules[scheduleIndex]["STD"] =
                                        formattedTime;
                                    }
                                  }
                                  onChange(time);
                                }}
                                errors={errors}
                              />
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* Via points section ------- */}
              {viaPoints?.length > 0 &&
                viaPoints?.map((viaPoint, index) => {
                  return (
                    <div
                      key={index}
                      // draggable
                      style={{ width: "96%" }}
                    // onDragStart={event => handleDragStart(event, index)}
                    // onDragOver={() => handleDragOver(index)}
                    // onDragEnd={handleDragEnd}
                    >
                      <Grid xs={12} item container mt={1}>
                        <Grid item md={1}>
                          <IconWithArrow name="viapoint" src={ViaPointImg} />
                        </Grid>
                        <Grid item container md={11}>
                          <Grid
                            container
                            item
                            xs={12}
                            borderTop={"0.25px solid #E8E8E8"}
                            borderBottom={"0.1px solid #E8E8E8"}
                            borderRight={"0.1px solid #E8E8E8"}
                            sx={{ position: "relative" }}
                          >
                            <Grid className="viaNumber">{index + 1}</Grid>
                            <Grid
                              item
                              xs={schedules?.length > 0 ? 6 : 12}
                              p={2}
                              borderLeft={"0.25px solid #E8E8E8"}
                            >
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...textClasses.normalText,
                                    paddingBottom: "1px"
                                  }}
                                >
                                  Viapoint Address*
                                </Typography>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  // className='deleteredBtn'
                                  sx={{
                                    ...buttonClasses.lynkitRedEmpty,
                                    minHeight: "25px",
                                    position: "relative",
                                    bottom: "5px",
                                  }}
                                  onClick={() => deleteViapoint(index)}
                                >
                                  Delete
                                </Button>
                              </Grid>
                              <Grid item mt={0} >
                                <Controller
                                  name={`viapoint${index}`}
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Viapoint is required",
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value, name },
                                  }) => {
                                    return (
                                      <CustomSelect
                                        noOptionsText={noGeofenceMessage}
                                        selectStylesOverride={
                                          selectStylesOverride
                                        }
                                        value={value || null}
                                        onChange={(option, newVal) => {
                                          const updatedViaPoints = [...viaPoints];
                                          const arrayIndex =
                                            updatedViaPoints.findIndex(
                                              (viaPoint) => viaPoint.key === index
                                            );

                                          if (arrayIndex !== -1) {
                                            updatedViaPoints[arrayIndex] = {
                                              ...updatedViaPoints[arrayIndex],
                                              _id: newVal?._id,
                                              itemName: newVal?.name,
                                            };
                                            onChangeViaPoints(updatedViaPoints);
                                          }
                                          // getDirectionsData();
                                          onChange(newVal);
                                        }}
                                        searchValue={
                                          viaPointsSearchObject?.find(
                                            (viaPoint) => {
                                              return viaPoint?.index === index;
                                            }
                                          )?.searchValue
                                        }
                                        onInputChange={(e) => {
                                          if (e?.type === "change") {
                                            const temp = viaPointsSearchObject;
                                            const existingSearchObject =
                                              temp?.find(
                                                (object) =>
                                                  object?.index === index
                                              );
                                            if (existingSearchObject) {
                                              const updateExistingObject =
                                                temp.map((object) => {
                                                  if (object?.index === index) {
                                                    return {
                                                      ...object,
                                                      searchValue:
                                                        e?.target?.value,
                                                    };
                                                  }
                                                });
                                              setViaPointsSearchObject(
                                                updateExistingObject
                                              );
                                            } else {
                                              setViaPointsSearchObject((prev) => {
                                                const vaiPointSearchObject = {
                                                  index: index,
                                                  searchValue: e?.target?.value,
                                                };
                                                return [
                                                  ...prev,
                                                  vaiPointSearchObject,
                                                ];
                                              });
                                            }

                                            if (e.target.value === "") {
                                              handleSearchGeofence(
                                                "viaPoint",
                                                index,
                                                "reset"
                                              );
                                            }
                                          }
                                        }}
                                        onSearch={() =>
                                          handleSearchGeofence("viaPoint", index)
                                        }
                                        name={name}
                                        required
                                        label="Select Viapoint"
                                        errors={errors}
                                        getOptionLabel={(option) => {
                                          return option?.name || option?.itemName || "";
                                        }}
                                        filterSelectedOptions
                                        filterOptions={(options) => options}
                                        labelKey="name" 
                                        // renderOptions={(props, option, { selected }) => {
                                        //   return (
                                        //     <MenuItem value={value || ""} selected={selected} {...props}>
                                        //       <Typography
                                        //         sx={{
                                        //           fontSize: ".8rem",
                                        //           overflow: "hidden",
                                        //           whiteSpace: "nowrap",
                                        //           textOverflow: "ellipsis",
                                        //         }}
                                        //       >
                                        //         {option?.name || option?.itemName || ""}
                                        //       </Typography>
                                        //     </MenuItem>
                                        //   );
                                        // }}
                                        options={
                                          viaPointsDropdownGeofenceList?.find(
                                            (geofenceList) =>
                                              geofenceList?.index === index
                                          )?.list ?? geofenceList
                                        }
                                      />
                                    );
                                  }}
                                />
                                {schedules.length > 0 && (
                                  <Box pt={2}>
                                    <Typography
                                      sx={{
                                        ...textClasses.normalText,
                                        paddingBottom: "11px",
                                      }}
                                    >
                                      Distance : {viaPoint?.totalDistance ?? "-"}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...textClasses.normalText,
                                        paddingBottom: "11px",
                                      }}
                                    >
                                      Average Speed : {viaPoint?.speed ?? "-"}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        ...textClasses.normalText,
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      Halt :{" "}
                                      {(schedules[tabValue] &&
                                        schedules[tabValue]?.scheduleVia &&
                                        schedules[tabValue]?.scheduleVia[index]
                                          ?.halt) ??
                                        ""}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>

                            {schedules.length === 0 && (
                              <Grid
                                container
                                item
                                xs={4}
                                gap={1}
                                p={2}
                                borderLeft={"0.1px solid #E8E8E8"}
                              >
                                <Grid item xs sx={{ padding: "0 !important" }}>
                                  <Controller
                                    name={`viapointDistance${index}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value, name },
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeholder="Distance"
                                          disabled={routeDetails && Object.keys(routeDetails).length ? false : true}
                                          selectStylesOverride={
                                            selectStylesOverride
                                          }
                                          inputClasses={inputClasses}
                                          name={name}
                                          value={value || ""}
                                          label="Distance"
                                          onChange={(e) => {
                                            const arrayIndex =
                                              viaPoints?.findIndex(
                                                (viaPoint) =>
                                                  viaPoint.key === index
                                              );
                                            if (arrayIndex !== -1) {
                                              viaPoints[arrayIndex] = {
                                                ...viaPoints[arrayIndex],
                                                totalDistance: e.target.value,
                                              };
                                            }
                                            if (Object.keys(routeDetails).length) {
                                              calculateDistanceTime(e.target.value, arrayIndex)
                                              
                                            }
                                            onChange(e.target.value);
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs sx={{ padding: "0 !important" }}>
                                  <Controller
                                    name={`viapointTime${index}`}
                                    control={control}
                                    render={({
                                      field: { onChange, value, name },
                                    }) => {
                                      return (
                                        <CustomTimeField
                                          label="Time"
                                          size="small"
                                          name={name}
                                          errors={errors}
                                          selectStylesOverride={
                                            selectStylesOverride
                                          }
                                          value={value}
                                          onChange={(time) => {
                                            if (
                                              new Date(time).toString() !==
                                              "Invalid Date"
                                            ) {
                                              const formattedTime = format(
                                                time,
                                                "HH:mm"
                                              );

                                              const arrayIndex =
                                                viaPoints?.findIndex(
                                                  (viaPoint) =>
                                                    viaPoint.key === index
                                                );
                                              if (arrayIndex !== -1) {
                                                viaPoints[arrayIndex] = {
                                                  ...viaPoints[arrayIndex],
                                                  etaTime: formattedTime,
                                                };
                                              }
                                              handleChangeViaPointTime(
                                                time,
                                                index
                                              );
                                            }

                                            onChange(time);
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs sx={{ padding: "0 !important" }}>
                                  <Controller
                                    name={`viapointSpeed${index}`}
                                    defaultValue={destinationSpeed || ""}
                                    control={control}
                                    render={({
                                      field: { onChange, value, name },
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeholder="Speed"
                                          selectStylesOverride={
                                            selectStylesOverride
                                          }
                                          inputClasses={inputClasses}
                                          name={name}
                                          value={value || ""}
                                          label="Speed"
                                          onChange={(e) => {
                                            const arrayIndex =
                                              viaPoints?.findIndex(
                                                (viaPoint) =>
                                                  viaPoint.key === index
                                              );
                                            if (arrayIndex !== -1) {
                                              viaPoints[arrayIndex] = {
                                                ...viaPoints[arrayIndex],
                                                speed: Number(e.target.value),
                                              };
                                            }
                                            handleChangeViaPointSpeed(
                                              e.target.value,
                                              index
                                            );
                                            onChange(e.target.value);
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            {/* Viapoint Address time*------- */}
                            {schedules.length > 0 && (
                              <Grid
                                container
                                item
                                xs
                                borderLeft={"0.25px solid #E8E8E8"}
                              >
                                <Box width={"100%"} pl={3} pr={3} md={12} pt={2}>
                                  <Typography
                                    sx={{
                                      ...textClasses.normalText,
                                      paddingBottom: "15px",
                                    }}
                                    pb={4}
                                  >
                                    Schedule Time of Arrival*
                                  </Typography>

                                  <Grid
                                    item
                                    container
                                    md={12}
                                    mb={2}
                                    display={"flex"}
                                    sx={{ justifyContent: "space-between" }}
                                  >
                                    <Grid item md={5.8}>
                                      <Box pb={0}>
                                        <Controller
                                          name={`scheduleViaPointArrivalDay${tabValue}_${index}`}
                                          control={control}
                                          rules={{
                                            required: {
                                              value: true,
                                              message: "Please add Day",
                                            },
                                          }}
                                          // defaultValue={null}
                                          render={({
                                            field: { onChange, value, name },
                                          }) => {
                                            return (
                                              <CustomSelect
                                                selectStylesOverride={
                                                  selectStylesOverride
                                                }
                                                // defaultValue={
                                                //   dayOptions?.find(
                                                //     (option) =>
                                                //       option?.value ===
                                                //       (schedules[tabValue] &&
                                                //         schedules[tabValue][
                                                //         "scheduleVia"
                                                //         ] &&
                                                //         schedules[tabValue][
                                                //           "scheduleVia"
                                                //         ][index]?.dayArrival)
                                                //   ) || null
                                                // }
                                                // inputValue={(schedules[tabValue] && schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index]?.dayArrival ) === null ?  "" : schedules[tabValue] && schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index]?.dayArrival }
                                                // value={
                                                //   schedules[tabValue] &&
                                                //     schedules[tabValue]
                                                //       ?.scheduleVia &&
                                                //     schedules[tabValue]
                                                //       ?.scheduleVia[index]
                                                //       ?.dayArrival
                                                //     ? dayOptions.find(
                                                //       (option) =>
                                                //         option?.value ===
                                                //         schedules[tabValue]
                                                //           ?.scheduleVia[index]
                                                //           ?.dayArrival
                                                //     ) ??
                                                //     dayOptions.find(
                                                //       (option) =>
                                                //         option?.value ===
                                                //         "custom"
                                                //     )
                                                //     : null
                                                // }
                                                value={scheduleCustomDay[`scheduleViaPointArrivalDay${tabValue}_${index}`] === "sameDay" ? { value: "sameDay", label: "Same Day" } :
                                                  scheduleCustomDay[`scheduleViaPointArrivalDay${tabValue}_${index}`] === "nextDay" ? { value: "nextDay", label: "Next Day" } :
                                                    scheduleCustomDay[`scheduleViaPointArrivalDay${tabValue}_${index}`] === "custom" ? { value: "custom", label: "Custom" } : null}
                                                onChange={(e, newVal) => {
                                                  if (newVal) {
                                                    if (
                                                      newVal?.value !== "custom"
                                                    ) {
                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );
                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][
                                                            index
                                                          ] = {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][
                                                            index
                                                          ] = {};
                                                        }
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index][
                                                          "dayArrival"
                                                        ] = newVal?.value;
                                                      }
                                                    }
                                                    const scheduleState = {
                                                      [`scheduleViaPointArrivalDay${tabValue}_${index}`]:
                                                        newVal?.value,
                                                    };
                                                    setScheduleCustomDay(
                                                      (prev) => ({
                                                        ...prev,
                                                        ...scheduleState,
                                                      })
                                                    );
                                                    onChange(newVal);
                                                  }
                                                }}
                                                name={name}
                                                required
                                                label="Schedule Day"
                                                errors={errors}
                                                getOptionLabel={(option) => {
                                                  return option?.label;
                                                }}
                                                filterSelectedOptions
                                                options={dayOptions}
                                              />
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>

                                    {scheduleCustomDay[
                                      `scheduleViaPointArrivalDay${tabValue}_${index}`
                                    ] !== "custom" && (
                                        <Grid item md={5.8}>
                                          <Box pb={1}>
                                            <Controller
                                              name={`scheduleViaPointArrivalTime${tabValue}_${index}`}
                                              control={control}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message: "Time is required",
                                                },
                                              }}
                                              render={({
                                                field: { onChange, value, name },
                                              }) => {
                                                return (
                                                  <CustomTimeField
                                                    selectStylesOverride={
                                                      selectStylesOverride
                                                    }
                                                    label="Time"
                                                    size="small"
                                                    name={name}
                                                    errors={errors}
                                                    value={
                                                      schedules[tabValue] &&
                                                        schedules[tabValue]
                                                          ?.scheduleVia &&
                                                        schedules[tabValue]
                                                          ?.scheduleVia[index]?.STA
                                                        ? convertHHMMToDate(
                                                          schedules[tabValue]
                                                            ?.scheduleVia[index]
                                                            ?.STA
                                                        )
                                                        : null
                                                    }
                                                    onChange={(time) => {
                                                      if (
                                                        new Date(
                                                          time
                                                        ).toString() !==
                                                        "Invalid Date"
                                                      ) {
                                                        const formattedTime =
                                                          format(time, "HH:mm");

                                                        const updatedSchedules = [
                                                          ...schedules,
                                                        ]; // Create a copy of the original array
                                                        const scheduleIndex =
                                                          updatedSchedules.findIndex(
                                                            (schedule) =>
                                                              schedule.key ===
                                                              tabValue
                                                          );

                                                        if (scheduleIndex !== -1) {
                                                          if (
                                                            !updatedSchedules[
                                                              scheduleIndex
                                                            ].scheduleVia
                                                          ) {
                                                            updatedSchedules[
                                                              scheduleIndex
                                                            ]["scheduleVia"] = [];
                                                            updatedSchedules[
                                                              scheduleIndex
                                                            ]["scheduleVia"][
                                                              index
                                                            ] = {};
                                                          }
                                                          if (
                                                            !updatedSchedules[
                                                            scheduleIndex
                                                            ]["scheduleVia"][index]
                                                          ) {
                                                            updatedSchedules[
                                                              scheduleIndex
                                                            ]["scheduleVia"][
                                                              index
                                                            ] = {};
                                                          }
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index][
                                                            "STA"
                                                          ] = formattedTime;
                                                        }
                                                      }
                                                      onChange(time);
                                                    }}
                                                  />
                                                );
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      )}
                                  </Grid>

                                  {scheduleCustomDay[
                                    `scheduleViaPointArrivalDay${tabValue}_${index}`
                                  ] === "custom" && (
                                      <Grid
                                        item
                                        container
                                        md={12}
                                        display={"flex"}
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <Grid item md={5.8} pb={1}>
                                          <Box pb={1}>
                                            <Controller
                                              name={`scheduleViaPointCustomArrivalDay${tabValue}_${index}`}
                                              control={control}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message: "Please add Day",
                                                },
                                              }}
                                              render={({
                                                field: { onChange, value, name },
                                              }) => {
                                                return (
                                                  <CustomInput
                                                    placeholder="Select Day"
                                                    selectStylesOverride={
                                                      selectStylesOverride
                                                    }
                                                    inputClasses={inputClasses}
                                                    type="number"
                                                    name={name}
                                                    value={value || ""}
                                                    // value={(schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index].length > 0 && Number(schedules[tabValue]?.scheduleVia[index]?.["dayArrival"]) ? Number(schedules[tabValue]?.scheduleVia[index]?.["dayArrival"]) : value ? value : "") || ""}
                                                    label="select Day"
                                                    onChange={(e) => {
                                                      // console.log('sleect day', schedules, tabValue, index)
                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );
                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (e.target.value < 3) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index][
                                                            "dayArrival"
                                                          ] = 3;
                                                        } else {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index][
                                                            "dayArrival"
                                                          ] = e.target.value;
                                                        }

                                                      }

                                                      onChange(e.target.value > 2 ? e.target.value : "");
                                                    }}
                                                    minValue={3}
                                                  />
                                                );
                                              }}
                                            />
                                            <FormHelperText style={{ fontSize: '11px' }}>
                                              Day value should be more than <b>2</b>.
                                            </FormHelperText>
                                          </Box>
                                        </Grid>
                                        <Grid item md={5.8} pb={1}>
                                          <Controller
                                            name={`scheduleViaPointArrivalTime${tabValue}_${index}`}
                                            control={control}
                                            rules={{
                                              required: {
                                                value: true,
                                                message: "Time is required",
                                              },
                                            }}
                                            render={({
                                              field: { onChange, value, name },
                                            }) => {
                                              return (
                                                <CustomTimeField
                                                  selectStylesOverride={
                                                    selectStylesOverride
                                                  }
                                                  label="Time"
                                                  size="small"
                                                  name={name}
                                                  errors={errors}
                                                  value={
                                                    schedules[tabValue] &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia[index]?.STA
                                                      ? convertHHMMToDate(
                                                        schedules[tabValue]
                                                          ?.scheduleVia[index]
                                                          ?.STA
                                                      )
                                                      : null
                                                  }
                                                  onChange={(time) => {
                                                    if (
                                                      new Date(time).toString() !==
                                                      "Invalid Date"
                                                    ) {
                                                      const formattedTime = format(
                                                        time,
                                                        "HH:mm"
                                                      );

                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );

                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index][
                                                          "STA"
                                                        ] = formattedTime;
                                                      }
                                                    }
                                                    onChange(time);
                                                  }}
                                                />
                                              );
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}

                                  <Typography
                                    sx={{ ...textClasses.normalText }}
                                    pb={2}
                                  >
                                    Schedule Time of Departure*
                                  </Typography>

                                  <Grid
                                    item
                                    container
                                    md={12}
                                    display={"flex"}
                                    sx={{ justifyContent: "space-between" }}
                                  >
                                    <Grid item md={5.8} pb={1}>
                                      <Box pb={1}>
                                        <Controller
                                          name={`scheduleViaPointDepartureDay${tabValue}_${index}`}
                                          control={control}
                                          rules={{
                                            required: {
                                              value: true,
                                              message: "Please add Day",
                                            },
                                          }}
                                          render={({
                                            field: { onChange, value, name },
                                          }) => {
                                            return (
                                              <CustomSelect
                                                selectStylesOverride={
                                                  selectStylesOverride
                                                }
                                                // defaultValue={
                                                //   dayOptions?.find(
                                                //     (option) =>
                                                //       option?.value ===
                                                //       (schedules[tabValue] &&
                                                //         schedules[tabValue][
                                                //         "scheduleVia"
                                                //         ] &&
                                                //         schedules[tabValue][
                                                //           "scheduleVia"
                                                //         ][index]?.dayDeparture)
                                                //   ) || null
                                                // }
                                                // value={
                                                //   schedules[tabValue] &&
                                                //     schedules[tabValue]
                                                //       ?.scheduleVia &&
                                                //     schedules[tabValue]
                                                //       ?.scheduleVia[index]
                                                //       ?.dayDeparture
                                                //     ? dayOptions.find(
                                                //       (option) =>
                                                //         option?.value ===
                                                //         schedules[tabValue]
                                                //           ?.scheduleVia[index]
                                                //           ?.dayDeparture
                                                //     ) ??
                                                //     dayOptions.find(
                                                //       (option) =>
                                                //         option?.value ===
                                                //         "custom"
                                                //     )
                                                //     : null
                                                // }
                                                value={scheduleCustomDay[`scheduleViaPointDepartureDay${tabValue}_${index}`] === "sameDay" ? { value: "sameDay", label: "Same Day" } :
                                                  scheduleCustomDay[`scheduleViaPointDepartureDay${tabValue}_${index}`] === "nextDay" ? { value: "nextDay", label: "Next Day" } :
                                                    scheduleCustomDay[`scheduleViaPointDepartureDay${tabValue}_${index}`] === "custom" ? { value: "custom", label: "Custom" } : null}
                                                onChange={(e, newVal) => {
                                                  if (
                                                    newVal?.value !== "custom"
                                                  ) {
                                                    const updatedSchedules = [
                                                      ...schedules,
                                                    ]; // Create a copy of the original array
                                                    const scheduleIndex =
                                                      updatedSchedules.findIndex(
                                                        (schedule) =>
                                                          schedule.key ===
                                                          tabValue
                                                      );
                                                    if (scheduleIndex !== -1) {
                                                      if (
                                                        !updatedSchedules[
                                                          scheduleIndex
                                                        ].scheduleVia
                                                      ) {
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"] = [];
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index] =
                                                          {};
                                                      }
                                                      if (
                                                        !updatedSchedules[
                                                        scheduleIndex
                                                        ]["scheduleVia"][index]
                                                      ) {
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index] =
                                                          {};
                                                      }
                                                      updatedSchedules[
                                                        scheduleIndex
                                                      ]["scheduleVia"][index][
                                                        "dayDeparture"
                                                      ] = newVal?.value;
                                                    }
                                                  }

                                                  const scheduleViaPoint = {
                                                    [`scheduleViaPointDepartureDay${tabValue}_${index}`]:
                                                      newVal?.value,
                                                  };

                                                  setScheduleCustomDay(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...scheduleViaPoint,
                                                    })
                                                  );

                                                  onChange(newVal);
                                                }}
                                                name={name}
                                                required
                                                label="Schedule Day"
                                                errors={errors}
                                                getOptionLabel={(option) => {
                                                  return option?.label;
                                                }}
                                                filterSelectedOptions
                                                options={dayOptions}
                                              />
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                    {scheduleCustomDay[
                                      `scheduleViaPointDepartureDay${tabValue}_${index}`
                                    ] !== "custom" && (
                                        <Grid item md={5.8}>
                                          <Controller
                                            name={`scheduleViaPointDepartureTime${tabValue}_${index}`}
                                            control={control}
                                            rules={{
                                              required: {
                                                value: true,
                                                message: "Time is required",
                                              },
                                            }}
                                            render={({
                                              field: { onChange, value, name },
                                            }) => {
                                              return (
                                                <CustomTimeField
                                                  label="Time"
                                                  size="small"
                                                  name={name}
                                                  errors={errors}
                                                  selectStylesOverride={
                                                    selectStylesOverride
                                                  }
                                                  value={
                                                    schedules[tabValue] &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia[index]?.STD
                                                      ? convertHHMMToDate(
                                                        schedules[tabValue]
                                                          ?.scheduleVia[index]
                                                          ?.STD
                                                      )
                                                      : null
                                                  }
                                                  onChange={(time) => {
                                                    if (
                                                      new Date(time).toString() !==
                                                      "Invalid Date"
                                                    ) {
                                                      const formattedTime = format(
                                                        time,
                                                        "HH:mm"
                                                      );
                                                      const newDate = new Date(time);
                                                      // console.log('time2==', newDate, formattedTime, schedules)
                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );

                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index][
                                                          "STD"
                                                        ] = formattedTime;
                                                      }
                                                      const AT =
                                                        schedules?.[scheduleIndex]
                                                          ?.scheduleVia[index]?.STA;
                                                      const DT = formattedTime;

                                                      const halt = calculateHalt(
                                                        AT,
                                                        DT,
                                                        schedules?.[scheduleIndex]
                                                          ?.scheduleVia[index]
                                                      );
                                                      updatedSchedules[
                                                        scheduleIndex
                                                      ]["scheduleVia"][index][
                                                        "halt"
                                                      ] = halt;
                                                      const indexForHalt =
                                                        haltAtViaPoints.findIndex(
                                                          (item) =>
                                                            item.key === index
                                                        );
                                                      if (indexForHalt !== -1) {
                                                        // If an object with the key exists, update its halt value
                                                        setHaltAtViaPoints(
                                                          (prevData) => [
                                                            ...prevData.slice(
                                                              0,
                                                              indexForHalt
                                                            ),
                                                            {
                                                              key: index,
                                                              halt: halt,
                                                            },
                                                            ...prevData.slice(
                                                              index + 1
                                                            ),
                                                          ]
                                                        );
                                                      } else {
                                                        setHaltAtViaPoints(
                                                          (prevData) => [
                                                            ...prevData,
                                                            {
                                                              key: index,
                                                              halt: halt,
                                                            },
                                                          ]
                                                        );
                                                      }
                                                    }

                                                    onChange(time);
                                                  }}
                                                />
                                              );
                                            }}
                                          />
                                        </Grid>
                                      )}
                                  </Grid>

                                  {scheduleCustomDay[
                                    `scheduleViaPointDepartureDay${tabValue}_${index}`
                                  ] === "custom" && (
                                      <Grid
                                        item
                                        container
                                        md={12}
                                        display={"flex"}
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <Grid item md={5.8} pb={1}>
                                          <Box pb={1}>
                                            <Controller
                                              name={`scheduleViaPointCustomDepartureDay${tabValue}_${index}`}
                                              control={control}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message: "Please add Day",
                                                },
                                              }}
                                              render={({
                                                field: { onChange, value, name },
                                              }) => {
                                                return (
                                                  <CustomInput
                                                    placeholder="Select Day"
                                                    selectStylesOverride={
                                                      selectStylesOverride
                                                    }
                                                    inputClasses={inputClasses}
                                                    type="number"
                                                    name={name}
                                                    value={value || ""}
                                                    label={"Select Day"}
                                                    onChange={(e) => {
                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );

                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index][
                                                          "dayDeparture"
                                                        ] = e.target.value

                                                      }
                                                      onChange(e.target.value > 2 ? e.target.value : "");
                                                    }}
                                                    minValue={3}
                                                  />
                                                );
                                              }}
                                            />
                                            <FormHelperText style={{ fontSize: '11px' }}>
                                              Day value should be more than <b>2</b>.
                                            </FormHelperText>
                                          </Box>
                                        </Grid>
                                        <Grid item md={5.8} pb={1}>
                                          <Controller
                                            name={`scheduleViaPointDepartureTime${tabValue}_${index}`}
                                            control={control}
                                            rules={{
                                              required: {
                                                value: true,
                                                message: "Time is required",
                                              },
                                            }}
                                            render={({
                                              field: { onChange, value, name },
                                            }) => {
                                              return (
                                                <CustomTimeField
                                                  label="Time"
                                                  size="small"
                                                  name={name}
                                                  errors={errors}
                                                  selectStylesOverride={
                                                    selectStylesOverride
                                                  }
                                                  value={
                                                    schedules[tabValue] &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia &&
                                                      schedules[tabValue]
                                                        ?.scheduleVia[index]?.STD
                                                      ? convertHHMMToDate(
                                                        schedules[tabValue]
                                                          ?.scheduleVia[index]
                                                          ?.STD
                                                      )
                                                      : null
                                                  }
                                                  onChange={(time) => {
                                                    if (
                                                      new Date(time).toString() !==
                                                      "Invalid Date"
                                                    ) {
                                                      const formattedTime = format(
                                                        time,
                                                        "HH:mm"
                                                      );
                                                      // console.log('time', formattedTime, schedules)
                                                      const updatedSchedules = [
                                                        ...schedules,
                                                      ]; // Create a copy of the original array
                                                      const scheduleIndex =
                                                        updatedSchedules.findIndex(
                                                          (schedule) =>
                                                            schedule.key ===
                                                            tabValue
                                                        );

                                                      if (scheduleIndex !== -1) {
                                                        if (
                                                          !updatedSchedules[
                                                            scheduleIndex
                                                          ].scheduleVia
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"] = [];
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        if (
                                                          !updatedSchedules[
                                                          scheduleIndex
                                                          ]["scheduleVia"][index]
                                                        ) {
                                                          updatedSchedules[
                                                            scheduleIndex
                                                          ]["scheduleVia"][index] =
                                                            {};
                                                        }
                                                        updatedSchedules[
                                                          scheduleIndex
                                                        ]["scheduleVia"][index][
                                                          "STD"
                                                        ] = formattedTime;
                                                      }
                                                      const AT =
                                                        schedules?.[scheduleIndex]
                                                          ?.scheduleVia[index]?.STA;
                                                      const DT = formattedTime;

                                                      const halt = calculateHalt(
                                                        AT,
                                                        DT,
                                                        schedules?.[scheduleIndex]
                                                          ?.scheduleVia[index]
                                                      );
                                                      updatedSchedules[
                                                        scheduleIndex
                                                      ]["scheduleVia"][index][
                                                        "halt"
                                                      ] = halt;
                                                      const indexForHalt =
                                                        haltAtViaPoints.findIndex(
                                                          (item) =>
                                                            item.key === index
                                                        );
                                                      if (indexForHalt !== -1) {
                                                        // If an object with the key exists, update its halt value
                                                        setHaltAtViaPoints(
                                                          (prevData) => [
                                                            ...prevData.slice(
                                                              0,
                                                              indexForHalt
                                                            ),
                                                            {
                                                              key: index,
                                                              halt: halt,
                                                            },
                                                            ...prevData.slice(
                                                              index + 1
                                                            ),
                                                          ]
                                                        );
                                                      } else {
                                                        setHaltAtViaPoints(
                                                          (prevData) => [
                                                            ...prevData,
                                                            {
                                                              key: index,
                                                              halt: halt,
                                                            },
                                                          ]
                                                        );
                                                      }
                                                    }

                                                    onChange(time);
                                                  }}
                                                />
                                              );
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )}
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

              {/* Destination------- */}
              <Grid container item xs={12} mt={1} mb={1}>
                <Grid item md={1}>
                  <IconWithArrow name="destination" src={DestinationImg} />
                </Grid>
                <Grid
                  item
                  container
                  md={10.5}
                  border={"0.25px solid #E8E8E8"}
                >
                  <Grid
                    item
                    xs={schedules?.length > 0 ? 6 : 12}
                    p={2}
                  >
                    <Typography
                      sx={{ ...textClasses.normalText, paddingBottom: "16px" }}
                    >
                      Destination Address*
                    </Typography>
                    <Controller
                      name="destination"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Destination is required",
                        },
                      }}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            noOptionsText={noGeofenceMessage}
                            ref={destinationRef}
                            selectStylesOverride={selectStylesOverride}
                            value={value || null}
                            searchValue={searchObject?.destination}
                            onInputChange={(e) => {
                              if (e?.type === "change") {
                                setSearchObject((prev) => ({
                                  ...prev,
                                  destination: e?.target?.value?.trim(),
                                }));
                                if (e.target.value === "") {
                                  handleSearchGeofence(
                                    "destination",
                                    null,
                                    "reset"
                                  );
                                }
                              }
                            }}
                            onSearch={() => handleSearchGeofence("destination")}
                            filterOptions={(options) => options}
                            onChange={(e, newVal) => {
                              setRouteDestination({
                                _id: newVal?._id,
                                name: newVal?.name,
                                speed: 40,
                                etaTime: "",
                                totalDistance: "",
                              });
                              onChange(newVal);
                              // getDirectionsData();
                              setDestinationObject(newVal);
                             
                            }}
                            name={name}
                            required
                            label="Select Geofence"
                            errors={errors}
                            getOptionLabel={(option) => {
                              return option?.name;
                            }}
                            filterSelectedOptions
                            labelKey="name"
                            options={
                              destinationGeofenceList?.length > 0 ? destinationGeofenceList : geofenceList
                            }
                          />
                        );
                      }}
                    />
                    {schedules.length !== 0 && (
                      <Box pt={1}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            paddingBottom: "11px",
                          }}
                        >
                          Distance : {routeDestination?.totalDistance}
                        </Typography>
                        <Typography
                          sx={{ ...textClasses.normalText, paddingBottom: "4px" }}
                        >
                          Average Speed : {routeDestination?.speed}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  {schedules.length === 0 && (
                    <Grid
                      container
                      item
                      xs={12}
                      gap={1}
                      p={2}
                    >
                      <Grid item xs sx={{ paddingLeft: "0 !important" }}>
                        <Controller
                          name={`distance`}
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                disabled={routeDetails && Object.keys(routeDetails).length ? false : true}
                                placeholder="Distance"
                                selectStylesOverride={selectStylesOverride}
                                inputClasses={inputClasses}
                                name={name}
                                value={value || ""}
                                label="Distance"
                                onChange={(e)=>{
                                  onChange(e?.target?.value)
                                  if (Object.keys(routeDetails).length) {
                                    const speed = routeDetails?.routeDestination?.speed || 40
                                    calculateDistanceTimeForDestination(e.target.value, speed)
                                  }
                                }}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs sx={{ padding: "0 !important" }}>
                        <Controller
                          name={`time`}
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomTimeField
                                label="Time"
                                name={name}
                                errors={errors}
                                fullWidth
                                defaultValue={destinationTime}
                                selectStylesOverride={selectStylesOverride}
                                value={value}
                                onChange={(time) => {
                                  if (time) {
                                    setDestinationTime(time);
                                    handleDestinationTimeChange(time);
                                  }
                                  onChange(time);
                                }}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs sx={{ padding: "0 !important" }}>
                        <Controller
                          name={`speed`}
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                placeholder="Speed"
                                selectStylesOverride={selectStylesOverride}
                                inputClasses={inputClasses}
                                defaultValue={destinationSpeed || ""}
                                name={name}
                                value={value || ""}
                                label="Speed"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    e.target.value === undefined
                                  ) {
                                    setDestinationSpeed("");
                                  } else {
                                    setDestinationSpeed(Number(e.target.value));
                                  }
                                  onChange(e.target.value);
                                }}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {schedules.length > 0 && (
                    <Grid
                      container
                      item
                      xs
                    // pl={3}
                    // pr={3}
                    >
                      <Box width={"100%"} md={12} pt={2} pl={3} pr={3} borderLeft={"0.1px solid #E8E8E8"}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            paddingBottom: "15px",

                          }}
                        >
                          Schedule Time of Arrival*
                        </Typography>

                        <Grid
                          item
                          container
                          md={12}
                          mb={2}
                          display={"flex"}
                          sx={{ justifyContent: "space-between" }}
                        >
                          <Grid item md={5.8}>
                            <Box>
                              <Controller
                                name={`scheduleArrivalDay${tabValue}`}
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Please add Day",
                                  },
                                }}
                                render={({
                                  field: { onChange, value, name },
                                }) => {
                                  return (
                                    <CustomSelect
                                      selectStylesOverride={selectStylesOverride}
                                      // defaultValue={
                                      //   dayOptions.find(
                                      //     (option) =>
                                      //       option?.value ===
                                      //       schedules[tabValue]?.dayArrival
                                      //   ) || null
                                      // }
                                      value={
                                        schedules[tabValue] &&
                                          schedules[tabValue]?.dayArrival
                                          ? dayOptions.find(
                                            (option) =>
                                              option?.value ===
                                              schedules[tabValue]?.dayArrival
                                          ) ??
                                          dayOptions?.find(
                                            (option) =>
                                              option?.value === "custom"
                                          )
                                          : null
                                      }
                                      onChange={(e, newVal) => {
                                        if (newVal?.value !== "custom") {
                                          const updatedSchedules = [...schedules]; // Create a copy of the original array
                                          const scheduleIndex =
                                            updatedSchedules.findIndex(
                                              (schedule) =>
                                                schedule.key === tabValue
                                            );

                                          if (scheduleIndex !== -1) {
                                            updatedSchedules[scheduleIndex][
                                              "dayArrival"
                                            ] = newVal?.value;
                                          }
                                        }

                                        const scheduleArrivalDay = {
                                          [`scheduleArrivalDay${tabValue}`]:
                                            newVal?.value,
                                        };
                                        setScheduleDestinationCustomDay(
                                          (prev) => ({
                                            ...prev,
                                            ...scheduleArrivalDay,
                                          })
                                        );
                                        onChange(newVal);
                                      }}
                                      name={name}
                                      required
                                      label="Day"
                                      errors={errors}
                                      getOptionLabel={(option) => {
                                        return option?.label;
                                      }}
                                      filterSelectedOptions
                                      options={dayOptions}
                                    />
                                  );
                                }}
                              />
                            </Box>
                          </Grid>
                          {scheduleDestinationCustomDay[
                            `scheduleArrivalDay${tabValue}`
                          ] !== "custom" && (
                              <Grid item md={5.8}>
                                <Controller
                                  name={`scheduleArrivalTime${tabValue}`}
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Time is required",
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value, name },
                                  }) => {
                                    return (
                                      <CustomTimeField
                                        label="Time"
                                        size="small"
                                        name={name}
                                        errors={errors}
                                        selectStylesOverride={selectStylesOverride}
                                        value={
                                          schedules[tabValue] &&
                                            schedules[tabValue]?.STA
                                            ? convertHHMMToDate(
                                              schedules[tabValue]?.STA
                                            )
                                            : null
                                        }
                                        onChange={(time) => {
                                          if (
                                            new Date(time).toString() !==
                                            "Invalid Date"
                                          ) {
                                            const formattedTime = format(
                                              time,
                                              "HH:mm"
                                            );

                                            const updatedSchedules = [...schedules]; // Create a copy of the original array
                                            const scheduleIndex =
                                              updatedSchedules.findIndex(
                                                (schedule) =>
                                                  schedule.key === tabValue
                                              );

                                            if (scheduleIndex !== -1) {
                                              updatedSchedules[scheduleIndex][
                                                "STA"
                                              ] = formattedTime;
                                            }
                                          }
                                          onChange(time);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            )}
                        </Grid>

                        {scheduleDestinationCustomDay[
                          `scheduleArrivalDay${tabValue}`
                        ] === "custom" && (
                            <Grid
                              item
                              container
                              md={12}
                              display={"flex"}
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Grid item md={5.8} pb={1}>
                                <Box pb={2}>
                                  <Controller
                                    name={`scheduleCustomArrivalDay${tabValue}`}
                                    control={control}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: "Please add Day",
                                      },
                                    }}
                                    render={({
                                      field: { onChange, value, name },
                                    }) => {
                                      return (
                                        <CustomInput
                                          placeholder="Select Day"
                                          selectStylesOverride={
                                            selectStylesOverride
                                          }
                                          inputClasses={inputClasses}
                                          name={name}
                                          value={value || ""}
                                          label="Select Day"
                                          onChange={(e) => {
                                            const updatedSchedules = [...schedules]; // Create a copy of the original array
                                            const scheduleIndex =
                                              updatedSchedules.findIndex(
                                                (schedule) =>
                                                  schedule.key === tabValue
                                              );

                                            if (scheduleIndex !== -1) {
                                              updatedSchedules[scheduleIndex][
                                                "dayArrival"
                                              ] = e.target.value;
                                            }
                                            onChange(e.target.value);
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                  <FormHelperText style={{ fontSize: '11px' }}>
                                    Day value should be more than <b>2</b>.
                                  </FormHelperText>
                                </Box>
                              </Grid>
                              <Grid item md={5.8} pb={1}>
                                <Controller
                                  name={`scheduleArrivalTime${tabValue}`}
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Time is required",
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value, name },
                                  }) => {
                                    return (
                                      <CustomTimeField
                                        label="Time"
                                        size="small"
                                        name={name}
                                        errors={errors}
                                        selectStylesOverride={selectStylesOverride}
                                        value={
                                          schedules[tabValue] &&
                                            schedules[tabValue]?.STA
                                            ? convertHHMMToDate(
                                              schedules[tabValue]?.STA
                                            )
                                            : null
                                        }
                                        onChange={(time) => {
                                          if (
                                            new Date(time).toString() !==
                                            "Invalid Date"
                                          ) {
                                            const formattedTime = format(
                                              time,
                                              "HH:mm"
                                            );

                                            const updatedSchedules = [...schedules]; // Create a copy of the original array
                                            const scheduleIndex =
                                              updatedSchedules.findIndex(
                                                (schedule) =>
                                                  schedule.key === tabValue
                                              );

                                            if (scheduleIndex !== -1) {
                                              updatedSchedules[scheduleIndex][
                                                "STA"
                                              ] = formattedTime;
                                            }
                                          }
                                          onChange(time);
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>
        <Grid
          item
          display="flex"
          alignItems="center"
          position={"relative"}
          sx={{ width: "100%", position: 'sticky', height: '55px', bottom: '0px', zIndex: '11' }}
        >
          <Box display={"flex"} gap={"8px"}>
            {!isEditRoute && (
              <Box key={0} display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={addMore}
                  onChange={handleAddMore}
                  sx={{
                    "&, &.Mui-checked": { color: themeType.default.themeOrange },
                  }}
                />
                <Typography sx={{ ...textClasses.normalText }}>
                  Add More Route
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            display={"flex"}
            gap={"8px"}
            justifyContent={"center"}
            sx={{ width: isEditRoute ? "100%" : "80%" }}
          >
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitBlackFill,
                minHeight: "32px",
                minWidth: "110px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {userPermissions &&
              havePermission(userPermissions, "routes", "createRoutes") ? (
              !isEditRoute ? (
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "32px",
                    minWidth: "110px",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </Button>
              ) : null
            ) : null}

            {userPermissions &&
              havePermission(userPermissions, "routes", "editRoutes") ? (
              isEditRoute ? (
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "32px",
                    minWidth: "110px",
                  }}
                  onClick={handleSubmit(handleEdit)}
                >
                  Update
                </Button>
              ) : null
            ) : null}
          </Box>
        </Grid>
      </Grid>


















    </>
  );
};

export default RouteForm;
