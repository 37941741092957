export const templateTypes = [
    {
        name: "Inward Data Entry",
        value: "inwardDataEntry",
        description: "Includes set of information, used to describe a gate-in of particular shipment, package or items."
    },
    {
        name: "Outward Data Entry",
        value: "outwardDataEntry",
        description: "Includes set of information, used to describe dispatch process."
    },
    {
        name: "Entity Master",
        value: "entityMaster",
        description: "An Entity Master is a collection of records about inventories/ items , customers(buyers), transporters,partners and sellers etc",
        subtypes: [
            {
                name: "Item Master",
                value: "itemMaster",
                description: "Includes set of information, used to describe your master inventories (SKUs, Master Box etc.) This helps users on data entry automation."
            },
            {
                name: "Supplier Master",
                value: "supplierMaster",
                description: "Includes set of information of your all suppliers. Helps user to automate the Purchase Order Data Entry Automation."
            },
            {
                name: "Customer Master",
                value: "customerMaster",
                description: "Includes set of your all customers or buyer. Helps users on Sale Oder or Dispatch process data entry automation."
            }
        ]
    },
    {
        name: "In House Movement",
        value: "inHouseMovement", // key confirmation
        description: "Includes set of information, used to describe all internal movements like Stock Transfer,Quality check etc of an item , and/ or package(s)",
        subtypes: [
            {
                name: "Stock Transfer",
                value: "stockTransfer",
                description: "Includes set of information, to whom or where to transfer the Stock, Stock QTY to be transferred. You can also transfer stock without creating a template."
            },
            {
                name: "Quality Checks",
                value: "qualityCheck",
                description: "Includes Check list of quality control metrics, users want to capture at the time of Receiving, Picking & Packaging"
            },
            {
                name: "Inventory Adjustment",
                value: "inventoryAdjustment",
                description: "Inventory Adjustments can be used to adjust inventory in the store up or down, or mark items available or unavailable, for any reason"
            }
        ]

    },
    {
        name: "Other",
        value: "freeTemplate",
        description: "Create your own dynamic template (s) and link these with your defined workflow. you can access the same while performing a warehouse operation"
    }
]
export const statusFields = [
    { label: "Active", value: 'ACTIVE' },
    { label: "In-Active", value: 'INACTIVE' },
]

export const efixedclosureTypes = [
    { label: "Arrival At Geofence", value: 'arrivalAtGeofence' },
    { label: "Unlocking At Geofence", value: 'unlockAtGeofence' },
    { label: "First Unlocking (In/Out Geofence)", value: 'firstUnlock' },
    { label: "Forcefully Closure", value: 'onlyForcefullyClosure' },
]
export const otherClsoureTypes = [
    { label: "Arrival At Geofence", value: 'arrivalAtGeofence' },
    { label: "Forcefully Closure", value: 'onlyForcefullyClosure' },
]

export const statusTypes = [
    { label: "Published", value: 'published' },
    { label: "Disabled", value: 'disabled' }, 
]

export const currentStatus = [
    "Allocated",
    "Not Allocated"
]
export const staticOptionDriver = {
    name: "Add Driver",
    _id: 'addDriver',
    "mobile": "1324356",
    "license_no": "12@",

};
export const fileTypes = ["image/png", "image/jpg", "image/jpeg", "image/doc", "application/pdf"]
export const getTemplateTypeName = (templateType) => {
    switch (templateType) {
        case "user": return "Users";
        default: return "Other"
    }
}

export const getSectionHelperText = (templateType) => {
    switch (templateType) {
        case "user": return "* add all data fields you want to capture."
        case "device": return "* add all data fields you want to capture."
        case "route": return "* add all data fields you want to capture."
        case "geofence": return "* add all data fields you want to capture."
        case "trip": return "* add all data fields you want to capture."
        case "freeTemplate": return "Other"
        default: return "Other"
    }
}
export const MapBoxAccessToken =
    "pk.eyJ1IjoieW9naWU2NCIsImEiOiJjbHFkb2M4OHMwYm05MnFtZXhrZ3lraGw3In0.WzwvOmkRqFGugMUP74Kh-w";

export const GoogleMapsAPIKey = 'AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A';

export const borderColorLists1 = [
    '#ff006f',
    '#fbbc04',
    '#34a853',
    '#ff6d01',
    '#b7b7b7'
]

export const borderColorLists2 = [
    "#000000",
    "#f1c232",
    "#6aa84f",
    "#ea4335",

]
export const borderColorLists3 = [
    '#6aa84f',
    '#b7b7b7',
    '#ff6d01',
    '#ea4335',
    '#34a853',
]

export const graphColors = {
    black: "#000000",
    yellow: "#f1c232", 
    dullGreen: "#6aa84f",
    gray: "#b7b7b7",
    orange: "#ff6d01",
    red: "#ea4335",
    brightGreen: "#34a853",
    pink: "#ff006f",
    grey: "#989898",
    blue: "#03A9F4",
}

export const trackingLinksOptions = [
    { label: "Valid upto selected date & time", value: 'datetime' },
    { label: "Valid upto selected duration", value: 'duration' },
    { label: "Valid upto selected address", value: 'geofence' }
]
export const tripShareNotes = (shareType) => { 
    switch (shareType) {
        case "datetime": return "Tracking link will be valid till the selected date time."
        case "duration": return "Tracking link will be valid till the selected time duration."
        case "geofence": return "Tracking link will be valid till vehicle reached the selected geofence."
        default: return ""
    }
}

export const darkStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  export const reportTypes = [
    {label : 'Current Status Report' , value : 'currentStatus'},
    {label : 'Lock/Unlock event Report' , value : 'lockUnlock'},
    {label : 'Trip Event Report' , value : 'tripEvent'},

  ]


export const GoogleMapsUrl = 'https://maps.googleapis.com/maps/api/js'; 
export const CIPHER_SECRET = 'j7k9h3e5g1m2n8p4q6r0s9t2u5v1w3x7'

export const alarmData = [
  {
      "code": "126",
      "type": "No Driver"
  },
  {
      "code": "132",
      "type": "Driver authentication failed"
  },
  {
      "code": "121",
      "type": "Eyes closing"
  },
  {
      "code": "1",
      "type": "Video Lost"
  },
  {
      "code": "31",
      "type": "Acc on"
  },
  {
      "code": "0",
      "type": "UnKnown"
  },
  {
      "code": "2",
      "type": "Motion detection"
  },
  {
      "code": "3",
      "type": "Video overshadowed"
  },
  {
      "code": "4",
      "type": "Alarm input triggered"
  },
  {
      "code": "5",
      "type": "Alarm of emergency"
  },
  {
      "code": "6",
      "type": "Alarm of low speed"
  },
  {
      "code": "7",
      "type": "Alarm of high speed"
  },
  {
      "code": "8",
      "type": "Alarm of low temperature"
  },
  {
      "code": "9",
      "type": "Alarm of high temperature"
  },
  {
      "code": "10",
      "type": "Alarm of humidity"
  },
  {
      "code": "11",
      "type": "Park Overtime"
  },
  {
      "code": "12",
      "type": "Alarm of vibratin"
  },
  {
      "code": "13",
      "type": "Electronic fence"
  },
  {
      "code": "14",
      "type": "Electronic route"
  },
  {
      "code": "15",
      "type": "Alarm of open or close door"
  },
  {
      "code": "16",
      "type": "Storage abnormal"
  },
  {
      "code": "17",
      "type": "Fatigue driving"
  },
  {
      "code": "18",
      "type": "Exceptional volume of gas"
  },
  {
      "code": "19",
      "type": "Illegal ignition"
  },
  {
      "code": "20",
      "type": "Location module abnormal"
  },
  {
      "code": "21",
      "type": "Front panel open"
  },
  {
      "code": "22",
      "type": "RFID tagged"
  },
  {
      "code": "23",
      "type": "IBUTTON"
  },
  {
      "code": "24",
      "type": "Rapid acceleration"
  },
  {
      "code": "25",
      "type": "Rapid deceleration"
  },
  {
      "code": "26",
      "type": "Low speed pre-alarm"
  },
  {
      "code": "27",
      "type": "High speed pre-alarm"
  },
  {
      "code": "28",
      "type": "Voltage Alarm"
  },
  {
      "code": "29",
      "type": "Population statistics"
  },
  {
      "code": "30",
      "type": "DMS/ADAS alarm (Active safety alarm)"
  },
  {
      "code": "31",
      "type": "Acc on"
  },
  {
      "code": "32",
      "type": "Idle speed"
  },
  {
      "code": "33",
      "type": "Gps antenna disconnected"
  },
  {
      "code": "34",
      "type": "Gps antenna short circuit"
  },
  {
      "code": "35",
      "type": "IO output"
  },
  {
      "code": "36",
      "type": "CANBus connection abnormal"
  },
  {
      "code": "37",
      "type": "Towing alarm"
  },
  {
      "code": "38",
      "type": "Glide in neutral"
  },
  {
      "code": "39",
      "type": "RPM too high 40 vehicle move"
  },
  {
      "code": "40",
      "type": "vehicle move"
  },
  {
      "code": "41",
      "type": "Trip start (st/et/dtu time is the same)"
  },
  {
      "code": "42",
      "type": "In trip"
  },
  {
      "code": "43",
      "type": "End of the trip (reported periodically after acc off)"
  },
  {
      "code": "44",
      "type": "GPS location recovery"
  },
  {
      "code": "45",
      "type": "Abnormal video"
  },
  {
      "code": "768",
      "type": "End of trip notification"
  },
  {
      "code": "769",
      "type": "Upgrade notfication"
  },
  {
      "code": "101",
      "type": "Forward collision warning"
  },
  {
      "code": "102",
      "type": "Lane departure warning"
  },
  {
      "code": "103",
      "type": "Front vehicle distance is too close warning"
  },
  {
      "code": "104",
      "type": "Pedestrian collision warning Howen VSS Web API"
  },
  {
      "code": "105",
      "type": "Frequent lane change alarm"
  },
  {
      "code": "106",
      "type": "Road marking violation alarm"
  },
  {
      "code": "107",
      "type": "Harsh acceleration"
  },
  {
      "code": "108",
      "type": "Harsh breaking"
  },
  {
      "code": "109",
      "type": "Road sign recognition incident"
  },
  {
      "code": "110",
      "type": "FCW Forward relative velocity collision"
  },
  {
      "code": "111",
      "type": "HMW Forward absolute velocity collision"
  },
  {
      "code": "112",
      "type": "LDW_L Left lane departure"
  },
  {
      "code": "113",
      "type": "LDW_R Right lane departure"
  },
  {
      "code": "114",
      "type": "VB Low-speed forward collision alarm"
  },
  {
      "code": "115",
      "type": "Fatigue driving alarm"
  },
  {
      "code": "116",
      "type": "Calling Alarm"
  },
  {
      "code": "117",
      "type": "Smoking alarm"
  },
  {
      "code": "118",
      "type": "Distracted driving alarm"
  },
  {
      "code": "119",
      "type": "Driver abnormal alarm"
  },
  {
      "code": "120",
      "type": "Driver change event"
  },
  {
      "code": "122",
      "type": "Yawning"
  },
  {
      "code": "123",
      "type": "Camera cover alarm"
  },
  {
      "code": "124",
      "type": "Glance right and left"
  },
  {
      "code": "125",
      "type": "Not wearing a seat belt"
  },
  {
      "code": "126",
      "type": "No driver"
  },
  {
      "code": "127",
      "type": "Drinking water"
  },
  {
      "code": "128",
      "type": "Driver changing"
  },
  {
      "code": "129",
      "type": "Driver returns"
  },
  {
      "code": "130",
      "type": "Infrared sunglasses"
  },
  {
      "code": "131",
      "type": "Driver authentication succeeded"
  },
  {
      "code": "133",
      "type": "No face detected"
  },
  {
      "code": "200",
      "type": "Infrared sunglasses"
  },
  {
      "code": "201",
      "type": "Leave the fence"
  },
  {
      "code": "202",
      "type": "Overspeed alarm in fence"
  },
  {
      "code": "203",
      "type": "Overspeed warning inside the fence"
  },
  {
      "code": "204",
      "type": "Low speed alarm in fence"
  },
  {
      "code": "205",
      "type": "Low speed warning inside the fence"
  },
  {
      "code": "207",
      "type": "Engine on in fence"
  },
  {
      "code": "208",
      "type": "Engine off in fence"
  },
  {
      "code": "209",
      "type": "Timeout inside the fence"
  },
  {
      "code": "210",
      "type": "Refuel"
  },
  {
      "code": "211",
      "type": "Fuel Theft"
  },
  {
      "code": "212",
      "type": "Rapid acceleration (gsebsor)"
  },
  {
      "code": "213",
      "type": "Rapid deceleration (gsebsor)"
  },
  {
      "code": "214",
      "type": "Sharp turn (gsebsor)"
  },
  {
      "code": "215",
      "type": "entry fence warning"
  },
  {
      "code": "216",
      "type": "Leave the fence warning"
  },
  {
      "code": "10001",
      "type": "Insufficient disk space on server"
  },
  {
      "code": "11001",
      "type": "Excessive device traffic"
  },
  {
      "code": "11003",
      "type": "The device is offline for a long time"
  },
  {
      "code": "121",
      "type": "Eyes closed"
  },
  {
      "code": "123",
      "type": "DMS Camera Covered"
  },
  {
      "code": "122",
      "type": "Yawning"
  },
  {
      "code": "117",
      "type": "Smoking alarm"
  },
  {
      "code": "124",
      "type": "Distracted Driving"
  },
  {
      "code": "116",
      "type": "Phone call alarm"
  },
  {
      "code": "132",
      "type": "Driver authentication failed"
  },
  {
      "code": "118",
      "type": "Distracted driving alarm"
  },
  {
      "code": "119",
      "type": "Driver abnormal alarm"
  },
  {
      "code": "120",
      "type": "Driver change event"
  },
  {
      "code": "125",
      "type": "Not wearing a seat belt"
  },
  {
      "code": "115",
      "type": "Fatigue driving alarm"
  },
]
