import { Paper, TablePagination } from "@mui/material";
import React from "react";
//import theme, { textClasses } from "../../../../static/styles/theme";

const DataTable = ({
  tableColumns,
  data,
  totalRecords,
  pagination,
  setPagination,
  pageFilterKey = "pageNumber",
  onRowChange = () => {},
  themeType,
  dataTestid,
  activeIndex
}) => {
  const { textClasses, tableClasses } = themeType;
  return (
    <Paper
      className="muitable"
      sx={{
        maxWidth: "100%",
        height: "100%",
        overflowX: "auto",
        background: tableClasses.dark.backgroundColor,
      }}
      elevation={0}
    >
      {tableColumns && data && (
        <table style={{ ...tableClasses.table }}>
          <thead
            style={{
              backgroundColor: themeType?.default?.themeGray,
              position: "sticky",
              top: "-1px",
              zIndex: 2,
            }}
          >
            <tr className="roundedRow">
              {tableColumns?.map((column, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      ...textClasses.boldText,
                      ...tableClasses.tableHeader,
                      textAlign: "center",
                    }}
                  >
                    {column?.header}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody >
            {data?.length > 0 ? (
              <>
                {data?.map((entry, key) => (
                  <tr
                    onClick={() => onRowChange(entry)}
                    key={key}
                    style={{ borderBottom: "1px solid #0000002B" }}
                  >
                    {tableColumns?.map((column, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                          padding: "8px 8px",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          background: activeIndex === key ? '#E8E8E8' : null
                        }}
                      >
                        {column?.header === "#" || column?.header === "Action"
                          ? column?.cell(key)
                          : column?.cell(entry, key)}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td
                  component="th"
                  scope="row"
                  style={{
                    ...textClasses.normalText,
                    ...tableClasses.dark,
                    textAlign: "left",
                    overflowWrap: "break-word",
                  }}
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {pagination && (
        <TablePagination
          component="div"
          count={totalRecords}
          page={pagination[pageFilterKey] - 1}
          onPageChange={(e, newPage) => {
            setPagination((v) => ({ ...v, [pageFilterKey]: newPage + 1 }));
          }}
          rowsPerPage={pagination?.limit}
          onRowsPerPageChange={(e) => {
            setPagination((v) => ({
              ...v,
              [pageFilterKey]: 1,
              limit: e.target.value,
            }));
          }}
          sx={{
            ...tableClasses.tableHeader,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
        />
      )}
    </Paper>
  );
};

export default DataTable;
