import { RestartAlt, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

const CustomMultiSelect = ({
  name,
  label,
  options = [],
  value,
  extraClasses,
  required = false,
  onChange,
  disabled,
  allSelected,
  renderOptions,
  errors = {},
  selectStylesOverride,
  handleToggleSelectAll,
  getOptionLabel,
  resetSearch = false,
  searchValue = null,
  onSearch = () => {},
  onResetSearch = () => {},
  labelKey = "label",
  ExtraCheckbox = () => {},
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ height: "max-content" }}>
      <Autocomplete
        {...props}
        value={value}
        size="small"
        options={options}
        filterOptions={searchValue ? (options) => options : undefined}
        multiple
        //sx={{ height: "1.1rem" }}
        onChange={onChange}
        disabled={disabled}
        disableCloseOnSelect={true}
        getOptionLabel={getOptionLabel}
        clearOnBlur={searchValue && false}
        error={errors[name] ? true : false}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            sx={{
              fontSize: "12px",
              ...selectStylesOverride,
              ...extraClasses,
            }}
            {...params}
            label={
              label ? (
                <Typography sx={{ fontSize: "12px" }}>
                  {label}
                  {required && "*"}
                </Typography>
              ) : null
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searchValue && searchValue !== null ? (
                    <Tooltip placement="top" title="Search">
                      <IconButton
                        size="small"
                        color=""
                        onClick={onSearch ? () => onSearch() : null}
                      >
                        <Search fontSize="12px" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {resetSearch ? (
                    <Tooltip placement="top" title="Reset Search">
                      <IconButton
                        size="small"
                        color=""
                        onClick={onResetSearch ? () => onResetSearch() : null}
                      >
                        <RestartAlt fontSize="12px" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              disableUnderline: true,
            }}
          />
        )}
        renderOption={renderOptions ? renderOptions : (props, option, { selected }) => {
          return (
            <MenuItem value={value || ""} selected={selected} {...props}>
              <Typography
                sx={{
                  fontSize: ".8rem",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {option[labelKey] || ""}
              </Typography>
            </MenuItem>
          );
        }}
        PaperComponent={
          handleToggleSelectAll
            ? (paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                  <Paper {...restPaperProps}>
                    <Box
                      onMouseDown={(e) => e.preventDefault()} // prevent blur
                      pl={1.5}
                      py={0.5}
                      display="flex"
                      gap={2}
                    >
                      <FormControlLabel
                        sx={{
                          color: "rgb(128, 128, 128)",
                          "& .MuiFormControlLabel-label": { fontSize: "13px" },
                        }}
                        label="Select all"
                        control={
                          <Checkbox
                            id="select-all-checkbox"
                            sx={{
                              color: "#ff7200",
                              "&.Mui-checked": {
                                color: "#ff7200",
                              },
                            }}
                            checked={allSelected}
                            onClick={(e) => {
                              e.preventDefault(); // prevent blur
                              handleToggleSelectAll(e);
                            }}
                          />
                        }
                      />

                      {ExtraCheckbox()}
                    </Box>
                    <Divider />
                    {children}
                  </Paper>
                );
              }
            : undefined
        }
      />
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};

export default CustomMultiSelect;
