/* eslint-disable */
import { Box, styled, Grid, Typography, Button, Breadcrumbs } from "@mui/material";
import React, { useState, useEffect } from "react";
import TripMapDrawer from "./components/TripMapDrawer";
import DrawerFloaterLabel from "../../Components/Drawer/DrawerFloaterLabel";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "@mui/icons-material";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom";
import { getTripDataByTripId, tripGeofenceWithRoute, tripGeofenceWithoutRoute, emptyTripObj, emptyTripData, getTripEvents } from "../../../redux/actions";
import routeIcon from "../../../assets/images/routeIcon.png"
import speed from "../../../assets/images/speed.png"
import distance from "../../../assets/images/distance.png"
import dateTime from "../../../assets/images/dateandtime.png"
import location from "../../../assets/infoWindow/location.png"
import truck from "../../../assets/infoWindow/truck.png"
import battery from "../../../assets/infoWindow/low-battery.png"
import tag from "../../../assets/infoWindow/tag.png"
import lock from "../../../assets/infoWindow/lock.png"
import unlock from "../../../assets/infoWindow/unlock.png"
import { darkStyles } from "../../../services/constants";
import { capitalizeSentence } from "../../../services/functions";
import { URLFORTRIPMAP } from "../../../services/Api";
import axios from 'axios';
import { locationUrl, baseURL } from "../../../services/config";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { convertIntoGivenTimezone } from "../../../services/functions";
import livepositionMarker from "../../../assets/marker/marker.png"
const TripMap = (props) => {
  var infoWindows = []

  const dispatch = useDispatch()
  let urlIcon = 'https://lynktrac.io/lynktracV2/'
  const [openDrawer, setOpenDrawer] = useState(true);
  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
  const tripDetails = useSelector((state) => state.tripMap.tripDataById);
  const geofencesWithRoute = useSelector((state) => state.tripMap?.tripGeofenceWithRoute?.geoData || {});
  const geofencesWithoutRoute = useSelector((state) => state.tripMap?.tripGeofenceWithoutRoute?.geoData || {});
  const tripEvents = useSelector((state) => state.tripMap?.tripEvents || {});
  const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/darktheme") : require("../../../static/styles/theme");
  const history = useHistory();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapLoader, setMapLoader] = useState(false)
  const [myMap, setMymap] = useState(null)
  const [mapCenter, setMapCenter] = useState({ lat: 28.6139, lng: 77.209 })
  const [idParam, setIdParam] = useState(null);
  const [tripParam, setTripParam] = useState(null);
  const [routeObj, setRouteObj] = useState({})
  const mapElement = document.getElementById("tripmap") || '';
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  var map;
  var marker;

  const {textClasses} = themeType;

  if (mapLoaded) {
    // var markerinfoWindow = new google.maps.InfoWindow();
    // var newInfoWindow = new google.maps.InfoWindow();
  }
  const [infoWindow, setInfoWindow] = useState(null);
  const [newInfoWindow, setNewInfoWindow] = useState(window.google && window.google.maps.Map ? new google.maps.InfoWindow() : '');
  useEffect(() => {
    const currentUrl = window.location.href;
    const path = new URL(currentUrl).pathname;
    const tripId = path.split('/trip-map/')[1];
    const urlParamsforId = new URLSearchParams(currentUrl.split('?')[1]);
    const id = urlParamsforId.get('id');

    setTripParam(tripId)
    setIdParam(id)
    setRouteObj({})
    setMymap(null)
  }, [window.location.href]);


  useEffect(() => {
    if (myMap) {
      const mapStyles = isDarkThemeEnabledSelector ? darkStyles : null;
      myMap.setOptions({ styles: mapStyles });
    }
  }, [isDarkThemeEnabledSelector, myMap])



  useEffect(() => {
    if (mapLoaded && !myMap) {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        return null;
      }
      setMapLoader(true)
      if (!mapElement) {
        return null;
      }
      if (!mapCenter.lat || !mapCenter.lng) {
        // console.log("Latitude or longitude not provided.");
        return null;
      }

      const mapOptions = {
        zoom: 15,
        center: { lat: parseFloat(mapCenter?.lat), lng: parseFloat(mapCenter?.lng) },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };
      map = new google.maps.Map(mapElement, mapOptions);
      setMymap(map);
      return map;
    };
  }, [mapLoaded, mapElement, myMap]);

  useEffect(() => {
    if (tripParam && idParam) {
      let body = {
        trip_id: tripParam,
        deviceId: idParam
      }
      dispatch(getTripDataByTripId(body));
    }
  }, [tripParam, idParam])

  useEffect(() => {
    if (tripDetails && Object.keys(tripDetails).length) {
      let currentTime = new Date().getTime();
      let tripEventBody = {
        tripId: tripDetails?.tripData?.tripDetails?.tripId || '',
        fromDate: tripDetails?.tripData?.detailsDeviceID?.time ? tripDetails?.tripData?.detailsDeviceID?.time : tripDetails?.tripData?.addedOnEpoch,
        toDate: tripDetails?.tripData?.status === 'completed' ? tripDetails?.tripData?.endTime : currentTime,
        deviceId: idParam,
        destinationId: tripDetails?.tripData?.route?.routeDestination?._id || ''
      }
      dispatch(getTripEvents(tripEventBody))
    }
  }, [tripDetails])



  const bgColor = (data) => {
    let lockStatus = data?.lockStatus || ''
    let color = '';
    if (lockStatus === 'locked') {
      color = 'green';
    }
    else if (lockStatus === 'unlocked') {
      color = '#D2042D';
    }
    else if (lockStatus === 'tampered') {
      color = '#D2042D';
    }
    else if (lockStatus === 'motorUnlocked') {
      color = '#FFAA1D';
    }

    return { color };
  }

  useEffect(() => {
    if (myMap && Object.keys(tripDetails).length) {
      setTimeout(() => {
        myMap?.data?.loadGeoJson(`${URLFORTRIPMAP}/getTripMapTrack?trip_Id=${tripParam}&deviceId=${idParam}`, null, (feature) => {
          myMap.data.setStyle((feature) => {
            let color = 'gray';
            let icon = {};
            if (feature.getProperty('icon')) {
              let iconSize = feature.getProperty('size') ? feature.getProperty('size') : 20;
              let iconImage;
              const featureIcon = feature.getProperty('icon');
              const trackingIconsCheck = "/img/trackingicons";

              if (featureIcon === 'Sealed') {
                iconImage = `${urlIcon}trip-icons/start.png`;
              }
              else if (featureIcon === 'Begin_Transit_Journey') {
                iconImage = `${urlIcon}trip-icons/maps.png`;
              }
              else if (featureIcon && featureIcon.includes(trackingIconsCheck)) {
                const splitArray = featureIcon.split(trackingIconsCheck);
                const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                iconImage = `${urlIcon}trip-icons${iconName}`;
              }
              else {
                const splitArray = featureIcon ? featureIcon.split("/img") : [];
                const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                iconImage = `${urlIcon}trip-icons${iconName}`;
              }
              icon = {
                url: iconImage,
                scaledSize: new google.maps.Size(iconSize, iconSize),
              };
            }
            if (feature.getProperty('isColorful')) {
              color = feature.getProperty('color');
            }
            return {
              strokeColor: color,
              strokeWeight: 2,
              icon,
            };
          });
        });
        myMap.data.addListener('click', async (event) => {
          let markerObj = {
            deviceId: idParam,
            packet_id: event.feature.getProperty('packet_id'),
            status: tripDetails?.tripData?.tripDetails?.status || '',
            eventAfterTampered: event.feature.getProperty("icon") === 'assets/img/trackingicons/maps_red.png' ? true : false,
            icon: event.feature.getProperty("icon"),
            gps: tripDetails?.tripData?.sealingDetails?.gps || '',
            offlineLimit: tripDetails?.tripData?.limitThreshold?.offlineLimit || '',
          }
          await axios.post(`${URLFORTRIPMAP}/getTripMapPacketData`, { ...markerObj }, {
            headers: {
              Authorization: JSON.parse(localStorage.getItem('token'))
            }
          })
            .then(response => {
              let data = response?.data?.trackData
              const lockStatus = data?.lockStatus || '-';
              const batteryPercent = data?.battery + '%' || '-';
              const tripStatus = data?.trip_status || '-';
              const speedVal = data?.speed.toFixed(2) + ' kmph' || '-';
              const distanceVal = data?.distance || '-';
              const time = convertIntoGivenTimezone(data?.packet_time, userDetails.timeZone.offset) || '-';
              const tripEventStatus = data?.event || null
              const activeDevice = data?.deviceType || "-"
              const color = data?.color === 'yellow' ? '#FFAA1D' : data?.color
              const backgroundColor = bgColor(data)
              const payload = {
                latitude: data?.latitude,
                longitude: data?.longitude,
                appType: "GOOGLE",
              };


              axios.post(`${locationUrl}fetchAddress`, payload, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
                },
              })
                .then(response => {
                  let address = response?.data?.data[0]?.address
                  let infoWindowContent = `<div class='infoDiv'>
                <div class='infoChild1'>
                
                
                ${tripEventStatus !== '-' ?
                      `<div class='subChild' style="background-color: ${color}" >
                      <img class='lockImg'  src=${truck} alt='' />
                      <span class='deviceLabel'>Trip Event </span>
                      <span class='devicevalue'>${tripEventStatus}</span>
                  </div>`
                      : ''
                    }
                    ${ lockStatus !== '-' ?
                      `<div class='subChild' style="background-color: ${backgroundColor?.color}">
                  <img class='lockImg'  src=${lockStatus === 'motorUnlocked' ? unlock : lock} alt='' />
                  <span class='deviceLabel'>Device Status </span>
                  <span class='devicevalue'>${lockStatus === 'motorUnlocked' ? 'Motor Unlocked' : capitalizeSentence(lockStatus)}</span>
                </div>  `
                      : ''
                    }
                <div class='subChild bgblue'>
                    <img class='lockImg'  src=${tag} alt='' />
                    <span class='deviceLabel'> Device Id </span>
                    <span class='devicevalue'> ${idParam}</span>
                </div>
              </div>
              <div class='infoChild2'>


              ${activeDevice !== "simTracking" ?
              `<div class='subChild2 '>
              <img class='imgCss'  src=${battery} alt='' />
              <span class='infoLabel label2'>Battery</span>
              <span class='infoValue infoValue2'>${batteryPercent}</span>
                </div>`
                :''}

        
                <div class='subChild2 '>
                  <img class='imgCss'  src=${routeIcon} alt='' />
                  <span class='infoLabel label2'>Trip Status</span>
                  <span class='infoValue infoValue2'>${tripStatus}</span>
                </div>
        
                  <div class='subChild2'>
                    <img class='imgCss'  src=${speed} alt='' />
                    <span class='infoLabel label2'>Speed </span>
                    <span class='infoValue infoValue2'>${speedVal}</span>
                  </div>
        
                <div class='subChild2'>
                    <img class='imgCss'  src=${distance} alt='' />
                    <span class='infoLabel label2'>Distance </span>
                    <span class='infoValue infoValue2'>${distanceVal}</span>
                </div>
        
                <div class='subChild2'>
                  <img class='imgCss'  src=${dateTime} alt='' />
                  <span class='infoLabel label2'>Date & Time</span>
                  <span class='infoValue infoValue2'>${time}</span>
                </div>
              </div>
        
              <div class='infoChild3'>
                <div class='addressDiv'>
                  <div class='addressImg'>
                  <img class='imgCss' src=${location} alt='' />
                  </div>
                  <div class='addressBox'>
                  <span class='infoLabel'>${address}</span>
                  </div>
                </div>
                
              </div>
              </div>
            `
                  let temp = newInfoWindow

                  if (infoWindow) {
                    infoWindow.close();
                  }

                  temp.setContent(infoWindowContent);
                  temp.setPosition(event.latLng);
                  temp.open(myMap);
                  setInfoWindow(temp);
                  setNewInfoWindow(temp)
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            }
            )
            .catch(error => {
              console.error('Error creating data:', error);
            });


        });


        tripDetails?.liveLocation?.forEach((location, index) => {
          marker = new window.google.maps.Marker({
            position: { lat: Number(location?.latitude), lng: Number(location?.longitude) },
            map: myMap,
            icon: livepositionMarker
          });
        });
      }, 50)
    }
  }, [myMap, idParam, tripDetails])




  useEffect(() => {
    if (Object.keys(tripDetails).length) {
      let routeFlag = tripDetails?.tripData?.isTripWithoutRoute
      if (!routeFlag) {
        let temp = []
        let sourceId = tripDetails?.tripData?.route.routeSource?._id || ''
        temp.push(sourceId)
        for (let i = 0; i < tripDetails?.tripData?.route?.viaPoints?.length; i++) {
          let viaPointId = tripDetails?.tripData?.route?.viaPoints[i]._id
          temp.push(viaPointId)
        }
        let destinationId = tripDetails?.tripData?.route?.routeDestination?._id || ''
        temp.push(destinationId)

        if (tripDetails?.tripData?.route?.geoOpsID.length) {
          tripDetails?.tripData?.route?.geoOpsID.forEach(id => {
            if (!temp.includes(id)) {
              temp.push(id);
            }
          });
        }
        // dispatch(tripGeofenceWithRoute({ geofenceIdArr: temp }))
        dispatch(tripGeofenceWithRoute({ geofenceIdArr: temp }, (res) => {
          if (res) {
            if (res.data?.geoData) {
              geofenceRenderer(res?.data?.geoData);
            }
          }
        })
        );
      }
      else if (routeFlag) {
        let body = {
          viewAllGeofences: tripDetails?.tripData?.tripDetails?.viewAllGeofences || '',
          deviceId: tripDetails?.tripData?.detailsDeviceID?.deviceID || '',
          trip_id: tripDetails?.tripData?._id
        }
        dispatch(tripGeofenceWithoutRoute(body))

        dispatch(tripGeofenceWithoutRoute(body, (res) => {
          if (res) {
            if (res.data?.geoData) {
              geofenceRenderer(res?.data?.geoData);
            }
          }
        })
        );

      }
    }
  }, [tripDetails])

  useEffect(() => {
    if (Object.keys(geofencesWithRoute).length) {
      setRouteObj(geofencesWithRoute)
    }
    else if (Object.keys(geofencesWithoutRoute).length) {
      setRouteObj(geofencesWithoutRoute)
    }
    else { }


  }, [geofencesWithRoute, geofencesWithoutRoute])

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };
  
  // In your React component
  useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (tripEvents && Object.keys(tripEvents).length && myMap) {
      const newMapCenter = new window.google.maps.LatLng(Number(tripEvents?.mapCenterLocation?.lat), Number(tripEvents?.mapCenterLocation?.lng));
      myMap.setCenter(newMapCenter);
    }
  }, [tripEvents, myMap])

  const geofenceRenderer = (geoJsonData) => {
    const outputJson = {
      type: "FeatureCollection",
      features: geoJsonData.map(location => ({
        type: "Feature",
        properties: location.features[0].properties,
        geometry: location.features[0].geometry
      }))
    };

    const geoJsonLayer = new google.maps.Data();
    geoJsonLayer.addGeoJson(outputJson);

    geoJsonLayer.setStyle(function (feature) {
      return {
        fillColor: feature.getProperty('fillColor'),
        strokeColor: feature.getProperty('fillColor'),
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.5,
      };
    });

    geoJsonLayer.setMap(myMap);
    geoJsonLayer.addListener('click', function (event) {
      let props = {
        name: event.feature.getProperty('name'),
        addedOn: convertIntoGivenTimezone(event.feature.getProperty('addedOn'), userDetails.timeZone.offset),
        addedBy: event.feature.getProperty('addedBy'),
        centerCoordinates: event.feature.getProperty('centerCoordinates'),
      }
      infoWindowRederer(props)
    });
  };

  const infoWindowRederer = (properties) => {
    const coordinates = properties.centerCoordinates[0];
    const infoWindowContent = `
    <div style="min-width:310px">
        <div> <span style="display: inline-block; width: 80px; color: #909090; ">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; f">${properties.name}</span></div><br>
        <div><span style="display: inline-block; width: 80px; color: #909090; ">Added By:</span><span style="color: #000; font-Weight: 500; "> ${properties?.addedBy?.name}</span><br><br>
        <div><span style="display: inline-block; width: 80px; color: #909090; ">Added On:</span><span style="color: #000; font-Weight: 500; "> ${properties?.addedOn}</span><br><br>
    </div>
    `;
    const infoWindow = createInfoWindow(infoWindowContent, {
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    myMap.panTo({ lat: Number(coordinates[0][1]), lng: Number(coordinates[0][0]) });
    infoWindows.forEach((window) => window.close());
    infoWindow.open(myMap);
    infoWindows.push(infoWindow);
  }

  function createInfoWindow(content, position) {
    return new google.maps.InfoWindow({
      content: content,
      position: position,
    });
  }


  return (
    <>
      <Grid className='parentModal' container item={true} xs={12} sx={{ height: '89vh', overflow: 'auto' }}>
        <Grid item sm={12} >

          <Box
            // sx={{ height: "calc(100vh - 90px)", display: "flex" }}
            sx={{ height: "100%", display: "flex" }}
            position="relative"
          >
            {!mapLoader ?
              <Grid
                container
                item
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                sx={{ p: 15 }}
              >
                <Grid item>
                  <img src={loaderGif} />
                </Grid>
              </Grid>
              :
              mapLoaded && window.google && window.google.maps.Map ?
              <Box width="100%">
                <Box height="58px">
                  <Typography sx={{ ...textClasses.cardTitle }}>
                    Trip Map
                  </Typography>
                  <Breadcrumbs data-testid="breadcrumbs">
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        fontSize: "12px",
                        color: themeType.default.themeOrange,
                      }}
                    >
                      <Link
                        style={{
                          color: themeType.default.themeOrange,
                          textDecoration: "none",
                        }}
                        data-testid="link-to-go-back"
                        to={{pathname: "/dashboard/trips", state: {from: "trip-map-page"}}}
                      >
                        Trips
                      </Link>
                    </Typography>

                    <Typography
                      sx={{ ...textClasses.normalText, fontSize: "12px" }}
                    >
                      Trip Map
                    </Typography>
                  </Breadcrumbs>
                </Box>
                <Box bgcolor="#DADADA" width="100%" height="calc(100% - 58px)">
                  <div id="tripmap" style={{ width: '100%', height: '100%' }}></div>
                </Box>
              </Box>
              :
              null
              
            }
            <TripMapDrawer
              open={openDrawer}
              themeType={themeType}
              history={history}
              details={tripDetails}
              tripEventsDetails={tripEvents}
              tripDetails={tripDetails}
              setTripParam={setTripParam}
              setIdParam={setIdParam}
              setMymap={setMymap}
              idParam={idParam}
              myMap={myMap}
              infoWindow={infoWindow}
              newInfoWindow={newInfoWindow}
              setInfoWindow={setInfoWindow}
              userDetails={userDetails}
            />
            <DrawerFloaterLabel
              show={openDrawer}
              setShow={setOpenDrawer}
              text="Details"
              Icon={<Map sx={{ fontSize: "20px", color: "#ffffff" }} />}
            />
          </Box>
        </Grid>

      </Grid>
    </>

  );
};

export default TripMap;
