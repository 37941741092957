import AxiosInatance from "axios";
import config from "./config";
import history from "./history";
import { showToast } from "./functions";
import CryptoJS from "crypto-js";
export const URLFORTRIPMAP = config.geoURL
let SECRET_KEY = 'j7k9h3e5g1m2n8p4q6r0s9t2u5v1w3x7'

export const axios = AxiosInatance.create({
  baseURL: config.baseURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
    // Authorization: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : undefined,
  },
});

// ------------- lyntrac v1
export const commonAxios = AxiosInatance.create({
  // baseURL: config.commonBaseURL || "/v1/",
  baseURL: "https://beta.lynktrac.io/",
  headers: {
    "Content-Type": "application/json",
    post: {
      accessKey: "ll4r7tw5_1001",
    },
  },
});

export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");

      if (tmp[0] === parameterName) {
        result = decodeURIComponent(tmp[1]);
      }
    });
  // console.log(
  // 	window.location.search,
  // 	`Parameter: ${parameterName} & Result : ${result}`
  // );
  // console.log(result);
  localStorage.setItem("accesskey", result);
  // if (parameterName === "accesskey") return "lbm54zji_9522";
  return result;
}

export const devicesAxios = AxiosInatance.create({
  baseURL: config.devicesBaseURL,
  headers: {
    "Content-Type": "application/json",
    //Authorization: JSON.parse(localStorage.getItem("token")),
  },
});

export const lynctracAxios = AxiosInatance.create({
  baseURL: config.lynctracBaseURL,
  headers: {
    "Content-Type": "application/json",
    //Authorization: JSON.parse(localStorage.getItem("token")),
  },
});

export const loginAxios = AxiosInatance.create({
  baseURL: config.loginURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const templateAxios = AxiosInatance.create({
  baseURL: config.baseURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : localStorage.getItem('token'),
  },
});
export const geoAxios = AxiosInatance.create({
  baseURL: config.geoURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
    Authorization: JSON.parse(localStorage.getItem('token')),
  },
});

export const reportAxios = AxiosInatance.create({
  baseURL: config.reportURL || "/v1/",
  headers: {
    "Content-Type": "application/json",
    Authorization: JSON.parse(localStorage.getItem('token')),
  },
});

const requestHandler = (request) => {   
  let hmacSignatureKey;
  console.log('requ',request)
  if (request.data) {
     hmacSignatureKey = wrapRequestBody(request.data);
  }
  request.headers.Authorization = JSON.parse(localStorage.getItem('token'));
  request.headers["x-hmac-signature"] = hmacSignatureKey ;

  return request;
};

function wrapRequestBody(payload) {
  const hmac = CryptoJS.HmacSHA256(JSON.stringify(payload), SECRET_KEY);
  const hmacSignature = hmac.toString(CryptoJS.enc.Hex);
  return hmacSignature

}

loginAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request) => requestHandler(request));

axios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    }

    return Promise.reject(error);
  }
);

// commonAxios.interceptors.request.use((request) => requestHandler(request));

commonAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    }

    return Promise.reject(error);
  }
);

devicesAxios.interceptors.request.use((request) => requestHandler(request));

devicesAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      history.push("/");
    }

    return Promise.reject(error);
  }
);

lynctracAxios.interceptors.request.use((request) => requestHandler(request));

lynctracAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      history.push("/");
    }

    return Promise.reject(error);
  }
);

geoAxios.interceptors.request.use((request) => requestHandler(request));

geoAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      history.push("/");
    }

    return Promise.reject(error);
  }
);
templateAxios.interceptors.request.use((request) => requestHandler(request));

templateAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      history.push("/");
    }

    return Promise.reject(error);
  }
);

reportAxios.interceptors.request.use((request) => requestHandler(request));

reportAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.statusCode === 204) {
      localStorage.removeItem("token");
      showToast("Session timeout", true);
      history.push("/");
    } else {
      return response;
    }
  },
  (error) => {
    // check if the error response indicates a session timeout
    if (error.response && error.response.status === 204) {
      // we will log the user out and show toast message for session timeout
      localStorage.removeItem("token");
      history.push("/");
    }

    return Promise.reject(error);
  }
);

export default axios;
