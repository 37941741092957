import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import DataTable from "../../../Components/DataTable/DataTable";
import EmptyPage from "../../EmptyPage";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AccountLinkage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionIndex, setActionIndex] = React.useState(null);

  const history = useHistory();

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;

  const apiData = [1, 2, 3];
  const loader = false;

  const tabs = [
    {
      id: 0,
      key: "all",
      name: "All",
    },
    {
      id: 1,
      key: "active",
      name: "Active",
    },
    {
      id: 2,
      key: "disabled",
      name: "Disabled",
    },
  ];

  const tableColumns = [
    {
      header: "#",
      cell: (i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {i + 1}
            {/* {(filter?.page - 1) * filter?.limit + i + 1} */}
          </Typography>
        );
      },
    },
    {
      header: "User Name",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.name ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Organization Name",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.orgName ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Account ID",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.id ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.type ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Action",
      cell: (index) => {
        return (
          <Typography
            data-testid="action-menu-btn"
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, index)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      },
    },
  ];

  /** Handlers ---- */
  const handleOpenAction = (event, key) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(key);
    // setTableIndex(key);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleTabsChange = (_, value) => {
    setSelectedTab(value);
  };

  return (
    <>
      <Grid container>
        {/* top row - page title and action button */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-testid="page-title"
        >
          <Typography sx={{ ...textClasses.cardTitle }}>
            Account Linkage
          </Typography>
          <Button
            variant="outlined"
            size="small"
            data-testid="add-new-button"
            onClick={() =>
              history.push("/dashboard/settings/account-linkage/add")
            }
            sx={{
              ...buttonClasses.small,
              minHeight: "36px",
              borderColor: themeType.default.themeOrange,
              color: buttonClasses.lynkitOrangeEmpty,
            }}
            startIcon={<Add />}
          >
            Add New
          </Button>
        </Grid>

        {/* Tabs ---- */}
        <Grid
          item
          xs={12}
          sx={{
            mt: 0,
            mb: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabsClass"
            aria-label="scrollable auto tabs example"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: window.innerWidth - 108,
              "& .MuiTabs-indicator": {
                backgroundColor: themeType.default.themeOrange,
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeType.default.themeOrange,
              },
            }}
          >
            {tabs?.map((item, i) => {
              return (
                <Tab
                  key={i}
                  label={`${item.name}`}
                  sx={{
                    ...textClasses.normalText,
                    textTransform: "capitalize",
                  }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                />
              );
            })}
          </Tabs>
          {/* Table and details section ----- */}
          {loader ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 15 }}
            >
              <Grid item>
                <img src={loaderGif} />
              </Grid>
            </Grid>
          ) : (
            <>
              {apiData && apiData?.length === 0 ? (
                <EmptyPage body="No accounts found" />
              ) : (
                <Grid item sm={12} mt={3}>
                  <Paper
                    sx={{
                      height: "100%",
                      width: "100%",
                      overflowX: "auto",
                      display: "block",
                    }}
                    data-testid="device-models-table"
                  >
                    <DataTable
                      tableColumns={tableColumns}
                      data={apiData ?? []}
                      // totalRecords={totalDeviceModels}
                      // pagination={filter}
                      // setPagination={setFilter}
                      // pageFilterKey="page"
                      themeType={themeType}
                    />
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          <MenuItem
            key={"edit"}
            onClick={() => {
              // setSelectedModel(tableData[tableIndex]);
              // setShowAddModal(true);
              handleCloseAction();
            }}
          >
            Edit
          </MenuItem>

          <MenuItem
            key="view"
            // onClick={() => handleRowChange(tableData[tableIndex])}
          >
            View
          </MenuItem>
          {/* <MenuItem
            key="active/block"
            onClick={() => {
              handleOpenModal(
                tableData[tableIndex]?.status?.toLowerCase() == "active"
                  ? "disable"
                  : "enable"
              );
              handleCloseAction();
            }}
          >
            {tableData[tableIndex]?.status?.toLowerCase() == "active"
              ? "Block"
              : "Active"}
          </MenuItem> */}
          <MenuItem
            key="delete"
            data-testid="delete-menu"
            onClick={() => {
              // handleOpenModal("delete");
              handleCloseAction();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default AccountLinkage;
