import React, { useEffect, useState } from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useDispatch, useSelector } from "react-redux";

const TripViaComponent = (props) => {
    const { viaModalArray, index } = props
    const [routeObject, setRouteObject] = useState({})
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../../static/styles/darktheme")
        : require("../../../../static/styles/theme");
    const {
        textClasses,
    } = themeType;


    useEffect(() => {
        if (viaModalArray && viaModalArray?.routeSchedule && Object.keys(viaModalArray.routeSchedule).length) {
            let temp = viaModalArray.routeSchedule;
            temp.viaPoints.forEach(point => {
                point.totalDistance = point.travelKm;
                point.etaTime = point.travelTime;
            });
            temp.routeDestination.totalDistance = temp?.routeDestination?.travelKm
            temp.routeDestination.etaTime = temp?.routeDestination?.travelTime
            setRouteObject(temp);
        } else {
            setRouteObject(viaModalArray && viaModalArray.route ? viaModalArray.route : {});
        }
    }, [viaModalArray]);

    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };
    }

   

    return (
        <div data-testid="running-status-container">
            {
                routeObject && Object.keys(routeObject)?.length ?
                    <div style={{ overflowX: 'hidden', marginTop: '8px', display: 'flex', marginTop: '2.5%' }}>
                        <div className="arrowBox">
                            <ArrowLeftIcon id={`slideLeft-${index}`} onClick={() => slide(index)} className="arrowClass" />
                        </div>
                        <div id={`container-${index}`} className="slidingContainer" style={{ paddingBottom: '18px' }} mt={5}>
                            <div className='pViaCont'>
                                <div className='pviaBox'>
                                    <div className='pAddress'>
                                        {routeObject?.routeSource?.name || '-'}
                                    </div>
                                    <div className='pTimeBox'>
                                        <div className='pSt'>
                                            <div className='pstLabelValue'>
                                                <span className='pstLabel' style={{ ...textClasses.boldText }}>STD :</span>
                                                <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{routeObject?.routeSource?.STD || '-'}</span>
                                            </div>
                                        </div>
                                        <div className='pSt'>
                                            <div className='pstLabelValue'>
                                                <span className='pstLabel' style={{ ...textClasses.boldText }}>ATD :</span>
                                                <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{routeObject?.routeSource?.ATD || '-'}</span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='pViaDots' style={{ border: isDarkThemeEnabledSelector ? '1px dashed #707070' : '1px dashed #181818' }}>
                                    <ArrowRightIcon className='pArrDot' style={{ color: isDarkThemeEnabledSelector ? '#707070' : '#181818' }} />
                                    <span className='pDistance'>Distance : {routeObject?.viaPoints?.length ? routeObject?.viaPoints[0]?.totalDistance ? routeObject?.viaPoints[0]?.totalDistance + ' km' : 0 + ' km' : 0}</span>
                                    <span className='pTime'>Time : {routeObject?.viaPoints?.length ? routeObject?.viaPoints[0]?.etaTime || '-' : '-'}</span>
                                </div>
                            </div>
                            {routeObject && routeObject?.viaPoints?.length ?
                                routeObject && routeObject?.viaPoints?.map((item, idx) => {
                                    return (
                                        <div key={idx} className='pViaCont'>

                                            <div className='pviaBox'>
                                                <div className='pAddress'>
                                                    {item?.itemName || '-'}
                                                </div>
                                                <div className='pTimeBox'>
                                                    <div className='pSt'>
                                                        <div className='pstLabelValue'>
                                                            <span className='pstLabel' style={{ ...textClasses.boldText }}> STA :</span>
                                                            <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{item?.STA || '-'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='pSt'>
                                                        <div className='pstLabelValue'>
                                                            <span className='pstLabel' style={{ ...textClasses.boldText }}>ATA :</span>
                                                            <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{item?.ATA || '-'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='pSt'>
                                                        <div className='pstLabelValue'>
                                                            <span className='pstLabel' style={{ ...textClasses.boldText }}>STD :</span>
                                                            <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{item?.STD || '-'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='pSt'>
                                                        <div className='pstLabelValue'>
                                                            <span className='pstLabel' style={{ ...textClasses.boldText }}>ATD :</span>
                                                            <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{item?.ATD || '-'}</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='pViaDots' style={{ border: isDarkThemeEnabledSelector ? '1px dashed #707070' : '1px dashed #181818' }}>
                                                <ArrowRightIcon className='pArrDot' style={{ color: isDarkThemeEnabledSelector ? '#707070' : '#181818' }} />

                                                {idx === routeObject?.viaPoints?.length - 1 ?
                                                    <>
                                                        <span className='pDistance'>Distance : {routeObject?.routeDestination?.totalDistance + ' km'}</span>
                                                        <span className='pTime'>Time : {routeObject?.routeDestination?.etaTime || '-'}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <span className='pDistance'>Distance : {routeObject?.viaPoints[idx + 1]?.totalDistance ? routeObject?.viaPoints[idx + 1]?.totalDistance + ' km' : 0 + ' km'}</span>
                                                        <span className='pTime'>Time : {routeObject?.viaPoints[idx + 1]?.etaTime || '-'}</span>
                                                    </>
                                                }


                                            </div>
                                        </div>

                                    )
                                })
                                : null
                            }
                            <div className='pViaCont'>
                                <div className='pviaBox'>
                                    <div className='pAddress'>
                                        {routeObject?.routeDestination?.name || '-'}
                                    </div>
                                    <div className='pTimeBox'>
                                        <div className='pSt'>
                                            <div className='pstLabelValue'>
                                                <span className='pstLabel' style={{ ...textClasses.boldText }}>STD :</span>
                                                <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{routeObject?.routeDestination?.STA || '-'}</span>
                                            </div>
                                        </div>
                                        <div className='pSt'>
                                            <div className='pstLabelValue'>
                                                <span className='pstLabel' style={{ ...textClasses.boldText }}>ATD :</span>
                                                <span className='pstValue' style={{ fontWeight: 'lighter ! important', ...textClasses.boldText }}>{routeObject?.routeDestination?.ATD || '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className="arrowBox">
                            <ArrowRightIcon id={`slideRight-${index}`} onClick={() => slide(index)} className="arrowClass" />
                        </div>
                    </div>
                    :
                    <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        No Device Linked..
                    </div>
            }
        </div>
    )
}

export default TripViaComponent;


