import { Button, CircularProgress } from "@mui/material";
import React from "react";

const CustomSubmitBtn = ({
  variant="contained",
  sx,
  onClick,
  disabled = false,
  loading,
  loadingText = "Loading...",
  label,
}) => {
  return (
    <Button
      variant={variant}
      sx={{...sx}}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? loadingText : label}
      {loading && (
        <CircularProgress
        size={20}
        sx={{
          color: "#383838",
          marginLeft: "5px"
        }}
      />
      )}
    </Button>
  );
};

export default CustomSubmitBtn;
