import {
  Box,
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import loaderGif from "../../../static/images/drawer/loader.gif";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DevicesTable from "./components/DevicesTable";
import {
  bulkUpdateDeviceModelStatus,
  getAllAlertSettings,
  getAllDeviceCategoryNames,
  getAllDeviceModels,
  getDeviceModelStatusCounts,
} from "../../../redux/actions";
import { flushAllDeviceModels } from "../../../redux/reducers/DevicesReducers";
import AddDeviceModel from "./components/AddDeviceModel";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import DeleteModel from "./components/DeleteModel";
import DetailCard from "./components/DetailCard";
import EmptyPage from "../EmptyPage";
import {  getShortText, convertIntoGivenTimezone } from "../../../services/functions";
import { havePermission } from "../../../utils/permissionCheck";

let payload = {
  page: 1,
  limit: 10,
  // selection: ["category", "manufactureName", "deviceModel", "lynkDeviceModel", "status", "alerts", "isActive"]
};

const DeviceModels = () => {
  // local states ----
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionIndex, setActionIndex] = React.useState(null);
  const [tableIndex, setTableIndex] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedModel, setSelectedModel] = React.useState(null);
  const [selectedModelId, setSelectedModelId] = React.useState(null);
  const [filter, setFilter] = React.useState({ ...payload });
  const [tableData, setTableData] = React.useState([]);
  const [actionStatus, setActionStatus] = React.useState(null);
  const [bulkModelStatus, setBulkModelStatus] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [checkedData, setCheckedData] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [searchFields, setSearchFields] = React.useState({
    deviceType: null,
    deviceModel: null,
  });

  // selectors ----
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { allDeviceModels, totalDeviceModels, deviceModelStatusCounts } =
    useSelector((state) => state.deviceslist);

  const { currentUserDetail } = useSelector((state) => state.auth);
  // dispatcher ----
  const dispatch = useDispatch();

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;

  const tabs = [
    {
      id: 0,
      key: "all",
      name: `All Models (${
        deviceModelStatusCounts
          ? deviceModelStatusCounts?.activeCount +
            deviceModelStatusCounts?.inactiveCount
          : 0
      })`,
    },
    {
      id: 1,
      key: "active",
      name: `Active (${deviceModelStatusCounts?.activeCount ?? 0})`,
    },
    {
      id: 2,
      key: "disabled",
      name: `Blocked (${deviceModelStatusCounts?.inactiveCount ?? 0})`,
    },
  ];

  const tableColumns = [
    {
      header: (
        <Typography
          sx={{
            ...textClasses.t13n,
            textTransform: "capitalize",
            color: themeType.default.themeOrange,
          }}
        >
          <Checkbox
            size="small"
            data-testid="header-checkbox"
            sx={{
              marginBottom: "4px",
              padding: "0px",
              color: "#ff7200",
              "&.Mui-checked": {
                color: "#ff7200",
              },
            }}
            checked={checkedData?.length === tableData?.length}
            onChange={(event) => handleSelectAll(event)}
          />
        </Typography>
      ),
      selector: "checkbox",
      cell: (entry, index) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textTransform: "capitalize",
              color: themeType.default.themeOrange,
            }}
          >
            <Checkbox
              size="small"
              data-testid="checkbox"
              sx={{
                marginBottom: "4px",
                padding: "0px",
                color: "#ff7200",
                "&.Mui-checked": {
                  color: "#ff7200",
                },
              }}
              checked={checkedData?.includes(entry._id) ? true : false}
              onChange={(event) => {
                handleCheckChange(event, entry, index);
              }}
            />
          </Typography>
        );
      },
    },
    {
      header: "#",
      cell: (i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {/* {i + 1} */}
            {(filter?.page - 1) * filter?.limit + i + 1}
          </Typography>
        );
      },
    },
    {
      header: "Device Model",
      cell: (entry) => {
        return (
          <Tooltip
            arrow
            title={
              entry?.lynkDeviceModel?.length > 15 ? entry?.lynkDeviceModel : ""
            }
            placement="top"
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
                color: themeType?.default?.themeOrange,
                cursor: "pointer",
              }}
              onClick={() => handleRowChange(entry)}
            >
              {getShortText(entry?.lynkDeviceModel, 15) ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      header: "OEM Model Name",
      cell: (entry) => {
        return (
          <Tooltip
            arrow
            title={entry?.deviceModel?.length > 15 ? entry?.deviceModel : ""}
            placement="top"
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {getShortText(entry?.deviceModel, 15) ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      header: "OEM",
      cell: (entry) => {
        return (
          <Tooltip
            arrow
            title={
              entry?.manufactureName?.length > 15 ? entry?.manufactureName : ""
            }
            placement="top"
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {getShortText(entry?.manufactureName, 15) ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      header: "Device Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.category ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color:
                entry?.status?.toLowerCase() == "active"
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.status?.toLowerCase() == "active" ? "Active" : "Blocked"}
          </Typography>
        );
      },
    },
    {
      header: "Added By",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.addedBy ? entry?.addedBy?.name : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Added On",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.addedOn ? convertIntoGivenTimezone(entry?.addedOn, currentUserDetail.timeZone.offset) : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Action",
      cell: (index) => {
        return (
          <Typography
            data-testid="action-menu-btn"
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, index)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      },
    },
  ];

  /** Handlers ---- */
  const handleOpenAction = (event, key) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(key);
    setTableIndex(key);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleModalClose = () => {
    handleCloseAction();
    setShowAddModal(false);
    selectedModel && setSelectedModel(null);
  };

  /**
   * @description --- This will open confirmation modal for mutliple checked data
   */
  const handleOpenDeleteModal = () => {
    let tempChecked = [...checkedData];
    let templateArr = [];
    tempChecked?.map((id) => {
      let selectedRowData = tableData?.find((_) => _?._id === id);
      if (selectedRowData)
        templateArr?.push({
          deviceName: selectedRowData?.lynkDeviceModel,
          id: id,
          isActive: selectedRowData?.isActive,
        });
    });
    setSelectedTemplates(templateArr);
    setShowDeleteModal(true);
  };

  /**
   * @description --- This will open confirmation modal for single items (through action menu)
   */
  const handleOpenModal = (action) => {
    let selectedRow = [];
    let template = [];
    setActionStatus(action);
    selectedRow?.push(tableData[tableIndex]?._id);
    template?.push({
      deviceName: tableData[tableIndex]?.lynkDeviceModel,
      id: tableData[tableIndex]?._id,
      isActive: tableData[tableIndex]?.isActive,
    });

    setCheckedData(selectedRow);
    setSelectedTemplates(template);
    setShowDeleteModal(true);
    sendCustomEventAnalytics("device models", `${action}-device-model`, `${action}-device-model`);
  };

  const handleCheckChange = (event, entry, index) => {
    if (event?.target?.checked) {
      setTableIndex(index);
      let temp = [...checkedData];
      temp?.push(entry?._id);
      setCheckedData([...temp]);
    } else {
      const temp = checkedData?.filter((_) => _ !== entry?._id);
      //tableIndex && setTableIndex(null);
      setCheckedData(temp);
    }
  };

  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      let temp = [];
      tableData?.map((_) => {
        temp?.push(_?._id);
      });
      setCheckedData([...temp]);
    } else {
      const temp = [];
      setCheckedData([...temp]);
    }
  };

  const handleTabsChange = (_, value) => {
    setSelectedTab(value);
    if (allDeviceModels && tabs[value]?.key === "active") {
      setFilter((existing) => ({
        ...existing,
        filter: {
          status: "ACTIVE",
        },
      }));
    } else if (allDeviceModels && tabs[value]?.key === "disabled") {
      setFilter((existing) => ({
        ...existing,
        filter: {
          status: "DISABLE",
        },
      }));
    } else if (allDeviceModels && tabs[value]?.key === "all") {
      let temp = { ...filter };
      delete temp["filter"]["status"];
      setFilter(temp);
    }
  };

  const handleRowChange = React.useCallback(
    (entry) => {
      if (entry) {
        handleCloseAction();
        setSelectedModelId(entry?._id);
      }
    },
    [selectedModelId]
  );

  const handleFetchAllData = React.useCallback(() => {
    dispatch(getAllDeviceCategoryNames({selection: ["category", "label"]}));
    dispatch(getAllAlertSettings({selection: ["name"]}));
    setBulkModelStatus(null);
  }, [dispatch]);

  const handleFetchAllDeviceModels = React.useCallback(() => {
    setLoader(true);
    try {
      dispatch(
        getAllDeviceModels(
          {...filter},
          () => {
            setLoader(false);
          },
          () => {
            setLoader(false);
          }
        )
      );
      setCheckedData([]);
      dispatch(getDeviceModelStatusCounts());
    } catch (e) {
      setLoader(false);
    }
  }, [dispatch, filter]);

  const handleStatusUpdate = React.useCallback(() => {
    try {
      if (checkedData?.length > 0) {
        setLoader(true);
        const payload = {
          ids: checkedData,
          status:
            actionStatus?.toUpperCase() === "ENABLE"
              ? "ACTIVE"
              : actionStatus?.toUpperCase(),
        };

        dispatch(
          bulkUpdateDeviceModelStatus(payload, () => {
            handleFetchAllDeviceModels();
            setShowDeleteModal(false);
            setTableIndex(null);
            setCheckedData([]);
            setActionStatus(null);
            setLoader(false);
            setComment("");
          })
        );
      }
    } catch (e) {
      console.log("error while update device model status", e);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  }, [actionStatus]);


  /** ----- Effects ----- */
  React.useEffect(() => {
    handleFetchAllData();

    return () => dispatch(flushAllDeviceModels());
  }, [dispatch]);

  React.useEffect(() => {
    if (filter) {
      handleFetchAllDeviceModels();
    }
    return () => dispatch(flushAllDeviceModels());
  }, [filter, dispatch]);

  React.useEffect(() => {
    if (allDeviceModels) {
      setTableData(allDeviceModels);
    }
  }, [allDeviceModels]);

  /**
   * @returns (string) = active, inactive or null [in case of mixed statuses]
   */
  React.useEffect(() => {
    if (checkedData?.length > 0) {
      let activeCount = 0;
      let disableCount = 0;

      for (const item of checkedData) {
        const sel = tableData?.find((_) => _?._id === item);
        if (sel?.status?.toLowerCase() === "active") {
          activeCount++;
        } else if (sel?.status?.toLowerCase() === "disable") {
          disableCount++;
        }
      }

      if (activeCount > 0 && disableCount === 0) {
        setBulkModelStatus("active");
      } else if (disableCount > 0 && activeCount === 0) {
        setBulkModelStatus("disable");
      } else {
        return setBulkModelStatus(null);
      }
    }
  }, [checkedData]);

  return (
    <>
      <Grid container>
        {/* top row - page title and action button */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-testid="page-title"
        >
          <Typography data-testid="title" sx={{ ...textClasses.cardTitle }}>
            Device Models
          </Typography>
          {havePermission(currentUserDetail, "device", "addDeviceCategory") && (
            <Button
              variant="outlined"
              size="small"
              data-testid="add-new-button"
              onClick={() =>{ 
                sendCustomEventAnalytics("device models", "add device model", "add device model");
                setSelectedModel(null);
                setShowAddModal(true);
              }}
              sx={{
                ...buttonClasses.small,
                minHeight: "36px",
                borderColor: themeType.default.themeOrange,
                color: buttonClasses.lynkitOrangeEmpty,
              }}
              startIcon={<AddIcon />}
            >
              Add New
            </Button>
          )}
        </Grid>

        {/* Tabs ---- */}
        <Grid
          item
          xs={12}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mt: 0,
            mb: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabsClass"
            aria-label="scrollable auto tabs example"
            sx={{
              maxWidth: window.innerWidth - 108,
              "& .MuiTabs-indicator": {
                backgroundColor: themeType.default.themeOrange,
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeType.default.themeOrange,
              },
            }}
          >
            {tabs?.map((item, i) => {
              return (
                <Tab
                  key={i}
                  label={`${item.name}`}
                  sx={{
                    ...textClasses.normalText,
                    textTransform: "capitalize",
                  }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  data-testid="tab"
                />
              );
            })}
          </Tabs>
        </Grid>

        {/* check action buttons ------ */}
        {checkedData.length > 0 ? (
          <Box pl={"0"} mt={1} data-testid="edit">
            {checkedData.length < 2 ? (
              <>
                {havePermission(
                  currentUserDetail,
                  "device",
                  "updateDeviceCategory"
                ) && (
                  <Button
                    size="small"
                    data-testid="edit"
                    variant="contained"
                    className="UM_uploadfile-btn"
                    //disabled={checkedData.length > 1 ? true : false}
                    sx={{
                      fontSize: "12px",
                      borderRadius: "8px",
                      margin: "5px",
                      color: "black",
                      border: "1px solid black",
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setSelectedModel(tableData[tableIndex]);
                      setShowAddModal(true);
                    }}
                    // sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                )}
                {havePermission(
                  currentUserDetail,
                  "device",
                  "detailsDeviceCategory"
                ) && (
                  <Button
                    size="small"
                    data-testid="view"
                    variant="contained"
                    className="UM_uploadfile-btn"
                    //disabled={checkedData.length > 1 ? true : false}
                    onClick={() => {
                      handleRowChange(tableData[tableIndex]);
                    }}
                    sx={{
                      fontSize: "12px",
                      borderRadius: "8px",
                      margin: "5px",
                      color: "black",
                      border: "1px solid black",
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    View{" "}
                  </Button>
                )}
              </>
            ) : (
              ""
            )}

            {bulkModelStatus !== null && (
              <Button
                size="small"
                data-testid="delete"
                variant="contained"
                className="UM_delete-btn"
                //disabled={checkedData.length >= 2 ? true : false}
                onClick={() => {
                  setActionStatus(
                    bulkModelStatus == "active" ? "disable" : "enable"
                  );
                  handleOpenDeleteModal();
                }}
                sx={{
                  fontSize: "12px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  textTransform: "none",
                  ...buttonClasses[
                    bulkModelStatus == "active"
                      ? "lynkitDisabled"
                      : "lynkitGreenFill"
                  ],
                }}
              >
                {bulkModelStatus == "active" ? "Block" : "Active"}
              </Button>
            )}

            <Button
              size="small"
              data-testid="delete"
              variant="contained"
              className="UM_delete-btn"
              onClick={() => {
                setActionStatus("delete");
                handleOpenDeleteModal();
              }}
              sx={{
                fontSize: "12px",
                borderRadius: "8px",
                margin: "5px",
                color: "black",
                border: "1px solid black",
                textTransform: "none",
                ...buttonClasses.lynkitBlackFill,
              }}
            >
              {" "}
              Delete{" "}
            </Button>
          </Box>
        ) : (
          ""
        )}

        {/* Table and details section ----- */}
        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {allDeviceModels && allDeviceModels?.length === 0 ? (
              <EmptyPage body="No device models found" />
            ) : (
              <Grid
                container
                sx={{ mt: 1 }}
                spacing={1}
                minHeight={selectedModelId ? "70vh" : "unset"}
              >
                <Grid item sm={selectedModelId ? 8 : 12}>
                  <Paper
                    sx={{
                      height: "100%",
                      width: "100%",
                      overflowX: "auto",
                      display: "block",
                    }}
                    data-testid="device-models-table"
                  >
                    <DevicesTable
                      tableColumns={tableColumns}
                      showAllColumns={true}
                      data={tableData ?? []}
                      totalRecords={totalDeviceModels}
                      pagination={filter}
                      setPagination={setFilter}
                      pageFilterKey="page"
                      themeType={themeType}
                    />
                  </Paper>
                </Grid>
                {selectedModelId && (
                  <Grid item sm={4}>
                    <DetailCard
                      id={selectedModelId}
                      onClose={() => setSelectedModelId(null)}
                      themeType={themeType}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>

      {/* -- Additional Components -- */}
      {actionIndex != null && (
        <Menu
          data-testid="action-menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {havePermission(
            currentUserDetail,
            "device",
            "updateDeviceCategory"
          ) && (
            <MenuItem
              key={"edit"}
              onClick={() => {
                setSelectedModel(tableData[tableIndex]);
                setShowAddModal(true);
                handleCloseAction();
              }}
            >
              Edit
            </MenuItem>
          )}
          {havePermission(
            currentUserDetail,
            "device",
            "detailsDeviceCategory"
          ) && (
            <MenuItem
              key="view"
              onClick={() => handleRowChange(tableData[tableIndex])}
            >
              View
            </MenuItem>
          )}
          <MenuItem
            key="active/block"
            onClick={() => {
              handleOpenModal(
                tableData[tableIndex]?.status?.toLowerCase() == "active"
                  ? "disable"
                  : "enable"
              );
              handleCloseAction();
            }}
          >
            {tableData[tableIndex]?.status?.toLowerCase() == "active"
              ? "Block"
              : "Active"}
          </MenuItem>
          <MenuItem
            key="delete"
            data-testid="delete-menu"
            onClick={() => {
              handleOpenModal("delete");
              handleCloseAction();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      )}
      {showDeleteModal && (
        <DeleteModel
          open={showDeleteModal}
          loading={loader}
          hide={() => {
            setShowDeleteModal(false);
            setActionStatus(null);
            handleCloseAction();
          }}
          tableData={tableData}
          checkedObj={checkedData}
          action={actionStatus}
          refresh={handleFetchAllDeviceModels}
          setShowDeleteModal={() => {
            setShowDeleteModal(false);
          }}
          comment={comment}
          setComment={setComment}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          actionFn={handleStatusUpdate}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={setCheckedData}
        />
      )}
      {showAddModal && (
        <AddDeviceModel
          open={showAddModal}
          hide={() => {
            setShowAddModal(false);
          }}
          handleClose={handleModalClose}
          searchFields={searchFields}
          setSearchFields={setSearchFields}
          themeType={themeType}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          refresh={handleFetchAllDeviceModels}
          selectedModel={selectedModel}
        />
      )}
    </>
  );
};

export default DeviceModels;
