/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl";
import { Box, Card, CardContent, Collapse, Button } from "@mui/material";
import { AspectRatio, Search } from "@mui/icons-material";
import { mapBoxToken } from "../../../../../services/config";
import { darkStyles } from "../../../../../services/constants";

const Map = ({
  isMap,
  themeType,
  isDark,
  latLng,
  newLat,
  newLng,
  dashboardSectionRef,
  isDarkThemeEnabledSelector
}) => {
  const [mapHeight, setMapHeight] = useState("240");
  const [myMap, setMyMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [myMapOptions, setMyMapOptions] = useState(null);
  const mapHeightChange = () => {
    setMapHeight((oldHeight) => (oldHeight === "240" ? "550" : "240"));
  };

  useEffect(() => {
    if (myMapOptions) {
      setTimeout(() => {
        if (
          typeof google === "undefined" ||
          typeof google.maps === "undefined"
        ) {
          return null;
        }

        const mapElement = document.getElementById("viewallmap");

        if (!mapElement) {
          console.error("Element with id 'viewallmap' not found.");
          return null;
        }

        const map = new google.maps.Map(mapElement, myMapOptions);
        setMyMap(map);

        return map;
      }, 40);
    }
  }, [myMapOptions, newLat, newLng]);

  useEffect(() => {
      if (!myMap || !newLat || !newLng) return;
      if (selectedMarker) {
        selectedMarker.setMap(null);
      }
      const pos = {
        lat: Number(newLat),
        lng: Number(newLng),
      };
      const dashboardSectionElement = dashboardSectionRef.current;

      const marker = new google.maps.Marker({
        position: pos,
        map: myMap,
      });
      setMarkers([marker]);
      setSelectedMarker(marker);

      myMap?.setCenter(pos); // Set map center
        myMap?.setZoom(15); //

      if (dashboardSectionElement) {
        dashboardSectionElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      // newLat.marker = marker;
      // newLng.marker = marker;
  }, [myMap, newLat, newLng, dashboardSectionRef]);

  useEffect(() => {
    if (myMap) {
      const newMapOptions = {
        zoom: 7,
        center: { lat: newLat === null ? Number(20.5937) : Number(newLat), lng: newLng === null ? Number(78.9629) : Number(newLng) },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        options: { styles: isDarkThemeEnabledSelector && styles },

              
      };
      myMap?.setOptions(newMapOptions);
    }
  }, [myMap, isDarkThemeEnabledSelector,newLat, newLng]);

  const styles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

 

 



  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      let isMounted = true;

      loadGoogleMapsApi()
        .then((google) => {
          if (isMounted) {
            setMapLoaded(true);
            setMyMapOptions({
              zoom: 7,
              center: { lat: Number(20.5937), lng: Number(78.9629) },
              mapTypeId: "terrain",
              zoomControl: true,
              zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_BOTTOM,
              },
              streetViewControl: false,
            });
          }
        })
        .catch((error) => {
          console.error("Error loading Google Maps API:", error);
        });

      return () => {
        isMounted = false;
      };
    }, 50);
  }, []);

  return (
    <>
      <Collapse in={isMap} collapsedSize={isMap ? 240 : 0}>
        <Card sx={{ position: "relative" }}>
          <CardContent sx={{ padding: 0 }}>
            <Box width="100%" style={{ height: `${mapHeight}px` }}>
              <div
                id="viewallmap"
                className="drawMap"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </CardContent>
        
          <Box
            className="geofence__map-resize-icon"
            id="resizeMap"
            onClick={mapHeightChange}
          >
            <AspectRatio sx={{ fontSize: "1.8rem" }} />
          </Box>
        </Card>
      </Collapse>
    </>
  );
};
export default Map;
