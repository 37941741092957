/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import {
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  capitalize,
  styled,
  useTheme,
} from "@mui/material";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeviceMapDrawer from "./components/DeviceMapDrawer";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import { FilterAlt, Fullscreen } from "@mui/icons-material";
import {
  useParams,
  Link,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import Iframe from "react-iframe";
import Fastag from "../../../static/images/map/Fastag.svg";
import ReactGA from 'react-ga4';


import {
  downloadMovementReport,
  generatePasscodeForFixedLock,
  getAllGeofencesForMap,
  getDeviceDetails,
  getDeviceTrackReport,
  getDeviceWiseSummary,
  getHistoryTrack,
  getListDeviceStatus,
  getLockUnlockOTP,
  getObj,
  getPasscodeOTP,
  getTrackData,
  getUlipData,
  lockUnlockDevice,
  showPasscode,
  verifyLockUnlockOTP,
  verifyPasscodeOTP,
} from "../../../redux/actions";
import DrawerFloaterLabel from "../../Components/Drawer/DrawerFloaterLabel";
import greenMarkerIcon from "../../../static/images/map/green_marker.svg";
import newLock from "../../../assets/img/new_lock.png";
import { saveAs } from "file-saver";
import OTPModal from "./components/OTPModal";
import { havePermission } from "../../../utils/permissionCheck";
import EnterEmailModal from "./components/EnterEmailModal";
import {
  capitalizeSentence,
  convertIntoGivenTimezone,
  dateTimeFormater,
  showToast,
} from "../../../services/functions";
import { flushDeviceMovementReport } from "../../../redux/reducers/DevicesReducers";
import loaderGif from "../../../static/images/drawer/loader.gif";
import config, { locationUrl } from "../../../services/config";
import FixedLockPasscodeModal from "./components/FixedLockPasscodeModal";
import DeviceCommandModal from "./components/DeviceCommandModal";
import routeIcon from "../../../assets/images/routeIcon.png";
import speed from "../../../assets/images/speed.png";
import geofenceImg from "../../../assets/images/map.png";
import distance from "../../../assets/images/distance.png";
import deviceeventImg from "../../../assets/images/deviceevent.png";
import dateTime from "../../../assets/images/dateandtime.png";
import location from "../../../assets/infoWindow/location.png";
import truck from "../../../assets/infoWindow/truckBlack.png";
import battery from "../../../assets/infoWindow/low-battery.png";
import batteryConnected from "../../../assets/infoWindow/connected_icon_new.png";
import batteryDisconnected from "../../../assets/infoWindow/disconnected_icon_new.png";
import attached from "../../../assets/infoWindow/attached_icon_new.png";
import deattached from "../../../assets/infoWindow/deattached_icon_new.png";
import tag from "../../../assets/infoWindow/tag.png";
import lock from "../../../assets/infoWindow/lock.png";
import darkLock from "../../../assets/infoWindow/dark_lock.png";
import darkUnlock from "../../../assets/infoWindow/dark_unlock.png";
import gpsIcon from "../../../assets/img/mapicon_orange.png";
import unlock from "../../../assets/infoWindow/unlock.png";
import axios from "axios";
import CameraEventsDrawer from "./components/CameraEventsDrawer";
import CameraFullScreenMode from "./components/CameraFullScreenMode";
import history from "../../../services/history";

import toll from "../../../assets/infoWindow/toll.png";
import truckIcon from "../../../assets/infoWindow/truckIcon.svg";

const DeviceMap = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  var infoWindows = [];
  var trackDetailInfoWindow = window.google
    ? new google.maps.InfoWindow()
    : null;
  // const iconPath = `${config?.baseURL}/images/lynktracV2/trip-icons`;
  const iconPath = `https://lynktrac.io/lynktracV2/trip-icons`;
  const [currentLocation, setCurrentLocation] = React.useState({});
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(true);
  const [showFixedLockPasscodeModal, setShowFixedLockPasscodeModal] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [unlockId, setUnlockId] = useState("");
  const [deviceTrackData, setDeviceTrackData] = useState(null);
  const [showEventSummary, setShowEventSummary] = React.useState(false);
  const [showOTPForLockUnlock, setShowOTPForLockUnlock] = React.useState(false);
  const [showPasscodeOTPModal, setShowPasscodeOTPModal] = React.useState(false);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const [newInfoWindow, setNewInfoWindow] = React.useState(null);
  const [showEmailMovementModal, setShowEmailMovementModal] =
    React.useState(false);
  const [showEmailTrackModal, setShowEmailTrackModal] = React.useState(false);
  const [showSendCommandModal, setShowSendCommandModal] = React.useState(false);
  const [passcodeValue, setPasscodeValue] = React.useState(null); //same state for showing elock and fixed lock passcode values
  const [passcodeIdToVerify, setPasscodeIdToVerify] = React.useState(null); //elock passcode id got in send otp response
  const [eventSummaryData, setEventSummaryData] = React.useState([]);
  const [myMap, setMymap] = React.useState(null);
  const [lockType, setLockType] = React.useState(null);
  const [totalDistance, setTotalDistance] = useState("");
  const [fullScreenCamera, setFullScreenCamera] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [filters, setFilters] = React.useState({
    from: new Date(),
    to: new Date(),
    overstoppage: "",
    overspeed: "",
  });

  const { currentUserDetail } = useSelector((_) => _?.auth);
  const { socketInstance, connected } = useSelector((_) => _?.socket);
  const { deviceDetails, deviceMovementReportData } = useSelector(
    (_) => _?.deviceslist
  );
  const { geofenceListingForMap } = useSelector((_) => _?.geofencelist);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  var objs = {
    lat: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][1]
    ),
    lng: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][0]
    ),
  };

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { textClasses } = themeType;

  const filtersPayload = React.useMemo(() => {
    return {
      id: deviceDetails?.client_id,
      type: deviceDetails?.deviceType,
      overspeed: filters?.overspeed ?? "",
      overstop: filters?.overstoppage ?? "",
      userId: currentUserDetail?._id,
      userType:
        currentUserDetail?.userRole?.type || currentUserDetail?.userType,
      socketId: connected && socketInstance ? socketInstance?.id : "",
    };
  }, [deviceDetails, filters, currentUserDetail, socketInstance, connected]);

  const styles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const geofenceRenderer = (map, geoJsonData) => {
    const outputJson = {
      type: "FeatureCollection",
      features: geoJsonData.map((location) => ({
        type: "Feature",
        properties: location.features[0].properties,
        geometry: location.features[0].geometry,
      })),
    };

    const geoJsonLayer = new google.maps.Data();
    geoJsonLayer.addGeoJson(outputJson);

    geoJsonLayer.setStyle(function (feature) {
      return {
        fillColor: "#0059ff",
        strokeColor: "#0059ff",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.5,
      };
    });

    geoJsonLayer.setMap(map);

    geoJsonLayer.addListener("click", function (event) {
      let props = {
        name: event.feature.getProperty("name"),
        status: event.feature.getProperty("status"),
        addedOn: convertIntoGivenTimezone(
          event.feature.getProperty("addedOn"),
          currentUserDetail.timeZone.offset
        ),
        addedBy: event.feature.getProperty("addedBy"),
        centerCoordinates: event.feature.getProperty("centerCoordinates"),
      };
      infoWindowRederer(map, props);
    });
  };

  const infoWindowRederer = (map, feature) => {
    const coordinates = feature.centerCoordinates[0];
    const infoWindowContent = `
    <div style="min-width:310px">
        <div> <span style="display: inline-block; width: 80px; color: #909090;">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; text-transform: capitalize">${feature?.name}</span></div><br>
        <div><span style="display: inline-block; width: 80px; color: #909090;" >Status:</span> <span style="color: #000; font-Weight: 500;"> ${feature?.status}</span><br><br>
        <div><span style="display: inline-block; width: 80px; color: #909090;">Added By:</span><span style="color: #000; font-Weight: 500;"> ${feature?.addedBy?.name}</span><br><br>
        <div><span style="display: inline-block; width: 80px; color: #909090;">Added On:</span><span style="color: #000; font-Weight: 500;"> ${feature?.addedOn}</span><br><br>
    </div>
    `;
    const infoWindow = createInfoWindow(infoWindowContent, {
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    map.panTo({
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    infoWindows.forEach((window) => window.close());
    infoWindow.open(map);
    infoWindows.push(infoWindow);
  };

  const createInfoWindow = (content, position) => {
    return new google.maps.InfoWindow({
      content: content,
      position: position,
    });
  };

  const trackInfoRenderer = (fieldData, event, map) => {
    // trackDetailInfoWindow?.close();
    const {
      lockStatus,
      lock_status,
      batteryPercent,
      speedVal,
      distanceVal,
      time,
      vehicleStatus,
      address,
      eventName,
      vehicleNumber,
      gpsVal,
      geofenceNames,
      deviceeventStatus,
    } = fieldData;

    let lockStatusUpdated;
    let status = deviceeventStatus ? deviceeventStatus : "-";

    let infoWindowContent = `
      <div class='infoDiv'>
        <div class='infoChild1'>
          ${
            status !== "-" && deviceDetails?.deviceType !== "fixedTracker"
              ? `<div class='subChild bgGray'  >
              <img class='lockImg'  src=${deviceeventImg} alt='' />
              <span class='deviceLabel'>Event </span>
              <span class='devicevalue'>${status}</span>
            </div>`
              : ""
          }

          ${
            deviceDetails?.deviceType !== "assetTracker" &&
            deviceDetails?.deviceType !== "fixedTracker"
              ? `
            <div class='subChild  ${
              lockStatus && lockStatus === "locked" ? "bgGreen" : "bgGray"
            }'>
              <img class='lockImg'  src=${
                lockStatus === "motorUnlocked" ? unlock : lock
              } alt='' />
              <span class='deviceLabel'>Lock Status </span>
              <span class='devicevalue'>${
                lockStatus === "motorUnlocked"
                  ? "Motor Unlocked"
                  : capitalizeSentence(lockStatus)
              }</span>
            </div>
          `
              : ""
          }

          ${
            deviceDetails?.deviceType === "fixedELock"
              ? `
            <div class='subChild ${
              lock_status && lock_status === "locked" ? "bgGreen" : "bgGray"
            }'>
              <img class='lockImg' style="height: 36px; width: 36px;" src=${
                lock_status !== "locked"
                  ? batteryDisconnected
                  : batteryConnected
              } alt='' />
              <span class='deviceLabel'>Connection Status </span>
              <span class='devicevalue'>${
                lock_status
                  ? lock_status === "locked"
                    ? "External Battery Connected"
                    : "External Battery Disconnected"
                  : "-"
              }</span>
            </div>
          `
              : ""
          }

          ${
            deviceDetails?.deviceType === "fixedTracker"
              ? `
            <div class='subChild ${
              lock_status && lock_status === "External Power Connected"
                ? "bgGreen"
                : "bgGray"
            }'>
              <img class='lockImg' style="height: 36px; width: 36px;" src=${
                lock_status !== "External Power Connected"
                  ? batteryDisconnected
                  : batteryConnected
              } alt='' />
              <span class='deviceLabel'>Connection Status</span>
              <span class='devicevalue'>${lock_status ?? "-"}</span>
            </div>
          `
              : ""
          }

          ${
            deviceDetails?.deviceType === "assetTracker" && !!lock_status
              ? `
            <div class='subChild ${
              lock_status && lock_status === "locked" ? "bgGreen" : "bgGray"
            }'>
              <img class='lockImg' style="height: 36px; width: 36px;" src=${
                lock_status !== "locked" ? deattached : attached
              } alt='' />
              <span class='deviceLabel'>Device Status </span>
              <span class='devicevalue'>${
                lock_status
                  ? lock_status === "locked"
                    ? "Attached"
                    : "Deattached"
                  : "-"
              }</span>
            </div>
          `
              : ""
          }

          <div class='subChild bgblue'>
            <img class='lockImg'  src=${tag} alt='' />
            <span class='deviceLabel'> Device Id </span>
            <span class='devicevalue'> ${deviceDetails?.client_id}</span>
          </div>
        </div>
        
        <div class='infoChild2'>
          ${
            deviceDetails?.deviceType !== "fixedELock" &&
            deviceDetails?.deviceType !== "fixedTracker"
              ? `
            <div class='subChild2 '>
              <img class='lockImg'  src=${battery} alt='' />
              <span class='infoLabel label2'>Battery </span>
              <span class='infoValue infoValue2'>${batteryPercent}</span>
            </div>
          `
              : ""
          }

          <div class='subChild2'>
            <img class='imgCss'  src=${truck} alt='' />
            <span class='infoLabel label2'>Vehicle Number </span>
            <span class='infoValue infoValue2'>${vehicleNumber}</span>
          </div>

          <div class='subChild2'>
            <img class='imgCss'  src=${speed} alt='' />
            <span class='infoLabel label2'>Speed </span>
            <span class='infoValue infoValue2'>${speedVal}</span>
          </div>

          ${
            deviceDetails?.deviceType !== "fixedTracker"
              ? `
          <div class='subChild2'>
            <img class='imgCss'  src=${distance} alt='' />
            <span class='infoLabel label2'>Distance </span>
            <span class='infoValue infoValue2'>${distanceVal}</span>
          </div>
          `
              : ""
          }

          <div class='subChild2'>
            <img class='imgCss'  src=${dateTime} alt='' />
            <span class='infoLabel label2'>Date & Time</span>
            <span class='infoValue infoValue2'>${time}</span>
          </div>
        </div>

        <div class='infoChild3' style='height: auto; margin-top: 2px;'>
          <div class='addressDiv'>
            <div class='addressImg'>
              <img class='imgCss' src=${location} alt='' />
            </div>
            
            <div class='addressBox' style='flex-direction:column; align-items: start; '>
              ${
                geofenceNames !== "-" && geofenceNames !== "nil"
                  ? `<p class='infoLabel' style='font-weight: bold; padding-top:16px; '>${geofenceNames}</p>`
                  : ""
              }
              <p class='infoLabel'>${address}</p>
            </div>
          </div>
        </div>
      </div>
    `;
    // ${geofenceNames ? `<span>${geofenceNames}</span>` : 'null'}
    trackDetailInfoWindow.setContent(infoWindowContent);
    trackDetailInfoWindow.setPosition(event.latLng);
    trackDetailInfoWindow.open(map);
  };

  const handleRenderTrackOnMap = (geoJsonData) => {
    try {
      if (geoJsonData && myMap) {
        // removePreviousTrack();
        const firstGpsSignal = geoJsonData?.features[0]?.properties?.gps;
        let trackData = [];
        myMap.data.addGeoJson(geoJsonData, {
          idPropertyName: "_id",
        });

        myMap.data.setStyle({
          strokeColor: "#ff7200", // Orange color
          strokeWeight: 2,
        });
        setShowEventSummary(true);
        myMap.data.forEach((feature, index) => {
          if (feature.getGeometry().getType() === "LineString") {
            const coordinates = feature.getGeometry().getArray();
            const lineSymbol = {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Arrow symbol
              scale: 3, // Size of the arrow
              strokeColor: "#ff7200", // Arrow color
              fillColor: "#ff7200",
            };
            // Create Polylines for each segment
            for (let i = 0; i < coordinates.length - 1; i++) {
              const segmentPath = [coordinates[i], coordinates[i + 1]];

              // Create the Polyline with arrow icon
              const line = new google.maps.Polyline({
                path: segmentPath,
                icons: [
                  {
                    icon: lineSymbol,
                    offset: "50%", // Adjust offset for the first arrow
                  },
                ],
                strokeColor: "#ff7200",
                // Customize line style as needed
                map: myMap,
              });
            }
          } else if (feature.getGeometry().getType() === "Point") {
            const iconFile = feature.getProperty("img")
              ? feature.getProperty("img")?.split("/")
              : "";
            let iconImg =
              iconFile && Array.isArray(iconFile)
                ? iconFile[iconFile?.length - 1]
                : null;
            const imgSize =
              iconImg &&
              (iconImg?.includes("start") || iconImg?.includes("end"))
                ? 25
                : 20;
            myMap.data.overrideStyle(feature, {
              icon: {
                url: iconImg ? `${iconPath}/${iconImg}` : greenMarkerIcon,
                scaledSize: new google.maps.Size(imgSize, imgSize),
              },
            });

            let objMap = {
              latitude: feature.getProperty("latitude"),
              longitude: feature.getProperty("longitude"),
              event: feature.getProperty("event"),
              event_alert: feature.getProperty("event_alert"),
              packet_time: feature.getProperty("packet_time"),
              lockStatus: feature.getProperty("lockStatus"),
              icon: feature.getProperty("img"),
            };
            handleEventSummaryData(objMap, true, trackData);
          }
        });

        myMap?.data?.addListener("click", (event) => {
          var feat = event.feature;
          let geofenceName = "";
          if (
            feat?.getProperty("geo_name") &&
            Array.isArray(feat?.getProperty("geo_name"))
          ) {
            geofenceName = feat
              ?.getProperty("geo_name")
              ?.map((_) => _)
              ?.join(", ");
          } else if (
            feat?.getProperty("geo_name") &&
            typeof feat?.getProperty("geo_name") === "string"
          ) {
            geofenceName = feat?.getProperty("geo_name");
          }
          const allData = {
            lockStatus: feat?.getProperty("lockStatus") || "-",
            lock_status: feat?.getProperty("lock_status") || "",
            batteryPercent: feat?.getProperty("battery_voltage")
              ? `${Number(feat?.getProperty("battery_voltage"))?.toFixed(2)}%`
              : "-",
            speedVal: feat?.getProperty("speed")
              ? /^\s*([-+]?\d+(\.\d*)?)([eE][+-]?\d+)\s*$/?.test(
                  Number(feat?.getProperty("speed"))
                )
                ? `${(parseFloat(feat?.getProperty("speed")) * 10 ** 0).toFixed(
                    2
                  )} Km/h`
                : `${feat?.getProperty("speed")} Km/h`
              : "-",
            vehicleStatus: feat?.getProperty("ignition_status") || "-",
            // distanceVal: ((feat?.getProperty("gps") - firstGpsSignal)/1000).toFixed(2) + ' KM' || "-",
            distanceVal: feat?.getProperty("distance") + " KM" || "-",
            time: feat?.getProperty("packet_time")
              ? convertIntoGivenTimezone(
                  feat?.getProperty("packet_time"),
                  currentUserDetail.timeZone.offset
                )
              : "-",
            eventName: feat?.getProperty("event") || "-",
            vehicleNumber: feat?.getProperty("vehicleNo") || "-",
            geofenceNames: geofenceName || "-",
            deviceeventStatus: feat?.getProperty("event") || "-",
          };

          const payload = {
            latitude: feat?.getProperty("latitude"),
            longitude: feat?.getProperty("longitude"),
            appType: "GOOGLE",
          };

          axios
            .post(`${locationUrl}fetchAddress`, payload, {
              headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
              },
            })
            .then((response) => {
              let address = response?.data?.data[0]?.address;
              allData.address = response?.data?.data[0]?.address;

              trackInfoRenderer(allData, event, myMap);

              // infowindow.setContent(infoWindowContent);
              // infowindow.setPosition(event.latLng);
              // trackDetailInfoWindows?.forEach((window) => window.close());
              // infowindow.open(myMap);
              // trackDetailInfoWindows?.push(infowindow);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
        setEventSummaryData(trackData);
      }
    } catch (e) {
      console.log("handleRenderTrackOnMap: ", e);
    }
  };

  const handleEventSummaryData = (data, flag = false, trackData) => {
    // let trackData = eventSummaryData;
    try {
      if (
        data.icon == "assets/img/trackingicons/end.png" ||
        data.icon == "assets/img/trackingicons/start.png"
      ) {
        if (
          deviceDetails?.deviceType == "elock" ||
          deviceDetails?.deviceType == "block" ||
          deviceDetails?.deviceType == "fixedLock"
        ) {
          if (data.event == "Sealed") {
            data.icon = "assets/img/new_lock.png";
          } else if (data.event == "Unsealed") {
            data.icon = "assets/img/new_auth.png";
          } else if (data.event == "Unauthorised Unsealing") {
            data.icon = "assets/img/new_unauth.png";
          } else if (data.event == "Authorised Unsealing") {
            data.icon = "assets/img/new_auth.png";
          } else if (data.event == "Device Tampered") {
            data.icon = "assets/img/new_tamp.png";
          } else if (data.event == "Motor Unlocked") {
            data.icon = "assets/img/new_motor.png";
          } else if (data.lockStatus == "Case Open") {
            data.icon = "assets/img/case_openNew.png";
          }
        }
      }

      if (flag) {
        if (data.event == "Sealed" && data.lockStatus == "locked") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Locked",
          });
        } else if (data.event == "Locked" && data.event != "Sealed") {
          // icon = 'assets/img/new_lock.png'
          // size = new google.maps.Size(20, 20)
          // trackData.push({ lat: track[interval].latitude, lng: track[interval].longitude, packetTime: track[interval].packet_time, event: track[interval].event , icon:data.icon })
        } else if (
          data.lockStatus == "unlocked" &&
          data.event != "Unsealed" &&
          data.event != "Unauthorised Unsealing" &&
          data.event != "Authorised Unsealing" &&
          data.event != "Device Tampered"
        ) {
          // trackData.push({ lat: track[interval].latitude, lng: track[interval].longitude, packetTime: track[interval].packet_time, event: track[interval].event, icon:data.icon })
        } else if (data.event == "Unauthorised Unsealing") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Unlocked",
          });
        } else if (data.event == "Authorised Unsealing") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Locked",
          });
        } else if (data.event == "Device Tampered") {
          // tampGlobalflag = true;
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Tampered",
          });
        } else if (data.event == "Unsealed") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Unlocked",
          });
        } else if (data.lockStatus == "motorUnlocked") {
          if (data.event == "Motor Unlocked")
            trackData.push({
              lat: data.latitude,
              lng: data.longitude,
              packetTime: data.packet_time,
              event: data.event,
              lockStatus: "Motor Unlocked",
              icon: data.icon,
            });
        } else if (data.event == "Case Open") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            lockStatus: "Motor Unlocked",
            icon: data.icon,
          });
        }

        if (
          data &&
          data.event_alert &&
          data.event_alert.length > 0 &&
          (data.event_alert.includes("connected") ||
            data.event_alert.includes("disconnected"))
        ) {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event_alert.includes("connected")
              ? "External Battery Connected"
              : "External Battery Disconnected",
            lockStatus: data.lockStatus,
            icon: data.event_alert.includes("connected")
              ? "assets/img/Attached_green.png"
              : "assets/img/Detached_grey.png",
          });
        }
      } else {
        if (data.event == "Unauthorised Unsealing") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Unlocked",
          });
        } else if (data.event == "Authorised Unsealing") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Unlocked",
          });
        } else if (data.event == "Device Tampered") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            icon: data.icon,
            lockStatus: "Tampered",
          });
        } else if (data.lockStatus == "motorUnlocked") {
          if (data.event == "Motor Unlocked")
            trackData.push({
              lat: data.latitude,
              lng: data.longitude,
              packetTime: data.packet_time,
              event: data.event,
              lockStatus: "Motor Unlocked",
              icon: data.icon,
            });
        } else if (data.event == "Case Open") {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            lockStatus: "Back Cover Open",
            icon: data.icon,
          });
        }

        if (
          data.event_alert &&
          data.event_alert.length > 0 &&
          (data.event_alert.includes("connected") ||
            data.event_alert.includes("disconnected"))
        ) {
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event_alert.includes("connected")
              ? "External Battery Connected"
              : "External Battery Disconnected",
            lockStatus: data.lockStatus,
            icon: data.event_alert.includes("connected")
              ? "assets/img/Attached_green.png"
              : "assets/img/Detached_grey.png",
          });
        }
      }
    } catch (e) {
      console.log("handleEventSummaryData: ", e);
    }
  };

  const handleHistoryTrack = () => {
    sendCustomEventAnalytics("device-map", "history-track", "history-track");
    try {
      setLoading(true);
      const payload = {
        fromDate: new Date(filters.from)?.getTime() ?? "",
        toDate: new Date(filters.to)?.getTime() ?? "",
        ...filtersPayload,
      };

      dispatch(
        getHistoryTrack(payload, (res) => {
          if (res) {
            setDeviceTrackData(null);
            setLoading(true);
          }
          setLoading(false);
        })
      );
    } catch (e) {
      console.log("handleHistoryTrack: ", e);
    }
  };

  const handleFastTagData = () => {
    let payload = {
      vehicleNumber: deviceDetails?.vehicleNo || "",
      size: "10000",
      page: 1,
    };
    dispatch(
      getUlipData(payload, (response) => {
        if (response?.data?.success) {
          if (response?.data?.features?.length >= 2) {
            let lastObject =
              response?.data?.features[response?.data?.features?.length - 2];
            let lastlat = lastObject?.geometry?.coordinates[1];
            let lastlng = lastObject?.geometry?.coordinates[0];
            myMap.panTo({
              lat: Number(lastlat),
              lng: Number(lastlng),
            });
          } else {
            let lastObject = response?.data?.features?.features[0];
            let lastlat = lastObject?.geometry?.coordinates[1];
            let lastlng = lastObject?.geometry?.coordinates[0];
            myMap.panTo({
              lat: Number(lastlat),
              lng: Number(lastlng),
            });
          }
          myMap?.data?.addGeoJson(response.data);
          myMap.data.setStyle({
            strokeColor: "#ff7200",
            strokeWeight: 2,
          });
          myMap.data.forEach((feature, index) => {
            if (feature.getProperty("tollPlazaGeocode")) {
              myMap.data.overrideStyle(feature, {
                icon: {
                  url: Fastag,
                  scaledSize: new google.maps.Size(34, 34),
                },
              });
            }
          });

          myMap.data.addListener("click", async (event) => {
            let markerData = {
              readTime: event.feature.getProperty("readerReadTime")
                ? convertIntoGivenTimezone(
                    event.feature.getProperty("readerReadTime"),
                    currentUserDetail?.timeZone?.offset
                  ) || "NA"
                : "",
              tollPlazaName: event.feature.getProperty("tollPlazaName"),
              vehicleRegNo: event.feature.getProperty("vehicleRegNo"),
              vehicleLatLng: event.feature.getProperty("tollPlazaGeocode"),
            };

            const payload = {
              latitude: markerData?.vehicleLatLng[0],
              longitude: markerData?.vehicleLatLng[1],
              appType: "GOOGLE",
            };
            axios
              .post(`${locationUrl}fetchAddress`, payload, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
                },
              })
              .then((response) => {
                let address = response?.data?.data[0]?.address;
                let infoWindowContent = ` 
                <div class='infoDiv' style="width:390px;">
                <div class='infoChild2'>
                  <div class='subChild2' style="width:33%;">
                    <img class='imgCss'  src=${toll} alt='' />
                    <span class='infoLabel label2'>Toll Plaza</span>
                    <span class='infoValue infoValue2'>${markerData?.tollPlazaName}</span>
                  </div>
          
                    <div class='subChild2' style="width:33%;">
                      <img class='imgCss' style='height:34px; width:34px;'  src=${truckIcon} alt='' />
                      <span class='infoLabel label2'>Vehicle Number </span>
                      <span class='infoValue infoValue2'>${markerData?.vehicleRegNo}</span>
                    </div>
          
                  <div class='subChild2' style="width:335;">
                      <img class='imgCss'  src=${dateTime} alt='' />
                      <span class='infoLabel label2'>Time </span>
                      <span class='infoValue infoValue2'>${markerData?.readTime}</span>
                  </div>
          
                 
                </div>
    
                <div class='infoChild3'>
                <div class='addressDiv'>
                  <div class='addressImg'>
                  <img class='imgCss' src=${location} alt='' />
                  </div>
                  <div class='addressBox'>
                  <span class='infoLabel'>${address}</span>
                  </div>
                </div>
                
              </div>
                </div>
                  `;
                let temp = newInfoWindow;

                if (infoWindow) {
                  infoWindow.close();
                }

                temp.setContent(infoWindowContent);
                temp.setPosition(event.latLng);
                temp.open(myMap);
                setInfoWindow(temp);
                setNewInfoWindow(temp);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        } else {
          showToast(response?.message, true);
        }
      })
    );
  };

  const handleTimeBtnClicked = (hours) => {
    sendCustomEventAnalytics("device-map", `${hours}-history-track`, `${hours}-history-track`);
    setLoading(true);
    const newToDateTime = new Date();
    const newFromDateTime = new Date(
      newToDateTime.getTime() - hours * 60 * 60 * 1000
    );

    setFilters((prev) => ({
      ...prev,
      from: newFromDateTime,
      to: newToDateTime,
    }));

    const payload = {
      fromDate: new Date(newFromDateTime)?.getTime() ?? "",
      toDate: new Date(newToDateTime)?.getTime() ?? "",
      ...filtersPayload,
    };
    dispatch(
      getHistoryTrack(payload, (res) => {
        if (res) {
          setDeviceTrackData(null);
          setLoading(true);
          // handleSocketForTrack();
        }
        setLoading(false);
      })
    );
  };

  const handleTrackReport = () => {
    setLoading(true);
    const payload = {
      deviceId: deviceDetails?.client_id,
      fromDate: new Date(filters.from)?.getTime() ?? "",
      toDate: new Date(filters.to)?.getTime() ?? "",
      overstop: filters?.overstoppage ?? "",
      overspeed: filters?.overspeed ?? "",
      emailIds: [],
    };
    dispatch(
      getDeviceTrackReport(payload, (res) => {
        if (res && res?.success) {
          const fileName = res?.message && res?.message?.split("getFile/")[1];

          if (res?.emailFlag) {
            setShowEmailTrackModal(true);
          } else {
            window.open(res?.message, "_blank");
          }
          // dispatch(
          //   getObj({ listObjects: [fileName] }, (res) => {
          //     if (res?.data && res?.data?.length > 0) {
          //       const base64Content =
          //         res?.data[0]?.base64?.split(";base64,")[1];
          //       const binaryString = atob(base64Content);
          //       const byteArray = new Uint8Array(binaryString.length);
          //       for (let i = 0; i < binaryString.length; i++) {
          //         byteArray[i] = binaryString.charCodeAt(i);
          //       }

          //       const blob = new Blob([byteArray], {
          //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          //       });

          //       // Save Blob as Excel file
          //       saveAs(blob, `${"new track file"}.xlsx`);
          //     }
          //   })
          // );
        }
        // else if (res && res?.success && res?.emailFlag) {
        //   setShowEmailTrackModal(true);
        // }
        setLoading(false);
      })
    );
    ///////
  };

  const handleMovementReport = () => {
    //TODO: payload validation
    setLoading(true);
    const payload = {
      deviceId: deviceDetails?.client_id,
      fromDate: new Date(filters.from)?.getTime() ?? "",
      toDate: new Date(filters.to)?.getTime() ?? "",
      overStop: filters?.overstoppage ?? "",
      overSpeed: filters?.overspeed ?? "",
    };
    dispatch(
      getDeviceWiseSummary(payload, (res) => {
        setLoading(false);
        if (res && res?.success) {
          sendCustomEventAnalytics("device-map", "get-movement-report-", "get-movement-report");
          setShowEmailMovementModal(true);
        }
      })
    );
  };

  const handleLockUnlockDevice = (type) => {
    sendCustomEventAnalytics("device-map", `${type}-DEVICE`, `${type}-DEVICE`);
    if (
      havePermission(currentUserDetail, "unlockingOption", "otpForUnlockingWeb")
    ) {
      setModalLoading(true);
      const payload = {
        deviceId: deviceDetails?.client_id,
        page: "device",
        source: "web",
        otp: "",
        type: type ?? "",
      };
      dispatch(
        getLockUnlockOTP(payload, (res) => {
          if (res && res?.success) {
            setUnlockId(res?._id);
            setShowOTPForLockUnlock(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = {
        deviceId: deviceDetails?.client_id,
        page: "device",
        source: "web",
        type: type ?? "",
      };
      dispatch(
        lockUnlockDevice(payload, (res) => {
          if (res?.success) {
            setLockType(null);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleVerifyOtpForLockUnlock = (data) => {
    setModalLoading(true);
    const payload = {
      deviceId: deviceDetails?.client_id,
      page: "device",
      source: "web",
      otp: data?.otp,
      type: lockType ?? "",
      _id: unlockId,
    };
    dispatch(
      verifyLockUnlockOTP(payload, (res) => {
        if (res && res?.success) {
          setLoading(true);
          dispatch(
            lockUnlockDevice(payload, (res) => {
              if (res?.success) {
                setLockType(null);
              }
              setLoading(false);
            })
          );
          setShowOTPForLockUnlock(false);
        }
        setModalLoading(false);
      })
    );
  };

  const handleViewPasscode = () => {
    if (
      havePermission(
        currentUserDetail,
        "unlockingOption",
        "otpForUnlockingPasscode"
      )
    ) {
      setModalLoading(true);
      dispatch(
        getPasscodeOTP({ deviceId: deviceDetails?.client_id }, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(res?._id);
            setShowPasscodeOTPModal(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = { deviceId: deviceDetails?.client_id };
      dispatch(
        showPasscode(payload, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(null);
            setPasscodeValue(res?.result);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleFixedLockPasscode = () => {
    setShowFixedLockPasscodeModal(true);
  };

  const handleVerifyOtpForPasscode = (data) => {
    setModalLoading(true);
    const payload = {
      otp: data?.otp,
      _id: passcodeIdToVerify,
    };

    dispatch(
      verifyPasscodeOTP(payload, (res) => {
        if (res && res?.success) {
          setPasscodeIdToVerify(null);
          setLoading(true);
          dispatch(
            showPasscode({ deviceId: deviceDetails?.client_id }, (res) => {
              if (res && res?.success) {
                setPasscodeValue(res?.result);
                setShowPasscodeOTPModal(false);
              }
              setLoading(false);
            })
          );
        }
        setModalLoading(false);
      })
    );
  };

  const handleGenerateFixedLockPasscode = (data, cb) => {
    setModalLoading(true);
    const payload = {
      randomNo: data?.randomNo,
      deviceId: deviceDetails?.client_id,
    };

    dispatch(
      generatePasscodeForFixedLock(payload, (res) => {
        if (res && res?.success) {
          setPasscodeValue(res?.message);
          cb && cb();
        }
        setModalLoading(false);
      })
    );
  };

  const handleSendMovementReport = (emailIds) => {
    if (deviceMovementReportData && emailIds && emailIds?.length > 0) {
      sendCustomEventAnalytics("device-map", "send-movement-report", "send-movement-report");
      setModalLoading(true);
      const payload = {
        Key: deviceMovementReportData?.Key,
        data: deviceMovementReportData?.data,
        fromDate: dateTimeFormater(filters?.from),
        toDate: dateTimeFormater(filters?.to),
        downloadReportEmail: emailIds,
      };

      dispatch(
        downloadMovementReport(payload, (res) => {
          if (res && res?.success) {
            setShowEmailMovementModal(false);
            dispatch(flushDeviceMovementReport());
          }
          setModalLoading(false);
        })
      );
    }
  };

  const handleSendTrackReport = (emailIds) => {
    sendCustomEventAnalytics("device-map", "send-track-report", "send-track-report");
    if (emailIds && emailIds?.length > 0) {
      setModalLoading(true);
      const payload = {
        deviceId: deviceDetails?.client_id,
        fromDate: new Date(filters.from)?.getTime() ?? "",
        toDate: new Date(filters.to)?.getTime() ?? "",
        // deviceId: "69067266392",
        // fromDate: 1703658454000,
        // toDate: 1703852577000,
        overstop: filters?.overstoppage ?? "",
        overspeed: filters?.overspeed ?? "",
        emailIds: emailIds,
      };

      dispatch(
        getDeviceTrackReport(payload, (res) => {
          if (res && res?.success) {
            setShowEmailTrackModal(false);
            showToast(res?.message);
          }
          setModalLoading(false);
        })
      );
    }
  };

  const handleSendCommand = () => {
    setShowSendCommandModal(true);
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title:'device' });
  }, []);

  // --- EFFECTS ---
  React.useEffect(() => {
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }

      const mapElement = document.getElementById("viewallmap");

      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      if (currentLocation) {
        const mapOptions = {
          zoom: 14,
          center: currentLocation,
          mapTypeId: "terrain",
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          // options: { styles: isDarkThemeEnabledSelector && styles },
        };

        const map = new google.maps.Map(mapElement, mapOptions);
        const marker = new window.google.maps.Marker({
          position: currentLocation,
          map: map,
          title: "Center Marker",
          optimized: false,
          // icon: gifIcon
        });

        marker?.addListener("click", (event) => {
          var feat = event.feature;
          let speedVal;

          if (deviceDetails?.speed) {
            if (deviceDetails["speed"].includes("e")) {
              speedVal = `${parseFloat(deviceDetails["speed"])} Km/h`;
            } else {
              speedVal = `${(Number(deviceDetails["speed"]))?.toFixed(2)} Km/h`;
            }
          } else {
            speedVal = "-";
          }

          const allData = {
            lockStatus: deviceDetails?.lockStatus
              ? deviceDetails["lockStatus"]
              : "-",
            lock_status: deviceDetails?.lock_status
              ? deviceDetails["lock_status"]
              : "",
            batteryPercent: deviceDetails?.battery_voltage
              ? `${Number(deviceDetails["battery_voltage"])?.toFixed(2)}%`
              : "-",
            speedVal: speedVal,
            vehicleStatus: deviceDetails?.ignition_status
              ? deviceDetails["ignition_status"]
              : "-",
            distanceVal: deviceDetails?.distance
              ? deviceDetails["distance"]
              : "-",
            time: deviceDetails?.packet_time
              ? convertIntoGivenTimezone(
                  new Date(Number(deviceDetails?.packet_time) * 1000),
                  currentUserDetail.timeZone.offset
                )
              : "-",
            eventName: deviceDetails?.event ?? "-",
            vehicleNumber: deviceDetails?.vehicleNo ?? "-",
            gpsVal: deviceDetails?.gps ?? "-",
            geofenceNames: deviceDetails?.geoName ?? "",
          };

          const payload = {
            latitude: deviceDetails?.latitude ? deviceDetails["latitude"] : "",
            longitude: deviceDetails?.longitude
              ? deviceDetails["longitude"]
              : "",
            appType: "GOOGLE",
          };

          axios
            .post(`${locationUrl}fetchAddress`, payload, {
              headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
              },
            })
            .then((response) => {
              allData.address = response?.data?.data[0]?.address;
              trackInfoRenderer(allData, event, map);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
        setMymap(map);

        if (geofenceListingForMap && geofenceListingForMap?.length > 0) {
          geofenceRenderer(map, geofenceListingForMap);
          //   geofenceListingForMap?.forEach((featureCollection) => {
          //     featureCollection.features.forEach((feature) => {
          //       geofenceRenderer(map, feature);
          //     });
          //   });
        }
        return map;
      }
    }, 40);
  }, [geofenceListingForMap, currentLocation]);

  React.useEffect(() => {
    if (deviceDetails) {
      var pos = {
        lat: Number(deviceDetails?.latitude),
        lng: Number(deviceDetails?.longitude),
      };
      setCurrentLocation(pos);
    }
  }, [deviceDetails]);

  React.useEffect(() => {
    if (id) {
      try {
        setLoading(true);
        setDeviceTrackData(null);
        setShowEventSummary(false);
        setEventSummaryData([]);
        dispatch(getDeviceDetails({ id }));
        dispatch(
          getAllGeofencesForMap(
            {
              filter: {
                orgId: currentUserDetail?.orgId,
              },
            },
            () => {
              setLoading(false);
            }
          )
        );
        setLockType(null);
        setPasscodeValue(null);
        setShowEmailMovementModal(false);
        setShowEmailTrackModal(false);
        setShowFixedLockPasscodeModal(false);
        setShowSendCommandModal(false);
        setShowPasscodeOTPModal(false);
        setModalLoading(false);
        setFilters({
          from: new Date(),
          to: new Date(),
          overstoppage: "",
          overspeed: "",
        });
      } catch (e) {
        console.log("error initial: ", e);
      }
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (deviceTrackData) {
      handleRenderTrackOnMap(deviceTrackData);
    }
  }, [deviceTrackData]);

  React.useEffect(() => {
    if (socketInstance && connected) {
      socketInstance?.on("data", (response) => {
        if (response) {
          if (response?.data) {
            dispatch(
              getTrackData({ file: response?.data?.file }, (res) => {
                if (res) {
                  setDeviceTrackData(res);
                  // handleRenderTrackOnMap();
                  setTotalDistance(res?.totalDistance);
                }
                setLoading(false);
              })
            );
          }
        }
      });

      return () => {
        socketInstance.off("data");
      };
    }
  }, [socketInstance, connected]);

  React.useEffect(() => {
    if (myMap) {
      const newMapOptions = {
        zoom: 14,
        center: currentLocation,
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        options: { styles: isDarkThemeEnabledSelector && styles },
      };
      myMap?.setOptions(newMapOptions);
    }
  }, [myMap, currentLocation, isDarkThemeEnabledSelector]);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  // In your React component
  React.useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
          setNewInfoWindow(new google.maps.InfoWindow());
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box width="100%" height="89vh" position="relative" display="flex">
      {/* <Box width="100%" height="90vh" position="relative" display="flex"> */}
      {/* {loading ? (
      ) : (
      )} */}
      {loading && (
        <Box
          bgcolor="background.paper"
          style={{
            zIndex: 1000,
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loaderGif} />
        </Box>
      )}
      <>
        {deviceDetails && deviceDetails?.deviceType === "camera" ? (
          <></>
        ) : (
          <>
            <Box width="100%">
              <Box height="58px">
                <Typography sx={{ ...textClasses.cardTitle }}>
                  Device Map
                </Typography>
                <Breadcrumbs data-testid="breadcrumbs">
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      fontSize: "12px",
                      color: themeType.default.themeOrange,
                    }}
                  >
                    <Link
                      data-testid="link-to-go-back"
                      style={{
                        color: themeType.default.themeOrange,
                        textDecoration: "none",
                      }}
                      to={{
                        pathname: "/dashboard/devices",
                        state: {
                          from: "device-map-page",
                        },
                      }}
                    >
                      Devices
                    </Link>
                  </Typography>
                  {/* <Typography
                    sx={{
                      ...textClasses.normalText,
                      fontSize: "12px",
                      color: themeType.default.themeOrange,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push("/dashboard/devices", {
                        from: "device-map-page",
                      })
                    }
                  >
                    Devices
                  </Typography> */}

                  <Typography
                    sx={{ ...textClasses.normalText, fontSize: "12px" }}
                  >
                    Device Map
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Box height="calc(100% - 58px)">
                {mapLoaded && (
                  <div
                    id="viewallmap"
                    className="drawMap"
                    style={{ width: "100%", height: "100%" }}
                  />
                )}
              </Box>
            </Box>
            <DeviceMapDrawer
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              themeType={themeType}
              filters={filters}
              setFilters={setFilters}
              deviceDetails={deviceDetails}
              isDrawerOpen={isDrawerOpen}
              passcodeValue={passcodeValue}
              resetPasscodefn={() => setPasscodeValue(null)}
              setIsDrawerOpen={setIsDrawerOpen}
              toggleDrawer={handleToggleDrawer}
              isDark={isDarkThemeEnabledSelector}
              setLockType={setLockType}
              showEventSummary={showEventSummary}
              eventSummaryData={eventSummaryData}
              setEventSummaryData={setEventSummaryData}
              setShowEventSummary={setShowEventSummary}
              handleHistoryTrack={handleHistoryTrack}
              handleFastTagData={handleFastTagData}
              handleTimeBtnClicked={handleTimeBtnClicked}
              handleTrackReport={handleTrackReport}
              handleLockUnlockDevice={handleLockUnlockDevice}
              handleViewPasscode={handleViewPasscode}
              handleFixedLockPasscode={handleFixedLockPasscode}
              handleMovementReport={handleMovementReport}
              handleSendCommand={handleSendCommand}
              currentUserDetail={currentUserDetail}
              totalDistance={totalDistance}
              socketInstance={socketInstance}
            />
            <DrawerFloaterLabel show={isDrawerOpen} setShow={setIsDrawerOpen} />
          </>
        )}
      </>

      <OTPModal
        open={showOTPForLockUnlock}
        setOpen={setShowOTPForLockUnlock}
        themeType={themeType}
        loading={modalLoading}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        handleVerifyFn={handleVerifyOtpForLockUnlock}
      />
      <OTPModal
        open={showPasscodeOTPModal}
        setOpen={setShowPasscodeOTPModal}
        themeType={themeType}
        loading={modalLoading}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        handleVerifyFn={handleVerifyOtpForPasscode}
      />
      <FixedLockPasscodeModal
        open={showFixedLockPasscodeModal}
        setOpen={setShowFixedLockPasscodeModal}
        themeType={themeType}
        loading={modalLoading}
        passcodeValue={passcodeValue}
        setPasscodeValue={setPasscodeValue}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        handleVerifyFn={handleGenerateFixedLockPasscode}
      />
      <DeviceCommandModal
        open={showSendCommandModal}
        setOpen={setShowSendCommandModal}
        deviceDetails={deviceDetails}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        themeType={themeType}
      />
      <EnterEmailModal
        open={showEmailMovementModal}
        hide={() => {
          setShowEmailMovementModal(false);
          dispatch(flushDeviceMovementReport());
        }}
        loading={modalLoading}
        onSubmitFn={handleSendMovementReport}
      />
      <EnterEmailModal
        open={showEmailTrackModal}
        hide={() => {
          setShowEmailTrackModal(false);
        }}
        loading={modalLoading}
        onSubmitFn={handleSendTrackReport}
      />
      <CameraFullScreenMode
        open={fullScreenCamera}
        setOpen={setFullScreenCamera}
      />
    </Box>
  );
};

export default DeviceMap;
