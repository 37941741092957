/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getAllGeofencesForMap } from "../../../redux/actions";
import { Card, CardHeader, CardContent } from "@mui/material";
import { selectStylesOverride } from "../../../utils/util";
import {
  convertIntoGivenTimezone,
  dateTimeFormater,
} from "../../../services/functions";
import loaderGif from "../../../static/images/drawer/loader.gif";
import EmptyPage from "../EmptyPage";
const ViewAllGeofence = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mapLoader, setMapLoader] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [myMap, setMymap] = useState(null);
  const geofenceListingForMap = useSelector(
    (state) => state.geofencelist.geofenceListingForMap
  );
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  var objs = {
    lat: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][1]
    ),
    lng: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][0]
    ),
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  var infoWindows = [];

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const { buttonClasses, inputClasses, cardClasses, textClasses } = themeType;

  useEffect(() => {
    const fetchAllGeosForMap = () => {
      setPageLoading(true);
      dispatch(
        getAllGeofencesForMap({}, () => {
          setPageLoading(false);
        })
      );
    };

    fetchAllGeosForMap();
  }, []);
  const mapElement = document.getElementById("viewallmap") || "";

  useEffect(() => {
    const loadGoogleMap = () => {
      if (mapLoader) {
        if (
          typeof google === "undefined" ||
          typeof google.maps === "undefined"
        ) {
          return null;
        }
        if (!mapElement) {
          console.error("Element with id 'viewallmap' not found.");
          return null;
        }
        if (!objs.lat || !objs.lng) {
          console.error("Latitude or longitude not provided.");
          return null;
        }
        const mapOptions = {
          zoom: 12,
          center: { lat: parseFloat(objs.lat), lng: parseFloat(objs.lng) },
          mapTypeId: "terrain",
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
        };

        const map = new google.maps.Map(mapElement, mapOptions);

        setMymap(map);
        return map;
      }
    };

    const timeout = setTimeout(loadGoogleMap, 40);
    setTimeout(() => {
      setMapLoader(true);
    }, 100);
    return () => clearTimeout(timeout);
  }, [geofenceListingForMap, objs.lat, objs.lng, mapElement]);

  useEffect(() => {
    if (myMap) {
      const outputJson = {
        type: "FeatureCollection",
        features: geofenceListingForMap.map((location) => ({
          type: "Feature",
          properties: location.features[0].properties,
          geometry: location.features[0].geometry,
        })),
      };
      myMap?.data?.addGeoJson(outputJson);
      myMap?.data?.setStyle(function (feature) {
        return {
          fillColor: "#0059ff",
          strokeColor: "#0059ff",
          strokeOpacity: 0.8,
          strokeWeight: 1.5,
          fillOpacity: 0.4,
        };
      });
      myMap?.data?.addListener("click", function (event) {
        let props = {
          name: event.feature.getProperty("name"),
          status: event.feature.getProperty("status"),
          addedOn: convertIntoGivenTimezone(
            event.feature.getProperty("addedOn"),
            userDetails.timeZone.offset
          ),
          addedBy: event.feature.getProperty("addedBy"),
          centerCoordinates: event.feature.getProperty("centerCoordinates"),
        };
        infoWindowRederer(props);
      });
    }
  }, [myMap]);

  const infoWindowRederer = (props) => {
    const coordinates = props.centerCoordinates[0];
    const infoWindowContent = `
        <div style="min-width:310px">
            <div> <span style="display: inline-block; width: 80px; color: #909090; ">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; ">${props.name}</span></div><br>
            <div><span style="display: inline-block; width: 80px; color: #909090; " >Status:</span> <span style="color: #000; font-Weight: 500;">  ${props?.status}</span><br><br>
            <div><span style="display: inline-block; width: 80px; color: #909090; ">Added By:</span><span style="color: #000; font-Weight: 500; "> ${props?.addedBy?.name}</span><br><br>
            <div><span style="display: inline-block; width: 80px; color: #909090; ">Added On:</span><span style="color: #000; font-Weight: 500; "> ${props?.addedOn}</span><br><br>
        </div>
        `;
    const infoWindow = createInfoWindow(infoWindowContent, {
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    myMap.panTo({
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    infoWindows.forEach((window) => window.close());
    infoWindow.open(myMap);
    infoWindows.push(infoWindow);
  };

  function createInfoWindow(content, position) {
    return new google.maps.InfoWindow({
      content: content,
      position: position,
    });
  }

  const infoWindowRedereronClick = (item) => {
    let props = {
      name: item?.features[0]?.properties?.name,
      status: item.features[0]?.properties?.status,
      addedOn: convertIntoGivenTimezone(
        item.features[0]?.properties?.addedOn,
        userDetails.timeZone.offset
      ),
      addedBy: item.features[0]?.properties?.addedBy,
      centerCoordinates: item.features[0]?.properties?.centerCoordinates,
    };
    infoWindowRederer(props);
  };

  return (
    <>
      <Grid container sx={{ height: "77vh", position: "relative" }}>
        <Grid item xs={12} height="75px">
          <Grid item sm={12} xs={12}>
            <Grid container>
              <Grid item sm={6} xs={4}>
                <Typography sx={{ ...textClasses.cardTitle }}>
                  View Geofence
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                xs={6}
                sx={{
                  displey: "flex",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "36px",
                }}
              >
                {/* <Button
                  data-testid="back"
                  onClick={history.goBack}
                  variant="outlined"
                  size="small"
                  sx={{
                    ...buttonClasses.small,
                    color: buttonClasses.lynkitBlackFill,
                  }}
                >
                  Back
                </Button> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumbs">
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: themeType.default.themeOrange,
                }}
              >
                <Link
                  data-testid="geofence"
                  style={{
                    color: themeType.default.themeOrange,
                    textDecoration: "none",
                  }}
                  to="/dashboard/geofence"
                >
                  Geofence
                </Link>
              </Typography>

              <Typography
                data-testid="viewgeofence"
                sx={{ ...textClasses.normalText, fontSize: "12px" }}
              >
                view-all-geofence
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        {pageLoading && (
          <Box
            bgcolor="background.paper"
            style={{
              zIndex: 1000,
              height: "calc(100vh - 70px)",
              width: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "70px"
            }}
          >
            <img src={loaderGif} />
          </Box>
        )}
        <>
          {geofenceListingForMap?.length === 0 ? (
            <EmptyPage body="No Geofences Found" />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overflow: "hidden",
                border: "1px solid #E8E8E8",
              }}
            >
              {mapLoader ? (
                <div
                  id="viewallmap"
                  style={{ width: "100%", height: "100%" }}
                ></div>
              ) : (
                <Grid
                  container
                  item
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  xs={12}
                  sx={{ p: 15 }}
                >
                  <Grid item>
                    <img data-testid="loaderImg" src={loaderGif} />
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <div
                  id="customDrawer"
                  className={`drawer ${isDrawerOpen ? "open" : ""}`}
                  style={{ overflowY: "auto" }}
                >
                  <Grid
                    container
                    item
                    md={12}
                    pl={3}
                    pr={3}
                    pt={2}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        ...textClasses.boldTextBlack,
                        textDecoration: "none",
                        fontSize: "20px",
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                      }}
                    >
                      {" "}
                      Total Count - {geofenceListingForMap.length}
                    </Typography>
                  </Grid>
                  {geofenceListingForMap &&
                    geofenceListingForMap.map((item, index) => {
                      return (
                        <Card
                          key={index}
                          onClick={() => infoWindowRedereronClick(item)}
                          sx={{
                            ...cardClasses.basic,
                            borderRadius: "8px",
                            position: "relative",
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginTop: "15px",
                            cursor: "pointer",
                          }}
                        >
                          <CardContent ml={3} mr={3}>
                            <Typography className="sNoBox">
                              {index + 1}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              style={{
                                color: themeType.default.themeOrange,
                                fontWeight: "600",
                              }}
                            >
                              {item?.features[0]?.properties?.name || "-"}
                            </Typography>
                            <Typography variant="body2" color="#000">
                              {item?.features[0]?.properties?.addedBy?.name ||
                                "-"}
                            </Typography>
                            <Typography variant="body2" color="#000">
                              {dateTimeFormater(
                                item?.features[0]?.properties?.addedOn || "-"
                              )}
                            </Typography>
                            {/* <Typography variant="body2" color="#000">
                                              {dateTimeFormater(item?.features[0]?.properties?.addedOn || '-')}
                                          </Typography> */}
                          </CardContent>
                        </Card>
                      );
                    })}
                </div>
                {isDrawerOpen ? (
                  <span
                    className="circleElem"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "29%",
                      zIndex: "200",
                      transition: "right 0.4s",
                    }}
                  >
                    <ArrowForwardIosIcon onClick={toggleDrawer} />
                  </span>
                ) : (
                  <span
                    className="circleElem"
                    style={{
                      position: "absolute",
                      top: "0px",
                      right: "-1%",
                      zIndex: "200",
                      transition: "right 0.4s",
                    }}
                  >
                    <ArrowBackIosIcon onClick={toggleDrawer} />
                  </span>
                )}
              </Grid>
            </Box>
          )}
        </>
      </Grid>
    </>
  );
};

export default ViewAllGeofence;
