import * as React from "react";
import history from "../../services/history";
import { styled, useTheme, alpha } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import AccountCircle from "@mui/icons-material/AccountCircle";

import {
  Download,
  ExitToAppOutlined,
  Http,
  Https,
  InfoOutlined,
  Person,
  PhoneOutlined,
  Upload,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  Menu,
  MenuItem,
  Box,
  Button,
  ThemeProvider,
  createTheme,
  Avatar,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import "../../static/css/dashboard2.css";
import {
  changePassword,
  darkTheme,
  disconnectFromSocket,
  flushCurrentUserDetail,
  flushUserId,
  lightTheme,
  logoutFromSocket,
  updateTheme,
  userLogout,
} from "../../redux/actions";
import LynkitLogo from "../../static/images/login/Lynktrac.png";
import colors, {
  buttonClasses,
  textClasses,
  tableClasses,
} from "../../static/styles/theme";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import ChangePasswordModal from "./ChangePasswordModal";
import { useEffect } from "react";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //   ...(open && {
  //     marginLeft: drawerWidth,
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     transition: theme.transitions.create(['width', 'margin'], {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //   }),
}));


export default function MiniDrawer({ open, handleDrawerOpen, userDetail, handleResponsiveDrawerToggle }) {
  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [theme, setTheme] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const sideBarValue = localStorage.getItem('sidebar')
  const themeType = isDarkThemeEnabledSelector
    ? require("../../static/styles/darktheme")
    : require("../../static/styles/theme");
  const {
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;
  // const userData = JSON.parse(localStorage.getItem("user"))

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(userLogout((res) => {
      if (res) {
        localStorage.removeItem("token");
        localStorage.removeItem("orgId");
        localStorage.removeItem("isSuperAdmin");
        dispatch(logoutFromSocket(userDetail?._id))
        dispatch(disconnectFromSocket())
        dispatch(flushUserId());
        dispatch(flushCurrentUserDetail());
        history.push("/login");
      }
    }))
  };

  const handleChangePassword = (payload) => {
    setChangePasswordLoading(true);
    dispatch(
      changePassword(
        payload,
        (res) => {
          if (res) {
            setOpenChangePassword(false);
            setChangePasswordLoading(false);
          }
        },
        () => {
          setChangePasswordLoading(false);
        }
      )
    );
  };


  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <ThemeProvider theme={isDarkThemeEnabledSelector ? "dark" : "dark"}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem >
          <Grid container >
            <Grid
              container
              item
              sm={3}
              justifyContent="center"
              alignItems="center"
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {/* {userData.name[0]} */}
              </Avatar>
            </Grid>
            <Grid
              container
              item
              direction={"column"}
              sm={9}
              justifyContent="center"
              sx={{ pl: 2 }}
            >
              <Typography
                sx={{
                  ...textClasses.boldText,
                  color: colors.themeOrange,
                  textTransform: "capitalize",
                  maxWidth: 120,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userDetail?.name || "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "17px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                {userDetail?.userRole ? userDetail?.userRole?.name : "-"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  lineHeight: "17px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                }}
              >
                {userDetail?.userCode || "-"}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {sideBarValue ? null :
          <MenuItem onClick={() => { setOpenChangePassword(true); setAnchorEl(null); }} dense>
            <Https sx={{ mr: 2 }} />
            <Typography variant="caption">Change Password</Typography>
          </MenuItem>
        }
        <MenuItem onClick={() => handleLogout()} dense>
          <ExitToAppOutlined sx={{ mr: 2 }} />
          <Typography variant="caption">Logout</Typography>
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
  const toggleTheme = () => {
    if (theme) {
      dispatch(darkTheme());
    } else {
      dispatch(lightTheme());
    }
    setTheme(!theme);
  };

  const myTheme = createTheme(theme);
  useEffect(() => {
    if (isDarkThemeEnabledSelector !== undefined || isDarkThemeEnabledSelector !== null) {
      setTheme(isDarkThemeEnabledSelector)
    }
  }, [isDarkThemeEnabledSelector]);

  return (
    <Box sx={{display: {xs: "none", md: "block"}}}>
      <ThemeProvider theme={isDarkThemeEnabledSelector ? "dark" : "dark"}>
        <AppBar
          position="fixed"
          open={open}
          sx={{ ...tableClasses.sideBarandHeader, display: {xs: "none", md: "flex"}}}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleResponsiveDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <img src={LynkitLogo} height="40" />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "flex" } }}>
              {sideBarValue ? null :
                !theme ? (
                  <span
                    className="theme-toggle"
                    sx={{ mr: 2 }}
                    style={{ color: "#000", paddingTop: "3px" }}
                    onClick={toggleTheme}
                  >
                    <BsFillSunFill
                      className="sun-icon"
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                ) : (
                  <span
                    className="theme-toggle"
                    style={{ color: "#BEBEBE", paddingTop: "3px" }}
                    onClick={toggleTheme}
                  >
                    <BsFillMoonStarsFill
                      className="moon-icon"
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                )}
              <IconButton
                sx={{ p: 0 }}
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {/* {userData.name[0]} */}
                </Avatar>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <ChangePasswordModal
          open={openChangePassword}
          hide={() => setOpenChangePassword(false)}
          loading={changePasswordLoading}
          handleChangePassword={handleChangePassword}
        />
      </ThemeProvider>
    </Box>
  );
}
