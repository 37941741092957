import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { KeyboardArrowDown, Search } from "@mui/icons-material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  updateUsers,
  deleteUsers,
  deleteUser,
  getAllOrganisations,
  getPermissionRoles,
  getUsersStatusWiseCount,
  disableEnableUsers,
} from "../../../redux/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { selectStylesOverride } from "../../../utils/util";
import UsersTable from "./components/UsersTable";
import DetailCard from "./components/DetailCard";
import GenericFilterTabs from "../../Components/Generic/GenericFilterTabs";
import Actions from "../../../utils/constants";
import DisableDeleteOrganization from "./components/DisableDeleteOrganization";
import { format } from "date-fns";
import EmptyPage from "../EmptyPage";
import history from "../../../services/history";
import { debounce } from "lodash";
import loaderGif from "../../../static/images/drawer/loader.gif";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { convertIntoGivenTimezone } from "../../../services/functions";
import { useDebounce } from "../../../utils/hooks";
import { havePermission } from "../../../utils/permissionCheck";

let payload = {
  page: 1,
  limit: 10,
  // selection: [
  //   "name",
  //   "email",
  //   "mobile",
  //   "orgId",
  //   "orgName",
  //   "addedBy",
  //   "addedOn",
  //   "modifiedBy",
  //   "modifiedOn",
  //   "isApproved",
  //   "userRole",
  // ],
};

export default function Users() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableIndex, setTableIndex] = useState("");
  const Users = useSelector((state) => state.userlist.allUsers);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const userTimeZone = useSelector((state) => state.auth.currentUserDetail);
  const [editBlock, setEditBlock] = useState(false);
  const [searchFields, setSearchFields] = useState({
    org: null,
    userRole: null,
  })
  const [checkedObj, setCheckedObj] = useState([]);
  const totalRows = useSelector((state) => state.userlist.totalRows);
  const [actionStatus, setActionStatus] = useState(Actions.ADD);
  const [loader, setLoader] = useState(true);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDisableBlock, setDeleteDisableBlock] = useState(false);
  const allPermissionRoles = useSelector(
    (state) => state.userPermission.permissionRoles
  );
  const usersCount = useSelector((state) => state.userlist.usersCount);
  const loggedInUserDetail = useSelector(
    (state) => state.auth.currentUserDetail
  );

  const [filter, setFilter] = useState({ ...payload });
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { OrganisationListing } = useSelector(
    (state) => state.Organisationlisting
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses } = themeType;

  const isSuperAdmin = loggedInUserDetail?.adminSpecificPermissions;
  // const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  useEffect(() => {
    if (loggedInUserDetail) {
      if (loggedInUserDetail?.adminSpecificPermissions) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
        setSelectedOrgId(null);
        // dispatch(getPermissionRoles({ selection: ["name"] }));
      } else {
        setFilter((prev) => ({
          ...prev,
          orgId: loggedInUserDetail?.orgId,
        }));
        dispatch(
          getPermissionRoles({
            orgId: loggedInUserDetail?.orgId,
            selection: ["name"],
          })
        );
        setSelectedOrgId(loggedInUserDetail?.orgId);
      }
    }
  }, [loggedInUserDetail]);

  useEffect(() => {
    if (checkedObj.length >= 2) {
      setSelectedUser(null);
    }
  }, [setSelectedUser, checkedObj]);

  const handleSelect = (e, index) => {
    let data = [...tableData];
    let result = [...checkedObj];
    let id = data[index]._id;
    let name = data[index].name;
    let approved = data[index].isApproved;

    if (!result.includes(id)) {
      setTableIndex(index);
      result.push(id);

      setCheckedObj(result);
      handleAddSelection(id, name, approved);
    } else {
      result.splice(result.indexOf(id), 1);
      setCheckedObj(result);
      handleRemoveSelection(id);
    }
  };

  const handleRemoveSelection = (id) => {
    let tempId = id || "";
    let oldTemplates = [...selectedTemplates];
    const i = oldTemplates.map((o) => o.id).indexOf(tempId);
    if (i >= 0) {
      oldTemplates.splice(i, 1);
      setSelectedTemplates(oldTemplates);
    }
  };

  const handleAddSelection = (id, name, approved) => {
    let obj = {
      name: name,
      id: id,
      status: approved,
    };
    setSelectedTemplates([...selectedTemplates, obj]);
  };

  const handleClose = (action) => {
    checkedObj.length = 0;
    setAnchorEl(null);
    handleAction(action, tableIndex);
    setActionIndex(null);
  };

  let tableColumns = [
    {
      selector: "checkbox",
      header: (
        <Typography sx={{ color: themeType.default.themeOrange }}>
          <Checkbox
            sx={{
              "&, &.Mui-checked": { color: themeType.default.themeOrange },
            }}
            checked={
              (tableData || [])
                .map((item) => {
                  return item._id;
                })
                .every((el) => checkedObj.includes(el)) &&
                tableData.length !== 0
                ? true
                : false
            }
            onChange={(event) => allHandleChecked(event)}
            size="small"
          />
        </Typography>
      ),
      cell: (index, entry) => {
        return (
          <Typography
            sx={{ color: themeType.default.themeOrange, textAlign: "center" }}
          >
            <Checkbox
              sx={{
                "&, &.Mui-checked": { color: themeType.default.themeOrange },
              }}
              data-testid="checkbox"
              checked={checkedObj.includes(entry._id) ? true : false}
              onChange={(event) => handleSelect(event, index)}
              size="small"
            />
          </Typography>
        );
      },
    },
    {
      key: "Sno",
      header: "#",
      cell: (key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
            }}
          >
            {(filter.page - 1) * filter.limit + key + 1}
          </Typography>
        );
      },
    },
    {
      key: "name",
      header: "Name",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              color: themeType.default.themeOrange,
              cursor: "pointer",
            }}
            onClick={() => setSelectedUser(entry)}
          >
            <Tooltip arrow title={entry?.name ?? "-"} placement="top">
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {entry && entry?.name.length > 14
                  ? entry?.name.substring(0, 14) + "..."
                  : entry?.name ?? "-"}
              </Typography>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      key: "email",
      header: "Email",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.email ?? "-"}
          </Typography>
        );
      },
    },

    {
      key: "phone",
      header: "Phone",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.mobile ?? "-"}
          </Typography>
        );
      },
    },
    {
      key: "orgName",
      header: "Company Name",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.orgName ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color:
                entry?.isApproved == true
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.isApproved == true ? "Active" : "Blocked"}
          </Typography>
        );
      },
    },
    {
      header: "User Role",
      cell: (entry) => {
        return (
          <Tooltip
            placement="top"
            arrow
            title={
              entry?.userRoles && entry?.userRoles[0]?.name
                ? entry?.userRoles[0]?.name
                : "-"
            }
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {entry?.userRoles && entry?.userRoles[0]?.name.length > 15
                ? entry?.userRoles[0]?.name?.substring(0, 15) + "..."
                : entry?.userRoles[0]?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      header: "Added By",
      cell: (entry) => {
        return (
          <Tooltip placement="top" arrow title={entry?.addedBy?.name ?? "-"}>
            <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
              {entry && entry?.addedBy?.name.length > 14
                ? entry?.addedBy?.name.substring(0, 14) + "..."
                : entry?.addedBy?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      header: "Added On",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {convertIntoGivenTimezone(entry?.addedOn, userTimeZone.timeZone.offset || "-")}
          </Typography>
        );
      },
    },
    {
      header: "Actions",
      cell: (index) => {
        return (
          <>
            <Typography
              data-testid="actions"
              sx={{
                ...textClasses.normalText,
                color: themeType.default.themeOrange,
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={(e) => handleOpenAction(e, index)}
            >
              Action <KeyboardArrowDown />
            </Typography>
          </>
        );
      },
    },
  ];

  const allHandleChecked = (e) => {
    let data = [...tableData];
    let result = [...checkedObj];
    if (e.target.checked === true) {
      result.length = 0;
      for (let i = 0; i < tableData.length; i++) {
        let id = data[i]._id;
        result.push(id);
        setCheckedObj(result);
        let sT = tableData.map((data) => ({
          name: data?.name || "",
          id: data?._id,
          status: data?.isApproved,
        }));
        setSelectedTemplates(sT);
      }
    } else {
      result.length = 0;
      setCheckedObj(result);
      setSelectedTemplates([]);
    }
  };

  useEffect(() => {
    dispatch(getUsersStatusWiseCount());
    setSelectedOrgId(null);
  }, []);

  const debounceSearch = () => {
    dispatch(getAllUsers(filter));
  };

  useEffect(() => {
    let timeoutId;
    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
        setLoader(false);
      };
    }
    if (filter && filter?.searchValue && filter?.searchValue?.length < 3) {
      setLoader(false);
      return;
    }
    if (filter || filter?.searchValue === "") {
      dispatch(
        getAllUsers(filter, () => {
          setLoader(false);
        })
      );
    }
  }, [dispatch, filter?.searchValue]);

  console.log("filter: ", filter);

  useEffect(() => {
    if (
      filter?.orgId ||
      filter?.userRole ||
      filter?.limit ||
      filter?.page ||
      filter?.createdOnDate ||
      filter?.isApproved
    ) {
      dispatch(
        getAllUsers(filter, () => {
          setLoader(false);
        })
      );
    }
  }, [
    filter?.orgId,
    filter?.userRole,
    filter?.limit,
    filter?.page,
    filter?.createdOnDate,
    filter?.isApproved
  ]);

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
    setTableIndex(i);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleAction = (action, index) => {
    setSelectedTemplates([]);
    let data = [...tableData];
    let result = [...checkedObj];
    if (
      action === Actions.DELETE ||
      action === Actions.BLOCK ||
      action === Actions.ACTIVE
    ) {
      let id = data[index]._id;
      let name = data[index].name;
      let approved = data[index].isApproved;

      result.push(id);
      setDeleteDisableBlock(true);
      // setEditBlock(true);
      setActionStatus(action);
      setCheckedObj(result);
      let obj = {
        name: name,
        id: id,
        status: approved,
      };
      setSelectedTemplates([obj]);
    }
  };

  const isFilterApplied = useMemo(() => {
    const filterArray = Object.keys(filter);
    if (
      Object.keys(filter).some((filter) =>
      isSuperAdmin ? ["createdOnDate", "searchValue", "orgId", "userRole"].includes(filter)
       : ["createdOnDate", "searchValue", "userRole"].includes(filter)
      )
    ) {
      return true;
    }
    return false;
  }, [filter]);

  const handleRefresh = useCallback(() => {
    setLoader(true);
    dispatch(
      getAllUsers(filter, () => {
        setLoader(false);
      })
    );
    dispatch(getUsersStatusWiseCount());
  }, [dispatch, filter]);

  useEffect(() => {
    if (Users) {
      setTableData(Users);
    }
  }, [Users]);

  const handleActionSubmission = useCallback(
    (remark) => {
      let bodyData;
      if (actionStatus === Actions.DELETE) {
        if (selectedTemplates.length == 1) {
          bodyData = {
            id: selectedTemplates[0].id,
          };
          dispatch(
            deleteUser(bodyData, () => {
              setCheckedObj([]);
              setSelectedTemplates([]);
              handleRefresh();
            })
          );
        } else {
          bodyData = {
            ids: selectedTemplates.map((st) => st.id),
          };
        }
        dispatch(
          deleteUsers(bodyData, () => {
            setCheckedObj([]);
            setSelectedTemplates([]);
            handleRefresh();
          })
        );
      } else if (
        actionStatus === Actions.BLOCK ||
        actionStatus === Actions.ACTIVE
      ) {
        bodyData = {
          ids:
            selectedTemplates.length == 1
              ? [selectedTemplates[0].id]
              : selectedTemplates.map((st) => st.id),
          isApproved: actionStatus === Actions.ACTIVE ? true : false,
        };
        dispatch(
          disableEnableUsers(
            actionStatus === Actions.BLOCK && remark !== ""
              ? { ...bodyData, remark: remark }
              : bodyData,
            () => {
              setCheckedObj([]);
              setSelectedTemplates([]);
              handleRefresh();
            }
          )
        );
      }
      setDeleteDisableBlock(false);
    },
    [
      dispatch,
      setDeleteDisableBlock,
      actionStatus,
      handleRefresh,
      selectedTemplates,
    ]
  );

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleSearchUserRole = (empty) => {
    let rolesPayload = {
      selection: ["name"],
    }
    if(loggedInUserDetail?.adminSpecificPermissions){
      rolesPayload.orgId = selectedOrgId
    }

    if (empty === true) {
      dispatch(getPermissionRoles({ ...rolesPayload}));
    } else {
      if (searchFields?.userRole || searchFields?.userRole == "")
        dispatch(
          getPermissionRoles({
            ...rolesPayload,
            searchValue: searchFields.userRole,
          })
        );
    }
  };

  function checkIsActive() {
    return (
      !!selectedTemplates?.length && selectedTemplates.every((st) => st?.status)
    );
  }
  function checkIfBothStatus() {
    return (
      !!selectedTemplates?.length &&
      selectedTemplates.some((st) => st?.status) &&
      selectedTemplates.some((st) => !st?.status)
    );
  }

  const isActive = checkIsActive();
  const isBlock = !isActive;
  const isBoth = checkIfBothStatus();

  const handlePaginationChange = useCallback(() => {
    setSelectedTemplates([]);
    setCheckedObj([]);
  }, [setSelectedTemplates, setCheckedObj]);

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Grid container>
          <Grid item sm={6} xs={4} data-testid="pageTitle">
            <Typography role="span" sx={{ ...textClasses.cardTitle }}>
              Manage Users
            </Typography>
          </Grid>

          <Grid
            container
            item
            sm={6}
            xs={6}
            sx={{ justifyContent: "flex-end" }}
          >
            {havePermission(loggedInUserDetail, "user", "addUser") && (
              <Grid
                item
                sm={6}
                xs={6}
                sx={{
                  displey: "flex",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "36px",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  data-testid="addUser"
                  sx={{
                    ...buttonClasses.small,
                    minHeight: "36px",
                    borderColor: themeType.default.themeOrange,
                    color: buttonClasses.lynkitOrangeEmpty,
                  }}
                  startIcon={<AddIcon />}
                  onClick={() => history.push("/dashboard/users/add-user")}
                >
                  Add User
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <GenericFilterTabs
        setFilter={setFilter}
        filter={filter}
        isFilterApplied={isFilterApplied}
        count={usersCount}
        module={"Users"}
      />

      <Grid container spacing={2} mt={0}>
        <Grid
          container
          item
          display={"flex"}
          flexDirection={"row"}
          sm={12}
          sx={4}
          spacing={2}
          justifyContent="flex-start"
        >
          <Grid item sx={4} sm={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "12px" }}
              >
                Search by name, email, phone
              </InputLabel>
              <OutlinedInput
                value={filter?.searchValue ?? ""}
                data-testid="search"
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by name, email, phone"
                label="Search by name, email, phone"
                onChange={(e) => {
                  setFilter((v) => {
                    if (e.target.value === "" || e.target === undefined) {
                      const { searchValue, ...rest } = v;
                      return {
                        ...rest,
                        page: 1,
                        limit: 10,
                      };
                    } else {
                      return {
                        ...v,
                        // searchValue: e.target.value.trimStart(),
                        searchValue: e.target.value
                          .trimStart()
                          .replace(/\s{2,}/g, " "),
                        page: 1,
                        limit: 10,
                      };
                    }
                  });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/* organization */}
          {isSuperAdmin && (
            <Grid item sx={4} sm={2}>
              <Box>
                <CustomSelect
                  label="Organisation Name"
                  extraClasses={inputClasses.filterField}
                  // variant="outlined"
                  fullWidth
                  data-testid="orgName"
                  searchValue={
                    searchFields?.org && searchFields?.org !== ""
                      ? searchFields?.org
                      : null
                  }
                  onSearch={handleSearchOrg}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      if (e?.target?.value === "") handleSearchOrg(true);
                      setSearchFields((prev) => ({
                        ...prev,
                        org: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  options={OrganisationListing}
                  getOptionLabel={(option) => option?.name}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "13px" }}>
                      {option.name}
                    </li>
                  )}
                  onChange={(event, option) => {
                    setSelectedOrgId(option ? option?._id : null);
                    setFilter((f) => {
                      if (option === undefined || option === null) {
                        const { orgId, ...rest } = f;
                        return {
                          ...rest,
                        };
                      } else {
                        return {
                          ...f,
                          orgId: option?._id,
                        };
                      }
                    });
                    if (option !== null || option !== undefined) {
                      dispatch(
                        getPermissionRoles({
                          orgId: option?._id,
                          selection: ["name"],
                        })
                      );
                    } else if (filter?.userRole) {
                      setFilter((f) => {
                        const { userRole, ...rest } = f;
                        return { ...rest };
                      });
                    }
                  }}
                  labelKey="name"
                  value={
                    OrganisationListing.find((dl) => {
                      return dl.name === filter?.orgId;
                    }) || null
                  }
                  sx={selectStylesOverride(isDarkThemeEnabledSelector)}
                />
              </Box>
            </Grid>
          )}
          {/* User role */}
          <Grid item sx={4} sm={2}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <CustomSelect
                data-testid="userRole"
                options={allPermissionRoles}
                disabled={!filter?.orgId}
                onChange={(event, option, reason) => {
                  if(reason === "clear") handleSearchUserRole(true);
                  setFilter((v) => {
                    if (option === "" || option === undefined) {
                      const { name, ...rest } = v;
                      return {
                        ...rest,
                        page: 1,
                        limit: 10,
                      };
                    } else {
                      return {
                        ...v,
                        userRole: option?.name,
                        page: 1,
                        limit: 10,
                      };
                    }
                  });
                }}
                searchValue={
                  searchFields?.userRole && searchFields?.userRole !== ""
                    ? searchFields?.userRole
                    : null
                }
                onSearch={handleSearchUserRole}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchUserRole(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      userRole: e?.target?.value?.trim(),
                    }));
                  }
                }}
                label="User Role"
                labelKey="name"
                getOptionLabel={(option) => {
                  return filter?.userRole ? option.name : "";
                }}
                value={
                  (allPermissionRoles &&
                    allPermissionRoles?.find((dl) => {
                      return dl.name === filter?.userRole;
                    })) ||
                  undefined
                }
              />
            </FormControl>
          </Grid>
          {/* Createed on date */}
          <Grid item sx={4} sm={2}>
            <DatePicker
              label="Created on date"
              value={filter?.createdOnDate ?? null}
              className="customDatePicker"
              fullWidth
              onChange={(newValue) => {
                setFilter((f) => {
                  if (newValue === undefined || newValue === null) {
                    const { createdOnDate, ...rest } = f;
                    return {
                      ...rest,
                      page: 1,
                      limit: 10,
                    };
                  } else {
                    return {
                      ...f,
                      createdOnDate: newValue,
                    };
                  }
                });
              }}
              format="dd/MM/yyyy"
              sx={{
                ...inputClasses.filterField,
                fontSize: "8px",
                width: "100%",
              }}
              slotProps={{
                textField: { size: "small" },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </Grid>
        </Grid>
        {checkedObj.length > 0 ? (
          <Box pl={"16px"} mt={2} data-testid="edit">
            {checkedObj.length < 2 ? (
              <Button
                size="small"
                data-testid="edit"
                variant="contained"
                className="UM_uploadfile-btn"
                disabled={checkedObj.length >= 2 ? true : false}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitOrangeFill,
                  mr: 1,
                  textTransform: "none",
                }}
                onClick={() => {
                  setSelectedUser(tableData[tableIndex]);
                  history.push(
                    `/dashboard/users/edit-user/${tableData[tableIndex]?._id}`
                  );
                }}
              // onClick={() => history.push("/dashboard/users/edit-user")}
              // onClick={() => { handleViewEdit('edit'); handleOptions('edit') }}
              // sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
              >
                {" "}
                Edit{" "}
              </Button>
            ) : (
              ""
            )}
            {checkedObj.length < 2 ? (
              <Button
                size="small"
                data-testid="view"
                variant="contained"
                className="UM_uploadfile-btn"
                disabled={checkedObj.length >= 2 ? true : false}
                onClick={() => {
                  setSelectedUser(tableData[tableIndex]);
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitOrangeFill,
                  mr: 1,
                  textTransform: "none",
                }}
              >
                {" "}
                View{" "}
              </Button>
            ) : (
              ""
            )}

            {havePermission(loggedInUserDetail, "user", "updateUser") && isActive && !isBoth ? (
              <Button
                size="small"
                data-testid="block"
                variant="contained"
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitDisabled,
                  mr: 1,
                  textTransform: "none",
                }}
                onClick={() => {
                  setActionStatus(Actions.BLOCK);
                  setDeleteDisableBlock(true);
                }}
              >
                Block
              </Button>
            ) : (
              ""
            )}

            {havePermission(loggedInUserDetail, "user", "updateUser") && isBlock && !isBoth ? (
              <Button
                size="small"
                data-testid="block"
                variant="contained"
                onClick={() => {
                  setActionStatus(Actions.ACTIVE);
                  setDeleteDisableBlock(true);
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitGreenFill,
                  mr: 1,
                  textTransform: "none",
                }}
              >
                {" "}
                Active
              </Button>
            ) : (
              ""
            )}

            {havePermission(loggedInUserDetail, "user", "deleteUser") && (
              <Button
                size="small"
                data-testid="delete"
                variant="contained"
                className="UM_delete-btn"
                onClick={() => {
                  setActionStatus(Actions.DELETE);
                  // setEditBlock(true);
                  setDeleteDisableBlock(true);
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  textTransform: "none",
                  ...buttonClasses.lynkitBlackFill,
                }}
              >
                {" "}
                Delete{" "}
              </Button>
            )}
          </Box>
        ) : (
          ""
        )}

        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            item
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img data-testId="loaderImg" src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <Grid container sx={{ mt: 2 }} minHeight="500px" maxHeight="70vh">
            <Grid height="100%" item sm={selectedUser ? 9 : 12} xs={12}>
              {/* <Paper
                sx={{
                  //minHeight: "70vh",
                  height: "100%",
                  width: "98%",
                  margin: "auto",
                  overflowX: "auto",
                  display: "block",
                }}
              > */}
              {tableData.length > 0 ? (
                <Paper
                  sx={{
                    //minHeight: "70vh",
                    width: "98%",
                    margin: "auto",
                    display: "block",

                    height: "65.5vh",
                    overflow: "auto",
                  }}
                >
                  <UsersTable
                    tableColumns={tableColumns}
                    data={tableData ?? []}
                    totalRecords={totalRows}
                    pagination={filter}
                    setPagination={setFilter}
                    themeType={themeType}
                    handlePaginationChange={handlePaginationChange}
                  />
                </Paper>
              ) : (
                <EmptyPage />
              )}
              {/* </Paper> */}
            </Grid>
            {selectedUser && (
              <Grid height="100%" item sm={3} xs={6} sx={{ p: 1, pt: 0 }}>
                <DetailCard
                  selectedUser={selectedUser}
                  themeType={themeType}
                  onClose={() => setSelectedUser(null)}
                  userTimeZone={userTimeZone}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {actionIndex != null && (
        <Menu
          data-testid="menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {havePermission(loggedInUserDetail, "user", "updateUser") && (
            <MenuItem
              key={1}
              onClick={() => {
                handleClose(Actions.EDIT);
                setSelectedUser(tableData[tableIndex]);
                history.push(
                  `/dashboard/users/edit-user/${tableData[tableIndex]?._id}`
                );
              }}
            >
              Edit
            </MenuItem>
          )}

          {/* <MenuItem key={2} >View</MenuItem> */}
          {havePermission(loggedInUserDetail, "user", "updateUser") && (
            <MenuItem
              key={3}
              onClick={() =>
                handleClose(
                  tableData[actionIndex].isApproved
                    ? Actions.BLOCK
                    : Actions.ACTIVE
                )
              }
            >
              {tableData[actionIndex].isApproved ? "Block" : "Active"}
            </MenuItem>
          )}
          {havePermission(loggedInUserDetail, "user", "deleteUser") && (
            <MenuItem
              data-testid="delete"
              key={4}
              onClick={() => handleClose(Actions.DELETE)}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      )}
      {deleteDisableBlock && (
        <DisableDeleteOrganization
          testId="deletePopUp"
          hide={() => {
            setDeleteDisableBlock(false);
          }}
          type="User"
          onSubmit={handleActionSubmission}
          tableData={tableData}
          checkedObj={checkedObj}
          action={actionStatus}
          refresh={handleRefresh}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          // callFetchUsers={clearSearch}
          setEditBlock={() => {
            setDeleteDisableBlock(false);
          }}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={setCheckedObj}
          pageLimits={filter.limit}
          pageNumber={filter.page}
          status={filter.approved}
        />
      )}
    </>
  );
}
