import {
  FETCH_SUCCESS,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SETTINGS,
  FETCH_DYNAMIC_KEYS_FOR_TEMPLATES,
  ORGANISATION_IMAGES,
  CLEAR_UPLOADED_IMAGES,
  CLEAR_SETTINGS
} from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

/**
 * @description -- to fetch all settings
 */
export const getAllSettings = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/getAllSettings", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        else{
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_SETTINGS,
            payload: [],
          });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_SETTINGS,
            payload: data?.data[0],
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: FETCH_SETTINGS, payload: null });
        console.log("Error****:", error.message);
      });
  };
};

export const updateSettings = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/saveSettings", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          // showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const listDynamicKeys = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/listDynamicKeysForTemplate", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({
            type: FETCH_DYNAMIC_KEYS_FOR_TEMPLATES,
            payload: data?.data,
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getImagesOfOrganisation = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/getImagesOfOrganization", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({
            type: ORGANISATION_IMAGES,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const uploadImagesInOrganisation = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/uploadImagesInOrganization", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }else{
          showToast(data?.message, true);
        }
        function callback() {
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        showToast(error?.response?.data?.message, true);
      });
  };
};

export const clearOrganisationImages = (payload) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPLOADED_IMAGES, payload: payload });
  };
};



export const clearSettings = (payload) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SETTINGS, payload: payload });
  };
};
