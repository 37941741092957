import { TRIP_LIST, TRIP_DETAILS, EMPTY_TRIP_DETAILS, AVAILABLE_DEVICE_TYPE, AVAILABLE_MESSAGE, TRIP_STATUS_CHART, TRIP_LOCK_STATUS_CHART, TRIP_ASSET_STATUS_CHART, TRIP_TRACK_LIST, EMPTY_TRIP_TRACK_LISTING, TRIP_ALERT_LIST, EMPTY_TRIP_ALERT_LISTING, REPORT_KEYS, TRIP_SUMMARY, EMPTY_REPORT_KEYS, EMPTY_TRIP_LIST, TRIP_PERFORMANCE_DATA, TRIP_LIST_FOR_ROUTE, EMPTY_TRIP_SUMMARY, CLOSURE_MESSAGE, TRIP_LIST_COUNT } from "../types";

const INIT_STATE = {
  tripListing: [],
  totalRows: 0,
  fetchTripsFlag: true,
  tripDetails: {}, 
  tripstatuschartData: {},
  triplockstatuschartData: {},
  tripassetstatuschartData: {},
  availavleDeviceType: '',
  availavleDeviceMssg: '',
  availableStatus:'',
  tripTrackListing: [],
  tripAlertListing: [],
  reportKeys: [],
  tripSummary: [],
  tripPerformanceData: [],
  routeListForTrips: [],
  bulkTripHeaders: [],
  closureMessage: null,
  tripPaginationLoading: false,
  tripsFilters: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_TRIPS_FLAG": return { ...state, fetchTripsFlag: action?.payload }
    case TRIP_LIST:
      return {
        ...state,
        tripListing: action.payload.rows,
      };
    case TRIP_LIST_COUNT:
      return {
        ...state,
        totalRows: action.payload,
      };
    case "TRIP_PAGINATION_LOADING":
      return {
        ...state,
        tripPaginationLoading: action.payload,
      };
    case TRIP_LIST_FOR_ROUTE:
      return {
        ...state,
        routeListForTrips: action.payload.rows,
        totalRows: action.payload.count,
      };
    case EMPTY_TRIP_LIST:
      return {
        ...state,
        tripListing: [],
        totalRows: 0,
      };
    case TRIP_DETAILS:
      return {
        ...state,
        tripDetails: action.payload.rows
      };
    case TRIP_PERFORMANCE_DATA:
      return {
        ...state,
        tripPerformanceData: action.payload.rows.trackData
      };
    case AVAILABLE_DEVICE_TYPE:
      return {
        ...state,
        availavleDeviceType: action.payload.rows
      };
    case AVAILABLE_MESSAGE:
      return {
        ...state,
        availavleDeviceMssg: action.payload.mssg,
        availableStatus: action.payload.success,
      };
    case TRIP_STATUS_CHART:
      return {
        ...state,
        tripstatuschartData: action.payload.rows
      };

    case TRIP_LOCK_STATUS_CHART:
      return {
        ...state,
        triplockstatuschartData: action.payload.rows
      };
    case TRIP_ASSET_STATUS_CHART:
      return {
        ...state,
        tripassetstatuschartData: action.payload.rows
      };
    case EMPTY_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: {}
      };
    case TRIP_TRACK_LIST:
      return {
        ...state,
        tripTrackListing: action.payload.rows,
      };
    case EMPTY_TRIP_TRACK_LISTING:
      return {
        ...state,
        tripTrackListing: [],
      };
    case TRIP_ALERT_LIST:
      return {
        ...state,
        tripAlertListing: action.payload.rows,
      };
    case EMPTY_TRIP_ALERT_LISTING:
      return {
        ...state,
        tripAlertListing: [],
      };
    case EMPTY_REPORT_KEYS:
      return {
        ...state,
        reportKeys: [],
      };
    case REPORT_KEYS:
      return {
        ...state,
        reportKeys: action.payload.rows,
      };
    case EMPTY_TRIP_SUMMARY:
      return {
        ...state,
        tripSummary: [],
      };
    case TRIP_SUMMARY:
      return {
        ...state,
        tripSummary: action.payload.rows,
      };
    case "BULK_ADD_TRIP_HEADERS":
      return {
        ...state,
        bulkTripHeaders: action.payload,
      };
    case "CLOSURE_MESSGAE":
      return {
        ...state,
        closureMessage: action.payload,
      };
    case "TRIPS_FILTERS":
      return {
        ...state,
        tripsFilters: action.payload,
      };
    default:
      return state;
  }
};

export const updateTripFlag = (payload) => ({
  type: "FETCH_TRIPS_FLAG", payload: payload
});

export const flushBulkHeaders = () => ({
  type: "BULK_ADD_TRIP_HEADERS", payload: []
});

export const setTripsFilters = (payload) => ({
  type: "TRIPS_FILTERS", payload: payload
});
