import {
    Button,
    Autocomplete,
    TextField,
    Grid,
    MenuItem,
    Paper,
    Menu,
    Checkbox,
    Tab,
    Tabs,
    Typography,
    Switch,
    Box,
    FormControl
} from "@mui/material";
import { havePermission } from "../../../../utils/permissionCheck";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import Table from "../components/Table";
import { useEffect } from "react";
import { useCallback } from "react";
import { selectStylesOverride } from "../../../../utils/util";
import {
    getAllSettings,
    updateSettings,
    clearSettings
} from "../../../../redux/actions/Settings";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import EmptyPage from "../../EmptyPage";
import { getAllOrganisations, getAllUsers } from "../../../../redux/actions";
import CustomSelect from "../../../../components/FormUI/CustomSelect";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    root: {
        width: "50px",
        height: "24px",
        padding: "0px",
    },
    switchBase: {
        padding: "2px",
        color: "red",
        // "&.Mui-disabled": {
        //   color: "green",
        // },
        "&.Mui-checked": {
            color: "green",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "transparent",
        },
    },
    track: {
        border: "0.5px solid #D9D9D9",
        borderRadius: "20px",
        backgroundColor: "transparent",
        opacity: "1 !important",
        "&:after, &:before": {
            fontSize: "11px",
            position: "absolute",
            top: "6px",
            content: "''", // Add an empty content property by default
        },
        "&:after": {
            color: "green",
            content: "'Yes'",
            left: "4px",
        },
        "&:before": {
            color: "red",
            content: "'No'",
            right: "7px",
        },
    },
    checked: {
        transform: "translateX(26px) !important",
    },
});

const HealthSettings = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        register,
        control,
        getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    // const tripSettingsData = useSelector((state) => state.settingsReducer.settingsData);
    let apiResponse = [
        {
            "userId": "66066bf321759dba293847b2",
            "name": "kavita kumari",
            "healthDevicePermissions": {
                "eLock": {
                    "label": "E-Lock",
                    "bluetoothUnlocking": {
                        "label": "Bluetooth unlocking should pass atleast ${value} attempts elock",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "webUnlocking": {
                        "label": "Web unlocking should pass atleast ${value} attempts",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "passcodeUnlocking": {
                        "label": "Passcode unlocking should pass atleast ${value} attempts",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "lastPacketTime": {
                        "label": "Device should be online, last packet time should not be ${value} hour older",
                        "isShow": true,
                        "value": 5,
                        "type": "String",
                        "status": "yes"
                    },
                    "noTampered": {
                        "label": "Device should not be tampered within ${value} hour",
                        "isShow": true,
                        "value": 24,
                        "type": "Number",
                        "status": "yes"
                    }
                },
                "fixedELock": {
                    "label": "Fixed E-Lock",
                    "bluetoothUnlocking": {
                        "label": "Bluetooth unlocking should pass atleast ${value} attempts fixed elock",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "webUnlocking": {
                        "label": "Web unlocking should pass atleast ${value} attempts",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "passcodeUnlocking": {
                        "label": "Passcode unlocking should pass atleast ${value} attempts",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "lastPacketTime": {
                        "label": "Device should be online, last packet time should not be ${value} hour older",
                        "isShow": true,
                        "value": "00:30",
                        "type": "String",
                        "status": "yes"
                    },
                    "noTampered": {
                        "label": "Device should not be tampered within ${value} hour",
                        "isShow": true,
                        "value": 24,
                        "type": "Number",
                        "status": "yes"
                    }
                },
                "fixedTracker": {
                    "label": "Fixed Tracker",
                    "bluetoothUnlocking": {
                        "label": "Bluetooth unlocking should pass atleast ${value} attempts fixed tracker",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "webUnlocking": {
                        "label": "Web unlocking should pass atleast ${value} attempts",
                        "isShow": true,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "passcodeUnlocking": {
                        "label": "Passcode unlocking should pass atleast ${value} attempts",
                        "isShow": false,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "lastPacketTime": {
                        "label": "Device should be online, last packet time should not be ${value} hour older",
                        "isShow": true,
                        "value": "00:30",
                        "type": "String",
                        "status": "yes"
                    },
                    "noTampered": {
                        "label": "Device should not be tampered within ${value} hour",
                        "isShow": false,
                        "value": 24,
                        "type": "Number",
                        "status": "yes"
                    }
                },
                "assetTracker": {
                    "label": "Asset Tracker",
                    "bluetoothUnlocking": {
                        "label": "Bluetooth unlocking should pass atleast ${value} attempts asset tracker",
                        "isShow": false,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "webUnlocking": {
                        "label": "Web unlocking should pass atleast ${value} attempts",
                        "isShow": false,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "passcodeUnlocking": {
                        "label": "Passcode unlocking should pass atleast ${value} attempts",
                        "isShow": false,
                        "value": 2,
                        "type": "Number",
                        "status": "yes"
                    },
                    "lastPacketTime": {
                        "label": "Device should be online, last packet time should not be ${value} hour older",
                        "isShow": true,
                        "value": "00:30",
                        "type": "String",
                        "status": "yes"
                    },
                    "noTampered": {
                        "label": "Device should not be tampered within ${value} hour",
                        "isShow": false,
                        "value": 24,
                        "type": "Number",
                        "status": "yes"
                    }
                }
            }
        }
    ]
    let healthSettings = apiResponse[0]?.healthDevicePermissions;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const [tabIndex, setTabIndex] = useState(0);
    const [isEditMode, setEditMode] = useState(false);
    const [dataToSend, setDataToSend] = useState(healthSettings);
    const [loader, setLoader] = useState(false);
    const [searchFields, setSearchFields] = useState({ org: null });
    const [editPermission, setEditPermission] = useState('editChkAvlPermission')
    const [selectedTabName, setSelectedTabName] = useState('eLock');
    const currentUser = useSelector((state) => state.auth.currentUserDetail);
    const [details, setDetails] = useState({
        orgId: '',
        id: ''
    })
    const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
    const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/darktheme") : require("../../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses } = themeType;
    const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions || {};
    const Users = useSelector((state) => state.userlist.allUsers);
    
    // useEffect(() => {
    //     if (healthSettings) {
    //         setDataToSend(healthSettings);
    //     }
    // }, [healthSettings]);

    const updateValue = useCallback(
        (value, key) => {
            let temp = dataToSend;
            const keyToChange = key;
            temp = {...temp,[selectedTabName]: {...temp[selectedTabName], [keyToChange]: {...temp[selectedTabName][keyToChange],value: Number(value)}}};
            setDataToSend(temp);
        },
        [dataToSend, selectedTabName]
    );

    const updateStatus = (newValue, key) => {
        let temp = dataToSend;
        const keyToChange = key;
        temp[selectedTabName][keyToChange].status = newValue;
        setDataToSend(temp);
    };

    const ConditionLabel = (entry) => {
        var stringSplitted = entry?.label?.split("${value}");
        const contentArray = stringSplitted?.map((part, index) => {
            if (index < stringSplitted.length - 1) {
                return (
                    <React.Fragment key={index}>
                        <div style={{ alignSelf: "center" }}>{part}</div>
                        <div>&nbsp;</div>
                        <Controller
                            name={entry?.key}
                            control={control}
                            required
                            rules={{
                                required: {
                                    value: true,
                                    message: "Field value is required",
                                },
                                validate: (value) => {
                                    return value > 0 || "Value must be greater than 0";
                                },
                            }}
                            errors={errors}
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <div style={{ lineHeight: 0 }}>
                                        <TextField
                                            data-testid="textField"
                                            sx={{
                                                ...selectStylesOverride(isDarkThemeEnabledSelector),
                                            }}
                                            id={`outlined-basic-${index}`}
                                            variant="outlined"
                                            name={name}
                                            type="number"
                                            defaultValue={value}
                                            value={value}
                                            InputProps={{ style: { width: "50px", height: "30px" } }}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    updateValue(e.target.value, entry?.key);
                                                }
                                                onChange(e.target.value);
                                            }}
                                        />
                                        {errors[name] && (
                                            <span
                                                role="alert"
                                                style={{ color: "#FF0101", fontSize: 11 }}
                                            >
                                                {errors[name]?.message}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                        <div>&nbsp;</div>
                    </React.Fragment>
                );
            } 
            else {
                return <div style={{ alignSelf: "center" }}>{part}</div>;
            }
        });

        const updatedLabel = isEditMode
            ? contentArray
            : entry?.label?.replace("${value}", entry?.value);

        return (
            <Typography
                sx={{
                    ...textClasses.normalText,
                    textAlign: "start",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    // fontWeight: 600,
                    // fontSize: "16px",
                    display: "flex",
                    flexDirection: "row",
                    // lineHeight: 0,
                }}
            >
                {updatedLabel}
            </Typography>
        );
    };

    let tableColumns = [
        {
            key: "Sno",
            header: "#",
            cell: (key) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "start",
                        }}
                    >
                        {key + 1}
                    </Typography>
                );
            },
        },
        {
            key: "conditions",
            header: "Checkpoints",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            // ...textClasses.t13n,
                            textAlign: "start",
                        }}
                    >
                        {ConditionLabel(entry)}
                    </Typography>
                );
            },
        },
        {
            key: "status",
            header: "Status",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "start",
                            fontSize: "16px",
                            fontWeight: 600,
                            color:
                                entry?.status == true
                                    ? themeType?.default.themeGreen
                                    : themeType?.default.themeRed,
                        }}
                    >
                        {entry?.isShow &&
                            (isEditMode ? (
                                <Controller
                                    name={`switch_${entry?.key}`}
                                    control={control}
                                    render={({ field: { onChange, value, name } }) => {
                                        return (
                                            <div>
                                                <Switch
                                                    classes={{
                                                        root: classes.root,
                                                        switchBase: classes.switchBase,
                                                        thumb: classes.thumb,
                                                        track: classes.track,
                                                        checked: classes.checked,
                                                    }}
                                                    data-testid="checkbox"
                                                    disabled={!entry?.isUpdateStatus}
                                                    size="medium"
                                                    name={name}
                                                    defaultChecked={entry?.status}
                                                    value={value}
                                                    onChange={(e) => {
                                                        updateStatus(e.target.checked, entry?.key);
                                                        onChange(e.target.checked);
                                                    }}
                                                />
                                                {errors[name] && (
                                                    <span
                                                        role="alert"
                                                        style={{ color: "#FF0101", fontSize: 11 }}
                                                    >
                                                        {errors[name]?.message}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            ) : entry?.status == true ? (
                                "Yes"
                            ) : (
                                "No"
                            ))}
                    </Typography>
                );
            },
        },
    ];

    const tableData = useMemo(() => {
        if (currentUser) {
            if (currentUser?.adminSpecificPermissions) {
                if (details.orgId && details?.id && selectedTabName) {
                    const data = healthSettings && healthSettings[selectedTabName] && Object.keys(healthSettings[selectedTabName])?.filter((condition) => condition !== "label")
                        ?.map((condition, index) => {
                            let conditionData = healthSettings && healthSettings[selectedTabName] && healthSettings[selectedTabName][condition];
                            if (conditionData) {
                                conditionData = { ...conditionData, key: condition };
                            }
                            return conditionData;
                        });
                    return data;
                }
                else { return [] }
            }
            else {
                if (details?.id) {
                    const data = healthSettings && healthSettings[selectedTabName] && Object.keys(healthSettings[selectedTabName])?.filter((condition) => condition !== "label")
                        ?.map((condition, index) => {
                            let conditionData = healthSettings && healthSettings[selectedTabName] && healthSettings[selectedTabName][condition];
                            if (conditionData) {
                                conditionData = { ...conditionData, key: condition };
                            }
                            return conditionData;
                        });
                    return data;
                }
                else { return [] }
            }
        }

    }, [healthSettings, details.orgId, details?.id, currentUser, selectedTabName])

    useEffect(() => {
        if (superAdmin && Object.keys(superAdmin).length && selectedTabName) {
            if (details?.orgId && details?.id) {
                let payload = {
                    type: "health",
                    orgId: details?.orgId,
                    userId: details?.id,
                }
                setLoader(true);
                dispatch(getAllSettings(payload, (res, error) => {
                    setLoader(false);
                }));
            }
            else {
                if (details?.orgId) {
                    let payload = {
                        type: "health",
                        orgId: details?.orgId,
                    }
                    setLoader(true);
                    dispatch(getAllSettings(payload, (res, error) => {
                        setLoader(false);
                    }));
                }
            }
        }
    }, [dispatch, details, selectedTabName, superAdmin]);

    useEffect(() => {
        dispatch(clearSettings())
        if (currentUser) {
            if (currentUser?.adminSpecificPermissions) {
                dispatch(getAllOrganisations({ selection: ["name"] }));
            } else {
                dispatch(getAllUsers({ orgId: currentUser?.orgId, selection: ["name", "orgName"] }));
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            if (!currentUser?.adminSpecificPermissions) {
                let payload = {
                    type: "health",
                    orgId: userPermissions?.orgId,
                    userId: userPermissions?._id,
                }
                setValue('userName', userPermissions)
                let temp = { details }
                temp.id = userPermissions?._id
                temp.orgId = userPermissions?.orgId
                setDetails(temp)
                setLoader(true);
                dispatch(getAllSettings(payload, (res, error) => {
                    setLoader(false);
                }));
            }
        }
    }, [currentUser, selectedTabName, userPermissions]);

    useEffect(() => {
        if (tableData) {
            tableData.forEach((data) => {
                setValue(`switch_${data?.key}`, data?.status);
                if (data?.value) {
                    setValue(data?.key, data?.value);
                }
            });
        }
    }, [tableData]);

    const handleUpdate = useCallback(
        (data) => {
            const payload = {
                type: "health",
                orgId: searchFields.org,
                settings: dataToSend,
            };
            dispatch(
                updateSettings(payload, () => {
                    dispatch(getAllSettings({ type: "health", orgId: searchFields?.org }));
                    setEditMode(false);
                })
            );
        },
        [
            dispatch,
            currentUser,
            dataToSend,
            setEditMode,
            getAllSettings,
            updateSettings,
        ]
    );
    const _handleChange = (e, newVal, item) => {
        let temp = { ...details }
        if (item === 'org') {
            if (newVal) {
                temp.orgId = newVal?._id
                setDetails(temp)
                if (Object?.keys(superAdmin)?.length) {
                    dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] }));
                }
            }
            else {
                temp.orgId = ""
                setDetails(temp)
                dispatch(clearSettings())
                setValue('userName', "")
            }
        }
        else if (item === 'user') {
            if (newVal) {
                temp.id = newVal?._id
                setDetails(temp)
                // setValue('userName', newVal?.name)
            }
            else {
                temp.id = ""
                setDetails(temp)
                dispatch(clearSettings())
            }
        }
    }

    const handleSearchOrg = (empty) => {
        if (empty === true) {
            dispatch(getAllOrganisations({ selection: ["name"] }));
        }
        else {
            if (searchFields?.org || searchFields?.org == "")
                dispatch(
                    getAllOrganisations({
                        selection: ["name"],
                        searchValue: searchFields.org,
                    })
                );
        }
    };

    const handleSearchUsers = (empty) => {
        if (empty === true) {
            dispatch(
                getAllUsers({
                    selection: ["name", "orgName"],
                    searchValue: "",
                })
            );
        } else {
            if (searchFields?.users || searchFields?.users == "")
                dispatch(
                    getAllUsers({
                        selection: ["name", "orgName"],
                        searchValue: searchFields?.users,
                    })
                );
        }
    };
    return (
        <>
            <Grid item sm={12} xs={12}>
                <Typography role="span" sx={{ ...textClasses.cardTitle }}>
                    Health Report Settings
                </Typography>
            </Grid>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Tabs
                    value={tabIndex}
                    aria-label="disabled tabs example"
                    style={{ height: "20px" }}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: themeType.default.themeOrange,
                        },
                        "& .MuiTab-root.Mui-selected": {
                            color: themeType.default.themeOrange,
                        },
                    }}
                >
                    {apiResponse?.map((setting, index) => (
                        Object.keys(setting.healthDevicePermissions).map((permissionKey, permissionindex) => (
                            <Tab
                                key={index + permissionKey}
                                label={setting.healthDevicePermissions[permissionKey].label}
                                {...a11yProps(index)}
                                sx={{ fontSize: "14px", textTransform: "none" }}
                                onClick={() => {
                                    setSelectedTabName(permissionKey)
                                    setTabIndex(permissionindex);
                                }}
                            />
                        ))
                    ))}
                </Tabs>
            </Box>
            <Grid
                container
                mt={2}
                display="flex"
                gap={2}
                alignItems="center"
                sx={{ width: "100%", paddingTop: "0 !important" }}
            >
                <Grid
                    item
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{ width: "40%", paddingTop: "0 !important" }}
                >
                    {superAdmin && Object.keys(superAdmin).length ?
                        <Grid
                            item
                            display="flex"
                            gap={2}
                            alignItems="center"
                            sx={{ width: "100%", paddingTop: "0 !important" }}
                        >

                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >

                                <CustomSelect
                                    selectStylesOverride={{ ...selectStylesOverride, width: '96%' }}
                                    key={`organisation`}
                                    onChange={(e, newVal) => {
                                        _handleChange(e, newVal, 'org')
                                    }}
                                    onSearch={handleSearchOrg}
                                    onInputChange={(e) => {
                                        if (e?.type === "change") {
                                            if (e?.target?.value === "") handleSearchOrg(true);
                                            setSearchFields((prev) => ({
                                                ...prev,
                                                org: e?.target?.value?.trim(),
                                            }));
                                        }
                                    }}
                                    searchValue={searchFields?.org && searchFields?.org !== ""
                                        ? searchFields?.org
                                        : null}
                                    label="Organisation"
                                    errors={errors}
                                    getOptionLabel={(option) => { return option?.name }}
                                    filterSelectedOptions
                                    labelKey="name"
                                    isOptionEqualToValue={(option, value) =>
                                        option._id === value.id && option.name === value.name
                                    }
                                    options={orgListing && orgListing}
                                />
                            </FormControl>

                        </Grid>
                        : null}
                    <Grid
                        item
                        display="flex"
                        gap={2}
                        alignItems="center"
                        sx={{ width: Object.keys(superAdmin).length ? '100%' : '50%', paddingTop: "0 !important" }}
                    >
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '96%' }}
                        >
                            <Controller
                                name="userName"
                                control={control}
                                render={({ field: { onChange, value, name } }) => {
                                    return (
                                        <CustomSelect
                                            selectStylesOverride={{ ...selectStylesOverride, width: '96%' }}
                                            key={`id`}
                                            defaultValue={value}
                                            onChange={(e, newVal) => {
                                                onChange(newVal?._id);
                                                _handleChange(e, newVal, 'user')
                                            }}
                                            searchValue={searchFields?.users && searchFields?.users !== ""
                                                ? searchFields?.users
                                                : null}
                                            onSearch={handleSearchUsers}
                                            onInputChange={(e) => {
                                                if (e?.type === "change") {
                                                    if (e?.target?.value === "") handleSearchUsers(true);
                                                    setSearchFields((prev) => ({
                                                        ...prev,
                                                        users: e?.target?.value?.trim(),
                                                    }));
                                                }
                                            }}
                                            label="User"
                                            name={name}
                                            errors={errors}
                                            value={value || null}
                                            getOptionLabel={(option) => {
                                                if (value === undefined) {
                                                    return "";
                                                }
                                                if (typeof option === "string") {
                                                    return (
                                                        Users?.find((_) => _?._id == value)?.name ||
                                                        ""
                                                    );
                                                } else {
                                                    return option?.name || "";
                                                }
                                            }}
                                            filterSelectedOptions
                                            labelKey="name"
                                            options={Users?.length > 0 ? Users.map((ele) => ({ "name": `${ele.name}-${ele.orgName}`, "_id": ele._id })) : []}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={0}>
                {loader ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        sx={{ p: 15 }}
                    >
                        <Grid item>
                            <img src={loaderGif} />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {tableData && tableData?.length === 0 && tableData === undefined ? (
                            <EmptyPage body="No data found" />
                        ) : (
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item sm={12} xs={12}>
                                    {(!tableData || tableData.length === 0) ? (
                                        <EmptyPage body={userPermissions?.adminSpecificPermissions ? "Please select Organsiation " : ""} />
                                    ) : (

                                        <Paper
                                            sx={{
                                                height: "100%",
                                                width: "98%",
                                                margin: "auto",
                                                overflowX: "auto",
                                                display: "block",
                                            }}
                                        >
                                            <Table
                                                tableColumns={tableColumns}
                                                data={tableData ?? []}
                                                themeType={themeType}
                                            />
                                        </Paper>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            {isEditMode ? (
                <Grid
                    item
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    gap={2}
                    alignItems="center"
                    sx={{ width: "100%", paddingTop: "0 !important" }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            ...buttonClasses?.lynkitBlackFill,
                            minHeight: "36px",
                            minWidth: "140px",
                        }}
                        onClick={() => setEditMode(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            ...buttonClasses?.lynkitOrangeFill,
                            minHeight: "36px",
                            minWidth: "140px",
                        }}
                        onClick={handleSubmit(handleUpdate)}
                    >
                        Save
                    </Button>
                </Grid>
            ) : null}

            {tableData && tableData?.length > 0 && (
                <>
                    {!isEditMode ?
                        <Grid
                            item
                            mt={2}
                            display="flex"
                            justifyContent="center"
                            gap={2}
                            alignItems="center"
                            sx={{ width: "100%", paddingTop: "0 !important" }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    ...buttonClasses?.lynkitOrangeFill,
                                    minHeight: "36px",
                                    minWidth: "140px",
                                }}
                                onClick={() => setEditMode((prev) => !prev)}
                            >
                                Edit
                            </Button>
                        </Grid>
                        : null
                    }
                </>
            )}
        </>
    );
};

export default HealthSettings;
