import { ArrowForwardIos, LockOpen, Visibility, Close, ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import EventSummaryTimeline from "./EventSummaryTimeline";
import { useDispatch, useSelector } from "react-redux";
import { convertIntoGivenTimezone, getCamelCaseText } from "../../../../services/functions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { havePermission } from "../../../../utils/permissionCheck";
import { getLockUnlockOTP, lockUnlockDevice, verifyLockUnlockOTP, getPasscodeOTP, verifyPasscodeOTP, showPasscode, generatePasscodeForFixedLock } from "../../../../redux/actions";
import OTPModal from "../../Devices/components/OTPModal";
import FixedLockPasscodeModal from "../../Devices/components/FixedLockPasscodeModal"
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import moment from 'moment'
import e from "cors";
const TripMapDrawer = ({ open, themeType, history, details, tripEventsDetails, setIdParam, tripDetails, setTripParam, setMymap, idParam, myMap, infoWindow, newInfoWindow, setInfoWindow, userDetails }) => {
  const { textClasses, buttonClasses } = themeType;
  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
  const dispatch = useDispatch();
  const [currentDeviceId, setCurrentDeviceId] = useState('')
  const { currentUserDetail } = useSelector((_) => _?.auth);
  const [lockType, setLockType] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [unlockId, setUnlockId] = useState("");
  const [loading, setLoading] = useState(false);
  const [passcodeIdToVerify, setPasscodeIdToVerify] = React.useState(null);
  const [passcodeValue, setPasscodeValue] = useState(null);
  const [showFixedLockPasscodeModal, setShowFixedLockPasscodeModal] = useState(false);
  const [showPasscodeOTPModal, setShowPasscodeOTPModal] = useState(false);
  const [showOTPForLockUnlock, setShowOTPForLockUnlock] = useState(false);

  const getTargetCurrentEta = () => {
    if (details?.tripData?.etaType === "destination") {
      return {
        targetETA: details?.tripData?.route?.routeDestination?.targetETA || '-',
        currentETA: convertIntoGivenTimezone(details?.tripData?.route?.routeDestination?.currentETA || '-', currentUserDetail?.timeZone?.offset),
        name: details?.tripData?.route?.routeDestination?.name || null
      };
    }
    else {
      return {
        targetETA: details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.targetETA || '-',
        currentETA: convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.currentETA || '-', currentUserDetail?.timeZone?.offset),
        name: details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.itemName || null
      };
    }
  };

  const detailsList = [
    {
      label: "Trip ID",
      key: "Trip ID",
      value: details && details?.tripData && details?.tripData?.tripDetails ? details?.tripData?.tripDetails?.tripId : '-',
    },
    {
      label: "Vehicle No.",
      key: "vehicle_number",
      value: details && details?.tripData && details?.tripData?.vehicleDetails ? details?.tripData?.vehicleDetails?.vehicleNumber : '-',
    },
    {
      label: "Distance from destination",
      key: "distance",
      value: tripEventsDetails && tripEventsDetails.distanceFromDestination ? tripEventsDetails?.distanceFromDestination + ' km' : '-'
    },
    {
      label: "Overstoppage",
      key: "overstoppage",
      value: tripDetails && tripDetails?.tripData && tripDetails?.tripData?.limitThreshold?.overstopLimit ? tripDetails?.tripData?.limitThreshold?.overstopLimit + ' mins' : '-'
    },
    {
      label: "Overspeed",
      key: "overspeed",
      value: tripDetails && tripDetails?.tripData && tripDetails?.tripData?.limitThreshold?.speedLimit ? tripDetails?.tripData?.limitThreshold?.speedLimit + ' kmph' : '-'
    },
    {
      label: "Device ID",
      key: "deviceId",
      value: details && details?.tripData && details?.tripData?.devices ? details?.tripData?.devices.map(item => ({
        deviceName: item.deviceId,
        deviceType: item.deviceType === 'fixedELock' ? 'Fixed E-Lock' : item.deviceType === 'elock' ? 'E-Lock' : item.deviceType === 'assetTracker' ? 'Asset Tracker' : item.deviceType === 'fixedTracker' ? 'Fixed Tracker' : item.deviceType === 'simTracking' ? 'Sim Tracking' : getCamelCaseText(item.deviceType)
      })) : []
    },
    // {
    //   label: "Target ETA",
    //   key: "targetEta",
    //   value: details && details?.tripData && details?.tripData?.etaType ? getTargetCurrentEta()?.targetETA : '-'
    // },
    // {
    //   label: "Current ETA",
    //   key: "currentEta",
    //   value: details && details?.tripData && details?.tripData?.etaType ? getTargetCurrentEta()?.currentETA : '-'
    // },
  ];

  const getLastTwoItems = () => {
    const lastTwoItems = [];

    if (havePermission(currentUserDetail, "trip", "enableETA")) {
      lastTwoItems.push({
        label: "Target ETA",
        key: "targetEta",
        value:
          details?.tripData?.etaType ? getTargetCurrentEta()?.targetETA : "-",
      });
    }

    if (havePermission(currentUserDetail, "trip", "enableETA")) {
      lastTwoItems.push({
        label: "Current ETA",
        key: "currentEta",
        value:
          details?.tripData?.etaType ? getTargetCurrentEta()?.currentETA : "-",
      });
    }

    return lastTwoItems;
  };

  const finalDetailsList = [...detailsList, ...getLastTwoItems()];

  const handleLockUnlockDevice = (type) => {
    if (havePermission(currentUserDetail, "unlockingOption", "otpForUnlockingWeb")) {
      setModalLoading(true);
      const payload = {
        deviceId: currentDeviceId,
        page: "device",
        source: "web",
        otp: "",
        type: type ?? "",
      };
      dispatch(
        getLockUnlockOTP(payload, (res) => {
          if (res && res?.success) {
            setUnlockId(res?._id);
            setShowOTPForLockUnlock(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = {
        deviceId: currentDeviceId,
        page: "device",
        source: "web",
        type: type ?? "",
      };
      dispatch(
        lockUnlockDevice(payload, (res) => {
          if (res?.success) {
            setLockType(null);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleVerifyOtpForLockUnlock = (data) => {
    setModalLoading(true);
    const payload = {
      deviceId: currentDeviceId,
      page: "device",
      source: "web",
      otp: data?.otp,
      type: lockType ?? "",
      _id: unlockId,
    };
    dispatch(
      verifyLockUnlockOTP(payload, (res) => {
        if (res && res?.success) {
          setLoading(true);
          dispatch(
            lockUnlockDevice(payload, (res) => {
              if (res?.success) {
                setLockType(null);
              }
              setLoading(false);
            })
          );
          setShowOTPForLockUnlock(false);
        }
        setModalLoading(false);
      })
    );
  };

  const changeDevice = (deviceID) => {
    setTripParam(null)
    setIdParam(null)
    setMymap(null)
    setCurrentDeviceId(deviceID)
    history.replace(`/dashboard/trips/trip-map/${tripDetails.tripData?._id}?id=${deviceID}`)

  }

  const handleFixedLockPasscode = () => {
    setShowFixedLockPasscodeModal(true);
  };
  const handleVerifyOtpForPasscode = (data) => {
    setModalLoading(true);
    const payload = {
      otp: data?.otp,
      _id: passcodeIdToVerify,
    };

    dispatch(
      verifyPasscodeOTP(payload, (res) => {
        if (res && res?.success) {
          setPasscodeIdToVerify(null);
          setLoading(true);
          dispatch(
            showPasscode({ deviceId: currentDeviceId }, (res) => {
              if (res && res?.success) {
                setPasscodeValue(res?.result);
                setShowPasscodeOTPModal(false);
              }
              setLoading(false);
            })
          );
        }
        setModalLoading(false);
      })
    );
  };

  const handleGenerateFixedLockPasscode = (data, cb) => {
    setModalLoading(true);
    const payload = {
      randomNo: data?.randomNo,
      deviceId: currentDeviceId,
    };

    dispatch(
      generatePasscodeForFixedLock(payload, (res) => {
        if (res && res?.success) {
          setPasscodeValue(res?.message);
          cb && cb();
        }
        setModalLoading(false);
      })
    );
  };
  const handleViewPasscode = () => {
    if (havePermission(currentUserDetail, "unlockingOption", "otpForUnlockingPasscode")) {
      setModalLoading(true);
      dispatch(
        getPasscodeOTP({ deviceId: currentDeviceId }, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(res?._id);
            setShowPasscodeOTPModal(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = { deviceId: currentDeviceId };
      dispatch(
        showPasscode(payload, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(null);
            setPasscodeValue(res?.result);
          }
          setLoading(false);
        })
      );
    }
  };

  useEffect(() => {
    if (idParam) {
      setCurrentDeviceId(idParam)
    }
  }, [idParam])

  const getDelay = (target, current) => {
    if (!target && !current) {
      return null
    }
    else {
      const targetTime = moment(target, 'DD/MM/YYYY hh:mm:ss A');
      const currentTime = moment(current, 'DD/MM/YYYY hh:mm:ss A');

      const timeDifference = currentTime.diff(targetTime);
      if (timeDifference < 0) {
        const reversedDifference = targetTime.diff(currentTime);
        const reversedDuration = moment.duration(reversedDifference);
        const days = reversedDuration.days();
        const hours = reversedDuration.hours();
        const minutes = reversedDuration.minutes();

        let formattedDifference = "";
        if (days > 0) {
          formattedDifference += `${days} day${days > 1 ? 's' : ''}, `;
        }
        if (hours > 0 || days > 0) {
          formattedDifference += `${hours} hr${hours > 1 ? 's' : ''}, `;
        }
        formattedDifference += `${minutes} min${minutes > 1 ? 's' : ''}`;
        if (formattedDifference.includes('NaN min') || formattedDifference === undefined) {
          return null;
        }
        return {
          time: '( ' + 'Early by ' + formattedDifference + ' )',
          color: 'green'
        }
      }
      else {
        const duration = moment.duration(timeDifference);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        let formattedDifference = "";
        if (days > 0) {
          formattedDifference += `${days} day${days > 1 ? 's' : ''}, `;
        }
        if (hours > 0 || days > 0) {
          formattedDifference += `${hours} hr${hours > 1 ? 's' : ''}, `;
        }
        formattedDifference += `${minutes} min${minutes > 1 ? 's' : ''}`;

        if (formattedDifference.includes('NaN min') || formattedDifference === undefined) {
          return null;
        }
        return {
          time: '( ' + 'Delay by ' + formattedDifference + ' )',
          color: 'red'
        }
      }
    }
  }




  return (

    <Box
      sx={{
        height: "100%",
        width: open ? 430 : 0,
        opacity: open ? 1 : 0,
        transition: "all 0.6s ease-in-out",
        overflowY: "scroll",
      }}
      data-testid="trip-map-side-panel"
    >
      {open && (
        <>
          {/* ----- Header (title & back icon) ------ */}
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              width: "100%",
              top: "-1px",
              background: (theme) => theme.palette.background.paper,
              zIndex: 300,
              borderBottom: "1px solid #D9D9D9",
              paddingY: "15.3px"
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                textAlign: "center",
                marginRight: "10px"
              }}
            >
              Trip Details
            </Typography>
          </Box>
          {tripDetails && Object.keys(tripDetails).length ?
            <Box display="flex" flexDirection="column" gap="8px" px={2}>
              {finalDetailsList?.map((detail, i) => {
                return (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    key={i}
                    sx={{ borderBottom: '1px solid #F5F5F5' }}
                  >
                    <Typography sx={{ ...textClasses.normalText }}>
                      {detail?.label}
                    </Typography>
                    {detail.key === 'deviceId' ?
                      <Grid sx={{ display: 'flex', flexDirection: 'column' }}>

                        {detail?.value?.map((item, index) => (
                          <Typography
                            key={index}
                            sx={{
                              ...textClasses.normalText, color: idParam === item.deviceName ? 'green' : '#ff7200', paddingTop: '2px', paddingBottom: '2px',
                              cursor: idParam === item.deviceName ? 'unset' : 'pointer',
                              '&:hover': {
                                color: 'grey',
                              },
                            }}
                            onClick={idParam === item.deviceName ? null : () => changeDevice(item.deviceName)}
                          >
                            {item?.deviceName || '-'}  ( {(item?.deviceType) || '-'} )
                          </Typography>
                        ))}
                      </Grid>

                      :
                      <Typography sx={{ ...textClasses.normalText, color: detail?.key === 'currentEta' || detail?.key === 'targetEta' ? 'green' : '#ff7200', fontSize: '14px' }}>
                        <>
                          <Typography style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '14px' }}>
                            {detail?.key === 'targetEta' || detail?.key === 'currentEta' ?
                              detail?.value !== undefined && detail?.value !== '-' && detail?.value !== null ? moment(detail?.value, 'DD-MM-YYYY hh:mm:ss a').format('DD-MM-YYYY hh:mm:ss a') || '-' : '-'
                              :
                              detail?.value
                            }
                          </Typography>


                          {!getTargetCurrentEta()?.targetETA || getTargetCurrentEta()?.name === '-' || getTargetCurrentEta()?.name === null ? null :
                            detail?.key === 'targetEta' ?
                              <Typography data-testid="target-eta" style={{ display: 'flex', justifyContent: 'right', fontSize: '13px' }}>( {getTargetCurrentEta()?.name} )</Typography>
                              : null}
                          {!getTargetCurrentEta()?.currentETA || getTargetCurrentEta()?.currentETA === '-' ? null :
                            detail?.key === 'currentEta' ?
                              <p data-testid="current-eta" style={{ color: 'red', display: 'flex', justifyContent: 'right', fontSize: '13px', color: getDelay(getTargetCurrentEta()?.targetETA, getTargetCurrentEta()?.currentETA)?.color }}>{getDelay(getTargetCurrentEta()?.targetETA, getTargetCurrentEta()?.currentETA)?.time}</p>
                              : null}

                        </>
                      </Typography>
                    }



                  </Box>
                );
              })}
              {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedTracker" ? null :
                <Button
                  sx={{
                    ...buttonClasses.lynkitOrangeEmpty,
                    boxShadow: "none",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    sendCustomEventAnalytics("trips", "Trip Summary", "Trip Summary");
                    history.push({
                      pathname: `/dashboard/trips/trip-summary/${tripDetails.tripData?._id}`,
                      state: { currentDeviceId }
                    });
                  }}

                >
                  View Summary
                </Button>
              }

              {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId && (item.deviceType === "assetTracker" || item.deviceType === "simTracking")) ? null :
                details?.redisObjData?.geo_type === "nil" || details?.tripData?.devices?.find(item => item.status === "completed") ? null :
                  <Grid sx={{ display: 'flex' }}>
                    {havePermission(currentUserDetail, "unlockingOption", "sendUnlockCommand") && details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedELock" ? null : (
                      <Grid
                        item
                        xs={12}
                        lg
                        sx={{ marginRight: '10px' }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            ...buttonClasses.lynkitOrangeEmpty,
                            px: "3px !important",
                            boxShadow: "none",
                            "& .MuiButton-startIcon": { marginRight: "4px" },
                          }}
                          startIcon={
                            <LockOpen sx={{ fontSize: "15px !important" }} />
                          }
                          onClick={() => {
                            setLockType("SET_UNLOCK");
                            handleLockUnlockDevice("SET_UNLOCK");
                          }}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    )}

                    {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedELock" &&
                      havePermission(
                        currentUserDetail,
                        "unlockingOption",
                        "viewPasscode"
                      ) ? (
                      <Grid
                        item
                        xs={12}
                        lg
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            ...buttonClasses.lynkitOrangeEmpty,
                            boxShadow: "none",
                            px: "3px !important",
                            "& .MuiButton-startIcon": { marginRight: "4px" },
                          }}
                          onClick={handleFixedLockPasscode}
                          startIcon={
                            <Visibility sx={{ fontSize: "15px !important" }} />
                          }
                        >
                          Passcode
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        {havePermission(
                          currentUserDetail,
                          "unlockingOption",
                          "viewPasscode"
                        ) && (
                            <Grid
                              item
                              xs={12}
                              lg
                            >
                              {passcodeValue ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  gap={0.5}
                                  sx={{ border: ' 0.5px dashed #ff7200', borderRadius: '12px', height: '33px' }}
                                >
                                  <Typography
                                    sx={{
                                      ...textClasses?.normalText,
                                      color: themeType?.default?.themeOrange,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {passcodeValue}
                                  </Typography>
                                  <IconButton onClick={() => setPasscodeValue(null)}>
                                    <Close
                                      sx={{ color: themeType?.default?.themeOrange }}
                                    />
                                  </IconButton>
                                </Box>
                              ) : (
                                details?.tripData?.detailsDeviceID?.assetType === 'fixedELock' ? null :
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                      ...buttonClasses.lynkitOrangeEmpty,
                                      boxShadow: "none",
                                      px: "3px !important",
                                      "& .MuiButton-startIcon": {
                                        marginRight: "4px",
                                      },
                                    }}
                                    onClick={handleViewPasscode}
                                    startIcon={
                                      <Visibility
                                        sx={{ fontSize: "15px !important" }}
                                      />
                                    }
                                  >
                                    Passcode
                                  </Button>
                              )}
                            </Grid>
                          )}
                      </>
                    )}


                  </Grid>
              }


              {tripEventsDetails !== null && tripEventsDetails !== undefined && Object.keys(tripEventsDetails).length ?
                <Box
                  mt={2}
                  borderRadius="12px"
                  border="1px solid #D9D9D9"
                  width="100%"
                >

                  <EventSummaryTimeline
                    themeType={themeType}
                    tripEventsDetails={tripEventsDetails}
                    myMap={myMap}
                    tripDetails={tripDetails}
                    idParam={idParam}
                    infoWindow={infoWindow}
                    newInfoWindow={newInfoWindow}
                    setInfoWindow={setInfoWindow}
                    userDetails={userDetails}
                  />
                </Box>
                :
                <Typography sx={{ paddingTop: '20px' }}>No Event Data.</Typography>
              }
            </Box>
            :
            <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
              <ThreeDotsLoader />
            </Grid>
          }
          <OTPModal
            open={showOTPForLockUnlock}
            setOpen={setShowOTPForLockUnlock}
            themeType={themeType}
            loading={modalLoading}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleVerifyOtpForLockUnlock}
          />

          <OTPModal
            open={showPasscodeOTPModal}
            setOpen={setShowPasscodeOTPModal}
            themeType={themeType}
            loading={modalLoading}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleVerifyOtpForPasscode}
          />

          <FixedLockPasscodeModal
            open={showFixedLockPasscodeModal}
            setOpen={setShowFixedLockPasscodeModal}
            themeType={themeType}
            loading={modalLoading}
            passcodeValue={passcodeValue}
            setPasscodeValue={setPasscodeValue}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleGenerateFixedLockPasscode}
          />
        </>
      )}
    </Box>

  );
};

export default TripMapDrawer;
