// In your main component or wherever appropriate

import React, { useEffect } from 'react';

const FrameBuster = () => {
  // useEffect(() => {
  //   const isInIframe = window !== window.top;
  //   if (isInIframe) {
  //     localStorage.removeItem('token')
  //     window.top.location = 'https://www.google.com/' 
  //   }
  // }, []);

  useEffect(() => {
    const isInIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };

    if (isInIframe()) {
      window.location.href = 'https://www.google.com/' ; 
    }
  }, []);

  return null;
};

export default FrameBuster;
