import {
  FETCH_SUCCESS,
  FETCH_START,
  FETCH_ROUTES,
  FETCH_ERROR,
  ROUTE_DETAILS,
  FLUSH_ROUTES_DATA,
  ROUTE_DISTANCE,
  ROUTE_STATUS_COUNT,
  SCHEDULE_DATA,
  CLEAR_ROUTE_DATA,
  FETCH_ROUTE_FLAG,
  EMPTY_ROUTE_SCHEDULE
} from "../types";
import axios, { geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";

/**
 * @description -- to fetch all routes with pagination and filters
 */
export const getAllRoutes = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/listRoutes", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: FETCH_ROUTES, payload: { rows: [], count: 0 } });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_ROUTES,
            payload: { rows: data?.data, count: data?.count },
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: FETCH_ROUTES, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        cb && cb(null);
      });
  };
};

/**
 * @description -- to fetch route details
 */
export const getRouteDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/routeDetails", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: ROUTE_DETAILS, payload: null });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: ROUTE_DETAILS,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
        cb && cb(null)
      });
  };
};

export const updateRouteDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateRouteDetails", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        else {
          showToast(data?.message, true);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            showToast(data?.message);
            cb();
          }
        }
      })
      .catch(function (error) {
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const getRouteSchedculeData = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: EMPTY_ROUTE_SCHEDULE })
    geoAxios
      .post("/routeScheduleData", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SCHEDULE_DATA,
            payload: data?.data,
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
        cb && cb(null);
      });
  };
};

/**
 * @description -- to enable/disable routes in bulk
 */
export const updateRouteStatus = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/bulkDisableEnableRoutes", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 *
 * @description -- To get total, active and blocked counts for routes
 */
export const getRoutesStatusCount = (payload={}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/routesStatusWiseCount", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: ROUTE_STATUS_COUNT, payload: data?.data });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- to delete routes in bulk
 */
export const deleteRoutes = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/bulkDeleteRoutes", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- to assign or unassign a route [will be used in "add/update user"]
 */
export const assignDeassignRoutes = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/assignDeassignRoutes", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- to get routes that are assigned to a user [will be used in "add/update user"]
 * @param {*} payload -- takes userId & orgId
 * @param {*} cb
 * @returns
 */
export const getUserAssignedRoutes = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getUserAssignedRoutes", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

// export const createRoute = (payload, cb) => {
//   return (dispatch) => {
//     dispatch({ type: FETCH_START });
//     axios
//       .post("/createRoute", { ...payload })
//       .then(({ data }) => {
//         if (data.success) {
//           callback();
//         }
//         function callback() {
//           dispatch({ type: FETCH_SUCCESS });
//           showToast(data?.message);
//           if (cb) {
//             cb();
//           }
//         }
//       })
//       .catch(function (error) {
//         showToast(error?.response?.data?.message, true);
//         console.log("Error****:", error.message);
//       });
//   };
// };
export const createRoute = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/createRoute", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        showToast(error?.response?.data?.message, true);
        dispatch({ type: FLUSH_ROUTES_DATA });
        console.log("Error****:", error.message);
      });
  };
};

export const clearRouteData = (payload) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ROUTE_DATA, payload: payload });
  };
};

// export const getAndSaveTotalDistanceRoute = (payload, cb) => {
//   return (dispatch) => {
//     dispatch({ type: FETCH_START });
//     geoAxios
//       .post("/getAndSaveTotalDistanceRouteWise", { ...payload })
//       .then(({ data }) => {
//         if (data.success) {
//           cb(); // Corrected to call the provided callback
//         }
//         function cb() {
//           dispatch({ type: FETCH_SUCCESS });
//           dispatch({ type: ROUTE_DISTANCE, payload: data.data });
//           if (cb) {
//             cb();
//           }
//         }
//       })
//       .catch(function (error) {
//         console.log("Error****:", error.message);
//         console.log("error get?");
//         if (cb) {
//           cb(error);
//         }
//       });
//   };
// };

export const getAndSaveTotalDistanceRoute = (payload, cb) => {

  function rearrangeIfSmaller(obj) {
    const latLng = obj && obj?.split(',');
    if (Math.abs(parseFloat(latLng[0])) > Math.abs(parseFloat(latLng[1]))) {
      return latLng[1] + ',' + latLng[0];
    }
    return obj;
  }

  payload.sourceObj.srcLATLNG = rearrangeIfSmaller(payload && payload?.sourceObj && payload?.sourceObj?.srcLATLNG ? payload?.sourceObj?.srcLATLNG : '-');
  payload.destinationObj.desLATLNG = rearrangeIfSmaller(payload && payload?.destinationObj && payload?.destinationObj?.desLATLNG ? payload?.destinationObj?.desLATLNG : '-');






  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getAndSaveTotalDistanceRouteWise", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: ROUTE_DISTANCE,
            payload: { rows: data.data },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: ROUTE_DISTANCE, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const updateRouteListFlag = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ROUTE_FLAG, payload: payload });
  };
};

export const flushRoutes = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_ROUTES" });
  };
};

export const flushScheduleData = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_SCHEDULE_DATA" });
  };
};
