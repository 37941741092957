
import React, { useEffect, useRef } from 'react';

export default function DriverMasterIndex() {

    const lynkitioServer = process.env.REACT_APP_ENV === "development" ? `https://beta.lynkit.io/login` : `https://www.lynkit.io/login`;
    
    const action = "/driver";

    // https://beta.lynkit.io/login?auth=lynktrac&timestamp=${new Date().getTime()}&action=%2Fdriver

    return (
        <div>
            {/* Other components or content */}
            <LynkitIOIframe lynkitioServer={lynkitioServer} action={action} />
        </div>
    );
}


export const LynkitIOIframe = ({ lynkitioServer, action }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const auth = "lynktrac";
        const timestamp = new Date().getTime();

        const url = new URL(lynkitioServer);
        url.searchParams.append("action", action);
        url.searchParams.append("auth", auth);
        url.searchParams.append("timestamp", timestamp);

        // Check if an iframe already exists
        let iframe = containerRef.current.querySelector('iframe');
        if (!iframe) {
            iframe = document.createElement('iframe');
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.border = 'none';
            containerRef.current.appendChild(iframe);
        }

        iframe.src = url.toString();

        const msgToSend = {
            phrase: JSON.parse(localStorage.getItem("token")),
        };

        const messageEventListener = (evt) => {
            if (lynkitioServer.includes(evt.origin)) {
                try {
                    const req_data = typeof evt.data == "string" ? JSON.parse(evt.data) : evt.data;
                    if (req_data.perkey == "sendphrase") {
                        evt.source.postMessage(msgToSend, evt.origin);
                    }

                } catch (error) {
                    console.error(error)
                }

            }
        };

        window.addEventListener("message", messageEventListener);

        return () => {
            window.removeEventListener("message", messageEventListener);
        };
    }, [lynkitioServer, action]);

    return (
        <>
            <div style={{ height: '100vh' }} ref={containerRef} />
        </>
    );
};