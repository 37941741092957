import {
  capitalizeSentence,
  convertIntoGivenTimezone,
} from "../../../services/functions";
import { fetchAddressFromCoords, userDetails } from "../../../redux/actions";
import { renderToString } from "react-dom/server";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { graphColors } from "../../../services/constants";
import connection_status from "../../../../src/assets//img/connection_status.png";
import uncoonected from "../../../../src/assets/img/uncoonected.png";
import attached from "../../../../src/assets/img/asset_green_1.png";
import deattached from "../../../../src/assets/img/asset_grey_1.png";
import new_auth from "../../../../src/assets/img/new_auth.png";
import new_lock from "../../../../src/assets/img/new_lock.png";
import new_tamp from "../../../../src/assets/img/new_tamp.png";
import new_motor from "../../../../src/assets/img/new_motor.png";
import fixedTrackerConnected from "../../../../src/assets/img/Connected-FT.png"
import fixedTrackerDisconnected from "../../../../src/assets/img/Disconnected-FT.png"
import fixedTrackerPowerOff from "../../../../src/assets/img/Poweroff-FT.png"
import { Box, Button } from "@mui/material";
import { havePermission } from "../../../utils/permissionCheck";
import { sendCustomEventAnalytics } from "../../../utils/util";

export function main(
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  setTableIndex,
  dispatch,
  fetchedAddress,
  tableIndex,
  setAdditionalInfoModal,
  SetDeviceId,
  history,
  deviceStatusGraphData,
  handlePasscodes,
  passcodeValue,
  setPasscodeValue,
  deviceType,
  currentUserDetail,
  passcodeLoader,
  passcodeIndex,
  setPasscodeIndex,
  handleSaveFiltersForStore,
) {
  switch (str) {
    case "fnClientId":
      return fnClientId(
        pKey,
        pLabel,
        objFamily,
        {},
        index,
        history,
        deviceType,
        deviceStatusGraphData,
        handleSaveFiltersForStore,
      );
    case "fnGPSSignal":
      return fnGPSSignal(pKey, pLabel, objFamily, {}, currentUserDetail);
    case "fnGPS":
      return fnGPS(pKey, pLabel, objFamily, {});
    case "fnLocation":
      return fnLocation(
        pKey,
        pLabel,
        objFamily,
        {},
        index,
        setTableIndex,
        dispatch,
        fetchedAddress,
        tableIndex
      );
    case "fnGeofence":
      return fnGeofence(pKey, pLabel, objFamily, {});
    case "fncAdminUsers":
      return fncAdminUsers(pKey, pLabel, objFamily, {});
    case "fnAdditionalDetails":
      return fnAdditionalDetails(
        str,
        pKey,
        (pLabel = ""),
        objFamily,
        index,
        setTableIndex,
        dispatch,
        fetchedAddress,
        tableIndex,
        setAdditionalInfoModal,
        SetDeviceId,
        history
      );
    case "fnLicenceId":
      return fnLicenceId(pKey, pLabel, objFamily, {});
    case "fnConnectionStatus":
      return fnConnectionStatus(pKey, pLabel, objFamily, deviceType);
    case "fnDeviceStatus":
      return fnDeviceStatus(pKey, pLabel, objFamily, {});
    case "fnFuelStatus":
      return fnFuelStatus(pKey, pLabel, objFamily, {});
    case "fnFuelReq":
      return fnFuelReq(pKey, pLabel, objFamily, {});
    case "fnBatterPercentage":
      return fnBatterPercentage(pKey, pLabel, objFamily, {});
    case "fnHourMeterReading":
      return fnHourMeterReading(pKey, pLabel, objFamily, {});
    case "fnLockStatus":
      return fnLockStatus(pKey, pLabel, objFamily, {});
    case "fnSpeedKm":
      return fnSpeedKm(pKey, pLabel, objFamily, {});
    case "fnIgnitionStatus":
      return fnIgnitionStatus(pKey, pLabel, objFamily, {});
    case "fncTripId":
      return fncTripId(pKey, pLabel, objFamily, {});
    case "fnctripStatus":
      return fnctripStatus(pKey, pLabel, objFamily, {});
    case "fnDoorStatus":
      return fnDoorStatus(pKey, pLabel, objFamily, {});
    case "fnceviewPasscode":
      return fnPassword(
        pKey,
        pLabel,
        objFamily,
        index,
        handlePasscodes,
        passcodeValue,
        setPasscodeValue,
        deviceType,
        currentUserDetail,
        passcodeLoader,
        passcodeIndex,
        setPasscodeIndex,
      );
    default:
      if (pKey === "pswd") {
        return fnPassword(
          pKey,
          pLabel,
          objFamily,
          index,
          handlePasscodes,
          passcodeValue,
          setPasscodeValue,
          deviceType
        );
      } else {
        try {
          let valueStr = getNestedValues(objFamily, pKey);
          return `${valueStr}`;
        } catch (error) {
          return pLabel && pLabel != "" ? `${pLabel} : - ` : "-";
        }
      }
  }
}

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
];

const fnClientId = (
  pKey,
  pLabel = "",
  objFamily,
  { },
  index,
  history,
  deviceType,
  deviceStatusGraphData,
  handleSaveFiltersForStore,
) => {
  if (objFamily["client_id"]) {
    const spanId = `clientId-${index}`;
    const spanHtml = `<div id=${spanId} class="color-chip" style="background: ${objFamily["ignition_status"] === "MOVING"
      ? graphColors?.dullGreen
      : objFamily["ignition_status"] === "IDLE"
        ? graphColors?.yellow
        : objFamily["ignition_status"] === "IGNITION_OFF"
          ? graphColors?.grey
          : objFamily["ignition_status"] === "OFFLINE"
            ? graphColors?.black
            : "transparent"
      };color: ${objFamily["ignition_status"] === "MOVING"
        ? "#ffffff"
        : objFamily["ignition_status"] === "IDLE"
          ? "#000000"
          : objFamily["ignition_status"] === "IGNITION_OFF"
            ? "#000000"
            : objFamily["ignition_status"] === "OFFLINE"
              ? "#ffffff"
              : "#ff7700"
      };">${objFamily["client_id"]}</div>`;
    // const spanHtml = `<span style="color: #ff7700; cursor: pointer" id='${spanId}'>${objFamily["client_id"]}</span>`;
    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          sendCustomEventAnalytics("devices", "go-to-device-map", "go-to-device-map")
          //redirect to map page
          handleSaveFiltersForStore();
          if (objFamily.deviceType === 'camera') {
            history.push(`/dashboard/devices/camera/${objFamily["client_id"]}`);
          }
          else {
            history.push(`/dashboard/devices/map/${objFamily["client_id"]}`);
          }
        };
      }
    }, 50);

    return spanHtml;
    //return `<span style="color: #ff7700; cursor: pointer">${objFamily["client_id"]}</span>`;
  } else {
    return "-";
  }
};
const fnBatterPercentage = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily[pKey]) {
    return `${new Number(objFamily[pKey])?.toFixed(2)}%`;
  } else {
    return "-";
  }
};
const fnDoorStatus = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily[pKey]) {
    if(objFamily?.formData?.irsensortype && objFamily?.formData?.irsensortype !== "na"){
      if (objFamily[pKey] === '0') {
        return `<span style="color: red; " >Door Open</span>`
      }
      else if (objFamily[pKey] === '1') {
        return `<span style="color: green; " >Door Close</span>`
      }
    }else{
      return "-";
    }
  } else {
    return "-";
  }
};

const fncTripId = (pKey, pLabel = "", objFamily, { }) => {
  let spanHtml = "";
  if (objFamily["tripId"]) {
    let lTripId = objFamily?.tripId;

    if (lTripId !== "") {
      spanHtml = `${lTripId}`;
      return spanHtml;
    } else {
      return (spanHtml = "-");
    }
  } else {
    return (spanHtml = "-");
  }
};
const fnctripStatus = (pKey, pLabel = "", objFamily, { }) => {
  let spanHtml = "";
  if (objFamily["status"]) {
    let lStatus = objFamily?.status;

    if (lStatus && lStatus !== "" && lStatus !== "error") {
      spanHtml += `${lStatus === "in_transit" ? "In-Transit" : capitalizeSentence(lStatus)
        }`;
      return spanHtml;
    } else {
      return (spanHtml = "-");
    }
  } else {
    return (spanHtml = "-");
  }
};
const fnLockStatus = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["lockStatus"]) {
    let spanHtml = "";
    let lStatus = objFamily?.lockStatus;
    let iconImage;

    if (lStatus === "locked") {
      iconImage = new_lock;
    } else if (lStatus === "unlocked") {
      iconImage = new_auth;
    } else if (lStatus === "motorUnlocked") {
      iconImage = new_motor;
    } else if (lStatus === "tampered") {
      iconImage = new_tamp;
    }

    spanHtml = `<img class='imgSize' style="width : 23px; height:22px;" src=${iconImage} alt='' />`;
    spanHtml += `<br/>`;
    spanHtml += `${capitalizeSentence(lStatus)}`;

    return spanHtml;
  } else {
    return "-";
  }
};

const fnGPSSignal = (pKey, pLabel = "", objFamily, { }, currentUserDetail) => {
  const timestamp = objFamily["packet_time"];
  const date = timestamp && new Date(Number(timestamp) * 1000);
  const formattedDate = convertIntoGivenTimezone(
    date,
    currentUserDetail.timeZone.offset
  );
  if (objFamily["packet_time"]) {
    return `${formattedDate}`;
  } else {
    return "-";
  }
};
const fnGPS = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["satellite_count"]) {
    let val = objFamily["satellite_count"];

    if (val <= 5) {
      return `<span style="color: red; cursor: pointer">Poor</span>`;
    } else if (val >= 5 && val <= 11) {
      return `<span style="color: rgb(52, 168, 83); cursor: pointer">Average</span>`;
    } else {
      return `<span style="color: rgb(241, 194, 50); cursor: pointer">Good</span>`;
    }
  }
  else {
    return "-";
  }
};

const fnConnectionStatus = (pKey, pLabel = "", objFamily, deviceType) => {
  if (objFamily["lock_status"]) {
    if (deviceType === "fixedELock") {
      let spanHtml = "";
      let val =
        objFamily["lock_status"] === "locked"
          ? "External Battery Connected"
          : "External Battery Disconnected";
      spanHtml =
        objFamily["lock_status"] === "locked"
          ? "External Battery Connected"
          : "External Battery Disconnected"
            ? capitalizeSentence(val)
            : "-";
      spanHtml += `<br/>`;
      spanHtml += `<img class='imgSize' src=${objFamily["lock_status"] === "locked" ? connection_status : uncoonected
        } alt='' />`;

      return spanHtml;
    } else if(deviceType === "fixedTracker"){
      let spanHtml = "";

      spanHtml = objFamily["lock_status"] ?? "-";
      spanHtml += `<br/>`;
      spanHtml += `<img class='imgSize-sm' src=${objFamily["lock_status"] === "External Power Connected" ? fixedTrackerConnected : objFamily["lock_status"] === "External Power Disconnected" ? fixedTrackerDisconnected : fixedTrackerPowerOff
        } alt='' />`;

      return spanHtml;
    } else if (deviceType === "assetTracker") {
      let spanHtml = "";
      let val =
        objFamily["lock_status"] === "locked" ? "Attached" : "De-attached";
      spanHtml =
        objFamily["lock_status"] === "locked"
          ? "Attached"
          : "De-attached"
            ? capitalizeSentence(val)
            : "-";
      spanHtml += `<br/>`;
      spanHtml += `<img class='imgSize' src=${objFamily["lock_status"] === "locked" ? attached : deattached
        } alt='' />`;

      return spanHtml;
    }
  } else {
    return "-";
  }
};

const fnFuelStatus = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["fuel"]) {
    let val = objFamily["fuel"] ? capitalizeSentence(objFamily["fuel"]) : "-";
    return `<span>${val}</span>`;
  } else {
    return "-";
  }
};

// mongoooooooooooooo
const fnFuelReq = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["fuelRequest"]) {
    let val = objFamily["fuelRequest"]
      ? capitalizeSentence(objFamily["fuelRequest"])
      : "-";
    return `<span>${val}</span>`;
  } else {
    return "-";
  }
};
const fnHourMeterReading = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["hmr"]) {
    let val = objFamily["hmr"] ? capitalizeSentence(objFamily["hmr"]) : "-";
    return `<span>${val}</span>`;
  } else {
    return "-";
  }
};
const fnDeviceStatus = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["ignition_status"]) {
    let val = objFamily["ignition_status"]
      ? objFamily["ignition_status"] === "IGNITION_OFF" ? "Ignition Off" : capitalizeSentence(objFamily["ignition_status"])
      : "-";
    return `<span>${val}</span>`;
  } else {
    return "-";
  }
};

const fnLicenceId = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily["asset_id"]) {
    let val = objFamily["asset_id"]
      ? capitalizeSentence(objFamily["asset_id"])
      : "-";
    return `<span style="color: #ff7700;>${val}</span>`;
  } else {
    return "-";
  }
};

const fnSpeedKm = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily && objFamily["speed"]) {
    return `${objFamily?.speed} Km/h`;
  } else {
    return "-";
  }
};

const fnIgnitionStatus = (pKey, pLabel = "", objFamily, { }) => {
  if (objFamily && objFamily["ignition_status"]) {
    return "";
  } else {
    return "";
  }
};

const fnLocation = (
  pKey,
  pLabel = "",
  objFamily,
  { },
  index,
  setTableIndex,
  dispatch,
  fetchedAddress,
  tableIndex
) => {
  if (objFamily["latitude"] && objFamily["longitude"]) {
    const spanId = `location-${index}`;
    let result =
      fetchedAddress?.address && tableIndex === objFamily
        ? fetchedAddress?.address
        : `${Number(objFamily["latitude"])?.toFixed(5)?.toString()} / ${Number(
          objFamily["longitude"]
        )?.toFixed(5)}`;

    const spanHtml = `<span class='addressCals' id='${spanId}'>
        ${result}
        </span>`;
    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          setTableIndex(objFamily);
          let data = {
            lat: objFamily["latitude"],
            long: objFamily["longitude"],
          };
          handleFetchAdress(data, dispatch);
        };
      }
    }, 50);
    return spanHtml;
  } else {
    return "-";
  }
};
const handleFetchAdress = (data, dispatch) => {
  if (data) {
    const payload = {
      latitude: data?.lat,
      longitude: data?.long,
      appType: "GOOGLE",
    };

    dispatch(fetchAddressFromCoords(payload));
  }
};
const fnGeofence = (pKey, pLabel = "", objFamily, { }) => {
  if (
    objFamily &&
    objFamily["geo_name"] &&
    objFamily["geo_name"] !== "nil" &&
    objFamily["geo_name"]?.startsWith("[")
  ) {
    let val =
      objFamily["geo_name"]?.length > 0
        ? JSON.parse(objFamily["geo_name"])?.join(", ")
        : "-";
    return `<span>${val}</span>`;
  } else if (
    objFamily &&
    objFamily["geo_name"] &&
    objFamily["geo_name"] !== "nil"
  ) {
    return objFamily["geo_name"] ?? "-";
  } else {
    return "-";
  }
  // if (objFamily && objFamily["geoName"]) {
  //   return `<div class="max-width-table-cell">${objFamily["geoName"]}</div>`;
  // } else {
  //   return "-";
  // }
};

const fnPassword = (
  pKey,
  pLabel = "",
  objFamily,
  index,
  handlePasscodes,
  passcodeValue,
  setPasscodeValue,
  deviceType,
  currentUserDetail,
  passcodeLoader,
  passcodeIndex,
  setPasscodeIndex,
) => {
  if (objFamily && objFamily[pKey] && objFamily[pKey] !== "") {
    const btnId = `pass-btn-${index}`;
    const spanBtnId = `hide-btn-${index}`;
    const formData = objFamily?.formData ? JSON.parse(objFamily?.formData) : null;
    const contentHtml = passcodeLoader && passcodeIndex === index ? `<div class="flex-row-center"><div class="small-custom-loader" /></div>` : havePermission(
      currentUserDetail,
      "unlockingOption",
      "viewPasscode"
    ) && formData && formData?.viewpasscode == true
      ? passcodeValue &&
        passcodeValue?.id === objFamily?.client_id &&
        passcodeValue?.passcode
        ? `<div class="flex-row-center"><div class="passcode-val-container">
            <span>${passcodeValue?.passcode}</span>
            <span style="cursor:pointer;" id=${spanBtnId}>&#10006;</span>
          </div></div>`
        : `<div class="filled-theme-btn" id=${btnId}>Passcode</div>`
      : ``;
    setTimeout(() => {
      const btnElement = document.getElementById(btnId);
      const hideBtnElement = document.getElementById(spanBtnId);
      if (btnElement) {
        btnElement.onclick = () => {
          sendCustomEventAnalytics("devices", "view-passcode", "view-passcode")
          handlePasscodes(objFamily);
          setPasscodeIndex(index);
        };
      }
      if (hideBtnElement) {
        hideBtnElement.onclick = () => {
          sendCustomEventAnalytics("devices", "hide-passcode", "hide-passcode")
          setPasscodeValue(null);
          setPasscodeIndex(null);
        };
      }
    }, 50);
    return contentHtml;
  } else {
    if (deviceType && deviceType === "fixedELock") {
      const btnId = `pass-btn-${index}`;
      const spanBtnId = `hide-btn-${index}`;
      const contentHtml = havePermission(
        currentUserDetail,
        "unlockingOption",
        "viewPasscode"
      )
        ? passcodeValue &&
          passcodeValue?.id === objFamily?.client_id &&
          passcodeValue?.passcode
          ? `<div class="passcode-val-container">
          <span>${passcodeValue?.passcode}</span>
          <span style="cursor:pointer;" id=${spanBtnId}>&#10006;</span>
        </div>`
          : `<div class="filled-theme-btn" id=${btnId}>Passcode</div>`
        : "";
      setTimeout(() => {
        const btnElement = document.getElementById(btnId);
        const hideBtnElement = document.getElementById(spanBtnId);
        if (btnElement) {
          btnElement.onclick = () => {
            sendCustomEventAnalytics("devices", "view-passcode", "view-passcode")
            handlePasscodes(objFamily);
          };
        }
        if (hideBtnElement) {
          hideBtnElement.onclick = () => {
            sendCustomEventAnalytics("devices", "hide-passcode", "hide-passcode")
            setPasscodeValue(null);
          };
        }
      }, 50);
      return contentHtml;
    } else {
      return "";
    }
  }
};

// mongoooooooooooooo
const fncAdminUsers = (pKey, pLabel = "", objFamily) => {
  if (objFamily[pKey]) {
    const adminName = `${objFamily[pKey]}`;
    const orgName = `${objFamily?.orgName ?? "-"}`;
    if (adminName.length > 14) {
      let adminShortText = adminName.substring(0, 14) + "...";
      let orgNameShortText = orgName.substring(0, 14) + "...";
      return `<div class="tooltip-container">
                    <span style="cursor: pointer; font-size:13px;">${adminShortText} ${objFamily?.orgName ? `- ${orgNameShortText}` : ""}</span>
                    <span class="tooltip-content">${objFamily[pKey]} ${objFamily?.orgName ? `- ${objFamily?.orgName}` : ""}</span>
                </div>`;
    } else {
      return `<span style="cursor: pointer; font-size:13px;">${objFamily[pKey]} ${objFamily?.orgName ? `- ${objFamily?.orgName}` : ""}</span>`;
    }
  }
  return "-";
};
// mongoooooooooooooo
const fnAdditionalDetails = (
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  setTableIndex,
  dispatch,
  fetchedAddress,
  tableIndex,
  setAdditionalInfoModal,
  SetDeviceId,
  history
) => {
  // const btnId = `ad-btn-${index}`;
  // const divHtml = `
  // <div id='${btnId}' class="outlined-theme-btn">
  //   <i class="fa fa-info circleIcon" id='${btnId}></i>
  // </div>`;
  // setTimeout(() => {
  //   const btnElement = document.getElementById(btnId);
  //   if (btnElement) {
  //     btnElement.onclick = () => {
  //       setAdditionalInfoModal(true);
  //       SetDeviceId(objFamily["_id"]);
  //     };
  //   }
  // }, 50);

  const iconId = `icon-${index}`;
  const spanHtml = `<div class="outlined-theme-btn" id=${iconId}><i class="fa fa-info" style="color: #ff7700;" aria-hidden="true"></i>
  </div>`;
  // const spanHtml = `<i class="fa fa-info circleIcon" id='${iconId}'></i>`;
  setTimeout(() => {
    const spanElement = document.getElementById(iconId);
    if (spanElement) {
      spanElement.onclick = () => {
        sendCustomEventAnalytics("devices", "open-additional-details", "open-additional-details")
        setAdditionalInfoModal(true);
        SetDeviceId(objFamily["_id"]);
      };
    }
  }, 50);
  return spanHtml;
};

function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  return formattedDate;
}

function processTimeZone(
  timeToConvert,
  timeZone,
  format = "MMM DD, YYYY, hh:mm:ss A"
) {
  const date = new Date(timeToConvert);
  const formattedDate = formatDate(date);
  return formattedDate;
}

function getNestedValues(objFamily, keyPath) {
  const keys = keyPath.split(".");
  let value = objFamily;
  for (const key of keys) {
    if (value && value.hasOwnProperty(key)) {
      value = value[key] || "-";
    } else {
      return "-";
    }
  }
  return value;
}
