/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  Checkbox,
  FormControlLabel,
  IconButton,
  Drawer,
  Tabs,
  Tab,
  MenuItem,
} from "@mui/material";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { CustomInput } from "../../../components/FormUI";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import {
  createGeofence,
  getAllGeofences,
  updateGeofenceDetails,
  checkTemplateExistance,
  getAllOrganisations,
  getOrganisation,
  userDetails,
} from "../../../redux/actions";
import { showToast } from "../../../services/functions";
import { useDebounce } from "../../../utils/hooks";
import history from "../../../services/history";
import DynamicFormRender from "../../Components/DynamicFormRender";
import { ArrowForwardIos, Google } from "@mui/icons-material";
import { havePermission } from "../../../utils/permissionCheck";

const GeofenceDrawer = (props) => {
  const dispatch = useDispatch();
  const [checkState, setCheckState] = useState(false);
  const { message, error } = useSelector((state) => state.common);
  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm();
  const {
    polygonCoordinates,
    geofenceType,
    searchInput,
    lat,
    lng,
    address,
    centerLat,
    centerLng,
    setAddress,
    drawingradii,
    addMarker,
    radii,
    setRadius,
    circleCrordinates,
    markersArray,
    setpolygonCoordinates,
    setCircleCoordinates,
    geofenceDetails,
    orgListing,
    superAdmin = {},
    Users,
    setOrganisationId,
    updateMarker,
    isDrawerOpen,
    setIsDrawerOpen,
    toggleDrawer,
    tabIndex,
    setTabIndex,
    setUserId,
    clearMap,
    searchFields,
    setSearchFields,
    handleSearchOrg,
    handleSearchUsers,
  } = props;
  let geopayload = {
    page: 1,
    limit: 10,
    selection: [
      "name",
      "description",
      "geofence",
      "centerCoordinates",
      "radius",
      "polygonType",
      "accessID",
      "status",
      // "orgId",
      "addedOn",
      "addedBy",
      "modifiedOn",
      "modifiedBy",
      "dynamicData",
      "address",
      "latitude",
      "longitude",
      "user",
    ],
  };
  // const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [btn, setBtn] = useState("polygon");
  const [loader, setLoader] = useState(true);
  const [addState, setAddState] = useState(null);
  const [geoData, setGeoData] = useState({});
  const [organizationList, setOrganizationList] = useState([]);
  const [userList, setUserList] = useState([]);
  const userPermissions =
    useSelector((state) => state.auth?.currentUserDetail) || {};
  const url = window.location.pathname;
  const templateExistence = useSelector(
    (state) => state.templates.templateExistence
  );
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;
  const toggleBtn = (val) => {
    setCircleCoordinates([]);
    setpolygonCoordinates([]);
    if (val === "polygon") {
      setBtn("polygon");
    } else {
      setBtn("Circular");
    }
  };

  const getTabStyles = (tIndex) => {
    return {
      backgroundColor:
        tabIndex == tIndex ? themeType.default.themeOrange : "transparent",
      color: tabIndex == tIndex ? "white" : themeType.default.themeOrange,
      border: "1px solid",
      boxShadow: "0px 2px 6px gray",
      borderColor: themeType.default.themeOrange,
      p: 0,
      pt: "10px",
      pb: "10px",
      fontSize: "14px",
      lineHeight: "19px",
      fontWeight: "400",
      textTransform: "none",
    };
  };

  // const toggleDrawer = () => {
  //     setIsDrawerOpen(!isDrawerOpen);
  // };
  useEffect(() => {
    if (lat && lng) {
      setValue("latitude", lat);
      setValue("longitude", lng);
      setError("latitude", { type: "custom", message: "" });
      setError("longitude", { type: "custom", message: "" });
    }
  }, [lat, lng]);

  useEffect(() => {
    setValue("latitude", centerLat);
    setValue("longitude", centerLng);
  }, [centerLat, centerLng]);

  useEffect(() => {
    setRadius(Math.ceil(drawingradii));
    setValue("radius", Math.ceil(drawingradii));
  }, [drawingradii]);

  useEffect(() => {
    if(orgListing){
      setOrganizationList(orgListing);
    }
  }, [orgListing]);

  useEffect(() => {
    if(Users){
      setUserList(Users);
    }
  }, [Users])

  const onSubmit = (data) => {
    setLoader(true);
    if (data.name === null) {
      setError("name", { type: "custom", message: "Name is required" });
    }
    if (data.latitude === "") {
      setError("latitude", { type: "custom", message: "Latitude is required" });
    }
    if (data.longitude === "") {
      setError("longitude", {
        type: "custom",
        message: "Longitude is required",
      });
    }
    if (data.orgId === "") {
      setError("longitude", {
        type: "custom",
        message: "Organisation is required",
      });
    } else {
      let payload = {
        name: data?.name,
        // description: data?.description === null ? '' : data.description,
        geofence: {
          coordinates:
            geofenceType === "Polygon" ? polygonCoordinates : circleCrordinates,
          type: geofenceType,
        },
        polygonType: geofenceType,
        radius: geofenceType === "Polygon" ? 10 : Number(data?.radius),
        address: address,
        latitude:
          data?.latitude !== null ? data?.latitude?.toString() || "" : "",
        longitude:
          data?.longitude !== null ? data?.longitude?.toString() || "" : "",
        templateId: (templateExistence && templateExistence?._id) || "",
        // user: {
        //     id: data?.id,
        //     name: Users?.find((_) => _?._id == data.id)?.name || "" ,
        //     orgId: data?.orgId,
        //     orgName: orgListing?.find((_) => _?._id == data.orgId)?.name || "" ,
        // }
      };
      if (Object.keys(superAdmin).length) {
        payload.user = {
          id: data?.id,
          name: userList?.find((_) => _?._id == data?.id)?.name || "",
          orgId: data?.orgId,
          orgName: organizationList?.find((_) => _?._id == data?.orgId)?.name || "",
        };
      }
      sendCustomEventAnalytics("geofence", "Create geofence", "Create geofence")
      handleSubmit(
        (data) => {
          const dynamicData = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith("dynamic")) {
              acc[key] = data[key];
            }
            return acc;
          }, {});
          let finalPayload = { ...payload, dynamicData };
          dispatch(
            createGeofence(finalPayload, (res) => {
              if (res && res?.success) {
                showToast(res?.message, false);

                if (window.location.pathname == "/dashboard/geomap/create") {
                  let payload = {
                    geo_name: res?.data?.geoName,
                    geoId: res?.data?.geoId,
                  };
                  if (window.opener) {
                    window.opener.postMessage(payload, '*');
                    setTimeout(() => {
                      window.self.close()
                    }, [2000])
                  }
                }
                if (checkState === false && window.location.pathname !== "/dashboard/geomap/create") {
                  history.push("/dashboard/geofence");
                }
                if (markersArray) {
                  for (let i = 0; i < markersArray.length; i++) {
                    markersArray[i].setMap(null);
                  }
                  markersArray.length = 0;
                }
                clearMap();
                setAddress("");
                reset();
              }
              setLoader(false);
            })
          );
        },
        (errors) => {
          let errorField = Object.keys(errors)[0];
          showToast(`${errors[errorField].message}`, true);
        }
      )();
    }
  };

  const clearVals = () => {
    // setValue("name", '');
    setValue("latitude", "");
    setValue("longitude", "");
  };

  useEffect(() => {
    if (message && loader) {
      // showToast(message, false);
      // setLoader(true);
      // if (checkState === false) {
      //   history.push("/dashboard/geofence");
      // }
      // dispatch(getAllGeofences(geopayload));
      // if (markersArray) {
      //   for (let i = 0; i < markersArray.length; i++) {
      //     markersArray[i].setMap(null);
      //   }
      //   markersArray.length = 0;
      // }
      // clearMap();
      // setAddress("");
      // reset();
      // window.location.reload(true);
    }
    if (error && loader) {
      showToast(error, true);
      setLoader(true);
    }
  }, [message, error]);

  const lati = useWatch({
    control,
    name: "latitude",
  });
  const longi = useWatch({
    control,
    name: "longitude",
  });

  const res = useWatch({
    control,
    name: "orgId",
  });

  const userIdWatch = useWatch({
    control: control,
    name: "id",
  });

  useEffect(() => {
    setOrganisationId(res);
  }, [res]);

  useEffect(() => {
    setUserId(userIdWatch);
  }, [userIdWatch]);

  const debouncedLati = useDebounce(lati, 1000);
  const debouncedLongi = useDebounce(longi, 1000);

  useEffect(() => {
    if (debouncedLati && debouncedLongi) {
      setAddress("Fetching...");
      const latlng = new google.maps.LatLng(debouncedLati, debouncedLongi);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            if (Object.keys(geoData).length === 0) {
              addMarker(debouncedLati, debouncedLongi);
            } else {
              if (!geoData.markerUpdated) {
                updateMarker(debouncedLati, debouncedLongi);
                geoData.markerUpdated = true;
              }
            }
            setAddress(results[0].formatted_address);
          } else {
            // setAddress("Address Not Found..")
            showToast("No address found for these coordinates.", true);
          }
        } else {
          // showToast("Geocoding failed due to: " + status, true);
        }
      });
    } else {
      // console.log("Please enter both latitude and longitude.")
    }
  }, [debouncedLati, debouncedLongi]);

  const addMore = () => {
    // const currentData = getValues();
    // onSubmit(currentData)
    setCheckState(!checkState);
  };
  useEffect(() => {
    setGeoData(geofenceDetails);
  }, [geofenceDetails]);

  // useEffect(() => {
  //   if (Object.keys(geoData).length) {
  //     setValue("name", geoData?.name);
  //     setValue("latitude", geoData?.latitude || "");
  //     setValue("longitude", geoData?.longitude || "");
  //     setValue("radius", geoData?.radius);
  //     setValue("orgId", geoData?.user?.orgId);
  //     setValue("id", geoData?.user?.id);
  //     setRadius(geoData?.radius);
  //     setAddress(geoData?.address);

  //     if (geoData?.dynamicData) {
  //       for (const key in geoData && geoData.dynamicData) {
  //         setValue(key, geoData.dynamicData[key]);
  //       }
  //     }
  //   }
  // }, [geoData]);

  useEffect(() => {
    if (Object.keys(geoData).length) {
      setValue("name", geoData?.name);
      setValue("latitude", geoData?.latitude || "");
      setValue("longitude", geoData?.longitude || "");
      setValue("radius", geoData?.radius);
      setRadius(geoData?.radius);
      setAddress(geoData?.address);

      let orgCheck =  organizationList?.find((_) => _?._id == geoData?.user?.orgId) || ""
      let userCheck =  userList?.find((_) => _?._id == geoData?.user?.id) || ""


      if(orgCheck){
        setValue("orgId", geoData?.user?.orgId);
      }
      else{
        dispatch(getOrganisation({ id: geoData?.user?.orgId },(res)=>{
          if(res){
            let tempOrgList = organizationList;
            tempOrgList.push({_id: res?._id, name: res?.name})
            setOrganizationList([...tempOrgList]);
            setValue("orgId", res?._id);
          }
        }));
      }

      if(userCheck){
        setValue("id", geoData?.user?.id);
      }
      else{
        dispatch(userDetails({ id: geoData?.user?.id },(res)=>{
          if(res){
            let tempUserList = userList;
            tempUserList.push({_id: res?._id, name: res?.name, orgName: res?.orgName})
            setUserList([...tempUserList]);
            setValue("id", res?._id);
          }
        }));
      }
      if (geoData?.dynamicData) {
        for (const key in geoData && geoData.dynamicData) {
          setValue(key, geoData.dynamicData[key]);
        }
      }
    }
  }, [geoData]);

  const updateGeofence = () => {
    const currentData = getValues();
    const payload = {};

    // const fieldsToCopy = ["latitude", "longitude"];
    // fieldsToCopy.forEach((field) => {
    //   if (dirtyFields.hasOwnProperty(field)) {
    //     payload[field] = currentData[field];
    //   }
    // });
    // Object.keys(dirtyFields)?.forEach((field) => {
    //     payload[field] = currentData[field];
    // });
    payload["id"] = localStorage.getItem('geoId')
    payload["templateId"] = (templateExistence && templateExistence?._id) || "";
    (payload["geofence"] = {
      coordinates:
        geofenceType === "Polygon" ? polygonCoordinates : circleCrordinates,
      type: geofenceType,
    }),
      (payload["polygonType"] = geofenceType);
    payload["radius"] = Number(radii) || 10;
    payload["address"] = address;
    payload["name"] = currentData?.name;
    if (geofenceType !== "Polygon") {
      payload["latitude"] = circleCrordinates[0][0][1].toString();
      payload["longitude"] = circleCrordinates[0][0][0].toString();
    }

    if (Object.keys(superAdmin).length) {
      
      payload.user = {
        id: currentData?.id,
        name: userList?.find((_) => _?._id == currentData?.id)?.name || "",
        orgId: currentData?.orgId,
        orgName:
        organizationList?.find((_) => _?._id == currentData?.orgId)?.name || "",
      };
    }
    sendCustomEventAnalytics("geofence", "Update geofence", "Update geofence")
    handleSubmit(
      (data) => {
        const dynamicData = Object.keys(data).reduce((acc, key) => {
          if (key.startsWith("dynamic")) {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        let finalPayload = { ...payload, dynamicData };
        console.log('finalPayload--',finalPayload);
        dispatch(
          updateGeofenceDetails(finalPayload, (res) => {
            if (res && res?.success) {
              // showToast(res?.message, false);

              if (window.location.pathname.includes("dashboard/geo/edit")) {
                let payload = {
                  geo_name: res?.data?.name,
                  geoId: res?.data?._id,
                };
                if (window.opener) {
                  window.opener.postMessage(payload, '*');
                }
                setTimeout(() => {
                  window.self.close()
                }, [2000])
              }
              if (checkState === false && !window.location.pathname.includes("dashboard/geo/edit")) {
                history.push("/dashboard/geofence");
              }
              if (markersArray) {
                for (let i = 0; i < markersArray.length; i++) {
                  markersArray[i].setMap(null);
                }
                markersArray.length = 0;
              }
              localStorage.removeItem('geoId')
              clearMap();
              setAddress("");
              reset();
            }
            else{
              // localStorage.removeItem('geoId')
            }
            setLoader(false);
          })
        );
      },
      (errors) => {
        let errorField = Object.keys(errors)[0];
        showToast(`${errors[errorField].message}`, true);
      }
    )();
  };

  useEffect(() => {
    let payload = { category: "geofence" };
    dispatch(checkTemplateExistance(payload));
  }, []);

  return (
    <Box
      position="relative"
      sx={{
        width: isDrawerOpen ? 400 : 0,
        opacity: isDrawerOpen ? 1 : 0,
        transition: "all 0.6s ease-in-out",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ background: tableClasses.dark.backgroundColor }}
      >
        <Grid container>
          <>
            <Box
              mb={0.3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                width: "100%",
                top: "-1px",
                background: tableClasses.dark.backgroundColor,
                zIndex: 300,
                borderBottom: "1px solid #D9D9D9",
              }}
            >
              <IconButton
                data-testid="toggleBtn"
                sx={{ marginRight: "auto" }}
                onClick={() => setIsDrawerOpen(false)}
              >
                <ArrowForwardIos />
              </IconButton>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: "700",
                  width: "100%",
                  textAlign: "center",
                }}
              ></Typography>
            </Box>
            {geofenceDetails ? (
              <Box width="100%">
                <Tabs
                  value={tabIndex}
                  variant="fullWidth"
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },
                    "& .MuiTab-root.Mui-selected": { color: "#FFFFFF" },
                  }}
                  onChange={(e, val) => {
                    setTabIndex(val);
                    val === 0 ? toggleBtn("polygon") : toggleBtn("Circular");
                  }}
                >
                  <Tab
                    id="toggleToPolygon"
                    label="Polygon"
                    disabled={
                      Object.keys(geofenceDetails).length &&
                      geofenceDetails?.polygonType === "Circular"
                    }
                    sx={{
                      ...getTabStyles(0),
                      borderTopLeftRadius: "8px",
                    }}
                  />
                  <Tab
                    label="Circular"
                    id="toggleToCircle"
                    disabled={
                      Object.keys(geofenceDetails).length &&
                      geofenceDetails?.polygonType === "Polygon"
                    }
                    sx={{
                      ...getTabStyles(1),
                      marginLeft: "1px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                </Tabs>
              </Box>
            ) : (
              ""
            )}

            <Grid sx={{ width: "100%", overflowY: "scroll", height:localStorage.getItem('sidebar') ? '500px' : "400px" }}>
              {Object.keys(superAdmin).length ? (
                <>
                  <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                    >
                      <Controller
                        name="orgId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Organisation is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              data-testid="org"
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              name={name}
                              onChange={(e, newVal) => {
                                onChange(newVal?._id);
                                setError("orgId", {
                                  type: "custom",
                                  message: "",
                                });
                              }}
                              label="Organisation"
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (value === undefined) {
                                  return "";
                                }
                                if (typeof option === "string") {
                                  return (
                                    organizationList?.find((_) => _?._id == value)
                                      ?.name || ""
                                  );
                                } else {
                                  return option?.name || "";
                                }
                              }}
                              searchValue={
                                searchFields?.org && searchFields?.org !== ""
                                  ? searchFields?.org
                                  : null
                              }
                              onSearch={handleSearchOrg}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  if (e?.target?.value === "") handleSearchOrg(true);
                                  setSearchFields((prev) => ({
                                    ...prev,
                                    org: e?.target?.value?.trim(),
                                  }));
                                }
                              }}
                              filterSelectedOptions
                              labelKey="name"
                              options={organizationList && organizationList}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                    >
                      <Controller
                        name="id"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "User is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              data-testid="users"
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              onChange={(e, newVal) => {
                                onChange(newVal?._id);
                                setError("id", { type: "custom", message: "" });
                              }}
                              // key={value}
                              searchValue={
                                searchFields?.users && searchFields?.users !== ""
                                  ? searchFields?.users
                                  : null
                              }
                              onSearch={handleSearchUsers}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  if (e?.target?.value === "") handleSearchUsers(true);
                                  setSearchFields((prev) => ({
                                    ...prev,
                                    users: e?.target?.value?.trim(),
                                  }));
                                }
                              }}
                              name={name}
                              required
                              label="User"
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (value === undefined) {
                                  return "";
                                }
                                if (typeof option === "string") {
                                  return (
                                    userList?.find((_) => _?._id == value)?.name ||
                                    ""
                                  );
                                } else {
                                  return option?.name || "";
                                }
                              }}
                              filterSelectedOptions
                              labelKey="name"
                              renderOptions={(props, option, { selected }) => {
                                return (
                                  <MenuItem
                                    value={value || ""}
                                    selected={selected}
                                    {...props}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: ".8rem",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {`${option?.name} - ${option?.orgName}` || ""}
                                    </Typography>
                                  </MenuItem>
                                );
                              }}
                              options={userList && userList}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ""
              )}

              <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <Controller
                    defaultValue={null}
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          errors={errors}
                          data-testid="name"
                          placeholder="Name"
                          required
                          selectStylesOverride={selectStylesOverride}
                          inputClasses={inputClasses}
                          name={name}
                          value={value || ""}
                          onChange={(e, newVal) => {
                            onChange(e);
                            setError("name", { type: "custom", message: "" });
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item={true}
                md={12}
                pl={3}
                pr={3}
                pt={2}
                position="relative"
              >
                <input
                  type="text"
                  placeholder="Search Places"
                  ref={searchInput}
                  data-testid="locations"
                  className="mapInput"
                  style={{
                    background: "transparent",
                    borderColor: isDarkThemeEnabledSelector
                      ? "rgb(81, 81, 81)"
                      : "#c6c6c6",
                  }}
                  id="searchTextField"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: "35px",
                    top: "40%",
                  }}
                >
                  <Google
                    fontSize="15px"
                    sx={{
                      color: isDarkThemeEnabledSelector ? "#fff" : "#696969",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <Controller
                    defaultValue={null}
                    name="latitude"
                    data-testid="latitude"
                    rules={{
                      required: {
                        value: true,
                        message: "Latitude is required",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          errors={errors}
                          placeholder="Latitude"
                          required
                          id="latitude"
                          selectStylesOverride={selectStylesOverride}
                          inputClasses={inputClasses}
                          name={name}
                          type={"number"}
                          value={value || ""}
                          // onChange={onChange}
                          onChange={(e, newVal) => {
                            onChange(e);
                            setError("latitude", {
                              type: "custom",
                              message: "",
                            });
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <Controller
                    defaultValue={null}
                    name="longitude"
                    data-testid="longitude"
                    rules={{
                      required: {
                        value: true,
                        message: "Longitude is required",
                      },
                    }}
                    control={control}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          errors={errors}
                          placeholder="Longitude"
                          required={true}
                          id="longitude"
                          selectStylesOverride={selectStylesOverride}
                          inputClasses={inputClasses}
                          name={name}
                          type={"number"}
                          value={value || ""}
                          onChange={(e, newVal) => {
                            onChange(e);
                            setError("longitude", {
                              type: "custom",
                              message: "",
                            });
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              {geofenceType === "Circular" ||
                geofenceDetails.polygonType === "Circular" ? (
                <Grid item={true} md={12} pl={3} pr={3} pt={2}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                  >
                    <Controller
                      defaultValue={null}
                      name="radius"
                      data-testid="radius"
                      rules={{
                        required: {
                          value: true,
                          message: "Radius is required",
                        },
                        validate: {
                          validRange: (value) => {
                            if (value >= 10 && value <= 500) {
                              return true; // Value is within the valid range
                            } else {
                              return "Radius must be between 10 and 500 meters";
                            }
                          },
                        },
                      }}
                      control={control}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            errors={errors}
                            placeholder="Radius (in meter)"
                            required={true}
                            selectStylesOverride={selectStylesOverride}
                            inputClasses={inputClasses}
                            name={name}
                            value={radii || ""}
                            id="radiusInput"
                            onChange={(e, newVal) => {
                              onChange(e);
                              setRadius(e.target.value);
                            }}
                            type={"number"}
                          // helperText="Radius (10m - 500m)"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : null}

              <Grid
                item={true}
                sm={12}
                xs={12}
                style={{
                  overflowX: "hidden",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
              >
                <DynamicFormRender
                  formHooks={{
                    register,
                    control,
                    errors,
                    handleSubmit,
                    reset,
                    setValue,
                  }}
                  templateId={
                    (templateExistence && templateExistence?._id) || ""
                  }
                  widthProp={12}
                />
              </Grid>
            </Grid>

            <Grid
              item
              sx={{
                position: "absolute",
                width: "100%",
                bottom: "15px",
              }}
            >
              {window.location.pathname == "/dashboard/geomap/create" || window.location.pathname.includes("dashboard/geo/edit") ? null :
                !Object.keys(geoData).length ? (
                  <Grid
                    container
                    item={true}
                    md={12}
                    pl={3}
                    pr={3}
                    pt={0}
                    display={"flex"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      sx={{ color: "rgb(128, 128, 128)" }}
                      control={
                        <Checkbox
                          sx={{
                            color: "#ff7200",
                            "&.Mui-checked": {
                              color: "#ff7200",
                            },
                          }}
                          onClick={() => addMore()}
                          checked={checkState}
                        />
                      }
                    />
                    <Typography
                      data-testid="addMore"
                      sx={{ ...textClasses.normalText }}
                      type="submit"
                    >
                      Add More Geofence
                    </Typography>
                  </Grid>
                ) : null}

              <Grid
                item={true}
                display="flex"
                gap={1}
                mt={0}
                ml={2}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Box sx={{ m: 1, position: "relative" }}>
                  {userPermissions &&
                    havePermission(
                      userPermissions,
                      "geofence",
                      "createGeofence"
                    ) ? (
                    url.includes("create-geo") ? (
                      <Button
                        variant="contained"
                        data-testid="create"
                        sx={{
                          ...buttonClasses?.lynkitOrangeFill,
                          minHeight: "36px",
                          minWidth: "100px",
                          marginRight: 2,
                        }}
                        type="submit"
                        id="clearAndSave"
                      >
                        Create
                      </Button>
                    ) : null
                  ) : null}

                  {userPermissions &&
                    havePermission(
                      userPermissions,
                      "geofence",
                      "createGeofence"
                    ) ? (
                    window.location.pathname == "/dashboard/geomap/create" ? (
                      <Button
                        variant="contained"
                        data-testid="create"
                        sx={{
                          ...buttonClasses?.lynkitOrangeFill,
                          minHeight: "36px",
                          minWidth: "100px",
                          marginRight: 2,
                        }}
                        type="submit"
                        id="clearAndSave"
                      >
                        Create
                      </Button>
                    ) : null
                  ) : null}

                  {/* {userPermissions && 
                      havePermission(userPermissions, "geofence", "editGeofence") && ( */}

                  {userPermissions &&
                    havePermission(
                      userPermissions,
                      "geofence",
                      "editGeofence"
                    ) ? (
                    url.includes("edit-geo") || window.location.pathname.includes("dashboard/geo/edit") ? (
                      <Button
                        variant="contained"
                        sx={{
                          ...buttonClasses?.lynkitOrangeFill,
                          minHeight: "36px",
                          minWidth: "100px",
                          marginRight: 2,
                        }}
                        onClick={updateGeofence}
                      >
                        Update
                      </Button>
                    ) : null
                  ) : null}

                  <Button
                    style={{
                      visibility:
                        circleCrordinates.length || polygonCoordinates.length
                          ? "unset"
                          : "hidden",
                    }}
                    id="clearMap"
                    variant="outlined"
                    size="small"
                    data-testid="clear"
                    onClick={clearVals}
                    sx={{
                      ...buttonClasses.outlined,
                      color: themeType.themeOrange,
                      minWidth: "100px",
                      minHeight: "36px",
                      color: "#ff7700",
                    }}
                  >
                    Clear Map
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        </Grid>
      </form>
    </Box>
  );
};

export default GeofenceDrawer;
