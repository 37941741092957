/* eslint-disable */

import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  Button,
  Breadcrumbs
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import { GoogleMapsAPIKey } from "../../../../services/constants";
import { useEffect, useMemo, useState } from "react";
import { parse, addMinutes, setMinutes, setHours } from "date-fns";
import _ from "lodash";
import SourceImg from "../../../../static/images/routes/source_icon.svg";
import DestinationImg from "../../../../static/images/routes/destination_icon.svg";
import ViaPointImg from "../../../../static/images/routes/viapoint_icon.svg";
//import RouteForm from "./RouteForm";
import {
  getAllGeofences,
  createRoute,
  getAndSaveTotalDistanceRoute,
  getAllOrganizations,
  getAllUsers,
  getRouteDetails,
  getRouteSchedculeData,
  updateRouteDetails,
  clearRouteData,
  checkTemplateExistance,
  flushGeofenceList,
} from "../../../../redux/actions";
import { flushRoutesData } from "../../../../redux/reducers/Route";
import { useCallback } from "react";
import { useRef } from "react";
import { showToast } from "../../../../services/functions";
import { useForm, useWatch } from "react-hook-form";
import RouteForm from "./RouteForm";
import { Link, useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";
import axios, { geoAxios } from "../../../../services/Api";
import { useLocation } from "react-router";

const dayOptions = [
  { value: "sameDay", label: "Same Day" },
  { value: "nextDay", label: "Next Day" },
  { value: "custom", label: "Custom" },
];

const batterPercentageOptions = [
  { value: "20", label: "20%" },
  { value: "30", label: "30%" },
  { value: "40", label: "40%" },
  { value: "50", label: "50%" },
  { value: "60", label: "60%" },
  { value: "70", label: "70%" },
  { value: "80", label: "80%" },
  { value: "90", label: "90%" },
];

const AddRoute = () => {
  const dispatch = useDispatch();
  const templateExistence = useSelector(state => state.templates.templateExistence);
  const history = useHistory();
  const location = useLocation();
  // const { id } = useParams();
  // const isEditRoute = !!id;

  const routesData = useSelector((state) => state.route.scheduleData);
  const selectedRouteSchedules = useSelector(
    (state) => state.route.selectedRouteSchedules
  );
  const Users = useSelector((state) => state.userlist.allUsers);
  const routeDetails = useSelector((state) => state.route.routeDetails);
  const [viaPointsCoordinates, setViaPointCoordinates] = useState([]);
  const [searchObject, setSearchObject] = useState({ source: "", destination: "", organisation: "", user: "" })
  const geofenceList = useSelector(
    (state) => state.geofencelist.geofenceListing
  );
  const [sourceGeofenceList, setSourceGeofenceList] = useState([])
  const [destinationGeofenceList, setDestinationGeofenceList] = useState([]);
  const [organisationList, setOrganisationList] = useState([])
  const [userList, setUserList] = useState([])
  // const [mapLoaded, setMapLoaded] = useState(false);
  const [viaPointsSearchObject, setViaPointsSearchObject] = useState([]);
  const [viaPointsDropdownGeofenceList, setViaPointsDropdownGeofenceList] = useState([])

  const organizations = useSelector((state) => state.userlist.allOrganizations);
  const currentUserDetailsObj = useSelector((state) => state.auth.currentUserDetail);
  const superAdmin = useSelector(
    (state) => state.auth.currentUserDetail
  )?.adminSpecificPermissions;
  const isSuperAdmin = !!superAdmin;

  const userDetailsRes = useSelector(
    (state) => state.userlist.userDetail
  )

  const id = useMemo(() => {
    if(location?.state?.data && location?.state?.data?.id){
      return location?.state?.data?.id;
    }else{
      return null;
    }
  }, [location]);
  const isEditRoute = !!id;

  useEffect(() => {
    if (geofenceList) {
      // setSourceGeofenceList(geofenceList)
      // setDestinationGeofenceList(geofenceList)
      setOrganisationList(organizations)
      setUserList(Users)
    }
  }, [geofenceList, setSourceGeofenceList, setDestinationGeofenceList, organizations, Users, setUserList, setOrganisationList])


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GoogleMapsAPIKey,
  });
  const center = { lat: 28.7, lng: 77.1 };
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const [user, setUser] = useState({});
  const [addMore, setAddMore] = useState(false);
  const [noGeofenceMessage, setNoGeofenceMessage] = useState("");

  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;
  const [mapLoaded, setMapLoaded] = useState(false);
  const {
    control,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    handleSubmit,
    reset,
    register,
    setError,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const selectedOrganisationName = useWatch({
    control,
    name: "organisation",
  });

  const distanceVal = useWatch({
    control,
    name: "distance",
  });

  const selectedOrganisation = organizations?.find(
    (organisation) => organisation?.name === selectedOrganisationName
  );

  const [viaPoints, setViaPoints] = useState([]);
  const [routeSource, setRouteSource] = useState({});
  const [routeDestination, setRouteDestination] = useState({});
  const [schedules, setSchedules] = useState([]);

  const [centerCoordinates, setCenterCoordinates] = useState({
    lat: "",
    lng: "",
  });
  const [myMap, setMymap] = useState(/** @type google.maps.Map */(null));
  const sourceRef = useRef();
  const destinationRef = useRef();
  const markers = [];

  const [sourceObject, setSourceObject] = useState();
  const [destinationObject, setDestinationObject] = useState();

  const handleFlush = () => {
    dispatch(flushRoutesData());
  };
 
  const [directions, setDirections] = useState();

  function convertHHMMToDate(hhmm = "") {
    // const [hours, minutes] = hhmm.split(":").map(Number);
    // const now = new Date();
    // const resultDate = setMinutes(setHours(now, hours), minutes);
    // return resultDate;
    if (typeof hhmm !== 'string') {
      // Handle the case where hhmm is not a string, maybe return null or throw an error
      return null;
    }
    const [hours, minutes] = hhmm?.split(":").map(Number);
    const now = new Date();
    const resultDate = new Date(setMinutes(setHours(now, hours), minutes));
    return resultDate;
  }

  const apiCallFunction = (sourceSelected, destinationSelected, payloads) => {
    if (payloads) {
      payloads.forEach((payload) => {
        if (payload?.sourceObj?._id && payload?.destinationObj?._id) {
          dispatch(
            getAndSaveTotalDistanceRoute(payload, (error) => {
              if (error) {
                const sourceSelected = geofenceList?.find(
                  (geofence) => geofence._id === payload?.sourceObj?._id
                );
                const destinationSelected = geofenceList?.find(
                  (geofence) => geofence._id === payload?.destinationObj?._id
                );
                googleDirectionsApi(
                  sourceSelected,
                  destinationSelected,
                  payload
                );
              }
            })
          );
        }
      });
    }
    else {
      if (sourceSelected && destinationSelected) {

        const payloadToGetDist = {
          sourceObj: {
            _id: sourceSelected?._id,
            srcName: sourceSelected?.name,
            srcLATLNG: sourceSelected && sourceSelected?.centerCoordinates && sourceSelected?.centerCoordinates[0] && sourceSelected?.centerCoordinates[0][0]?.join(","),
          },
          destinationObj: {
            _id: destinationSelected?._id,
            desName: destinationSelected?.name,
            desLATLNG: destinationSelected && destinationSelected?.centerCoordinates && destinationSelected?.centerCoordinates[0] && destinationSelected?.centerCoordinates[0][0] ? destinationSelected?.centerCoordinates[0][0]?.join(",") : '',
          },
          userId: userDetailsRes?._id,
        };
        dispatch(getAndSaveTotalDistanceRoute(payloadToGetDist, (status, message) => {
          if (status.success) {
            let distance = status.data.distance || ''
            setValue("distance", distance)
          }
          else {
            googleDirectionsApi(sourceSelected, destinationSelected, payloadToGetDist);
          }
        }));
      }
    }
  };

  const googleDirectionsApi = (source, destination, payload) => {
    const directionsService = new google.maps.DirectionsService();

    // Function to rearrange coordinates if the second one is smaller
    const rearrangeIfSmaller = (coords) => {
      const absLat = Math.abs(parseFloat(coords.lat));
      const absLng = Math.abs(parseFloat(coords.lng));
      if (absLat > absLng) {
        return { lat: coords.lng, lng: coords.lat };
      }
      return coords;
    };
    
    const originCoords = {
      lat: source?.centerCoordinates && source?.centerCoordinates[0][0][1],
      lng: source?.centerCoordinates && source?.centerCoordinates[0][0][0],
    };
    const destinationCoords = {
      lat: destination?.centerCoordinates && destination?.centerCoordinates[0][0][1],
      lng: destination?.centerCoordinates && destination?.centerCoordinates[0][0][0],
    };


    directionsService.route(
      {
        origin: originCoords,
        destination: destinationCoords,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      },
      (result, status) => {
        if (status === "OK") {
          const distance = result.routes[0].legs[0].distance?.text;
          payload["distance"] = distance.match(/\d+(\.\d+)?/)[0];
          setValue("distance", distance)
          dispatch(getAndSaveTotalDistanceRoute(payload, (status) => {

          }));
        } else {
          showToast("Please select geofence with valid latitude longitude", true);
        }
      }
    );
  };

  const handleCancel = useCallback(() => {
    sendCustomEventAnalytics("routes", "cancel-route", "Cancel Route");
    reset();
    history.push("/dashboard/routes");
  }, []);

  // Making custom markers for source, destination and viaPoints
  var icons = myMap && {
    start: new google.maps.MarkerImage(
      // URL
      SourceImg,
      // (width,height)
      new google.maps.Size(44, 32),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32)
    ),
    end: new google.maps.MarkerImage(
      // URL
      DestinationImg,
      // (width,height)
      new google.maps.Size(44, 32),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32)
    ),
    via: new google.maps.MarkerImage(
      // URL
      ViaPointImg,
      // (width,height)
      new google.maps.Size(44, 32),
      // The origin point (x,y)
      new google.maps.Point(0, 0),
      // The anchor point (x,y)
      new google.maps.Point(22, 32)
    ),
  };

  function makeMarker(position, icon, title) {
    const marker = new google.maps.Marker({
      position: position,
      map: myMap,
      icon: icon,
      title: ''
    });
    markers.push(marker)

  }

  const getDirectionsData = () => {
    const sourceSelected = getValues("source");
    const destinationSelected = getValues("destination");
    setTimeout(() => {
      if (viaPoints.length === 0 && sourceSelected && destinationSelected) {
        apiCallFunction(sourceSelected, destinationSelected);
      }
      else if (viaPoints.length === 0 && ((!sourceSelected && destinationSelected) || (sourceSelected && !destinationSelected))) {
        handleFlush()
        return;
      }
      else {
        let payloadsForAPI;
        let payloadsToSend = [];
        if (!sourceSelected && !destinationSelected) {
          payloadsForAPI = viaPoints.slice(0, -1)?.map((viaPoint, index) => {
            let payload = {};
            const source = geofenceList?.find(
              (data) => data._id === viaPoint?._id
            );
            payload["sourceObj"] = {
              _id: source?._id,
              srcName: source?.name,
              srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : '',
            };


            const destinationViaPoint = viaPoints[index + 1];
            const destination = geofenceList?.find(
              (data) => data._id === destinationViaPoint?._id
            );
            payload["destinationObj"] = {
              _id: destination?._id,
              desName: destination?.name,
              // desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',


            };
            payload["userId"] = userDetailsRes?._id;
            return payload
          })
          payloadsToSend = payloadsForAPI;

        }
        else if (sourceSelected && !destinationSelected) {
          payloadsForAPI = viaPoints?.map((viaPoint, index) => {
            let payload = {};
            const destination = geofenceList?.find(
              (data) => data._id === viaPoint?._id
            );
            if (index === 0) {
              const source = sourceSelected;
              payload["sourceObj"] = {
                _id: source?._id,
                srcName: source?.name,
                srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : '',
              };

            }
            else {
              const prevViaPoint = viaPoints[index - 1];
              const source = geofenceList?.find(
                (data) => data._id === prevViaPoint?._id
              );
              payload["sourceObj"] = {
                _id: source?._id,
                srcName: source?.name,
                srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : '',
              };
            }
            payload["destinationObj"] = {
              _id: destination?._id,
              desName: destination?.name,
              // desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
            };
            payload["userId"] = userDetailsRes?._id
            return payload;
          });
          payloadsToSend = payloadsForAPI
        }
        else if (!sourceSelected && destinationSelected) {
          payloadsForAPI = viaPoints?.map((viaPoint, index) => {
            let payload = {};
            const source = geofenceList?.find(
              (data) => data._id === viaPoint?._id
            );
            payload["sourceObj"] = {
              _id: source?._id,
              srcName: source?.name,
              srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : ''
            };

            if (index !== viaPoints?.length - 1) {
              const destinationViaPoint = viaPoints[index + 1];
              const destination = geofenceList?.find(
                ({ _id }) => _id === destinationViaPoint?._id
              );
              payload["destinationObj"] = {
                _id: destination?._id,
                desName: destination?.name,
                // desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
              };
            } else {
              payload["destinationObj"] = {
                _id: destinationSelected?._id,
                desName: destinationSelected?.name,
                // desLATLNG: destinationSelected && destinationSelected?.centerCoordinates && destinationSelected?.centerCoordinates[0] && destinationSelected?.centerCoordinates[0][0] ? destinationSelected?.centerCoordinates[0][0]?.join(",") : '',

              };
            }
            payload["userId"] = userDetailsRes?._id
            return payload;
          });
          payloadsToSend = payloadsForAPI;
        }
        else if (sourceSelected && destinationSelected) {
          const payloadsForAPI = viaPoints?.map((viaPoint, index) => {
            let payload = {};
            const destination = geofenceList?.find(
              (data) => data._id === viaPoint?._id
            );
            if (index === 0) {
              const source = sourceSelected;
              payload["sourceObj"] = {
                _id: source?._id,
                srcName: source?.name,
                srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : '',
              };
            } else {
              const prevViaPoint = viaPoints[index - 1];
              const source = geofenceList?.find(
                (data) => data._id === prevViaPoint?._id
              );
              payload["sourceObj"] = {
                _id: source?._id,
                srcName: source?.name,
                srcLATLNG: source && source?.centerCoordinates && source?.centerCoordinates[0] && source?.centerCoordinates[0][0] ? source?.centerCoordinates[0][0]?.join(",") : '',
              };
            }

            payload["destinationObj"] = {
              _id: destination?._id,
              desName: destination?.name,
              // desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',

            };
            payload["userId"] = userDetailsRes?._id
            return payload;
          });

          const lastViaPointAsSource = viaPoints[viaPoints.length - 1];
          const lastSource = geofenceList?.find(
            (data) => data._id === lastViaPointAsSource?._id
          );
          const lastDestination = destinationSelected;


          const lastPayload = {
            sourceObj: {
              _id: lastSource?._id,
              srcName: lastSource?.name,
              srcLATLNG: lastSource?.centerCoordinates && lastSource?.centerCoordinates[0][0]?.join(","),
            },
            destinationObj: {
              _id: lastDestination?._id,
              desName: lastDestination?.name,
              // desLATLNG: lastDestination?.centerCoordinates && lastDestination?.centerCoordinates[0][0]?.join(","),
            },
            userId: userDetailsRes?._id,
          };
          payloadsToSend = [...payloadsForAPI, lastPayload];
        }
        const routesDataToClear = payloadsToSend?.filter((payload) => !payload?.sourceObj?._id || !payload?.destinationObj?._id)
        dispatch(clearRouteData(routesDataToClear))
        apiCallFunction(null, null, payloadsToSend);
      }
    }, 20)
  };

  const dateForArrival = (data, previousDate) => {
    // console.log('validation-pre-Arr', data.halt, previousDate)
    if (previousDate && Object.prototype.toString.call(previousDate) === '[object Date]' && !isNaN(previousDate.getTime())) {
      if (data.dayArrival === "sameDay") {
        const originalDate = new Date(previousDate);
        const [hours, minutes] = data.STA.split(':').map(Number);
        const newDate = new Date(originalDate);
        // console.log('1st-hour-min', hours, minutes)
        newDate.setHours(hours, minutes, 0, 0);
        return newDate;
      } else if (data.dayArrival === "nextDay") {
        const originalDate = new Date(previousDate);
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + 1);
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      } else {
        const originalDate = new Date(previousDate);
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      }

    } else {
      if (data.dayArrival === "sameDay") {
        const originalDate = new Date();
        const [hours, minutes] = data.STA.split(':').map(Number);
        const newDate = new Date(originalDate);
        // console.log('1st-hour-min', hours, minutes)
        newDate.setHours(hours, minutes, 0, 0);
        return newDate;
      } else if (data.dayArrival === "nextDay") {
        const originalDate = new Date();
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + 1);
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      } else {
        const originalDate = new Date();
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
        const [hours, minutes] = data.STA.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      }

    }

  }

  const dateForDeparture = (data, previousDate) => {
    // console.log('validation-pre-Dep', previousDate)
    if (previousDate && Object.prototype.toString.call(previousDate) === '[object Date]' && !isNaN(previousDate.getTime())) {
      if (data.dayDeparture === "sameDay") {
        const originalDate = new Date(previousDate);
        const [hours, minutes] = data.STD.split(':').map(Number);
        const newDate = new Date(originalDate);
        // console.log('1st-hour-min', hours, minutes)
        newDate.setHours(hours, minutes, 0, 0);
        return newDate;
      } else if (data.dayDeparture === "nextDay") {
        const originalDate = new Date(previousDate);
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + 1);
        const [hours, minutes] = data.STD.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      } else {
        const originalDate = new Date(previousDate);
        const nextDate = new Date(originalDate);
        nextDate.setDate(originalDate.getDate() + Number(data.dayDeparture - 1));
        const [hours, minutes] = data.STD.split(':').map(Number);
        nextDate.setHours(hours, minutes, 0, 0);
        return nextDate;
      }

    } else {
      if (data.dayDeparture === "sameDay") {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const [hours, minutes] = data.STD.split(':').map(Number);
          // console.log('2st-hour-min', hours, minutes)
          const newDate = new Date(originalDate);
          newDate.setHours(hours, minutes, 0, 0);
          return newDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }
      } else if (data.dayDeparture === "nextDay") {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 2);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + 1);
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }
      } else {
        if (data.dayArrival === "sameDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else if (data.dayArrival === "nextDay") {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + 2 + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        } else {
          const originalDate = new Date();
          const nextDate = new Date(originalDate);
          nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + Number(data.dayDeparture - 1));
          const [hours, minutes] = data.STD.split(':').map(Number);
          nextDate.setHours(hours, minutes, 0, 0);
          return nextDate;
        }

      }

    }
  }

  const validatePayload = (formData, stateData) => {
    const finalData = [];
    stateData.length > 0 && stateData.forEach(item => {
      const newItem = { ...item };
      if (newItem.scheduleVia) {
        newItem.scheduleVia = newItem.scheduleVia.map(via => ({ ...via }));
      }
      finalData.push(newItem);
    });
    let success = false;
    if (finalData.length > 0) {
      stateData.length > 0 && stateData.forEach((ele, index) => {
        ele["scheduleVia"]?.length > 0 && ele["scheduleVia"].forEach((innerele, innerIndex) => {
          let first;
          let second;
          let third;
          if (innerIndex === 0) {
            first = dateForArrival(innerele, "").getTime() - formData[`scheduleDeparture${index}`].getTime();
            second = dateForDeparture(innerele, "").getTime() - dateForArrival(innerele, "").getTime();
            finalData[index].STD = formData[`scheduleDeparture${index}`];
            finalData[index]["scheduleVia"][innerIndex].STA = dateForArrival(innerele, "");
            finalData[index]["scheduleVia"][innerIndex].STD = dateForDeparture(innerele, "");
          } else {
            first = dateForArrival(innerele, finalData[index]["scheduleVia"][innerIndex - 1].STD).getTime() - (finalData[index]["scheduleVia"][innerIndex - 1].STD).getTime();
            finalData[index]["scheduleVia"][innerIndex].STA = dateForArrival(innerele, finalData[index]["scheduleVia"][innerIndex - 1].STD);
            second = dateForDeparture(innerele, finalData[index]["scheduleVia"][innerIndex].STA).getTime() - finalData[index]["scheduleVia"][innerIndex].STA.getTime();
            finalData[index]["scheduleVia"][innerIndex].STD = dateForDeparture(innerele, finalData[index]["scheduleVia"][innerIndex].STA);
          }
          if(innerIndex === (ele["scheduleVia"]?.length - 1)){
            third = dateForArrival(ele, finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD).getTime() - (finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD).getTime();
            finalData[index].STA = dateForArrival(ele, finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD);
          }
          const firstDiffHours = first / (1000 * 60 * 60);
          const secondDiffHours = second / (1000 * 60 * 60);
          const thirdDiffHours = third / (1000 * 60 * 60);
          console.log('validation', firstDiffHours, secondDiffHours, thirdDiffHours);
          if (firstDiffHours <= 0) {
            setError(`scheduleViaPointArrivalTime${index}_${innerIndex}`, {
              type: "manual",
              message: "Time can't less than Departure Time",
            });
            success = true;
          }
          if (secondDiffHours <= 0) {
            setError(`scheduleViaPointDepartureTime${index}_${innerIndex}`, {
              type: "manual",
              message: "Time can't less than Arrival Time",
            });
            success = true;
          }
          if (thirdDiffHours <= 0) {
            setError(`scheduleArrivalTime${index}`, {
              type: "manual",
              message: "Time can't less than Departure Time",
            });
            success = true;
          }
        })
      })
    }

    console.log('validation-schedules', finalData)
    return success

  }

  const onSubmit = useCallback(
    (data) => {
      console.log('submit1', data);
      const validation = validatePayload(data, schedules)
      if (validation) {
        showToast("Some value are invalid", true);
        return;
      }
      const finalViaPoints = viaPoints?.map((viaPoint) => {
        if (viaPoint?.key) {
          const { key, ...newItem } = viaPoint;
          return newItem;
        }
        return viaPoint;
      });

      schedules.forEach((schedule) => {
        delete schedule.key
      })
      const payload = {
        routeSource: routeSource,
        routeDestination: routeDestination,
        viaPoints: finalViaPoints,
        routeName: data?.name,
        description: data?.description,
        batteryPercentage: data?.battery?.value,
        scheduleData: schedules,
        templateId: templateExistence && templateExistence?._id || '',
      };

      if (isSuperAdmin) {
        payload["user"] = user;
      }

      handleSubmit((data) => {
        // console.log('submit2', data);
        const dynamicData = Object.keys(data).reduce((acc, key) => {
          if (key.startsWith("dynamic")) {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        let finalPayload = { ...payload, dynamicData }
        sendCustomEventAnalytics("routes", "create-route", "Create Route");
        dispatch(
          createRoute(finalPayload, () => {
            reset();
            setSchedules([])
            setViaPoints([])
            if (!addMore) {
              history.push("/dashboard/routes");
            }
          })
        );

      }, (errors) => {
        let errorField = Object.keys(errors)[0];
        showToast(`${errors[errorField].message}`, true);
      })();
    },
    [
      routeDestination,
      routeSource,
      dispatch,
      isSuperAdmin,
      user,
      schedules,
      viaPoints,
      addMore,
      reset,
    ]
  );

  const handleEdit = useCallback(
    (data) => {
      const validation = validatePayload(data, schedules)
      if (validation) {
        showToast("Some value are invalid", true);
        return;
      }
      const finalViaPoints = viaPoints?.map((viaPoint) => {
        console.log("inside viaPoints map:", viaPoint);
        if (viaPoint?.key) {
          const { key, etaTime, ...newItem } = viaPoint;
          const updated = {
            ...newItem,
            etaTime: etaTime ?? "00:00"
          }
          return updated;
        }
        return viaPoint;
      });

      let updatedRouteDestination = routeDestination;
      if(routeDestination && routeDestination?.speed == "") delete updatedRouteDestination["speed"];
      if(routeDestination && !routeDestination?.time) delete updatedRouteDestination["time"];
      if(routeDestination && routeDestination?.totalDistance == "") delete updatedRouteDestination["totalDistance"];

      const payload = {
        id: id,
        templateId: templateExistence && templateExistence?._id || '',
        routeSource: routeSource,
        routeDestination: updatedRouteDestination,
        viaPoints: finalViaPoints,
        scheduleData: schedules,
      };

      if (!!dirtyFields?.name) {
        payload["routeName"] = data?.name;
      }
      if (!!dirtyFields?.description) {
        payload["description"] = data?.description;
      }
      if (!!dirtyFields?.battery) {
        payload["batteryPercentage"] = data?.battery?.value;
      }
      if (!!dirtyFields?.organisation || !!dirtyFields?.user) {
        if (isSuperAdmin) {
          payload["user"] = user;
        }
      }

      handleSubmit((data) => {
        const dynamicData = Object.keys(data).reduce((acc, key) => {
          if (key.startsWith("dynamic")) {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        let finalPayload = { ...payload, dynamicData }
        sendCustomEventAnalytics("routes", "edit-route", "Edit Route");
        dispatch(
          updateRouteDetails(finalPayload, () => {
            reset();
            setSchedules([])
            setViaPoints([])
            history.push("/dashboard/routes");
          })
        );

      }, (errors) => {
        let errorField = Object.keys(errors)[0];
        showToast(`${errors[errorField].message}`, true);
      })();


    },
    [viaPoints, routeSource, routeDestination, isSuperAdmin, schedules]
  );

  const handleAddMore = useCallback(() => {
    setAddMore((prev) => !prev);
  }, [setAddMore]);

  const handleSearchGeofence = useCallback((type, index, reset) => {
    let payload;
    if (type === "source" && !reset) {
      payload = {
        searchValue: searchObject?.source,
      }
    } else if (type === "destination" && !reset) {
      payload = {
        searchValue: searchObject?.destination,
      }
    }
    else if (type === "viaPoint" && index !== undefined && !reset) {
      payload = {
        searchValue: viaPointsSearchObject?.find((viaPoint) => viaPoint?.index === index)?.searchValue
      }
    }
    if(isSuperAdmin && user?.orgId &&  user?.id){
      payload.filter = {orgId: user?.orgId};
      payload.user = {userId: user?.id};
    }

    geoAxios.post("/listGeofence", payload && {...payload, selection:['name', 'centerCoordinates']}).then(({ data }) => {
      if (data.success) {
        if (type === "source") {
          setSourceGeofenceList(data.data)

        } else if (type === "destination") {
          setDestinationGeofenceList(data.data)
        } else if (type === "viaPoint" && index !== undefined) {
          const existingDropdownList = viaPointsDropdownGeofenceList?.find((viaPointList) => {
            return viaPointList?.index === index
          })
          if (existingDropdownList) {
            const updatedViaPointsDropdowns = viaPointsDropdownGeofenceList?.map((viaPointList) => {
              if (viaPointList?.index === index) {
                return { ...viaPointList, list: data?.data }
              } else return viaPointList
            })
            setViaPointsDropdownGeofenceList(updatedViaPointsDropdowns)
          } else {
            const viaPointDropdownList = {
              index: index,
              list: data?.data,
            }
            setViaPointsDropdownGeofenceList(prev => ([...prev, viaPointDropdownList]))
          }
        }
      }
    })


  }, [searchObject, viaPointsSearchObject, viaPointsDropdownGeofenceList, setSourceGeofenceList, setViaPointsDropdownGeofenceList, setDestinationGeofenceList])

  const handleOrganisationSearch = useCallback((reset) => {
    const payload = {
      searchValue: searchObject?.organisation,
    }

    if (isSuperAdmin) {
      dispatch(getAllOrganizations(!reset && payload, (data) => {
        setOrganisationList(data)
      }));
    }


  }, [searchObject, dispatch, isSuperAdmin, setOrganisationList])

  const handleUserSearch = useCallback((reset) => {
    const payload = {
      searchValue: searchObject?.user,
    }

    if (isSuperAdmin) {
      dispatch(getAllUsers(!reset && payload, (data) => {
        setUserList(data)
      }));
    }
  }, [searchObject, dispatch, isSuperAdmin, setUserList])

  const successCallback = (position) => {
    const currentLocationCoords = { lat: position?.coords?.latitude, lng: position?.coords?.longitude }
    setCenterCoordinates(currentLocationCoords ?? center)


  };

  const errorCallback = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation?.getCurrentPosition(successCallback, errorCallback);
    }
  }, [])
  
  useEffect(() => {
    let payload = { "category": "route" }
    dispatch(checkTemplateExistance(payload))
  }, [])

  useEffect(() => {
    reset();
    dispatch(flushGeofenceList());
    // return () => {
    // }
  }, []);

  useEffect(() => {
    if (isEditRoute) {
      dispatch(getRouteDetails({ id: id }), () => { });
    }
  }, [dispatch, isEditRoute]);

  useEffect(() => {
    if (!sourceObject) {
      setCenterCoordinates(center)
    } else if (sourceObject?.centerCoordinates) {
      const coords = { lat: sourceObject?.centerCoordinates[0][0][1], lng: sourceObject?.centerCoordinates[0][0][0] };
      setCenterCoordinates(coords)
    }
  }, [setCenterCoordinates, sourceObject])

  useEffect(() => {
    const directionsService = myMap && new google.maps.DirectionsService();
    if (!sourceObject || !destinationObject) {
      setDirections(null);
    }

    const rearrangeIfSmaller = (coords) => {
      const absLat = Math.abs(parseFloat(coords.lat));
      const absLng = Math.abs(parseFloat(coords.lng));
      if (absLat > absLng) {
        return { lat: coords.lng, lng: coords.lat };
      }
      return coords;
    };

    const origin = {
      lat: sourceObject?.centerCoordinates && sourceObject?.centerCoordinates[0][0][1],
      lng: sourceObject?.centerCoordinates && sourceObject?.centerCoordinates[0][0][0],
    };
    const destination = {
      lat: destinationObject?.centerCoordinates && destinationObject?.centerCoordinates[0][0][1],
      lng: destinationObject?.centerCoordinates && destinationObject?.centerCoordinates[0][0][0],
    };

    if (origin.lat && origin.lng && destination.lat && destination.lng) {
      directionsService && directionsService.route(
        {
          origin: origin,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          waypoints: viaPointsCoordinates || [],
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            result.routes[0].legs.forEach((leg, index) => {
              if (index === 0) {
                makeMarker(leg.start_location, icons.start, "title")
              }
              if (index === result.routes[0].legs.length - 1) {
                makeMarker(leg.end_location, icons.end, 'title')
              } else {
                makeMarker(leg.end_location, icons.via, 'title')
              }
            })
          } else {
            console.log("Directions API Error--", status)
          }
        }
      );
    }

    return () => {
      markers?.forEach((marker) => {
        marker.setMap(null)
      })
    }
  }, [setDirections, myMap, sourceObject, destinationObject, viaPointsCoordinates]);

  useEffect(() => {
    if (viaPoints?.length > 0) {
      const viaPointsCoords = viaPoints?.map((viaPoint) => {
        const geofenceCoordinates = geofenceList?.find(
          (geofence) => geofence._id === viaPoint?._id
        )?.centerCoordinates;
        const coordinates = geofenceCoordinates && {
          location: {
            lat: geofenceCoordinates[0][0][1],
            lng: geofenceCoordinates[0][0][0],
          },
        };

        return coordinates;
      });
      setViaPointCoordinates(
        viaPointsCoords?.filter((coordinate) => coordinate !== undefined)
      );
    }
  }, [viaPoints, geofenceList, setViaPointCoordinates]);
  
  useEffect(() => {
    if (selectedRouteSchedules && isEditRoute) {
      const newSchedules = selectedRouteSchedules?.map((schedule, index) => {
        const sendingSchedule = {
          key: index,
          schedule: schedule?.schedule,
          STD: schedule?.routeSource?.STD,
          dayArrival: schedule?.routeDestination?.dayArrival,
          STA: schedule?.routeDestination?.STA,
        };
        setValue(`schedule${index}`, schedule?.schedule);
        setValue(
          `scheduleArrivalDay${index}`,
          dayOptions?.find(
            (option) => option?.value === schedule?.routeDestination?.dayArrival
          )
        );
        setValue(
          `scheduleDeparture${index}`,
          convertHHMMToDate(schedule?.routeSource?.STD)
        );
        setValue(
          `scheduleArrivalTime${index}`,
          convertHHMMToDate(schedule?.routeDestination?.STA)
        );
        const updatedViaPoints = schedule?.viaPoints?.map(
          (viaPoint, viaPointIndex) => {
            setValue(
              `scheduleViaPointArrivalTime${index}_${viaPointIndex}`,
              convertHHMMToDate(viaPoint?.STA)
            );
            setValue(
              `scheduleViaPointDepartureTime${index}_${viaPointIndex}`,
              convertHHMMToDate(viaPoint?.STD)
            );
            setValue(
              `scheduleViaPointArrivalDay${index}_${viaPointIndex}`,
              dayOptions?.find(
                (option) => option?.value === viaPoint?.dayArrival
              )
            );
            setValue(
              `scheduleViaPointDepartureDay${index}_${viaPointIndex}`,
              dayOptions?.find(
                (option) => option?.value === viaPoint?.dayDeparture
              )
            );

            const checkViaPoint = {
              dayDeparture: viaPoint?.dayDeparture,
              STD: viaPoint?.STD,
              halt: viaPoint?.halt,
              STA: viaPoint?.STA,
              dayArrival: viaPoint?.dayArrival,
            };
            return checkViaPoint;
          }
        );
        sendingSchedule["scheduleVia"] = updatedViaPoints;
        return sendingSchedule;
      });
      setSchedules(newSchedules);
    }
  }, [selectedRouteSchedules, isEditRoute, setSchedules]);

  useEffect(() => {
    if (routeDetails?._id && isEditRoute) {
      dispatch(getRouteSchedculeData({ routeId: routeDetails?._id }), () => { });
      dispatch(getAllGeofences(
        {
          selection: [
            "name",
            "centerCoordinates",
          ],
          user: {
            userId: routeDetails?.user?.id,
            addedBy: routeDetails?.addedBy?.id,
          }
        }, (success, error) => {
          if (success && success.count === 0) {
            setNoGeofenceMessage(success?.message)
          }

        }
      )
      );
    }
  }, [dispatch, routeDetails, isEditRoute]);
  
  useEffect(() => {
    if (routeDetails && isEditRoute) {
      // const modifiedObject = JSON.parse(JSON.stringify(routeDetails));
      const modifiedObject = _.cloneDeep(routeDetails);
      modifiedObject.viaPoints.forEach((point, index) => {
          point.name = point.itemName;
          point.etaTime = routeDetails?.viaPoints[index]?.etaTime;
      });

      setRouteSource(routeDetails?.routeSource);
      setSourceObject(geofenceList?.find((geofence) => geofence?._id === routeDetails?.routeSource?._id))
      setValue("name", routeDetails?.routeName);
      setValue("description", routeDetails?.description);
      setValue(
        "battery",
        batterPercentageOptions?.find(
          (option) => option?.value === routeDetails?.batteryPercentage
        )
      );
      setValue("organisation", routeDetails?.user?.orgName);
      setValue("user", routeDetails?.user?.name);
      setRouteDestination(routeDetails?.routeDestination);
      setDestinationObject(geofenceList?.find((geofence) => geofence?._id === routeDetails?.routeDestination?._id))
      const selectedDestination = geofenceList?.find(
        (geofence) => geofence?._id === routeDetails?.routeDestination?._id
      );
      setValue("destination", selectedDestination);
      const selectedSource = routeDetails?.routeSource
      setValue("source", selectedSource);

      if (routeDetails?.dynamicData) {
        for (const key in routeDetails && routeDetails.dynamicData) {
          setValue(key, routeDetails.dynamicData[key])
        }
      }
      if (modifiedObject?.viaPoints) {
        const ViaPointsWithKey = modifiedObject?.viaPoints?.map((viaPoint, index) => {
          // console.log("ViaPointsWithKey viaPoint: ", viaPoint);
          const etaTimeVP = convertHHMMToDate(viaPoint?.etaTime);
          setValue(`viapoint${index}`, viaPoint);
          setValue(`viapointDistance${index}`, viaPoint?.totalDistance);
          setValue(`viapointTime${index}`, etaTimeVP);
          setValue(`viapointSpeed${index}`, viaPoint?.speed);
          return { ...viaPoint, key: index }
        });
        
        setViaPoints(ViaPointsWithKey)
      }

      if (routeDetails?.routeDestination) {
        setValue("distance", routeDetails?.routeDestination?.totalDistance);
        setValue("speed", routeDetails?.routeDestination?.speed);
        setValue("time", routeDetails?.routeDestination?.etaTime);
        setValue("destination",routeDetails?.routeDestination)
      }
    }
  }, [
    batterPercentageOptions,
    geofenceList,
    isEditRoute,
    routeDetails,
    setRouteSource,
    setSourceObject,
    setDestinationObject,
    setValue,
    setViaPoints,
  ]);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getAllOrganizations({
        selection: [
          "name"
        ],
      }));
    }else{
      dispatch(getAllGeofences({
        selection: [
          "name",
          "centerCoordinates",
        ],
      }));
    }
  }, [dispatch, isSuperAdmin]);

  useEffect(() => {
    if (isSuperAdmin && selectedOrganisation) {
      dispatch(getAllUsers({ orgId: selectedOrganisation?._id, selection: ["name", "addedBy"] }));
    }
  }, [selectedOrganisation, isSuperAdmin, dispatch]);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  useEffect(() => {
    setTimeout(()=>{
      let isMounted = true;

      loadGoogleMapsApi()
        .then((google) => {
          if (isMounted) {
            setMapLoaded(true);
          }
        })
        .catch((error) => {
          console.error("Error loading Google Maps API:", error);
        });
  
      return () => {
        isMounted = false;
      };
    },50)
   
  }, []);

  return (
    <>
      <Grid container>
        {/* page title and action btns */}
        <Grid item sm={12} xs={12}>
          <Grid container>
            <Grid item sm={6} xs={4}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                {!isEditRoute ? "Add Route" : "Edit Route"}
              </Typography>
            </Grid>
            <Grid item={true} sm={6} xs={6} sx={{ displey: 'flex', display: 'flex', justifyContent: 'flex-end', height: '36px' }}  >
              {/* <Button onClick={() => history.goBack()} variant='outlined' size='small' sx={{ ...buttonClasses.small, color: buttonClasses.lynkitBlackFill }}>Back</Button> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Breadcrumbs sx={{ mb: 1 }}>
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{ color: themeType.default.themeOrange, textDecoration: "none" }}
                to="/dashboard/routes"
              >
                Routes
              </Link>
            </Typography>

            <Typography
              sx={{ ...textClasses.normalText, fontSize: "12px" }}
            >
              {!isEditRoute ? "Add" : "Edit"}
            </Typography>
          </Breadcrumbs>
        </Grid>





        <Grid container direction="row" alignItems={"stretch"} mt={0}>
          <Grid item sm={8}>
            <Card
              sx={{
                ...cardClasses.basic,
                borderRadius: "8px",
                position: "relative",
                maxHeight: "77vh",
                minHeight: "77vh",
                overflowY: "auto"
              }}
            >
              <CardContent
                sx={{
                  height: "77vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <Suspense fallback={<div>Loading ...</div>}> */}
                <RouteForm
                  addMore={addMore}
                  control={control}
                  userList={userList}
                  selectStylesOverride={selectStylesOverride(
                    isDarkThemeEnabledSelector
                  )}
                  batterPercentageOptions={batterPercentageOptions}
                  dayOptions={dayOptions}
                  destinationObject={destinationObject}
                  errors={errors}
                  handleAddMore={handleAddMore}
                  handleCancel={handleCancel}
                  handleOrganisationSearch={handleOrganisationSearch}
                  handleSearchGeofence={handleSearchGeofence}
                  isSuperAdmin={isSuperAdmin}
                  noGeofenceMessage={noGeofenceMessage}
                  viaPointsDropdownGeofenceList={viaPointsDropdownGeofenceList}
                  destinationGeofenceList={destinationGeofenceList}
                  formSetValue={setValue}
                  geofenceList={geofenceList}
                  getValues={getValues}
                  handleEdit={handleEdit}
                  handleSubmit={handleSubmit}
                  handleUserSearch={handleUserSearch}
                  isEditRoute={isEditRoute}
                  onSubmit={onSubmit}
                  organisationList={organisationList}
                  routesData={routesData}
                  searchObject={searchObject}
                  setDestinationGeofenceList={setDestinationGeofenceList}
                  setSearchObject={setSearchObject}
                  setViaPointsSearchObject={setViaPointsSearchObject}
                  sourceGeofenceList={sourceGeofenceList}
                  themeType={themeType}
                  viaPoints={viaPoints}
                  viaPointsSearchObject={viaPointsSearchObject}
                  destinationRef={destinationRef}
                  getDirectionsData={getDirectionsData}
                  handleFlush={handleFlush}
                  onChangeViaPoints={setViaPoints}
                  organizations={organizations}
                  routeDestination={routeDestination}
                  routeDetails={routeDetails}
                  routeSource={routeSource}
                  schedules={schedules}
                  setDestinationObject={setDestinationObject}
                  setRouteDestination={setRouteDestination}
                  setRouteSource={setRouteSource}
                  setSchedules={setSchedules}
                  setSourceObject={setSourceObject}
                  setUser={setUser}
                  sourceObject={sourceObject}
                  sourceRef={sourceRef}
                  user={user}
                  Users={Users}
                  templateExistence={templateExistence}
                  register={register}
                  reset={reset}
                  setValue={setValue}
                  setNoGeofenceMessage={setNoGeofenceMessage}
                  userDetailsRes={userDetailsRes}
                />
                {/* </Suspense> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={4}>
            <Card
              sx={{
                ...cardClasses.basic,
                borderRadius: "8px",
                position: "relative",
                height: "77vh"
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  paddingBottom: 0,
                  height: "100%",
                  "&:last-child": {
                    paddingBottom: 0
                  }
                }}>
                {mapLoaded && window.google && window.google.maps.Map ?
                  <GoogleMap
                    center={centerCoordinates}
                    zoom={7}
                    onLoad={(map) => setMymap(map)}
                    mapContainerStyle={{ minHeight: "82vh", height: "100%" }}
                  >

                    {directions && (<DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />)}
                  </GoogleMap>
                  : null}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddRoute;
