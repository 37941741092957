import React from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { Grid, Paper, Typography } from "@mui/material";
import RoutesTable from "./RoutesTable";
import { convertHHMMToDuration } from "../../../../services/functions";

const ViaPointModal = ({ open, hide, selectedEntry, themeType }) => {
  const { tableClasses, textClasses } = themeType;


  const tableHeader = ["#","Name","Distance", "Speed","ETA"];

  const tableRows = selectedEntry?.viaPoints.map((item, index) => {
    let items = [];

    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {index + 1}
      </Typography>
    );
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        {item?.itemName ?? "-"}
      </Typography>
    );
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        {item?.totalDistance ?  item?.totalDistance + ' km' ?? "-" : '-'}
      </Typography>
    );
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        {item?.speed  ? item?.speed + ' kmph' ?? "-" : '-'}
      </Typography>
    );
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        {item?.etaTime ? item?.etaTime ? `${item?.etaTime} hr` : "-" : '-'}
      </Typography>
    );
    return items;
  })



  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={770}
      maxWidth={770}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"80vh"}
      childrenMaxHeight={"80vh"}
      Title={selectedEntry?.routeName}
      viewCloseIcon={open}
    >
      <Grid
        container
        my={3}
      >
        <Paper
      className="muitable"
      sx={{
        height: 'auto',
        overflow: 'auto',
        width: "100%",
        display: "block",
        maxHeight:'30vh'
      }}
    >
      <table style={{ ...tableClasses.table }}>
        <thead style={{ backgroundColor: themeType.default.themeGray, position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            {tableHeader.slice(0, 2).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
            {tableHeader.slice(2, tableHeader.length).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableRows.length > 0 ? (
            <>
              {tableRows.map((row, i) => (
                <tr data-testid="tablerow" key={i}>
                  {row.slice(0, 2).map((col, j) => {
                    return (
                      <td
                        key={j}
                        data-testid="tablecell"
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                  {row.slice(2, row.length).map((col, j) => {
                    return (
                      <td
                        key={j}
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                data-testid="nodata"
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              ></td>
            </tr>
          )}
        </tbody>
      </table>
   
    </Paper>

























      </Grid>
    </CustomModel>
  );
};

export default ViaPointModal;
