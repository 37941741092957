import React from "react";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  History,
  Lock,
  LockOpen,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { CustomInput } from "../../../../components/FormUI";
import EventSummaryTimeline from "./EventSummaryTimeline";
import { dateTimePickerSlotProps } from "../../../../utils/util";
import { havePermission } from "../../../../utils/permissionCheck";
import DownloadIcon from '@mui/icons-material/Download'; 
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
const DeviceMapDrawer = ({
  themeType,
  isDrawerOpen,
  filters,
  isDark,
  setFilters,
  passcodeValue,
  resetPasscodefn,
  deviceDetails,
  setLockType,
  setIsDrawerOpen,
  eventSummaryData,
  setEventSummaryData,
  selectStylesOverride,
  showEventSummary,
  setShowEventSummary,
  handleHistoryTrack,
  handleFastTagData,
  handleTimeBtnClicked,
  handleTrackReport,
  handleLockUnlockDevice,
  handleViewPasscode,
  handleFixedLockPasscode,
  handleMovementReport,
  handleSendCommand,
  currentUserDetail,
  totalDistance,
  socketInstance,
}) => {
  const { inputClasses, buttonClasses, textClasses, tableClasses } = themeType;
  const paddingZero = { paddingTop: "10px !important" };

  return (
    <>
      <Box
        position="relative"
        sx={{
          overflowY: "scroll",
          width: isDrawerOpen ? 450 : 0,
          opacity: isDrawerOpen ? 1 : 0,
          transition: "all 0.6s ease-in-out",
          boxShadow: "-3px 0 5px 0 #D7D7D7",
        }}
      >
        <Box
          mb={1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            width: "100%",
            top: "-1px",
            background: (theme) => theme.palette.background.paper,
            zIndex: 300,
            borderBottom: "1px solid #D9D9D9",
            paddingY: "8.3px"
          }}
        >
          <IconButton
            sx={{ marginRight: "auto" }}
            onClick={() => setIsDrawerOpen(false)}
          >
            <ArrowForwardIos sx={{ ...textClasses.themeColor }} />
          </IconButton>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "700",
              width: "100%",
              textAlign: "center",
              ...textClasses.themeColor,
            }}
          >
            Apply Filter Detail
          </Typography>
        </Box>
        {isDrawerOpen && (
          <Grid container mt={1} spacing={2} px={2}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="6px"
              sx={{ paddingTop: "0px !important" }}
            >
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTimeBtnClicked(6)}
                sx={{ ...buttonClasses.lynkitOrangeFill }}
                disabled={!socketInstance || !socketInstance?.id}
              >
                6 hrs
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTimeBtnClicked(12)}
                sx={{ ...buttonClasses.lynkitOrangeFill }}
                disabled={!socketInstance || !socketInstance?.id}
              >
                12 hrs
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleTimeBtnClicked(24)}
                sx={{ ...buttonClasses.lynkitOrangeFill }}
                disabled={!socketInstance || !socketInstance?.id}
              >
                24 hrs
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={paddingZero}
            >
              <DateTimePicker
                label="From"
                value={filters?.from || new Date()}
                onChange={(date) =>
                  setFilters((prev) => ({ ...prev, from: date }))
                }
                className="customDatePicker"
                sx={{
                  width: "100%",
                  ...selectStylesOverride,
                }}
                slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={paddingZero}
            >
              <DateTimePicker
                label="To"
                value={filters?.to || new Date()}
                onChange={(date) =>
                  setFilters((prev) => ({ ...prev, to: date }))
                }
                className="customDatePicker"
                sx={{
                  width: "100%",
                  ...selectStylesOverride,
                }}
                slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={paddingZero}
            >
              <CustomInput
                value={filters?.overstoppage || ""}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    overstoppage: e?.target?.value,
                  }));
                }}
                placeholder="Over Stoppage (Minutes)"
                extraStyles={{
                  borderRadius: "12px",
                  boxShadow: isDark
                    ? "rgb(40, 40, 40) 0px 1px 4px 0px"
                    : "0px 4px 4px 0px #D8D8D8",
                }}
                selectStylesOverride={selectStylesOverride}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={paddingZero}
            >
              <CustomInput
                value={filters?.overspeed || ""}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    overspeed: e?.target?.value,
                  }));
                }}
                placeholder="Over Speed Limit (KMPH)"
                selectStylesOverride={selectStylesOverride}
                extraStyles={{
                  borderRadius: "12px",
                  boxShadow: isDark
                    ? "rgb(40, 40, 40) 0px 1px 4px 0px"
                    : "0px 4px 4px 0px #D8D8D8",
                }}
              />
            </Grid>
            {totalDistance ? (
              <Grid item xs={12}>
                <Box
                  border="1px solid #D9D9D9"
                  p={1}
                  width="100%"
                  borderRadius="12px"
                  display="flex"
                  alignItems="center"
                >
                  <Typography sx={{ ...textClasses.normalText, color: "gray" }}>
                    Total Distance:
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      color: themeType.default.themeOrange,
                      marginLeft: "7px",
                    }}
                  >
                    {totalDistance ? totalDistance + " KM" : "-"}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
            {/* --HISTORY TRACK SECTION-- */}
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={paddingZero}
            >
              <Button
                variant="contained"
                fullWidth
                sx={{ ...buttonClasses.lynkitOrangeFill }}
                onClick={handleHistoryTrack}
                startIcon={<History />}
                disabled={!socketInstance || !socketInstance?.id}
              >
                History Track
              </Button>
              {/* {havePermission(
                  currentUserDetail,
                  "vehicleMaster",
                  "fasttag_api"
                ) && (
              <Button
                variant="contained"
                fullWidth
                sx={{ ...buttonClasses.lynkitOrangeFill, marginTop:'10px' }}
                onClick={handleFastTagData}
                startIcon={<LocalShippingIcon  />}
              >
                Fast Tag Data
              </Button>
                )} */}
            </Grid>
            <Box border="0.03px solid #D9D9D9" width="100%" mt={1} ml={2} />
            {showEventSummary && (
              <>
                <Grid item xs={12} lg={6} sx={paddingZero}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={handleMovementReport}
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    startIcon={<DownloadIcon />}
                  >
                    Movement
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6} sx={paddingZero}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ ...buttonClasses.lynkitOrangeEmpty }}
                    onClick={handleTrackReport}
                    startIcon={<DownloadIcon />}
                  >
                    Track
                  </Button>
                </Grid>
              </>
            )}

            {/* Lock/Unlock & View passcode - [elock, fixed lock] */}
            {deviceDetails && deviceDetails?.deviceType === "elock" && deviceDetails?.deviceType !== "fixedTracker" && (
              <>
                {(currentUserDetail?.adminSpecificPermissions ||
                  havePermission(
                    currentUserDetail,
                    "unlockingOption",
                    "sendLockCommand"
                  )) && (
                  <Grid item xs={12} lg sx={{ ...paddingZero }}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        ...buttonClasses.lynkitOrangeEmpty,
                        px: "3px !important",
                        "& .MuiButton-startIcon": { marginRight: "4px" },
                      }}
                      startIcon={<Lock sx={{ fontSize: "15px !important" }} />}
                      onClick={() => {
                        setLockType("SET_LOCK");
                        handleLockUnlockDevice("SET_LOCK");
                      }}
                    >
                      Lock
                    </Button>
                  </Grid>
                )}
                {(currentUserDetail?.adminSpecificPermissions ||
                  havePermission(
                    currentUserDetail,
                    "unlockingOption",
                    "sendUnlockCommand"
                  )) && (
                  <Grid
                    item
                    xs={12}
                    lg
                    sx={{ ...paddingZero, paddingLeft: "5px !important" }}
                  >
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        ...buttonClasses.lynkitOrangeEmpty,
                        px: "3px !important",
                        "& .MuiButton-startIcon": { marginRight: "4px" },
                      }}
                      startIcon={
                        <LockOpen sx={{ fontSize: "15px !important" }} />
                      }
                      onClick={() => {
                        setLockType("SET_UNLOCK");
                        handleLockUnlockDevice("SET_UNLOCK");
                      }}
                    >
                      Unlock
                    </Button>
                  </Grid>
                )}
                {(currentUserDetail?.adminSpecificPermissions ||
                  havePermission(
                    currentUserDetail,
                    "unlockingOption",
                    "viewPasscode"
                  )) && (
                  <Grid
                    item
                    xs={12}
                    lg
                    sx={{ ...paddingZero, paddingLeft: "5px !important" }}
                  >
                    {passcodeValue ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        gap={0.5}
                      >
                        <Typography
                          sx={{
                            ...textClasses?.normalText,
                            // color: themeType?.default?.themeOrange,
                            // fontWeight: 600,
                          }}
                        >
                          Passcode:
                        </Typography>
                        <Typography
                          sx={{
                            ...textClasses?.normalText,
                            color: themeType?.default?.themeOrange,
                            fontWeight: 600,
                          }}
                        >
                          {passcodeValue}
                        </Typography>
                        <IconButton onClick={resetPasscodefn}>
                          <Close
                            sx={{ color: themeType?.default?.themeOrange }}
                          />
                        </IconButton>
                        {/* <Button sx={{padding: 0, textDecoration: "underline"}} onClick={resetPasscodefn}>Hide</Button> */}
                      </Box>
                    ) : (
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          ...buttonClasses.lynkitOrangeEmpty,
                          px: "3px !important",
                          "& .MuiButton-startIcon": {
                            marginRight: "4px",
                          },
                        }}
                        onClick={handleViewPasscode}
                        startIcon={
                          <Visibility sx={{ fontSize: "15px !important" }} />
                        }
                      >
                        Passcode
                      </Button>
                    )}
                  </Grid>
                )}
              </>
            )}

            {deviceDetails && deviceDetails?.deviceType === "fixedELock" && deviceDetails?.deviceType !== "fixedTracker" &&
              (currentUserDetail?.adminSpecificPermissions ||
                havePermission(
                  currentUserDetail,
                  "unlockingOption",
                  "viewPasscode"
                )) && (
                <Grid
                  item
                  xs={12}
                  lg
                  sx={{ ...paddingZero, marginLeft: "5px !important" }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      ...buttonClasses.lynkitOrangeEmpty,
                      px: "3px !important",
                      "& .MuiButton-startIcon": { marginRight: "4px" },
                    }}
                    onClick={handleFixedLockPasscode}
                    startIcon={
                      <Visibility sx={{ fontSize: "15px !important" }} />
                    }
                  >
                    Passcode
                  </Button>
                </Grid>
              )}

            {(currentUserDetail?.adminSpecificPermissions ||
              havePermission(currentUserDetail, "device", "sendCommand")) && (
              <Grid item xs={12} sx={{ ...paddingZero ,marginLeft : "5px !important"}}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    ...buttonClasses.lynkitOrangeEmpty,
                  }}
                  onClick={handleSendCommand}
                >
                  Send Command
                </Button>
              </Grid>
            )}
            {deviceDetails && deviceDetails?.deviceType === "fuelSensor" && (
              <>
                <Box border="0.03px solid #D9D9D9" width="100%" mt={1} ml={2} />

                <Grid item xs={12} sx={paddingZero}>
                  <Box
                    border="1px solid #D9D9D9"
                    p={1}
                    width="100%"
                    borderRadius="12px"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      sx={{ ...textClasses.normalText, color: "gray" }}
                    >
                      Average Mileage:
                    </Typography>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: themeType.default.themeOrange,
                        marginLeft: "7px",
                      }}
                    >
                      1 kmpl
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={paddingZero}>
                  <Box
                    border="1px solid #D9D9D9"
                    p={1}
                    width="100%"
                    borderRadius="12px"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography
                      sx={{ ...textClasses.normalText, color: "gray" }}
                    >
                      Refuelling Count:
                    </Typography>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: themeType.default.themeOrange,
                        marginLeft: "7px",
                      }}
                    >
                      0
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            {/* Event summary ------ */}
            <Grid item xs={12} sx={paddingZero}>
              {showEventSummary && eventSummaryData && eventSummaryData?.length > 0 && (
                <Box
                  borderRadius="12px"
                  border="1px solid #D9D9D9"
                  width="100%"
                >
                  <Box
                    p={1}
                    textAlign="center"
                    borderBottom="1px solid #D9D9D9"
                  >
                    <Typography sx={{ ...textClasses.boldText, color: "gray" }}>
                      Event Summary
                    </Typography>
                  </Box>
                  <EventSummaryTimeline
                    events={eventSummaryData}
                    themeType={themeType}
                    setEventSummaryData={setEventSummaryData}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default DeviceMapDrawer;
