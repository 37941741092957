import React, {useEffect, useState} from "react";
import CustomModel from "./CustomModels/CustomModel1";
import { encryptUsingAES256, selectStylesOverride } from "../../utils/util";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { userDetails } from "../../redux/actions";
import AlertDialog from "../../views/Components/DialogBox/dialogbox";

const ChangePasswordModal = ({ open, hide, handleChangePassword, loading, currentUserDetail }) => {
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../static/styles/darktheme")
    : require("../../static/styles/theme");

  const { buttonClasses } = themeType;
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [termsCheck, setTermsCheck] = useState(false)
  const [dialog, setDialog] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const passwordEntered = useWatch({
    control,
    name: "newPassword",
  });

  // const handleSubmitFn = (data) => {
  //   if (data) {
  //     const payload = {
  //       password: data?.currentPassword,
  //       newPassword: data?.newPassword,
  //     };
  //     console.log('p11-------',payload)
  //     // handleChangePassword(payload);
  //   }
  // };

  const handleSubmitFn = (data) => {
    if (data) {
      const encryptCurrentPassword = encryptUsingAES256(data?.currentPassword);
      const encryptNewPassword = encryptUsingAES256(data?.newPassword);
  
      Promise.all([encryptCurrentPassword, encryptNewPassword])
        .then(([currentPasswordRes, newPasswordRes]) => {
          if (currentPasswordRes?.isSuccess && newPasswordRes?.isSuccess) {
            const payload = {
              password: currentPasswordRes?.data,
              newPassword: newPasswordRes?.data,
            };
            handleChangePassword(payload);
          }
        })
        .catch((error) => {
          console.error("Encryption failed:", error);
        });
    }
  };
  

  const handleSubmitFnFirstTime = (data) => {
    if (data) {
      const encryptCurrentPassword = encryptUsingAES256(data?.currentPassword);
      const encryptNewPassword = encryptUsingAES256(data?.newPassword);
  
      Promise.all([encryptCurrentPassword, encryptNewPassword])
        .then(([currentPasswordRes, newPasswordRes]) => {
          if (currentPasswordRes?.isSuccess && newPasswordRes?.isSuccess) {
            const payload = {
              password: currentPasswordRes?.data,
              newPassword: newPasswordRes?.data,
              isFirstLogin: false
            };
            handleChangePassword(payload);
          }
        })
        .catch((error) => {
          console.error("Encryption failed:", error);
        });
    }
  };


  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => {
        hide();
        reset();
      }}
      minWidth={550}
      maxWidth={550}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"auto"}
      childrenMaxHeight={"auto"}
      Title="Change Password"
      viewCloseIcon={open}
      viewMinimiseIcon={currentUserDetail?.isFirstLogin}
    >
      <Grid container my={3}>
        {currentUserDetail?.isFirstLogin ?
          <Grid item xs={12} mb={3} sx={{ display: 'flex', textAlign: 'center' }}>
            <Typography>
              Hey <b>{currentUserDetail?.name}.</b>, Welcome to <b>Lynktrac!</b> Please change your password before getting started.
            </Typography>
          </Grid>
          : null}
        <Grid item xs={12} mb={2}>
          <Controller
            name="currentPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Current Password is required",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                  }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    Current Password
                  </InputLabel>
                  <OutlinedInput
                    error={errors.currentPassword ? true : false}
                    id="outlined-adornment-password"
                    value={value || ""}
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {!showPassword ? (
                            <Visibility sx={{ fontSize: "1rem" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "1rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Current Password"
                    fullWidth
                    placeholder="Enter Password"
                  />
                  {errors?.currentPassword && (
                    <span
                      role="alert"
                      style={{ color: "#FF0101", fontSize: 11 }}
                    >
                      {errors?.currentPassword?.message}
                    </span>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: "New Password is required",
              },
              pattern: {
                value:
                  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                message:
                  "Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special case character",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                  }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    error={errors?.newPassword ? true : false}
                    id="outlined-adornment-password"
                    type={showNewPassword ? "text" : "password"}
                    value={value || ""}
                    onChange={onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {!showNewPassword ? (
                            <Visibility sx={{ fontSize: "1rem" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "1rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    fullWidth
                    placeholder="Enter Password"
                  />
                  {errors?.newPassword && (
                    <span
                      role="alert"
                      style={{ color: "#FF0101", fontSize: 11 }}
                    >
                      {errors?.newPassword?.message}
                    </span>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: {
                value: passwordEntered && true,
                message: "Please confirm your password",
              },
              validate: {
                passwordMatch: (value) =>
                  passwordEntered === value || "Please enter same password",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                  }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    Confirm New Password
                  </InputLabel>
                  <OutlinedInput
                    error={errors?.confirmPassword ? true : false}
                    value={value || ""}
                    onChange={onChange}
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {!showConfirmPassword ? (
                            <Visibility sx={{ fontSize: "1rem" }} />
                          ) : (
                            <VisibilityOff sx={{ fontSize: "1rem" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password"
                    fullWidth
                    placeholder="Enter Password"
                  />
                  {errors?.confirmPassword && (
                    <span
                      role="alert"
                      style={{ color: "#FF0101", fontSize: 11 }}
                    >
                      {errors?.confirmPassword?.message}
                    </span>
                  )}
                </FormControl>
              );
            }}
          />
        </Grid>




        {currentUserDetail?.isFirstLogin ?
          <Grid item xs={12} mb={0} sx={{ display: 'flex', textAlign: 'center'}}>
            <FormControlLabel
              sx={{
                color: "rgb(128, 128, 128)",
                "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
              }}
              label="I accept the"
              control={
                <Checkbox
                  id="select-all-checkbox"
                  sx={{
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    }
                  }}
                  checked={termsCheck}
                  onClick={(e) => {
                    e.preventDefault(); 
                    setTermsCheck(!termsCheck)
                  }}
                />
              }
            />
            <Typography sx={{fontSize:'13px', color:'#ff7700', display:'flex', alignItems:'center', position:'relative', right:'10px', cursor:'pointer', textDecoration:'underline', textUnderlineOffset:'3px'}} onClick={()=>setDialog(true)}>Terms and Conditions .</Typography>
          </Grid>
          : null}











        <Grid item xs={12} mt={2}>
        {currentUserDetail?.isFirstLogin ?
          <Button
            fullWidth
            size="small"
            disabled={!termsCheck}
            sx={buttonClasses.lynkitOrangeFill}
            onClick={handleSubmit(handleSubmitFnFirstTime)}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          :
          <Button
            fullWidth
            size="small"
            disabled={loading}
            sx={buttonClasses.lynkitOrangeFill}
            onClick={handleSubmit(handleSubmitFn)}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        }
        </Grid>
      </Grid>

      {dialog && (
          <AlertDialog
            viewDialog={dialog}
            sx={{ borderRadius: "50px" }}
            title={`Terms and Conditions `}
            subTitle={``}
            width={true}
            body={
              <Grid>
                <Grid sx={{marginBottom:'5px'}}><Typography sx={{fontWeight:'600'}}><b>1. INFORMATION ABOUT US</b></Typography></Grid>
                <Grid><Typography>These Terms and Conditions govern the use of the services that are made available by Lynkit Solutions Pvt. Ltd. (hereinafter referred to as “LSPL”, “We”, or “Us” or “Our”) through its website, www.lynktrac.io. These Terms and Conditions represent the understanding between the LSPL and the person, natural or legal, who subscribes and avails the services provided by LSPL. Please read and understand the Terms and Conditions carefully before clicking on the I Agree button. Upon the you agreeing to the Terms and Conditions, the same will be legally binding and will govern your subscription of our services.</Typography></Grid>
                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>2. About The Website</b></Typography></Grid>
                <Grid><Typography>www.lynktrac.io is a website (hereinafter referred to as “website”) owned and operated by Lynkit Solutions Pvt. Ltd., being a company incorporated under the Indian Companies Act, 1956. Our website provides a software application for supply chain management for tracking assets, trucks, containers, cargo etc. It provides services based on IoT devices by use of technologies such as RFID, GPS, LoRaWAN, Bluetooth, blockchain, or any other suitable technologies. By subscribing and using our website or availing the services provided on or through the website or any other website of LSPL or any applications made available by LSPL, however accessed, you agree to be bound by these Terms and Conditions. Also, LSPL reserves the right to update, modify and/ or change the Terms and Conditions from time to time by posting modification/ changes on the website, without any notice. You shall, at all times, be responsible for regularly reviewing the Terms and Conditions and the other policies and note the changes made therein. Your continued usage and access of the website, after any such modifications/ changes are posted, shall constitute your consent to such changes. We also encourage and advise you to read our privacy policy. You also agree to abide by the privacy policy (the "Privacy Policy") that applies to the website which forms a part and parcel of Terms and Conditions. If you do not agree to be bound by all of these Terms and Conditions, please refrain from accessing or using our website and availing the Services provided by Us. </Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>3. Registration</b></Typography></Grid>
                <Grid><Typography>To use our services, you are required to be registered on the website. You are also required to give such information and documents that may be required by various government authorities or required by other service providers engaged in carrying out incidental and ancillary services. If you fail to provide the said information and/ or documents, your registration may not be possible and you will not be able to avail the services. Further, you represent and warrant that: (a) all the information and data that you provide while registering and the documents you upload for that purpose are true, accurate, updated, and complete as required by various registration forms on the Website (the "Registration Data") and that (b) you will maintain and promptly update the Registration Data and documents uploaded to keep it true, accurate, current and complete. If you provide any information or documents that is false, untrue, inaccurate, not current or incomplete, or if we have reasonable grounds to suspect that such information or document is false, untrue, inaccurate, not current or incomplete, we have the right to suspend or terminate your account with immediate effect. In consideration of using our website, you represent that you are competent to use our site and authorized by the person, natural or legal, in whose name the registration is done and to create binding legal obligations for any liability you may incur as a result of the use of our site. You understand that you and persons authorised by you are legally responsible for use of our services.</Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>4. Software</b></Typography></Grid>
                <Grid><Typography>We may make available for download certain software or documentation. The said software or downloads, and their installation or its use are subject to the terms and conditions of use as mentioned therein and you will be required to accept the same. By accepting the terms, you agree to become a party to and you will be bound by all the terms and conditions mentioned herein.</Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>5. THIRD-PARTY SITES, PRODUCTS, AND SERVICES</b></Typography></Grid>
                <Grid><Typography>In case of links to other Internet sites owned by third parties, on LSPL’s web site your use of each of those sites is subject to the terms and conditions, if any, that each of those sites has posted. We have no control over sites that are not ours, and we are not responsible for any changes to or content on them. You agree that we will not be responsible or liable for any loss or damage of any sort incurred as the result of any of your transactions with third parties. Any questions, complaints, or claims related to any product or service should be directed to that website. LSPL makes no warranties as to the accuracy and correctness of the content of the links of third parties at any time.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>6. INFORMATION UPLOADED BY THE USERS</b></Typography></Grid>
                <Grid><Typography>Our site does not control the information provided by you and it is your duty to ensure that all the information provided by you is true, complete and accurate and not offensive or incomplete or fraudulent, harmful, inaccurate or misleading. You agree that we may monitor the server contents at any time to (a) comply with necessary laws, regulations or other governmental requests; (b) to operate the server properly or to protect itself and its users; or (c) perform any other function relating to the provision of services you have subscribed.</Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>7. OUR LIABILITY</b></Typography></Grid>
                <Grid><Typography>You understand and agree that LSPL will not be responsible or liable for any breach of security or violation of any laws with respect to your usage of the application. You understand and agree that LSPL will not be responsible or liable for any matter relating to the procedure, rules or regulations that you are required to follow under the any law for the time being in force. While we undertake to do the best to ensure uninterrupted functioning and availability of services, the web-enabled application as well as electronic devices deployed, the nature of the Internet and wireless connectivity is such that uninterrupted services cannot be guaranteed. You understand and agree that the Company will not responsible and liable for any loss or interruption in services or delays that may arise due to uploading of data to the web application or due to issues related to wireless connectivity or the functioning of the internet or the performance of electronic devices. We are also not responsible for indirect losses which happen as a side effect of the main loss or damage, including but not limited to:(a) loss of income or revenue;(b) loss of business; (c) loss of profits or contracts;(d) loss of anticipated savings; (e) loss of data; (f) waste of management or office time however arising and whether caused by tort (including negligence), breach of contract or otherwise.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>8. PAYMENT</b></Typography></Grid>
                <Grid><Typography>The applicable charges for availing various services offered by us have been duly agreed by you before commencing use of the application. Payment towards subscribed services must be made within 7 business days from receipt of invoice unless other credit period has been mutually agreed upon. Delay in payment beyond the decided period could attract interest at the rate of 15% p.a. and could lead to disabling of the account. Payment are accepted through NEFT/IMPS/RTGS and through cheque/DD. We reserve the right to add other payment methods in the future or to refrain from using any or some of the payment methods, which we currently offer. Please note that additional costs may be applicable, depending on the payment method that has been chosen by you. In case you are using the payment gateway you shall provide correct and accurate credit/ debit card or such other information and details required by the approved payment gateway for availing services.. Further, you shall not use the credit/ debit card which is not lawfully owned by you. You will be solely responsible for the security and confidentiality of your credit/ debit card details. We expressly disclaim all liabilities that may arise as a consequence of any unauthorized use of your credit/ debit card. We reserve the right to cancel or temporarily stop any service being provided by us, at any time and without prior notice. In case of cancellation of services, your right to use the service ceases right away. Refund of the fees, if any, shall be at our sole discretion.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>9. TERMINATION</b></Typography></Grid>
                <Grid><Typography>The services you have subscribed have been mutually agreed for a period and on the terms and conditions contained your purchase order and/or our sale invoice. If you wish to terminate services being availed, you may notify us at support@lynctrac.io and a representative of LSPL shall get in touch with you for closure of your account and settlement of dues including retrieval of our IoT devices and other equipment which may have been deployed to render services to you. You also agree that LSPL reserves the right to refuse to provide services and terminate your access to all or any part of the services at any time. You acknowledge that if we suspend or deactivate your Account and all related information made from your Account will not be accessible to you and you shall be barred from any further access or to such information or to the website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability and obligations towards government, in respect of services availed by you from and through our website.</Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>10. THIRD-PARTY AUTHORIZATIONS</b></Typography></Grid>
                <Grid><Typography>If you authorize or grant access to any person to upload any information or access any features of the services provided by LSPL’s website based on your Registration Data or User details or log in credentials, or avail the services provided by the website and/or to use certain segments of the website, then you will be at all times responsible and liable for access and the activities that are carried out using such authorization by the third party.</Typography></Grid>


                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>11. CONTENT ON OUR SITES</b></Typography></Grid>
                <Grid><Typography></Typography>Our website includes a combination of content that we create, or create with third parties, or with our partners or licensors or associates. All materials published on our sites, including, but not limited to written content, photographs, graphics, images, maps, illustrations, marks, logos, sound or video clips, design, texts, artwork, graphic material, databases, proprietary information and/ or otherwise legally protectable elements of the website are protected by our copyrights and the trademarks, service marks and logos contained therein and are the property of LSPL or those of our partners or licensors or associates respectively. You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on our website in whole or in part. Any unauthorized use of any material may subject you to civil liability, criminal prosecution, or both, under applicable laws. Except as expressly authorized by LSPL, in writing, you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly display, publish, adapt, edit the content and materials on the website, or create derivative works from such materials or content. LSPL reserves the right to modify, reject or eliminate any information residing on or transmitted to any of its servers that it, in its sole discretion, believes is unacceptable or in violation of these terms and conditions and to suspend or end your service for any reason or for violation of these terms and conditions.</Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>12. CONSENT</b></Typography></Grid>
                <Grid><Typography>Without the express prior written authorization of the Company, you may not: use any data mining robots ("bots"), hardware or software modules that add a specific feature or service by plugging into an existing larger system ("plug-ins"), or other data gathering and extraction tools, scripts, applications, or methods on this site; use any device, software, or hardware to bypass any operational element or to interfere, or attempt to interfere, with the proper working of this website, server or activities conducted therein; take any action that imposes an unreasonable or disproportionately large load on this website or its network infrastructure or that adversely affects our network or other customers; decompile, reverse engineer, modify or disassemble any of the software in or associated with the network and/or server; use any meta tags or any other "hidden text" utilizing the name of LSPL or any trademark of LSPL; frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of LSPL or use any trademark of the LSPL except as set forth in the foregoing limited license in these terms of use or in any other written agreement between you and LSPL that is currently in effect at the time of use.</Typography></Grid>
                
                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>13. UNAUTHORIZED USE</b></Typography></Grid>
                <Grid><Typography>You agree to take all reasonable steps to protect your account from being misused in any manner including but not limited to changing the password, authorization code etc. In case of any unauthorized use of your account with LSPL’s website, you must notify us immediately on becoming aware of the same at support@lynktrac.io. LSPL shall not be responsible or liable for any misuse or fraud which is caused as a result of your use of the account and/or if you authorize any other person/ entity to use or avail the services from the said account and/ or with third parties. You shall not transfer your account to another person. Only you will be personally liable in case of loss or damage caused to website or to any third party resulting from your negligence or misuse in protecting account information. LSPL reserves its rights to take legal action against the User or any person or entity in case of any unauthorized use of this website or any other domain names owned by LSPL and/or the content or data displayed by the said user or any other person or entity that is unlawful. You agree to undertake to indemnify and hold LSPL harmless from any loss or damage that arises in case of any breach or violation of the terms and conditions as mentioned herein and shall be liable to reimburse all costs and expenses incurred by LSPL in defending any claim, demand, suit or proceeding arising from or in connection with such violation or breach.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>14. FRAUDULENT ACCOUNTS</b></Typography></Grid>
                <Grid><Typography> We reserve the right to monitor suspicious account activities on our website. Accordingly, any user who fraudulently misuses our services through his/ her account shall be liable for legal action under the applicable laws. We reserve the right to suspend or terminate your account without notice if you have indulged in illegal, prohibited, fraudulent activity, or provided untrue, incomplete information, or misrepresented in any manner. You are strictly prohibited from communicating on or through our website any unlawful, harmful, offensive or otherwise objectionable material of any sort, including, but not limited to, any material that encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or international law. You also agree not to upload any viruses, worms, trojan horses, or other forms of harmful computer code, nor subject the website's network or servers to unreasonable traffic loads, or otherwise engage in conduct deemed disruptive to the ordinary operation of the website. If any of your actions undermine the integrity of our website or if you engage in such prohibited activities, we shall have the right to terminate your account with immediate effect. For the purpose of this clause, we shall owe no liability for any refunds. We also reserve the right to initiate appropriate legal proceedings against you as per applicable law, in addition to the action that may be taken by appropriate Government authorities against you. Further, you agree to indemnify us for the lawyer's fees or other costs that may arise in prosecuting such actions. Further, we disclaim any responsibility for the results of your use, misuse or fraud with respect to the specific authorization given by you to any person for use of your account. </Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>15. PRIVACY</b></Typography></Grid>
                <Grid><Typography>The information provided by you will not be utilized or shared with any third party unless required for services that you have subscribed or in relation to fraud verifications under any law for the time being in force. Information submitted to this website will be treated in accordance with our current Privacy Policy. To learn more about how we protect the privacy of the personal information, please visit our Privacy Policy . If you use the website and/or our services, you are accepting the terms and conditions of our Privacy Policy. If You do not agree to have your information used in any of the ways described in the Privacy Policy, you may discontinue use of the services.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>16. LIMITATION OF LIABILITY</b></Typography></Grid>
                <Grid><Typography>You understand that to the extent permitted under applicable law, in no event will the company or its officers, employees, directors, subsidiaries, affiliates, agents or licensors be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of revenues, profits, goodwill, use, data, intangible losses or any other damages (even if such parties were advised of, knew of or should have known of the possibility of such damages, and notwithstanding the failure of essential purpose of any limited remedy), arising out of or related to your use of the site or the services, regardless of whether such damages are based on contract, tort (including negligence and strict liability), warranty, statute or otherwise. If you are dissatisfied with any portion of this website and the services, your sole and exclusive remedy is to discontinue use of the site and the services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to You. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of our liability will be the minimum permitted under such applicable law.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>17. INDEMNITY</b></Typography></Grid>
                <Grid><Typography>You agree to indemnify, defend and hold harmless the Company, its parents, subsidiaries, affiliates, officers, directors, co-branders and other partners, employees, consultants and agents, from and against any and all third party claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys’ fees and court costs) that such parties may incur as a result of or arising from (a) any information you provide, post or transmit through the website , (b) Your use of the website and services, (c) Your violation of this Agreement, (d) Your violation of any rights of any other person or (e) any viruses, trojan horses, worms, , cancelbots or other similar harmful or deleterious programming routines input by you into the website.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>18. INTELLECTUAL PROPERTY RIGHTS</b></Typography></Grid>
                <Grid><Typography>Our website includes a combination of content that we create. All materials published on the website, including, but not limited to written content, data, photographs, graphics, images, illustrations, marks, logos, sound or video clips, design, texts, artwork, graphic material, databases, proprietary information, flash animation and/ or otherwise legally protectable elements of the website, are protected by our copyrights and the trademarks, service marks and logos contained therein are the property of the LSPL or those of our partners or licensors or associates respectively. Except where expressly stated otherwise, you are not permitted to copy, broadcast, download, store (in any medium), transmit, show or play in public, adapt or change in any way the content or any material of the website or its web pages for any other purpose whatsoever without the prior written permission of LSPL. Violators will be prosecuted. All rights reserved. Any rights not expressly granted herein are reserved.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>19. COMMUNICATION WITH USERS</b></Typography></Grid>
                <Grid><Typography>You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the website. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. </Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>20. NO AGENCY</b></Typography></Grid>
                <Grid><Typography>This agreement in whole or in any part thereof cannot be interpreted in such a manner so as to constitute the agency, or joint venture or partnership relationship between the parties.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>21. COMPLAINTS</b></Typography></Grid>
                <Grid><Typography>All complaints regarding our services can be emailed at contact@lynkit.in</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>22. NOTICE</b></Typography></Grid>
                <Grid><Typography>Any notice may be sent to LSPL by sending the same to the registered office or by email at contact@lynkit.in Except as explicitly stated otherwise, LSPL will send a notice to you at the email address that you have provided during the registration process. Notice shall be deemed given 24 hours after email is sent. Alternatively, we may give you notice by certified mail or post, to the address provided to us during the registration process. In such case, notice shall be deemed given 3 days after the date of mailing.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>23. MODIFICATION/NOTIFICATION OF CHANGES</b></Typography></Grid>
                <Grid><Typography>We reserve the right to make changes to the website, User Agreement, Privacy Policy or related policies from time to time at any time by posting changes on this site. You shall, at all times, be responsible for regularly reviewing the Terms and Conditions and the other policies and note the changes made on the website. If you disagree with our changes, then you should stop using our Services, and terminate this agreement. However, your continued usage of the service after any such changes is posted shall constitute your consent to such changes and the same shall be legally enforceable and binding upon you. In the event that any of the additional terms and conditions governing such areas conflict with this Agreement, this Agreement will prevail.</Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>24. SEVERABILITY</b></Typography></Grid>
                <Grid><Typography>The Terms and Conditions shall be severable. If any part of this Agreement is held invalid, illegal, or unenforceable, that part will be construed to reflect the parties’ original determination, and the remaining portions will remain in full force and effect. However, such determination shall not affect the enforceability, legality, and validity of any other remaining provisions. A waiver by either party of any terms and conditions of this agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. </Typography></Grid>

                <Grid sx={{marginBottom:'5px', marginTop:'5px'}}><Typography sx={{fontWeight:'600'}}><b>25. GOVERNING LAW & JURISDICTION</b></Typography></Grid>
                <Grid><Typography>The Terms and Conditions, its performance and all disputes arising out of or in connection with the Terms and Conditions shall be governed by the laws of India, with exclusive jurisdiction conferred on the courts at Delhi.</Typography></Grid>
              </Grid>

            }
          >
            <Grid container sx={{ p: 1, mt: 0 }} justifyContent="center">
              <Button
                variant="outlined"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  minWidth: "150px",
                }}
                onClick={()=>setDialog(false)}
              >
                 Cancel
              </Button>

             
            </Grid>
          </AlertDialog>
        )}
    </CustomModel>
  );
};

export default ChangePasswordModal;
