import axios, { commonAxios, geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import {
  ALERT_COUNT_LOADING,
  DETAIL_LOADING,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ADMINS_FROM_ACCESSKEY,
  GET_ADMINS_MONITORING,
  GET_ALERTS_DATA,
  GET_ALERT_COMMENTS,
  GET_ASSETS_OBSERVATION,
  GET_GEOFENCE_MONITORING,
  GET_LOCATION,
  GET_MAPVIEW_ALERTS,
  GET_MONITORING_DATA,
  MONITORING_COUNT_LOADING,
  MONITORING_LOADING,
  SET_ADMIN_VALUE,
} from "../types";

export const getMonitoringData = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: MONITORING_LOADING, payload: true });
    geoAxios
      .post("/getMonitoring", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_MONITORING_DATA, payload: [] });
          cb && cb();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_MONITORING_DATA,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
        dispatch({ type: MONITORING_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        dispatch({ type: MONITORING_LOADING, payload: false });
      });
  };
};

export const createMonitoringBoxes = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: MONITORING_LOADING, payload: true });
    geoAxios
      .post("/createMonitoringBoxesForUser", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          // dispatch({ type: GET_MONITORING_DATA, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          // dispatch({
          //   type: GET_MONITORING_DATA,
          //   payload: data?.data,
          // });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: MONITORING_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        console.log("Error****:", error.message);
        dispatch({ type: MONITORING_LOADING, payload: false });
      });
  };
};

export const getMonitoringTripCount = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: MONITORING_COUNT_LOADING, payload: true });
    geoAxios
      .post("/getMonitoringTripCount", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          dispatch({ type: "MONITORING_TRIP_COUNT", payload: null });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "MONITORING_TRIP_COUNT",
            payload: data?.data,
          });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: MONITORING_COUNT_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: MONITORING_COUNT_LOADING, payload: false });
      });
  };
};

export const getMonitoringDeviceCount = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: MONITORING_COUNT_LOADING, payload: true });
    geoAxios
      .post("/getMonitoringDeviceCount", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          dispatch({ type: "MONITORING_DEVICE_COUNT", payload: null });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "MONITORING_DEVICE_COUNT",
            payload: data?.data,
          });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: MONITORING_COUNT_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: MONITORING_COUNT_LOADING, payload: false });
      });
  };
};

export const getMonitoringAlertCount = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: ALERT_COUNT_LOADING, payload: true });
    axios
      .post("/getEmailLogsCount", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          dispatch({ type: "MONITORING_ALERT_COUNT", payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "MONITORING_ALERT_COUNT",
            payload: data?.data,
          });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: ALERT_COUNT_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: ALERT_COUNT_LOADING, payload: false });
      });
  };
};

export const getMonitoringAnalyticsDetails = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    geoAxios
      .post("/getMonitoringHeaderData", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          dispatch({ type: "MONITORING_ANALYTICS_DETAILS", payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "MONITORING_ANALYTICS_DETAILS",
            payload: data?.data,
          });
          dispatch({ type: "MONITORING_ALERT_DETAILS", payload: [] });
          dispatch({ type: GET_ASSETS_OBSERVATION, payload: [] });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const getMonitoringAlertDetails = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    axios
      .post("/getAlertData", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
          dispatch({ type: "MONITORING_ALERT_DETAILS", payload: [], count: 0 });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "MONITORING_ALERT_DETAILS",
            payload: data?.data,
            count: data?.count
          });
          dispatch({ type: "MONITORING_ANALYTICS_DETAILS", payload: [] });
          dispatch({ type: GET_ASSETS_OBSERVATION, payload: [] });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const editMonitoringData = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/editMonitoringParams", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        cb && cb(null);
        console.log("Error****:", error.message);
      });
  };
};

export const getMapviewAlerts = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/mapviewAlerts", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_MAPVIEW_ALERTS, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_MAPVIEW_ALERTS,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const getAlertsData = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getAlertsData", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_ALERTS_DATA, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ALERTS_DATA,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const generateAlertComment = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/generateAlertComment", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const getAlertComments = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getAlertComments", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_ALERT_COMMENTS, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ALERT_COMMENTS,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const resolveAlertComments = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/resolveAlertComments", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const getLocation = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getLocations", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_LOCATION, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_LOCATION,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const saveGeofenceMonitoring = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/saveGeofenceMonitoring", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const getGeofenceMonitoring = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getGeofenceMonitoring", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_GEOFENCE_MONITORING, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_GEOFENCE_MONITORING,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const getAdminsForMonitoringOnly = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getAdminsForMonitoringOnly", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_ADMINS_MONITORING, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ADMINS_MONITORING,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const getAdminFromAccessKey = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    commonAxios
      .post("auth/v1/monitoring/getAdminFromAccessKey", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_ADMINS_FROM_ACCESSKEY, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ADMINS_FROM_ACCESSKEY,
            payload: data?.data,
          });
          localStorage.setItem("userDetail", JSON.stringify(data?.data));
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const addDeviceToObservation = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    geoAxios
      .post("/addToObservation", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        showToast(error?.response?.data?.message, true);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const removeDeviceFromObservation = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    geoAxios
      .post("/removeFromObservation", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        showToast(error?.response?.data?.message, true);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const resolveAlert = (body, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    geoAxios
      .post("/resolveAlert", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          showToast(data?.message);
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        showToast(error?.response?.data?.message, true);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const getAssetsObservation = (body = {}, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: DETAIL_LOADING, payload: true });
    geoAxios
      .post("/getAssetsObservation", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          cb && cb(null);
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_ASSETS_OBSERVATION, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_ASSETS_OBSERVATION,
            payload: data?.data,
          });
          dispatch({ type: "MONITORING_ANALYTICS_DETAILS", payload: [] });
          dispatch({ type: "MONITORING_ALERT_DETAILS", payload: [] });
          if (cb) {
            cb(data?.data);
          }
        }
        dispatch({ type: DETAIL_LOADING, payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
        dispatch({ type: DETAIL_LOADING, payload: false });
      });
  };
};

export const flushAllMonitoringData = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_MONITORING_DATA" });
  };
};

export const setUserId = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_USER_ID", payload: data });
  };
};

export const resetUserId = () => {
  return (dispatch) => {
    dispatch({ type: "SET_USER_ID", payload: null });
  };
};

export const flushAllCounts = () => {
  return (dispatch) => {
    dispatch({ type: "MONITORING_DEVICE_COUNT", payload: null });
    dispatch({ type: "MONITORING_TRIP_COUNT", payload: null });
    dispatch({ type: "MONITORING_ALERT_COUNT", payload: null });
  };
};

export const setAdminValue = (data) => ({
  type: SET_ADMIN_VALUE,
  payload: data,
});
