import { Row } from "reactstrap";
import FilterForm from "./Sub-Components/FilterForm";
import PanelAction from "./Sub-Components/PanelAction";
const Panel = ({ children }) => {
  return (
    <>
      <Row className="d-flex space-between">{children}</Row>
    </>
  );
};
export default Panel;
export { FilterForm };
export { PanelAction };


