import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  CHECK_LOGIN,
  CURRENT_USER_DETAILS,
  VALIDATE_TOKEN
} from "../types";
import axios from "../../services/Api";
import { loginAxios } from "../../services/Api";
import history from "../../services/history";
import { showToast } from "../../services/functions";
import { userDetails } from "./Users";
import { decryptUsingAES256 } from "../../utils/util";
import { connectSocket, connectToSocket } from "./socket";
import configureStore from "../store";
import { getAllFilters } from "./Filters";
import { setTripsFilters } from "../reducers/TripsReducer";
import { setDevicesFilters } from "../reducers/DevicesReducers";

export const dummyAction = (url) => {
  return {
    type: "DUMMY",
    payload: "shavika",
  };
};

export const userLogin = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    loginAxios
      .post("/login", payload)
      .then(({ data }) => {
        if (data?.encryptedString) {
          const decryptedRes = decryptUsingAES256(data?.encryptedString);
          decryptedRes?.then((res) => {
            if (res?.isSuccess) {
              const responseData = JSON.parse(res?.data);
              localStorage.removeItem('sidebar')
              if (responseData?.success) {
                localStorage.setItem(
                  "token",
                  JSON.stringify(responseData.data.token)
                );
                axios.defaults.headers.common["authorization"] =
                  responseData.data.token;
                dispatch({ type: FETCH_SUCCESS });
                // history.push("/dashboard");

                //socket code -----
                const decodedToken = parseJwt(
                  JSON.stringify(responseData.data.token)
                );
                dispatch({ type: CHECK_LOGIN, payload: decodedToken?.userId });
                dispatch(flushCurrentUserDetail())
                dispatch(getAllFilters());
                dispatch(
                  currentUserDetails({ id: decodedToken?.userId }, (res) => {
                    if (res) {
                      if (res?.permissions?.user?.v1user?.value) {
                        localStorage.removeItem("token");
                        window.location.assign("https://lynktrac.io");
                      } else {
                        history.push("/dashboard");
                        const connectionObj = {
                          userId: res?._id,
                          userName: res?.name,
                          iat: Date.now(),
                          socketID: "",
                        };
                        dispatch(connectToSocket(connectionObj));
                      }
                    }
                  })
                );
                // dispatch(checkLogin());
              } else {
                dispatch({ type: FETCH_ERROR, payload: res?.data.message });
                showToast(res?.data?.message, true);
              }
            }
          });
        }
      })
      .catch(function (error) {
        const decryptedRes = decryptUsingAES256(error?.response?.data?.encryptedString);
        decryptedRes?.then((res) => {
          if (res?.isSuccess) {
            const responseData = JSON.parse(res?.data);
            if (!responseData?.success) {
              showToast(responseData?.message, true)
            }
          }
        });
        // dispatch({ type: FETCH_ERROR, payload: error.message });
        // showToast("Some problem occurred", true);
      });
  };
};

export const userLogout = (cb) => {
  const config = {
    headers: {
      Authorization: JSON.parse(localStorage.getItem('token'))

    },
  };
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    loginAxios
      .post("/logout", {}, config)
      .then(({ data }) => {
        if (data?.success) {
          cb && cb(true);
          const store = configureStore()
          store.persistor?.purge();
          var versionValue = localStorage.getItem("version");
          localStorage.clear();
          localStorage.setItem("version", versionValue);
          dispatch(setTripsFilters(null));
          dispatch(setDevicesFilters(null));
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
          cb && cb(null);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        cb && cb(null);
      });
  };
}

export const getOTPforResetPassword = (payload, cb, errorCb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "GENERATE_OTP_LOADING", payload: true });
    loginAxios
      .post("/forgotPassword", payload)
      .then(({ data }) => {
        if (data?.success) {
          cb && cb(true);
          showToast(data.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
          errorCb && errorCb();
        }
        dispatch({ type: "GENERATE_OTP_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        errorCb && errorCb();
        dispatch({ type: "GENERATE_OTP_LOADING", payload: false });
      });
  };
};

export const sendOTP = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "GENERATE_OTP_LOADING", payload: true });
    axios
      .post("/sendOtp", payload)
      .then(({ data }) => {
        console.log("data response: ", data);
        if (data?.success) {
          cb && cb(true);
          showToast(data.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
        }
        dispatch({ type: "GENERATE_OTP_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message || "Something went wrong!", true);
        dispatch({ type: "GENERATE_OTP_LOADING", payload: false });
      });
  };
};

export const verifyOTP = (payload, cb, errorCb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "VERIFY_OTP_LOADING", payload: true });
    axios
      .post("/verifyOtp", payload)
      .then(({ data }) => {
        if (data?.success) {
          cb && cb(true);
          showToast(data.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
          errorCb && errorCb();
        }
        dispatch({ type: "VERIFY_OTP_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message || "Something went wrong!", true);
        errorCb && errorCb();
        dispatch({ type: "VERIFY_OTP_LOADING", payload: false });
      });
  };
};

export const registerUser = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "REGISTER_USER_LOADING", payload: true });
    axios
      .post("/registerUser", payload)
      .then(({ data }) => {
        if (data?.success) {
          showToast(data.message);
          cb && cb(true);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
        }
        dispatch({ type: "REGISTER_USER_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message || "Something went wrong!", true);
        dispatch({ type: "REGISTER_USER_LOADING", payload: false });
      });
  };
};

export const updatePassword = (payload, cb, errorCb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    loginAxios
      .post("/updatePassword", payload)
      .then(({ data }) => {
        if (data?.success) {
          cb && cb(true);
          showToast(data.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
          errorCb && errorCb();
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        errorCb && errorCb();
      });
  };
};

export const changePassword = (payload, cb, errorCb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    loginAxios
      .post("/changePassword", payload, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token'))
        },
      })
      .then(({ data }) => {
        if (data?.success) {
          cb && cb(true);
          setTimeout(() => {
            showToast(data.message);
          }, 600)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data.message, true);
          errorCb && errorCb();
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        errorCb && errorCb();
      });
  };
};

export const currentUserDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/userDetails", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: CURRENT_USER_DETAILS, payload: { rows: [] } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          localStorage.setItem("orgId", data?.data?.orgId);
          const isSuperAdmin = data.data?.adminSpecificPermissions
            ? true
            : false;
          localStorage.setItem("isSuperAdmin", isSuperAdmin);
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: CURRENT_USER_DETAILS,
            payload: { rows: data.data },
          });
          if (cb) {
            cb(data.data);
          }
        }
      })
      .catch(function (error) {
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const checkLogin = (user) => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = parseJwt(token);
    dispatch({ type: CHECK_LOGIN, payload: decodedToken?.userId });
    dispatch(currentUserDetails({ id: decodedToken?.userId }, (res) => {
      if (res) {
        dispatch(getAllFilters())
      }
    }));
  };
};

export const flushUserId = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_USER_ID", payload: "" });
  };
};

export const parseJwt = (token) => {
  if (token) {
    var base64Url = token?.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
};

export const flushCurrentUserDetail = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_CURRENT_USER" });
  };
};

export const validateToken = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await loginAxios
      .post("/validateToken", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: VALIDATE_TOKEN,
            payload: { rows: data.data },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: VALIDATE_TOKEN, payload: { rows: [] } });
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};