import {
  capitalizeSentence,
  convertHHMMToDuration,
} from "../../../services/functions";
function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  return formattedDate;
}

function processTimeZone(
  timeToConvert,
  timeZone,
  format = "MMM DD, YYYY, hh:mm:ss A"
) {
  // need to implement timezone and format logic here
  // return new Date(timeToConvert);
  // Example usage:
  const date = new Date(timeToConvert); // Current date and time
  const formattedDate = formatDate(date);
  return formattedDate;
}
/** End of Timezone convertor */

const fnBatteryPercentage = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily?.batteryPercentage) {
    return `${objFamily?.batteryPercentage}%`;
  }
  return `${pLabel}  - `;
};
const fnRouteName = (
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  handleShowDetails
) => {
  if (objFamily["routeName"]) {
    const routeName = `${objFamily?.routeName}`;
    const elemId = `routeName-${index}`;
    let spanHTML;

    if (routeName.length > 14) {
      let shortText = routeName.substring(0, 14) + "...";
      spanHTML = `<div class="tooltip-container">
        <span id=${elemId} style="color: #ff7700; cursor: pointer; font-size:13px;">${shortText}</span>
        <span class="tooltip-content">${objFamily?.routeName}</span>
      </div>`;
    } else {
      spanHTML = `<span id=${elemId} style="color: #ff7700; cursor: pointer; font-size:13px;">${routeName}</span>`;
    }
    setTimeout(() => {
      const nameEl = document.getElementById(elemId);
      if (nameEl) {
        nameEl.onclick = () => {
          handleShowDetails(objFamily);
        };
      }
    }, 50)
    return spanHTML;
  }

  return "-";
};
const fnRouteDescription = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily["description"]) {
    const description = `${objFamily?.description}`;
    if (description.length > 14) {
      let shortText =
        description && description?.length > 14
          ? description.substring(0, 14) + "..."
          : description ?? "-";
      return `<div class="tooltip-container">
                <span style=" cursor: pointer; font-size:13px;">${shortText}</span>
                <span class="tooltip-content">${objFamily?.description}</span>
            </div>`;
    } else {
      return `<span style="font-size:13px;"> ${description} </span>`;
    }
  }
  return `-`;
};
const fnRouteOrigin = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily?.routeSource?.name) {
    const origin = `${objFamily?.routeSource?.name}`;
    if (origin.length > 14) {
      let shortText =
        origin && origin?.length > 14
          ? origin.substring(0, 14) + "..."
          : origin ?? "-";
      return `<div class="tooltip-container">
                <span style=" cursor: pointer; font-size:13px;">${shortText}</span>
                <span class="tooltip-content">${objFamily?.routeSource?.name}</span>
            </div>`;
    } else {
      return `<span style="cursor: pointer; font-size:13px;">${objFamily?.routeSource?.name}</span>`;
    }
  }
  return `-`;
};

const fnRouteDestination = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily?.routeDestination?.name) {
    const origin = `${objFamily?.routeDestination?.name}`;
    if (origin.length > 14) {
      let shortText =
        origin && origin?.length > 14
          ? origin.substring(0, 14) + "..."
          : origin ?? "-";
      return `<div class="tooltip-container">
                <span style=" cursor: pointer; font-size:13px;">${shortText}</span>
                <span class="tooltip-content">${objFamily?.routeDestination?.name}</span>
            </div>`;
    } else {
      return `<span style="cursor: pointer; font-size:13px;">${objFamily?.routeDestination?.name}</span>`;
    }
  }
  return `-`;
};

const fnRouteViapoints = (
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  setViaPointEntry
) => {
  let len = objFamily?.viaPoints && objFamily?.viaPoints?.length;
  if (objFamily && objFamily.viaPoints) {
    const btnId = `btn-${index}`;
    const spanHtml = `<div style="position:relative; top: 5px;">
    <div id='${btnId}' class="${len ? "viapointContainerActive" : "viapointContainer"
      }">
                            <span style="color: #fff;  font-size:12px;">${objFamily?.viaPoints?.length
      }</span>
                        </div> </div>`;
    setTimeout(() => {
      const btnElement = document.getElementById(btnId);
      if (btnElement) {
        btnElement.onclick = () => {
          if (len !== 0) {
            setViaPointEntry(objFamily);
          }
        };
      }
    }, 50)

    return spanHtml;
  }
  return `-`;
};
const fnRouteStatus = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily && objFamily["status"]) {
    const status = objFamily["status"];
    if (status === "ACTIVE") {
      return `<span style="color: #008000; font-size: 13px; font-weight: 400">${capitalizeSentence(
        status
      )}</span>`;
    } else if (status === "DISABLE") {
      return `<span style="color: red; font-size: 13px; font-weight: 400">Blocked</span>`;
    }
  }
};

const fnRouteDistance = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily?.routeDestination?.totalDistance) {
    return `${objFamily?.routeDestination?.totalDistance} km`;
  }
  return `${pLabel}  - `;
};
const fnRouteTime = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily?.routeDestination?.etaTime) {
    return `${convertHHMMToDuration(objFamily?.routeDestination?.etaTime)}`;
  }
  return `${pLabel}  - `;
};

export function main(
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  setViaPointEntry,
  handleShowDetails
) {
  switch (str) {
    case "fnBatteryPercentage":
      return fnBatteryPercentage(str, pKey, pLabel, objFamily);
    case "fnRouteName":
      return fnRouteName(
        str,
        pKey,
        pLabel,
        objFamily,
        index,
        handleShowDetails
      );
    case "fnRouteDescription":
      return fnRouteDescription(str, pKey, pLabel, objFamily);
    case "fnRouteOrigin":
      return fnRouteOrigin(str, pKey, pLabel, objFamily);
    case "fnRouteDestination":
      return fnRouteDestination(str, pKey, pLabel, objFamily);
    case "fnRouteViapoints":
      return fnRouteViapoints(
        str,
        pKey,
        pLabel,
        objFamily,
        index,
        setViaPointEntry
      );
    case "fnRouteStatus":
      return fnRouteStatus(str, pKey, pLabel, objFamily, index);
    case "fnRouteDistance":
      return fnRouteDistance(str, pKey, pLabel, objFamily, index);
    case "fnRouteTime":
      return fnRouteTime(str, pKey, pLabel, objFamily, index);

    default:
      try {
        let valueStr = getNestedValues(objFamily, pKey);
        return `<span style="font-size:13px ">${valueStr}</span>`;
        // return (objFamily[pKey]) ? `${pLabel} : ${objFamily[pKey]}` : `${pLabel} : -`;
      } catch (error) {
        return pLabel && pLabel != "" ? `${pLabel} : - ` : "-";
      }
  }
}
/** Nested object resolutions */

function getNestedValues(objFamily, keyPath) {
  const keys = keyPath.split(".");
  let value = objFamily;
  for (const key of keys) {
    if (value && value.hasOwnProperty(key)) {
      value = value[key] || "-";
    } else {
      return "-";
    }
  }
  return value;
}
