import React, { useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeSentence, getCamelCaseText } from "../../../../services/functions";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function DevicesDetails(props) {
    const { tripDetails, index } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { tableClasses } = themeType;

    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 150;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 150;
        };


    }
    const styleCssLabel = isDarkThemeEnabledSelector ?
        {
            color: "#808080",
            border: '1px solid #808080',
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }

    const styleCssValue = isDarkThemeEnabledSelector ?
        {
            color: "#fff",
            border: '1px solid #808080'
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }

    const styleCSSBorder = isDarkThemeEnabledSelector ?
        {
            borderLeft: '1px solid #808080',
            borderRight: '1px solid #808080'

        }
        :
        {
            borderLeft: '1px solid #E0E0E0',
            borderRight: '1px solid #E0E0E0'
        }


    function chunkArray(array, chunkSize) {
        console.log('a--',array);
        if (array.length) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                const chunk = array.slice(i, i + chunkSize);
                // Check if the chunk is smaller than the specified size
                if (chunk.length < chunkSize) {
                    // Pad the chunk with empty objects
                    const diff = chunkSize - chunk.length;
                    for (let j = 0; j < diff; j++) {
                        chunk.push({});
                    }
                }
                chunks.push(chunk);
            }
            return chunks;
        }
        return [];
    }

    const chunkedArrays = chunkArray(tripDetails?.devices ? tripDetails?.devices : [], 3);


    const copyToClipBoard = (num) => {
        navigator.clipboard.writeText(num)
    }
    return (
        <>
            {
                chunkedArrays && chunkedArrays?.length ?
                    <div data-testid="linked-devices-table-box" style={{ overflowX: 'hidden', display: 'flex', marginTop: '2.3%'  }}>
                        <div className="arrowBox">
                            <ArrowLeftIcon id={`slideLeft-${index}`} onClick={() => slide(index)} className="arrowClass" />
                        </div>
                        <div id={`container-${index}`} className="slidingContainer" style={{ height:'160px', justifyContent:'center', alignItems: "flex-start", paddingTop: "5px"}} mt={5}>
                            {chunkedArrays && chunkedArrays?.map((chunk, index) => (
                                <table style={{ ...tableClasses.myCTable, width: '100%', marginRight:'0px' }} key={index}>
                                    <thead>
                                        <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'15%', background: "rgba(159, 158, 158, 0.2)" }}>Device ID</th>
                                        <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'15%', background: "rgba(159, 158, 158, 0.2)" }}>Device Type</th>
                                        <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'15%', background: "rgba(159, 158, 158, 0.2)" }}>Assigned Location</th>
                                        <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'15%', background: "rgba(159, 158, 158, 0.2)" }}>Remark</th>
                                        <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'15%', background: "rgba(159, 158, 158, 0.2)" }}>Status</th>

                                    </thead>
                                    <tbody>

                                        {chunk && chunk.map((item, idx) => (
                                            <tr key={idx}>
                                                <td style={{ ...tableClasses.myTD, ...styleCssValue }}>
                                                    {item?.deviceId || '-'}
                                                    {item?.deviceId ?
                                                        <ContentCopyIcon style={{ fontSize: '13px', cursor: 'pointer', marginLeft: '15px' }} onClick={() => copyToClipBoard(item?.deviceId)} />
                                                        : null}
                                                </td>
                                                <td style={{ ...tableClasses.myTD, ...styleCssValue }}>
                                                    {item.assetType || item.deviceType ?
                                                        item.assetType ?
                                                            item.assetType === 'fixedELock' ? 'Fixed E-Lock' : item.assetType === 'elock' ? 'E-Lock' : item.assetType === 'assetTracker' ? 'Asset Tracker' : item.assetType === 'fixedTracker' ? 'Fixed Tracker' : item.assetType === 'simTracking' ? 'Sim Tracking': getCamelCaseText(item.assetType)
                                                            :
                                                            item.deviceType === 'fixedELock' ? 'Fixed E-Lock' : item.deviceType === 'elock' ? 'E-Lock' : item.deviceType === 'assetTracker' ? 'Asset Tracker' : item.deviceType === 'fixedTracker' ? 'Fixed Tracker' : item.assetType === 'simTracking' ? 'Sim Tracking' : getCamelCaseText(item.deviceType)
                                                        : '-'
                                                    }
                                                </td>
                                                <td style={{ ...tableClasses.myTD, ...styleCssValue }}>
                                                    {item?.address && item?.address?.name ? item?.address?.name : '-'}
                                                </td>
                                                <td style={{ ...tableClasses.myTD, ...styleCssValue }}>
                                                    {item?.remark || '-'}
                                                </td>
                                                <td style={{ ...tableClasses.myTD, ...styleCssValue }}>
                                                    {capitalizeSentence(item?.status) || '-'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ))}
                        </div>

                        <div className="arrowBox">
                            <ArrowRightIcon id={`slideRight-${index}`} onClick={() => slide(index)} className="arrowClass" />
                        </div>
                    </div>
                    :
                    <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        No Device Linked..
                    </div>
            }


        </>
    );
}

export default DevicesDetails;
