import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import "./signup.scss";
import { CustomInput } from "../../../components/FormUI";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import theme from "../../../static/styles/darktheme";
import { encryptUsingAES256, selectStylesOverride } from "../../../utils/util";
import { InputField } from "./InputField";
import { registerUser, sendOTP, verifyOTP } from "../../../redux/actions";
import { showToast } from "../../../services/functions";
import { ToastContainer } from "react-toastify";
import history from "../../../services/history";

const stylesOverride = {
  "& label.Mui-focused": {
    color: "#505050",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#505050",
      borderWidth: 1,
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#505050",
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: "#505050",
      borderWidth: 1,
    },
  },
};

const Signup = () => {
  // local states ----
  const [requestOtp, setRequestOtp] = React.useState(false);
  const [countdown, setCountdown] = React.useState(60);
  const [isCounting, setIsCounting] = React.useState(false);
  const [otp, setOtp] = React.useState(null);

  const { inputClasses } = theme;

  //dispatchers and selectors -----
  const dispatch = useDispatch();
  const { generateOtpLoading, verifyOtpLoading, registerUserLoading } =
    useSelector((state) => state.auth);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const nameWatch = useWatch({
    control: control,
    name: "name",
  });
  const emailWatch = useWatch({
    control: control,
    name: "email",
  });
  const mobileWatch = useWatch({
    control: control,
    name: "mobileNumber",
  });
  const passwordWatch = useWatch({
    control: control,
    name: "password",
  });

  // handlers ----------
  const onFormSubmit = (values) => {
    if (values) {
      const payload = {
        email: values?.email.toLowerCase(),
        mobile: values?.mobileNumber,
        name: values?.name,
      };

      dispatch(
        sendOTP(payload, (res) => {
          if (res) {
            setRequestOtp(true);
            setIsCounting(true);
            setCountdown(60);
            setOtp(null);
          }
        })
      );
    }
  };

  const handleResendOTP = (e) => {
    e.preventDefault();
    if (requestOtp) {
      const payload = {
        email: emailWatch.toLowerCase(),
        mobile: mobileWatch,
        name: nameWatch,
      };

      dispatch(
        sendOTP(payload, (res) => {
          if (res) {
            setRequestOtp(true);
            setIsCounting(true);
            setCountdown(60);
            setOtp(null);
          }
        })
      );

      // const generateOtpPayload = {
      //   userName: nameWatch?.trim(),
      //   email: emailWatch,
      //   mobile: fields?.phoneNumber,
      // };

      // dispatch(generateOtp(generateOtpPayload))?.then((res) => {
      //   if (res?.type === "auth/generateOtp/fulfilled") {
      //     setId(res?.payload?.data?._id);
      //     setRequestOtp(true);
      //     setIsCounting(true);
      //     setCountdown(60);
      //     setOtp(null);
      //   }
      // });
    }
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (otp) {
      if (!/^\d{6}$/.test(otp)) {
        showToast("Please enter a valid OTP", true);
      } else {
        const verifyOtpPayload = {
          mobile: mobileWatch,
          email: emailWatch.toLowerCase(),
          otp: otp,
        };

        dispatch(
          verifyOTP(verifyOtpPayload, (res) => {
            if (res) {
              handleRegisterUser();
            }
          })
        );
      }
    }
  };

  const handleRegisterUser = () => {
    if (passwordWatch) {
      const encryptedPassword = encryptUsingAES256(passwordWatch);

      encryptedPassword?.then((res) => {
        if (res?.isSuccess) {
          const registerUserPayload = {
            email: emailWatch.toLowerCase(),
            mobile: mobileWatch,
            userName: nameWatch,
            city: "",
            address: "",
            pincode: "",
            accountType: "Personal",
            state: "",
            companyName: "",
            gstIn: "",
            password: res?.data,
            confirmPassword: res?.data,
          };

          dispatch(
            registerUser({ ...registerUserPayload }, (res) => {
              if (res) {
                history.push("/login");
              }
            })
          );
        }
      });
    }
  };

  const onInputChange = (e, onChange,name) => {
    if (requestOtp) {
      setRequestOtp(false);
      setCountdown(60);
      setIsCounting(false);
    }
    if(name === 'email' || name === 'mobileNumber'){
      onChange(e?.target?.value.trim());
    }
    else{
      onChange(e?.target?.value);
    }
  };

  //effects ------
  React.useEffect(() => {
    reset();
  }, []);

  React.useEffect(() => {
    let countdownInterval;

    if (isCounting) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            setIsCounting(false);
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isCounting]);

  return (
    <Grid container className="sign-up">
      <Grid item className="logo_main">
        <img
          data-testid="main-logo"
          style={{ height: "100%" }}
          src={require("../../../assets/images/sign-up/logo_dark.png")}
          alt="#"
        />
      </Grid>

      <Grid xs={12} className="hero-img-row">
        {/* Hero img section---- */}
        <div className="hero-img-container">
          <img
            src={require("../../../assets/images/sign-up/amazaon register page asset copy.png")}
          />
        </div>
      </Grid>

      {/* form section----- */}
      <section
        className="form-card d-flex flex-column align-items-center"
        data-testid="form-card"
      >
        <div className="pill fw-700">Sign Up</div>
        <span>Access to Lynktrac Tracker</span>
        <form className="w-100 mt-2">
          <Grid container rowGap={2}>
            <Grid xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    label="Name"
                    name={name}
                    required
                    value={value || ""}
                    onChange={(e) => {
                      onInputChange(e, onChange);
                    }}
                    selectStylesOverride={stylesOverride}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email",
                  },
                }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    label="Email"
                    name={name}
                    required
                    value={value || ""}
                    onChange={(e) => {
                      onInputChange(e, onChange,name);
                    }}
                    selectStylesOverride={stylesOverride}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Mobile No. is required",
                  },
                  pattern: {
                    value: /^\d{7,15}$/,
                    message: "Please enter a valid mobile number",
                  },
                }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    label="Mobile No."
                    name={name}
                    required
                    value={value || ""}
                    onChange={(e) => {
                      onInputChange(e, onChange,name);
                    }}
                    selectStylesOverride={stylesOverride}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter password",
                  },
                  pattern: {
                    value:
                      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                    message:
                      "Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special case character",
                  },
                }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    label="Password"
                    name={name}
                    required
                    value={value || ""}
                    onChange={(e) => {
                      onInputChange(e, onChange);
                    }}
                    selectStylesOverride={stylesOverride}
                    errors={errors}
                  />
                )}
              />
            </Grid>
            {!requestOtp && (
              <Grid xs={12}>
                <Button
                  // className="signup-submit-btn"
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={generateOtpLoading}
                  sx={{ borderRadius: "12px", p: 1 }}
                  data-testid="request-otp-btn"
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {generateOtpLoading ? `Loading... ` : "Request OTP"}
                  {generateOtpLoading ? (
                    <CircularProgress size={20} sx={{ marginLeft: 1 }} />
                  ) : null}
                </Button>
              </Grid>
            )}

            {requestOtp && (
              <>
                <Grid xs={12}>
                  <InputField
                    label="Enter OTP"
                    required
                    value={otp}
                    onChange={(e) => {
                      setOtp(e?.target?.value);
                    }}
                    selectStylesOverride={stylesOverride}
                  />
                  {isCounting && (
                    <span className="font-sz-12 text-gray mx-auto">{`Resend OTP in ${countdown} second${
                      countdown !== 1 ? "s" : ""
                    }`}</span>
                  )}
                </Grid>

                <div
                  className="d-flex justify-content-center"
                  style={{ gap: "0.6rem", width: "100%" }}
                >
                  <Button
                    fullWidth
                    disabled={isCounting}
                    sx={{ p: 1 }}
                    onClick={handleResendOTP}
                  >
                    {generateOtpLoading ? "Loading..." : "Resend OTP"}
                    {generateOtpLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ marginLeft: 1, color: "rgb(255, 114, 0)" }}
                      />
                    ) : null}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ borderRadius: "12px", p: 1 }}
                    disabled={!otp || otp?.length < 6}
                    onClick={handleVerifyOtp}
                  >
                    {verifyOtpLoading ? "Loading..." : "Submit"}
                    {verifyOtpLoading ? (
                      <CircularProgress size={18} sx={{ marginLeft: 1 }} />
                    ) : null}
                  </Button>
                </div>
              </>
            )}

            <Grid item sx={12}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography
                  component={"p"}
                  sx={{ mt: 2, mb: 2, fontWeight: 700, color: "#777 " }}
                  align="left"
                >
                 Already have an account?{" "}
                </Typography>
                <Typography
                  component="p"
                  sx={{
                    mt: 2,
                    mb: 2,
                    fontWeight: 700,
                    color: "#FF7200",
                    cursor: "pointer",
                  }}
                  align="left"
                  onClick={(e) => {
                    e?.preventDefault();
                    history?.push("/");
                  }}
                >
                  Sign In.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </form>
      </section>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
    </Grid>
  );
};

export default Signup;
