import { dateTimeFormater, convertIntoGivenTimezone } from "../../../services/functions";
import moment from "moment";
import history from "../../../services/history";
import { fetchAddressFromCoords } from "../../../redux/actions";
import imgpin from "../../../static/images/drawer/imgpin.png"
import { sendCustomEventAnalytics } from "../../../utils/util";

const fnTripId = (pKey, pLabel, objFamily, index, handleSaveFiltersForStore) => {
  if (objFamily["tripId"]) {

    let spanId = `tripid-${index}`;
    let spanHtml = ''

    const status = objFamily["status"];
    let backgroundColor;
    switch (status) {
      case "mapped":
        backgroundColor = "#fbbc04";
        break;
      case "created":
        backgroundColor = "#ff006f";
        break;
      case "initiated":
        backgroundColor = "#ff006f";
        break;
      case "in_transit":
        backgroundColor = "#34a853";
        break;
      case "via":
        backgroundColor = "#34a853";
        break;
      case "reached":
        backgroundColor = "#ff7200";
        break;
      case "completed":
        backgroundColor = "#989898";
        break;
      default:
        backgroundColor = "";
    }

    if (objFamily["tripId"] && objFamily["tripId"] !== undefined && objFamily && objFamily?.detailsDeviceID) {
      spanHtml = `<span id='${spanId}' class="tripIdBox" style="background: ${backgroundColor};">${objFamily["tripId"]}</span>`;
    }
    else {
      spanHtml = `<span  class="tripIdBox" style="background: ${backgroundColor}; cursor: default;">${objFamily["tripId"]}</span>`;
    }
    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          sendCustomEventAnalytics("trips", "Trip Map Page", "Trip Map Page")
          handleSaveFiltersForStore();
          history.push(`/dashboard/trips/trip-map/${objFamily._id}?id=${objFamily?.detailsDeviceID?.deviceID}`)
        }
      }

    }, 50)


    if (objFamily["isTripWithoutRoute"]) {
      spanHtml = `<span id='${spanId}' class="tripIdBox" style="background: ${backgroundColor};">${objFamily["tripId"]}</span><br/>`;
      spanHtml += `<span class="withoutRoute_minDis">(Trip Without Route)</span>`;
    }

    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          sendCustomEventAnalytics("trips", "Trip Map Page", "Trip Map Page")
          handleSaveFiltersForStore();
          history.push(`/dashboard/trips/trip-map/${objFamily._id}?id=${objFamily.detailsDeviceID.deviceID}`)
        };
      }
    }, 50)

    return spanHtml;
  }
  else {
    return '-';
  }
}
const fnTripCreatedOn = (pKey, pLabel, objFamily, args = {}, userPermissions) => {
  try {

    if (objFamily["addedBy"]["name"] && objFamily["addedOn"] && objFamily["addedBy"]["name"] !== undefined && objFamily["addedOn"] !== undefined) {
      return `<span >Trip Created on <br/><b>${convertIntoGivenTimezone(objFamily["addedOn"], userPermissions.timeZone.offset)}</b> by <br/><b>${objFamily["addedBy"]["name"]}</b> </span>`
    } else if (!!objFamily["addedBy"]["name"] && !!objFamily["addedOn"] === false) {
      return `<span >Trip Created by <br/><b>${objFamily["addedBy"]["name"]}</b> </span>`
    } else {
      return '-'
    }
  }
  catch (error) {
    return '-';
  }
}
const fnOrigin = (pKey, pLabel, objFamily, { }, userPermissions) => {
  let rtrnStr = "";
  let origin = objFamily?.route?.routeSource?.name || ''


  if (!objFamily["tripDetails"]["isDeviceIDLinked"]) {
    rtrnStr += `<span>${objFamily?.route?.routeSource?.name}</span>`;
  }

  if (!objFamily["tripDetails"]["isDeviceIDLinked"] && objFamily["minDisFromSrc"] && objFamily["minDisFromSrc"] != "") {
    rtrnStr += `<div class="toolCus">
      <i class="fa fa-info" style="color: #ff7700;cursor: pointer;"></i>
      <span class="tooltiptextCus">${objFamily["minDisFromSrc"]} meters away from source geofence - ${objFamily?.route?.routeSource?.name}</span>
  </div>`;
  }

  if (objFamily["tripDetails"]["isDeviceIDLinked"]) {
    // rtrnStr += `<span>${objFamily?.route?.routeSource?.name}</span> &nbsp;`;
    if (origin?.length > 25) {
      let shortText = origin?.substring(0, 25) + "...";
      rtrnStr += `<div class="tooltip-container">
                      <span style="cursor: pointer; font-size:13px;">${shortText}</span>
                      <span class="tooltip-content">${objFamily?.route?.routeSource?.name || "-"}</span>
                  </div>`;
    } else {
      rtrnStr += `<span style="cursor: pointer; font-size:13px;">${objFamily?.route?.routeSource?.name || "-"}</span>`;
    }

    if (objFamily["tripDetails"]["isDeviceIDLinked"] && objFamily["minDisFromSrc"] && objFamily["minDisFromSrc"] != "") {
      rtrnStr += ` <div class="toolCus">
          <i class="fa fa-info" style="color: #ff7700;cursor: pointer;"></i>
          <span class="tooltiptextCus">${objFamily["minDisFromSrc"]} meters away from source geofence - ${objFamily?.route?.routeSource?.name || "-"}</span>
      </div><br/>`;
    }

    if (objFamily["detailsDeviceID"] && objFamily["detailsDeviceID"]["assetType"] && objFamily["detailsDeviceID"]["assetType"] == "elock" &&
      objFamily["detailsDeviceID"]["time"] &&
      objFamily["detailsDeviceID"]["sealedBy"]
    ) {
      let sealedDate = convertIntoGivenTimezone(objFamily["detailsDeviceID"]["time"], userPermissions.timeZone.offset, "DD/MM/YYYY")
      let sealedTime = convertIntoGivenTimezone(objFamily["detailsDeviceID"]["time"], userPermissions.timeZone.offset, "hh:mm:ss A")
      rtrnStr += `<span>
              Sealed on <b>${sealedDate}</b><br/> at <b>${sealedTime}</b> by <b>${objFamily["detailsDeviceID"]["sealedBy"]}</b>
          </span>`;
    }

    if (
      objFamily["detailsDeviceID"] && objFamily["detailsDeviceID"]["assetType"] && (objFamily["detailsDeviceID"]["assetType"] == "asset" || objFamily["detailsDeviceID"]["assetType"] == "fixed") &&
      objFamily["detailsDeviceID"]["time"] &&
      objFamily["detailsDeviceID"]["sealedBy"]
    ) {
      let sealedDate = convertIntoGivenTimezone(objFamily["detailsDeviceID"]["time"], userPermissions.timeZone.offset, "DD/MM/YYYY")
      let sealedTime = convertIntoGivenTimezone(objFamily["detailsDeviceID"]["time"], userPermissions.timeZone.offset, "hh:mm:ss A")
      rtrnStr += `<span>
              Trip Started on <b>${sealedDate}</b><br/> at <b>${sealedTime}</b> by <b>${objFamily["detailsDeviceID"]["sealedBy"]}</b>
          </span>`;
    }


  }

  return rtrnStr || '-';
}
const fnTripStatus = (pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions) => {
  try {
    let tripStatus = '-';
    if (!objFamily["eventData"] || (objFamily["eventData"] && !objFamily["eventData"]["event"])) {

      if (objFamily["detailsDeviceID"] && objFamily["detailsDeviceID"]["assetType"] && objFamily["detailsDeviceID"]["assetType"] == "elock") {
        const elockData = processElock(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
        return elockData;
      }
      else {
        const trackerData = processTracker(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
        return trackerData;
      }

    }

    if (objFamily["eventData"] && objFamily["eventData"]["event"]) {
      if (
        objFamily["detailsDeviceID"] &&
        objFamily["detailsDeviceID"]["assetType"] &&
        objFamily["detailsDeviceID"]["assetType"] == 'elock'
      ) {
        return processElockEvent(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
      }
      else {
        return processTrackerEvent(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
      }
    }

    return tripStatus;
  } catch (error) {
    return '-';
  }
}
function processElock(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions) {
  let rtrnStr = '';
  if (
    objFamily["status"] &&
    objFamily["status"] === "via" &&
    objFamily["eventData"] && objFamily["eventData"]["geoType"] == "viapoints"
  ) {
    rtrnStr += ` <span > Inside Viapoint - <b>${objFamily["eventData"]["geoName"]}</b> </span>`;
  }
  if (
    objFamily["isUnsealed"] == true &&
    objFamily["status"] == "completed" &&
    (!objFamily["tampered"] || (objFamily["tampered"] && objFamily["tampered"] == "backcover")) &&
    (objFamily["unsealingDetails"] && (!objFamily["unsealingDetails"]["reason"])) &&
    (!objFamily["tripClosure"] || (objFamily["tripClosure"] && objFamily["tripClosure"] == 'firstUnlock')) &&
    (objFamily["status"] == "completed")
  ) {
    /*  "trip?.route && trip?.route?.routeDestination && trip?.route?.routeDestination?.name!='' */
    if (
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"] !== ""
    ) {
      rtrnStr += `<span >Destination :
          ${objFamily["route"]["routeDestination"]["name"]}</span> <br/>`;
    }

    /*"trip.isUnsealed==true && (trip.webUnlock || trip.webUnlockDetails); then webUnlock; else otherunlock" */

    // webunlock  or  other unlock      

    if (
      objFamily["isUnsealed"] == true &&
      (objFamily["webUnlock"] || objFamily["webUnlockDetails"])
    ) {
      // webunlock            

      // TIMZONE logic goes here
      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

      let unlockNameDetails = (objFamily["webUnlock"] && (!objFamily["webUnlockDetails"]) && objFamily["webUnlock"]["name"]) ? objFamily["webUnlock"]["name"] : (!objFamily["webUnlock"] && objFamily["webUnlockDetails"] && objFamily["webUnlockDetails"]["name"]) ? objFamily["webUnlockDetails"]["name"] : "";


      rtrnStr += `<span style="color: green;">Authorised</span><br/> unsealing
          on
          <br>          
          <b>${endTime}</b> by`;

      rtrnStr += `<span>
              <b>${unlockNameDetails}</b> </span>           
          &nbsp;
          <i class="fa fa-globe globe" aria-hidden="true"></i>`;

    } else {
      // otherunlock -> Either Via - Bluetooth or Rfid

      //BT
      if (objFamily["isBluetoothUnlocked"]) {

        let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
        let btName = objFamily["bluetoothUnlocked"]["name"] || "";
        rtrnStr += `<span>
              <span style="color: green;">Authorised</span><br/> 
              unsealing on
              <br>
              <b>${endTime}</b> by
              <b>${btName}</b>&nbsp;
              <i class="fa fa-bluetooth blue"></i>
          </span>`;
      } else {
        //RFID -> Either RFID : true | false
        // (!trip?.reportReason || trip?.reportReason=='RFID') && trip.rfid != '-' && trip.rfid!='00000000' 
        if (
          !objFamily["reportReason"] || (objFamily["reportReason"] == "RFID") &&
          objFamily["rfid"] !== "-" &&
          objFamily["rfid"] !== '00000000'
        ) {
          // RFID Unlock : true
          let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

          let rfidCardDetails = (objFamily["unsealingDetails"]["name"] && objFamily["unsealingDetails"]["name"] == "unknown") ? `RFID  Card no. <b>${objFamily["rfid"]}</b>` : `<span>
                  <b>${objFamily["unsealingDetails"]["name"]} </b>
                  using RFID card no. <b>${objFamily["rfid"]}</b>
              </span>`;

          rtrnStr += ` <span style="color: green;">Authorised</span><br/> 
                  unsealing on
                  <br>
                  <b>${endTime}</b> by ${rfidCardDetails}`;
        } else {
          let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

          rtrnStr += `<span style="color: green;">Authorised</span><br/> 
                  unsealing
                  <br>
                  <span>
                      on <b>${endTime}</b>
                  </span>`;
        }

      }


    }

  }

  //  <!-- Unauthorised -->
  /*
  "trip.isUnsealed==true && trip.status=='completed' && trip?.tampered && trip.tampered!='backcover' && !trip.unsealingDetails?.reason && (!trip?.tripClosure || (trip?.tripClosure && trip?.tripClosure=='firstUnlock' && trip?.status=='completed'))"
  */
  else if (
    objFamily["isUnsealed"] == true &&
    objFamily["status"] == "completed" &&
    (objFamily["tampered"] && objFamily["tampered"] != "backcover") &&
    (objFamily["unsealingDetails"] && (!objFamily["unsealingDetails"]["reason"])) &&
    (!objFamily["tripClosure"] || (objFamily["tripClosure"] && objFamily["tripClosure"] == 'firstUnlock')) &&
    (objFamily["status"] == "completed")
  ) {
    /*
    "trip.isUnsealed==true && (trip.webUnlock || trip.webUnlockDetails); then webUnlock; else otherunlock"
     */

    // webunlock  or  other unlock
    if (
      objFamily["isUnsealed"] == true &&
      (objFamily["webUnlock"] || objFamily["webUnlockDetails"])
    ) {
      // webunlock
      if (
        objFamily["route"] &&
        objFamily["route"]["routeDestination"] &&
        objFamily["route"]["routeDestination"]["name"] &&
        objFamily["route"]["routeDestination"]["name"] !== ""
      ) {
        rtrnStr += `<span >Destination :
              ${objFamily["route"]["routeDestination"]["name"]}</span> <br/>`;
      }

      if (objFamily["unauthorisedUnsealingTime"]) {
        rtrnStr += ` <span style="color: red;">Unauthorised</span>`;
      }

      if (!objFamily["unauthorisedUnsealingTime"]) {
        rtrnStr += `  <span style="color: red">Tampered  Trip </span><span> unsealed on </span>`;
      }

      // "(trip?.unauthorisedUnsealingTime && trip?.tamperedAddress && trip?.tamperedAddress != 'NA')"
      if (
        objFamily["unauthorisedUnsealingTime"] &&
        objFamily["tamperedAddress"] &&
        objFamily["tamperedAddress"] !== 'NA'
      ) {

        rtrnStr += `<div  class="tooltipCustom">unsealing on
              <span class="tooltiptext">
                  ${objFamily["tamperedAddress"]}
              </span>
              </div>`;
      }

      if (
        objFamily["unauthorisedUnsealingTime"] &&
        objFamily["tamperedAddress"] &&
        objFamily["tamperedAddress"] !== 'NA'
      ) {
        let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
        rtrnStr += `<div>
              <b>${endTime}</b>
              </div>`;
      }
      if (
        !objFamily["unauthorisedUnsealingTime"] &&
        objFamily["tamperedAddress"] &&
        objFamily["tamperedAddress"] !== 'NA'
      ) {
        let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
        rtrnStr += `<div>
              <b>${endTime}</b>
              </div>`;
      }

      if (
        objFamily["unauthorisedUnsealingTime"] &&
        objFamily["tamperedAddress"] &&
        objFamily["tamperedAddress"] == 'NA'
      ) {
        let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
        rtrnStr += `<div>
              <b>${endTime}</b>
              </div>`;
      }

      if (objFamily["unauthorisedUnsealingTime"]) {
        rtrnStr += ` <span>by</span>`;
      }

      if (
        objFamily["unauthorisedUnsealingTime"] &&
        objFamily["webUnlock"] &&
        !objFamily["webUnlockDetails"]
      ) {
        rtrnStr += ` <span>
              <b>${objFamily["webUnlock"]["name"]}</b> </span>`;
      }

      if (
        objFamily["unauthorisedUnsealingTime"] &&
        objFamily["webUnlock"] &&
        objFamily["webUnlockDetails"]
      ) {
        rtrnStr += ` <span>
              <b>${objFamily["webUnlockDetails"]["name"]}</b> </span>`;
      }

      rtrnStr += `&nbsp;
        <i class="fa fa-globe globe" aria-hidden="true"></i>`;

    } else {
      //other unlock , either - BT or RFID

      // BT
      //" trip.isBluetoothUnlocked==true; then bluetoothUnlock; else rfidUnlock"
      if (objFamily["isBluetoothUnlocked"] == true) {
        // "trip?.route && trip?.route?.routeDestination && trip?.route?.routeDestination?.name!=''"

        if (
          objFamily["route"] &&
          objFamily["routeDestination"] &&
          objFamily["routeDestination"]["name"] &&
          objFamily["routeDestination"]["name"] != ""
        ) {
          rtrnStr += `<span >Destination : ${objFamily["routeDestination"]["name"]}</span><br/>`;
        }

        if (!objFamily["unauthorisedUnsealingTime"]) {
          rtrnStr += `<span style="color: red">Tampered Trip </span>  <span> unsealed on </span>`;
        }

        if (objFamily["unauthorisedUnsealingTime"]) {

          let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

          rtrnStr += `  <span style="color: red;">Unauthorised</span><span>unsealing on </span><span><b>${endTime}</b> by
                  <b>${objFamily["bluetoothUnlocked"]["name"]}</b></span>`;
        }

        if (
          !objFamily["unauthorisedUnsealingTime"] &&
          !objFamily["tamperedAddress"] ||
          objFamily["tampered"] == 'NA'
        ) {
          rtrnStr += `<span>NA</span>`;
        }

        rtrnStr += `  &nbsp;
              <i class="fa fa-bluetooth blue"></i>`;

      } else {
        // RFID 
        // "(!trip?.reportReason || trip?.reportReason=='RFID') && trip.rfid != '-' && trip.rfid!='00000000'
        // RFID unlock - TRUE
        if (
          (!objFamily["reportReason"] || (objFamily["reportReason"] && objFamily["reportReason"] == "RFID")) &&
          (objFamily["rfid"] !== '-') &&
          (objFamily["rfid"] !== '00000000')
        ) {
          // rfidUnlockTrue
          // "trip?.route && trip?.route?.routeDestination && trip?.route?.routeDestination?.name!=''"
          if (
            objFamily["route"] &&
            objFamily["routeDestination"] &&
            objFamily["routeDestination"]["name"] &&
            objFamily["routeDestination"]["name"] != ""
          ) {
            rtrnStr += `<span >Destination : ${objFamily["routeDestination"]["name"]}
                      </span> <br/>`;
          }

          if (!objFamily["unauthorisedUnsealingTime"]) {
            rtrnStr += `<span style="color: red">Tampered Trip</span> <span> unsealed on </span>`;
          }

          if (objFamily["unauthorisedUnsealingTime"]) {
            rtrnStr += `<span style="color: red;">Unauthorised</span><span> unsealing on </span>`;
          }

          if (
            !objFamily["unauthorisedUnsealingTime"] &&
            objFamily["tamperedAddress"] &&
            objFamily["tamperedAddress"] !== 'NA'
          ) {
            let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
            rtrnStr += ` <span><b>${endTime}</b></span>`;
          }

          if (
            !objFamily["unauthorisedUnsealingTime"] &&
            !objFamily["tamperedAddress"] ||
            objFamily["tampered"] == 'NA'
          ) {
            rtrnStr += `<span>NA</span>`;
          }

          if (
            objFamily["unauthorisedUnsealingTime"]
          ) {
            let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
            rtrnStr += ` <b>${endTime}</b> <span>by </span>`;
          }

          if (
            objFamily["unauthorisedUnsealingTime"] &&
            objFamily["unsealingDetails"] &&
            objFamily["unsealingDetails"]["name"] &&
            objFamily["unsealingDetails"]["name"] == 'unknown'
          ) {

            rtrnStr += `<span>RFID Card no. <b>${objFamily["rfid"]}</b></span>`;
          }

          if (
            objFamily["unauthorisedUnsealingTime"] &&
            objFamily["unsealingDetails"] &&
            objFamily["unsealingDetails"]["name"] &&
            objFamily["unsealingDetails"]["name"] != 'unknown'
          ) {
            rtrnStr += `<span>
                      <b>${objFamily["unsealingDetails"]["name"]} </b>
                      using RFID card no. <b>${objFamily["rfid"]}</b>
                  </span>`;
          }

        } else {
          // RFID unlock - FALSE
          if (
            objFamily["tampered"] == 'outside' &&
            objFamily["route"]["routeDestination"] &&
            objFamily["route"]["routeDestination"]["name"] != ""
          ) {
            rtrnStr += `    <span >Destination : ${objFamily["route"]["routeDestination"]["name"]}
                      </span> <br/>`;
          }

          if (objFamily["unauthorisedUnsealingTime"]) {
            rtrnStr += `<span style="color: red;">Unauthorised </span>`;
          }

          if (!objFamily["unauthorisedUnsealingTime"]) {
            rtrnStr += `<span style="color: red;">Tampered Trip </span> <span>  unsealed on</span><br/>`;
          }

          if (
            objFamily["tamperedAddress"] &&
            objFamily["tamperedAddress"] != 'NA' &&
            !objFamily["unauthorisedUnsealingTime"]
          ) {
            let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
            rtrnStr += `   <span><b> ${endTime}</b></span>`;

          }

          if (
            objFamily["tamperedAddress"] &&
            objFamily["tamperedAddress"] != 'NA' &&
            objFamily["unauthorisedUnsealingTime"]
          ) {
            let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
            rtrnStr += `   <div class="tooltipCustom">unsealing on
                      <span class="tooltiptext">
                          ${objFamily["tamperedAddress"]}
                      </span>
                  </div><div><b>${endTime}</b></div>`;
          }

          if (
            !objFamily["tamperedAddress"] ||
            objFamily["tamperedAddress"] == 'NA'
          ) {
            rtrnStr += `  <span>NA</span>`;
          }

        }
      }
    }

  }

  // <!-- Forcefully -->

  /*
  "trip.isUnsealed==true && trip.status=='completed' && trip.tampered && trip.tampered!='backcover' && trip.unsealingDetails?.reason"
  */
  else if (
    objFamily["isUnsealed"] == true &&
    objFamily["status"] == "completed" &&
    (objFamily["tampered"] && objFamily["tampered"] != "backcover") &&
    (objFamily["unsealingDetails"] && (!objFamily["unsealingDetails"]["reason"]))
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    rtrnStr += `<span style="color: red">Tampered Trip </span> Forcefully closed on <br>`;



    rtrnStr += `<b>${endTime}</b>  <b>${objFamily["unsealingDetails"]["name"]}</b>`;
  }
  /*
  "trip.isUnsealed==true && trip.status=='completed' && (!trip?.tampered || trip?.tampered=='backcover') && trip.unsealingDetails?.reason"
  */
  else if (
    objFamily["isUnsealed"] == true &&
    objFamily["status"] == "completed" &&
    (!objFamily["tampered"] || objFamily["tampered"] == "backcover") &&
    (objFamily["unsealingDetails"] && (objFamily["unsealingDetails"]["reason"]))
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    let remark = objFamily?.tripForcefullyClosed?.remark || ''

    rtrnStr += `<span style="color: red">Trip Forcefully</span> closed on
      <br>
      <b>${endTime}</b> by <br/>
      <b>${objFamily["unsealingDetails"]["name"]}</b>`;

    {

      rtrnStr += remark !== '' ? `<div class="tooltip-container">
        <div class="outlined-theme-btn outlined-theme-btn-add-ons" ><i class="fa fa-info" style="color: #808080;" aria-hidden="true"></i> </div>
          <span class="tooltip-content">${remark}</span>
        </div>`   : ''

    }

  }


  // <!-- Normal -->

  /*
  "trip.tampered && trip.tampered!='backcover' && trip.status != 'completed'; then tampered; else notTampered"
  */

  else if (
    objFamily["tampered"] &&
    objFamily["tampered"] != 'backcover' &&
    objFamily["status"] != 'completed'
  ) {
    // Tampered
    rtrnStr += ` <span style="color: red;">Tampered</span> at <br>`;
    if (
      objFamily["tamperedAddress"] &&
      objFamily["tamperedAddress"] != 'NA'
    ) {
      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
      rtrnStr += `<b>${objFamily["tamperedAddress"]}</b> on <b> ${endTime}</b>`;
    }
    // "!trip.tamperedAddress || trip.tampered == 'NA' "
    if (
      !objFamily["tamperedAddress"] ||
      (objFamily["tamperedAddress"] && objFamily["tamperedAddress"] == "NA")
    ) {
      rtrnStr += `<span>NA</span>`;
    }
  } else {
    // not tampered
    /*
    "trip.status=='mapped' && trip?.route?.routeDestination && trip?.route?.routeDestination?.name"
    */
    if (
      objFamily["status"] &&
      objFamily["status"] == "mapped" &&
      (objFamily["route"] && objFamily["route"]["routeDestination"] && objFamily["route"]["routeDestination"]["name"])
    ) {
      rtrnStr += `<span>Awaiting departure to<br/> Destination -
      <b>${objFamily["route"]["routeDestination"]["name"]}</b></span>`;
    }

    /*
    "trip.status=='mapped' && (!trip?.route?.routeDestination || (trip?.route?.routeDestination && !trip?.route?.routeDestination.name))"
     */
    if (
      objFamily["status"] &&
      objFamily["status"] == "mapped" &&
      (objFamily["route"] &&
        (!objFamily["route"]["routeDestination"] ||
          (objFamily["route"]["routeDestination"] &&
            (!objFamily["route"]["routeDestination"]["name"]))
        ))
    ) {
      rtrnStr += ` <span>Awaiting Departure</span>`;
    }

    /*
    "trip.status=='in_transit' && trip?.route?.routeDestination && trip?.route?.routeDestination?.name"
    */
    if (
      objFamily["status"] &&
      objFamily["status"] == "in_transit" &&
      (objFamily["route"]) &&
      (objFamily["route"]["routeDestination"] && objFamily["route"]["routeDestination"]["name"])
    ) {
      rtrnStr += `<span><br/> In Transit to Destination <br/> ${objFamily["route"]["routeDestination"]["name"]}</span>`;
    }
    /*
    "trip.status=='in_transit' && (!trip?.route?.routeDestination || (trip?.route?.routeDestination && !trip?.route?.routeDestination.name))" */

    if (
      objFamily["status"] &&
      objFamily["status"] == "in_transit" &&
      (objFamily["route"]) &&
      (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"])
    ) {
      rtrnStr += `<span>In Transit</span>`;
    }
    /*
    "trip.status=='initiated' && trip?.route?.routeDestination && trip?.route?.routeDestination?.name"
    */
    if (
      objFamily["status"] &&
      objFamily["status"] == "initiated" &&
      (objFamily["route"]) &&
      (objFamily["route"]["routeDestination"] && objFamily["route"]["routeDestination"]["name"])
    ) {
      rtrnStr += `<span>Destination : ${objFamily["route"]["routeDestination"]["name"]}</span>`;
    }

    if (objFamily["status"] == 'reached') {

      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

      rtrnStr += `<span>Arrived ${objFamily["route"]["routeDestination"]["name"]}
      <br />at <b>${endTime}</b></span>`;
    }
    /*
    "trip.status=='completed' && !trip?.tripForcefullyClosed && trip?.tripClosure && trip?.tripClosure!='firstUnlock'"
    */
    if (
      objFamily["status"] == 'completed' &&
      objFamily["tripForcefullyClosed"] &&
      (objFamily["tripClosure"] && objFamily["tripClosure"] != 'firstUnlock')
    ) {

      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

      rtrnStr += ` <span style="color: green;">Trip Completed</span><br/> on <span><b>${endTime}</b></span>`;

    }
  }

  return rtrnStr;
}
function processTracker(pKey, pLabel = '', objFamily, args = {}, userPermissions) {
  let rtrnStr = '';
  //  <!-- Forcefully -->
  /*
  "trip.status=='completed' && trip.unsealingDetails?.reason"
   */
  if (
    objFamily["status"] &&
    objFamily["status"] === "via" &&
    objFamily["eventData"] && objFamily["eventData"]["geoType"] == "viapoints"
  ) {
    rtrnStr += ` <span > Inside Viapoint - <b>${objFamily["eventData"]["geoName"]}</b> </span>`;
  }
  if (
    objFamily["status"] &&
    objFamily["status"] == 'completed' &&
    objFamily["unsealingDetails"] &&
    objFamily["unsealingDetails"]["reason"]
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    let remark = objFamily?.tripForcefullyClosed?.remark || ''
    rtrnStr += ` <span style="color: red">Trip Forcefully</span> closed on
          <br>
          <b>${endTime}</b> by <br/>
          <b>${objFamily["unsealingDetails"]["name"]}</b>`;

    {

      rtrnStr += remark !== '' ? `<div class="tooltip-container">
            <div class="outlined-theme-btn outlined-theme-btn-add-ons" ><i class="fa fa-info" style="color: #808080;" aria-hidden="true"></i> </div>
              <span class="tooltip-content">${remark}</span>
            </div>`   : ''

    }
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'completed' &&
    objFamily["unsealingDetails"] &&
    !objFamily["unsealingDetails"]["reason"]
  ) {

    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    if (
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"] != ""
    ) {
      rtrnStr += ` <span >Destination : ${objFamily["route"]["routeDestination"]["name"]}</span><br/>`;
    }

    if (
      objFamily["detailsDeviceID"] &&
      objFamily["detailsDeviceID"]["assetType"] &&
      objFamily["detailsDeviceID"]["assetType"] == 'elock'
    ) {
      rtrnStr += `  <span style="color: green;">Trip unsealed</span>`;
    }

    if (
      objFamily["detailsDeviceID"] &&
      objFamily["detailsDeviceID"]["assetType"] &&
      (objFamily["detailsDeviceID"]["assetType"] == 'asset' || objFamily["detailsDeviceID"]["assetType"] == 'fixed')
    ) {
      rtrnStr += `<span style="color: green;">Trip Completed</span>`;
    }

    rtrnStr += `on <b>${endTime}</b>`;

  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'mapped' &&
    objFamily["route"] &&
    objFamily["route"]["routeDestination"]["name"]
  ) {
    rtrnStr += ` <span>Awaiting departure to<br/> Destination ${objFamily["route"]["routeDestination"]["name"]}</span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'mapped' &&
    objFamily["route"] &&
    (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
  ) {
    rtrnStr += `<span>Awaiting Departure</span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'in_transit' &&
    objFamily["route"] &&
    objFamily["route"]["routeDestination"] &&
    objFamily["route"]["routeDestination"]["name"]
  ) {
    rtrnStr += `<span><br/> In Transit to Destination<br/> ${objFamily["route"]["routeDestination"]["name"]}</span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'in_transit' &&
    objFamily["route"] &&
    (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
  ) {
    rtrnStr += `<span>In Transit</span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'initiated'
  ) {
    rtrnStr += `<p class="withoutRoute_minDis" style="padding-top: 19px;"> Not Affixed Yet </p>  `
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'reached'
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    rtrnStr += `<span>Arrived ${objFamily["route"]["routeDestination"]["name"]} <br />at<b>${endTime}</b></span>`;
  }
  return rtrnStr;
}
const handleFetchAdress = (data, dispatch) => {
  if (data) {
    const payload = {
      latitude: data?.lat,
      longitude: data?.long,
      appType: "GOOGLE",
    };

    dispatch(fetchAddressFromCoords(payload));
  }
};
function processElockEvent(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions) {
  let rtrnStr = '';

  // if (objFamily["status"] && objFamily["status"] === "via" && objFamily["eventData"] && objFamily["eventData"]["geoType"] == "viapoints") {
  //   rtrnStr += ` <span > Inside Viapoint - <b>${objFamily["eventData"]["geoName"]}</b> </span>`;
  // }
  if (objFamily["status"] && objFamily["status"] === "via" && objFamily["eventData"] && objFamily["eventData"]["geoType"] && objFamily["eventData"]["geoType"] === 'viapoints') {
    let destName = objFamily["route"]["routeDestination"]['name'] || ''
    rtrnStr += `<span><br/> In Transit  to Destination -<br/> <b>${destName}</b></span>`;
  }
  if (objFamily["status"] && objFamily["status"] === "in_transit" && objFamily["eventData"] && objFamily["eventData"]["geoType"] && objFamily["eventData"]["geoType"] === 'viapoints') {
    let destName = objFamily["route"]["routeDestination"]['name'] || ''
    rtrnStr += `<span><br/> In Transit  to Destination -<br/> <b>${destName}</b></span>`;
  }


  if (objFamily?.status === "completed" && objFamily?.eventData?.event !== 'tampered' && objFamily?.isUnsealed && objFamily?.eventData?.geoType === 'destination') {


    let endTime = processTimeZone(objFamily["endTime"], userPermissions.timeZone.offset)

    rtrnStr += `<span style="color: green"> Authorised unsealing  </span>`;
    rtrnStr += `<span><br/>on <b>${endTime}</b></span>`;
    return rtrnStr;
  }


  if (objFamily["eventData"] && (objFamily["eventData"]["event"].includes('unlocked') || objFamily["eventData"]["event"].includes('unlocking_request') || objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) &&
    objFamily["eventData"]["geoType"] && objFamily["eventData"]["geoType"] == "destination" && (!objFamily["tripClosure"] || (objFamily["tripClosure"] && objFamily["tripClosure"] == 'firstUnlock' && objFamily["status"] == 'completed'))
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

    if (
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"] &&
      objFamily["route"]["routeDestination"]["name"] != ""
    ) {
      rtrnStr += `<span >Destination : ${objFamily["route"]["routeDestination"]["name"]} </br></span>`;
    }

    if (objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) {
      rtrnStr += ` <span> <span style="color: red;">Tampered </span>Trip Unsealed at </span>`;
    }

    if (!objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) {
      rtrnStr += `<span><span style="color: green; font-weight:600;">Authorised</span> unsealing  </span>`;
    }

    if (objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) {
      rtrnStr += ` <div>
          <span
              style="color:#4CAF50;text-decoration: underline;cursor:pointer;"
              >${objFamily["eventData"]["latitude"]}/${objFamily["eventData"]["longitude"]}</span>
          <img style='display:none;'
              src='assets/img/loadingondashboardgrid.gif' />
      </div>`;

    }
    rtrnStr += ` on <br/> <b>${endTime}</b>`;

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] &&
      objFamily["eventData"]["reportReason"] == 'web_unlocking'
    ) {
      rtrnStr += `<span>
          by <b>${objFamily["eventData"]["userName"]}</b>&nbsp;<i
              class="fa fa-globe globe" aria-hidden="true"></i>
      </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] &&
      objFamily["eventData"]["reportReason"] == 'bt_unlocking'
    ) {
      rtrnStr += `<span>
          by <b>${objFamily["eventData"]["userName"]}</b>&nbsp;<i
              class="fa fa-globe globe" aria-hidden="true"></i>
      </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] &&
      objFamily["eventData"]["reportReason"] == 'Rfid_unlocked'
    ) {
      rtrnStr += `<span>using RFID Card no. <b>${objFamily["eventData"]["value"]}</b>
      </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] &&
      objFamily["eventData"]["reportReason"] == 'SMS'
    ) {
      rtrnStr += ` <span> by SMS </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] &&
      objFamily["eventData"]["reportReason"] == 'passcode_unlocked'
    ) {
      rtrnStr += ` <span><br/> by PASSCODE </span>`;
    }
    // if(objFamily?.status === "completed" && objFamily?.eventData?.event !== 'tampered' && objFamily?.isUnsealed && objFamily?.eventData?.geoType === 'destination'){


    //   let endTime = processTimeZone(objFamily["endTime"], userPermissions.timeZone.offset)

    //   rtrnStr += `<span style="color: green"> Authorised unsealing </span>`;
    //   rtrnStr += `<span><br/>on <b>${endTime}</b></span>`;
    //   return rtrnStr;
    // }
  }

  if (objFamily?.status === "completed" && objFamily?.eventData?.event === 'motorUnlocked' && objFamily?.isUnsealed) {


    let endTime = processTimeZone(objFamily["endTime"], userPermissions.timeZone.offset)

    rtrnStr += `<span> Trip Completed on </span>`;
    rtrnStr += `<span><br/>on <b>${endTime}</b></span>`;
    // return rtrnStr;
  }


  if (
    objFamily["eventData"] &&
    objFamily["eventData"]["event"] &&
    (objFamily["eventData"]["event"].includes('unlocked') || objFamily["eventData"]["event"].includes('unlocking_request') || objFamily["eventData"]["event"].includes('Tampered_trip_unsealed'))
    &&
    objFamily["eventData"]["geoType"] &&
    objFamily["eventData"]["geoType"] != "destination" &&
    objFamily["eventData"]["geoType"] != "viapoints" &&
    objFamily["status"] == 'completed' &&
    (!objFamily["tripClosure"] || (objFamily["tripClosure"] && objFamily["tripClosure"] == 'firstUnlock' && objFamily["status"] == 'completed'))
  ) {

    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    const spanId = `btn-${index}`;

    if (
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"] != ""
    ) {
      rtrnStr += `<span >Destination : ${objFamily["route"]["routeDestination"]["name"]} </span><br/>`;
    }

    if (objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) {
      rtrnStr += `  <span> <span style="color: red;">Tampered </span>Trip Unsealed at </span>`;
    }

    if (!objFamily["eventData"]["event"].includes('Tampered_trip_unsealed')) {
      rtrnStr += `<span><span style="color: red;">Unauthorised</span> unsealing at  </span>`;
    }

    rtrnStr += `
    ${fetchedAddress?.address && tableIndex === objFamily ? `<span class='addressContainer'>${fetchedAddress.address}</span>` :
        `<div>
        <span id='${spanId}' style="color:#4CAF50;text-decoration: underline;cursor:pointer;">
          ${objFamily["eventData"]["latitude"]}/${objFamily["eventData"]["longitude"]}
        </span>
      </div>`}
    on <b>${endTime}</b>
  `;


    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          setTableIndex(objFamily);
          let data = {
            lat: objFamily["eventData"]["latitude"],
            long: objFamily["eventData"]["longitude"],
          };
          handleFetchAdress(data, dispatch);
        };
      }
    }, 50)

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] == 'web_unlocking'
    ) {
      rtrnStr += `<span>
              by <b>${objFamily["eventData"]["userName"]}</b>&nbsp;<i
                  class="fa fa-globe globe" aria-hidden="true"></i>
          </span>`;
    }
    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] == 'bt_unlocking'
    ) {
      rtrnStr += `<span>
              by <b>${objFamily["eventData"]["userName"]}</b>&nbsp;<i
              class="fa fa-bluetooth blue"></i>
          </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] == 'Rfid_unlocked'
    ) {
      rtrnStr += ` <span>
              using RFID Card no. <b>${objFamily["eventData"]["value"]}</b> </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] == 'SMS'
    ) {
      rtrnStr += ` <span>by SMS </span>`;
    }

    if (
      objFamily["eventData"] &&
      objFamily["eventData"]["reportReason"] == 'passcode_unlocked'
    ) {
      rtrnStr += ` <span><br/> by PASSCODE </span>`;
    }


  }

  if (
    objFamily["eventData"] &&
    objFamily["eventData"]["event"] &&
    !objFamily["eventData"]["event"].includes('tampered') &&
    !objFamily["eventData"]["event"].includes('tampForcefullyclosed') &&
    !objFamily["eventData"]["event"].includes('forcefully_closure') &&
    !objFamily["eventData"]["geoType"] == 'viapoints'
  ) {

    if (
      objFamily["status"] &&
      objFamily["status"] == 'mapped' &&
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"]
    ) {
      rtrnStr += ` <span>
          <span>Awaiting departure to <br/>  Destination
             ${objFamily["route"]["routeDestination"]["name"]}</span>
      </span>`;
    }

    if (
      objFamily["status"] &&
      objFamily["status"] == 'mapped' &&
      objFamily["route"] &&
      (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"])
      )
    ) {
      rtrnStr += ` <span><span>Awaiting Departure</span></span>`;
    }

    if (
      objFamily["status"] &&
      objFamily["status"] == 'in_transit' &&
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"]
    ) {
      rtrnStr += `  <span><span><br/> In Transit to Destination <br/> ${objFamily["route"]["routeDestination"]["name"]}</span> </span>`;
    }

    if (
      objFamily["status"] &&
      objFamily["status"] == 'in_transit' &&
      objFamily["route"] &&
      (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"])
      )
    ) {
      rtrnStr += `<span><span>In Transit</span></span>`;
    }

    if (
      objFamily["status"] &&
      objFamily["status"] == 'initiated'
    ) {
      rtrnStr += ` <span><span>Destination : ${objFamily["route"]["routeDestination"]["name"]}</span></span>`;
    }
    if (
      objFamily["status"] &&
      objFamily["status"] == 'reached' &&
      objFamily["eventData"] &&
      objFamily["eventData"]["event"] &&
      objFamily["eventData"]["event"] == 'arrived'
    ) {
      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
      rtrnStr += `  <span>
          <span>Arrived  ${objFamily["route"]["routeDestination"]["name"]}
              <br />at <b>${endTime}</b></span></span>`;
    }
    if (
      objFamily["status"] &&
      objFamily["status"] == 'completed' &&
      !objFamily["tripForcefullyClosed"] &&
      objFamily["tripClosure"] &&
      objFamily["tripClosure"] != 'firstUnlock'
    ) {
      let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
      rtrnStr += `  <span>
          <span style="color: green;">Trip Completed</span>
          <br />on
          <span><b>${endTime}</b></span>
      </span>`;
    }

  }

  // <!-- Tampered and normal trip forcefully closed-->

  if (
    objFamily["eventData"] &&
    objFamily["eventData"]["event"] &&
    objFamily["eventData"]["event"].includes('tampForcefullyclosed')
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    let remark = objFamily?.tripForcefullyClosed?.remark || ''

    rtrnStr += ` <span style="color: red"> Tampered Trip Forcefully closed on <br> </span><b>${endTime}</b> by<br/>
      <b>${objFamily["eventData"]["userName"]}</b>`;

    {

      rtrnStr += remark !== '' ? `<div class="tooltip-container">
        <div class="outlined-theme-btn outlined-theme-btn-add-ons" ><i class="fa fa-info" style="color: #808080;" aria-hidden="true"></i> </div>
          <span class="tooltip-content">${remark}</span>
        </div>`   : ''

    }
  }

  if (
    objFamily["eventData"] &&
    objFamily["eventData"]["event"] &&
    objFamily["eventData"]["event"].includes('forcefully_closure')
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    let remark = objFamily?.tripForcefullyClosed?.remark || ''
    rtrnStr = ` <span style="color: red"> Trip Forcefully closed on <br> </span><b>${endTime}</b> by<br/>
      <b>${objFamily["eventData"]["userName"]}</b><br/>`;


    {

      rtrnStr += remark !== '' ? `<div class="tooltip-container">
        <div class="outlined-theme-btn outlined-theme-btn-add-ons" ><i class="fa fa-info" style="color: #808080;" aria-hidden="true"></i> </div>
          <span class="tooltip-content">${remark}</span>
        </div>`   : ''

    }
  }

  if (objFamily["eventData"] && objFamily["eventData"]["event"]) {
    const tamperedId = `tamperedId-${index}`;
    if (objFamily["eventData"]["event"].includes('tampered')) {
      rtrnStr += `
      
    ${fetchedAddress?.address && tableIndex === objFamily ? `<span class='addressContainer'>${fetchedAddress.address}</span>` :
          `<div>
        <span style="color: red"> Tampered </span>
        <span> at </span><br>
        <span id='${tamperedId}' style="color:#4CAF50;text-decoration: underline;cursor:pointer;">
          ${objFamily["eventData"]["latitude"]}/${objFamily["eventData"]["longitude"]}
        </span>
      </div>`}`;
    }
    else {

      // notTampered

      /*
      "!trip?.eventData?.event.includes('unlocking_request') && !trip?.eventData?.geoType.includes('viapoints')"
      */
      if (
        objFamily["eventData"] &&
        objFamily["eventData"]["event"] &&
        !objFamily["eventData"]["event"].includes('unlocking_request') &&
        (objFamily["eventData"]["geoType"] && !objFamily["eventData"]["geoType"].includes('viapoints'))
      ) {

        if (
          objFamily["status"] &&
          objFamily["status"] == 'mapped' &&
          objFamily["route"] &&
          objFamily["route"]["routeDestination"] &&
          objFamily["route"]["routeDestination"]["name"]
        ) {
          rtrnStr += `<span>
              <span>Awaiting departure to<br/> Destination
                  ${objFamily["route"]["routeDestination"]["name"]}</span>
          </span>`;
        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'mapped' &&
          objFamily["route"] &&
          (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
        ) {
          rtrnStr += `<span><span>Awaiting Departure</span></span>`;
        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'in_transit' &&
          objFamily["route"] &&
          objFamily["route"]["routeDestination"] &&
          objFamily["route"]["routeDestination"]["name"]
        ) {
          rtrnStr += ` <span><span><br/> In Transit  to Destination <br/> ${objFamily["route"]["routeDestination"]["name"]}</span> </span>`;
        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'in_transit' &&
          objFamily["route"] &&
          (!objFamily["route"]["routeDestination"] || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
        ) {
          rtrnStr += ` <span>In Transit</span>`;
        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'initiated' &&
          objFamily["route"] &&
          objFamily["route"]["routeDestination"] &&
          objFamily["route"]["routeDestination"]["name"] &&
          objFamily["route"]["routeDestination"]["name"] != ""
        ) {
          rtrnStr += `<span>
                  <span>Destination :
                      ${objFamily["route"]["routeDestination"]["name"]}</span>
              </span>`;

        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'reached' &&
          objFamily["eventData"] &&
          objFamily["eventData"]["event"] &&
          objFamily["eventData"]["event"].includes('reached') &&
          objFamily["route"] &&
          objFamily["route"]["routeDestination"] &&
          objFamily["route"]["routeDestination"]["name"] &&
          objFamily["route"]["routeDestination"]["name"] != ""
        ) {

          let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
          rtrnStr += `<span><span>Arrived ${objFamily["route"]["routeDestination"]["name"]} <br />at <b>${endTime}</b></span> </span>`;
        }

        if (
          objFamily["status"] &&
          objFamily["status"] == 'completed' &&
          !objFamily["tripForcefullyClosed"] &&
          objFamily["tripClosure"] &&
          objFamily["tripClosure"] != 'firstUnlock'
        ) {
          let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
          rtrnStr += `  <span>
                  <span style="color: green;">Trip Completed</span>
                  <br />on
                  <span><b>${endTime}</b></span>
              </span>`;
        }
      }

    }

    setTimeout(() => {
      const spanElement = document.getElementById(tamperedId);
      if (spanElement) {
        spanElement.onclick = () => {
          setTableIndex(objFamily);
          let data = {
            lat: objFamily["eventData"]["latitude"],
            long: objFamily["eventData"]["longitude"],
          };
          handleFetchAdress(data, dispatch);
        };
      }
    }, 50)

  }

  // if (
  //   objFamily["eventData"] &&
  //   objFamily["eventData"]["geoType"] && objFamily["eventData"]["geoType"] === 'viapoints'
  // ) {
  //   let destName = objFamily["route"]["routeDestination"]['name'] || ''
  //   rtrnStr += `<span><br/> In Transit  to Destination - <b>${destName}</b></span>`;
  // }

  // if(objFamily?.status === "completed" && objFamily?.eventData?.event !== 'tampered' && objFamily?.isUnsealed && objFamily?.eventData?.geoType === 'destination'){


  //   let endTime = processTimeZone(objFamily["endTime"], userPermissions.timeZone.offset)

  //   rtrnStr += `<span style="color: green"> Authorised unsealing </span>`;
  //   rtrnStr += `<span><br/>on <b>${endTime}</b></span>`;
  //   return rtrnStr;
  // }

  // if(objFamily?.status === "completed" && objFamily?.eventData?.event !== 'tampered' && objFamily?.isUnsealed){


  //   let endTime = processTimeZone(objFamily["endTime"], userPermissions.timeZone.offset)

  //   rtrnStr += `<span> Trip Completed on </span>`;
  //   rtrnStr += `<span><br/>on <b>${endTime}</b></span>`;
  //   return rtrnStr;
  // }


  return rtrnStr;
}
function processTrackerEvent(pKey, pLabel = '', objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions) {
  let rtrnStr = "";
  //  <!-- Forcefully -->

  if (
    objFamily["status"] &&
    objFamily["status"] === "via" &&
    objFamily["eventData"] && objFamily["eventData"]["geoType"] == "viapoints"
  ) {
    rtrnStr += ` <span > Inside Viapoint - <b>${objFamily["eventData"]["geoName"]}</b> </span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == "completed" &&
    objFamily["unsealingDetails"] &&
    objFamily["unsealingDetails"]["reason"]
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    let remark = objFamily?.tripForcefullyClosed?.remark || ''

    rtrnStr += `<span>
      <span style="color: red">Trip Forcefully</span> closed on 
      <br>
      <b>${endTime}</b> by<br/>
      <b>${objFamily["unsealingDetails"]["name"]}</b>
  </span>`;


    {

      rtrnStr += remark !== '' ? `<div class="tooltip-container">
    <div class="outlined-theme-btn outlined-theme-btn-add-ons" ><i class="fa fa-info" style="color: #808080;" aria-hidden="true"></i> </div>
      <span class="tooltip-content">${remark}</span>
    </div>`   : ''

    }
  }

  //   <!-- Authorised -->
  // "trip.status=='completed' && !trip.unsealingDetails?.reason"
  if (
    objFamily["status"] &&
    objFamily["status"] == 'completed' &&
    (objFamily["unsealingDetails"] && !objFamily["unsealingDetails"]["reason"])
  ) {

    if (
      objFamily["route"] &&
      objFamily["route"]["routeDestination"] &&
      objFamily["route"]["routeDestination"]["name"] &&
      objFamily["route"]["routeDestination"]["name"] != ""
    ) {
      rtrnStr += `<span >Destination : ${objFamily["route"]["routeDestination"]["name"]}</span><br/>`;
    }
    if (
      objFamily["detailsDeviceID"] &&
      objFamily["detailsDeviceID"]["assetType"] &&
      objFamily["detailsDeviceID"]["assetType"] == 'elock'
    ) {
      rtrnStr += `<span style="color: green;">Trip unsealed</span>`;
    }

    if (
      objFamily["detailsDeviceID"] &&
      objFamily["detailsDeviceID"]["assetType"] &&
      (objFamily["detailsDeviceID"]["assetType"] == 'elock' || objFamily["detailsDeviceID"]["assetType"] == 'fixed')
    ) {
      rtrnStr += ` <span style="color: green;">Trip Completed</span>`;
    }

    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)

    rtrnStr += ` on <b>${endTime}</b>`;
  }

  //  <!-- Normal -->

  if (
    objFamily["status"] &&
    objFamily["status"] == 'mapped' &&
    objFamily["route"] &&
    objFamily["route"]["routeDestination"] &&
    objFamily["route"]["routeDestination"]["name"]
  ) {
    rtrnStr += `<span><span>Awaiting departure to<br/> Destination ${objFamily["route"]["routeDestination"]["name"]}</span> </span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'mapped' &&
    (objFamily["route"] && (!objFamily["route"]["routeDestination"]) || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
  ) {
    rtrnStr += ` <span><span>Awaiting Departure</span></span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'in_transit' &&
    objFamily["route"] &&
    objFamily["route"]["routeDestination"] &&
    objFamily["route"]["routeDestination"]["name"]) {

    rtrnStr += `<span> <span><br/> In Transi-6 t to Destination <br/> ${objFamily["route"]["routeDestination"]["name"]}</span></span>`;
  }
  if (
    objFamily["status"] &&
    objFamily["status"] == 'in_transit' &&
    (objFamily["route"] && (!objFamily["route"]["routeDestination"]) || (objFamily["route"]["routeDestination"] && !objFamily["route"]["routeDestination"]["name"]))
  ) {
    rtrnStr += `<span><span>In Transit</span></span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'initiated'
  ) {
    rtrnStr += `<span><span>Destination : ${objFamily["route"]["routeDestination"]["name"]}</span> </span>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == 'reached'
  ) {
    let endTime = processTimeZone(objFamily["eventData"]["packet_time"], userPermissions.timeZone.offset)
    rtrnStr += `<span>
      <span>Arrived ${objFamily["route"]["routeDestination"]["name"]} <br />at <b>${endTime}</b></span> </span>`;
  }

  return rtrnStr;
}
const fnDevice = (pKey, pLabel = '', objFamily, index, setDevicesModal, setTripDetails, userPermissions, setBtnIndex, setSelectedBtn, btnIndex, handleSaveFiltersForStore) => {
  let spanId = `affix-${index}`;
  let deviceId = `device-${index}`;
  let imgId =  `img-${index}`;
  let testId = `test-device-${index}`;
  let fixedId = `fixedId-${index}`;
  let fixedAffix = `fixed-${index}`;
  let rtrnStr = "";

  const status = objFamily["devStatus"];
  let backgroundColor;
  switch (status) {
    case "offline":
      backgroundColor = "#000";
      break;
    case "OFFLINE":
      backgroundColor = "#000";
      break;
    case "MOTION":
      backgroundColor = "#6aa84f";
      break;
    case "MOVING":
      backgroundColor = "#6aa84f";
      break;
    case "IDLE":
      backgroundColor = "#f1c232";
      break;
    case "STOP":
      backgroundColor = "#f1c232";
      break;
    case "LOWBATTERY":
      backgroundColor = "#ea4335";
      break;
    default:
      backgroundColor = "#989898";
  }
  let color;
  switch (status) {
    case "offline":
      color = "#fff";
      break;
    case "OFFLINE":
      color = "#fff";
      break;
    case "motion":
      color = "#fff";
      break;
    case "MOTION":
      color = "#fff";
      break;
    case "idle":
      color = "#fff";
      break;
    case "IDLE":
      color = "#fff";
      break;
    case "STOP":
      color = "#fff";
      break;
    case "stop":
      color = "#fff";
      break;
    case "LOWBATTERY":
      color = "#fff";
      break;
    default:
      color = "#fff";
  }
  
  if (
    objFamily["detailsDeviceID"] &&
    objFamily["detailsDeviceID"]["assetType"] &&
    (objFamily["detailsDeviceID"]["assetType"] == "elock" || objFamily["detailsDeviceID"]["assetType"] == "fixedELock" || objFamily["detailsDeviceID"]["assetType"] == "assetTracker" || objFamily["detailsDeviceID"]["assetType"] == "simTracking")
  ) {
    if (objFamily["tripDetails"]) {
      rtrnStr += ` <span>`;
      let imgPath = objFamily?.Icon || ''

      if ((!objFamily["detailsDeviceID"]["client_id"]) || (objFamily["detailsDeviceID"]["client_id"] && objFamily["detailsDeviceID"]["client_id"] == "")) {
        rtrnStr += `<span id='${deviceId}' data-testid='${testId}' style="box-shadow: 0 0 3px #E8E8E8; background: ${backgroundColor}; color: ${color};" class="tripIdBox">${objFamily["detailsDeviceID"]["deviceID"]}</span>`;
        if (imgPath) {
          rtrnStr += `<div class='boxVertical'>  <img src=${imgPath} width="20px"></div>`;
        }
        setTimeout(() => {
          const spanElement = document.getElementById(deviceId);
          if (spanElement) {
            spanElement.onclick = () => {
              sendCustomEventAnalytics("trips", "Device Details & Doc Upload", "Device Details & Doc Upload")
              index === btnIndex ? setBtnIndex(null) : setBtnIndex(index);
              setSelectedBtn('devices')
              // setDevicesModal(true)
              setTripDetails(objFamily)
            };
          }
        }, 50)
      }


      rtrnStr += `</span>`;
    }

  }

  if (!objFamily.detailsDeviceID && objFamily.tripDetails.isDeviceIDLinked === false && objFamily?.addedBy?.orgId === userPermissions?.orgId) {
    if (objFamily?.tripDetails?.isDeviceIDLinked === false) {
      rtrnStr +=
        ` 
        <div class='flexcolBox' >
          <span>
            <button id='${spanId}' class="mb-2 btn-icon btn-pill btn btn-sm btn-secondary" style="padding-right: 8px; border-radius: 8px;"></i>Affix Device</button>
          </span>
          <span>
              <img id='${imgId}' style="width:20px; cursor: pointer;" src=${imgpin} alt='' />
          </span>
        </div>
        `

    }
    else {
      rtrnStr += '-'
    }

    setTimeout(() => {
      const spanElement = document.getElementById(spanId);
      if (spanElement) {
        spanElement.onclick = () => {
          sendCustomEventAnalytics("trips", "Trip Edit", "Trip Edit")
          handleSaveFiltersForStore();
          history.push({
            pathname: `/dashboard/trips/edit/${objFamily._id}`,
            state: { param: "ViewEdit", id: objFamily._id, cdtn: 'affix' },
          });
        };
      }
    }, 50)
  }
  setTimeout(() => {
    const spanElement = document.getElementById(imgId);
    if (spanElement) {
      spanElement.onclick = () => {
        index === btnIndex ? setBtnIndex(null) : setBtnIndex(index);
        setSelectedBtn('devices')
        // setDevicesModal(true)
        setTripDetails(objFamily)
      };
    }
  }, 50)
  if (!objFamily?.detailsDeviceID && objFamily?.tripDetails?.isDeviceIDLinked === false && objFamily?.addedBy?.orgId !== userPermissions?.orgId) {
    rtrnStr += `<p class="withoutRoute_minDis" style="padding-top: 15px;">( Not Affixed Yet )</p>`
  }
  else {

  }

  return rtrnStr;
}
const fnRoute = (pKey, pLabel = '', objFamily, args = {}) => {
  let rtrnStr = '';
  if (
    objFamily["route"] &&
    objFamily["route"]["routeName"]
  ) {
    rtrnStr += `${objFamily["route"]["routeName"]}`;
  }
  else {
    rtrnStr += '-'
  }

  if (
    objFamily["route"] &&
    objFamily["route"]["description"] &&
    objFamily["route"]["description"] != "NA"
  ) {
    rtrnStr += `<div> <small>(${objFamily["route"]["description"]})</small></div>`;
  }


  return rtrnStr;
}
const fnStatus = (pKey, pLabel = '', objFamily, args = {}) => {
  let rtrnStr = "";
  if (
    objFamily["status"] &&
    objFamily["status"] == "completed"
  ) {
    rtrnStr += `<div class="page-title-icon"><i
      class="icon fa fa-flag icon-gradient bg-tempting-dark"></i><span
      style="font-size:11px"></span></div>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == "in_transit"
  ) {
    rtrnStr += `<div class="page-title-icon"><i
      class="icon fa fa-flag icon-gradient bg-tempting-success"></i><span
      style="font-size:11px"></span></div>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == "mapped"
  ) {
    rtrnStr += ` <div class="page-title-icon"><i
      class="icon fa fa-flag icon-gradient bg-tempting-warning"></i><span
      style="font-size:11px"></span></div>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == "initiated"
  ) {
    rtrnStr += `<div class="page-title-icon"><i
      class="icon fa fa-flag icon-gradient bg-tempting-info"></i><span
      style="font-size:11px"></span></div>`;
  }

  if (
    objFamily["status"] &&
    objFamily["status"] == "reached"
  ) {
    rtrnStr += `<div  class="page-title-icon"><i
      class="icon fa fa-flag icon-gradient bg-tempting-defautlt"></i><span
      style="font-size:11px"></span></div>`;
  }

  return rtrnStr;
}
const fnTruckDriver = (pKey, pLabel = '', objFamily, args = {}) => {
  let rtrnStr = "";
  if (
    objFamily["detailsDeviceID"] && objFamily["detailsDeviceID"]["vehicleDetails"] &&
    objFamily["detailsDeviceID"]["vehicleDetails"]["vehicleNo"]
  ) {
    rtrnStr += `<span>${objFamily["detailsDeviceID"]["vehicleDetails"]["vehicleNo"]}</span><br/>`;
  }

  if (objFamily["detailsDeviceID"] && objFamily["detailsDeviceID"]["vehicleDetails"] && !objFamily["detailsDeviceID"]["vehicleDetails"]["vehicleNo"]) {
    rtrnStr += `<span>-</span><br/>`;
  }

  if (
    objFamily["driverName"] &&
    objFamily["driverName"] != "",
    objFamily["runningStatus"] &&
    objFamily["runningStatus"] != ""
  ) {
    rtrnStr += `<div>${objFamily["driverName"]} </div>`;
  }

  if (
    objFamily["runningStatus"] &&
    objFamily["runningStatus"] == "accepted" &&
    objFamily["status"] &&
    objFamily["status"] != "completed"
  ) {
    rtrnStr += ` <div class="badge badge-success">accepted</div>`;
  }

  if (
    objFamily["driverId"] &&
    !objFamily["runningStatus"] &&
    objFamily["status"] &&
    objFamily["status"] != "completed" &&
    objFamily["status"] != "reached" &&
    objFamily["status"] != "in_transit"
  ) {
    rtrnStr += `<div class="badge badge-warning">requested</div>`;
  }

  if (
    objFamily["runningStatus"] &&
    objFamily["runningStatus"] == "rejected" &&
    objFamily["status"] &&
    objFamily["status"] != "completed"
  ) {
    rtrnStr += ` <div class="badge badge-warning">rejected</div>`;
  }

  if (
    objFamily["status"] &&
    (objFamily["status"] == "initiated" || objFamily["status"] == "mapped") &&
    objFamily["driverId"] &&
    objFamily["driverId"] == ""
  ) {
    rtrnStr += `<div class="badge badge-primary" id="assign" style="cursor:pointer" data-toggle="modal" data-target="#assigDriver"
      (click)="getTripId(trip.tripId,'assign',trip.driverId, trip.driverName)"> Assign driver </div>`;
  }
  if (
    objFamily["runningStatus"] &&
    (objFamily["runningStatus"] == "rejected") &&
    objFamily["status"] &&
    (objFamily["status"] != "completed")
  ) {
    rtrnStr += `<div class="badge badge-primary mt-2" id="assign" style="cursor:pointer" data-toggle="modal"
      data-target="#assigDriver" (click)="getTripId(trip.tripId,'reassign',trip.driverId, trip.driverName)"> Reassign driver </div>`;
  }

  return rtrnStr;
}
const fnDriverDetails = (pKey, pLabel = '', objFamily, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails) => {
  let rtrnStr = "";

  let spanId = `driver-${index}`;

  try {
    if (objFamily["vehicleDetails"]["driver"][0]["driverName"] && objFamily["vehicleDetails"]["driver"][0]["driverName"] !== undefined) {
      rtrnStr = `<span>${objFamily["vehicleDetails"]["driver"][0]["driverName"]}</b></span>`
      if (objFamily.vehicleDetails.driver.length > 1) {
        rtrnStr += `<span id='${spanId}' style="text-decoration: underline;cursor: pointer;"> <b>+${objFamily.vehicleDetails.driver.length - 1}</b></span>`
      }


      setTimeout(() => {
        const spanElement = document.getElementById(spanId);
        if (spanElement) {
          spanElement.onclick = () => {
            sendCustomEventAnalytics("trips", "Driver Details", "Driver Details")
            setDriverDetails(objFamily)
            setDriverModal(true)
          };
        }
      }, 50)
    }
    else {
      return '-';
    }
    return rtrnStr;

  }
  catch (error) {
    return '-';
  }
}
const fnTripCreatedMethod = (pKey, pLabel = '', objFamily, args = {}) => {
  let tripType = objFamily && objFamily?.tripDetails && objFamily?.tripDetails?.tripCreationMethod || '-'
  try {
    if (tripType === 'createTripWithoutRoute') {
      return `<span class="withoutRoute_minDis">( Trip Without Route )</span>`
    }
    else if (tripType === 'tripWithRoute') {
      return `<span class="withoutRoute_minDis">( Trip With Route )</span>`
    }
    else {
      return '-';
    }

  }
  catch (error) {
    return '-';
  }
}
function processTimeZone(date, offset = 5.5, timeFormat = "DD/MM/YYYY hh:mm:ss A") {
  let formatteddate = moment(new Date(date)).utc().utcOffset(offset * 60).format(timeFormat)
  if (formatteddate === 'Invalid date') {
    return null
  }
  else {
    return formatteddate;
  }
}
const fnAdditionalDetails = (str, pKey, pLabel = "", objFamily, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails, dispatch, fetchedAddress, tableIndex, setTableIndex, setAdditionalModal, setAdditionalData) => {
  const iconId = `icon-${index}`;
  const spanHtml = `<div class="outlined-theme-btn" id=${iconId}><i class="fa fa-info" style="color: #ff7700;" aria-hidden="true"></i>
  </div>`;
  setTimeout(() => {
    const spanElement = document.getElementById(iconId);
    if (spanElement) {
      spanElement.onclick = () => {
        sendCustomEventAnalytics("trips", "Additional Details", "Additional Details")
        setAdditionalData(objFamily)
        setTimeout(() => {
          setAdditionalModal(true);
        }, 20);
      };
    }
  }, 50);
  return spanHtml;
}
const fntripName = (pKey, pLabel = '', objFamily, args = {}) => {
  let movementNumber = objFamily && objFamily?.dynamicArray && objFamily?.dynamicArray[0] && objFamily?.dynamicArray[0]?.dynamic_tripName || '-'
  let spanHTML;

  try {
    if (movementNumber.length > 14) {
      let shortText = movementNumber.substring(0, 14) + "...";
      spanHTML = `<div class="tooltip-container">
        <span>${shortText}</span>
        <span class="tooltip-content">${movementNumber}</span>
      </div>`;
      return spanHTML;
    } else {
      spanHTML = `<span style="color: #ff7700; cursor: pointer; font-size:13px;">${movementNumber}</span>`;
      return spanHTML;
    }

  }
  catch (error) {
    return '-';
  }
}
const fnNewTripStatus = (pKey, pLabel, objFamily) => {
  let tripStatus = objFamily && objFamily?.TRIP_STATUS_NEW ? objFamily?.TRIP_STATUS_NEW : '-'
  try {
    if (tripStatus) {
      return `
      <div class='statusBox' >
      <span class='statusSpan'>${tripStatus}</span>
      </div>
      `
    }
  }
  catch (error) {
    return '-';
  }
}

export function main(str, pKey, pLabel = "", objFamily, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails, dispatch, fetchedAddress, tableIndex, setTableIndex, setAdditionalModal, setAdditionalData, userPermissions, setBtnIndex, setSelectedBtn, btnIndex, handleSaveFiltersForStore) {
  switch (str) {
    case 'fnTripId':
      return fnTripId(pKey, pLabel, objFamily, index, handleSaveFiltersForStore);
    case 'fnTripCreatedOn':
      return fnTripCreatedOn(pKey, pLabel, objFamily, {}, userPermissions);
    case 'fnOrigin':
      return fnOrigin(pKey, pLabel, objFamily, {}, userPermissions);
    // case 'fnTripStatus':
    //   return fnTripStatus(pKey, pLabel, objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
    case 'fnTripStatus':
      return fnNewTripStatus(pKey, pLabel, objFamily, index, dispatch, fetchedAddress, tableIndex, setTableIndex, userPermissions);
    case 'fnDevice':
      return fnDevice(pKey, pLabel = '', objFamily, index, setDevicesModal, setTripDetails, userPermissions, setBtnIndex, setSelectedBtn, btnIndex, handleSaveFiltersForStore);
    case 'fnRoute':
      return fnRoute(pKey, pLabel = '', objFamily, {});
    case 'fnStatus':
      return fnStatus(pKey, pLabel = '', objFamily, {});
    case 'fnTruckDriver':
      return fnTruckDriver(pKey, pLabel = '', objFamily, {});
    case 'fnDriverDetails':
      return fnDriverDetails(pKey, pLabel = '', objFamily, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails);
    case 'fnTripCreatedMethod':
      return fnTripCreatedMethod(pKey, pLabel = '', objFamily, {});
    case 'fnAdditionalDetails':
      return fnAdditionalDetails(str, pKey, pLabel = "", objFamily, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails, dispatch, fetchedAddress, tableIndex, setTableIndex, setAdditionalModal, setAdditionalData);
    case 'fntripName':
      return fntripName(pKey, pLabel = '', objFamily, {});



    // Trial
    default:
      try {
        let valueStr = getNestedValues(objFamily, pKey);
        return `${valueStr}`;
      } catch (error) {
        return pLabel && pLabel != "" ? "" : "-";
      }
  }
}
function getNestedValues(objFamily, keyPath) {
  const keys = keyPath.split(".");
  let value = objFamily;
  for (const key of keys) {
    if (value && value.hasOwnProperty(key)) {
      value = value[key] || "-";
    } else {
      return "-";
    }
  }
  return value;
}




