import {
  PORT_WISE_STATUS,
  SIMPLE_TRACK_STATUS,
  FULL_TRACK_STATUS,
  TRACK_STATUS_KEYS,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
} from "../types";
import axios, { reportAxios } from "../../services/Api";
import { geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";

export const getSimpleTrackData = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getHealthDashboardTrackData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: SIMPLE_TRACK_STATUS, payload: { data: [], count: 0 } });
          if (cb) {
            cb(data);
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SIMPLE_TRACK_STATUS,
            payload: data,
          });
          //   console.log('/getAlertLogs', data)
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: SIMPLE_TRACK_STATUS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
}

export const getFullTrackData = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getHealthDashboardTrackData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: FULL_TRACK_STATUS, payload: { data: [], count: 0 } });
          if (cb) {
            cb(data);
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FULL_TRACK_STATUS,
            payload: data,
          });
          //   console.log('/getAlertLogs', data)
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: FULL_TRACK_STATUS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
}

export const getportWiseStatus = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/port/portWiseStatus", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: PORT_WISE_STATUS, payload: { data: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: PORT_WISE_STATUS,
            payload: data,
          });
          //   console.log('/getAlertLogs', data)
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: PORT_WISE_STATUS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
}

export const getTrackStatusKeys = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    reportAxios
      .post("/getTripReportKeys", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: TRACK_STATUS_KEYS, payload: { data: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRACK_STATUS_KEYS,
            payload: data,
          });
          //   console.log('/getAlertLogs', data)
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRACK_STATUS_KEYS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
}