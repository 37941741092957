import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Card, CardContent, Grid } from "@mui/material";
import '../D3/DonutChart.css';
import { useSelector } from 'react-redux';

const DonutChart = ({ data, widthProp }) => {

  const chartRefs = useRef([]);
  const tooltipRefs = useRef([]);
  const legendRefs = useRef([]);

  const isDarkTheme = useSelector(
    (state) => state.theme.themeDark
  );

  useEffect(() => {
    const createDonutChart = (chartData, chartIndex) => {

      // Cleanup
      d3.select(chartRefs.current[chartIndex]).selectAll("*").remove();
      d3.select(tooltipRefs.current[chartIndex]).selectAll("*").remove();
      d3.select(legendRefs.current[chartIndex]).selectAll("*").remove();

      // Start rendering
      const width = 200;
      const height = 200;
      const radius = Math.min(width, height) / 2;

      const svg = d3
        .select(chartRefs.current[chartIndex])
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const pie = d3.pie().value(d => d.value);
      const arc = d3
        .arc()
        .innerRadius(radius - 27)
        .outerRadius(radius - 5);

      const filteredChartData = chartData.filter(d => d.label !== 'Completed');
      const arcs = svg.selectAll('arc').data(pie(filteredChartData)).enter().append('g');
      // const arcs = svg.selectAll('arc').data(pie(filteredChartData)).enter().append('g');

      arcs
        .append('path')
        .attr('d', arc)
        .attr('fill', d => d.data.color)
        .attr('stroke', 'white')
        // .attr('stroke-width', '2px')
        .on('mouseover', function (event, d) {
          const fillColor = d3.select(this).attr('fill');
          d3.select(this)
            .transition()
            .duration(600)
            .attr('d', d3.arc().innerRadius(radius - 28).outerRadius(radius - 4))
            .style('opacity', 0.8)
            .style('cursor', 'pointer')
            .attr('stroke', fillColor);

          const tooltip = d3.select(tooltipRefs.current[chartIndex]);
          tooltip.style('color', "#000000");
          tooltip.transition().duration(200).style('opacity', 1);
          tooltip.html(`${d.data.label}: ${d.data.value}${d.data.param === 'percentage' ? '%' : ''}`);
        })
        .on('mousemove', function (event) {
          const tooltip = d3.select(tooltipRefs.current[chartIndex]);
          const tooltipOffsetX = 80;
          const tooltipOffsetY = 1;
          tooltip.style('color', "#000000");
          tooltip
            .style('left', event.offsetX + tooltipOffsetX + 'px')
            .style('top', event.offsetY + tooltipOffsetY + 'px');
        })
        .on('mouseout', function () {
          d3.select(this)
            .transition()
            .attr('d', arc)
            .style('opacity', 1)
            .attr('stroke', 'white');

          const tooltip = d3.select(tooltipRefs.current[chartIndex]);
          tooltip.style('color', "#000000");
          tooltip.transition().duration(500).style('opacity', 0);
        });

      // Dynamic title
      const title = chartData[chartData.length - 1].chartTitle;
      const titleWords = title.split('\n');

      const titleText = svg
        .append('text')
        // .attr('class', 'donut-middle-title')
        .attr('text-anchor', 'middle')
        .attr('dy', '.35em')
        .attr('font-weight', '600')
        .attr('font-family', 'sans-serif')
        .style('fill', isDarkTheme ? "#ffffff" : "#000000");

        const maxWidth = 150;
        const minFontSize = 13.6;
        let fontSize = Math.min(20, maxWidth / Math.max(...titleWords.map(word => word.length)));
        fontSize = Math.max(fontSize, minFontSize);
        
        titleText.attr('font-size', fontSize + 'px');


      if (titleWords.length > 1) {
        titleWords.forEach((word, index) => {
          titleText
            .append('tspan')
            .attr('x', 0)
            .attr('dy', index > 0 ? '1.2em' : 0)
            .text(word);
        });
      }
      else {
        titleText.text(title);
      }

      // Add legends
      const legend = d3.select(legendRefs.current[chartIndex]);
      const legendData = chartData.slice(0, -1);
      const legendItems = legend
        .selectAll('.legendItem')
        .data(legendData)
        // .data(chartData)
        .enter()
        .append('div')
        .attr('class', 'legendItem');

      legendItems
        .append('span')
        .attr('class', 'legendColor')
        .style('background-color', d => d.color);


      legendItems.append('span').text(d => {
        if (d.param === 'percentage') {
          return d.label + ` (${d.value || d.value == 0 ? d.value + ' %' : '-'})`;
        } else {
          return d.label + ` (${d.value || d.value == 0 ? d.value : '-'})`;
        }
      });

      svg.transition().duration(800).attr('transform', `translate(${width / 2},${height / 2})`);
    };

    data.forEach((chartData, index) => {
      createDonutChart(chartData, index);
    });
  }, [data, isDarkTheme]);

  return (
    <Grid container justifyContent="center" alignContent={"center"} spacing={2} pl={2}>
      {data && data?.map((itm, index) => {
        return (
          <Grid
            item
            xs={widthProp ? widthProp : 4}
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card sx={{ width: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  position: "relative",
                  // flexDirection: 'column',
                }}
              >
                {itm?.every((value) => value.value === 0 || value.value === "" || value.value === undefined || value.value === null) ? (
                  <div className="ring">
                    <span style={{fontSize:'20px', fontWeight:'550'}}>{itm.find(item => item.chartTitle)?.chartTitle}</span>
                  </div>
                ) : (
                  <>
                    <div ref={(el) => (chartRefs.current[index] = el)} key={`chart-${index}`}></div>
                  </>
                )}
                <div ref={(el) => (tooltipRefs.current[index] = el)} key={`tooltip-${index}`} className='tooltip'></div>
                <div ref={(el) => (legendRefs.current[index] = el)} key={`legend-${index}`} className="legend"></div>
              </CardContent>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  );
};

export default DonutChart;
