export const inputClasses = {
  labelField: {
    backgroundColor: "white",
  },
  textField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "2px",
    borderColor: "1px solid #D9D9D9",
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: "400",
    "& label": {
      top: "5px",
    },
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
    "&::placeholder": {
      color: "#000", // Change the color here
      fontSize: "8px",
    },
  },
  shadowField: {
    // boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    borderColor: "#D9D9D9",
    fontSize: "12px",
    lineHeight: "19px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
  },
  themeBoundary: {
    "& label.Mui-focused": {
      color: "#F77200",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
        fontSize: "12px",
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
        fontSize: "12px",
      },
      "&:hover fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
        fontSize: "12px",
      },
    },
    "& input::placeholder": {
      fontSize: "14px",
      color: "#000",
    },
  },
  filterField: {
    boxShadow: "0px 4px 4px 0px #D8D8D8",
    borderRadius: "12px",
    borderColor: "1px solid #D9D9D9",
    fontSize: "15px",
    lineHeight: "19px",
    fontWeight: "400",
    color: "#000",
    "& input::placeholder": {
      fontSize: "12px",
    },
    "& label.Mui-focused": {
      color: "#F77200",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "#F77200",
        borderWidth: 1,
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F77200",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#F77200",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#F77200",
      borderWidth: 1,
    },
    "&:focused > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#F77200",
      borderWidth: 1,
    },
    "& input::placeholder": {
      fontSize: "12px",
      color: "#000",
    },
  },
};

export const cardClasses = {
  basic: {
    borderRadius: "15px",
    border: "1px solid #0000002B",
    boxShadow: "0px 4px 4px 0px #00000040",
  },
};

export const textClasses = {
  t11n: {
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "13px",
  },
  t12n: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t13n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  t14n: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  normalText: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    // color: "#000",
  },
  boldText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#455A64",
  },
  boldTextBlack: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#000",
  },
  t20n: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "27px",
  },
  cardTitle: {
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: "600",
  },
  pageTitle: {
    fontSize: "32px",
    lineHeight: "44px",
    fontWeight: "600",
    color: "#FF7200",
  },
  themeColor: {
    color: "#000000",
  },
  pageTitleHeading: {
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: "600",
    color: "#000",
    backgroundColor:'rgb(240, 240, 240)'
  },
  pageSubText: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
    color: "#212529",
  },
  labelHeadColor:{
    fontSize:'9.5px',
    color:'#00000099',
    backgroundColor:'#fff'
  },
  labelHeadBorder:{
    border:'1px solid #C0C0C0',
    borderRadius:'12px'
  }
};

export const baseButton = {
  border: "1px solid #D9D9D9",
  borderRadius: "12px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  minHeight: "48px",
  textTransform: "none",
};

export const buttonClasses = {
  small: {
    width: "auto",
    borderRadius: "12px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  normal: {
    border: "1px solid #D9D9D9",
    borderRadius: "12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    minHeight: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  orange: {
    ...baseButton,
    backgroundColor: "#FF7200",
    color: "white",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
    },
  },
  smallOrange: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "27px",
    maxHeight: "24px",
    maxWidth: "24px",
    textTransform: "none",
    backgroundColor: "#FF7200",
    color: "white",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  outlined: {
    border: "1px solid #FF7200",
    color: "#FF7200",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    textTransform: "none",
    minHeight: "40px",
    "&:hover": {
      border: "1px solid #FF7200",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  lynkitOrangeEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "#FF7200",
    border: `1px solid #FF7200`,
    textTransform: "none",
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid #FF7200`,
      color: "#ff7200",
      boxShadow: "0 8px 6px -7px #696969",
    },
    "&:disabled": {
      backgroundColor: "#827878",
      border: `1px solid #827878`,
      color: "#EAEAEA",
    },
  },
  lynkitMapOrangeFill: {
    boxShadow: "0px 0px 5px #696969",
    "&:hover": {
      backgroundColor: "#ff7200",
      border: `1px solid #FF7200`,
      color: "#fff",
      boxShadow: "0px 0px 10px #696969",
    },
  },
  lynkitOrangeFill: {
    backgroundColor: "#FF7200",
    borderRadius: "12px",
    border: `1px solid #FF7200`,
    textTransform: "none",
    color: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#FF7200",
      border: `1px solid #FF7200`,
      boxShadow: "0 8px 6px -7px #696969",
    },
    "&:disabled": {
      backgroundColor: "#827878",
      border: `1px solid #827878`,
      color: "#EAEAEA",
    },

    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    minHeight: "30px",
  },
  lynkitOrangeFillDisabled: {
    backgroundColor: "#FF7200",
    borderRadius: "12px",
    border: `1px solid #FF7200`,
    // border: `1px solid #fff`,
    textTransform: "none",
    color: "#fff",
    height: "32px",
    opacity: "0.9",

    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#FF7200",
      border: `1px solid #FF7200`,
      boxShadow: "0 8px 6px -7px #696969",
    },
    minWidth: "60px",
  },
  lynkitBlackEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "#000",
    border: `1px solid #000`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000",
      border: `1px solid #000`,
      color: "#fff",
    },
    "&:disabled": {
      border: "none",
    },
  },
  lynkitGreenFill: {
    backgroundColor: "#3E621E",
    borderRadius: "12px",
    border: `1px solid #3E621E`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3E621E",
      border: `1px solid #3E621E`,
    },
    "&:disabled": {
      border: "none",
    },
  },
  lynkitGreenEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "green",
    border: `1px solid green`,
    textTransform: "none",
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid green`,
      color: "green",
      boxShadow: "0 8px 6px -7px #3E621E",
    },
  },
  lynkitRedEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "red",
    border: `1px solid red`,
    textTransform: "none",
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid red`,
      color: "red",
      boxShadow: "0 8px 6px -7px #3E621E",
    },
  },
  lynkitRedFill: {
    backgroundColor: "#FF0101",
    borderRadius: "12px",
    border: `1px solid #FF0101`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF0101",
      border: `1px solid #FF0101`,
    },
    "&:disabled": {
      border: "none",
    },
  },
  lynkitDisabled: {
    backgroundColor: "#827878",
    borderRadius: "12px",
    border: `1px solid #827878`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#827878",
      border: `1px solid #827878`,
    },
  },
  lynkitDelete: {
    backgroundColor: "#FF0000",
    borderRadius: "12px",
    border: `1px solid #FF0000`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF0000",
      border: `1px solid #FF0000`,
    },
  },
  lynkitBlackFill: {
    backgroundColor: "#000",
    borderRadius: "12px",
    color: "#ffffff",
    border: `1px solid #000`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000",
      border: `1px solid #000`,
      color: "#fff",
    },
    minWidth: "60px",
    "&:disabled": {
      backgroundColor: "#827878",
      border: `1px solid #827878`,
      color: "#EAEAEA",
    },
  },
  lynkitGreenFill: {
    backgroundColor: "#4BB543",
    borderRadius: "12px",
    color: "#ffffff",
    border: `1px solid #3ac47d`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4BB543",
      border: `1px solid #4BB543`,
      color: "#fff",
    },
  },
  lynkitGrayFill: {
    backgroundColor: "#696969",
    borderRadius: "12px",
    color: "#ffffff",
    border: `1px solid #696969`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#696969",
      border: `1px solid #696969`,
      color: "#fff",
    },
  },
  lynkitNewBlackEmpty: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "black",
    fontWeight:'600',
    border: `1px solid #808080`,
    textTransform: "none",
    fontSize:'14px',
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid #ff7200`,
      color: "red",
      boxShadow: "0 8px 6px -7px #ff7200",
    },
  },
};
export const tableClasses = {
  table: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    border: "2px solid #E8E8E8",
    borderLeft: "1px solid #F5F5F5",
    borderRight: "1px solid #F5F5F5",
    transition: "background-color 250ms linear",
  },
  dark: {
    backgroundColor: "#fff",
    padding: "13px 5px",
    // padding: "13px 5px 13px 14px",
    borderBottom: "1px solid #E8E8E8",
    transition: "background-color 250ms linear",
  },
  sideBarandHeader: {
    backgroundColor: "#fff",
  },
  tableHeader: {
    padding: "10px 10px 10px 10px",
    color: "#000",
    fontWeight: "600",
  },
  tablePagination: {
    backgroundColor: '#fff'
  },
  tableHeaderClass: {
    color: "#000",
    padding: "8px 8px 8px 8px",
    fontWeight: "600",
  },
  tableBodyClass: {
    padding: "7px 7px 7px 7px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #E8E8E8",
    transition: "background-color 250ms linear",
  },
  myCTable: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    marginRight: '25px'
  },
  myTH: { 
    padding: '5px', 
    textAlign: 'left',
    wordWrap: 'break-word', 
    wordBreak: 'break-all',
    fontSize: '12px',
  },
  myTD: { 
    border: "1px solid #dddddd", 
    padding: "5px", 
    textAlign: "left",
    wordWrap: "break-word", 
    wordBreak: "break-all",
    fontSize: "13px",
  }
};
export const driverModal = {
  modal: {
    whiteSpace: "nowrap",
    border: "2px solid #E8E8E8",
    borderRadius: "8px",
    boxShadow: "0 8px 6px -2px #D3D3D3",
    backgroundColor: "#fff",
  },
};
export const dividerClass = {
  upper: {
    borderTop: "1px solid #E0E0E0",
  },
  lower: {
    borderBottom: "1px solid #E0E0E0",
  },
  all:{
    border:"1px solid #E0E0E0"
  }
};
export const borderRight = {
  borderRight: "1px solid #E8E8E8",
};
export const borderAll = {
  border: "1px solid #E8E8E8",
};
export const borderTop = {
  borderTop: "1px solid #E8E8E8",
};
export const borderBottom = {
  borderBottom: "1px solid #E8E8E8",
};
export default {
  themeOrange: "#FF7200",
  themeLightOrange: "#f3d7c0",
  themeOrangeShadow: "#c95c03",
  themeBlack: "#000000",
  themeGray: "#F0F0F0",
  themeGreen: "green",
  themeRed: "#FF0101",
  themeLightOrange: "#f3d7c0",
  themeWhite: "#fff",
  sideBarTheme: "#fff",
};
