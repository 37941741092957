import { INIT_CANVAS, ADD_SECTION, REMOVE_SECTION, ADD_FIELD, REMOVE_FIELD, EDIT_FIELD, SET_EDIT_FIELD, REMOVE_EDIT_FIELD, SELECT_TEMPLATE, REPLACE_EDIT_CANVAS, SELECT_SUB_TEMPLATE, FETCH_SUPPLIER_MASTER_TEMPLATES, FETCH_CUSTOMER_MASTER_TEMPLATES, FETCH_ITEM_MASTER_TEMPLATES, SELECT_SUPPLIER_MASTER, SELECT_CUSTOMER_MASTER, SELECT_ITEM_MASTER } from "../types";

export const cleanCanvas = () => {
    const canvas = {
        attributes: {
            status: true,
            mandatory: [
                {
                    "type": "select",
                    "label": "Select Organisation",
                    "_id": "organisation",
                    "templateType": "user",
                    "placeholder": "Select Organistaion",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select Organistaion",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "type": "select",
                    "label": "Select User Role",
                    "_id": "userRole",
                    "templateType": "user",
                    "placeholder": "Select User Role",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select User Role",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "key":"",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "handle": true,
                    "_id": "name",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Name",
                    "description": "Enter your name",
                    "placeholder": "Enter your name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter your name.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "user"
                },
                { 
                    "handle": true,
                    "_id": "mobileNo",
                    "type": "number",
                    "icon": "simple-icon-note",
                    "label": "Mobile Number",
                    "description": "Enter your mobile number",
                    "placeholder": "Enter your mobile number",
                    "className": "form-control",
                    "subtype": "number",
                    "regex": "^[0-9]*$",
                    "forType": "table",
                    "errorText": "Enter your mobile mumber.",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled":true,
                    'templateType': "user",
                    "key":""
                },
                {
                    "handle": true,
                    "_id": "password",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Password",
                    "description": "Enter your password",
                    "placeholder": "Enter your password",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter your password.",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "key":"",
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "user"
                },
                {
                    "handle": true,
                    "_id": "confirmPassword",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Confirm Password",
                    "description": "Re-Enter your password",
                    "placeholder": "Re-Enter your password",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "key":"",
                    "forType": "table",
                    "errorText": "Re-Enter your password.",
                    "min": "",
                    "max": "",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "user"
                },
                {
                    "handle": true,
                    "_id": "name",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Name",
                    "description": "Enter your name",
                    "placeholder": "Enter your name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter your name.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "geofence"
                },
                {
                    "handle": true,
                    "_id": "latitude",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Latitude",
                    "description": "Enter latitude",
                    "placeholder": "Enter latitude",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter latitude.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "geofence"
                },
                {
                    "handle": true,
                    "_id": "longitude",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Longitude",
                    "description": "Enter longitude",
                    "placeholder": "Enter longitude",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter longitude.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "geofence"
                },
                {
                    "handle": true,
                    "_id": "radius",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Radius",
                    "description": "Enter radius",
                    "placeholder": "Enter radius",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter radius.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "geofence"
                },
                // Routes
                {
                    "handle": true,
                    "_id": "routeName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Route name",
                    "description": "Enter route name",
                    "placeholder": "Enter route name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter route name.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "route"
                },
                {
                    "handle": true,
                    "_id": "routeDescription",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Route description",
                    "description": "Enter route description",
                    "placeholder": "Enter route description",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter route description.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "route"
                },
              
                {
                    "type": "select",
                    "label": "Minimum Batter Percentage",
                    "_id": "minBatterPercent",
                    "templateType": "route",
                    "placeholder": "Minimum Batter Percentage",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Minimum Batter Percentage",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "type": "select",
                    "label": "Select Organisation",
                    "_id": "organisation",
                    "templateType": "route",
                    "placeholder": "Select Organistaion",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select Organistaion",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "type": "select",
                    "label": "Select User",
                    "_id": "user",
                    "templateType": "route",
                    "placeholder": "Select User",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select User",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                // TRIPS
                {
                    "handle": true,
                    "_id": "tripId",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Trip ID",
                    "description": "Enter Trip Id",
                    "placeholder": "Enter Trip Id",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter Trip Id.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "trip"
                },
                {
                    "type": "select",
                    "label": "Route Name",
                    "_id": "routeName",
                    "templateType": "trip",
                    "placeholder": "Route Name",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Route Name",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "type": "select",
                    "label": "Route Schedule",
                    "_id": "routeSchedule",
                    "templateType": "trip",
                    "placeholder": "Select Route Schedule",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select Route Schedule",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "handle": true,
                    "_id": "vehicleNumber",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Vehicle Number",
                    "description": "Enter Vehicle Number",
                    "placeholder": "Enter Vehicle Number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter Vehicle Number",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "trip"
                },
                {
                    "type": "select",
                    "label": "Select Driver",
                    "_id": "selectDriver",
                    "templateType": "trip",
                    "placeholder": "Select Driver",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select Driver",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "handle": true,
                    "_id": "deviceId",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Device ID",
                    "description": "Enter Device Id",
                    "placeholder": "Enter Device Id",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter Device Id.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "trip"
                },
                {
                    "type": "select",
                    "label": "Select Address",
                    "_id": "selectAddress",
                    "templateType": "trip",
                    "placeholder": "Select Address",
                    "handle": false,
                    "icon": "simple-icon-note",
                    "description": "Select Address",
                    "className": "form-control select2-single",
                    "disabled": true,
                    "required": true,
                    "delete": false,
                    "subtype": "single",
                    "forType": "all",
                    "elementType": "Selection Type",
                    "sortType": 2,
                    "key":"",
                    "values": [
                        {
                            "label": "Option 1",
                            "value": "option 1"
                        },
                        {
                            "label": "Option 2",
                            "value": "option 2"
                        },
                        {
                            "label": "Option 3",
                            "value": "option 3"
                        }
                    ],
                },
                {
                    "handle": true,
                    "_id": "remark",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Remark",
                    "description": "Enter Remark",
                    "placeholder": "Enter Remark",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)",
                    "forType": "table",
                    "errorText": "Enter Remark.",
                    "min": "",
                    "max": "",
                    "key":"",
                    "required": true,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled": true,
                    'templateType': "trip"
                },
            ],
            other: [
                // users Dynamic fields
                {
                    "handle": true,
                    "_id": "email",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Email",
                    "description": "Enter your email",
                    "placeholder": "Enter your email",
                    "className": "form-control",
                    "subtype": "email",
                    "regex": "^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$",
                    "forType": "table",
                    "errorText": "Enter your email.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    "disabled":false,
                    'templateType': "user",
                    "key":"dynamic_email"
                },
                {
                    "handle": true,
                    "_id": "dynamic_rfidCard",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "RFID Card",
                    "description": "Enter RFID card number",
                    "placeholder": "Enter RFID card number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter your RFID card number.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "user",
                    "key":"dynamic_rfidCard"
                },
                // devices dynamic fields
                {
                    "handle": true,
                    "_id": "dynamic_driverName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Driver Name",
                    "description": "Enter driver name",
                    "placeholder": "Enter driver name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter driver name.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "device",
                    "key":"dynamic_driverName"
                },
                {
                    "handle": true,
                    "_id": "dynamic_driverMobile",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Driver Mobile",
                    "description": "Enter driver mobile",
                    "placeholder": "Enter driver mobile",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter driver mobile.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "device",
                    "key":"dynamic_driverMobile"
                },
                {
                    "handle": true,
                    "_id": "dynamic_vehicleNumber",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Vehicle Number",
                    "description": "Enter vehicle number",
                    "placeholder": "Enter vehicle number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter vehicle number.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "device",
                    "key":"dynamic_vehicleNumber"
                },
                {
                    "handle": true,
                    "_id": "dynamic_displayName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Display Name",
                    "description": "Enter display name",
                    "placeholder": "Enter display name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter display name.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "device",
                    "key":"dynamic_displayName"
                },
                // geofence
                {
                    "handle": true,
                    "_id": "dynamic_description",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Description",
                    "description": "Description",
                    "placeholder": "Description",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter description.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "geofence",
                    "key":"dynamic_description"
                },
                // Trips
                {
                    "handle": true,
                    "_id": "dynamic_tripName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Trip Name",
                    "description": "Trip Name",
                    "placeholder": "Trip name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter trip name.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "trip",
                    "key":"dynamic_tripName"
                },
                {
                    "handle": true,
                    "_id": "dynamic_transporterName",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Transporter / Contractor",
                    "description": "Transporter Name",
                    "placeholder": "Transporter Name",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter transporter name.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "trip",
                    "key":"dynamic_transporterName"
                },
                {
                    "handle": true,
                    "_id": "dynamic_containerNumber",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Container Number",
                    "description": "Container Number",
                    "placeholder": "Container Number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter container no.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "trip",
                    "key":"dynamic_containerNumber"
                },
                {
                    "handle": true,
                    "_id": "dynamic_invoiceNumber",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "Invoice Number",
                    "description": "Invoice Number",
                    "placeholder": "Invoice Number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter Invoice No.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "trip",
                    "key":"dynamic_invoiceNumber"
                },
                {
                    "handle": true,
                    "_id": "dynamic_grNumber",
                    "type": "input",
                    "icon": "simple-icon-note",
                    "label": "GR Number",
                    "description": "GR Number",
                    "placeholder": "GR Number",
                    "className": "form-control",
                    "subtype": "text",
                    "regex": "",
                    "forType": "table",
                    "errorText": "Enter GR No.",
                    "min": "",
                    "max": "",
                    "required": false,
                    "multiple": false,
                    "hideLabel": false,
                    "delete": false,
                    "value": '',
                    'templateType': "trip",
                    "key":"dynamic_grNumber"
                },

            ]
        }
    }
    return canvas;
}

const INIT_STATE = {
    components: cleanCanvas(),
    editField: null,
    selectedTemplate: "-",
    selectedSubTemplate: "-",
    countElements:{}
}

export default (state = INIT_STATE, action) => {
    let newComps; 
    let sectionId = action.payload && action.payload.sectionId;
    // let field;
    switch (action.type) {

        case SELECT_TEMPLATE: return { ...state, selectedTemplate: action.payload.templateType, selectedSubTemplate: action.payload.subTemplateType, components: cleanCanvas() }

        case INIT_CANVAS: return { ...state, components: cleanCanvas() }

        case REPLACE_EDIT_CANVAS: return { ...state, 
            selectedTemplate: action.payload.templateType,
             selectedSubTemplate: action.payload.templateCategory,
             components: action.payload.newComponents }

        case ADD_SECTION:

            newComps = { ...state.components };
            newComps[sectionId].status = true;
            return { ...state, components: newComps }

        case REMOVE_SECTION:
            newComps = { ...state.components };
            newComps[sectionId].status = false;
            newComps[sectionId].other = []
            return { ...state, components: newComps }

        case ADD_FIELD:
            newComps = { ...state.components };
            newComps[sectionId].other = [...newComps[sectionId].other, ...action.payload.fields]
            return { ...state, components: newComps, countElements: action.payload.counterFields }

        case REMOVE_FIELD:
            newComps = { ...state.components }
            let newArr = [...newComps[sectionId].other.filter(f=> f.templateType === state.selectedSubTemplate)]
            newArr.splice(action.payload.index, 1);
            newComps[sectionId].other = newArr
            return { ...state, components: newComps }

            

        case SET_EDIT_FIELD: 
        newComps = { ...state.components }
        let otherArray = [...newComps.attributes.other.filter(f=> f.templateType === state.selectedSubTemplate)]
        let mandatArray = [...newComps.attributes.mandatory.filter(f=> f.templateType === state.selectedSubTemplate)]
        return {...state,components: {...state.components,attributes: {mandatory: mandatArray, other: otherArray}}, editField: { ...action.payload }}

        case REMOVE_EDIT_FIELD: return { ...state, editField: null }

        case EDIT_FIELD:
            newComps = JSON.parse(JSON.stringify(state.components))
            newComps[sectionId][action.payload.type][action.payload.index] = action.payload.field
            return { ...state, components: newComps }
            

        default: return state

    }
}