/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { Radio, Grid, Card, Typography, CardHeader, CardContent, FormControl, FormControlLabel, IconButton, InputAdornment, Button, Checkbox, Breadcrumbs, Box, RadioGroup, MenuItem } from '@mui/material'
import { CustomInput } from '../../../components/FormUI';
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { Controller, useForm, useWatch } from "react-hook-form";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import { Link } from "react-router-dom";
import history from "../../../services/history";
import { showToast } from "../../../services/functions";
import { Search } from "@mui/icons-material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { getAllRoutes, getRouteSchedculeData, flushScheduleData, getAllGeofences, emptyRouteSchedule, getAllDevices, editTrip, affixTrip } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ImagePreviewModal from '../../Components/Generic/ImagePreviewModal';
import UploadIcon from '@mui/icons-material/Upload';
import CancelIcon from '@mui/icons-material/Cancel';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DownloadIcon from '@mui/icons-material/Download';
import { staticOptionDriver, efixedclosureTypes, fileTypes, otherClsoureTypes } from '../../../services/constants';
import { Close, SellOutlined } from "@mui/icons-material";
import { CustomTimeField } from '../../../components/FormUI/CustomTimeField';
import { DateTimePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { createTrip, saveDraftTrip, checkAvailability, uploadImagetoS3, getTemplate, getAllTrips, emptyImage, getAllOrganisations, getAllUsers, tripDetails, emptyTripDetails, getObj, emptyObj, driverListing, checkTemplateExistance } from "../../../redux/actions";
import moment from 'moment';
import { format, setHours, setMinutes } from "date-fns";
import { getInputFieldForTableRow } from '../../Components/Utils';
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
import { havePermission } from '../../../utils/permissionCheck';
import GeoIcon from '../../../static/images/sidebarIcons/GeoIcon';
import schedule from "../../../assets/marker/schedule.png"
import { all } from 'axios';
import CustomSubmitBtn from '../../../components/FormUI/CustomSubmitBtn';

let payload = {
    limit: 500,
    page: 1
}
let driverPayload = {
    "limit": 1000,
    "page_no": 1,
    "type": "listing",
    "isThirdParty": false
}
export default function CreateTrip(props) {
    const editField = props?.location?.state || {}
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [availabilityLOader, setAvailabilityLoader] = useState(false)
    const [editLoader, setEdit] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [affixLoader, setAffixLoader] = useState(false)
    const [editAffixLoader, setEditAffixLoader] = useState(false)
    const { message, error } = useSelector((state) => state.common);
    const isDarkThemeEnabledSelector = useSelector(state => state.theme.themeDark);
    const [imageModal, setImageModal] = useState(false)
    const [imgInfo, setImgInfo] = useState("")
    const { allRoutes } = useSelector((state) => state.routes);
    const selectedRouteSchedules = useSelector((state) => state.route.selectedRouteSchedules);
    const GeofencesListing = useSelector((state) => state.geofencelist.geofenceListing);
    const imageURL = useSelector((state) => state.imageList.imagePaths);
    const getImageObj = useSelector((state) => state.imageList.imagePathfromBase);
    const [selectedRow, setSelectedRow] = useState(null);
    const [scheduleExist, setScehduleExist] = useState(false);
    const [driverModalIndex, setDriverModalIndex] = useState(null)
    const [storeIndex, setStoreIndex] = useState(0)
    const [routeViapoints, setRouteViapoints] = useState([])
    const [addMoreCheck, setAddMoreCheck] = useState(false)
    const [driverInnerIndex, setDriverInnerIndex] = useState(null)
    const [scheduleIndex, setScheduleIndex] = useState(0)
    const [imgIndex, setImgIndex] = useState(null)
    const [scheduleData, setScheduleData] = useState({})
    const [devicesArr, setDevicesArr] = useState([{ arr: [] }])
    const [routeScheduleState, setRouteScheduleState] = useState({ data: [] })
    const [userState, setUserState] = useState({ data: [] })
    const [templateArray, setTemplateArray] = useState([])
    const [tempLoader, setTempLoader] = useState(false)
    // const [vehicleNumberError, setVehicleNumberError] = useState(false)
    const [imageUrlFolder, setImageUrlFolder] = useState([])
    const [editImgs, setEditImgs] = useState([]) //----images for edit 
    const completeFields = useSelector((state) => state.templates.templateExistence?.attributes || []);
    const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
    const [modalFields, setModalFields] = useState({
        driverName: '',
        mobileNo: ''
    })
    const [imageBase, setImageBase] = useState([])
    const templateExistence = useSelector((state) => state.templates.templateExistence);
    const detailTrips = useSelector((state) => state.tripsData.tripDetails);
    const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
    const Users = useSelector((state) => state.userlist.allUsers);
    const deviceType = useSelector((state) => state.tripsData.availavleDeviceType?.deviceType || '');
    const deviceAvailabilityMssg = useSelector((state) => state.tripsData.availavleDeviceMssg || '');
    const deviceavailableStatus = useSelector((state) => state.tripsData.availableStatus || '');
    const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions || {};
    const listOfDrivers = useSelector((state) => state.driverReducer.driverList);
    const [driversArray, setDriversArray] = useState({
        driversArr: []
    })
    let url = window.location.pathname
    const { allDevices } = useSelector((state) => state.deviceslist);


    // useEffect(() => {

    //     if (url.includes("trips/create")) {
    //         dispatch(emptyTripDetails())
    //         setTripData({
    //             data: [
    //                 {
    //                     tripId: "",
    //                     routeNotAvailable: false,
    //                     route: { id: "", routeName: "" },
    //                     routeSchedule: {},
    //                     dynamicArray: [],
    //                     location: false,
    //                     sameTripId: false,
    //                     sameRoute: false,
    //                     driverData: {
    //                         vehicleNumber: "",
    //                         driver: [{
    //                             _id: "",
    //                             mobileNo: "",
    //                             licenseNo: "",
    //                             driverName: "",
    //                         }],
    //                         images: []
    //                     },
    //                     devices: [
    //                         {
    //                             deviceId: "",
    //                             address: {},
    //                             primary: false,
    //                             remark: ""
    //                         }
    //                     ],
    //                     // closureData :{
    //                     tripClosure: "",
    //                     geofence: { id: "" },
    //                     // }


    //                 },
    //             ]
    //         })
    //     }
    // }, [window.location.href])

    useEffect(() => {
        if (listOfDrivers && listOfDrivers.length) {
            let temp = { ...driversArray }
            temp.driversArr.push(listOfDrivers)
            setDriversArray(temp)
        }
    }, [listOfDrivers])
    const getDriversList = (index) => {
        let arr = [staticOptionDriver, ...driversArray && driversArray.driversArr[index] ? driversArray.driversArr[index] : []]

        let licenseNumbers = [];

        const data = tripData.data[index];

        data.driverData.driver.forEach((driver) => {
            licenseNumbers.push(driver.licenseNo);
        });

        let modifiedList = arr.filter((driver) => {
            return !licenseNumbers?.includes(driver.license_no);
        });

        const modifiedData = modifiedList.map(item => ({
            _id: item._id,
            driverName: item?.name,
            licenseNo: item?.license_no,
            mobileNo: item?.mobile,
            sso_id: item?.formData?.sso_id
        }));



        return modifiedData
    }

    useEffect(() => {
        if (templateExistence && templateExistence?._id) {
            dispatch(getTemplate({ template_id: templateExistence && templateExistence?._id }))
        }
    }, [templateExistence])

    const {
        handleSubmit,
        control,
        register,
        reset,
        setValue,
        setError,
        getValues,
        formState: { errors, dirtyFields },
    } = useForm();
    const themeType = isDarkThemeEnabledSelector ? require('../../../static/styles/darktheme') : require('../../../static/styles/theme');
    const {
        buttonClasses,
        inputClasses,
        cardClasses,
        textClasses,
        driverModal
    } = themeType;

    const [primaryCheck, setPrimaryCheck] = useState(false)

    const moveBack = () => {
        setTripData({
            data: [
                {
                    tripId: "",
                    routeNotAvailable: false,
                    route: { id: "", routeName: "" },
                    routeSchedule: {},
                    location: false,
                    sameTripId: false,
                    sameRoute: false,
                    driverData: {
                        vehicleNumber: "",
                        driver: [{
                            _id: "",
                            mobileNo: "",
                            licenseNo: "",
                            driverName: "",
                        }],
                        images: []
                    },
                    devices: [
                        {
                            deviceId: "",
                            address: {},
                            primary: false,
                            remark: ""
                        }
                    ],
                    // closureData :{
                    tripClosure: "",
                    geofence: { id: "" },
                    // }


                },
            ]
        })
        setImageBase([])
        setEditImgs([]);
        dispatch(emptyObj())
        dispatch(emptyTripDetails())
        setModalFields({ driverName: '', mobileNo: '' })
        setTimeout(() => {
            history.goBack()
        }, 120)
    }
    const dateTimePickerSlotProps = {
        textField: { size: "small", ...inputClasses.textfield },
        day: {
            sx: {
                "& .Mui-selected:hover": {
                    background: "#FF7200",
                },
            },
        },
        popper: {
            sx: {
                "& .MuiDateCalendar-root": {
                    maxHeight: "300px",
                },
                "& .MuiMultiSectionDigitalClock-root": {
                    maxHeight: "300px",
                },
            },
        },
        digitalClockSectionItem: {
            sx: {
                fontSize: "12px",
            },
        },
    };

    const [tripData, setTripData] = useState(
        {
            data: [
                {
                    tripId: "",
                    routeNotAvailable: false,
                    route: { id: "", routeName: "" },
                    routeSchedule: {},
                    dynamicArray: [],
                    location: false,
                    sameTripId: false,
                    sameRoute: false,
                    driverData: {
                        vehicleNumber: "",
                        driver: [{
                            _id: "",
                            mobileNo: "",
                            licenseNo: "",
                            driverName: "",
                        }],
                        images: []
                    },
                    devices: [
                        {
                            deviceId: "",
                            address: {},
                            primary: false,
                            remark: ""
                        }
                    ],
                    // closureData :{
                    tripClosure: "",
                    geofence: { id: "" },
                    // }


                },
            ]
        }
    )
    const isAnyDeviceIdEmpty = () => {
        return tripData.data.some(item => item.devices.some(device => device.deviceId === ''));
    };

    useEffect(() => {
        if (completeFields && completeFields.length > 0) {
            setTempLoader(true)
            const filteredData = {};
            completeFields.forEach(item => {
                filteredData[item._id] = item.value || "";
            });
            setTemplateArray([filteredData]);

            setTripData(prevTripData => {
                const updatedData = [...prevTripData.data];
                updatedData[0].dynamicArray = [filteredData];
                return { ...prevTripData, data: updatedData };
            });
            setTimeout(() => {
                setTempLoader(false)
            }, 300)

        }

    }, [completeFields]);

    const addVehicle = () => {
        let temp = { ...tripData }
        let dummy = [...imageBase]
        let viaArray = [...routeViapoints]
        let tempArray = [...templateArray]
        let scheduleArray = { ...routeScheduleState }
        let driversList = { ...driversArray }
        let dummyDevices = [...devicesArr];

        dummyDevices.push({ arr: [] })
        setDevicesArr(dummyDevices)

        driversList.driversArr.push(listOfDrivers)
        setDriversArray(driversList)

        let fieldsData = {};
        let fields = completeFields.filter(f => f._id !== "count");

        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type === "checkbox") {
                fieldsData[fields[i]._id] = [];
            } else if (fields[i].type === "file") {
                fieldsData[fields[i]._id] = null;
            } else {
                fieldsData[fields[i]._id] = "";
            }
        }

        let newRow = {
            ...fieldsData
        };

        temp?.data.push(
            {
                tripId: "",
                sameTripId: false,
                sameRoute: false,
                dynamicArray: [newRow],
                driverData: {
                    vehicleNumber: "",
                    driver: [{
                        _id: "",
                        mobileNo: "",
                        licenseNo: "",
                        driverName: "",
                    }],
                    images: []
                },
                devices: [
                    {
                        deviceId: "",
                        address: {},
                        primary: false,
                        remark: ""
                    }
                ],
                routeNotAvailable: false,
                route: { id: "", routeName: "" },
                routeSchedule: {},
                location: false,
                // closureData :{
                tripClosure: "",
                geofence: { id: "" },
                // }
            }
        )
        dummy.push({ base: [] })
        viaArray.push({ data: [] })
        tempArray.push(newRow)
        setTemplateArray(tempArray)
        scheduleArray.data.push([])
        setRouteViapoints(viaArray)
        setImageBase(dummy)

        setRouteScheduleState(scheduleArray)

        dispatch(emptyImage())
        setTripData(temp)
    }


    const deleteVehicle = (index) => {
        let temp = { ...tripData }
        let viaArray = [...routeViapoints]
        let tempArray = [...templateArray]
        let driverList = { ...driversArray }
        let dummyDevices = [...devicesArr];

        dummyDevices[index].arr.splice(index, 1)
        setDevicesArr(dummyDevices)


        driverList.driversArr.splice(index, 1)
        setDriversArray(driverList)


        temp.data.splice(index, 1)
        setTripData(temp)

        let dummy = { ...routeScheduleState }
        dummy.data.splice(index, 1)
        setRouteScheduleState(dummy)

        viaArray.splice(index, 1)
        tempArray.splice(index, 1)
        setTemplateArray(tempArray)
        setRouteViapoints([...routeViapoints, { data: [] }]);

    }
    const addDriver = (index) => {
        let temp = { ...tripData }
        temp?.data[index]?.driverData?.driver?.push({
            _id: "",
            mobileNo: "",
            licenseNo: "",
            driverName: "",
        })
        setTripData(temp)
    }
    const removeDriver = (index, driverIndex) => {
        let temp = { ...tripData }
        temp?.data[index]?.driverData?.driver?.splice(driverIndex, 1)
        setTripData(temp)
    }
    const addDevice = (index) => {
        let temp = { ...tripData }
        temp?.data[index]?.devices.push(
            {
                deviceId: "",
                address: "",
                primary: false,
                remark: ""
            }
        )
        setTripData(temp)

        let dummy = [...devicesArr];
        dummy[index].arr.push([])
        setDevicesArr(dummy)
    }
    const removeDevice = (index, deviceIndex) => {
        let temp = { ...tripData }
        temp?.data[index]?.devices.splice(deviceIndex, 1)
        setTripData(temp)

        let dummy = [...devicesArr];
        dummy[index].arr.splice(deviceIndex, 1)
        setDevicesArr(dummy)
    }
    useEffect(() => {
        dispatch(checkTemplateExistance({ category: "trip" }));
        dispatch(getAllRoutes({ ...payload, selection: ["routeName", "scheduleData", "viaPoints", "isSchedulesExists", "routeSource", "routeDestination"] }))
        dispatch(getAllGeofences({ ...payload, selection: ["name"] }))
        dispatch(driverListing(driverPayload))
        setEditImgs([]);
        setImageBase([]);
    }, [dispatch])

    // useEffect(() => {
    //     if (Object?.keys(superAdmin)?.length) {
    //         dispatch(getAllOrganisations({ selection: ["name"] }));
    //     }
    // }, [dispatch, superAdmin]);

    useEffect(() => {
        let dummy = { ...routeScheduleState }
        let temp = { ...tripData }
        if (selectedRouteSchedules.length) {
            dummy.data[storeIndex] = (selectedRouteSchedules)
            temp.data[storeIndex].routeSchedule = {}
            setRouteScheduleState(dummy)
            setTripData(temp)
        }
    }, [storeIndex, selectedRouteSchedules])

    useEffect(() => {
        let dummy = { ...userState }
        if (Users.length) {
            dummy.data[storeIndex] = [...Users]
            setUserState(dummy)
        }
    }, [storeIndex, Users])

    const _handleChange = (e, newVal, item, index, deviceIndex, driverIndex) => {
        let temp = { ...tripData }
        let dummy = [...routeViapoints]
        let modalData = { ...modalFields }
        if (item === 'route') {
            if (newVal) {
                setScheduleIndex(index)
                if (dummy[index]) {
                    dummy[index].data = newVal?.viaPoints || [];
                } else {
                    dummy.push({ data: [...(newVal?.viaPoints || [])] });
                }
                setRouteViapoints(dummy)

                if (newVal?.isSchedulesExists) {
                    dispatch(getRouteSchedculeData({ routeId: newVal?._id }));
                }
                // else {
                //     setScheduleData({})
                //     dispatch(flushScheduleData())
                // }
                temp.data[index].route.id = newVal?._id
                temp.data[index].route.routeName = newVal?.routeName
                setTripData(temp)
                setStoreIndex(index)
            }
            else {
                temp.data[index].route.id = ""
                temp.data[index].route.routeName = ""
                temp.data[index].routeSchedule = {}
                setRouteScheduleState({ data: [] })
                setTripData(temp)
            }
        }
        else if (item === 'routeSchedule') {
            if (newVal) {
                temp.data[index].routeSchedule = newVal
                setTripData(temp)
            }
            else {
                temp.data[index].routeSchedule = {}
                setTripData(temp)
            }
        }
        else if (item === 'org') {
            if (newVal) {
                temp.data[index].orgId = newVal?._id
                setTripData(temp)
                setStoreIndex(index)
                if (Object?.keys(superAdmin)?.length) {
                    // dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name"] }));
                }
            }
            else {
                temp.data[index].orgId = ""
                setTripData(temp)
            }
        }
        else if (item === 'user') {
            if (newVal) {
                temp.data[index].id = newVal?._id
                setTripData(temp)
            }
            else {
                temp.data[index].id = ""
                setTripData(temp)
            }
        }
        else if (item === 'vehicleNumber') {
            temp.data[index].driverData.vehicleNumber = e.target.value
            setTripData(temp)
        }
        else if (item === 'address') {
            temp.data[index].devices[deviceIndex].address = newVal
            setTripData(temp)
        }
        else if (item === "tripId") {
            temp.data[index].tripId = e.target.value
            setTripData(temp)
        }
        else if (item === 'deviceId') {
            const newDeviceId = e.target.value.replace(/\s/g, '');
            temp.data[index].devices[deviceIndex].deviceId = newDeviceId;
            setTripData(temp);
        }
        else if (item === 'deviceSelect') {
            if (newVal) {
                temp.data[index].devices[deviceIndex].deviceId = newVal?.deviceId
                setTripData(temp)
            }
            else {
                temp.data[index].devices[deviceIndex].deviceId = ''
                setTripData(temp)
            }
        }
        else if (item === 'radio') {
            temp.data[index].devices.forEach((device, i) => {
                device.primary = i === deviceIndex; // Set to true only for the selected radio button
            });
            setTripData(temp);
        }
        else if (item === 'locationCheckbox') {
            if (temp.data[index].location === false) {
                temp.data[index].location = true
                setTripData(temp)
            }
            else {
                temp.data[index].location = false
                temp.data[index].devices.map((data) => { return (data.address = {}) })
                setTripData(temp)
            }
        }
        else if (item === 'driverName') {
            if (newVal !== null) {
                if (newVal.driverName === 'Add Driver') {
                    setModalFields({ driverName: '', mobileNo: '' })
                    setDriverInnerIndex(driverIndex)
                    temp.data[index].driverData.driver[driverIndex]._id = ""
                    temp.data[index].driverData.driver[driverIndex].mobileNo = ""
                    temp.data[index].driverData.driver[driverIndex].licenseNo = ""
                    temp.data[index].driverData.driver[driverIndex].driverName = ""
                    setTripData(temp)
                    setDriverModalIndex(index)
                }
                else {
                    setDriverModalIndex(null)
                    setDriverInnerIndex(null)
                    temp.data[index].driverData.driver[driverIndex]._id = newVal?._id
                    temp.data[index].driverData.driver[driverIndex].mobileNo = newVal?.mobileNo
                    temp.data[index].driverData.driver[driverIndex].licenseNo = newVal?.licenseNo
                    temp.data[index].driverData.driver[driverIndex].driverName = newVal?.driverName
                    temp.data[index].driverData.driver[driverIndex].sso_id = newVal?.sso_id
                    setTripData(temp)
                }
            }
            else {
                setDriverModalIndex(null)
                temp.data[index].driverData.driver[driverIndex]._id = ""
                temp.data[index].driverData.driver[driverIndex].mobileNo = ""
                temp.data[index].driverData.driver[driverIndex].licenseNo = ""
                temp.data[index].driverData.driver[driverIndex].driverName = ""
                setTripData(temp)
            }
        }
        else if (item === 'remark') {
            temp.data[index].devices[deviceIndex].remark = e.target.value
            setTripData(temp)
        }
        else if (item === "primaryCheckBox") {
            setPrimaryCheck(!primaryCheck)
            temp.data[index].devices.forEach((device, i) => {
                device.primary = i === deviceIndex; // Set to true only for the selected radio button
            });
            setTripData(temp);
        }
        else if (item === "sametripCheck") {
            if (temp.data[index].sameTripId === true) {
                temp.data[index].sameTripId = false
                temp.data[index].tripId = ""
                setTripData(temp);
            }
            else {
                temp.data[index].sameTripId = true
                temp.data[index].tripId = temp.data[index - 1].tripId
                setTripData(temp);
            }
        }
        else if (item === "sameRouteCheck") {
            if (temp.data[index].sameRoute === true) {
                temp.data[index].sameRoute = false
                temp.data[index].route.id = ""
                temp.data[index].route.routeName = ""
                setTripData(temp);
            }
            else {
                setStoreIndex(index)
                temp.data[index].sameRoute = true
                temp.data[index].route.id = temp.data[index - 1].route?.id
                temp.data[index].route.routeName = temp.data[index - 1].route?.routeName
                dispatch(getRouteSchedculeData({ routeId: temp.data[index].route?.id }));
                setTripData(temp);
            }
        }
        else if (item === "routeAvailableCheck") {
            if (temp.data[index].routeNotAvailable === true) {
                temp.data[index].routeNotAvailable = !temp.data[index].routeNotAvailable
                setTripData(temp);
            }
            else {
                temp.data[index].routeNotAvailable = !temp.data[index].routeNotAvailable
                temp.data[index].route.id = ""
                temp.data[index].route.routeName = ""
                temp.data[index].routeSchedule = {}
                temp.data[index].location = false
                temp.data[index].devices.splice(1)
                temp.data.splice(1)
                setTripData(temp);
                setScheduleData({})
                setSelectedRow(false);
            }
        }
        else if (item === "tripClosure") {
            if (newVal) {
                temp.data[index].tripClosure = newVal.value
                setTripData(temp);
            }
            else {
                temp.data[index].tripClosure = ""
                setTripData(temp);
            }
        }
        else if (item === "geofence") {
            if (newVal) {
                temp.data[index].geofence.id = newVal._id
                setTripData(temp);
            }
            else {
                temp.data[index].geofence.id = ""
                setTripData(temp);
            }
        }
        else if (item === "driverNameModal") {
            modalData.driverName = e.target.value
            setModalFields(modalData)
        }
        else if (item === "driverMobileModal") {
            modalData.mobileNo = e.target.value
            setModalFields(modalData)
        }

    }

    const uploadImage = async (e, index) => {
        setImgIndex(index)
        let temp = [...imageBase]
        const file = e.target.files[0]
        const type = file.type
        const size = file.size;
        if (size > 4 * 1024 * 1024) {
            showToast("File size should be less than 4MB.", true);
            return;
        }
        const base64 = await convertBase64(file)
        if (base64 && fileTypes?.includes(type)) {
            if (temp[index]) {
                if (Array.isArray(temp[index].base)) {
                    temp[index].base.push(base64);
                } else {
                    temp[index].base = [temp[index].base, base64];
                }
            } else {
                temp.push({ base: [base64] });
            }
            let dummy = { uploadObjects: [base64] }
            setImageBase(temp);
            let tempEditImgs = editImgs;
            dispatch(uploadImagetoS3({ ...dummy }, (res) => {
                if (res && res?.success) {
                    const newImg = {
                        filePath: res?.data[0],
                        type: "web"
                    }
                    if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(
                            function (position) {
                                newImg.latitude = position.coords.latitude?.toString();
                                newImg.longitude = position.coords.longitude?.toString();
                            },
                            function (error) {
                                console.error(
                                    "Error getting geolocation:",
                                    error.message
                                );
                            }
                        );
                    }
                    tempEditImgs?.push(newImg);
                    setEditImgs([...tempEditImgs]);
                }
            }))
        }
        else {
            showToast("Only png,jpg,doc,pdf are allowed to upload.", true);
        }
    }
    useEffect(() => {
        if (imageURL && imageURL !== "" && imgIndex !== null) {
            let dummy = [...imageUrlFolder]
            let temp = { ...tripData }
            dummy.push(
                {
                    filePath: imageURL[0], type: "web"
                }
            )
            temp?.data[imgIndex]?.driverData?.images.push(imageURL)
            setImageUrlFolder(dummy)
            setTripData(temp)
        }
    }, [imageURL, imgIndex])

    const convertBase64 = (file) => {
        if (file) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                }, 400)
            })
        }
        else {
        }
    }

    const onSubmit = (data) => {
        let duplicateData = JSON.parse(JSON.stringify(tripData));
        setLoader(true);
        for (let i = 0; i < duplicateData?.data?.length; i++) {
            if (duplicateData.data[i].routeNotAvailable === true) {
                delete duplicateData.data[i].routeSchedule;
                delete duplicateData.data[i].route;
            }
            else if (duplicateData.data[i].routeNotAvailable === false) {
                delete duplicateData.data[i].tripClosure;
                delete duplicateData.data[i].geofence;
            }

            let updatedImgUrlFolder = imageUrlFolder;

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        const latitude = position.coords.latitude?.toString();
                        const longitude = position.coords.longitude?.toString();

                        updatedImgUrlFolder.forEach(obj => {
                            obj.latitude = latitude;
                            obj.longitude = longitude;
                        });

                        duplicateData.data[i].image = updatedImgUrlFolder;
                        setAffixLoader(true)
                        dispatch(
                            createTrip({ ...duplicateData }, () => {
                                setLoader(false);
                                setAffixLoader(false)
                            })
                        );

                    },
                    function (error) {
                        console.error("Error getting geolocation:", error.message);
                        duplicateData.data[i].image = imageUrlFolder;
                        setAffixLoader(true)
                        dispatch(
                            createTrip({ ...duplicateData }, () => {
                                setLoader(false);
                                setAffixLoader(false)
                            })
                        );
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                duplicateData.data[i].image = imageUrlFolder;
                setAffixLoader(true)
                dispatch(
                    createTrip({ ...duplicateData }, () => {
                        setLoader(false);
                        setAffixLoader(false)
                    })
                );
            }
        }

        // dispatch(
        //     createTrip(duplicateData, () => {
        //         setLoader(false);
        //     })
        // );
    };

    const onEdit = () => {
        setEdit(true)
        setEditAffixLoader(true)
        let duplicateData = JSON.parse(JSON.stringify(tripData));
        for (let i = 0; i < duplicateData.length; i++) {
            if (duplicateData[i].data.routeNotAvailable === true) {
                delete duplicateData[i].data.routeSchedule;
                delete duplicateData[i].data.route;
            }
            else if (duplicateData[i].data.routeNotAvailable === false) {
                delete duplicateData[i].data.tripClosure;
                delete duplicateData[i].data.geofence;
            }
        }
        if (editField?.cdtn === 'affix') {
            let affixPaylaod = {
                trip_id: duplicateData?.data[0]?._id || '',
                devices: duplicateData?.data[0]?.devices.map(device => device.deviceId) || [],
                vehicleNumber: duplicateData?.data[0]?.driverData?.vehicleNumber || '',
                routeSchedule: duplicateData?.data[0]?.routeSchedule,
                image: editImgs ?? [],
            }

            dispatch(
                affixTrip({ ...affixPaylaod }, () => {
                    setEdit(false)
                    setEditAffixLoader(false)
                })
            );

            // if(imageUrlFolder && imageUrlFolder?.length){
            //     let updatedImgUrlFolder = imageUrlFolder;

            //     if ("geolocation" in navigator) {
            //         navigator.geolocation.getCurrentPosition(
            //         function (position) {
            //             const latitude = position.coords.latitude?.toString();
            //             const longitude = position.coords.longitude?.toString();

            //             updatedImgUrlFolder.forEach(obj => {
            //             obj.latitude = latitude;
            //             obj.longitude = longitude;
            //             });

            //             affixPaylaod.image = updatedImgUrlFolder;

            //             dispatch(
            //                 affixTrip({...affixPaylaod}, () => {
            //                     setEdit(false)
            //                 })
            //             );
            //         },
            //         function (error) {
            //             console.error("Error getting geolocation:", error.message);

            //             affixPaylaod.image = imageUrlFolder;
            //             dispatch(
            //                 affixTrip({...affixPaylaod}, () => {
            //                     setEdit(false)
            //                 })
            //             );
            //         }
            //         );
            //     } else {
            //         console.error("Geolocation is not supported by this browser.");

            //         affixPaylaod.image = imageUrlFolder;
            //         dispatch(
            //             affixTrip({...affixPaylaod}, () => {
            //                 setEdit(false)
            //             })
            //         );
            //     }
            // }else{
            //     dispatch(
            //         affixTrip(affixPaylaod, () => {
            //             setEdit(false)
            //         })
            //     );
            // }

        }
        else {
            duplicateData.data[0].images = editImgs ?? [];
            duplicateData.data[0].driverData.images = [];
            dispatch(
                editTrip(duplicateData, () => {
                    setEdit(false)
                    setEditAffixLoader(false)
                })
            );
        }
    }

    const saveAsDraft = () => {
        let duplicateData = JSON.parse(JSON.stringify(tripData));
        for (let i = 0; i < duplicateData.data.length; i++) {
            if (duplicateData.data[i].routeNotAvailable === true) {
                delete duplicateData.data[i].routeSchedule;
                delete duplicateData.data[i].route;
            }
            else if (duplicateData.data[i].routeNotAvailable === false) {
                delete duplicateData.data[i].tripClosure;
                delete duplicateData.data[i].geofence;
            }

            let updatedImgUrlFolder = imageUrlFolder;

            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        const latitude = position.coords.latitude?.toString();
                        const longitude = position.coords.longitude?.toString();

                        updatedImgUrlFolder.forEach(obj => {
                            obj.latitude = latitude;
                            obj.longitude = longitude;
                        });

                        duplicateData.data[i].image = updatedImgUrlFolder;
                        setBtnLoader(true)
                        setLoader(true);
                        dispatch(
                            saveDraftTrip({ ...duplicateData }, (res) => {
                                // if(res && res?.success === false && res?.message === "Please provide route."){
                                //     setVehicleNumberError(true);
                                // }
                                setLoader(false);
                                setBtnLoader(false)
                            })
                        );
                    },
                    function (error) {
                        console.error("Error getting geolocation:", error.message);
                        duplicateData.data[i].image = imageUrlFolder;
                        setLoader(true);
                        setBtnLoader(true)
                        dispatch(
                            saveDraftTrip({ ...duplicateData }, (res, err) => {
                                setBtnLoader(false)
                                setLoader(false);
                            })
                        );
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                duplicateData.data[i].image = imageUrlFolder;
                setLoader(true);
                setBtnLoader(true)
                dispatch(
                    saveDraftTrip({ ...duplicateData }, () => {
                        setLoader(false);
                        setBtnLoader(false)
                    })
                );
            }

        }

    }

    useEffect(() => {
        console.log('---', btnLoader)
    }, [btnLoader])

    const checkAvaialabiltyFnc = (index, deviceIndex) => {
        let temp = { ...tripData }
        let availabilityData = {}
        if (temp.data[index].routeNotAvailable) {
            availabilityData = {
                "deviceId": temp.data[index].devices[deviceIndex].deviceId,
            }
        }
        else if (!temp.data[index].routeNotAvailable && !Object.keys(editField).length) {

            availabilityData = {
                "deviceId": temp.data[index].devices[deviceIndex].deviceId,
                "route": { "id": temp?.data[index]?.route?.id }

            }
        }
        else if (!temp.data[index].routeNotAvailable && Object.keys(editField).length) {

            availabilityData = {
                "deviceId": temp.data[index].devices[deviceIndex].deviceId,
                "route": { "id": temp?.data[index]?.route?._id }

            }
        }
        setAvailabilityLoader(true)
        dispatch(
            checkAvailability(availabilityData, () => {
                setAvailabilityLoader(false)
            }));
    }

    useEffect(() => {
        if (message && loader) {
            showToast(message, false);
            setLoader(true);
            if (!addMoreCheck) {
                history.push('/dashboard/trips')
            }
            setTripData({
                data: [
                    {
                        tripId: "",
                        routeNotAvailable: false,
                        route: { id: "", routeName: "" },
                        routeSchedule: {},
                        location: false,
                        sameTripId: false,
                        sameRoute: false,
                        driverData: {
                            vehicleNumber: "",
                            driver: [{
                                _id: "",
                                mobileNo: "",
                                licenseNo: "",
                                driverName: "",
                            }],
                            images: []
                        },
                        devices: [
                            {
                                deviceId: "",
                                address: {},
                                primary: false,
                                remark: ""
                            }
                        ],
                        // closureData :{
                        tripClosure: "",
                        geofence: { id: "" },
                        // }


                    },
                ]
            })
            dispatch(emptyObj())
            setModalFields({ driverName: '', mobileNo: '' })
            dispatch(emptyTripDetails())
            setTimeout(() => {
                setAddMoreCheck(false)
            }, 1000)
            setImageBase([])
        }
        if (error && loader) {
            showToast(error, true);
            setLoader(true);
        }
    }, [message, error]);

    useEffect(() => {
        if (message && editLoader) {
            setEdit(true);
            setTripData({
                data: [
                    {
                        tripId: "",
                        routeNotAvailable: false,
                        route: { id: "", routeName: "" },
                        routeSchedule: {},
                        location: false,
                        sameTripId: false,
                        sameRoute: false,
                        driverData: {
                            vehicleNumber: "",
                            driver: [{
                                _id: "",
                                mobileNo: "",
                                licenseNo: "",
                                driverName: "",
                            }],
                            images: []
                        },
                        devices: [
                            {
                                deviceId: "",
                                address: {},
                                primary: false,
                                remark: ""
                            }
                        ],
                        // closureData :{
                        tripClosure: "",
                        geofence: { id: "" },
                        // }


                    },
                ]
            })
            dispatch(emptyObj())
            setModalFields({ driverName: '', mobileNo: '' })
            dispatch(emptyTripDetails())
            setImageBase([])
            setScheduleData({})
            showToast(message, false);
            history.push('/dashboard/trips')
        }
        if (error && editLoader) {
            showToast(error, true);
            setEdit(true);
        }
    }, [message, error]);

    const removeImage = (index, ind) => {
        let temp = [...imageBase]
        let tempEditImgs = editImgs;
        temp[index].base.splice(ind, 1)
        tempEditImgs?.splice(ind, 1)
        setImageBase([...temp])
        setEditImgs([...tempEditImgs]);

        let dummy = { ...tripData }
        dummy.data[index].driverData.images.splice(ind, 1)
        setTripData(dummy);

    }

    const previewImage = (index, ind) => {
        setImageModal(true)
        setImgInfo(imageBase[index].base[ind])
    }

    const downloadImage = (index, ind) => {
        var temp = [...imageBase]
        var a = document.createElement("a");
        a.href = temp[index].base[ind]
        a.download = 'sample'
        a.click();
    }
    const handleSelectedRow = (selectedSchedule, index) => {
        setScheduleIndex(index)
        setScheduleData(selectedSchedule)
        setSelectedRow(true);
    };
    useEffect(() => {
        if (Object?.keys(editField)?.length && editField?.param !== "" && editField?.id !== "") {
            dispatch(tripDetails({ id: editField?.id }))
        }
    }, [editField])

    useEffect(() => {
        let temp = { ...tripData }
        if (url.includes("trips/edit")) {
            if (Object?.keys(detailTrips)?.length) {
                temp.data = [detailTrips]
                setTimeout(() => {
                    setTemplateArray(detailTrips?.dynamicArray)
                    // setTripData(temp);
                }, 20);
                setRouteViapoints([{ data: temp?.data[0].route?.viaPoints }])

                if (!detailTrips?.devices?.length && temp?.data[0]?.devices) {
                    temp?.data[0]?.devices?.push(
                        {
                            deviceId: "",
                            address: {},
                            primary: false,
                            remark: ""
                        }
                    )
                }

                if (detailTrips?.routeSchedule) {
                    setTimeout(() => {
                        setTripData(temp);
                        setScehduleExist(true)
                        setScheduleIndex(0)
                        setScheduleData(detailTrips?.routeSchedule)
                        setSelectedRow(true);
                    }, 20)
                }
                else {
                    setTimeout(() => {
                        setScehduleExist(false)
                        setScheduleIndex(0)

                        const updatedData = [...temp.data];
                        if (!detailTrips?.route?.routeDestination) {
                            updatedData[0] = { ...updatedData[0], routeSchedule: { ...updatedData[0].routeSchedule, ...detailTrips?.route, routeDestination: {} } };
                            setScheduleData({ ...detailTrips?.route, routeDestination: {} })
                        } else {
                            updatedData[0] = { ...updatedData[0], routeSchedule: detailTrips?.route };
                            setScheduleData(detailTrips?.route)
                        }

                        setTripData({ data: updatedData });
                        setSelectedRow(true);

                    }, 20)
                }
                if (detailTrips?.devices?.length >= 2) {
                    setPrimaryCheck(true)
                }
                if (detailTrips?.images?.length) {
                    let tempImageBase = [];
                    for (let i = 0; i < detailTrips?.images?.length; i++) {
                        dispatch(getObj({ listObjects: [detailTrips?.images[i]?.filePath] }, (res) => {
                            if (res && res?.success) {
                                const imgObj = res?.data;
                                if (imgObj) {
                                    let baseOfImg = imgObj && imgObj[0] && imgObj[0]?.base64 || ''
                                    if (tempImageBase[0]) {
                                        if (Array.isArray(tempImageBase[0].base)) {
                                            tempImageBase[0].base.push(baseOfImg);
                                        } else {
                                            tempImageBase[0].base = [tempImageBase[0].base, baseOfImg];
                                        }
                                    } else {
                                        tempImageBase.push({ base: [baseOfImg] });
                                    }
                                    setImageBase([...tempImageBase]);
                                }
                            }
                        }))
                    }
                    setEditImgs(detailTrips?.images);
                }
            }
        }
        else {

        }

    }, [detailTrips])

    // useEffect(() => {
    //     if (getImageObj) {
    //         let temp = imageBase;
    //         let baseOfImg = getImageObj && getImageObj[0] && getImageObj[0]?.base64 || ''
    //         if (temp[0]) {
    //             if (Array.isArray(temp[0].base)) {
    //                 temp[0].base.push(baseOfImg);
    //             } else {
    //                 temp[0].base = [temp[0].base, baseOfImg];
    //             }
    //         } else {
    //             temp.push({ base: [baseOfImg] });
    //         }
    //         setImageBase(temp)
    //         dispatch(emptyObj());
    //         return () => {
    //             dispatch(emptyObj())
    //         }
    //     }
    // }, [getImageObj])

    const addDriverFunction = (index) => {
        let temp = { ...tripData }
        temp.data[index].driverData.driver[driverInnerIndex].driverName = modalFields.driverName
        temp.data[index].driverData.driver[driverInnerIndex].mobileNo = modalFields.mobileNo
        setTripData(temp)
        setDriverModalIndex(null)
    }

    const handleChange = async (event, i, fieldId) => {
        const targetField = completeFields.filter(f => f._id == fieldId)[0];
        let temp = { ...tripData }
        let oldItems = [...templateArray];
        let oldObject = { ...oldItems[i] };

        if (targetField.type == "checkbox") {
            let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
            if (event.checked) {
                oldValues.push(event.value);
            }
            else {
                let pos = oldValues.indexOf(event.value);
                oldValues.splice(pos, 1)
            }
            oldObject[fieldId] = [...oldValues];
        }


        else {
            oldObject[fieldId] = event.target.value;
            temp.data[i].dynamicArray[0][fieldId] = event.target.value;
        }
        oldItems[i] = { ...oldObject };
        setTemplateArray([...oldItems]);
        setTripData(temp)
    }
    const getItemRow = (i) => {
        const others = completeFields.map((of) => {
            let component = { ...of };
            let width = '96%'
            return (
                <>
                    {
                        getInputFieldForTableRow(component, templateArray, i, handleChange, width, isDarkThemeEnabledSelector)
                    }

                </>
            )
        })
        return [...others]
    }

    const searchDevice = (item, index, deviceIndex) => {
        if (tripData.data[index].devices[deviceIndex].deviceId.length >= 4) {
            let payload = {
                "q": tripData.data[index].devices[deviceIndex].deviceId,
                "pageNumber": 1,
                "limit": 20,
                "selection": ['client_id']
            }


            dispatch(getAllDevices(payload, (res) => {
                if (res && res?.length > 0) {
                    const updatedArray = res.map(obj => {
                        return { deviceId: obj.client_id };
                    });
                    let temp = [...devicesArr];
                    temp[index].arr[deviceIndex] = updatedArray
                    setDevicesArr(temp)

                }
                else {
                    showToast('No Device found.', true)
                }
            })
            );
        }
        else {
            showToast('Write atleast 4 characters.', true)
        }
    }


    useEffect(() => {
        if (deviceAvailabilityMssg && availabilityLOader) {
            setAvailabilityLoader(true);
        }
        if (deviceavailableStatus && availabilityLOader) {
            setAvailabilityLoader(true);
        }
    }, [deviceAvailabilityMssg, deviceavailableStatus]);

    return (
        <>
            <form>

                <Grid item={true} sm={12} xs={12} >
                    <Grid container>
                        <Grid item={true} sm={6} xs={4} >
                            <Typography sx={{ ...textClasses.cardTitle }}>
                                {editField?.param === 'ViewEdit' ? 'Edit Trip' : 'Create Trip'}
                            </Typography>
                        </Grid>
                        <Grid item={true} sm={6} xs={6} sx={{ displey: 'flex', display: 'flex', justifyContent: 'flex-end', height: '36px' }}  >
                            {/* <Button onClick={() => moveBack()} variant='outlined' size='small' sx={{ ...buttonClasses.small, color: buttonClasses.lynkitBlackFill }}>Back</Button> */}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid>
                        <Breadcrumbs sx={{ mb: 1 }}>
                            <Typography
                                sx={{
                                    ...textClasses.normalText,
                                    fontSize: "12px",
                                    color: themeType.default.themeOrange,
                                }}
                            >
                                <Link
                                    style={{
                                        color: themeType.default.themeOrange,
                                        textDecoration: "none",
                                    }}
                                    to={{pathname: "/dashboard/trips", state: {from: "create-trip-page"}}}
                                >
                                    Trips
                                </Link>
                            </Typography>

                            <Typography
                                sx={{ ...textClasses.normalText, fontSize: "12px" }}
                            >
                                {editField?.param === 'ViewEdit' ? 'edit' : 'create'}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>

                <Grid className='parentModal' container item={true} xs={12} sx={{ height: '71vh', overflow: 'auto' }}>
                    <Grid item sm={selectedRow ? 9 : 12} >


                        {tripData?.data?.map((item, index) => {
                            return (
                                <Box key={`trip-${index}`} sx={{ ...themeType.borderAll, borderBottom: '0px solid #fff', width: '100%', position: 'relative' }} mt={0} mb={0}>
                                    {!tripData?.data[0]?.routeNotAvailable ?
                                        !Object?.keys(editField)?.length ?
                                            <>
                                                {tripData?.data?.length - 1 === index ?
                                                    <Button
                                                        id={`add-btn-${index}`}
                                                        key={`add-btn-${index}`}
                                                        variant="outlined"
                                                        size="small"
                                                        className='addGreenBtn'
                                                        sx={{
                                                            ...buttonClasses.lynkitGreenEmpty,
                                                            marginLeft: "14px",
                                                            minHeight: "34px",
                                                        }}
                                                        onClick={() => addVehicle()}
                                                    >
                                                        Add Vehicle
                                                    </Button>
                                                    : ''}
                                                {tripData?.data?.length === 1 ? "" :
                                                    <Button
                                                        id={`remove-btn-${index}`}
                                                        key={`remove-btn-${index}`}
                                                        variant="outlined"
                                                        size="small"
                                                        className={tripData?.data?.length - 1 === index ? 'deleteredBtn' : 'addGreenBtn'}
                                                        sx={{
                                                            ...buttonClasses.lynkitRedEmpty,
                                                            marginLeft: "14px",
                                                            minHeight: "34px"
                                                        }}
                                                        onClick={() => deleteVehicle(index)}
                                                    >
                                                        Delete Vehicle
                                                    </Button>
                                                }
                                            </>
                                            : null
                                        : null}
                                    <Typography className='indexBox'>{index + 1}</Typography>

                                    {/* {Object?.keys(superAdmin)?.length ?
                                        <Grid item spacing={2} container mb={0} md={12} p={0} pl={2} pr={2} >


                                            <Grid key={`org-${index}`} item md={4} pl={2} pr={2} style={{ width: '100%' }}>
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                                >
                                                    <Controller
                                                        name={`orgId-${index}`}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Organisation is required",
                                                            },
                                                        }}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    selectStylesOverride={{ ...selectStylesOverride, width: '97%' }}
                                                                    key={`organisation-${index}`}
                                                                    value={value || null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleChange(e, newVal, 'org', index)
                                                                        setError('orgId', { type: "custom", message: "" });
                                                                    }}
                                                                    name={name}
                                                                    required
                                                                    label="Organisation"
                                                                    errors={errors}
                                                                    getOptionLabel={(option) => { return option?.name }}
                                                                    filterSelectedOptions
                                                                    labelKey="name"
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option._id === value.id && option.name === value.name
                                                                    }
                                                                    options={orgListing && orgListing}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid key={`user-3-${index}`} item md={4} style={{ width: '100%' }}>
                                                <FormControl
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width:'96%' }}
                                                >
                                                    <Controller
                                                        name={`id-${index}`}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "User is required",
                                                            },
                                                        }}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    selectStylesOverride={{ ...selectStylesOverride, width: '96%' }}
                                                                    key={`id-${index}`}
                                                                    value={value || null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleChange(e, newVal, 'user', index)
                                                                        setError('id', { type: "custom", message: "" });
                                                                    }}
                                                                    name={name}
                                                                    required
                                                                    label="User"
                                                                    errors={errors}
                                                                    getOptionLabel={(option) => { return option?.name }}
                                                                    filterSelectedOptions
                                                                    labelKey="name"
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option._id === value.id && option.name === value.name
                                                                    }
                                                                    options={userState && userState?.data[index]}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>

                                            </Grid>



                                        </Grid>
                                        : null} */}

                                    <Grid item spacing={2} container mb={0} md={12} p={2} pt={1} >
                                        <Grid item mb={0} md={12}  >
                                            {index !== 0 && tripData?.data[0]?.tripId !== "" ?
                                                <FormControlLabel
                                                    sx={{
                                                        color: "rgb(128, 128, 128)",
                                                        "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
                                                        marginRight: '15px'
                                                    }}
                                                    label="Same Trip Id as above"
                                                    control={
                                                        <Checkbox
                                                            id={`tripId-${index}`}
                                                            key={`tripId-${index}`}
                                                            sx={{
                                                                color: "#ff7200",
                                                                "&.Mui-checked": {
                                                                    color: "#ff7200",
                                                                }
                                                            }}
                                                            checked={item?.sameTripId}
                                                            onClick={(e) => {
                                                                e.preventDefault(); // prevent blur
                                                                _handleChange(e, null, 'sametripCheck', index)
                                                            }}
                                                        />
                                                    }
                                                />
                                                : null}
                                            {index !== 0 && tripData?.data[0]?.route?.id ?
                                                <FormControlLabel
                                                    sx={{
                                                        color: "rgb(128, 128, 128)",
                                                        "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
                                                    }}
                                                    label="Same Route as above"
                                                    control={
                                                        <Checkbox
                                                            id={`route-checkbox-${index}`}
                                                            key={`route-checkbox-${index}`}
                                                            sx={{
                                                                color: "#ff7200",
                                                                "&.Mui-checked": {
                                                                    color: "#ff7200",
                                                                }
                                                            }}
                                                            checked={item?.sameRoute}
                                                            onClick={(e) => {
                                                                e.preventDefault(); // prevent blur
                                                                _handleChange(e, null, 'sameRouteCheck', index)
                                                            }}
                                                        />
                                                    }
                                                />
                                                : null}
                                        </Grid>





                                        <Grid key={`trip-2-${index}`} item md={4} pl={2} pr={2} style={{ width: '100%' }}>
                                            <FormControl sx={{ ...selectStylesOverride, width: '97%' }}>
                                                <Controller
                                                    defaultValue=""
                                                    name="tripId"
                                                    control={control}
                                                    id={`tripId-field-${index}`}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        return (
                                                            <CustomInput
                                                                placeholder="Trip Id"
                                                                disabled={Object?.keys(editField)?.length ? true : false}
                                                                key={`tripID-${index}`}
                                                                label="Trip Id"
                                                                required={false}
                                                                selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                inputClasses={inputClasses}
                                                                // name={name}
                                                                errors={errors}
                                                                value={item?.tripId}
                                                                onChange={(e, newVal) => {
                                                                    _handleChange(e, newVal, 'tripId', index)
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid key={`trip-3-${index}`} item md={4} style={{ width: '100%' }}>

                                            <FormControl sx={{ ...selectStylesOverride, width: "92%", display: 'flex' }}>
                                                <Controller
                                                    name={`routeName-${index}`}
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        return (
                                                            <CustomSelect
                                                                selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                key={`routeName-${index}`}
                                                                label="Route Name"
                                                                value={item?.route || null}
                                                                onChange={(e, newVal) => {
                                                                    _handleChange(e, newVal, 'route', index)
                                                                    setScehduleExist(newVal?.isSchedulesExists)
                                                                    if (!newVal?.isSchedulesExists) {
                                                                        dispatch(flushScheduleData())
                                                                        setTimeout(() => {
                                                                            handleSelectedRow(newVal, index)
                                                                        }, 500)

                                                                        const updatedData = [...tripData.data];
                                                                        updatedData[index] = { ...updatedData[index], routeSchedule: {} };
                                                                        const updatedTripData = { ...tripData, data: updatedData };
                                                                        setTripData(updatedTripData);
                                                                    }
                                                                    else {
                                                                        setScheduleData({})
                                                                    }
                                                                }}
                                                                disabled={item?.routeNotAvailable || Object?.keys(editField)?.length ? true : false}
                                                                name={name}
                                                                errors={errors}
                                                                getOptionLabel={(option) => { return option?.routeName || '' }}
                                                                labelKey="routeName"
                                                                filterSelectedOptions
                                                                options={allRoutes && allRoutes}
                                                                isOptionEqualToValue={(option, value) =>
                                                                    option._id === value.id && option.routeName === value.routeName
                                                                }
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                            {userPermissions &&
                                                havePermission(userPermissions, "trip", "allowTripWithoutRoute") ?
                                                <Grid key={`trip-4-${index}`} item sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', right: '15px' }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            color: "rgb(128, 128, 128)",
                                                            "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
                                                            position: 'relative',
                                                            left: '13px'
                                                        }}
                                                        label="Route Not Available"
                                                        control={
                                                            <Checkbox
                                                                id="select-all-checkbox"
                                                                key={`selectAll-${index}`}
                                                                disabled={Object?.keys(editField)?.length ? true : false}
                                                                sx={{
                                                                    color: "#ff7200",
                                                                    "&.Mui-checked": {
                                                                        color: "#ff7200",
                                                                    },
                                                                }}
                                                                checked={Object?.keys(editField)?.length ? (item?.routeNotAvailable ? true : false) : (item?.routeNotAvailable ? true : false)}
                                                                onClick={(e) => {
                                                                    sendCustomEventAnalytics("trips", "Route not available", "Route not available")
                                                                    e.preventDefault(); // prevent blur
                                                                    _handleChange(e, null, 'routeAvailableCheck', index)
                                                                }}
                                                            />
                                                        }
                                                    />
                                                    {/* {!item?.routeNotAvailable ?
                                                    <Typography sx={{ textAlign: 'end', position: 'relative', right: '27px', cursor: 'pointer', color: '#ff7200', textDecoration: 'underline', textUnderlineOffset: '2px', fontSize: '14px' }} onClick={() => { handleSelectedRow(item.routeSchedule, index) }}>View Route</Typography>
                                                    : null} */}
                                                </Grid>
                                                : null}
                                        </Grid>

                                        {item?.routeNotAvailable !== true && scheduleExist || item?.routeSchedule?.schedule ?
                                            <Grid key={`trip-5-${index}`} item md={4} style={{ width: '100%' }}>
                                                <FormControl sx={{ ...selectStylesOverride, width: "92%", display: 'flex' }}>
                                                    <Controller
                                                        name="routeSchedule"
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    label="Route Schedule"
                                                                    key={`routeSchdule-${index}`}
                                                                    value={item?.routeSchedule ? Object?.keys(item?.routeSchedule).length ? item?.routeSchedule : null : null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleChange(e, newVal, 'routeSchedule', index)
                                                                        setTimeout(() => {
                                                                            handleSelectedRow(item.routeSchedule, index)
                                                                        }, 50)
                                                                    }}
                                                                    name={name}
                                                                    errors={errors}
                                                                    disabled={item?.routeNotAvailable || detailTrips?.routeSchedule ? true : false}
                                                                    getOptionLabel={(option) => {
                                                                        if (typeof option === "string") {
                                                                            return selectedRouteSchedules?.find(
                                                                                (_) => _?.schedule == value
                                                                            )?.schedule;
                                                                        } else {
                                                                            return option?.schedule;
                                                                        }
                                                                    }}
                                                                    labelKey="schedule"
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option._id === value._id && option.schedule === value.schedule
                                                                    }
                                                                    options={routeScheduleState && routeScheduleState?.data[index]}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {/* {!item?.routeNotAvailable && Object?.keys(item?.routeSchedule ? item?.routeSchedule : {}).length ?
                                                    <Typography sx={{ textAlign: 'end', position: 'relative', right: '27px', cursor: 'pointer', color: '#ff7200', textDecoration: 'underline', textUnderlineOffset: '2px', fontSize: '14px' }} onClick={() => { handleSelectedRow(item.routeSchedule, index) }}>View Details</Typography>
                                                    : null} */}
                                                {/* {!item?.routeNotAvailable && (Object || {})?.keys(item && item?.routeSchedule)?.length ?
                                                    <Typography sx={{ textAlign: 'end', position: 'relative', right: '27px', cursor: 'pointer', color: '#ff7200', textDecoration: 'underline', textUnderlineOffset: '2px', fontSize: '14px' }} onClick={() => { handleSelectedRow(item?.routeSchedule, index) }}>View Details</Typography>
                                                    : null} */}
                                            </Grid>
                                            : null}

                                    </Grid>
                                    {tempLoader ?

                                        <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                            <ThreeDotsLoader />
                                        </Grid>
                                        :
                                        item && item?.dynamicArray && item?.dynamicArray?.length ?
                                            <Grid container mb={0} md={12} pb={3}
                                                sx={{
                                                    width: '100%',
                                                    paddingLeft: "16px",
                                                    paddingRight: "16px",
                                                    display: 'flex'
                                                }}
                                            >
                                                {getItemRow(index)}
                                            </Grid>
                                            : null
                                    }

                                    <Box key={`trip-6-${index}`} sx={{ width: '100%', maxHeight: '400px', overflow: 'auto' }}>
                                        <Grid item container mb={0} md={12} mt={1} sx={{ ...themeType.borderTop, ...themeType.borderBottom, marginTop: '12px', marginBottom: '0px' }}>
                                            <Grid item md={3.93} pl={2} pr={2} sx={{ ...themeType.borderRight, width: '100%' }} >
                                                <Grid item pt={2} >
                                                    <FormControl sx={{ ...selectStylesOverride, width: "90%" }}>
                                                        <Controller
                                                            defaultValue=""
                                                            name="vehicleNumber"
                                                            key={`vehNum-${index}`}
                                                            control={control}
                                                            render={({ field: { onChange, value, name } }) => {
                                                                return (
                                                                    <CustomInput
                                                                        placeholder={"Vehicle Number"}
                                                                        label="Vehicle Number"
                                                                        required={false}
                                                                        // hasError={vehicleNumberError}
                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                        inputClasses={inputClasses}
                                                                        // name={name}
                                                                        errors={errors}
                                                                        value={item?.driverData?.vehicleNumber}
                                                                        onChange={(e, newVal) => {
                                                                            _handleChange(e, newVal, 'vehicleNumber', index)
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid key={`driverModal-${index}`} className={index === driverModalIndex ? 'driverModal' : 'driverModal close'} sx={{ ...driverModal.modal }} mt={1.7} >
                                                    <Grid item md={12} sx={{ background: '#ff7200' }} pt={1} pb={0.5} display={'flex'} justifyContent='space-between' >
                                                        <Typography sx={{ ...textClasses.boldText, fontSize: "19px", color: '#fff' }} pl={2}>Driver Details</Typography>
                                                        <CloseIcon sx={{ color: "#fff", paddingBottom: '2px', cursor: 'pointer', paddingRight: '4px' }} onClick={() => setDriverModalIndex(null)} />
                                                    </Grid>
                                                    <Grid item md={12} mt={1.5} pl={1} pr={1}>
                                                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                                            <Controller
                                                                defaultValue=""
                                                                name="driverName"
                                                                control={control}
                                                                key={`driverName-${index}`}
                                                                id="driverName"
                                                                render={({ field: { onChange, value, name } }) => {
                                                                    return (
                                                                        <CustomInput
                                                                            placeholder="Driver Name"
                                                                            label="Driver Name"
                                                                            required={false}
                                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                            inputClasses={inputClasses}
                                                                            name={name}
                                                                            errors={errors}
                                                                            value={modalFields.driverName}
                                                                            onChange={(e, newVal) => {
                                                                                _handleChange(e, newVal, 'driverNameModal', index)
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={12} mt={1.5} pl={1} pr={1} >
                                                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                                            <Controller
                                                                defaultValue=""
                                                                name="driverNumber"
                                                                control={control}
                                                                key={`driverNum-${index}`}
                                                                id="driverNumber"
                                                                render={({ field: { onChange, value, name } }) => {
                                                                    return (
                                                                        <CustomInput
                                                                            placeholder="Driver Number"
                                                                            label="Driver Number"
                                                                            required={false}
                                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                            inputClasses={inputClasses}
                                                                            name={name}
                                                                            errors={errors}
                                                                            value={modalFields.mobileNo}
                                                                            onChange={(e, newVal) => {
                                                                                _handleChange(e, newVal, 'driverMobileModal', index)
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={12} mt={1.5} pl={1} pr={1} mb={1.5} >
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                ...buttonClasses?.lynkitOrangeFill,
                                                                minHeight: "28px",
                                                                minWidth: "70px",
                                                            }}
                                                            onClick={() => {addDriverFunction(index); sendCustomEventAnalytics("trips", "Add Driver", "Add Driver")}}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                {userPermissions &&
                                                    havePermission(userPermissions, "driver", "driver_list") ?
                                                    item?.driverData?.driver?.map((data, driverIndex) => {
                                                        return (
                                                            <Grid key={driverIndex} item className='driverDrop'>
                                                                <FormControl sx={{ ...selectStylesOverride, width: "90%", display: 'flex' }}>
                                                                    <Controller
                                                                        name={`driver-${driverIndex}`}
                                                                        control={control}
                                                                        render={({ field: { onChange, value, name } }) => {
                                                                            return (
                                                                                <CustomSelect
                                                                                    //disabled={true}
                                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                                    label="Select Driver"
                                                                                    key={`driver-${driverIndex}`}
                                                                                    value={data || null}
                                                                                    onChange={(e, newVal) => {
                                                                                        _handleChange(e, newVal, 'driverName', index, null, driverIndex)
                                                                                    }}
                                                                                    name={name}
                                                                                    errors={errors}
                                                                                    getOptionLabel={(option) => {
                                                                                        return option?.driverName || "";
                                                                                    }}
                                                                                    filterSelectedOptions
                                                                                    renderOptions={(props, option, { selected }) => {
                                                                                        return (
                                                                                            <>
                                                                                                <MenuItem value={value || ""} selected={selected} {...props}>
                                                                                                    <Typography
                                                                                                        sx={{
                                                                                                            fontSize: ".8rem",
                                                                                                            overflow: "hidden",
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis",
                                                                                                        }}
                                                                                                    >
                                                                                                        {option?.driverName || ''}
                                                                                                    </Typography>
                                                                                                </MenuItem>
                                                                                            </>
                                                                                        );
                                                                                    }}
                                                                                    isOptionEqualToValue={(option, value) =>
                                                                                        option._id === value._id && option.driverName === value.driverName
                                                                                    }
                                                                                    options={getDriversList(index)}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {tripData?.data[index]?.driverData?.driver?.length === 1 ? "" :
                                                                    <RemoveCircleOutlineIcon onClick={() => removeDriver(index, driverIndex)} className='removeIcon' />
                                                                }
                                                                {tripData?.data[index].driverData?.driver?.length - 1 === driverIndex ?
                                                                    <AddCircleOutlineIcon className='addIcon' onClick={() => addDriver(index)} />
                                                                    : ''}

                                                            </Grid>

                                                        )
                                                    }) : null}
                                                <Grid item mt={2}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            ...buttonClasses?.lynkitGrayFill,
                                                            display: 'flex',
                                                            cursor: 'pointer',
                                                            height: '33px'
                                                        }}
                                                    >
                                                        Upload
                                                        <UploadIcon sx={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} />
                                                        <input
                                                            onChange={(e) => uploadImage(e, index)}
                                                            accept=".png, .jpg, .jpeg, .doc, .pdf, video/*"
                                                            className="fileUpload"
                                                            multiple
                                                            type="file"
                                                        />
                                                    </Button>
                                                </Grid>

                                                {/* {imageBase.length ?
                                                    <Grid item mt={2} className='imageContainer'>
                                                        {imageBase?.map((baseContainer, imgIndex) => {
                                                            return (
                                                              


                                                            )
                                                        })} */}
                                                {imageBase[index] && imageBase[index]?.base?.length ?
                                                    <Grid item mt={2} className='imageContainer'>
                                                        {imageBase[index].base?.map((data, ind) => {
                                                            return (
                                                                data?.includes("image") ? (
                                                                    <Box key={ind} className='imageBox' >
                                                                        <CancelIcon onClick={() => removeImage(index, ind)} className='cancelIcon' />
                                                                        <img className='imgFile' src={data} alt='' onClick={() => previewImage(index, ind)} />
                                                                        <DownloadIcon className='downloadIcon' onClick={() => downloadImage(index, ind)} />
                                                                        <ZoomOutMapIcon className='zoomImage' onClick={() => previewImage(index, ind)} />
                                                                    </Box>
                                                                )
                                                                    :
                                                                    (
                                                                        <Box key={ind} className='imageBox' >
                                                                            <CancelIcon onClick={() => removeImage(index, ind)} className='cancelIcon' />
                                                                            <object
                                                                                data={data}
                                                                                onClick={() => previewImage(index, ind)}
                                                                                type="application/pdf"
                                                                                width="100px"
                                                                                height='100px'
                                                                            ></object>
                                                                            <DownloadIcon className='downloadIcon' onClick={() => downloadImage(index, ind)} />
                                                                            <ZoomOutMapIcon className='zoomImage' onClick={() => previewImage(index, ind)} />
                                                                        </Box>
                                                                    )
                                                            )
                                                        })}
                                                    </Grid>
                                                    : null}
                                            </Grid>



                                            <Grid item md={8.07} pl={2} pr={2} mb={9}>
                                                {item?.routeNotAvailable || item?.devices && item?.devices?.length < 2 ? null :
                                                    <Grid item mb={0} md={12} pl={2} pt={1} sx={{ position: 'relative', right: '15px' }} >
                                                        <FormControlLabel
                                                            sx={{
                                                                color: "rgb(128, 128, 128)",
                                                                "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
                                                            }}
                                                            label="Assign Location"
                                                            control={
                                                                <Checkbox
                                                                    id="select-all-checkbox"
                                                                    sx={{
                                                                        color: "#ff7200",
                                                                        "&.Mui-checked": {
                                                                            color: "#ff7200",
                                                                        }
                                                                    }}
                                                                    checked={tripData?.data[index]?.location}
                                                                    onClick={(e) => {
                                                                        sendCustomEventAnalytics("trips", "Location Assign", "Location Assign")
                                                                        e.preventDefault(); // prevent blur
                                                                        _handleChange(e, null, 'locationCheckbox', index)
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        {item && item?.devices && item?.devices?.length >= 2 ?
                                                            <FormControlLabel
                                                                sx={{
                                                                    color: "rgb(128, 128, 128)",
                                                                    "& .MuiFormControlLabel-label": { fontSize: "13px", paddingTop: '2px' },
                                                                    marginLeft: '15px'
                                                                }}
                                                                label="Set Primary Location"
                                                                control={
                                                                    <Checkbox
                                                                        id="select-all-id-checkbox"
                                                                        sx={{
                                                                            color: "#ff7200",
                                                                            "&.Mui-checked": {
                                                                                color: "#ff7200",
                                                                            }
                                                                        }}
                                                                        checked={primaryCheck}
                                                                        onClick={(e) => {
                                                                            e.preventDefault(); // prevent blur
                                                                            _handleChange(e, null, 'primaryCheckBox', index)
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            : null}
                                                    </Grid>
                                                }
                                                {item?.devices && item?.devices?.map((data, deviceIndex) => {
                                                    return (
                                                        <Grid key={deviceIndex} item pt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Grid item md={9.5} display={"flex"} sx={{ alignItems: 'center' }}>
                                                                {primaryCheck ?
                                                                    <FormControl sx={{ ...selectStylesOverride, width: "6%" }}>
                                                                        <Radio
                                                                            checked={data?.primary}
                                                                            onChange={(e, newVal) => {
                                                                                _handleChange(e, null, 'radio', index, deviceIndex)
                                                                            }}
                                                                            value={data?.primary}
                                                                            key={`radio-${deviceIndex}`}
                                                                            name="radio-buttons"
                                                                            sx={{
                                                                                "&, &.Mui-checked": {
                                                                                    color: themeType.default.themeOrange,
                                                                                    position: 'relative',
                                                                                    right: '9px'
                                                                                },
                                                                            }}
                                                                            inputProps={{ 'aria-label': 'A' }}
                                                                        />
                                                                    </FormControl>
                                                                    : null}
                                                                {devicesArr && devicesArr[index] && devicesArr[index]?.arr[deviceIndex]?.length ?
                                                                    <FormControl sx={{ ...selectStylesOverride, width: "47%", display: 'flex' }}>
                                                                        <Controller
                                                                            name={`deviceDropdown-${deviceIndex}`}
                                                                            control={control}
                                                                            render={({ field: { onChange, value, name } }) => {
                                                                                return (
                                                                                    <CustomSelect
                                                                                        //disabled={true}
                                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                                        label="Select Device Id"
                                                                                        key={`deviceDropdown-${deviceIndex}`}
                                                                                        value={data}
                                                                                        onChange={(e, newVal) => {
                                                                                            _handleChange(e, newVal, 'deviceSelect', index, deviceIndex)
                                                                                        }}
                                                                                        name={name}
                                                                                        errors={errors}
                                                                                        getOptionLabel={(option) => {
                                                                                            return option.deviceId || "";
                                                                                        }}
                                                                                        filterSelectedOptions
                                                                                        renderOptions={(props, option, { selected }) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <MenuItem value={value || ""} selected={selected} {...props}>
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                fontSize: ".8rem",
                                                                                                                overflow: "hidden",
                                                                                                                whiteSpace: "nowrap",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {option?.deviceId || ''}
                                                                                                        </Typography>
                                                                                                    </MenuItem>
                                                                                                </>
                                                                                            );
                                                                                        }}
                                                                                        isOptionEqualToValue={(option, value) =>
                                                                                            option.deviceId === value.deviceId
                                                                                        }
                                                                                        options={devicesArr && devicesArr[index]?.arr ? devicesArr[index]?.arr[deviceIndex] : []}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                    :
                                                                    <FormControl sx={{ ...selectStylesOverride, width: "47%" }}>
                                                                        <Controller
                                                                            defaultValue=""
                                                                            name="deviceId"
                                                                            key={`deviceId-${deviceIndex}`}
                                                                            control={control}
                                                                            render={({ field: { onChange, value, name } }) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        placeholder="Device Id"
                                                                                        label="Device Id"
                                                                                        required={false}
                                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                                        inputClasses={inputClasses}
                                                                                        // name={name}
                                                                                        errors={errors}
                                                                                        value={data?.deviceId}
                                                                                        onChange={(e, newVal) => {
                                                                                            _handleChange(e, newVal, 'deviceId', index, deviceIndex)

                                                                                        }}
                                                                                        InputProps={{
                                                                                            endAdornment: (
                                                                                                <InputAdornment position="end">
                                                                                                    <Search style={{ cursor: 'pointer' }} onClick={() => searchDevice(item, index, deviceIndex)} />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                }

                                                                {tripData?.data && tripData?.data[index] && tripData?.data[index]?.location ?
                                                                    <FormControl sx={{ ...selectStylesOverride, width: "47%", marginLeft: '12px', display: 'flex' }}>
                                                                        <Controller
                                                                            name={`address-${deviceIndex}`}
                                                                            control={control}
                                                                            key={`address-${deviceIndex}`}
                                                                            render={({ field: { onChange, value, name } }) => {
                                                                                return (
                                                                                    <CustomSelect
                                                                                        //disabled={true}
                                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                                        label="Select Address"
                                                                                        value={data?.address || null}
                                                                                        onChange={(e, newVal) => {
                                                                                            // onChange(newVal?.name);
                                                                                            _handleChange(e, newVal, 'address', index, deviceIndex)

                                                                                        }}
                                                                                        filterSelectedOptions
                                                                                        name={name}
                                                                                        errors={errors}
                                                                                        getOptionLabel={(option) => {
                                                                                            if (typeof option === "string") {
                                                                                                return routeViapoints?.find((_) => _?.name == value)?.name || "";
                                                                                            } else {
                                                                                                return option?.itemName || "";
                                                                                            }
                                                                                        }}
                                                                                        isOptionEqualToValue={(option, value) =>
                                                                                            option._id === value._id && option.itemName === value.itemName
                                                                                        }
                                                                                        labelKey="itemName"
                                                                                        options={routeViapoints && routeViapoints[index] && routeViapoints[index]?.data ? routeViapoints[index]?.data : []}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                    : null}
                                                                <FormControl sx={{ ...selectStylesOverride, width: '47%', marginLeft: '12px', marginRight: '12px', }}>
                                                                    <Controller
                                                                        defaultValue=""
                                                                        name="remark"
                                                                        key={`remark-${deviceIndex}`}
                                                                        control={control}
                                                                        render={({ field: { onChange, value, name } }) => {
                                                                            return (
                                                                                <CustomInput
                                                                                    placeholder="Remark"
                                                                                    label="Remark"
                                                                                    required={false}
                                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                                    inputClasses={inputClasses}
                                                                                    value={data?.remark}
                                                                                    errors={errors}
                                                                                    onChange={(e, newVal) => {
                                                                                        _handleChange(e, newVal, 'remark', index, deviceIndex)
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={1.7} display={"flex"} sx={{ justifyContent: 'flex-end' }} className='devicesBox'>
                                                                {data.deviceId !== "" ?
                                                                    <Button
                                                                        variant="outlined"
                                                                        size="small"
                                                                        key={`checkBtn-${deviceIndex}`}
                                                                        // disabled={tableData.length ? false : true}
                                                                        sx={{
                                                                            ...buttonClasses.small,
                                                                            minHeight: "36px",
                                                                            borderColor: themeType.default.themeOrange,
                                                                            color: buttonClasses.lynkitOrangeEmpty,
                                                                            marginLeft: '0px'
                                                                        }}
                                                                        onClick={() => {checkAvaialabiltyFnc(index, deviceIndex); sendCustomEventAnalytics("trips", "Check Availability", "Check Availability")}}
                                                                    >
                                                                        Check Availability
                                                                    </Button>
                                                                    : null}
                                                            </Grid>
                                                            <Grid md={1} item className='devicesBoxSno'>
                                                                {item?.routeNotAvailable ? null :
                                                                    tripData?.data[index].devices.length === 1 ? "" :
                                                                        <RemoveCircleOutlineIcon onClick={() => removeDevice(index, deviceIndex)} className='removeIcon' />
                                                                }
                                                                {item?.routeNotAvailable ? null :
                                                                    tripData?.data[index].devices.length - 1 === deviceIndex ?
                                                                        <AddCircleOutlineIcon className='addIcon' onClick={() => addDevice(index)} />
                                                                        : ""
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>


                                    </Box>


                                    {item?.routeNotAvailable && deviceavailableStatus ?
                                        <Grid key={`closure-${index}`} item spacing={2} container mb={0} md={12} p={2} mt={0} >

                                            <Grid item md={4} pl={2} pr={2} mb={2} style={{ width: '100%' }}>
                                                <FormControl sx={{ ...selectStylesOverride, width: "96%", display: 'flex' }}>
                                                    <Controller
                                                        name={`tripClosure-${index}`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    disabled={!deviceType}
                                                                    //disabled={true}
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    label={deviceType ? "Trip Closure" : "Check Avaialbilty of Device first"}
                                                                    key={`tripClosure-${index}`}
                                                                    // value={item?.route || null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleChange(e, newVal, 'tripClosure', index)
                                                                    }}
                                                                    name={name}
                                                                    errors={errors}
                                                                    labelKey="label"
                                                                    options={deviceType ? (deviceType === "elock" || deviceType === 'fixedELock' ? efixedclosureTypes : otherClsoureTypes) : [{ label: 'Check Avaialbilty of Device first', value: 'Check Avaialabilty First' }]}

                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {item?.tripClosure === 'arrivalAtGeofence' || item?.tripClosure === 'unlockAtGeofence' ?
                                                <Grid item md={4} style={{ width: '100%' }}>
                                                    <FormControl sx={{ ...selectStylesOverride, width: "96%", display: 'flex' }}>
                                                        <Controller
                                                            name={`geofence-${index}`}
                                                            control={control}
                                                            render={({ field: { onChange, value, name } }) => {
                                                                return (
                                                                    <CustomSelect
                                                                        //disabled={true}
                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                        label="Select Geofence"
                                                                        onChange={(e, newVal) => {
                                                                            // onChange(newVal?.name);
                                                                            _handleChange(e, newVal, 'geofence', index)

                                                                        }}
                                                                        name={name}
                                                                        errors={errors}
                                                                        getOptionLabel={(option) => {
                                                                            if (typeof option === "string") {
                                                                                return GeofencesListing?.find((_) => _?.name == value)?.name || "";
                                                                            } else {
                                                                                return option?.name || "";
                                                                            }
                                                                        }}
                                                                        labelKey="name"
                                                                        options={GeofencesListing && GeofencesListing}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>

                                                </Grid>
                                                : null}



                                        </Grid>
                                        : null}
                                </Box>
                            )
                        })}

                        {/* <Grid item mb={0} md={12} pb={3} pt={1}
                            sx={{
                                overflowX: "hidden",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                border: '1px solid #E8E8E8',
                                borderTop: '1PX solid #fff'
                            }}
                        >
                            <DynamicFormRender
                                formHooks={{
                                    register,
                                    control,
                                    errors,
                                    handleSubmit,
                                    reset,
                                    setValue,
                                }}
                                templateId={(templateExistence && templateExistence?._id) || ""}
                            />
                        </Grid> */}









                        {imageModal && (
                            <ImagePreviewModal
                                open={imageModal}
                                hide={() => {
                                    setImageModal(false);
                                }}
                                imgInfo={imgInfo}
                            />
                        )}
                    </Grid>
                    {selectedRow && (
                        <Grid item={true} sm={3} xs={6} sx={{ p: 1, pt: 0 }}>
                            <DetailCard
                                themeType={themeType}
                                scheduleData={scheduleData}
                                setScheduleData={setScheduleData}
                                handleSelectedRow={handleSelectedRow}
                                isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                                control={control}
                                errors={errors}
                                dateTimePickerSlotProps={dateTimePickerSlotProps}
                                setSelectedRow={setSelectedRow}
                                GeofencesListing={GeofencesListing}
                                tripData={tripData}
                                setTripData={setTripData}
                                scheduleIndex={scheduleIndex}
                                routeViapoints={routeViapoints}
                                setRouteViapoints={setRouteViapoints}
                                detailTrips={detailTrips}
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid item={true} xs={12} sx={{ height: '60px', width: selectedRow ? '75%' : '100%', border: '1px solid #E8E8E8' }} display="flex" justifyContent="center" alignItems="center" position="relative" gap="7px">

                    {/* <Box
                        key={0}
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translate(0,-50%)",
                        }}
                    >
                        <Checkbox
                            checked={addMoreCheck}
                            onChange={(e) => { setAddMoreCheck(!addMoreCheck) }}
                            sx={{
                                "&, &.Mui-checked": {
                                    color: themeType.default.themeOrange,
                                },
                                marginBottom: "4px",
                            }}
                        />
                        <Typography
                            sx={{ ...textClasses.normalText, paddingBottom: "4px" }}
                        >
                            Add More Trip(s)
                        </Typography>
                    </Box> */}
                    {Object.keys(editField).length ?
                        

                        <CustomSubmitBtn
                            variant="contained"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                ml: 0,
                                fontSize: ".7rem",
                                border: "none",
                                height: "37px",
                                minHeight: "36px",
                                minWidth: "140px",
                            }}
                            disabled={editAffixLoader || isAnyDeviceIdEmpty()}
                            loading={editAffixLoader}
                            label= {editField.cdtn === 'affix' ? 'Affix' : 'Update'}
                            onClick={() => {onEdit(); sendCustomEventAnalytics("trips", "Affix/Update", "Affix/Update")}}
                        />
                        :
                        <CustomSubmitBtn
                            variant="contained"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                ml: 0,
                                fontSize: ".7rem",
                                border: "none",
                                height: "37px",
                                minHeight: "36px",
                                minWidth: "140px",
                            }}
                            disabled={affixLoader || isAnyDeviceIdEmpty()}
                            loading={affixLoader}
                            label="Create & Affix"
                            onClick={() => {onSubmit(); sendCustomEventAnalytics("trips", "Create & Affix", "Create & Affix")}}
                        />
                    }
                    {userPermissions &&
                        havePermission(userPermissions, "trip", "allowTripSaveAsDraft") ?
                        window?.location?.pathname === '/dashboard/trips/create' && !tripData.data[0].routeNotAvailable ?


                            <CustomSubmitBtn
                                variant="contained"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    ml: 0,
                                    fontSize: ".7rem",
                                    border: "none",
                                    height: "37px",
                                    minHeight: "36px",
                                    minWidth: "140px",
                                }}
                                disabled={btnLoader || !isAnyDeviceIdEmpty()}
                                loading={btnLoader}
                                label="Create"
                                onClick={() => {saveAsDraft(); sendCustomEventAnalytics("trips", "Save as draft", "Save as draft")}}
                            />



                            : null
                        : null}
                </Grid>






            </form>

        </>

    );
};



export const DetailCard = ({ themeType, handleSelectedRow, isDarkThemeEnabledSelector, control, errors, dateTimePickerSlotProps, setSelectedRow, GeofencesListing, scheduleData, setScheduleData, tripData, setTripData, scheduleIndex, detailTrips }) => {
    const { theme, buttonClasses, cardClasses, inputClasses, textClasses, tableClasses } = themeType;

    const checkAndUpdateDates = (dateField, value, item, dayAdjustment) => {
        const currentDate = new Date();
        if (dayAdjustment === 'nextDay') {
            currentDate.setDate(currentDate.getDate() + 1);
        } else if (dayAdjustment && !isNaN(parseInt(dayAdjustment))) {
            currentDate.setDate(currentDate.getDate() + parseInt(dayAdjustment));
        }

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const date = `${year}-${month}-${day}`;

        if (value) {
            let dates = value.split(" ");

            if (dates.length >= 2) {
                return `${value}`;
            }
            else {
                return `${date} ${value}`;
            }
        }

    };

    useEffect(() => {
        let temp = { ...tripData };
        if (scheduleData && Object?.keys(scheduleData)?.length && scheduleData?.routeSource?.STD && scheduleData?.routeDestination?.STA) {
            temp.data[scheduleIndex].routeSchedule.routeSource.STD = checkAndUpdateDates(null, temp?.data[scheduleIndex]?.routeSchedule?.routeSource?.STD, 'source');
            temp.data[scheduleIndex].routeSchedule.routeDestination.STA = checkAndUpdateDates(null, temp?.data[scheduleIndex]?.routeSchedule?.routeDestination?.STA, 'destination', temp?.data[scheduleIndex]?.routeSchedule?.routeDestination?.dayArrival);

            for (let i = 0; i < scheduleData?.viaPoints?.length; i++) {
                if (temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA !== '' && temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD !== '') {
                    temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA = checkAndUpdateDates(null, temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STA, 'viapoints', temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.dayArrival);
                    temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD = checkAndUpdateDates(null, temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STD, 'viapoints', temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.dayDeparture);
                }
            }
        }
        setTripData(temp);

    }, [scheduleData]);

    const changesDueToStartTime = (time1, time2) => {
        // Parse time1
        var parts1 = time1.split(" , ");
        var days1 = 0;
        var timeComponents1 = parts1[parts1.length - 1].split(":");
        if (parts1.length > 1) {
            days1 = parseInt(parts1[0]); // Extract days
        }
        var hours1 = parseInt(timeComponents1[0]); // Extract hours
        var minutes1 = parseInt(timeComponents1[1]); // Extract minutes

        // Parse time2
        var date2 = new Date(time2);
        var days2 = date2.getDate(); // Extract days
        var hours2 = date2.getHours(); // Extract hours
        var minutes2 = date2.getMinutes(); // Extract minutes

        // Perform addition
        var totalDays = days1 + days2;
        var totalHours = hours1 + hours2;
        var totalMinutes = minutes1 + minutes2;

        // Handle overflow
        if (totalMinutes >= 60) {
            totalMinutes -= 60;
            totalHours++;
        }

        if (totalHours >= 24) {
            totalHours -= 24;
            totalDays++;
        }

        var resultDate = new Date();
        resultDate.setDate(totalDays);
        resultDate.setHours(totalHours);
        resultDate.setMinutes(totalMinutes);

        // Format the result as YYYY-MM-DD HH:mm
        var formattedResult =
            resultDate.getFullYear() + "-" +
            ("0" + (resultDate.getMonth() + 1)).slice(-2) + "-" +
            ("0" + resultDate.getDate()).slice(-2) + " " +
            ("0" + resultDate.getHours()).slice(-2) + ":" +
            ("0" + resultDate.getMinutes()).slice(-2)
        return formattedResult;
    }

    const changeScheduleEntries = (date, item, index) => {
        let temp = { ...scheduleData }
        if (item === 'src') {

            var time1 = temp.viaPoints[0].travelTime;
            var time2 = date;
            let newSTA = changesDueToStartTime(time1, time2)
            temp.viaPoints[0].STA = newSTA

            for (let i = index; i < temp?.viaPoints?.length; i++) {
                if (i !== 0) {
                    var viatime1 = temp.viaPoints[i].travelTime;
                    var viatime2 = temp.viaPoints[i - 1].STD;

                    let newSTA = changesDueToStartTime(viatime1, viatime2)
                    temp.viaPoints[i].STA = newSTA

                    let newSTD = changesDueToStartTime(temp.viaPoints[i].halt, newSTA)
                    temp.viaPoints[i].STD = newSTD
                }
            }

            var time3 = temp.viaPoints[temp.viaPoints.length - 1].STD;
            var time4 = temp.routeDestination.travelTime;


            let newRouteDestinationSTD = changesDueToStartTime(time4, time3)
            temp.routeDestination.STA = newRouteDestinationSTD
            setScheduleData(temp)

        }
        else if (item === 'via') {
            for (let i = index; i < temp?.viaPoints?.length; i++) {
                if (i !== 0) {
                    var viatime1 = temp.viaPoints[i].travelTime;
                    var viatime2 = temp.viaPoints[i - 1].STD;

                    let newSTA = changesDueToStartTime(viatime1, viatime2)
                    temp.viaPoints[i].STA = newSTA

                    let newSTD = changesDueToStartTime(temp.viaPoints[i].halt, newSTA)
                    temp.viaPoints[i].STD = newSTD
                }
            }

            var time3 = temp.viaPoints[temp.viaPoints.length - 1].STD;
            var time4 = temp.routeDestination.travelTime;


            let newRouteDestinationSTD = changesDueToStartTime(time4, time3)
            temp.routeDestination.STA = newRouteDestinationSTD

            setScheduleData(temp)
        }


    }

    const travelTimeLogic = (date1String, date2String, index, newVal, item, param) => {
        let temp = { ...scheduleData }
        let dummy = { ...tripData }
        if (item === 'sta') {
            if (index === 0) {
                var date1 = moment(date1String, "YYYY-MM-DD HH:mm");
                var date2 = moment(date2String, "YYYY-MM-DD HH:mm");
                var diffMinutes = date2.diff(date1, 'minutes');
                if (diffMinutes < 0) {
                    if (!param) {
                        temp.viaPoints[index].travelTime = '00:00'
                        temp.viaPoints[index].STA = ''
                        temp.viaPoints[index].STD = ''
                    }
                    else {
                        temp.routeSource.STD = ''
                    }

                    setScheduleData(temp)
                    showToast('Source Time cannot be greater than Viapoint-1 STA.', true)

                } else {
                    var days = Math.floor(diffMinutes / (60 * 24));
                    diffMinutes -= days * 60 * 24;
                    var hours = Math.floor(diffMinutes / 60);
                    var minutes = diffMinutes % 60;
                    var result = '';

                    if (days > 0) {
                        result += days + 'd , ';
                    }
                    result += hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
                    temp.viaPoints[index].travelTime = result
                    temp.viaPoints[index].STA = moment(newVal).format("YYYY-MM-DD HH:mm")
                    temp.viaPoints[index].STD = changesDueToStartTime(temp.viaPoints[0].halt, moment(newVal).format("YYYY-MM-DD HH:mm"))
                    setScheduleData(temp)


                    const copy = dummy.data[scheduleIndex].routeSchedule;

                    if (!copy.viaPoints) {
                        copy.viaPoints = [];
                    }

                    while (copy.viaPoints.length <= index) {
                        copy.viaPoints.push({});
                    }

                    const viaAddress = {
                        ...copy.viaPoints[index],
                        STA: moment(newVal).format("YYYY-MM-DD HH:mm")
                    };

                    copy.viaPoints[index] = viaAddress;
                    setTripData(copy);
                }
            }
            else {

                var date1 = moment(date1String, "YYYY-MM-DD HH:mm");
                var date2 = moment(date2String, "YYYY-MM-DD HH:mm");
                var diffMinutes = date2.diff(date1, 'minutes');
                if (diffMinutes < 0) {
                    showToast(`Viapoint-${index - 1} STD cannot be greater than Viapoint-${index} STA`, true)
                    temp.viaPoints[index].travelTime = '00:00'
                    temp.viaPoints[index].STA = ''
                    setScheduleData(temp)
                }
                else {
                    var days = Math.floor(diffMinutes / (60 * 24));
                    diffMinutes -= days * 60 * 24;
                    var hours = Math.floor(diffMinutes / 60);
                    var minutes = diffMinutes % 60;
                    var result = '';

                    if (days > 0) {
                        result += days + 'd , ';
                    }

                    result += hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
                    temp.viaPoints[index].travelTime = result
                    temp.viaPoints[index].STA = moment(newVal).format("YYYY-MM-DD HH:mm")
                    setScheduleData(temp)
                }
            }
        }
        else if (item === 'rdsta') {
            if (temp.viaPoints.length) {
                var date1 = moment(date1String, "YYYY-MM-DD HH:mm");
                var date2 = moment(date2String, "YYYY-MM-DD HH:mm");
                var diffMinutes = date2.diff(date1, 'minutes');
                if (diffMinutes < 0) {
                    showToast(`Viapoint-${temp.viaPoints.length} STD cannot be greater than Route Destination STA`, true)
                    temp.routeDestination.travelTime = '00:00'
                    temp.routeDestination.STA = ''
                    setScheduleData(temp)
                } else {
                    var days = Math.floor(diffMinutes / (60 * 24));
                    diffMinutes -= days * 60 * 24;
                    var hours = Math.floor(diffMinutes / 60);
                    var minutes = diffMinutes % 60;
                    var result = '';

                    if (days > 0) {
                        result += days + 'd , ';
                    }

                    result += hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
                    temp.routeDestination.travelTime = result
                    temp.routeDestination.STA = moment(newVal).format("YYYY-MM-DD HH:mm"),
                        setScheduleData(temp)
                }
            }
            else {
                var date1 = moment(date1String, "YYYY-MM-DD HH:mm");
                var date2 = moment(date2String, "YYYY-MM-DD HH:mm");
                var diffMinutes = date2.diff(date1, 'minutes');
                if (diffMinutes < 0) {
                    showToast(`Source Time cannot be greater than Route Destination STA`, true)
                    temp.routeDestination.travelTime = '00:00'
                    temp.routeDestination.STA = '',
                        setScheduleData(temp)
                } else {
                    var days = Math.floor(diffMinutes / (60 * 24));
                    diffMinutes -= days * 60 * 24;
                    var hours = Math.floor(diffMinutes / 60);
                    var minutes = diffMinutes % 60;
                    var result = '';

                    if (days > 0) {
                        result += days + 'd , ';
                    }

                    result += hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
                    temp.routeDestination.travelTime = result
                    temp.routeDestination.STA = moment(newVal).format("YYYY-MM-DD HH:mm"),
                        setScheduleData(temp)
                }
            }
        }
    }

    const calculateHaltTime = (dateString1, dateString2) => {
        // Parse the input date strings into Date objects
        const date1 = new Date(dateString1);
        const date2 = new Date(dateString2);

        if (date2 > date1) {
            showToast(`STA cannot be greater than STD`, true)
            return '00:00';
        }


        // Calculate the difference in milliseconds
        let difference = Math.abs(date2 - date1);

        // Convert milliseconds to hours and minutes
        let hours = Math.floor(difference / (1000 * 60 * 60));
        let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        // Format the output to ensure two digits for hours and minutes
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        // Combine hours and minutes into a formatted string
        let differenceString = hours + ':' + minutes;

        return differenceString;
    }

    const _handleDetailCardChange = (e, newVal, item, index) => {
        let temp = { ...scheduleData }
        let dummy = { ...tripData }
        if (item === "sourceAddress") {
            if (newVal) {
                temp.routeSource.name = newVal?.name
                temp.routeSource._id = newVal?._id
                setScheduleData(temp)

                const newRouteSource = {
                    ...dummy.data[scheduleIndex].routeSchedule.routeSource,
                    name: newVal?.name,
                    _id: newVal?._id
                };
                dummy.data[scheduleIndex].routeSchedule.routeSource = newRouteSource;
                setTripData(dummy)
            }
            else {
                temp.routeSource.name = ""
                temp.routeSource._id = ""
                dummy.data[scheduleIndex].routeSchedule.routeSource = {}
                setScheduleData(temp)

            }
        }
        else if (item === "sourceSTA") {
            temp.routeSource.STD = moment(newVal).format("YYYY-MM-DD HH:mm")
            var date1String = temp?.routeSource?.STD
            var date2String = temp?.viaPoints[0]?.STA

            setScheduleData(temp);
            changeScheduleEntries(moment(newVal).format("YYYY-MM-DD HH:mm"), 'src', 0)
            travelTimeLogic(date1String, date2String, 0, date2String, 'sta', 'src')

            const newRouteSourceSTD = {
                ...dummy.data[scheduleIndex].routeSchedule.routeSource,
                STD: moment(newVal).format("YYYY-MM-DD HH:mm")
            };

            dummy.data[scheduleIndex].routeSchedule.routeSource = newRouteSourceSTD;
            setTripData(dummy)
        }
        else if (item === 'STA') {
            if (index === 0) {
                var date1String = temp?.routeSource?.STD
                var date2String = newVal
                travelTimeLogic(date1String, date2String, index, newVal, 'sta')

                changeScheduleEntries(moment(newVal).format("YYYY-MM-DD HH:mm"), 'via', index)

                let newHaltTime = calculateHaltTime(temp.viaPoints[index].STD, temp.viaPoints[index].STA)
                temp.viaPoints[index].halt = newHaltTime
                setScheduleData(temp)
            }
            else {
                var date1String = temp?.viaPoints[index - 1]?.STD
                var date2String = newVal
                travelTimeLogic(date1String, date2String, index, newVal, 'sta')
                changeScheduleEntries(moment(newVal).format("YYYY-MM-DD HH:mm"), 'via', index)

                let newHaltTime = calculateHaltTime(temp.viaPoints[index].STD, temp.viaPoints[index].STA)
                temp.viaPoints[index].halt = newHaltTime
                setScheduleData(temp)
            }
        }
        else if (item === 'STD') {
            temp.viaPoints[index].STD = moment(newVal).format("YYYY-MM-DD HH:mm")
            let newHaltTime = calculateHaltTime(temp.viaPoints[index].STD, temp.viaPoints[index].STA)
            temp.viaPoints[index].halt = newHaltTime

            changeScheduleEntries(moment(newVal).format("YYYY-MM-DD HH:mm"), 'via', index)

            setScheduleData(temp)
            const copy = dummy.data[scheduleIndex].routeSchedule;
            if (!copy.viaPoints) {
                copy.viaPoints = [];
            }
            while (copy.viaPoints.length <= index) {
                copy.viaPoints.push({});
            }
            const viaAddress = {
                ...copy.viaPoints[index],
                STD: moment(newVal).format("YYYY-MM-DD HH:mm")
            };
            copy.viaPoints[index] = viaAddress;
            setTripData(copy);
        }
        else if (item === "sourceDestination") {
            if (newVal) {
                temp.routeDestination.name = newVal?.name
                temp.routeDestination._id = newVal?._id
                setScheduleData(temp)

                const newRouteDestination = {
                    ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
                    name: newVal.name,
                    _id: newVal._id
                };
                dummy.data[scheduleIndex].routeSchedule.routeDestination = newRouteDestination;
                setTripData(dummy)
            }
            else {
                temp.routeDestination.name = ""
                temp.routeDestination._id = ""
                setScheduleData(temp)

                dummy.data[scheduleIndex].routeSchedule.routeDestination = {}
                setScheduleData(temp)
            }
        }
        else if (item === "travelTime") {

            temp.viaPoints[index].travelTime = format(newVal, "HH:mm")
            setScheduleData(temp)

            const travelTime = dummy.data[scheduleIndex].routeSchedule;

            if (!travelTime.viaPoints) {
                travelTime.viaPoints = [];
            }

            while (travelTime.viaPoints.length <= index) {
                travelTime.viaPoints.push({});
            }

            const viaAddress = {
                ...travelTime.viaPoints[index],
                travelTime: format(newVal, "HH:mm")
            };

            travelTime.viaPoints[index] = viaAddress;
            setTripData(travelTime);


        }
        else if (item === "viapt") {
            temp.viaPoints[index].itemName = newVal?.name
            temp.viaPoints[index]._id = newVal?._id
            setScheduleData(temp)

            const routeSchedule = dummy.data[scheduleIndex].routeSchedule;

            if (!routeSchedule.viaPoints) {
                routeSchedule.viaPoints = [];
            }

            while (routeSchedule.viaPoints.length <= index) {
                routeSchedule.viaPoints.push({});
            }

            const viaAddress = {
                ...routeSchedule.viaPoints[index],
                itemName: newVal?.name,
                _id: newVal?._id
            };

            routeSchedule.viaPoints[index] = viaAddress;
            setTripData(routeSchedule);

        }
        else if (item === "destinationTravelTime") {
            temp.routeDestination.travelTime = format(newVal, "HH:mm")
            setScheduleData(temp)

            const destinationAvgSpeed = {
                ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
                travelTime: format(newVal, "HH:mm")
            };

            dummy.data[scheduleIndex].routeSchedule.routeDestination = destinationAvgSpeed;
            setTripData(dummy)


        }
        else if (item === 'rdSTA') {
            if (temp.viaPoints.length) {
                var date1String = temp?.viaPoints[temp.viaPoints.length - 1]?.STD
                var date2String = newVal
                travelTimeLogic(date1String, date2String, index, newVal, 'rdsta')
            }
            else {
                var date1String = temp?.routeSource?.STD
                var date2String = newVal
                travelTimeLogic(date1String, date2String, index, newVal, 'rdsta')
            }

            const newRouteDestinationSTD = {
                ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
                STA: moment(newVal).format("YYYY-MM-DD HH:mm")
            };
            dummy.data[scheduleIndex].routeSchedule.routeDestination = newRouteDestinationSTD;
            setTripData(dummy)

        }
        else if (item === "avgSpeed") {
            temp.viaPoints[index] = {
                ...temp.viaPoints[index],
                averageSpeed: e.target.value
            };
            setScheduleData(temp)
            const copy = dummy.data[scheduleIndex].routeSchedule;

            if (!copy.viaPoints) {
                copy.viaPoints = [];
            }

            while (copy.viaPoints.length <= index) {
                copy.viaPoints.push({});
            }

            const viaAddress = {
                ...copy.viaPoints[index],
                averageSpeed: e.target.value
            };

            copy.viaPoints[index] = viaAddress;
            setTripData(copy);

        }
        else if (item === "destinationaverageSpeed") {
            temp.routeDestination.averageSpeed = e.target.value
            setScheduleData(temp)

            const destSpeed = {
                ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
                averageSpeed: e.target.value
            };

            dummy.data[scheduleIndex].routeSchedule.routeDestination = destSpeed;
            setTripData(dummy)
        }
        setTripData(dummy)
    }
    const addViapoint = () => {
        let temp = { ...scheduleData }
        temp.viaPoints.push({
            "itemName": "",
            "_id": "",
            "travelTime": "00:00",
            "STA": "",
            "STD": "",
            // "halt": "",
            "averageSpeed": ""
        })
        setScheduleData(temp)
    }
    const deleteViapoint = (index) => {
        let temp = { ...scheduleData }
        temp.viaPoints.splice(index, 1)
        setScheduleData(temp)
    }
    function convertHHMMToDate(hhmm = "") {
        const [hours, minutes] = hhmm?.split(":").map(Number);
        const now = new Date();
        const resultDate = setMinutes(setHours(now, hours), minutes);
        return resultDate;
    }


    return (
        <>

            <Card
                className="ppmodal"
                data-testid="modal"
                sx={{
                    ...cardClasses.basic,
                    borderRadius: "8px",
                    border: `1px solid ${themeType.default.themeOrange}`,
                    position: "absolute",
                    marginRight: '20px',
                    borderTop: '2px solid #E8E8E8'
                }}
            >
                <Grid item container>
                    <CardHeader sx={{ width: '100%', height: "46px", backgroundColor: themeType.default.themeOrange }}
                        title={
                            <Typography
                                sx={{
                                    ...textClasses.boldText,
                                    color: "white",
                                    fontSize: "16px",
                                }}
                            >
                                <img style={{ width: '22px', height: '22px' }} src={schedule} alt='' />
                                <span style={{ position: "relative", left: "10px" }}>
                                    Route Schedule
                                </span>

                            </Typography>
                        }
                        action={
                            <IconButton
                                size="small"
                                sx={{ p: 0, mb: 1 }}
                                onClick={() => setSelectedRow(false)}
                            >
                                {detailTrips && Object.keys(detailTrips).length ? null : <Close sx={{ color: "white" }} />}
                            </IconButton>
                        }
                    />


                    <CardContent sx={{ height: '72.4vh', overflow: 'auto' }}>
                        <Box pb={3} sx={{ borderBottom: '1.5px dashed #ff7200' }}>
                            <Grid container>
                                <Grid item md={12} >
                                    <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>Source</Typography>
                                </Grid>
                                <Grid item md={12} mt={2}>
                                    <FormControl sx={{ ...selectStylesOverride, width: "100%", display: 'flex' }}>
                                        <Controller
                                            name="sourceAddress"
                                            control={control}
                                            render={({ field: { onChange, value, name } }) => {
                                                return (
                                                    <CustomSelect
                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                        label="Source Address"
                                                        value={scheduleData?.routeSource || null}
                                                        onChange={(e, newVal) => {
                                                            _handleDetailCardChange(e, newVal, 'sourceAddress')
                                                        }}
                                                        name={name}
                                                        errors={errors}
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === "string") {
                                                                return GeofencesListing?.find((_) => _?.name == value)?.name || "";
                                                            } else {
                                                                return option?.name || "";
                                                            }
                                                        }}
                                                        labelKey="name"
                                                        isOptionEqualToValue={(option, value) =>
                                                            option._id === value._id && option.name === value.name
                                                        }
                                                        options={GeofencesListing && GeofencesListing}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} mt={2}>

                                    <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                        <DateTimePicker
                                            label="Time"
                                            value={new Date(scheduleData?.routeSource?.STD)}
                                            onChange={(date) => _handleDetailCardChange(null, date, 'sourceSTA')}
                                            className="customDatePicker"
                                            format="dd-MM-yyyy hh:mm a"
                                            sx={{
                                                width: "100%",
                                                ...selectStylesOverride
                                            }}
                                            slotProps={dateTimePickerSlotProps}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>



                        <Box mt={2}>
                            {scheduleData?.viaPoints?.length ?
                                scheduleData?.viaPoints.map((item, index) => {
                                    return (
                                        <Grid key={`via-${index}`} mb={2} pb={2} container sx={{ borderBottom: '1.5px dashed #ff7200' }}>
                                            <Grid item md={12} display={"flex"} sx={{ justifyContent: 'space-between' }} >
                                                <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>Via Point - {index + 1}</Typography>
                                                <Grid item>
                                                    {scheduleData?.viaPoints?.length - 1 === index ?
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                ...buttonClasses.lynkitGreenEmpty,
                                                                marginRight: '10px',
                                                                minHeight: "34px",
                                                                position: 'relative',
                                                                bottom: '8px'
                                                            }}
                                                            onClick={() => addViapoint()}
                                                        >
                                                            Add
                                                        </Button>
                                                        : null}
                                                    <Button
                                                        id={`remove-btn-${index}`}
                                                        variant="outlined"
                                                        size="small"
                                                        className={scheduleData?.viaPoints?.length - 1 === index ? 'deleteredBtns' : 'addGreenBtns'}
                                                        sx={{
                                                            ...buttonClasses.lynkitRedEmpty,
                                                            marginLeft: "1px",
                                                            minHeight: "34px",
                                                            position: 'relative',
                                                            bottom: '8px'
                                                        }}
                                                        onClick={() => deleteViapoint(index)}
                                                    >
                                                        Delete
                                                    </Button>
                                                    {/* } */}
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} mt={2}>
                                                <FormControl sx={{ ...selectStylesOverride, width: "100%", display: 'flex' }}>
                                                    <Controller
                                                        name={`viaPointAddress-${index}`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    label="Via Point Address"
                                                                    value={item || null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleDetailCardChange(e, newVal, 'viapt', index)
                                                                    }}
                                                                    name={name}
                                                                    errors={errors}
                                                                    getOptionLabel={(option) => {
                                                                        const originalOption = GeofencesListing.find((item) => item._id === option._id);
                                                                        return originalOption ? originalOption.name : '';
                                                                    }}
                                                                    labelKey="name"
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option._id === value._id && option.name === value.name
                                                                    }
                                                                    options={GeofencesListing && GeofencesListing}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} gap={1} mt={2} display={'flex'}>
                                                <Grid item md={6}>
                                                    <Grid sx={{ height: '36px', alignItems: 'center', display: 'flex', paddingLeft: '8px', position: 'relative', ...textClasses.labelHeadBorder }}>
                                                        <Typography sx={{ position: 'absolute', top: '-7px', left: '12px', ...textClasses.labelHeadColor }}>Travel Time</Typography>
                                                        <Typography sx={{ color: '#808080', fontSize: '0.8rem', paddingLeft: '7px', paddingTop: '2PX' }}>{item.travelTime}</Typography>
                                                    </Grid>
                                                    {/* <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                        <Controller
                                                            name={`travelTime-${index}`}
                                                            control={control}
                                                            rules={{
                                                                required: {
                                                                    value: true,
                                                                    message: "Time is required",
                                                                },
                                                            }}
                                                            render={({ field: { onChange, value, name } }) => {
                                                                return (
                                                                    <CustomTimeField
                                                                        label="Travel Time"
                                                                        selectStylesOverride={selectStylesOverride}
                                                                        disabled
                                                                        value={convertHHMMToDate(item.travelTime) || null}
                                                                        onChange={(date) => _handleDetailCardChange(null, date, 'travelTime', index)}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </FormControl> */}
                                                </Grid>

                                                <Grid item md={6}>
                                                    <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                                        <Controller
                                                            name={`averageSpeed-${index}`}
                                                            control={control}
                                                            render={({ field: { onChange, value, name } }) => {
                                                                return (
                                                                    <CustomInput
                                                                        placeholder="Average Speed"
                                                                        label="Average Speed"
                                                                        value={item?.speed ? item?.speed : 40}
                                                                        required={false}
                                                                        disabled
                                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                        inputClasses={inputClasses}
                                                                        name={name}
                                                                        errors={errors}
                                                                        onChange={(e, newVal) => {
                                                                            _handleDetailCardChange(e, newVal, 'avgSpeed', index)
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />

                                                    </FormControl>
                                                </Grid>


                                            </Grid>
                                            <Grid item md={12} mt={2}>
                                                <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                    <DateTimePicker
                                                        name={`ScheduleTimeOfArrival-${index}`}
                                                        label="Schedule Time Of Arrival"
                                                        value={new Date(item?.STA)}
                                                        onChange={(date) => _handleDetailCardChange(null, date, 'STA', index)}
                                                        className="customDatePicker"
                                                        format="dd-MM-yyyy hh:mm a"
                                                        sx={{
                                                            width: "100%",
                                                            ...selectStylesOverride
                                                        }}
                                                        slotProps={dateTimePickerSlotProps}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} mt={2}>
                                                <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                    <DateTimePicker
                                                        name={`ScheduleTimeDepart-${index}`}
                                                        label="Schedule Time Of Departure"
                                                        value={new Date(item?.STD)}
                                                        onChange={(date) => _handleDetailCardChange(null, date, 'STD', index)}
                                                        className="customDatePicker"
                                                        format="dd-MM-yyyy hh:mm a"
                                                        sx={{
                                                            width: "100%",
                                                            ...selectStylesOverride
                                                        }}
                                                        slotProps={dateTimePickerSlotProps}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {item?.halt ?
                                                <Grid item md={12} mt={0} mb={2}>
                                                    <Typography sx={{ fontSize: '14px', paddingLeft: '8px' }}>Halt Duration :  {item.halt}</Typography>
                                                </Grid>
                                                : null}
                                        </Grid>
                                    )
                                })
                                :
                                <Grid item md={12} display={"flex"} pt={0} mb={2} sx={{ justifyContent: 'space-between', borderBottom: '1.5px dashed #ff7200' }} >
                                    <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>Via Points - 0</Typography>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                ...buttonClasses.lynkitGreenEmpty,
                                                marginRight: '10px',
                                                minHeight: "34px",
                                                position: 'relative',
                                                bottom: '8px'
                                            }}
                                            onClick={() => addViapoint()}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </Box>

                        <Box pb={3}>
                            <Grid container>
                                <Grid item md={12} >
                                    <Typography sx={{ ...textClasses.boldText, fontSize: "20px" }}>Destination</Typography>
                                </Grid>
                                <Grid item md={12} mt={2}>
                                    <FormControl sx={{ ...selectStylesOverride, width: "100%", display: 'flex' }}>
                                        <Controller
                                            name="destinationAddress"
                                            control={control}
                                            render={({ field: { onChange, value, name } }) => {
                                                return (
                                                    <CustomSelect
                                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                        label="Destination Address"
                                                        value={scheduleData?.routeDestination || null}
                                                        onChange={(e, newVal) => {
                                                            // onChange(newVal?.name);
                                                            _handleDetailCardChange(e, newVal, 'sourceDestination')

                                                        }}
                                                        name={name}
                                                        errors={errors}
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === "string") {
                                                                return GeofencesListing?.find((_) => _?.name == value)?.name || "";
                                                            } else {
                                                                return option?.name || "";
                                                            }
                                                        }}
                                                        labelKey="name"
                                                        isOptionEqualToValue={(option, value) =>
                                                            option._id === value._id && option.name === value.name
                                                        }
                                                        options={GeofencesListing && GeofencesListing}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={12} gap={1} mt={2} display={'flex'}>
                                    <Grid item md={6}>
                                        <Grid sx={{ height: '36px', alignItems: 'center', display: 'flex', paddingLeft: '8px', position: 'relative', ...textClasses.labelHeadBorder }}>
                                            <Typography sx={{ position: 'absolute', top: '-7px', left: '12px', ...textClasses.labelHeadColor }}>Travel Time</Typography>
                                            <Typography sx={{ color: '#808080', fontSize: '0.8rem', paddingLeft: '7px', paddingTop: '2PX' }}>{scheduleData?.routeDestination?.travelTime}</Typography>
                                        </Grid>

                                        {/* <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                            <Controller
                                                name="travelTime"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Time is required",
                                                    },
                                                }}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return (
                                                        <CustomTimeField
                                                            label="Travel Time"
                                                            disabled
                                                            selectStylesOverride={selectStylesOverride}
                                                            value={convertHHMMToDate(scheduleData?.routeDestination?.travelTime) || null}
                                                            onChange={(date) => _handleDetailCardChange(null, date, 'destinationTravelTime')}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl> */}
                                    </Grid>

                                    <Grid item md={6}>
                                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                            <Controller
                                                defaultValue=""
                                                name="destinationaverageSpeed"
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return (
                                                        <CustomInput
                                                            placeholder="Average Speed"
                                                            label="Average Speed"
                                                            required={false}
                                                            disabled
                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                            inputClasses={inputClasses}
                                                            name={name}
                                                            errors={errors}
                                                            value={scheduleData?.routeDestination?.speed || '40'}
                                                            onChange={(e, newVal) => {
                                                                _handleDetailCardChange(e, newVal, 'destinationaverageSpeed')
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />

                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item md={12} mt={2}>
                                    <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                        <DateTimePicker
                                            label="Schedule Time Of Arrival"
                                            value={new Date(scheduleData?.routeDestination?.STA)}
                                            onChange={(date) => _handleDetailCardChange(null, date, 'rdSTA')}
                                            className="customDatePicker"
                                            format="dd-MM-yyyy hh:mm a"
                                            sx={{
                                                width: "100%",
                                                ...selectStylesOverride
                                            }}
                                            slotProps={dateTimePickerSlotProps}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                    </CardContent>
                </Grid>

                {/* <Grid item pl={2} pb={0.8} pt={0.8} sx={{ background: '#F8F8F8', borderRadius: '2px' }}>
                    <Button
                        variant="contained"
                        sx={{
                            ...buttonClasses?.lynkitOrangeFill,
                            minHeight: "26px",
                            minWidth: "70px",
                        }}
                        onClick={() => editRouteSchedule()}
                    >
                        Edit
                    </Button>

                </Grid> */}



            </Card>


        </>
    );
};


