import React, { useEffect, useState } from 'react'
import { Grid,CircularProgress } from '@mui/material'
import { getFieldsLayoutForEntry } from '../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplate, trackUser } from '../../../redux/actions';
import loaderGif from "../../../static/images/drawer/loader.gif"
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
let initial_state = { single: [], multi: [] }

export default function DynamicFormRender({ formHooks, templateId, widthProp }) {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)
    const { register, control, errors, action } = formHooks;
    const autoFillOnFocus = "";
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const [layout, setLayout] = useState({ ...initial_state })
    

    useEffect(() => {
        if (templateId) {
            setLoader(true)
            dispatch(getTemplate({ template_id: templateId }, (data) => {
                setLoader(false)
                if (data?.attributes?.length > 0) {
                    let output_lauout = getFieldsLayoutForEntry(data.attributes.filter(obj => data.templateType === obj.templateType), register, control, autoFillOnFocus, errors, action,isDarkThemeEnabledSelector);
                    setLayout(output_lauout)
                }
                else {
                    setLayout({ ...initial_state })
                }
            }));
        }
        else {
            setLoader(false)
            setLayout({ ...initial_state })
        }
    }, [templateId])


    return (
        <>
            {loader
                ? <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                <ThreeDotsLoader/>
                    {/* <Grid item><img src={loaderGif} alt='' /></Grid> */}
                </Grid>
                :
                <Grid container sx={{pt:2}}>
                    <Grid item container spacing={2}>
                        {
                            layout.single.map((field, i) => {
                                return (
                                    <Grid container item={true} xs={6} sm={widthProp ? widthProp : 4} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 0.1 }}>
                        {
                            layout.multi.map((field, i) => {
                                return (
                                    <Grid container item={true} xs={6} sm={widthProp ? widthProp : 4} justifyContent={"center"} alignItems="center" key={i}>
                                        {field}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}