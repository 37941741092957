import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "../../../../components/FormUI";
import { useForm, useWatch } from "react-hook-form";
import GeofenceSection from "../../Users/components/GeofenceSection";
import {
  getAllDeviceCategoryNames,
  getAllDevicePermissions,
  getAllDevices,
  getAllGeofences,
  getAllRoutes,
} from "../../../../redux/actions";
import DeviceSection from "../../Users/components/DeviceSection";
import RouteSection from "../../Users/components/RouteSection";
import { flushAllDevices } from "../../../../redux/reducers/DevicesReducers";
import { selectStylesOverride } from "../../../../utils/util";

const AddAccount = () => {
  const dispatch = useDispatch();

  const [selectedDeviceCategory, setSelectedDeviceCategory] = React.useState(null);
  const [geofenceRadio, setGeofenceRadio] = React.useState(null);
  const [routeRadio, setRouteRadio] = React.useState(null);
  const [deviceRadio, setDeviceRadio] = React.useState(null);
  const [selectedGeofence, setSelectedGeofence] = React.useState([]);
  const [showAllGeo, setShowAllGeo] = React.useState(false);
  const [selectedRoute, setSelectedRoute] = React.useState([]);
  const [showAllRoute, setShowAllRoute] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState([]);
  const [showAllDevice, setShowAllDevice] = React.useState(false);
  const [deviceSpecificPermissions, setDeviceSpecificPermissions] = React.useState([]);
  const [initialSelectedGeofence, setInitialSelectedGeofence] = React.useState(
    []
  ); // --- only for edit
  const [initialSelectedDevice, setInitialSelectedDevice] = React.useState([]); // --- only for edit
  const [initialSelectedRoute, setInitialSelectedRoute] = React.useState([]); // --- only for edit
  const [searchFields, setSearchFields] = React.useState({
    geofence: null,
    routes: null,
    deviceType: null,
    devices: null,
  });

  let geoSize = selectedGeofence?.length;
  let routeSize = selectedRoute?.length;
  let deviceSize = selectedDevice?.length;
  let deviceFilter = { filter: {} };

  const { currentUserDetail } = useSelector((state) => state.auth);
  const geofencesListing = useSelector(
    (state) => state.geofencelist.geofenceListing
  );
  const routesListing = useSelector((state) => state.routes.allRoutes);
  const deviceTypeList = useSelector(
    (state) => state.deviceslist.allDeviceCategoryNames
  );
  const devicePermissions = useSelector(
    (state) => state.deviceslist.alldevicePermissions
  );
  const devices = useSelector((state) => state.deviceslist.allDevices);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const geofenceSelected = useWatch({
    control,
    name: "geofence",
  });
  const geoRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-geo",
  });

  const deviceTypeSelected = useWatch({
    control,
    name: "deviceType",
  });
  const deviceSelected = useWatch({
    control,
    name: "device",
  });
  const deviceRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-device",
  });

  const routesSelected = useWatch({
    control,
    name: "routes",
  });
  const routeRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-route",
  });

  // -------handlers --------
  const handleRemoveSelection = (index, item) => {
    if (item === "geofence") {
      let temp = [...selectedGeofence];
      temp.splice(index, 1);
      setSelectedGeofence(temp);
    } else if (item === "route") {
      let temp = [...selectedRoute];
      temp.splice(index, 1);
      setSelectedRoute(temp);
    } else if (item === "device") {
      let temp = [...selectedDevice];
      temp.splice(index, 1);
      setSelectedDevice(temp);
    }
  };

  const handleSearchDeviceType = (reset = false) => {
    if (searchFields?.deviceType || searchFields?.deviceType == "") {
      if (reset === true) {
        dispatch(getAllDeviceCategoryNames({selection: ["category"],}));
      } else {
        dispatch(
          getAllDeviceCategoryNames({
            selection: ["category"],
            searchValue: searchFields?.deviceType,
          })
        );
      }
    }
  };

  const handleSearchDevice = (reset = false) => {
    if (searchFields?.devices || searchFields?.devices == "") {
      if (reset === true) {
        dispatch(
          getAllDevices({
            filter: {
              deviceType: deviceTypeSelected,
            },
          })
        );
      } else {
        dispatch(
          getAllDevices({
            q: searchFields?.devices,
          })
        );
      }
    }
  };

  const handleSearchGeofence = (reset = false) => {
    if (
      (currentUserDetail && searchFields?.geofence) ||
      searchFields?.geofence == ""
    ) {
      if (reset === true) {
        dispatch(
          getAllGeofences({selection: ["name"], filter: { orgId: currentUserDetail?.orgId } })
        );
      } else {
        dispatch(
          getAllGeofences({
            selection: ["name"],
            filter: {
              name: searchFields?.geofence,
              orgId: currentUserDetail?.orgId,
            },
          })
        );
      }
    }
  };

  const handleSearchRoute = (reset = false) => {
    if (
      (currentUserDetail && searchFields?.routes) ||
      searchFields?.routes == ""
    ) {
      if (reset === true) {
        dispatch(getAllRoutes({selection: ["name"], filter: { orgId: currentUserDetail?.orgId } }));
      } else {
        dispatch(
          getAllRoutes({
            selection: ["name"],
            filter: {
              searchValue: searchFields?.routes,
              orgId: currentUserDetail?.orgId,
            },
          })
        );
      }
    }
  };

  const handleAddDevice = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (deviceSelected) {
      const tempSelectedDevices = [...selectedDevice];
      tempSelectedDevices?.push({
        id: deviceSelected,
        permissions: {
          deviceSpecificPermission: deviceSpecificPermissions,
        },
      });
      setSelectedDevice(tempSelectedDevices);
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
      setValue("device", undefined);
      dispatch(flushAllDevices());
    }
  };

  const handleCheckboxChange = (e, key) => {
    setDeviceSpecificPermissions((prevData) => {
      if (prevData[key]) {
        return {
          ...prevData,
          [key]: { ...prevData[key], value: e?.target?.value ? true : false },
        };
      } else {
        return prevData;
      }
    });
  };

  // -----effects ---------
  React.useEffect(() => {
    if (geofenceSelected) {
      setSelectedGeofence(geofenceSelected);
    }
  }, [geofenceSelected]);

  React.useEffect(() => {
    if (routesSelected) {
      setSelectedRoute(routesSelected);
    }
  }, [routesSelected]);

  React.useEffect(() => {
    if (deviceRadioWatch === "select") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    }
    else if (deviceRadioWatch === "None") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    }
  }, [deviceRadioWatch]);

  React.useEffect(() => {
    if (geoRadioWatch === "select") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadioWatch);
    } else if (geoRadioWatch === "All") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      let arr =
        geofencesListing &&
        geofencesListing?.map((item) => ({ id: item._id, name: item?.name }));
      setValue("geofence", arr);
      setSelectedGeofence(arr);
      setGeofenceRadio(geoRadioWatch);
    } else if (geoRadioWatch === "None") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadioWatch);
    }
  }, [geoRadioWatch]);

  React.useEffect(() => {
    if (routeRadioWatch === "select") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "All") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      let arr =
        routesListing &&
        routesListing?.map((item) => ({ id: item._id, name: item?.routeName }));
      setSelectedRoute(arr);
      setValue("routes", arr);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "None") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    }
  }, [routeRadioWatch]);

  React.useEffect(() => {
    if (deviceTypeSelected) {
      setSelectedDeviceCategory(deviceTypeSelected);
      dispatch(
        getAllDevices({
          ...deviceFilter,
          filter: { deviceType: deviceTypeSelected },
          selection: ["client_id"]
        })
      );
      dispatch(getAllDevicePermissions());
    }
  }, [deviceTypeSelected]);

  React.useEffect(() => {
    if (currentUserDetail && currentUserDetail?.orgId) {
      const organizationSelected = currentUserDetail?.orgId;
      const paylaodFilter = {
        page: 1,
        limit: 100,
      };
      dispatch(
        getAllGeofences(
          { ...paylaodFilter, selection: ["name"], filter: { orgId: organizationSelected } },
          () => {}
        )
      );
      dispatch(getAllDeviceCategoryNames({selection: ["category"]}));
      dispatch(
        getAllRoutes(
          { ...paylaodFilter, selection: ["routeName"], filter: { orgId: organizationSelected } },
          () => {}
        )
      );
    }
  }, [currentUserDetail]);

  React.useEffect(() => {
    if (devicePermissions && devicePermissions?.deviceSpecificPermission) {
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
    }
  }, [devicePermissions]);

  return (
    <>
      <Grid container>
        {/* top row - page title*/}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          data-testid="page-title"
        >
          <Typography sx={{ ...textClasses.cardTitle }}>
            Link Account
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          p={2}
          my={2}
          borderRadius="10px"
          border="1px solid #E0E0E0"
        >
          <Grid item xs={12}>
            <Typography sx={{ ...textClasses.normalText }}>
              Firstly, need to enter and verify the account ID of user and then
              proceed for account linkage.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" gap={3} mt={2}>
            <CustomInput
              label="Account ID"
              placeholder="Enter account ID"
              maxWidth="300px"
              selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            />
            <Button
              sx={{ ...buttonClasses.lynkitOrangeFill, width: "max-content" }}
            >
              Verify User
            </Button>
            {/* <Button sx={{textDecoration: "underline"}}>
              Reset
            </Button> */}
          </Grid>

          <Grid mt={2} item xs={12}>
            <GeofenceSection
              handleRemoveSelection={handleRemoveSelection}
              geofencesListing={geofencesListing}
              selectedGeofence={selectedGeofence}
              showAllGeo={showAllGeo}
              setShowAllGeo={setShowAllGeo}
              geoSize={geoSize}
              searchFields={searchFields}
              setSearchFields={setSearchFields}
              handleSearchGeofence={handleSearchGeofence}
              geofenceRadio={geofenceRadio}
              setGeofenceRadio={setGeofenceRadio}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={() => {}}
              setValue={setValue}
              title="Share Geofence(s)"
            />

            <RouteSection
              handleRemoveSelection={handleRemoveSelection}
              routesListing={routesListing}
              selectedRoute={selectedRoute}
              showAllRoute={showAllRoute}
              searchFields={searchFields}
              setSearchFields={setSearchFields}
              handleSearchRoute={handleSearchRoute}
              setShowAllRoute={setShowAllRoute}
              routeSize={routeSize}
              routeRadio={routeRadio}
              setRouteRadio={setRouteRadio}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={() => {}}
              setValue={setValue}
              title="Share Route(s)"
            />

            <DeviceSection
              handleRemoveSelection={handleRemoveSelection}
              deviceSize={deviceSize}
              deviceRadio={deviceRadio}
              setDeviceRadio={setDeviceRadio}
              searchFields={searchFields}
              setSearchFields={setSearchFields}
              deviceTypeList={deviceTypeList}
              devices={devices}
              selectedDeviceCategory={selectedDeviceCategory}
              setSelectedDeviceCategory={setSelectedDeviceCategory}
              handleSearchDeviceType={handleSearchDeviceType}
              handleSearchDevice={handleSearchDevice}
              selectedDevice={selectedDevice}
              deviceSelected={deviceSelected} //device dropdown controller value
              showAllDevice={showAllDevice}
              setShowAllDevice={setShowAllDevice}
              handleAddDevice={handleAddDevice}
              permissions={deviceSpecificPermissions}
              handleCheckboxChange={handleCheckboxChange}
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onSubmit={() => {}}
              setValue={setValue}
              title="Share Device(s)"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddAccount;
