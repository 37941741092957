import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Form, Button } from "reactstrap";
import { Box, Button, IconButton, Typography } from "@mui/material";
// import DatePicker from "react-datepicker/dist/react-datepicker";
import moment from "moment/moment";
import { Close, Download, Visibility } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { selectStylesOverride } from "../../../utils/util";
import {
  getMainAssetData,
  getVendorAssetData,
} from "../../../redux/actions/AssetMgt";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { flushUsersList, getAllUsers } from "../../../redux/actions";
import XLSX from "sheetjs-style";
import { convertIntoGivenTimezone } from "../../../services/functions";
// import {
// 	getMainAssetData,
// 	getVendorAssetData
// } from "../../redux/asset_management/assetManagementSlice";
// import { useMainAssetCount, useVendorAsset } from ".";
// import { useFilterPanel } from "./useFilterPanel";

export const FilterPanel = ({
  isOpen,
  onClose,
  data,
  setFilterDate,
  fromDate,
  handleFilter,
  filterOpen,
  themeBorderColor,
  isDark,
  themeType,
  filterObj,
  setFilterObj,
  applyFiltersFn,
  currentUser,
  orgOptions = [],
  userOptions = [],
  searchFields,
  setSearchFields,
  handleSearchOrg,
  handleSearchUsers,
  mainFilterDate,
  setMainFilterDate,
}) => {
  // const [fromDate, setFromDate] = useState(new Date()); // need to cmt
  // const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();
  // const {  fromDate, setFilterDate } = useFilterPanel();
  const [toDate, setToDate] = useState("");
  // const { mainAssetData, isMainAssetDataLoading } = useMainAssetCount();
  // const { vendorAssetData, isVendorAssetDataLoading, fetchVendorAssetData } = useVendorAsset();
  const { buttonClasses, textClasses, inputClasses } = themeType;

  const handleDateChange = (value) => {
    // setFilterDate(value);
    setMainFilterDate(value);
  };
  const { currentUserDetail } = useSelector((_) => _?.auth);

  function convertPacketTime(time, item){
    if(item === 'packetTime'){
    let newTime = time * 1000
    let convertedTime = convertIntoGivenTimezone(newTime, currentUserDetail.timeZone.offset)
    const parsedDateTime = moment(convertedTime, 'DD/MM/YYYY hh:mm:ss A');
    const formattedDateTime = parsedDateTime.format('YYYY-MM-DD HH:mm');
    return formattedDateTime
    }
    else{
      let convertedTime = convertIntoGivenTimezone(Number(time), currentUserDetail.timeZone.offset)
      const parsedDateTime = moment(convertedTime, 'DD/MM/YYYY hh:mm:ss A');
      const formattedDateTime = parsedDateTime.format('YYYY-MM-DD HH:mm');
      return formattedDateTime
    }
  }

  

 


  return (
    <div
      className={`asset_management_detail_panel ${isOpen ? "open" : "clos"}`}
      data-testid="detail_panel_wrap"
      style={{
        borderColor: themeBorderColor,
        visibility: `${isOpen ? "visible" : "hidden"}`
      }}
    >
      {isOpen ? (
        <>
          {/* <h5 className="panel__header">
            <span
              className="close_btn"
              onClick={onClose}
              aria-label="close_btn"
            ></span>
            {data?.label}
          </h5> */}

          <Box
            width="100%"
            mb={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: `1px solid ${themeBorderColor}`,
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                marginLeft: "25px",
                textAlign: "center",
                ...textClasses.themeColor,
              }}
            >
              {data?.label}
            </Typography>
            <IconButton
              size="medium"
              sx={{ marginLeft: "auto" }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Box>
          {filterPanel()}
        </>
      ) : null}
    </div>
  );

  function filter() {
    applyFiltersFn();
    // --- to make it outside.
    // handleFilter(true);
    // dispatch(
    //   getMainAssetData({
    //     deviceType: "all",
    //     time: moment(fromDate).format("YYYY-MM-DD HH:mm"),
    //   })
    // );
    // dispatch(
    //   getVendorAssetData({
    //     page: 1,
    //     limit: 10,
    //     time: moment(fromDate).format("YYYY-MM-DD HH:mm"),
    //   })
    // );
  }

  function convertTime(dateTime) {
    const secs = dateTime;
    const output = new Date(secs * 1000);
    return output;
  }



  function download() {
    dispatch(
      getMainAssetData(
        {
          deviceType: "all",
          time: moment(mainFilterDate).format("YYYY-MM-DD HH:mm"),
          type: "download",
          orgId: filterObj?.orgId ?? "",
          userId: filterObj?.userId ?? "",
        },
        (res) => {
          if (res && res?.length > 0) {
            const downloadData = res;
            let newArr = [];
            for (let i = 0; i < downloadData.length; i++) {
              var elem = downloadData[i];
              console.log('elem',elem);
              newArr.push({
                "Device ID": elem?.client_id ? elem?.client_id : "",
                "Admin Name-Org Name": elem.orgName ? `${elem.adminName}-${elem.orgName}` : `${elem.adminName}`,
                "Vehicle No": elem.vehicleNo ? elem.vehicleNo : "",
                "Device Status": elem.ignitionStatus ? elem.ignitionStatus : "",
                "Lock Status": elem.lockStatus ? elem.lockStatus : "",
                "Battery Percentage": elem.batteryPercentage
                  ? parseInt(elem.batteryPercentage) + "%"
                  : "",
                "Geofence Name": elem.geofenceName ? elem.geofenceName : "",
                "Previous Geofence Name": elem.previousGeofenceName ? elem.previousGeofenceName : "",
                "Lat/Lng": Number(elem.latitude)?.toFixed(5) + "/" + Number(elem.longitude)?.toFixed(5),
                "Packet Time": convertPacketTime(elem.packetTime,'packetTime'),
                "Trip ID": elem.tripId ? `${elem.tripId}` : "",

                "Trip Name": elem.tripName ? `${elem.tripName}` : "",
                "Trip Status": elem.tripStatus ? elem.tripStatus : "",
                "Route Name": elem.routeName ? elem.routeName : "",
                "Route Description": elem.routeDescription
                  ? elem.routeDescription
                  : "-",
                  
                "Trip Completion Time":  elem.tripCompletionTime && /^\d{10}$/.test(elem.tripCompletionTime) ? convertPacketTime(elem.tripCompletionTime * 1000, 'tripCompletionTime') : (elem.tripCompletionTime ? convertPacketTime(elem.tripCompletionTime, 'tripCompletionTime') : "-"),
                
                "Category": elem.label,
                "Idle Since Last Event": elem.idleSinceLastAlert ? elem.idleSinceLastAlert : "",
                "Idle Since Last Trip": elem.tripStatus === 'completed' ? elem.idleTime : ""
              });
            }

            if (newArr.length > 0) {
              const headers = Object.keys(newArr[0]);
              const sheetData = [
                headers,
                ...newArr.map((item) => headers.map((header) => item[header])),
              ];
              const workbook = XLSX.utils.book_new();
              const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
              XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
              XLSX.writeFile(workbook, `assetData-${moment(mainFilterDate)?.format("YYYY-MM-DD HH:mm")}.xlsx`);
            }
          }
        }
      )
    );
  }

  function filterPanel() {
    const dateTimePickerSlotProps = {
      textField: { size: "small", ...inputClasses.textfield },
      day: {
        sx: {
          "& .Mui-selected:hover": {
            background: "#FF7200",
          },
        },
      },
      popper: {
        sx: {
          "& .MuiDateCalendar-root": {
            maxHeight: "300px",
          },
          "& .MuiMultiSectionDigitalClock-root": {
            maxHeight: "300px",
          },
        },
      },
      digitalClockSectionItem: {
        sx: {
          fontSize: "12px",
        },
      },
    };

    return (
      <div className="categories_container" data-testid="categories_container">
        <form className="aItemsCenter gap-one w-100">
          {/* {
            <DatePicker
              // showIcon
              selected={fromDate}
              onChange={(date) => handleDateChange(date)}
              dateFormat="dd-MM-yyyy hh:mm"
              maxDate={new Date()}
              timeIntervals={15}
              showTimeSelect
              timeFormat="p"
            />
          } */}
          <DateTimePicker
            value={mainFilterDate ? mainFilterDate : new Date()}
            onChange={(date) => handleDateChange(date)}
            className="customDatePicker"
            sx={{
              width: "100%",
              marginBottom: 2,
              ...selectStylesOverride,
            }}
            slotProps={dateTimePickerSlotProps}
          />
          {currentUser && currentUser?.adminSpecificPermissions && (
            <Box className="d-flex flex-column text-center" gap={2} mb={2}>
              <CustomSelect
                selectStylesOverride={selectStylesOverride}
                label="Select Organization"
                labelKey="name"
                searchValue={
                  searchFields?.org && searchFields?.org !== ""
                    ? searchFields?.org
                    : null
                }
                value={filterObj?.orgId || null}
                onChange={(e, newVal) => {
                  if (newVal) {
                    dispatch(
                      getAllUsers({ orgId: newVal?._id, selection: ["name"] })
                    );
                    setFilterObj((prev) => ({
                      ...prev,
                      orgId: newVal?._id,
                    }));
                  } else {
                    dispatch(flushUsersList());
                    let temp = { ...filterObj };
                    delete temp["orgId"];
                    setFilterObj(temp);
                  }
                }}
                onSearch={handleSearchOrg}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchOrg(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      org: e?.target?.value?.trim(),
                    }));
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return (
                      orgOptions?.find((_) => _?._id == filterObj?.orgId)
                        ?.name || ""
                    );
                  } else {
                    return option?.name || "";
                  }
                }}
                options={orgOptions && orgOptions}
              />
              <CustomSelect
                selectStylesOverride={selectStylesOverride}
                label="Select Users"
                labelKey="name"
                disabled={!filterObj?.orgId}
                searchValue={
                  searchFields?.users && searchFields?.users !== ""
                    ? searchFields?.users
                    : null
                }
                value={filterObj?.userId || null}
                onChange={(e, newVal) => {
                  if (newVal) {
                    setFilterObj((prev) => ({
                      ...prev,
                      userId: newVal?._id,
                    }));
                  } else {
                    let temp = { ...filterObj };
                    delete temp["userId"];
                    setFilterObj(temp);
                  }
                }}
                onSearch={handleSearchUsers}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchUsers(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      users: e?.target?.value?.trim(),
                    }));
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return (
                      userOptions?.find((_) => _?._id == filterObj?.userId)
                        ?.name || ""
                    );
                  } else {
                    return option?.name || "";
                  }
                }}
                options={userOptions && userOptions}
              />
            </Box>
          )}

          {
            <Box className="d-flex flex-column text-center" gap={2}>
              <Button
                style={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  boxShadow: "none",
                  height: "auto",
                }}
                onClick={filter}
                disabled={fromDate ? false : true}
              >
                Apply Changes
              </Button>
              <Button
                // className="btn-blue"
                style={{
                  ...buttonClasses.lynkitBlackFill,
                }}
                onClick={download}
                disabled={fromDate ? false : true}
                startIcon={<Download />}
              >
                Download
              </Button>
            </Box>
            // <div className="d-flex">
            // 	<Button color="primary" className="px-3 mx-2" onClick={filter} disabled={fromDate ? false : true}>
            // 		View
            // 	</Button>
            // 	<Button className="btn-blue px-3 mx-2"  onClick={download} disabled={fromDate ? false : true}>
            // 		Download
            // 	</Button>
            // 	<Button color="light" outline className="px-3 mx-2" onClick={filter} disabled={mainAssetData && mainAssetData.length != 0 ? false : true}>
            // 		Clear
            // 	</Button>
            // </div>
          }
        </form>
      </div>
    );
  }
};
