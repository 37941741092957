import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { useHistory, useParams, Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import DataTable from "./components/DataTable";
import { Upload } from "@mui/icons-material";
import { getTripSummary, fetchAddressFromCoords, getTripPerformanceData, userDetails, emptyTripSummary } from "../../../redux/actions";
import { capitalizeSentence, convertIntoGivenTimezone } from "../../../services/functions";
import ViaPointsModal from "./components/ViaPointsModal";
import loaderGif from "../../../static/images/drawer/loader.gif";
// import DoughnutChart from "../../Components/DoughnutChart/DoughnutChart";
import DonutChart from "../../Components/D3/DonutChart";
import { graphColors } from "../../../services/constants";
import { BarChart } from "../../Components/BarChart/BarChart";
import EmptyPage from "../EmptyPage";
const TripSummary = (props) => {
  let propsShared = props?.location?.state?.currentDeviceId
  const dispatch = useDispatch()
  const history = useHistory();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const summarDetails = useSelector((state) => state.tripsData?.tripSummary || []);
  const performanceDetails = useSelector((state) => state.tripsData.tripPerformanceData || []);
  const [trackData, setTrackData] = useState([])
  const [viaModal, setViaModal] = useState(false)
  const [summaryLoader, setSummaryLoader] = useState(false)
  const [viaData, setViaData] = useState([])
  const [errMsg, setErrMssg] = useState('')
  const [d3DougnutArray, setD3DougnutArray] = useState([]);
  const { textClasses, buttonClasses } = themeType;
  const themeBorderColor = isDarkThemeEnabledSelector ? "#282828" : "#D9D9D9";
  const userTimeZone = useSelector((state) => state.auth.currentUserDetail);
  useEffect(() => {
    let tempSummary = [];
    if (summarDetails && summarDetails?.trackEventData?.length) {
      tempSummary = summarDetails?.trackEventData;
    }
    if(summarDetails?.tripData?.tripForcefullyClosed){
      const closedData = summarDetails?.tripData?.tripForcefullyClosed;
      tempSummary?.push({
        date: new Date(Number(closedData?.packetTime) * 1000),
        event: "Forcefully Closed",
        lockStatus: closedData?.lockStatus,
        lat: closedData?.latitude,
        lng: closedData?.longitude,
      })
    }
    setTrackData([...tempSummary]);
  }, [summarDetails?.trackEventData])

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      let payload = {
        projection: ['event', 'trip', 'analytics'],
        trip_id: id
      }
      dispatch(emptyTripSummary())
      setSummaryLoader(true)
      dispatch(getTripSummary({ ...payload, deviceId: propsShared }, (res) => {
        if (!res.success) {
          setErrMssg(res?.message)
          setTrackData([])
        }
        setSummaryLoader(false);
      })
      );
      dispatch(getTripPerformanceData({ trip_id: id, deviceId: propsShared }))
    }
  }, [id])

  const getAddressFromCoords = (i, item) => {
    let temp = [...summarDetails?.trackEventData]
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(fetchAddressFromCoords(payload, (res) => {
      if (res && res?.length > 0) {
        const { address } = res[0];

        if (temp[i]) {
          temp[i] = { ...temp[i], address: address };
        }
        setTrackData([...temp]);
      }
    })
    );
  }

  const columns = [
    {
      header: "#",
      cell: (i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {i + 1}
          </Typography>
        );
      },
    },
    {
      header: "Date-Time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {convertIntoGivenTimezone(entry?.date, userTimeZone.timeZone.offset) ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Event",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.event ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {capitalizeSentence(entry?.lockStatus) ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Location",
      cell: (i, entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              cursor: 'pointer',
            }}
            onClick={() => getAddressFromCoords(i, entry)}
          >
            {entry?.address && Object.keys(entry.address).length !== 0 ? (
              <Grid sx={{ display: 'flex', maxWidth: '250px', margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    textAlign: "center",
                    cursor: 'pointer',
                    // color: '#ff7200',
                    textDecoration: 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    whiteSpace: 'initial',
                    // color: '#000'
                  }}
                  onClick={() => getAddressFromCoords(i, entry)}
                > {entry.address}
                </Typography>
              </Grid>
            ) : (
              <Typography sx={{
                fontSize: '13px', '&:hover': {
                  color: '#ff7200',
                  textDecoration: 'none',
                },
              }}>{entry?.lat ?? "-"} / {entry?.lng ?? "-"}</Typography>
            )}
            <br />
            <Typography
              sx={{
                fontSize: '13px', fontWeight: '600', '&:hover': {
                  color: '#000',
                  cursor: 'pointer',
                },
              }}>
              {entry?.geo_name !== 'nil' ? entry?.geo_name : '-'}
            </Typography>
          </Typography>
        );
      },
    },
    {
      header: "Battery",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.battery ? entry?.battery + ' %' : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Speed",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.speed ?? "-"}
          </Typography>
        );
      },
    },
  ];
  const getStatus = (id, item) => {
    let res = item?.tripData?.devices?.find(item => item.deviceId === id)?.status
    if (res === 'in_transit') {
      return "In-Transit"
    }
    else {
      return capitalizeSentence(res)
    }
  }
  const details = [
    {
      title: "Vehicle Number",
      key: "vehicleNo",
      value: summarDetails && summarDetails?.tripData && summarDetails?.tripData?.vehicleDetails ? summarDetails?.tripData?.vehicleDetails?.vehicleNumber : '-',
    },
    {
      title: "Trip Id",
      key: "tripId",
      value: summarDetails && summarDetails?.tripData ? summarDetails?.tripData?.tripId : '-',
    },
    {
      title: "Device Id",
      key: "client_id",
      value: propsShared || '-',
    },
    {
      title: "Trip Status",
      key: "status",
      value: getStatus(propsShared, summarDetails)
      // value: summarDetails && summarDetails?.tripData && summarDetails?.tripData?.status ? summarDetails?.tripData?.status === 'in_transit' ? 'In-Transit' : capitalizeSentence(summarDetails?.tripData?.status) : '-',
    },
    {
      title: "Remarks",
      key: "remarks",
    },
  ];
  const table1 = [
    {
      title: "Total Trip Time",
      key: "totalTripTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.total_trip_time ? summarDetails?.analyticsData?.total_trip_time : '-',
    },
    {
      title: "Total Stoppage Time",
      key: "Total Stoppage Time",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.total_stoppage_time ?
      (summarDetails.analyticsData.total_stoppage_time === 'NaN:NaN:NaN' ?
        '0' :
        summarDetails.analyticsData.total_stoppage_time) :
      '-'
    },

    {
      title: "Total Moving Time",
      key: "totalTripTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.total_moving_time ? summarDetails?.analyticsData?.total_moving_time : '-',
    },
    {
      title: "Total Distance Covered",
      key: "totalDistanceCovered",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.total_distance_covered ? summarDetails?.analyticsData?.total_distance_covered + ' kms' : '-',
    },
    {
      title: "Average Speed",
      key: "averageSpeed",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.average_speed ? summarDetails?.analyticsData?.average_speed + ' kmph' : '-',
    },
    {
      title: "Average Moving Speed",
      key: "averageMovingSpeed",
      value: summarDetails?.analyticsData?.average_moving_speed ?
      (summarDetails.analyticsData.average_moving_speed === 'NaN' || summarDetails.analyticsData.average_moving_speed === 'Infinity' ?
          '0 kmph' :
          `${summarDetails.analyticsData.average_moving_speed} kmph`) :
      '-'
    },
    {
      title: "Average Transit Journey Speed",
      key: "averageTransitJourneySpeed",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.average_transit_journey_speed ? summarDetails?.analyticsData?.average_transit_journey_speed + ' kmph' : '-',
    },
    {
      title: "Time Spent in Origin",
      key: "timeSpentInOrigin",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.time_spent_in_origin ? summarDetails?.analyticsData?.time_spent_in_origin : '-',
    },
    {
      title: "Time Spent in Transit",
      key: "timeSpentInTransit",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.time_spent_in_transit ? summarDetails?.analyticsData?.time_spent_in_transit : '-',
    },
    {
      title: "Time Spent in Destination",
      key: "timeSpentInDestination",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.time_spent_in_destination ? summarDetails?.analyticsData?.time_spent_in_destination : '-',
    },
    {
      title: "Time Spent in Via Points",
      key: "timeSpentInViaPoints",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.time_spent_in_viapoints ? summarDetails?.analyticsData?.time_spent_in_viapoints[summarDetails?.analyticsData?.time_spent_in_viapoints.length - 1]?.totalTimeSpentInViaPoints : '-',
      data: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.time_spent_in_viapoints ? summarDetails?.analyticsData?.time_spent_in_viapoints : []
    },
    {
      title: "Origin - Destination",
      key: "originDestination",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.origin_destination ? summarDetails?.analyticsData?.origin_destination : '-',
    },
    {
      title: "Sealing Time",
      key: "sealingTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.sealing_time
        ? convertIntoGivenTimezone(summarDetails?.analyticsData?.sealing_time, userTimeZone.timeZone.offset)
        : '-'

    },
    {
      title: "Trip Begin Transit Time",
      key: "tripBeginTransitTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.trip_begin_transit_time
        ? convertIntoGivenTimezone(summarDetails?.analyticsData?.trip_begin_transit_time, userTimeZone.timeZone.offset)
        : '-'
    },
    {
      title: "Arrival Time",
      key: "arrivalTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.arrival_time ?
        convertIntoGivenTimezone(summarDetails?.analyticsData?.arrival_time, userTimeZone.timeZone.offset)
        : '-',
    },
    {
      title: "Trip Completed Time",
      key: "completedTime",
      value: summarDetails && summarDetails?.analyticsData && summarDetails?.analyticsData?.trip_completed_time ?
        convertIntoGivenTimezone(summarDetails?.analyticsData?.trip_completed_time, userTimeZone.timeZone.offset) : '-'
    },

  ];
  const moveBack = () => {
    history.goBack()
  }
  const openViaModal = (item, i) => {
    setViaModal(true)
    setViaData(item?.data)
  }

  const calculatePercentage = (time, item) => {
    if (item === 'duration') {
      if (time && summarDetails?.analyticsDataChart) {
        let timeInDestination = summarDetails?.analyticsDataChart?.time_spent_in_destination
        let timeInOrigin = summarDetails?.analyticsDataChart?.time_spent_in_origin
        let timeInTransit = summarDetails?.analyticsDataChart?.time_spent_in_transit

        let totalTime = timeInDestination + timeInOrigin + timeInTransit

        let res = Math.ceil((time / totalTime) * 100);

        return res;
      }
    }
    else if (item === 'time') {
      if (time && summarDetails?.analyticsDataChart) {
        let movingTime = summarDetails?.analyticsDataChart?.total_moving_time
        let stoppageTime = summarDetails?.analyticsDataChart?.total_stoppage_time
        let totalTime = movingTime + stoppageTime
        let res = Math.ceil((time / totalTime) * 100);

        return res;
      }
    }
    else return '-';

  }

  useEffect(() => {
    let d3Array = [];
    if (summarDetails && summarDetails?.analyticsDataChart || {}) {
      let tripDuartion = [
        { label: "In Origin", value: calculatePercentage(summarDetails?.analyticsDataChart?.time_spent_in_origin, 'duration'), color: graphColors?.blue, param: 'percentage' },
        { label: "In Transit", value: calculatePercentage(summarDetails?.analyticsDataChart?.time_spent_in_transit, 'duration'), color: graphColors?.orange, param: 'percentage' },
        { label: "In Destination", value: calculatePercentage(summarDetails?.analyticsDataChart?.time_spent_in_destination, 'duration'), color: graphColors?.brightGreen, param: 'percentage' },
        { chartTitle: "Trip Duration" }
      ];
      let moving_Stoppage = [
        { label: "Moving Time", value: calculatePercentage(summarDetails?.analyticsDataChart?.total_moving_time, 'time'), color: graphColors?.brightGreen, param: 'percentage' },
        { label: "Stoppage", value: calculatePercentage(summarDetails?.analyticsDataChart?.total_stoppage_time, 'time'), color: graphColors?.red, param: 'percentage' },
        { chartTitle: "Total Moving vs\n Total Stoppage" }
      ];

      d3Array.push(
        [...tripDuartion],
        [...moving_Stoppage]
      );
    }
    setD3DougnutArray(d3Array);
  }, [summarDetails, summarDetails.analyticsDataChart]);




  return (
    <>

      {summaryLoader ? (
        <Grid
          container
          item
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 15 }}
        >
          <Grid item>
            <img data-testid="loaderImg" src={loaderGif} />
          </Grid>
        </Grid>
      ) : (
        <>
          {trackData && trackData.length === 0 ? (
            <Grid container justifyContent={"center"} height={'85vh'} alignItems="center">
              <EmptyPage body={errMsg} />
            </Grid>
          ) : (
            <Grid container>
              <Grid item={true} sm={12} xs={12} >
                <Grid container>
                  <Grid item={true} sm={6} xs={4} >
                    <Typography sx={{ ...textClasses.cardTitle }}>
                      Trip Summary
                    </Typography>
                  </Grid>
                  <Grid item={true} sm={6} xs={6} sx={{ displey: 'flex', display: 'flex', justifyContent: 'flex-end', height: '36px' }}  >
                    {/* <Button
              sx={{ ...buttonClasses.lynkitOrangeFill, marginRight: '15px' }}
              startIcon={<Upload />}
            >
              Export to Excel
            </Button> */}
                    {/* <Button onClick={() => moveBack()} variant='outlined' size='small' sx={{ ...buttonClasses.small, color: buttonClasses.lynkitBlackFill }}>Back</Button> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Grid>
                    <Breadcrumbs sx={{ mb: 1 }}>
                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          fontSize: "12px",
                          color: themeType.default.themeOrange,
                        }}
                      >
                        <Link
                          style={{ color: themeType.default.themeOrange, textDecoration: "none" }}
                          to="/dashboard/trips"
                        >
                          Trips
                        </Link>
                      </Typography>

                      <Typography
                        sx={{
                          ...textClasses.normalText,
                          fontSize: "12px",
                          color: themeType.default.themeOrange,
                        }}
                      >
                        <Link
                          style={{ color: themeType.default.themeOrange, textDecoration: "none" }}
                          to={`/dashboard/trips/trip-map/${id}?id=${propsShared}`}
                        >
                          Trip Map
                        </Link>
                      </Typography>

                      <Typography
                        sx={{ ...textClasses.normalText, fontSize: "12px" }}
                      >
                        Trip Summary
                      </Typography>
                    </Breadcrumbs>
                  </Grid>
                  <Card>
                    <CardContent sx={{ padding: "0px !important" }}>
                      <Box display="flex" alignItems="center" width="100%">
                        {summarDetails?.tripData && details?.map((detail, i) => {
                          return (
                            <Box
                              key={i}
                              width="100%"
                              p={2}
                              borderRight={
                                i !== details?.length - 1
                                  ? `1px solid ${themeBorderColor}`
                                  : "none"
                              }
                              marginRight={i !== details?.length - 1 ? 1 : 0}
                            >
                              <Typography
                                sx={{ ...textClasses.normalText, fontWeight: 700 }}
                              >
                                {detail?.title}
                              </Typography>
                              <Typography sx={{ ...textClasses.normalText }}>
                                {detail?.value || "-"}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </CardContent>
                  </Card>

                  {!summaryLoader ?
                    <Card sx={{ mt: 3, }}>
                      <CardContent>
                        <Box display="flex" gap={1}>
                          <Box width="100%">
                            <Table>
                              {table1?.map((_, i) => {
                                return (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        padding: "10px",
                                        border: `1px solid ${themeBorderColor}`,
                                      }}
                                    >
                                      <Typography
                                        sx={{ ...textClasses.normalText, fontWeight: 700 }}
                                      >
                                        {_?.title}
                                      </Typography>
                                    </td>
                                    <td
                                      style={{
                                        padding: "10px",
                                        paddingRight: "30px",
                                        border: `1px solid ${themeBorderColor}`,
                                      }}
                                    >
                                      <Typography sx={{ ...textClasses.normalText, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {_?.value || '-'}
                                        {_.title === 'Time Spent in Via Points' ? _.data.length ?
                                          <div className="outlined-theme-btn" style={{ margin: '0px', marginLeft: '6px' }} onClick={() => openViaModal(_, i)}><i class="fa fa-info" aria-hidden="true"></i>
                                          </div> : null
                                          : null}
                                      </Typography>

                                    </td>
                                  </tr>
                                );
                              })}
                            </Table>
                          </Box>
                          <Box className='graphBox' width="100%" justifyContent='center' display="flex" alignItems="center">
                            <Grid container justifyContent="center" mt={2}>
                              <DonutChart data={d3DougnutArray} widthProp={6} />
                            </Grid>
                          </Box>
                        </Box>
                        {trackData && trackData?.length ?
                          <Box mt={3} sx={{ maxHeight: 400, overflow: 'auto', width: "100%" }}>
                            <DataTable
                              tableColumns={columns}
                              data={trackData || []}
                              themeType={themeType}
                            />
                          </Box>
                          : null}
                      </CardContent>
                    </Card>
                    :
                    <Grid
                      container
                      item
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems="center"
                      xs={12}
                      sx={{ p: 15 }}
                    >
                      <Grid item>
                        <img src={loaderGif} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}


      {viaModal && (
        <ViaPointsModal
          open={viaModal}
          hide={() => {
            setViaModal(false);
          }}
          viaData={viaData}
        />
      )}
    </>
  );
};

export default TripSummary;
