import { Paper, TablePagination } from "@mui/material";
import React from "react";

const HealthTable = ({
  testId,
  tableColumns = [],
  data,
  totalRecords,
  pagination,
  setPagination,
  themeType,
  handlePaginationChange,
}) => {

  const {
    textClasses,
    tableClasses,
  } = themeType;


  return (
    <Paper
      data-testid={testId}
      className="muitable"
      sx={{
        maxWidth: "100%",
        overflowX: "auto",
      }}
    >
      <table style={{ ...tableClasses.table }}>
        <thead style={{ backgroundColor: themeType?.default?.themeGray }}>
          <tr className="roundedRow">
            {tableColumns?.map((column, index) => {
              return (
                <td
                  key={index}
                  // style={{
                  //   ...tableClasses.tableHeader,
                  //   ...textClasses.boldText,
                  //   textAlign: "center",
                  // }}
                  style={{ ...textClasses.boldText, ...tableClasses.tableHeaderClass, textAlign: "center" }}
                >
                  {column?.header}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((entry, key) => (
                <tr data-testid='tablerow' key={key} style={{ borderBottom: "1px solid #0000002B" }}>
                  {tableColumns?.map((column, index) => {
                    return (<td
                      data-testid='tablecell'
                      key={index}
                      // style={{
                      //   textAlign: "left",
                      //   ...textClasses.normalText,
                      //   ...textClasses.boldText, ...tableClasses.tableHeader,
                      // }}
                      style={{ textAlign: "center", ...textClasses.normalText, ...tableClasses.tableBodyClass, textAlign: "center" }}
                    >
                      {column?.header === "#" || column?.header === "Actions"
                        ? column?.cell(key) : column?.selector === "checkbox" ? column?.cell(key, entry)
                          : column?.cell(entry)}
                    </td>)
                  }
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  ...tableClasses.dark,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div>
        <TablePagination
          data-testid="tablePagination"
          component="div"
          sx={{
            ...tableClasses.tableHeader,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: '0'
            },
            "& .MuiTablePagination-displayedRows": {
              margin: '0'
            }
          }}
          count={totalRecords || 0}
          page={(pagination.page || 1) - 1}
          onPageChange={(e, newPage) => {
            setPagination({ ...pagination, "page": newPage + 1 })
            handlePaginationChange({ ...pagination, "page": newPage + 1 })
          }}
          rowsPerPage={pagination.limit}
          onRowsPerPageChange={(e) => {
            console.log('limit', e.target.value)
            setPagination({ ...pagination, "page": 1, "limit": e.target.value })
            handlePaginationChange({ ...pagination, "page": 1, "limit": e.target.value })
          }}
        />
      </div>
    </Paper>
  );
};

export default HealthTable;
