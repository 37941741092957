import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { convertIntoGivenTimezone } from "../../../../services/functions";
import PropTypes from 'prop-types';

export const TableColumns = ({ filter, selectedLog }) => {

    const { currentUserDetail } = useSelector((state) => state.auth);
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../../static/styles/darktheme")
        : require("../../../../static/styles/theme");
    const { textClasses } = themeType;
    let column = []

    const EmailRenderer = ({ emails }) => {
        // Split the emails into an array
        if(emails){
            const emailArray = emails.split(',');

            return (
                emailArray.length > 0 ? emailArray.map((ele) => {
                    return <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {ele}
                    </Typography>
                }) : <Typography
                    sx={{
                        ...textClasses.t13n,
                        textAlign: "center",
                    }}
                >
                    {emails}
                </Typography>
            );

        }
       
    };

    if (selectedLog === 'Alert Sent Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Alert Type",
                header: "Alert Type",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                            whiteSpace: 'wrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: "200px",
                            justifyContent: "center"
                        }}
                    >
                        {entry?.subject ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Trip ID",
                header: "Trip ID",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.tripId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device ID/Vehicle No",
                header: "Device ID/Vehicle No",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.client_id ?? "-"}{entry?.client_id && entry?.vehicleNo ? " / " : ""}{entry?.vehicleNo ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.userName ? `${entry?.userName}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>

                ),
            },
            {
                header: "Packet Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.createdTime, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Email",
                cell: (entry) => {
                    return (
                        <EmailRenderer emails={entry?.email} />
                        // <Typography
                        //     sx={{
                        //         ...textClasses.t13n,
                        //         textAlign: "center",
                        //         textTransform: "capitalize",
                        //         // color:
                        //         //     entry?.isApproved == true
                        //         //         ? themeType?.default.themeGreen
                        //         //         : themeType?.default.themeRed,
                        //     }}
                        // >
                        //     {/* {entry?.email ?? "-"} */}
                        //     {entry?.email.split(',').map((emails, index) => (
                        //         <React.Fragment key={index}>
                        //             {emails.trim()} &nbsp;
                        //         </React.Fragment>
                        //     ))}
                        // </Typography>
                    );
                },
            },
            {
                header: "Mobile",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.mobile ?? "-"}
                        </Typography>
                    );
                },
            },
        ];
    } else if (selectedLog === 'Web Lock/Unlock Request Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Trip ID",
                header: "Trip ID",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                        }}
                    >
                        {entry?.tripId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Name",
                header: "Name",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.name ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "IP",
                header: "IP",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.ip ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.userName ? `${entry?.userName}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>
                ),
            },
            {
                header: "Device ID",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.client_id ? entry?.client_id : "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Request Type",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry.type ? entry.type : "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Mode",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.mode || entry?.source ? `${entry?.mode} (${entry?.source})` : "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.date, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Request Status",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.requestStatus ?? "-"}
                        </Typography>
                    );
                },
            },
        ];

    } else if (selectedLog === 'Bluetooth Lock/Unlock Request Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Device Id",
                header: "Device Id",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                        }}
                    >
                        {entry?.client_id ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Trip ID",
                header: "Trip ID",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.tripId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.userName ? `${entry?.userName}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>
                ),
            },
            {
                header: "Name",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.name ?? "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Request Type",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.userRequestType ?? "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.date, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            },
        ];
    } else if (selectedLog === 'Rfid Lock/Unlock Request Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Device Id",
                header: "Device Id",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                        }}
                    >
                        {entry?.client_id ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Trip ID",
                header: "Trip ID",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.tripId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.userName ? `${entry?.userName}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>
                ),
            },
            {
                key: "Request Type",
                header: "Request Type",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.userRequestType ?? "-"}
                    </Typography>
                ),
            },
            {
                header: "RFID Card Number",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {entry?.rfid ?? "-"}
                        </Typography>
                    );
                },
            },
            {
                header: "Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.date, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            }
        ];
    } else if (selectedLog === 'Passcode Lock/Unlock Request Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Device Id",
                header: "Device Id",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                        }}
                    >
                        {entry?.deviceId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Trip ID",
                header: "Trip ID",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.tripId ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.userName ? `${entry?.userName}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>
                ),
            },
            {
                key: "Request Type",
                header: "Request Type",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.userRequestType ?? "-"}
                    </Typography>
                ),
            },
            {
                header: "Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.date, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            }

        ];
    } else if (selectedLog === 'Passcode View Logs') {
        column = [
            {
                key: "Sno",
                header: "#",
                cell: (key) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                        }}
                    >
                        {(filter.page - 1) * filter.limit + key + 1}
                    </Typography>
                ),
            },
            {
                key: "Device Id",
                header: "Device Id",
                cell: (entry) => (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            color: themeType.default.themeOrange,
                            cursor: "pointer",
                        }}
                    >
                        {entry?.client_id ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "User Name",
                header: "User Name",
                cell: (entry) => (
                    <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                        {entry?.userName ?? "-"}
                    </Typography>
                ),
            },
            {
                key: "Device Admin",
                header: "Device Admin",
                cell: (entry) => (
                    <>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.deviceAdmin ? `${entry?.deviceAdmin}` : null}
                        </Typography>
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                            }}
                        >
                            {entry?.orgName ? entry?.orgName : "-"}
                        </Typography>
                    </>
                ),
            },
            {
                header: "Time",
                cell: (entry) => {
                    return (
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                // color:
                                //     entry?.isApproved == true
                                //         ? themeType?.default.themeGreen
                                //         : themeType?.default.themeRed,
                            }}
                        >
                            {convertIntoGivenTimezone(entry?.createdAt, currentUserDetail.timeZone.offset) ?? "-"}
                        </Typography>
                    );
                },
            }
        ];
    } else {
        column = [];
    }

    return column;
};
