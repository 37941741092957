import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import DrawerNavigate from "../../views/Components/Drawer/Drawer";
import DashboardHome from "../../views/Modules/Dashboard";
import UserRole from "../../views/Modules/Users/UserRole";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Organisation from "../../views/Modules/Users/Organisation";
import Users from "../../views/Modules/Users/UsersIndex";
import { tableClasses } from "../../static/styles/theme";
import Devices from "../../views/Modules/Devices/DevicesIndex";
import DeviceModels from "../../views/Modules/Devices/DeviceModels";
import Geofence from "../../views/Modules/Geofence/GeofenceIndex";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../../static/css/util.css";
import AddDeviceIndex from "../../views/Modules/Devices/AddDeviceIndex";
import Home from "../../views/Modules/Templates";
import TripDashboard from "../../views/Modules/Trips/TripDashboard";
import ViewTemplate from "../../views/Modules/Templates/ViewTemplate";
import EditTemplate from "../../views/Modules/Templates/EditTemplate";
import CreateTemplate from "../../views/Modules/Templates/CreateTemplate";
import AddUser from "../../views/Modules/Users/components/AddUser";
import { userDetails } from "../../redux/actions";
import CreateViewGeo from "../../views/Modules/Geofence/CreateViewGeofence";
import DynamicBoard from "../../views/Modules/Board/DynamicBoard";
import BoardCreation from "../../views/Modules/Board/BoardCreation";
import RouteIndex from "../../views/Modules/Routes/index";
import AssetManagement from "../../views/Modules/AssetManagement";
import AddRoute from "../../views/Modules/Routes/components/AddRoute";
import ViewAllGeofence from "../../views/Modules/Geofence/ViewAllGeofence";
import CreateTrip from "../../views/Modules/Trips/CreateTrip";
import TripSettings from "../../views/Modules/Settings/TripSettings";
import AlertSettings from "../../views/Modules/Settings/AlertSettings";
import DeviceMap from "../../views/Modules/Devices/DeviceMap";
import Unauthorized from "../../views/Components/Utils/Unauthorized";
import ProtectedRoute from "../../routes/ProtectedRoute";
import MonitoringDashboard from "../../views/Modules/Monitoring";
import TripMap from "../../views/Modules/Trips/TripMap";
import TripSummary from "../../views/Modules/Trips/TripSummary";
import AccountLinkage from "../../views/Modules/Settings/AccountLinkage";
import AddAccount from "../../views/Modules/Settings/AccountLinkage/AddAccount";
import {
  checkSettingsPermissions,
  havePermission,
} from "../../utils/permissionCheck";
import Area_mapping from "../../views/Modules/AreaMapping";
import BulkCreateTrip from "../../views/Modules/Trips/BulkCreateTrip";
import Logs from "../../views/Modules/Logs";
import HealthLogs from "../../views/Modules/Health";
import Signup from "../../views/Landing/Signup";
import SharingTrackMap from "../../views/Modules/SharingTrackMap/SharingTrackMap";
import CameraPlayback from "../../views/Modules/Devices/CameraPlayback";
import CameraMap from "../../views/Modules/Devices/CameraMap";
import BulkUploadGeofence from "../../views/Modules/Geofence/BulkUploadGeofence";
import VehicleMasterIndex from "../../views/Modules/VehicleMaster";
import DriverMasterIndex from "../../views/Modules/DriverMaster";
import FastTagPlotting from "../../views/Modules/FastTagPlot";
import history from "../../services/history";
import ReactGA from "react-ga4";
import { getPageTitle } from "../../utils/util";
import HealthSettings from "../../views/Modules/Settings/HealthSettings";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: window.matchMedia("(max-width: 899px)").matches ? "10px" : "65px",
  // minHeight: window.matchMedia("(max-width: 899px)").matches ? "10px" : "65px",
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

function App(props) {
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const [previousRoute, setPreviousRoute] = useState(null);

  const { currentUserDetail } = useSelector((state) => state.auth);
  const currentRole =
    currentUserDetail && currentUserDetail?.adminSpecificPermissions
      ? "superadmin"
      : "others";
  // const userId = useSelector((state) => state.auth.userId);
  // const { userDetail } = useSelector((state) => state.userlist);
  const themeType = isDarkThemeEnabledSelector
    ? require("../../static/styles/darktheme")
    : require("../../static/styles/theme");

  const { tableClasses } = themeType;

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      const pageTitle = getPageTitle(newLocation.pathname);
      ReactGA.send({ hitType: 'pageview', page: newLocation.pathname, title: pageTitle });
      if (action === 'PUSH') {
        setPreviousRoute(newLocation)
      }
      
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const isSuperAdmin = localStorage.getItem("isSuperAdmin");


  return (
    <>
      <Box sx={{ display: "flex", minHeight: "98vh" }}>
        <DrawerNavigate {...props} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pl: 1.5,
            pr: 1.5,
            pt: 1,
            background: tableClasses.dark.backgroundColor,
            width: 0,
            overflow: "hidden",
          }}
        >
          {/* <DrawerHeader /> */}
          <div className="drawer-header-container" />
         
          <Switch>
            <Route
              path="/dashboard/unauthorized"
              render={() => <Unauthorized />}
            />

            <ProtectedRoute
              exact
              path="/dashboard/asset-management"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "assets", "viewAssetMgmt")
              }
              component={<AssetManagement />}
            />
            {/* <Route
              path="/dashboard/asset-management"
              render={() => <AssetManagement />}
            /> */}

            <ProtectedRoute
              exact
              path="/dashboard/monitoring"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "monitoring",
                  "viewMonitoring"
                )
              }
              component={<MonitoringDashboard />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/logs"
              havePermission={
                currentUserDetail &&
                (havePermission(
                  currentUserDetail,
                  "unlockingLogs",
                  "viewBtUnlockingLogs"
                ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewPasscodeLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewPasscodeUnlockingLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewRfidUnlockingLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewSmsLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewSmsUnlockingLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "unlockingLogs",
                    "viewWebUnlockingLogs"
                  ) ||
                  havePermission(
                    currentUserDetail,
                    "alertLogs",
                    "viewAlertLogs"
                  ))
              }
              component={<Logs />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/health"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "healthDashboard",
                  "viewHealthDashboard"
                )
              }
              component={<HealthLogs />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/area-mapping"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "areaMapping",
                  "viewAreaMapping"
                )
              }
              component={<Area_mapping />}
            />
            {/* <Route
              path="/dashboard/area-mapping"
              render={() => <Area_mapping />}
            /> */}

            {/*  ---- TEMPLATE ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/manage-templates"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "dynamicTemplates",
                  "viewTemplate"
                )
              }
              component={<Home />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/templates/create"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "dynamicTemplates",
                  "addTemplate"
                )
              }
              component={<CreateTemplate {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/templates/view/:templateId"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "dynamicTemplates",
                  "viewTemplate"
                )
              }
              component={<ViewTemplate />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/templates/edit/:templateId"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "dynamicTemplates",
                  "editTemplate"
                )
              }
              component={<EditTemplate {...props} />}
            />

            {/* ---- DYNAMIC DASHBOARD ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/board"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "dynamicBoard", "viewBoard")
              }
              component={<DynamicBoard {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/board/create"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "dynamicBoard", "addBoard")
              }
              component={<BoardCreation {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/board/edit/:id"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "dynamicBoard", "editBoard")
              }
              component={<BoardCreation {...props} />}
            />

            {/* <Route
              exact
              path="/dashboard"
              render={() => <DashboardHome {...props} />}
            /> */}

            {/* -----ORGANIZATION & USERS----- */}
            <ProtectedRoute
              exact
              path="/dashboard/users/organisation"
              allowedRoles={["superadmin"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "organization",
                  "viewOrganization"
                )
              }
              component={<Organisation {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users"
              // allowedRoles={["superadmin", "others"]}
              // userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "user", "viewUser")
              }
              component={<Users {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users/my-team"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "user", "viewUser")
              }
              component={<Users {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users/user-role"
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "userRole",
                  "viewPermissionsRole"
                )
              }
              component={<UserRole />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users/edit-user/:id"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "user", "updateUser")
              }
              component={<AddUser {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users/add-user/"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "user", "addUser")
              }
              component={<AddUser {...props} />}
            />




            {/* -----DEVICES----- */}
            <ProtectedRoute
              exact
              path="/dashboard/devices/device-models"
              allowedRoles={["superadmin"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(
                  currentUserDetail,
                  "device",
                  "viewDeviceCategory"
                )
              }
              component={<DeviceModels {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/devices/add-device"
              allowedRoles={["superadmin", "others"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "device", "addDevice")
              }
              component={<AddDeviceIndex {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/devices/edit-device/:id"
              allowedRoles={["superadmin", "others"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "device", "updateDevice")
              }
              component={<AddDeviceIndex {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/devices"
              allowedRoles={["superadmin", "others"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "device", "viewDevice")
              }
              component={<Devices previousRoute={previousRoute} {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/devices/all-devices"
              allowedRoles={["superadmin", "others"]}
              userRole={currentRole}
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "device", "viewDevice")
              }
              component={<Devices previousRoute={previousRoute} {...props} />}
            />
            <Route
              exact
              path="/dashboard/devices/map/:id"
              render={() => <DeviceMap {...props} />}
            />
            <Route
              exact
              path="/dashboard/devices/camera/:id"
              render={() => <CameraMap {...props} />}
            />
            <Route
              exact
              path="/dashboard/camera/:id"
              render={() => <CameraPlayback {...props} />}
            />

            {/* ---- ROUTES ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/routes"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "routes", "viewRoutes")
              }
              component={<RouteIndex previousRoute={previousRoute} {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/routes/add-route"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "routes", "createRoutes")
              }
              component={<AddRoute {...props} />}
            />
            <ProtectedRoute
              exact
              // path="/dashboard/routes/edit-route/:id"
              path="/dashboard/routes/edit-route"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "routes", "editRoutes")
              }
              component={<AddRoute {...props} />}
            />

            {/* ---- GEOFENCE ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/geofence"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "viewGeofence")
              }
              component={<Geofence previousRoute={previousRoute} {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/geofence/view-all-geofence"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "viewGeofence")
              }
              component={<ViewAllGeofence {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/geomap/create-geo"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<CreateViewGeo {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/geomap/create-bulk-geo"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<BulkUploadGeofence {...props} />}
            />
            {/* <ProtectedRoute
              exact
              path="/dashboard/geomap/create"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<CreateViewGeo {...props} />}
            /> */}
            <Route
              path="/dashboard/geomap/create"
              render={() => <CreateViewGeo />}
            />

            <ProtectedRoute
              exact
              path="/dashboard/geomap/create-bulk-geo"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<BulkUploadGeofence {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/geomap/edit-geo/:id"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<CreateViewGeo {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/geo/edit/:id"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "geofence", "createGeofence")
              }
              component={<CreateViewGeo {...props} />}
            />

            {/* ---- TRIP ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/trips"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "trip", "viewTrip")
              }
              component={<TripDashboard previousRoute={previousRoute}  />}
            />
            <Route
              exact
              path="/dashboard/trips/trip-map/:tripId"
              render={() => <TripMap {...props} />}
            />
            <Route
              exact
              path="/dashboard/vehicle-master"
              render={() => <VehicleMasterIndex {...props} />}
            />
            <Route
              exact
              path="/dashboard/vehicle-detail/:vehicleNo"
              render={() => <FastTagPlotting {...props} />}
            />
            <Route
              exact
              path="/dashboard/driver-master"
              render={() => <DriverMasterIndex {...props} />}
            />



            <Route
              exact
              path="/dashboard/trips/trip-summary/:id"
              render={() => <TripSummary {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/trips/create"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "trip", "createTrip")
              }
              component={<CreateTrip {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/trips/bulk-create"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "trip", "bulkUploadTrip")
              }
              component={<BulkCreateTrip {...props} />}
            />
            <Route
              exact
              path="/dashboard/trips/edit/:id"
              render={() => <CreateTrip {...props} />}
            />

            {/* ---- SHARING TRACK (DEVICE/TRIP)
            <ProtectedRoute
              exact
              path="/sharingTrack"
              havePermission={
                currentUserDetail &&
                (havePermission(
                  currentUserDetail,
                  "trip",
                  "allowSharingTripTrackingLink"
                ) ||
                  havePermission(
                    currentUserDetail,
                    "device",
                    "allowSharingDeviceTrackingLink"
                  ))
              }
              component={<SharingTrackMap {...props} />}
            /> */}

            <Route
              path="/sharingTrack"
              render={() => <Redirect to="/" />}
            />


            {/* ---- SETTINGS ---- */}
            <ProtectedRoute
              exact
              path="/dashboard/settings/trips"
              havePermission={
                currentUserDetail &&
                checkSettingsPermissions(currentUserDetail, "tripSettings")
              }
              component={<TripSettings {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/settings/alerts"
              havePermission={
                currentUserDetail &&
                checkSettingsPermissions(currentUserDetail, "alertSettings")
              }
              component={<AlertSettings {...props} />}
            />
             <ProtectedRoute
              exact
              path="/dashboard/settings/health-report"
              havePermission={
                currentUserDetail &&
                checkSettingsPermissions(currentUserDetail, "alertSettings")
              }
              component={<HealthSettings {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/settings/account-linkage"
              havePermission={
                currentUserDetail &&
                checkSettingsPermissions(
                  currentUserDetail,
                  "accountLinkageSettings"
                )
              }
              component={<AccountLinkage {...props} />}
            />
            <ProtectedRoute
              exact
              path="/dashboard/settings/account-linkage/add"
              havePermission={
                currentUserDetail &&
                havePermission(currentUserDetail, "settings", "viewSettings")
              }
              component={<AddAccount {...props} />}
            />

            {/* <ProtectedRoute
              exact
              path="/dashboard/area-mapping"
              allowedRoles={["superadmin"]}
              userRole={currentRole}
              component={<Area_mapping {...props} />}
            /> */}

            {!localStorage.getItem('sidebar') &&
              <Redirect
                to={
                  havePermission(
                    currentUserDetail,
                    "monitoring",
                    "viewMonitoring"
                  )
                    ? "/dashboard/monitoring"
                    : "/dashboard/devices/all-devices"
                }
              />
            }
          </Switch>
          <ToastContainer
            position="bottom-center"
            autoClose={false}
            closeOnClick={false}
          />
        </Box>
      </Box>
    </>
  );
}

export default App;
