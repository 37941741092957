import { Visibility } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export const InputField = ({
  name,
  required = false,
  value,
  onChange,
  errors = {},
  placeholder,
  label = "",
  disabled,
  inputProps,
  inputClasses,
  multiline,
  rows,
  defaultValue,
  type,
  width = "100%",
  maxWidth,
  minValue,
  maxValue,
  helperText,
  selectStylesOverride,
  extraStyles = null,
  ...props
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width}
      maxWidth={maxWidth}
    >
      <FormControl sx={{ width: "100%" }}>
        {/* <InputLabel sx={{ fontSize: "12px" }}>{label}{required && "*"}</InputLabel> */}
        {/* <TextField
          size="small"
          role="textbox"
          value={value}
          onChange={onChange}
          multiline={multiline}
          rows={multiline ? 1 : rows}
          helperText={helperText}
          defaultValue={defaultValue}
          label={
            label || placeholder ? (
              <Typography sx={{ fontSize: "12px",}}>
                {label ? label : placeholder}
                {required && "*"}
              </Typography>
            ) : null
          }
          sx={{
            // ...inputClasses?.lightertextField,
            // ...selectStylesOverride,
            // "&Mui-focused": {
            //   transform: "translate(14px, -3px) scale(0.75)",
            // },
            fieldset:{borderColor: "#777"},
            label:{color: "#777"},
            height: "36px",
            "& .hover":{
              fieldset: {
                borderColor: "#777"
              }
            }
          }}
          inputProps={{
            ...inputProps,
            style: {
              padding: "8.5px 11px",
              ...extraStyles,
            },
            min: minValue,
            max: maxValue,
            step: 1,
          }}
          type={type}
          disabled={disabled}
          error={errors[name] ? true : false}
          placeholder={placeholder}
          {...props}
        /> */}
        <TextField
          value={value}
          onChange={onChange}
          helperText={helperText}
          defaultValue={defaultValue}
          label={
            label || placeholder ? (
              <Typography sx={{ fontSize: "12px",}}>
                {label ? label : placeholder}
                {required && "*"}
              </Typography>
            ) : null
          }
          sx={{
            fieldset:{borderColor: "#777"},
            label:{color: "#777"},
          }}
          type={type}
          disabled={disabled}
          error={errors[name] ? true : false}
          placeholder={placeholder}
          {...props}
        />
      </FormControl>
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};
