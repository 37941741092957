import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import NetworkAdminsUpdate from "./NetworkAdminsUpdate";
import { Cancel } from "@mui/icons-material";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { showToast } from "../../../../services/functions";

const AdminsSection = ({ isEdit, allProps }) => {
  const {
    errors,
    control,
    showAll,
    reset,
    setShowAll,
    deviceDetailsTemplate,
    loading,
    orgList,
    setNetworkAdminAll,
    networkAdminAll,
    handleSearchAdminOrg,
    handleSearchNetworkAdminOrg,
    handleSearchAdmins,
    handleSearchNetworkAdmins,
    editableNAPermissions,
    networkAdminsDropdownVal, // all network admins (array of objects)
    themeType,
    selectStylesOverride,
    setValue,
    searchFields,
    setSearchFields,
    userList,
    userRoleOptions,
    adminSelected,
    networkAdminSelected, // selected network admins (array of ids)
    adminOrgUser,
    networkAdminOrgUser,
    openUpdateNetworkAdmins,
    alldevicePermissions,
    adminPermissions,
    showResetSearchNA,
    setShowResetSearchNA,
    allPermissionRoles,
    handleFetchUserRoles,
    handleFetchMultipleUserRoles,
    handleFetchUsersForMultipleNA,
    networkAdminPermissions,
    organizationListing,
    handleFetchAndCatchUsers,
    handleCheckboxChange,
    handleMultipleNaCheckboxChange,
    handleSelectAllPermission,
    handleMultipleSelectAllPermission,
    multipleNetworkAdmins,
    setMultipleNetworkAdmins,
    handleSearchOrgMultipleNa,
    handleSearchUsersMultipleNa,
  } = allProps;

  const { buttonClasses, textClasses } = themeType;
  const [search, setSearch] = React.useState("");

  const checkNetworkAdminSelectAll = React.useMemo(() => {
    return Object.values(networkAdminPermissions).every(
      (subObject) => subObject.value === true
    )
      ? true
      : false;
  }, [networkAdminPermissions]);

  const checkAdminSelectAll = React.useMemo(() => {
    return Object.values(adminPermissions).every(
      (subObject) => subObject.value === true
    )
      ? true
      : false;
  }, [adminPermissions]);

  //TODO: use it like alerts --- objects as values

  const handleRemoveSelection = React.useCallback(
    (st) => {
      const updatedNASelected = networkAdminSelected?.filter((id) => {
        return id !== st;
      });
      const updatedNA = networkAdminsDropdownVal?.filter((_) => {
        return _?._id !== st;
      });
      setValue("networkAdmins", updatedNA);
      setValue("networkAdminSelected", updatedNASelected);
    },
    [networkAdminsDropdownVal, networkAdminSelected, setValue]
  );

  const handleRemoveSelectionMultiple = (st, idx) => {
    setMultipleNetworkAdmins((prev) => {
      const existing = [...prev];
      const objectToUpdate = existing[idx];

      const updatedNa = objectToUpdate?.networkAdmins?.filter((_) => {
        return _?._id !== st;
      });

      objectToUpdate.networkAdmins = updatedNa;
      existing[idx] = objectToUpdate;

      return existing;
    });
  };

  const getFilteredNetworkAdmins = () => {
    return userList?.networkAdmins?.filter((_) => {
      const isAdminSelected = adminSelected !== _?._id;
      const isNotInExisting = isEdit
        ? !editableNAPermissions?.some((item) => item?.id === _?._id)
        : true;

      // Return true if both conditions are met
      return isAdminSelected && isNotInExisting;
    });
  };

  const getMultipleFilteredNetworkAdmins = (idx) => {
    return multipleNetworkAdmins[idx]?.networkAdminList?.filter((_) => {
      const isAdminSelected = adminSelected !== _?._id;
      const isNotInExisting = isEdit
        ? !editableNAPermissions?.some((item) => item?.id === _?._id)
        : true;

      // Return true if both conditions are met
      return isAdminSelected && isNotInExisting;
    });
  };

  const handleToggleSelectAlladmins = (e) => {
    const filteredNA = getFilteredNetworkAdmins();
    const filteredIds = filteredNA?.map((_) => _?._id);
    if (filteredNA?.length > 0) {
      if (e?.target?.checked) {
        const existingNetworkAdmins = networkAdminsDropdownVal || [];
        const existingSelectedNetworkAdmins = networkAdminSelected || [];

        const newNetworkAdmins = [
          ...existingNetworkAdmins,
          ...filteredNA,
        ]?.filter(
          (ele, ind) =>
            ind ===
            [...existingNetworkAdmins, ...filteredNA]?.findIndex(
              (elem) => elem?._id === ele?._id && elem?.name === ele?.name
            )
        );
        const newSelectedNetworkAdmins = [
          ...new Set([...existingSelectedNetworkAdmins, ...filteredIds]),
        ];

        setValue("networkAdmins", newNetworkAdmins);
        setValue("networkAdminSelected", newSelectedNetworkAdmins);
      } else {
        setValue("networkAdmins", []);
        setValue("networkAdminSelected", []);
      }
    }
  };

  const handleToggleSelectAllMultipleAdmins = (e, idx) => {
    const filteredNA = getMultipleFilteredNetworkAdmins(idx);
    if (filteredNA?.length > 0) {
      if (e?.target?.checked) {
        const tempNetworkAdmins = multipleNetworkAdmins;
        const existingNetworkAdmins =
          tempNetworkAdmins[idx]?.networkAdmins || [];

        const newNetworkAdmins = [
          ...existingNetworkAdmins,
          ...filteredNA,
        ]?.filter(
          (ele, ind) =>
            ind ===
            [...existingNetworkAdmins, ...filteredNA]?.findIndex(
              (elem) => elem?._id === ele?._id && elem?.name === ele?.name
            )
        );

        tempNetworkAdmins[idx].networkAdmins = newNetworkAdmins;
        tempNetworkAdmins[idx].networkAdminAllFromOrg = false;
        setMultipleNetworkAdmins([...tempNetworkAdmins]);
      } else {
        const tempNetworkAdmins = multipleNetworkAdmins;
        tempNetworkAdmins[idx].networkAdmins = [];
        tempNetworkAdmins[idx].networkAdminAllFromOrg = false;
        setMultipleNetworkAdmins([...tempNetworkAdmins]);
      }
    }
  };

  const handleInputChange = (e, newVal, reason) => {
    setSearchFields((prev) => ({
      ...prev,
      networkAdmins: {
        orgName: networkAdminOrgUser[0] ?? undefined,
        searchValue: e?.target?.value?.trim(),
        userRole: networkAdminOrgUser[1] ?? undefined,
      },
    }));
  };

  const handleInputChangeMultiple = (e, idx) => {
    setMultipleNetworkAdmins((prev) => {
      const existing = [...prev];
      const objectToUpdate = existing[idx];

      objectToUpdate.networkAdminSearchVal = e?.target?.value?.trim();
      existing[idx] = objectToUpdate;

      return existing;
    });
  };

  // console.log("multipleNetworkAdmins: ", multipleNetworkAdmins);

  return (
    <>
      {/* Select Admin ------------- */}
      <Grid item container spacing={2} mb={0} mt={2}>
        <Grid item md={12}>
          <Typography>Select Admin</Typography>
        </Grid>
        {/* org, role and user list container grid------------- */}
        <Grid item container spacing={2}>
          <Grid item md={6}>
            <Controller
              name="adminOrganization"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    //disabled={true}
                    selectStylesOverride={selectStylesOverride}
                    label="Select Organization"
                    value={value}
                    searchValue={
                      searchFields?.adminOrg && searchFields?.adminOrg !== ""
                        ? searchFields?.adminOrg
                        : null
                    }
                    onSearch={() => {
                      handleSearchAdminOrg();
                    }}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "") handleSearchAdminOrg(true);
                        setSearchFields((prev) => ({
                          ...prev,
                          adminOrg: e?.target?.value?.trim(),
                        }));
                      }
                    }}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") handleSearchAdminOrg(true);
                      onChange(newVal?.name);
                      setValue("adminOrganization", newVal?.name);
                      handleFetchAndCatchUsers(
                        {
                          orgId: newVal?._id,
                          //userType: adminOrgUser[1] ?? undefined,
                        },
                        "admin"
                      );
                    }}
                    name={name}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return (
                          orgList?.admins?.find((_) => _?.name == value)
                            ?.name || ""
                        );
                      } else {
                        return option?.name || "";
                      }
                    }}
                    labelKey="name"
                    options={orgList?.admins && orgList?.admins}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="admin"
              control={control}
              // rules={{
              //   required: {
              //     value: true,
              //     message: "Admin is required.",
              //   },
              // }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride}
                    loading={loading}
                    loadingText="Loading..."
                    value={value || null}
                    defaultValue={null}
                    // clearOnBlur={false}
                    filterOptions={(options) => options}
                    searchValue={
                      searchFields?.admin?.searchValue &&
                      searchFields?.admin?.searchValue !== ""
                        ? searchFields?.admin?.searchValue
                        : null
                    }
                    renderOptions={(props, option, { selected }) => {
                      return (
                        <MenuItem
                          value={value || ""}
                          selected={selected}
                          {...props}
                        >
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {`${option?.name} - ${option?.orgName}` || ""}
                          </Typography>
                        </MenuItem>
                      );
                    }}
                    onSearch={() => {
                      handleSearchAdmins();
                    }}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "") handleSearchAdmins(true);
                        setSearchFields((prev) => ({
                          ...prev,
                          admin: {
                            orgName: adminOrgUser[0] ?? undefined,
                            searchValue: e?.target?.value?.trim(),
                          },
                        }));
                      }
                    }}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") handleSearchAdmins(true);
                      onChange(newVal?._id);
                      setValue("adminSelected", newVal?._id);
                    }}
                    name={name}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        const user = userList?.admins?.find(
                          (_) => _?._id == value
                        );
                        return `${user?.name || ""} - ${user?.orgName || ""}`;
                      } else {
                        return `${option?.name} - ${option?.orgName}` || "";
                      }
                    }}
                    label="Select User"
                    labelKey="name"
                    options={userList?.admins && userList?.admins}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          borderRadius={1}
          border="1px solid #E0E0E0"
          my={2}
          ml={2}
          p={2}
          sx={{ width: "100%" }}
        >
          {/* select all--- */}
          <Grid item xs={12}>
            <FormControlLabel
              sx={{
                color: "rgb(128, 128, 128)",
                "& .MuiFormControlLabel-label": { fontSize: "13px" },
              }}
              label="Select All"
              control={
                <Checkbox
                  data-testid="admin-checkbox"
                  sx={{
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    },
                  }}
                  disabled={!adminSelected}
                  checked={checkAdminSelectAll}
                  onChange={(e) => {
                    handleSelectAllPermission(e, "admin");
                  }}
                  //name={key}
                />
              }
            />
          </Grid>
          {Object.keys(adminPermissions)?.map((key, i) => {
            return (
              <Grid item md={3} key={i}>
                <FormControlLabel
                  sx={{
                    color: "rgb(128, 128, 128)",
                    "& .MuiFormControlLabel-label": { fontSize: "13px" },
                  }}
                  label={adminPermissions[key]?.label}
                  control={
                    <Checkbox
                      data-testid="admin-checkbox"
                      sx={{
                        color: "#ff7200",
                        "&.Mui-checked": {
                          color: "#ff7200",
                        },
                      }}
                      disabled={!adminSelected}
                      checked={adminPermissions[key]?.value}
                      onChange={(e) => {
                        handleCheckboxChange(e, key, "admin");
                      }}
                      name={key}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      {/* Select Network Admin ------------- */}
      <Grid item container spacing={2} mb={0} mt={0}>
        <Grid
          item
          md={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Select Network Admin</Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitOrangeEmpty,
                minHeight: "36px",
                minWidth: "140px",
              }}
              onClick={() => {
                if (
                  alldevicePermissions &&
                  alldevicePermissions?.deviceSpecificPermission
                ) {
                  const { deviceSpecificPermission } = alldevicePermissions;
                  const { label, ...rest } = deviceSpecificPermission;
                  setMultipleNetworkAdmins((prev) => [
                    ...prev,
                    {
                      id: prev?.length,
                      organizationListing: organizationListing,
                      devicePermissions: rest,
                    },
                  ]);
                }
              }}
            >
              Add More
            </Button>
            {isEdit && (
              <Button
                variant="outlined"
                sx={{
                  ...buttonClasses?.lynkitOrangeEmpty,
                  minHeight: "36px",
                  minWidth: "140px",
                }}
                onClick={openUpdateNetworkAdmins}
              >
                Network Admins
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={4}>
            <Controller
              name="networkAdminOrganization"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride}
                    //disabled={true}
                    label="Select Organization"
                    value={value || null}
                    searchValue={
                      searchFields?.networkAdminOrg &&
                      searchFields?.networkAdminOrg !== ""
                        ? searchFields?.networkAdminOrg
                        : null
                    }
                    onSearch={() => {
                      handleSearchNetworkAdminOrg();
                    }}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "")
                          handleSearchNetworkAdminOrg(true);
                        setSearchFields((prev) => ({
                          ...prev,
                          networkAdminOrg: e?.target?.value?.trim(),
                        }));
                      }
                    }}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") handleSearchNetworkAdminOrg(true);
                      onChange(newVal?._id);
                      setValue("networkAdminOrganization", newVal?._id);
                      handleFetchUserRoles(newVal?._id);
                      handleFetchAndCatchUsers(
                        {
                          orgId: newVal?._id,
                          userRole: networkAdminOrgUser[1] ?? undefined,
                        },
                        "networkAdmin"
                      );
                    }}
                    name={name}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return (
                          orgList?.networkAdmins?.find((_) => _?._id == value)
                            ?.name || ""
                        );
                      } else {
                        return option?.name || "";
                      }
                    }}
                    labelKey="name"
                    options={orgList?.networkAdmins && orgList?.networkAdmins}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="networkAdminUserRole"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    //disabled={true}
                    selectStylesOverride={selectStylesOverride}
                    value={value}
                    onChange={(e, newVal) => {
                      onChange(newVal?._id);
                      setValue("networkAdminUserRole", newVal?._id);
                      handleFetchAndCatchUsers(
                        {
                          orgId: networkAdminOrgUser[0] ?? undefined,
                          userRole: newVal?.name,
                        },
                        "networkAdmin"
                      );
                    }}
                    disabled={!networkAdminOrgUser[0]}
                    name={name}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return allPermissionRoles?.find((_) => _?._id == value)
                          ?.name;
                      } else {
                        return option?.name;
                      }
                      // if (typeof option === "string") {
                      //   return userRoleOptions?.find((_) => _?.value == value)
                      //     ?.label;
                      // } else {
                      //   return option?.label;
                      // }
                    }}
                    labelKey="name"
                    label="Select User Role"
                    options={allPermissionRoles && allPermissionRoles}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={4}>
            <Controller
              name="networkAdmins"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomMultiSelect
                    value={value || []}
                    selectStylesOverride={selectStylesOverride}
                    onChange={(e, newVal) => {
                      let idSet = newVal?.map((_) =>
                        typeof _ !== "string" ? _?._id : _
                      );
                      onChange(newVal);
                      setValue("networkAdminSelected", idSet);
                      setNetworkAdminAll(false);
                    }}
                    name={name}
                    disabled={!adminSelected}
                    label={
                      !adminSelected ? "Select admin first" : "Select User"
                    }
                    labelKey="name"
                    errors={errors}
                    onInputChange={(e, newVal, reason) => {
                      if (reason === "input") {
                        setTimeout(() => {
                          handleInputChange(e, newVal, reason);
                        }, 400);
                      } else if (reason === "reset" || reason === "clear") {
                        // handleSearchNetworkAdmins(true);
                      }
                    }}
                    renderOptions={(props, option, { selected }) => {
                      return (
                        <MenuItem
                          value={value || ""}
                          selected={selected}
                          {...props}
                        >
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {`${option?.name} - ${option?.orgName}` || ""}
                          </Typography>
                        </MenuItem>
                      );
                    }}
                    ExtraCheckbox={() => {
                      return networkAdminOrgUser[0] ? (
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          label={`All Users (${
                            userList?.networkAdminCount ?? 0
                          })`}
                          control={
                            <Checkbox
                              id="select-all-from-org-checkbox"
                              sx={{
                                color: "#ff7200",
                                "&.Mui-checked": {
                                  color: "#ff7200",
                                },
                              }}
                              checked={networkAdminAll}
                              onClick={(e) => {
                                e.preventDefault(); // prevent blur
                                if (e?.target?.checked) {
                                  if (!networkAdminOrgUser[0]) {
                                    showToast(
                                      "Please select organization first",
                                      true
                                    );
                                  } else {
                                    setNetworkAdminAll(true);
                                    onChange([]);
                                    setValue("networkAdminSelected", []);
                                  }
                                } else {
                                  setNetworkAdminAll(false);
                                  onChange([]);
                                  setValue("networkAdminSelected", []);
                                }
                              }}
                            />
                          }
                        />
                      ) : (
                        <></>
                      );
                    }}
                    resetSearch={showResetSearchNA && networkAdminSelected?.filter((_) => _ !== undefined)?.length > 0 ? true : false}
                    searchValue={searchFields?.networkAdmins?.searchValue}
                    onSearch={handleSearchNetworkAdmins}
                    onResetSearch={() => {
                      setTimeout(() => {
                        handleInputChange({target: {value: ""}});
                      }, 400);
                      handleSearchNetworkAdmins(true);
                    }}
                    options={
                      userList?.networkAdmins &&
                      userList?.networkAdmins?.filter((_) => {
                        const isNotSelected = !networkAdminSelected?.includes(
                          _._id
                        );
                        const isNotInExisting = isEdit
                          ? !editableNAPermissions?.some(
                              (item) => item?.id === _?._id
                            )
                          : true;

                        // Return true if both conditions are met
                        return isNotSelected && isNotInExisting;
                      })
                    }
                    allSelected={
                      !networkAdminAll &&
                      networkAdminSelected &&
                      networkAdminSelected?.length ===
                        getFilteredNetworkAdmins()?.length
                    }
                    handleToggleSelectAll={(e) => {
                      setNetworkAdminAll(false);
                      handleToggleSelectAlladmins(e);
                    }}
                    disableCloseOnSelect={true}
                    renderTags={() => null}
                    filterOptions={(options) => options}
                    getOptionDisabled={(option) =>
                      adminSelected && adminSelected === option?._id
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.name === value?.name;
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        const names = userList?.networkAdmins
                          ?.filter((_) => value?.includes(_?._id))
                          ?.map((_) => {
                            return `${_?.name} - ${_?.orgName}`;
                          });
                        return names || [];
                      } else {
                        return `${option?.name} - ${option?.orgName}` || "";
                      }
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container my={0} px={2} spacing={2}>
          {networkAdminSelected
            ?.filter((_) => _ !== undefined)
            ?.slice(
              0,
              showAll
                ? networkAdminSelected?.filter((_) => _ !== undefined)?.length
                : 3
            )
            ?.map((nAdmin, i) => {
              if (nAdmin !== undefined) {
                const updatedNA =
                  networkAdminSelected?.length ===
                    networkAdminsDropdownVal?.length &&
                  networkAdminSelected?.includes(undefined)
                    ? networkAdminsDropdownVal?.slice(
                        editableNAPermissions?.length
                      )
                    : networkAdminsDropdownVal || [];
                return (
                  <Grid item sm={3} xs={6} key={i}>
                    <Tooltip
                      placement="top"
                      arrow
                      title={`${
                        updatedNA && updatedNA?.length
                          ? updatedNA[i]?.name
                          : "-"
                      } - ${
                        updatedNA && updatedNA?.length
                          ? updatedNA[i]?.orgName
                          : "-"
                      }`}
                    >
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                          backgroundColor: themeType.default.themeLightOrange,
                          border: `1px solid ${themeType.default.themeOrange}`,
                          borderRadius: "12px",
                          pl: 1,
                          pr: 1,
                          minHeight: "35px",
                        }}
                      >
                        <Grid item sm={10} xs={8}>
                          <Typography
                            sx={{
                              ...textClasses.normalText,
                              color: themeType.default.themeOrange,
                              textAlign: "start",
                            }}
                          >
                            {updatedNA &&
                            updatedNA?.length &&
                            updatedNA[i]?.name &&
                            updatedNA[i]?.name?.length > 15
                              ? updatedNA[i]?.name.substring(0, 15) + "..."
                              : updatedNA[i]?.name}
                            {/* {st.name && st.name.length > 15
                              ? st.name.substring(0, 15) + "..."
                              : st.name} */}
                          </Typography>
                        </Grid>
                        {handleRemoveSelection && (
                          <Grid
                            item
                            sm={2}
                            xs={4}
                            display="flex"
                            justifyContent="end"
                          >
                            <IconButton
                              sx={{ alignContent: "right", padding: "5px" }}
                              onClick={() => handleRemoveSelection(nAdmin)}
                            >
                              <Cancel
                                sx={{ color: themeType.default.themeOrange }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Tooltip>
                  </Grid>
                );
              }
            })}

          {networkAdminSelected &&
            networkAdminSelected?.filter((_) => _ !== undefined)?.length >
              3 && (
              <>
                <Grid
                  container
                  item
                  sm={3}
                  xs={6}
                  justifyContent="start"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? (
                      <>Collapse</>
                    ) : (
                      <>
                        View All (
                        {
                          networkAdminSelected?.filter((_) => _ !== undefined)
                            ?.length
                        }
                        )
                      </>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          borderRadius={1}
          border="1px solid #E0E0E0"
          my={2}
          ml={2}
          p={2}
          sx={{ width: "100%" }}
        >
          {/* select all--- */}
          <Grid item xs={12}>
            <FormControlLabel
              sx={{
                color: "rgb(128, 128, 128)",
                "& .MuiFormControlLabel-label": { fontSize: "13px" },
              }}
              label="Select All"
              control={
                <Checkbox
                  data-testid="network-checkbox"
                  sx={{
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    },
                  }}
                  disabled={
                    (!networkAdminSelected ||
                      networkAdminSelected?.length < 1) &&
                    networkAdminAll !== true
                  }
                  checked={checkNetworkAdminSelectAll}
                  onChange={(e) => {
                    handleSelectAllPermission(e, "networkAdmins");
                  }}
                />
              }
            />
          </Grid>
          {Object.keys(networkAdminPermissions)?.map((key, i) => {
            return (
              <Grid item md={3} key={i}>
                <FormControlLabel
                  sx={{
                    color: "rgb(128, 128, 128)",
                    "& .MuiFormControlLabel-label": { fontSize: "13px" },
                  }}
                  label={networkAdminPermissions[key]?.label}
                  control={
                    <Checkbox
                      data-testid="network-checkbox"
                      sx={{
                        color: "#ff7200",
                        "&.Mui-checked": {
                          color: "#ff7200",
                        },
                      }}
                      disabled={
                        (!networkAdminSelected ||
                          networkAdminSelected?.length < 1) &&
                        networkAdminAll !== true
                      }
                      checked={networkAdminPermissions[key]?.value}
                      onChange={(e) => {
                        handleCheckboxChange(e, key, "networkAdmins");
                      }}
                      name={key}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>

        {/* --- MULTIPLE NETWORK ADMINS --- */}
        {multipleNetworkAdmins &&
          multipleNetworkAdmins?.length > 0 &&
          multipleNetworkAdmins?.map((networkAdmin, idx) => (
            <Grid
              item
              container
              spacing={2}
              mb={0}
              mt={0}
              key={idx}
              sx={{ paddingTop: "0px !important" }}
            >
              <Box
                ml={2}
                width="100%"
                height="1px"
                sx={{ background: (theme) => theme.palette.divider }}
              />
              <Grid item container spacing={2}>
                <Grid item md={4}>
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride}
                    label="Select Organization"
                    value={networkAdmin?.networkAdminOrganization || null}
                    searchValue={
                      networkAdmin?.orgSearchValue &&
                      networkAdmin?.orgSearchValue !== ""
                        ? networkAdmin?.orgSearchValue
                        : null
                    }
                    onSearch={() => {
                      handleSearchOrgMultipleNa({searchValue: networkAdmin?.orgSearchValue}, idx);
                    }}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "")
                          handleSearchOrgMultipleNa({}, idx);
                        
                        setMultipleNetworkAdmins((prev) => {
                          const existing = [...prev];
                          const objectToUpdate = existing[idx];

                          objectToUpdate.orgSearchValue = e?.target?.value?.trim();
                          existing[idx] = objectToUpdate;

                          return existing;
                        });
                      }
                    }}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") handleSearchOrgMultipleNa({}, idx);
                      let tempMultipleNetworkAdmin = multipleNetworkAdmins;
                      let tempNetworkAdmin = networkAdmin;
                      tempNetworkAdmin.networkAdminOrganization = newVal?._id;
                      handleFetchMultipleUserRoles(newVal?._id, idx);
                      handleFetchUsersForMultipleNA(
                        {
                          orgId: newVal?._id,
                          userRole:
                            networkAdmin?.networkAdminUserRole ?? undefined,
                        },
                        idx
                      );
                      tempMultipleNetworkAdmin[idx] = tempNetworkAdmin;
                      setMultipleNetworkAdmins([...tempMultipleNetworkAdmin]);
                    }}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return (
                          networkAdmin?.organizationListing?.find(
                            (_) =>
                              _?._id == networkAdmin?.networkAdminOrganization
                          )?.name || ""
                        );
                      } else {
                        return option?.name || "";
                      }
                    }}
                    labelKey="name"
                    options={
                      networkAdmin?.organizationListing &&
                      networkAdmin?.organizationListing
                    }
                  />
                </Grid>
                <Grid item md={4}>
                  <CustomSelect
                    //disabled={true}
                    selectStylesOverride={selectStylesOverride}
                    value={networkAdmin?.networkAdminUserRole || null}
                    onChange={(e, newVal) => {
                      let tempMultipleNetworkAdmin = multipleNetworkAdmins;
                      let tempNetworkAdmin = networkAdmin;
                      tempNetworkAdmin.networkAdminUserRole = newVal?._id;

                      handleFetchUsersForMultipleNA(
                        {
                          orgId:
                            networkAdmin?.networkAdminOrganization ?? undefined,
                          userRole: newVal?.name,
                        },
                        idx
                      );
                      tempMultipleNetworkAdmin[idx] = tempNetworkAdmin;
                      setMultipleNetworkAdmins([...tempMultipleNetworkAdmin]);
                    }}
                    disabled={!networkAdmin?.networkAdminOrganization}
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return networkAdmin?.userRoleList?.find(
                          (_) => _?._id == networkAdmin?.networkAdminUserRole
                        )?.name;
                      } else {
                        return option?.name;
                      }
                    }}
                    labelKey="name"
                    label="Select User Role"
                    options={
                      networkAdmin?.userRoleList && networkAdmin?.userRoleList
                    }
                  />
                </Grid>
                <Grid item md={4}>
                  <CustomMultiSelect
                    value={networkAdmin?.networkAdmins || []}
                    selectStylesOverride={selectStylesOverride}
                    onChange={(e, newVal) => {
                      let tempMultipleNetworkAdmin = multipleNetworkAdmins;
                      let tempNetworkAdmin = networkAdmin;

                      tempNetworkAdmin.networkAdmins = newVal;
                      tempMultipleNetworkAdmin[idx] = tempNetworkAdmin;

                      setMultipleNetworkAdmins([...tempMultipleNetworkAdmin]);
                    }}
                    disabled={!adminSelected}
                    label={
                      !adminSelected ? "Select admin first" : "Select User"
                    }
                    labelKey="name"
                    errors={errors}
                    renderOptions={(props, option, { selected }) => {
                      return (
                        <MenuItem
                          value={networkAdmin?.networkAdmins || ""}
                          selected={selected}
                          {...props}
                        >
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {`${option?.name} - ${option?.orgName}` || ""}
                          </Typography>
                        </MenuItem>
                      );
                    }}
                    ExtraCheckbox={() => {
                      return networkAdmin?.networkAdminOrganization ? (
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          label={`All Users (${
                            networkAdmin?.networkAdminCount ?? 0
                          })`}
                          control={
                            <Checkbox
                              id="select-all-from-org-checkbox"
                              sx={{
                                color: "#ff7200",
                                "&.Mui-checked": {
                                  color: "#ff7200",
                                },
                              }}
                              checked={
                                networkAdmin?.networkAdminAllFromOrg || false
                              }
                              onClick={(e) => {
                                e.preventDefault(); // prevent blur
                                if (e?.target?.checked) {
                                  if (!networkAdmin?.networkAdminOrganization) {
                                    showToast(
                                      "Please select organization first",
                                      true
                                    );
                                  } else {
                                    setMultipleNetworkAdmins((prev) => {
                                      const newArray = [...prev];
                                      const objectToUpdate = newArray[idx];
                                      objectToUpdate.networkAdminAllFromOrg = true;
                                      objectToUpdate.networkAdmins = [];
                                      newArray[idx] = objectToUpdate;
                                      return newArray;
                                    });
                                  }
                                } else {
                                  setMultipleNetworkAdmins((prev) => {
                                    const newArray = [...prev];
                                    const objectToUpdate = newArray[idx];
                                    objectToUpdate.networkAdminAllFromOrg = false;
                                    objectToUpdate.networkAdmins = [];
                                    newArray[idx] = objectToUpdate;
                                    return newArray;
                                  });
                                }
                              }}
                            />
                          }
                        />
                      ) : (
                        <></>
                      );
                    }}
                    onInputChange={(e, newVal, reason) => {
                      if (reason === "input") {
                        setTimeout(() => {
                          handleInputChangeMultiple(e, idx);
                        }, 400);
                      } else if (reason === "reset" || reason === "clear") {
                        handleSearchUsersMultipleNa({}, idx);
                      }
                    }}
                    searchValue={networkAdmin?.networkAdminSearchVal || null}
                    onSearch={() => {
                      const payload = {
                        orgId: networkAdmin?.networkAdminOrganization ?? undefined,
                        searchValue: networkAdmin?.networkAdminSearchVal ?? "",
                        userRole: networkAdmin?.networkAdminUserRole ?? undefined,
                      };
                      handleSearchUsersMultipleNa(payload, idx);
                    }}
                    options={
                      networkAdmin?.networkAdminList &&
                      networkAdmin?.networkAdminList?.filter((_) => {
                        const isNotSelected = !networkAdminSelected?.includes(
                          _._id
                        );
                        const isNotInExisting = isEdit
                          ? !editableNAPermissions?.some(
                              (item) => item?.id === _?._id
                            )
                          : true;

                        // Return true if both conditions are met
                        return isNotSelected && isNotInExisting;
                      })
                    }
                    allSelected={
                      !networkAdmin?.networkAdminAllFromOrg &&
                      networkAdmin?.networkAdmins &&
                      networkAdmin?.networkAdmins?.length ===
                        getMultipleFilteredNetworkAdmins(idx)?.length
                    }
                    handleToggleSelectAll={(e) => {
                      handleToggleSelectAllMultipleAdmins(e, idx);
                    }}
                    disableCloseOnSelect={true}
                    renderTags={() => null}
                    filterOptions={(options) => options}
                    getOptionDisabled={(option) =>
                      adminSelected && adminSelected === option?._id
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.name === value?.name;
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        const names = networkAdmin?.networkAdminList
                          ?.filter((_) =>
                            networkAdmin?.networkAdmins?.includes(_?._id)
                          )
                          ?.map((_) => {
                            return `${_?.name} - ${_?.orgName}`;
                          });
                        return names || [];
                      } else {
                        return `${option?.name} - ${option?.orgName}` || "";
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container my={0} px={2} spacing={2}>
                {networkAdmin?.networkAdmins
                  ?.filter((_) => _ !== undefined)
                  ?.slice(
                    0,
                    showAll
                      ? networkAdmin?.networkAdmins?.filter(
                          (_) => _ !== undefined
                        )?.length
                      : 3
                  )
                  ?.map((nAdmin, i) => {
                    if (nAdmin !== undefined) {
                      return (
                        <Grid item sm={3} xs={6} key={i}>
                          <Tooltip
                            placement="top"
                            arrow
                            title={`${
                              networkAdmin?.networkAdmins &&
                              networkAdmin?.networkAdmins?.length
                                ? networkAdmin?.networkAdmins[i]?.name
                                : "-"
                            } - ${
                              networkAdmin?.networkAdmins &&
                              networkAdmin?.networkAdmins?.length
                                ? networkAdmin?.networkAdmins[i]?.orgName
                                : "-"
                            }`}
                          >
                            <Grid
                              container
                              direction={"row"}
                              justifyContent="center"
                              alignItems={"center"}
                              sx={{
                                backgroundColor:
                                  themeType.default.themeLightOrange,
                                border: `1px solid ${themeType.default.themeOrange}`,
                                borderRadius: "12px",
                                pl: 1,
                                pr: 1,
                                minHeight: "35px",
                              }}
                            >
                              <Grid item sm={10} xs={8}>
                                <Typography
                                  sx={{
                                    ...textClasses.normalText,
                                    color: themeType.default.themeOrange,
                                    textAlign: "start",
                                  }}
                                >
                                  {networkAdmin?.networkAdmins &&
                                  networkAdmin?.networkAdmins?.length &&
                                  networkAdmin?.networkAdmins[i]?.name &&
                                  networkAdmin?.networkAdmins[i]?.name?.length >
                                    15
                                    ? networkAdmin?.networkAdmins[
                                        i
                                      ]?.name.substring(0, 15) + "..."
                                    : networkAdmin?.networkAdmins[i]?.name}
                                </Typography>
                              </Grid>
                              {handleRemoveSelectionMultiple && (
                                <Grid
                                  item
                                  sm={2}
                                  xs={4}
                                  display="flex"
                                  justifyContent="end"
                                >
                                  <IconButton
                                    sx={{
                                      alignContent: "right",
                                      padding: "5px",
                                    }}
                                    onClick={() =>
                                      handleRemoveSelectionMultiple(
                                        nAdmin?._id,
                                        idx
                                      )
                                    }
                                  >
                                    <Cancel
                                      sx={{
                                        color: themeType.default.themeOrange,
                                      }}
                                    />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Tooltip>
                        </Grid>
                      );
                    }
                  })}

                {networkAdmin?.networkAdmins &&
                  networkAdmin?.networkAdmins?.filter((_) => _ !== undefined)
                    ?.length > 3 && (
                    <>
                      <Grid
                        container
                        item
                        sm={3}
                        xs={6}
                        justifyContent="start"
                        alignItems={"center"}
                      >
                        <Typography
                          sx={{
                            ...textClasses.boldText,
                            color: themeType.default.themeOrange,
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowAll(!showAll)}
                        >
                          {showAll ? (
                            <>Collapse</>
                          ) : (
                            <>
                              View All (
                              {
                                networkAdmin?.networkAdmins?.filter(
                                  (_) => _ !== undefined
                                )?.length
                              }
                              )
                            </>
                          )}
                        </Typography>
                      </Grid>
                    </>
                  )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                borderRadius={1}
                border="1px solid #E0E0E0"
                my={2}
                ml={2}
                p={2}
                sx={{ width: "100%" }}
              >
                {/* select all--- */}
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    label="Select All"
                    control={
                      <Checkbox
                        data-testid="network-checkbox"
                        sx={{
                          color: "#ff7200",
                          "&.Mui-checked": {
                            color: "#ff7200",
                          },
                        }}
                        disabled={
                          (!networkAdmin?.networkAdmins ||
                            networkAdmin?.networkAdmins?.length < 1) &&
                          networkAdmin?.networkAdminAllFromOrg !== true
                        }
                        checked={networkAdmin?.allPermissionSelected || false}
                        onChange={(e) => {
                          handleMultipleSelectAllPermission(e, idx);
                        }}
                      />
                    }
                  />
                </Grid>
                {networkAdmin?.devicePermissions &&
                  Object.keys(networkAdmin?.devicePermissions)?.map(
                    (key, i) => {
                      return (
                        <Grid item md={3} key={i}>
                          <FormControlLabel
                            sx={{
                              color: "rgb(128, 128, 128)",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            label={networkAdmin?.devicePermissions[key]?.label}
                            control={
                              <Checkbox
                                data-testid="network-checkbox"
                                sx={{
                                  color: "#ff7200",
                                  "&.Mui-checked": {
                                    color: "#ff7200",
                                  },
                                }}
                                disabled={
                                  (!networkAdmin?.networkAdmins ||
                                    networkAdmin?.networkAdmins?.length < 1) &&
                                  networkAdmin?.networkAdminAllFromOrg !== true
                                }
                                checked={
                                  networkAdmin?.devicePermissions[key]?.value
                                }
                                onChange={(e) => {
                                  handleMultipleNaCheckboxChange(e, key, idx);
                                }}
                                name={key}
                              />
                            }
                          />
                        </Grid>
                      );
                    }
                  )}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default AdminsSection;
