
// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { Button, Grid, Typography } from "@mui/material";
// import CustomModel from "../../../Components/CustomModels/CustomModel1";
// import { showToast } from "../../../../services/functions";
// import { ToastContainer } from "react-toastify";
// import { Controller, useForm } from "react-hook-form";
// import DynamicFormRender from "../../../Components/DynamicFormRender";
// import { getInputFieldForTableRow } from '../../../Components/Utils';
// import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
// import {
//     checkTemplateExistance,
//     getTemplate,
//     tripDetails,
//     editTrip,
//     emptyTripDetails
// } from "../../../../redux/actions";


// function AdditionalInfo(props) {
//     const dispatch = useDispatch();
//     const { message, error } = useSelector((state) => state.common);
//     const { open, hide, additionalData, setAdditionalModal, setAdditionalData } = props;
//     const completeFields = useSelector((state) => state.templates.templateExistence.attributes || []);
//     const detailTrips = useSelector((state) => state.tripsData.tripDetails);
//     const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
//     const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
//     const { buttonClasses } = themeType;
//     const [templateArray, setTemplateArray] = useState([])
//     const [editResult, setEditResult] = useState(false)
//     const templateExistence = useSelector((state) => state.templates.templateExistence);
//     const [tempLoader, setTempLoader] = useState(false)

//     useEffect(() => {
//         if (templateExistence && templateExistence?._id) {
//             dispatch(getTemplate({ template_id: templateExistence && templateExistence?._id }))
//         }
//     }, [templateExistence])

//     const [tripData, setTripData] = useState(
//         {
//             data: [
//                 {
//                     tripId: "",
//                     routeNotAvailable: false,
//                     route: { id: "", routeName: "" },
//                     routeSchedule: {},
//                     dynamicArray: [],
//                     location: false,
//                     sameTripId: false,
//                     sameRoute: false,
//                     driverData: {
//                         vehicleNumber: "",
//                         driver: [{
//                             _id: "",
//                             mobile: "",
//                             license_no: "",
//                             name: "",
//                         }],
//                         images: []
//                     },
//                     devices: [
//                         {
//                             deviceId: "",
//                             address: {},
//                             primary: false,
//                             remark: ""
//                         }
//                     ],
//                     // closureData :{
//                     tripClosure: "",
//                     geofence: { id: "" },
//                     // }


//                 },
//             ]
//         }
//     )  

//     useEffect(() => {
//         let temp = { ...tripData }
//         if (Object?.keys(detailTrips)?.length) {
//             temp.data = [detailTrips]
//             setTempLoader(true)
//             setTimeout(() => {
//                 setTripData(temp)
//                 setTemplateArray(detailTrips?.dynamicArray)
//                 setTempLoader(false)
//             }, 10);
//         }
//         else {
//         }
//     }, [detailTrips])

//     const handleChange = async (event, i, fieldId) => {
//         const targetField = completeFields.filter(f => f._id == fieldId)[0];
//         let temp = { ...tripData }
//         let oldItems = [...templateArray];
//         let oldObject = { ...oldItems[i] };

//         if (targetField.type == "checkbox") {
//             let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
//             if (event.checked) {
//                 oldValues.push(event.value);
//             }
//             else {
//                 let pos = oldValues.indexOf(event.value);
//                 oldValues.splice(pos, 1)
//             }
//             oldObject[fieldId] = [...oldValues];
//         }


//         else {
//             oldObject[fieldId] = event.target.value;
//             temp.data[i].dynamicArray[0][fieldId] = event.target.value;
//         }
//         oldItems[i] = { ...oldObject };
//         setTemplateArray([...oldItems]);
//         setTripData(temp)
//     }

//     const getItemRow = (i) => {
//         const others = completeFields.map((of) => {
//             let component = { ...of };
//             let width = '96%'
//             return (
//                 <>
//                     {
//                         getInputFieldForTableRow(component, templateArray, i, handleChange, width, isDarkThemeEnabledSelector,additionalData)
//                     }

//                 </>
//             )
//         })
//         return [...others]
//     }

//     useEffect(() => {
//         dispatch(emptyTripDetails())
//         dispatch(checkTemplateExistance({ category: "trip" }));
//     }, [dispatch])

//     useEffect(() => {
//         if(Object.keys(additionalData).length){
//             dispatch(tripDetails({ id: additionalData?._id }))
//         }
//     }, [additionalData])

//     const onEdit = () => {
//         setEditResult(true)
//         dispatch(
//             editTrip(tripData, () => {
//                 setEditResult(false)
//             })
//         );
//     }


//     useEffect(() => {
//         if (message && editResult) {
//             showToast(message, false);
//             setAdditionalModal(false);
//             dispatch(emptyTripDetails())
//             setAdditionalData({})
//         }
//         if (error && editResult) {
//             showToast(error, true);
//         }
//     }, [message, error]);


//     return (
//         <>

//                 <Grid container mb={2}>

//                         <form>
//                             <Grid
//                                 mt={2}
//                                 item
//                                 sm={12}
//                                 xs={12}
//                                 style={{
//                                     overflowY: "auto",
//                                     border: "1px solid #E8E8E8",
//                                     overflowX: "hidden",
//                                     padding: "10px",
//                                     paddingBottom: "20px",
//                                 }}
//                             >
//                                 {tempLoader ?

//                                     <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
//                                         <ThreeDotsLoader />
//                                     </Grid>
//                                     :
//                                     tripData && tripData?.data[0] &&  tripData?.data[0]?.dynamicArray?.length ?
//                                     <Grid container mb={0} md={12} pb={3}
//                                                 sx={{
//                                                     width: '100%',
//                                                     paddingLeft: "10px",
//                                                     paddingRight: "10px",
//                                                     display: 'flex'
//                                                 }}
//                                             >
//                                         {getItemRow(0)}
//                                     </Grid>
//                                     :
//                                     <Grid container mb={0} md={12} pb={3}
//                                                 sx={{
//                                                     width: '100%',
//                                                     height:'100%',
//                                                     justifyContent:'center',
//                                                     alignItems:'center',
//                                                     display: 'flex'
//                                                 }}
//                                             >
//                                        No Template Found.
//                                     </Grid>
//                                 }

//                             </Grid>
//                         </form>
//                 </Grid>
//                 <Grid
//                     container
//                     item
//                     direction={"row"}
//                     sm={12}
//                     xs={12}
//                     sx={{ ml: 1, pb: 3 }}
//                 >
//                     <>
//                         <Button
//                             variant="outlined"
//                             sx={{
//                                 size: "small",
//                                 ...buttonClasses?.lynkitBlackFill,
//                                 minHeight: "36px",
//                                 minWidth: "130px",
//                                 marginRight: "20px",
//                             }}
//                             onClick={()=>{hide(); setAdditionalData({})}}
//                         >
//                             Cancel
//                         </Button>
//                         {additionalData?.status !== 'completed' && additionalData?.status !== 'reached' && tripData.data[0]?.detailsDeviceID ?
//                         <Button
//                             variant="contained"
//                             size="small"
//                             sx={{
//                                 ...buttonClasses.lynkitOrangeFill,
//                                 ml: 0,
//                                 fontSize: ".7rem",
//                                 border: "none",
//                                 height: "37px",
//                                 minWidth: "130px",
//                             }}
//                             onClick={() => onEdit()}
//                         >
//                             Update
//                         </Button>
//                         :null}
//                     </>
//                 </Grid>

//             <ToastContainer
//                 position="bottom-center"
//                 autoClose={false}
//                 closeOnClick={false}
//             />
//         </>
//     );
// }

// export default AdditionalInfo;



import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    checkTemplateExistance,
    getTemplate,
    tripDetails,
    emptyTripDetails
} from "../../../../redux/actions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";

function AdditionalInfo(props) {
    const dispatch = useDispatch();
    const { additionalData, index } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, textClasses, tableClasses } = themeType;
    const templateExistence = useSelector((state) => state.templates.templateExistence);
    const completeFields = useSelector((state) => state.templates.templateExistence.attributes || []);
    const detailTrips = useSelector((state) => state.tripsData.tripDetails);
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        if (Object.keys(additionalData).length) {
            dispatch(tripDetails({ id: additionalData?._id }))
        }
    }, [additionalData])

    useEffect(() => {
        dispatch(emptyTripDetails())
        setLoader(true)
        dispatch(checkTemplateExistance({ category: "trip" }, () => {
            setLoader(false);
        })
        );


    }, [dispatch])

    useEffect(() => {
        if (templateExistence && templateExistence?._id) {
            dispatch(getTemplate({ template_id: templateExistence && templateExistence?._id }))
        }
    }, [templateExistence])


    const chunkArray = (labelArray, valueArray, chunkSize) => {
        if (labelArray?.length && valueArray?.dynamicArray?.length) {
            labelArray.forEach(item => {
                const matchKey = Object.keys(valueArray?.dynamicArray[0]).find(key => key === item._id);
                if (matchKey) {
                    item.newVal = valueArray?.dynamicArray[0][matchKey];
                }
            });

            const chunkedArray = [];
            for (let i = 0; i < labelArray.length; i += chunkSize) {
                const chunk = labelArray.slice(i, i + chunkSize);
                if (chunk.length < chunkSize) {
                    const diff = chunkSize - chunk.length;
                    for (let j = 0; j < diff; j++) {
                        chunk.push({});
                    }
                }
                chunkedArray.push(chunk);
            }
             labelArray.push({
                _id: "route_source",
                label: "Route Source",
                newVal: valueArray?.route?.routeSource?.name || ""
            });
            labelArray.push({
                _id: "route_via_points",
                label: "Route Via Points",
                newVal: valueArray?.route?.viaPoints?.map(point => point.itemName).join(", ") || ""
            });
            labelArray.push({
                _id: "route_destination",
                label: "Route Destination",
                newVal: valueArray?.route?.routeDestination?.name || ""
            });
            
            labelArray.push({
                _id: "driver_details",
                label: "Vehicle Number",
                newVal: valueArray?.driverData?.vehicleNumber || '-'
            });
            labelArray.push({
                _id: "driver_details",
                label: "Driver Details",
                newVal: valueArray?.driverData?.driver?.map(driver => `${driver.driverName} - ${driver.mobileNo}`).join(", ") || ""
            });
            return chunkedArray;
        }
        return [];
    }

    const chunkedDataArray = completeFields?.length && detailTrips?.dynamicArray ? chunkArray(completeFields, detailTrips, 4) : [];


    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 1000;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 1000;
        };


    }
    const styleCssLabel = isDarkThemeEnabledSelector ?
        {
            color: "#808080",
            border: '1px solid #808080',
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }

    const styleCssValue = isDarkThemeEnabledSelector ?
        {
            color: "#fff",
            border: '1px solid #808080'
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }



    return (
        <>
            {loader ?
                <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                    <ThreeDotsLoader />
                </Grid>
                :
                chunkedDataArray && chunkedDataArray?.length ?
                <div style={{ overflowX: 'hidden',  display: 'flex', marginTop: '2.2%', }}>
                    <div className="arrowBox">
                        <ArrowLeftIcon id={`slideLeft-${index}`} onClick={() => slide(index)} className="arrowClass" />
                    </div>
                    <div id={`container-${index}`} className="slidingContainer" style={{height:'158px'}} mt={5}>
                        {chunkedDataArray && chunkedDataArray?.map((chunk, index) => (
                            <table style={{ ...tableClasses.myCTable, minWidth:'33%', marginLeft:'0px' }} key={index}>
                                <tbody>
                                    {chunk && chunk.map((item, idx) => (
                                        <tr key={idx}>
                                            <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'40%' }}>{item?.label || '-'}</th>
                                            <td style={{ ...tableClasses.myTD, ...styleCssValue, width: '60%' }}>{item?.newVal || '-'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                    </div>
                    <div className="arrowBox">
                        <ArrowRightIcon id={`slideRight-${index}`} onClick={() => slide(index)} className="arrowClass" />
                    </div>
                </div>
                :
                <div style={{marginTop: '2%', display:'flex',justifyContent:'center', alignItems:'center', height:'100px'}}>
                    No Trip Details..
                </div>
            }
        </>
    );
}

export default AdditionalInfo;