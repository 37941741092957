import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { Close, SellOutlined } from "@mui/icons-material";
import { GoogleMap } from "@react-google-maps/api";
import {
  dateTimeFormater,
  convertIntoGivenTimezone,
} from "../../../../services/functions";
import RouteIcon from "../../../../static/images/sidebarIcons/RouteIcon";
import { getRouteSchedculeData, userDetails } from "../../../../redux/actions";
import s from "../../Devices/components/components.module.css";
//import s from "./components.module.css";

const DetailCard = ({
  id,
  onClose,
  handleGetDetails,
  details,
  schedulesData,
  themeType,
  schedulesTabs,
  setSchedulesTabs,
  selectedSchedule,
  setSelectedSchedule,
  userDetails,
}) => {
  // theme --------
  const { cardClasses, textClasses } = themeType;
  const [centerCoordinates, setCenterCoordinates] = React.useState();
  const [myMap, setMymap] = React.useState(/** @type google.maps.Map */ (null));
console.log('schedulesTabs', schedulesTabs)
  const center = { lat: 28.7, lng: 77.1 };

  const cardFields = [
    {
      key: "routeName",
      name: "Name",
    },
    {
      key: "description",
      name: "Description",
    },
    {
      key: "batteryPercentage",
      name: "Min. Battery Percentage",
    },
    {
      key: "status",
      name: "Status",
    },
    {
      value: details ? details?.addedBy?.name : "",
      key: "addedBy",
      name: "Added By",
    },
    {
      value: details
        ? convertIntoGivenTimezone(
            details?.addedOn,
            userDetails.timeZone.offset
          )
        : "--",
      key: "addedOn",
      name: "Added On",
    },
    {
      value: details && details?.modifiedBy ? details?.modifiedBy?.name : "--",
      name: "Last Updated By",
    },
    {
      value:
        details && details?.modifiedOn
          ? convertIntoGivenTimezone(
              details?.modifiedOn,
              userDetails.timeZone.offset
            )
          : "--",
      name: "Last Updated On",
    },
  ];

  // Effects --------
  React.useEffect(() => {
    if (id) {
      handleGetDetails();
    }
  }, [id]);

  React.useEffect(() => {
    if (schedulesData && schedulesData?.length > 0) {
      setSchedulesTabs(schedulesData);
    }
  }, [schedulesData]);

  return (
    <>
      <Card
        className="ppmodal"
        data-testid="genericdetailedtable"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "relative",
          height: "74vh",
          overflow: "auto",
        }}
      >
        <CardHeader
          sx={{
            height: "45px",
            backgroundColor: themeType.default.themeOrange,
          }}
          title={
            <Typography
              sx={{
                ...textClasses.boldText,
                color: "white",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <RouteIcon height="16px" width="16px" fill="#ffffff" /> Route
              Details
            </Typography>
          }
          action={
            <IconButton size="small" sx={{ p: 0, mb: 1 }}>
              <Close sx={{ color: "white" }} onClick={onClose} />
            </IconButton>
          }
        />

        <CardContent sx={{ height: "calc(100% - 50px)", overflowY: "scroll" }}>
          {details && (
            <>
              <Box
                width="100%"
                height="50%"
                minHeight="300px"
                maxHeight="300px"
              >
                <GoogleMap
                  center={center}
                  zoom={7}
                  onLoad={(map) => setMymap(map)}
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                ></GoogleMap>
              </Box>
            </>
          )}

          <Grid container spacing={1.3} sx={{ mt: 2 }}>
            {cardFields?.map((field) => {
              return (
                <>
                  <Grid item sm={6} xs={4}>
                    <Typography
                      sx={{
                        ...textClasses.t13n,
                        color: "unset",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {field?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    sm={6}
                    xs={8}
                    direction="row"
                    alignItems={"center"}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        field?.value
                          ? field?.value
                          : details && details[field?.key]
                          ? details[field?.key]
                          : "-"
                      }
                    >
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color:
                            details && details[field?.key] === "ACTIVE"
                              ? themeType.default.themeGreen
                              : details && details[field?.key] === "DISABLE"
                              ? themeType.default.themeRed
                              : "unset",
                          textTransform: "capitalize",
                        }}
                      >
                        {`:  ${
                          field?.value
                            ? field?.value
                            : field?.key === "status"
                            ? details && details[field?.key] === "ACTIVE"
                              ? "Active"
                              : "Blocked"
                            : details && details[field?.key]
                            ? details[field?.key]
                            : "--"
                        }`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              );
            })}
          </Grid>

          {schedulesTabs && schedulesTabs?.length > 0 ? (
            <Box mt={4}>
              <Box>
                <Tabs
                  value={selectedSchedule}
                  onChange={(event, newValue) => {
                    setSelectedSchedule(newValue);
                  }}
                  variant="scrollable"
                  sx={{ minHeight: "16px" }}
                  scrollButtons="auto"
                  indicatorColor=""
                  textColor="inherit"
                >
                  {schedulesTabs?.map((schedule, i) => {
                    return (
                      <Tab
                        sx={{
                          bgcolor: "transparent",
                          minHeight: "24px",
                          borderRadius: "4px 4px 0 0",
                          border: "1px solid #E0E0E0",
                          padding: 0,
                          marginRight: "2px",
                          fontSize: "11px",
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            bgcolor: "transparent",
                            border: "1px solid #FF7200",
                            color: "#FF7200",
                          },
                        }}
                        label={<span>{schedule?.schedule ?? "-"}</span>}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <Box>
                <table
                  className={s["details-table"]}
                  style={{
                    width: "100%",
                    ...textClasses?.t12n,
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        background: themeType?.default?.themeOrange,
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    >
                      <td style={{ padding: "5px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#fff",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Geofence
                        </Typography>
                      </td>
                      <td style={{ padding: "5px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#fff",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center"
                          }}
                        >
                          Distance
                        </Typography>
                      </td>
                      <td style={{ padding: "5px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#fff",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center"
                          }}
                        >
                          STA
                        </Typography>
                      </td>
                      <td style={{ padding: "5px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#fff",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center"
                          }}
                        >
                          STD
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ borderBottom: "1px solid #0000002B" }}>
                      <td style={{ padding: "5px", textAlign: "left" }}>
                        {schedulesTabs[selectedSchedule]?.routeSource?.name}
                      </td>
                      <td style={{ padding: "5px", textAlign: "center" }}>-</td>
                      <td style={{ padding: "5px", textAlign: "center" }}>-</td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        {schedulesTabs[selectedSchedule]?.routeSource?.STD ??
                          "-"}
                      </td>
                    </tr>
                    {/* via-points */}
                    {schedulesTabs[selectedSchedule]?.viaPoints && (
                      <>
                        {schedulesTabs[selectedSchedule]?.viaPoints?.map(
                          (vp) => {
                            return (
                              <tr
                                style={{
                                  borderBottom: "1px solid #0000002B",
                                }}
                              >
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "left",
                                  }}
                                >
                                  {vp?.itemName ?? "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {vp?.travelKm ? `${vp?.travelKm} km` : "-"}
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {vp?.STA ?? "-"}<br/>{vp?.dayArrival && (vp?.dayArrival !== "sameDay" && vp?.dayArrival !== "nextDay") ? `(${vp?.dayArrival} days)` : (vp?.dayArrival ? `(${vp?.dayArrival})` : "-")}
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {vp?.STD ?? "-"}<br/>{vp?.dayDeparture && (vp?.dayDeparture !== "sameDay" && vp?.dayDeparture !== "nextDay") ? `(${vp?.dayDeparture} days)` : (vp?.dayDeparture ? `(${vp?.dayDeparture})` : "-")}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </>
                    )}
                    {/* destination */}
                    <tr style={{ borderBottom: "1px solid #0000002B" }}>
                      <td style={{ padding: "5px", textAlign: "left" }}>
                        {
                          schedulesTabs[selectedSchedule]?.routeDestination
                            ?.name
                        }
                      </td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        {schedulesTabs[selectedSchedule]?.routeDestination
                          ?.travelKm
                          ? `${schedulesTabs[selectedSchedule]?.routeDestination?.travelKm} km`
                          : "-"}
                      </td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        {schedulesTabs[selectedSchedule]?.routeDestination
                          ?.STA ?? "-"}<br/>{(schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival && schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival !== "sameDay" && schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival !== "nextDay") ? `(${schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival} days)` : schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival ? `(${schedulesTabs[selectedSchedule]?.routeDestination?.dayArrival})` : null}
                      </td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        {schedulesTabs[selectedSchedule]?.routeDestination
                          ?.STD ?? "-"}<br/>{(schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture && schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture !== "sameDay" && schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture !== "nextDay") ? `(${schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture} days)` : schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture ? `(${schedulesTabs[selectedSchedule]?.routeDestination?.dayDeparture})`  : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          ) : (
            <Box mt={4}>
              <table
                className={s["details-table"]}
                style={{
                  width: "100%",
                  ...textClasses?.t12n,
                }}
              >
                <thead>
                  <tr
                    style={{
                      background: themeType?.default?.themeOrange,
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    <td style={{ padding: "5px" }}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#fff",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Geofence
                      </Typography>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#fff",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Distance
                      </Typography>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#fff",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Time
                      </Typography>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#fff",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        Speed
                      </Typography>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ borderBottom: "1px solid #0000002B" }}>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeSource?.name}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeSource?.distance ?? "-"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeSource?.time ?? "-"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeSource?.speed ?? "-"}
                    </td>
                  </tr>
                  {/* via-points */}
                  {details?.viaPoints && (
                    <>
                      {details?.viaPoints?.map((vp) => {
                        return (
                          <tr
                            style={{
                              borderBottom: "1px solid #0000002B",
                            }}
                          >
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {vp?.itemName ?? "-"}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {vp?.totalDistance
                                ? `${vp?.totalDistance} km`
                                : "-"}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {vp?.etaTime ?? "-"}
                            </td>
                            <td
                              style={{
                                padding: "5px",
                                textAlign: "left",
                              }}
                            >
                              {vp?.speed ?? "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                  {/* destination */}
                  <tr style={{ borderBottom: "1px solid #0000002B" }}>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeDestination?.name}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeDestination?.totalDistance
                        ? `${details?.routeDestination?.totalDistance} km`
                        : "-"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeDestination?.etaTime ?? "-"}
                    </td>
                    <td style={{ padding: "5px", textAlign: "left" }}>
                      {details?.routeDestination?.speed ?? "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

const TableSection = ({ tableColumns, data, themeType }) => {
  const { tableClasses, textClasses } = themeType;
  return (
    <Box mt={2} width="100%">
      <table
        className={s["details-table"]}
        style={{
          width: "100%",
          ...textClasses?.t12n,
        }}
      >
        <thead>
          <tr
            style={{
              background: themeType?.default?.themeOrange,
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            {tableColumns?.map((column, index) => {
              return (
                <td style={{ padding: "5px" }} key={index}>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      color: "#fff",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {column?.header}
                  </Typography>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((entry, key) => (
                <tr key={key} style={{ borderBottom: "1px solid #0000002B" }}>
                  {tableColumns?.map((column, index) => (
                    <td
                      key={index}
                      style={{ padding: "5px", textAlign: "left" }}
                    >
                      {column?.header === "#"
                        ? column?.cell(key)
                        : column?.cell(entry)}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td component="th" scope="row" style={{ padding: "5px" }}>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
};

export default DetailCard;
