import { useState } from "react";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
// import { RefreshCcw } from "react-feather";
import { ReactComponent as SettingsIcon } from "../../../icons/setting2.svg";
import { ReactComponent as FullScreenIcon } from "../../../icons/zoom4.svg";
import { ReactComponent as HideMapIcon } from "../../../icons/HideMap2.svg";
import { ReactComponent as ExcelIcon } from "../../../icons/Xls_Icon.svg";
import Settings from "./Settings";
import XLSX from "sheetjs-style";
// import { downloadAsExcel } from "../../../../../util/util";
import { AiOutlineStar } from "react-icons/ai";
import { Button, Tooltip } from "@mui/material";
import {
  Description,
  Map,
  Refresh,
  Settings as SettingIcon,
  StarBorder,
} from "@mui/icons-material";

const PanelAction = ({
  isMap,
  mapToggle,
  fetchData,
  onViewUnderObserve,
  themeType,
  isDark,
  currentUserDetail,
  handleCreateBoxesForUser,
}) => {
  const [isSettings, setIsSettings] = useState(false);
  const { monitoringData, userDetail } = useSelector(
    (content) => content.monitoring
  );

  const { buttonClasses } = themeType;

  const handleSettingsToggle = () => setIsSettings(!isSettings);

  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      document
        .getElementById("settingsDashboard__container")
        .classList.add("p-5");
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const downloadDashboardData = () => {
    const excelData = monitoringData
      .map((_) => _.data)
      .flat()
      .filter((_) => _.isVisible)
      .map((_) => ({ Type: _.name, Value: _.value || 0 }));

    if (excelData?.length > 0) {
      if (excelData.length > 0) {
        const headers = Object.keys(excelData[0]);
        const sheetData = [
          headers,
          ...excelData.map((item) => headers.map((header) => item[header])),
        ];
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "monitoring-data.xlsx");
      }
    }
  };

  return (
    <>
      <Col
        md
        style={{ gap: "8px" }}
        className="d-flex align-items-center justify-content-end"
      >
        {currentUserDetail && currentUserDetail?.adminSpecificPermissions && monitoringData && monitoringData?.length == 0 && (
          <Button
            sx={{ ...buttonClasses?.lynkitOrangeEmpty }}
            onClick={handleCreateBoxesForUser}
          >
            Create Boxes
          </Button>
        )}
        <Tooltip placement="bottom" arrow title="Toggle Map">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
              }}
              onClick={mapToggle}
            >
              {/* <HideMapIcon
                className="monitoringDashboard-tabs__icon"
                style={{ height: "1.5em", width: "1.5em" }}
              /> */}
              <Map sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>

        {currentUserDetail && !currentUserDetail?.adminSpecificPermissions && (
          <Tooltip placement="bottom" arrow title="Settings">
            <span>
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  px: "8px",
                  minWidth: "50px",
                  maxHeight: "35px",
                  maxWidth: "35px",
                }}
                onClick={handleSettingsToggle}
              >
                <SettingIcon sx={{ fontSize: "1.2rem" }} />
              </Button>
            </span>
          </Tooltip>
        )}

        <Tooltip placement="bottom" arrow title="Refresh">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              onClick={fetchData}
            >
              <Refresh sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>

        <Tooltip placement="bottom" arrow title="Download monitoring data">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              onClick={downloadDashboardData}
            >
              <Description sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip placement="bottom" arrow title="Devices under observation">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              onClick={onViewUnderObserve}
            >
              <StarBorder sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>
      </Col>

      {isSettings ? (
        <Settings
          isModal={isSettings}
          onToggle={handleSettingsToggle}
          themeType={themeType}
          isDark={isDark}
        />
      ) : null}
    </>
  );
};

export default PanelAction;
