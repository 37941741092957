import {
    LOGS_LIST,
    LOGS_DOC,
    ALERT_LIST_For_AdminAndSubuser,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    EMPTY_LOGS_REPORT
  } from "../types";
  import axios from "../../services/Api";
  import { geoAxios } from "../../services/Api";
  import { showToast } from "../../services/functions";

  export const getAlertLogs = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getAlertLogs", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

  export const getAlertLogsExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getAlertLogsExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              if(data.statusCode === 201){
                showToast(data?.message, true);
              }
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//  {
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }

  export const getWebUnlockRequests = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getWebUnlockRequests", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }

  export const getWebUnlockRequestsExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getWebUnlockRequestsExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }


//   {
//     "limit": 10,
//     "skip": 1,
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }

  export const getBluetoothLogs = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getBluetoothLogs", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }

  export const getBluetoothLogsExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getBluetoothLogsExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "limit": 10,
//     "skip": 1,
//     "regex": "",
//     "toDate": "",
//     "fromDate": "",
//     "type":"rfid"
// }


  export const getRfid_PasscodeLogs = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getRfid_PasscodeLogs", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "regex": "",
//     "toDate": "",
//     "fromDate": "",
//     "type":"rfid"
// }

  export const downloadRfid_PasscodeExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/downloadRfid_PasscodeExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "limit": 10,
//     "skip": 1,
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }


  export const getFixedLockLogs = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getFixedLockLogs", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "regex": "",
//     "toDate": "",
//     "fromDate": ""
// }

  export const downloadFixedLock_LogsExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/downloadFixedLock_LogsExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "limit": 10,
//     "page": 1,
//     "skip": 1,
//     "deviceId": "",
//     "dateto": "",
//     "datefrom": ""
// }


export const getViewPassCodeLogs = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getViewPassCodeLogs", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_LIST, payload: { data: [], count: 0 } });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_LIST,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_LIST, payload: { rows: [], count: 0 } });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

//   {
//     "deviceId": "",
//     "dateto": "",
//     "datefrom": ""
// }
export const getViewPassCodeLogsExcel = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        geoAxios
          .post("/getViewPassCodeLogsExcel", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: LOGS_DOC, payload: {} });
              showToast(data?.message, true);
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: LOGS_DOC,
                payload: data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: LOGS_DOC, payload: {} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }

export const alertListingForWithTrip = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
          .post("/getAllSettings", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: ALERT_LIST_For_AdminAndSubuser, payload: { data: []} });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: ALERT_LIST_For_AdminAndSubuser,
                payload: data.data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: ALERT_LIST_For_AdminAndSubuser, payload: { data: []} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }


export const alertListingForWithoutTrip = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        axios
          .post("/getAllSettings", { ...payload })
          .then(({ data }) => {
            if (data.success) {
              callback();
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.message });
              dispatch({ type: ALERT_LIST_For_AdminAndSubuser, payload: { data: []} });
              if (cb) {
                cb();
              }
            }
            function callback() {
              dispatch({ type: FETCH_SUCCESS });
              dispatch({
                type: ALERT_LIST_For_AdminAndSubuser,
                payload: data.data,
              });
            //   console.log('/getAlertLogs', data)
              if (cb) {
                cb(data);
              }
            }
          })
          .catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            dispatch({ type: ALERT_LIST_For_AdminAndSubuser, payload: { data: []} });
            console.log("Error****:", error.message);
            if (cb) {
              cb();
            }
          });
      };
  }


  export const emptyLogsReport = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: EMPTY_LOGS_REPORT });
    }
  }



