import React, { useEffect } from "react";
import Login from "../../views/Landing/Login";
import history from "../../services/history";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { getOTPforResetPassword, userLogin } from "../../redux/actions/Auth";
import ReactGA from 'react-ga4';
import FrameBuster from "../../views/Modules/FrameBuster";

const loginFont = createTheme({
  typography: {
    fontFamily: ["Nunito", "serif"].join(","),
  },
});

const TRACKING_ID = 'G-YMDF21LE3R' //--- correct one
// const TRACKING_ID = 'HBHSHDSHDSjjkkk'

ReactGA.initialize(TRACKING_ID);

export default function App() {
  const dispatch = useDispatch();
  const [otpLoading, setOtpLoading] = React.useState(false);
  const [otpSuccess, setOtpSuccess] = React.useState(false);
  let token = localStorage.getItem("token");
  let version=''
  let versionMismatch=false

  const _onLogin = (data) => {
    dispatch(userLogin(data));
  };

  // const _getOTP = (data) => {
  //   let result = {};
  //   try {
  //     setOtpLoading(true);
  //     dispatch(
  //       getOTPforResetPassword(data, (res) => {
  //         setOtpLoading(false);
  //         result = {
  //           success: true,
  //         };
  //       })
  //     );
  //   } catch (e) {
  //     setOtpLoading(false);
  //     result = {
  //       success: false,
  //     };
  //   }
  //   return result;
  // };

  return (
    <ThemeProvider theme={loginFont}>
    <FrameBuster />
      <div>
        {/* <Navbar logo={logo}/> */}
        <Login
          _onLogin={_onLogin}
          setOtpLoading={setOtpLoading}
          otpLoading={otpLoading}
          otpSuccess={otpSuccess}
          setOtpSuccess={setOtpSuccess}
        />
        {/* <Footer/> */}
      </div>
    </ThemeProvider>
  );
}
