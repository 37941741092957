import React, { useState, useEffect } from "react";
import { Router } from "react-router-dom";
import history from "./../services/history";
import CustomRoutes from "./../routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import {ConnectedRouter} from "react-router-redux";
import { Provider, useSelector } from "react-redux";
import configureStore from "../redux/store/index";
import { createTheme, ThemeProvider } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import "../static/css/wms.css";
import { showToastWithButtons } from "./../services/functions";
import { toast } from 'react-toastify';

export const store = configureStore();
let toastId = null;
let intervalId;

function App() {

  // useEffect(() => {
  //   let version = localStorage.getItem("version");
  //   let versionMismatch = false;

  //   if ('caches' in window) {
  //     caches.keys().then((names) => {
  //       names.forEach(name => {
  //         caches.delete(name);
  //       })
  //     });
  //   }

  //   function versionCheck() {
  //     fetch("/assets/version.json?t=" + new Date().getTime(), {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     })
  //       .then((response) => response.json())
  //       .then((version1) => {
  //         if (version1.hash && version1.hash !== version) {
  //           if (!versionMismatch) {
  //             if (toastId !== null) {
  //               toast.dismiss(toastId);
  //             }

  //             const newToastId = showToastWithButtons("A new version of Lynktrac in available!", "Refresh", () => handleAction1(versionMismatch, version1), "Cancel", handleAction2);
  //             toastId = newToastId;


  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  //   if (!localStorage.getItem('sidebar')) {
  //     versionCheck();
  //     intervalId = setInterval(versionCheck, 30000);
  //   }
  //   return () => clearInterval(intervalId);
  // }, []);

  // const handleAction1 = (versionMismatch, version1) => {
  //   versionMismatch = true;
  //   localStorage.setItem("version", version1.hash);
  //   setTimeout(() => {
  //     window.location.reload(true);
  //   }, 2000);
  // };

  // const handleAction2 = () => {
  //   clearInterval(intervalId);
  //   localStorage.removeItem("version");
  // };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store.store}>
        <PersistGate persistor={store.persistor}>
          <Router history={history}>
            <CustomRoutes />
            {/* <ThemeProvider theme={appFont}>
          </ThemeProvider> */}
          </Router>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}
export default App;
