import { ROUTE_DISTANCE, FLUSH_ROUTES_DATA, FETCH_ROUTES, ROUTE_DETAILS, SCHEDULE_DATA, CLEAR_ROUTE_DATA, FETCH_ROUTE_FLAG, EMPTY_ROUTE_SCHEDULE } from "../types";
  
  const INIT_STATE = {
    routeDistance: "",
    scheduleData: [],
    allRoutes: [],
    routeDetails: null,
    totalRoutes: 0,
    selectedRouteSchedules: [],
    filterFromStore: null,
  };
 
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ROUTE_DISTANCE: {
         const temp = [...state.scheduleData];
        temp.push(action.payload)
        return {...state, scheduleData: temp}
      }
      case CLEAR_ROUTE_DATA: {
        const temp  = [...state.scheduleData];

        const filterRouteData = temp?.filter((data)=> {
          const obj = action?.payload?.find((action)=> action?.sourceObj?._id === data?.sourceObj?._id || action?.destinationObj?._id === data?.destinationObj?._id)
          if(obj?.sourceObj?._id === data?.sourceObj?._id || obj?.destinationObj?._id === data?.destinationObj?._id){
            return 
          }else return data

        })
        return {...state, scheduleData: filterRouteData}
      }
      
      case EMPTY_ROUTE_SCHEDULE: return { ...state, selectedRouteSchedules : []}
      case FLUSH_ROUTES_DATA: return { ...state, scheduleData : []}
      case FETCH_ROUTES:
        return {
          ...state,
          allRoutes: action?.payload?.rows,
          totalRoutes: action?.payload?.count,
        };
      case ROUTE_DETAILS:
        return {
          ...state,
          routeDetails: action?.payload,
        };
      case SCHEDULE_DATA: return {
        ...state, 
        selectedRouteSchedules: action?.payload
      }
      case "FLUSH_SCHEDULE_DATA": return {
        ...state, 
        selectedRouteSchedules: []
      }
      case "ROUTES_TEMP_FILTER": return {
        ...state, 
        filterFromStore: action?.payload
      }
      default:
        return state;
  }
};

export const flushRoutesData = () => ({
  type: FLUSH_ROUTES_DATA,
});

export const setRoutesTempFilter = (data) => ({
  type: "ROUTES_TEMP_FILTER", payload: data
});
