import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import moment from "moment/moment";
// import { getAddress } from "../../redux/geofence/geofenceSlice";
import { capitalize } from ".";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { fetchAddressFromCoords } from "../../../redux/actions";
import history from "../../../services/history";
import { convertIntoGivenTimezone } from "../../../services/functions";
import { CustomInput } from "../../../components/FormUI";
import { selectStylesOverride } from "../../../utils/util";
import { getMainAssetData } from "../../../redux/actions/AssetMgt";

export const DetailPanel = ({
  isOpen,
  onClose,
  data: detail = {},
  themeBorderColor,
  isDark,
  themeType,
  currentUserDetail,
  setSearchDeviceValue,
  searchDeviceValue,
  mainFilterDate,
  filterObj,
}) => {
  const [data, setDetail] = useState();
  const [searchTags, setSearchTags] = useState(null);
  const [categoriesArray, setCategoriesArray] = useState([]);

  const { textClasses, inputClasses } = themeType;

  useEffect(() => {
    if (
      searchDeviceValue &&
      searchDeviceValue?.length > 0 &&
      Array?.isArray(detail) &&
      detail?.length > 0
    ) {
      let newArr = [];
      let tagsTemp = [];
      detail?.map((obj) => {
        if (obj?.count == 1) {
          newArr?.push(obj?.data[0]);
          tagsTemp?.push(obj?.label);
        }
      });

      setDetail(newArr[0] ? [newArr[0]] : []);
      setSearchTags([...tagsTemp]);
    } else {
      if ("categories" in detail) {
        let keyValueToAdd = { label: detail?.label };
        const newArray = detail?.data?.map((item, index) => {
          if (index === 0) {
            return { ...item, ...keyValueToAdd };
          } else {
            return item;
          }
        });
        setDetail(newArray);
        setCategoriesArray(detail?.categories);
      } else {
        let keyValueToAdd = { label: detail?.label };
        const newArray = detail?.dataSet?.map((item, index) => {
          if (index === 0) {
            return { ...item, ...keyValueToAdd };
          } else {
            return item;
          }
        });
        setDetail(newArray);
        setCategoriesArray([]);
      }
    }
  }, [detail]);

  const dispatch = useDispatch();
  const listRef = useRef({});
  const rowHeights = useRef({});

  function getRowHeight(index) {
    return rowHeights.current[index] + 20 || 300;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const handleGetAddress = (location, index, ref) => {
    if (location && location?.length > 0) {
      const payload = {
        latitude: location[1],
        longitude: location[0],
        appType: "GOOGLE",
      };
      dispatch(
        fetchAddressFromCoords(payload, (res) => {
          if (res && res?.length > 0) {
            const { address } = res[0];
            let temp = [...data];
            // Check if the object is non-extensible
            if (!Object.isExtensible(temp[index])) {
              // Create a new object with the same properties
              temp[index] = { ...temp[index] };
            }
            temp[index].geoAddress = address;
            setDetail(temp);
          }
        })
      );
    }
  };

  function Row({ index, style }) {
    const rowRef = useRef({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    const item = data?.[index] || {};
    const {
      deviceId,
      clientId,
      deviceType,
      geofenceName,
      previousGeofenceName,
      lockStatus,
      ignitionStatus,
      tripStatus,
      tripCompletionTime,
      packetTime,
      tripId,
      routeName,
      description,
      latitude,
      longitude,
      vehicleNo,
      tripName,
      idleTime,
      idleSinceLastAlert,
      adminName,
    } = item;

    const lock_status = item?.lock_status ?? "";
    let deviceConnectionStatus = "";

    if (deviceType === "fixedELock" || deviceType === "fixedTracker") {
      deviceConnectionStatus =
        lock_status === "locked"
          ? "External Battery Connected"
          : "External Battery Disconnected";
    } else if (deviceType === "assetTracker") {
      deviceConnectionStatus =
        lock_status === "locked" ? "Attached" : "Deattached";
    }

    return (
      <div style={style}>
        <div
          className="detail_panel__card"
          style={{ borderColor: themeBorderColor, position: "relative" }}
          data-testid="detail_panel__card"
          ref={rowRef}
        >
          <div
            style={{
              height: "20px",
              padding: "0px 4px",
              position: "absolute",
              top: "-10px",
              left: "7px",
              background: isDark ? "#121212" : "#FFFFFF",
              fontSize: "14px",
            }}
          >
            {index + 1}.
          </div>
          <div className="detail_panel__row">
            <div className="detail_panel__col">
              {/* <i className="fa fa-info"></i> */}
              Device ID
            </div>
            <div className="detail_panel__col ">
              <span
                data-testid="deviceid"
                onClick={() =>
                  history.push(`/dashboard/devices/${deviceId}/map`)
                }
                className="address"
              >
                {clientId || "NA"}
              </span>
            </div>
          </div>
          <div className="detail_panel__row">
            <div className="detail_panel__col">
              {/* <i className="fa fa-globe"></i> */}
              Device Type
            </div>
            <div className="detail_panel__col">{deviceType || "NA"}</div>
          </div>
          {geofenceName && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-location-arrow"></i> */}
                Geofence
              </div>
              <div className="detail_panel__col">{geofenceName || "NA"}</div>
            </div>
          )}
          {previousGeofenceName && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-location-arrow"></i> */}
                Last Geofence Name
              </div>
              <div className="detail_panel__col">
                {previousGeofenceName || "NA"}
              </div>
            </div>
          )}
          {(deviceType === "eLock" || deviceType === "fixedELock") &&
            lockStatus && (
              <div className="detail_panel__row">
                <div className="detail_panel__col">
                  {/* <i className="fa fa-lock"></i> */}
                  Lock Status
                </div>
                <div className="detail_panel__col">
                  {capitalize(lockStatus) || "NA"}
                </div>
              </div>
            )}
          {deviceConnectionStatus && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-lock"></i> */}
                Device Connection Status
              </div>
              <div className="detail_panel__col">
                {capitalize(deviceConnectionStatus) || "NA"}
              </div>
            </div>
          )}
          {ignitionStatus && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-location-arrow"></i> */}
                Device Running Status
              </div>
              <div className="detail_panel__col">{ignitionStatus || "NA"}</div>
            </div>
          )}
          {tripStatus && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-location-arrow"></i> */}
                Trip Status
              </div>
              <div className="detail_panel__col">{tripStatus || "NA"}</div>
            </div>
          )}
          {tripStatus && tripCompletionTime && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-location-arrow"></i> */}
                Trip Completion Time
              </div>
              <div className="detail_panel__col">
                {convertIntoGivenTimezone(
                  new Date(Number(tripCompletionTime)),
                  currentUserDetail?.timeZone?.offset
                ) || "NA"}
              </div>
            </div>
          )}
          <div className="detail_panel__row">
            <div className="detail_panel__col">
              {/* <i className="fa fa-clock-o"></i> */}
              Time
            </div>
            <div className="detail_panel__col">
              {currentUserDetail
                ? convertIntoGivenTimezone(
                    new Date(Number(packetTime * 1000)),
                    currentUserDetail?.timeZone.offset
                  )
                : "NA"}
              {/* {moment(packetTime * 1000).format("DD/MM/YYYY HH:mm:ss")} */}
            </div>
          </div>
          {tripId && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-map-marker"></i> */}
                Trip Id
              </div>
              <div className="detail_panel__col">
                <span
                  data-testid="tripid"
                  // onClick={() => {
                  //   if (!tripId || !tripStatus) {
                  //   } else {
                  //     history?.push(
                  //       `dashboard/trips/trip-map/${tripId}?id=${deviceId}`
                  //     );
                  //   }
                  // }}
                  className={!tripId || !tripStatus ? "" : "address"}
                >
                  {!tripId || !tripStatus
                    ? "NA"
                    : `${tripId} (${capitalize(tripStatus)})`}
                </span>
                {/* } */}
              </div>
            </div>
          )}
          {routeName && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-globe"></i> */}
                Route Name
              </div>
              <div className="detail_panel__col">{routeName || "NA"}</div>
            </div>
          )}
          {description && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-globe"></i> */}
                Description
              </div>
              <div className="detail_panel__col">{description || "NA"}</div>
            </div>
          )}
          <div className="detail_panel__row">
            <div className="detail_panel__col">
              {/* <i className="fa fa-globe"></i> */}
              Address
            </div>
            <div className="detail_panel__col" id={`address-${index}`}>
              {!latitude || !longitude ? (
                "NA"
              ) : item?.geoAddress ? (
                <span
                  className="address"
                  // onClick={() =>
                  //   handleGetAddress([longitude, latitude], index, rowRef)
                  // }
                >
                  {item?.geoAddress}
                </span>
              ) : (
                <span
                  className="address"
                  onClick={() =>
                    handleGetAddress([longitude, latitude], index, rowRef)
                  }
                >
                  {Number(latitude).toFixed(4)} , {Number(longitude).toFixed(4)}
                </span>
              )}
            </div>
          </div>
          {vehicleNo && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-truck"></i> */}
                Vehicle Number
              </div>
              <div className="detail_panel__col">{vehicleNo || "NA"}</div>
            </div>
          )}
          {tripName && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-truck"></i> */}
                Trip Name
              </div>
              <div className="detail_panel__col">{tripName || "NA"}</div>
            </div>
          )}
          {idleTime && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-truck"></i> */}
                Idle Since Last Trip
              </div>
              <div className="detail_panel__col">{idleTime || "NA"}</div>
            </div>
          )}
          {idleSinceLastAlert && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-truck"></i> */}
                Idle Since Last Event
              </div>
              <div className="detail_panel__col">
                {idleSinceLastAlert || "NA"}
              </div>
            </div>
          )}
          {adminName && (
            <div className="detail_panel__row">
              <div className="detail_panel__col">
                {/* <i className="fa fa-user"></i> Admin */}
                Admin
              </div>
              <div className="detail_panel__col">{adminName || "NA"}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`asset_management_detail_panel ${isOpen ? "open" : "clos"}`}
      data-testid="detail_panel_wrap"
      style={{
        borderColor: themeBorderColor,
        visibility: `${isOpen ? "visible" : "hidden"}`,
      }}
    >
      {isOpen ? (
        <>
          {/* <h5 className="panel__header">
            <span
              className="close_btn"
              onClick={onClose}
              aria-label="close_btn"
            ></span>
            {data && data[0]?.label}
          </h5> */}
          <Box
            width="100%"
            mb={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: `1px solid ${themeBorderColor}`,
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                marginLeft: "25px",
                textAlign: "center",
                ...textClasses.themeColor,
              }}
            >
              {searchDeviceValue &&
              searchDeviceValue?.length > 0 &&
              Array?.isArray(detail)
                ? `Device Id: ${searchDeviceValue}`
                : `${data && data[0]?.label}`}
              {/* {data && data[0]?.label} */}
            </Typography>
            <IconButton
              size="medium"
              sx={{ marginLeft: "auto" }}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </Box>

          {searchDeviceValue &&
            searchDeviceValue?.length > 0 &&
            searchTags &&
            searchTags?.length > 0 && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {searchTags?.map((tag, i) => {
                  return (
                    <Box
                      style={{
                        padding: "2px 5px",
                        borderRadius: "10px",
                        background: themeType?.default?.themeGray,
                      }}
                      key={i}
                    >
                      {tag}
                    </Box>
                  );
                })}
              </Box>
            )}

          {categoriesArray?.length ? detailsData() : null}
          <div className="detail_panel">
            {data && data?.length > 0 ? (
              <AutoSizer>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemCount={data?.length || 0}
                    itemSize={getRowHeight}
                    ref={listRef}
                  >
                    {Row}
                  </VariableSizeList>
                )}
              </AutoSizer>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h6" textAlign="center">
                  No Data Available
                </Typography>
              </Box>
            )}
          </div>
        </>
      ) : null}
    </div>
  );

  function detailsData() {
    return (
      <div className="categories_container" data-testid="categories_container">
        {categoriesArray.map((category, indx) => {
          return (
            <span className="category" key={indx} aria-label="category">
              {category}
            </span>
          );
        })}
      </div>
    );
  }
};
export function redirect_to(page = "", id = "") {
  const lynktrac_map = {
    trip: "tripmap",
    device: "devicemap",
  };
  var base_url =
    process.env.REACT_APP_ENV === "prod"
      ? "https://lynktrac.io"
      : "https://beta.lynktrac.io";
  let url = `${base_url}/${lynktrac_map[page]}/${id}`;
  window.open(url, "_blank");
}
