import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Input } from "reactstrap";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import moment from "moment";
import { ReactComponent as ExcelIcon } from ".././icons/Xls_Icon.svg";
import MiniLoader from "./MiniLoader";
import { useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
// import SearchBar from "../../util/searchbar/SearchBar";
// import { confirmToast } from "../../util/ConfirmToast";
import { toast } from "react-toastify";
import XLSX from "sheetjs-style";
import {
  convertIntoGivenTimezone,
  showToast,
} from "../../../../services/functions";
import {
  addDeviceToObservation,
  generateAlertComment,
  getAlertComments,
  getAlertsData,
  getAssetsObservation,
  getMonitoringAlertDetails,
  getMonitoringAnalyticsDetails,
  removeDeviceFromObservation,
  resolveAlert,
} from "../../../../redux/actions/Monitoring";
import { selectAlertData } from "../../../../redux/reducers/Monitoring";
import e from "cors";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import history from "../../../../services/history";
import { confirmToast } from "../../../Components/ConfirmPopup/ConfirmToast";
import { fetchAddressFromCoords } from "../../../../redux/actions";
import { Close, FileDownload, Search } from "@mui/icons-material";
import { CustomInput } from "../../../../components/FormUI/CustomInput";
import { selectStylesOverride } from "../../../../utils/util";

const dummyAnalyticsDetails = {
  lateDeparture: [
    {
      client_id: "99875087932",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Awating Departure",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 05:05 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "CP",
      previous_geo_name: "CP",
      tripId: "LY1285",
      route_name: "Lynkit-CP-GP-CP",
      vehicleNo: "DL86869",
      STD: "06/03/2024 06:00 PM",
      STA: "06/03/2024 05:35 PM",
      Current_ETA: "06/03/2024 05:50 PM (Late by 15 mins)",
    },
  ],
  lateArrival: [
    {
      client_id: "9984848848485",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 05:40 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "GP",
      tripId: "LY148453",
      route_name: "Lynkit-CP-GP-KL",
      vehicleNo: "DL86SA8696",
      STD: "06/03/2024 07:50 AM",
      STA: "07/03/2024 07:45 AM",
      Current_ETA: "07/03/2024 11:30 AM (Late by 03 hours & 45 mins)",
    },
  ],
  onTime: [
    {
      client_id: "224533870",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 05:00 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "GP",
      tripId: "LY1284533",
      route_name: "Lynkit-CP-GP-KL",
      vehicleNo: "DL86SA8693",
      STD: "06/03/2024 08:00 PM",
      STA: "06/03/2024 07:45 PM",
      Current_ETA: "06/03/2024 07:37 PM",
    },
    {
      client_id: "9987534088884",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 05:33 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "GP",
      tripId: "LY1284534",
      route_name: "Lynkit-CP-GP-KL",
      vehicleNo: "DL86SA8693",
      STD: "06/03/2024 09:45 PM",
      STA: "06/03/2024 09:15 PM",
      Current_ETA: "06/03/2024 09:06 PM",
    },
  ],
  earlyArrival: [
    {
      client_id: "99875088884",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 04:45 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "Lynkit",
      tripId: "LY12853",
      route_name: "Lynkit-CP",
      vehicleNo: "DL86SA8693",
      STD: "07/03/2024 04:15 AM",
      STA: "07/03/2024 03:35 AM",
      Current_ETA: "07/03/2024 02:20 AM (Before time by 1 hours & 15 mins)",
    },
    {
      client_id: "99875088884",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 04:48 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "Lynkit",
      tripId: "LY1285003",
      route_name: "Lynkit-CP",
      vehicleNo: "DL86SA8693",
      STD: "07/03/2024 12:35 PM",
      STA: "07/03/2024 11:35 AM",
      Current_ETA: "07/03/2024 11:07 AM (Before time by 28 minutes)",
    },
    {
      client_id: "99875088884",
      longitude: "7.72714710000000053469e+01",
      latitude: "2.85291449999999997544e+01",
      deviceType: "elock",
      adminName: "Pandey",
      tripStatus: "Intransit to Destination",
      ignition_status: "MOVING",
      lockStatus: "locked",
      packet_time: "06/03/2024 04:30 PM",
      battery_voltage: "93.89",
      speed: "7.00000000000000000000e+01",
      geo_name: "nil",
      previous_geo_name: "Lynkit",
      tripId: "LY1285233",
      route_name: "Lynkit-CP",
      vehicleNo: "DL86SA8693",
      STD: "07/03/2024 02:45 PM",
      STA: "07/03/2024 01:35 PM",
      Current_ETA: "07/03/2024 01:02 PM (Before time by 33 minutes)",
    },
  ],
};

export function DetailPanel({
  sideToOpen,
  onClose,
  data = {},
  boxRef,
  themeType,
  setLatLng,
}) {
  const dispatch = useDispatch();

  const listRef = useRef({});
  const rowHeights = useRef({});

  const [alertSwitch, setAlertSwitch] = useState("trip");
  const [comment, setComment] = useState("");
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });
  const [isDropDown, setIsDropDown] = useState({});
  const { currentUserDetail } = useSelector((state) => state.auth);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { inputClasses, tableClasses } = themeType;
  const {
    monitoringAnalyticsDetails,
    monitoringAlertDetails,
    monitoringAlertDetailsCount,
    devicesUnderObservation,
    activeUserId,
    detailLoading,
    alertCount,
  } = useSelector((_) => _?.monitoring);

  const [alerts, setAlerts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [tempSearch, setTempSearch] = useState("");

  const {
    _id,
    borderColor,
    header,
    name,
    value,
    type,
    dataType,
    isUnderObservation,
  } = data;

  useEffect(() => {
    if (monitoringAnalyticsDetails && monitoringAnalyticsDetails?.length > 0) {
      setAlerts(monitoringAnalyticsDetails);
    } else if (monitoringAlertDetails && monitoringAlertDetails?.length > 0) {
      setAlerts(monitoringAlertDetails);
    } else if (devicesUnderObservation && devicesUnderObservation?.length > 0) {
      setAlerts(devicesUnderObservation);
    } else {
      setAlerts([]);
    }
  }, [
    monitoringAnalyticsDetails,
    monitoringAlertDetails,
    devicesUnderObservation,
  ]);
  const count = alerts?.length;

  useEffect(() => {
    if (!sideToOpen) return;
    fetchAlerts();
    setSearchValue("");
    setTempSearch("");
    setAlerts([]);
  }, [sideToOpen]);

  // useEffect(() => {
  //   if (sideToOpen && header === "Analytics") {
  //     setAlerts(dummyAnalyticsDetails[sideToOpen] || []);
  //   }
  // }, [sideToOpen, header]);

  useEffect(() => {
    if (pagination?.limit || pagination?.page) {
      const alertsPayload = {
        type,
        header,
        getData: alertSwitch,
        searchValue: "",
      };
      if (activeUserId) {
        alertsPayload.userId = activeUserId;
      }
      dispatch(
        getMonitoringAlertDetails(
          { ...alertsPayload, ...pagination },
          (res) => {}
        )
      );
    }
  }, [pagination?.page, pagination?.limit]);

  const fetchAlerts = () => {
    if (isUnderObservation) {
      dispatch(getAssetsObservation({ searchValue: "" }));
    } else {
      const payload = {
        type,
        header,
        searchValue: "",
      };
      const alertsPayload = {
        type,
        header,
        getData: alertSwitch,
        searchValue: "",
      };
      if (activeUserId) {
        payload.userId = activeUserId;
        alertsPayload.userId = activeUserId;
      }
      header === "Alerts"
        ? dispatch(
            getMonitoringAlertDetails(
              { ...alertsPayload, ...pagination },
              (res) => {}
            )
          )
        : dispatch(getMonitoringAnalyticsDetails({ ...payload }, () => {}));
    }
  };

  const onSearchDetails = (searchVal) => {
    if (isUnderObservation) {
      dispatch(getAssetsObservation({ searchValue: searchVal }));
    } else {
      const payload = {
        type,
        header,
        searchValue: searchVal,
      };
      const alertsPayload = {
        type,
        header,
        getData: alertSwitch,
        searchValue: searchVal,
      };
      if (activeUserId) {
        payload.userId = activeUserId;
        alertsPayload.userId = activeUserId;
      }
      header === "Alerts"
        ? dispatch(getMonitoringAlertDetails({ ...alertsPayload }, () => {}))
        : dispatch(getMonitoringAnalyticsDetails({ ...payload }, () => {}));
    }
  };

  const onClearSearch = () => {
    setSearchValue("");
    setTempSearch("");
    fetchAlerts();
  };

  if (!sideToOpen) return null;
  const isAlertsData = alerts && !!alerts[0]?.alerts;

  const addComment = (item, index) => {
    if (!comment) {
      // showToasts({ success: false, message: "Please add a comment!" });
      showToast("Please add a comment!", true);
    } else {
      uploadComment({ item, comment, index });
      setComment("");
    }
  };
  const handleExcelDownload = () => {
    if (!alerts.length) return;
    const excelData = alerts.map((item) => {
      const parsedData = parseDetail(item);
      return Object.keys(parsedData).reduce((row, key) => {
        row[key] = parsedData[key]?.value;
        return row;
      }, {});
    });

    if (excelData.length > 0) {
      const headers = Object.keys(excelData[0]);
      const sheetData = [
        headers,
        ...excelData.map((item) => headers.map((header) => item[header])),
      ];
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `${name?.split(" ").join("_")}_${new Date().getTime()}.xlsx`
      );
    }
  };
  const uploadComment = (data) => {
    dispatch(
      generateAlertComment(
        {
          alertType: name,
          comment: data.comment,
          tripId: data.item.tripId,
          deviceID: data.item.alerts.deviceID,
        },
        (res) => {
          if (res) {
            let nextIsDropDown = { ...isDropDown };
            delete nextIsDropDown[`${data.index}addComment`];
            setIsDropDown(nextIsDropDown);
          }
        }
      )
    );
  };

  const getComment = (data) => {
    // showLoader(true);
    dispatch(
      getAlertComments({
        tripId: data.tripId,
        deviceID: data.alerts.deviceID,
      })
    );
  };
  const handleLineDropdown = (e, index, id) => {
    const target =
      e.target.closest(".addComment")?.classList[0] ||
      e.target.closest(".viewComments")?.classList[0];

    const modId = index + target;

    const tempIsDropDown = { ...isDropDown };
    // console.log({ modId }, tempIsDropDown[modId]);

    if (target === "addComment") {
      delete tempIsDropDown[`${index}viewComments`];
    } else {
      if (!tempIsDropDown[`${index}viewComments`]) {
        getComment(alerts[index]);
      }
      delete tempIsDropDown[`${index}addComment`];
    }

    // setIsDropDown({
    // 	...tempIsDropDown,
    // 	[modId]: tempIsDropDown[modId] ? "" : modId,
    // });
    setIsDropDown({
      [modId]: tempIsDropDown[modId] ? "" : modId,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setComment(value);
  };
  const handleAddToObservation = (alert, callbackFn) => {
    if (alert) {
      const deviceId = alert?.asset_id;
      const clientId = alert?.client_id;
      const payload = { deviceId: deviceId, clientId: clientId, type: header };
      if (header === "Alerts") {
        payload.createdAlertTime = alert?.createdAlertTime;
        payload.alertType = type;
      }
      dispatch(
        addDeviceToObservation({ ...payload }, (res) => {
          if (res && res?.success) {
            callbackFn();
            fetchAlerts();
          }
        })
      );
    }
  };
  const handleRemoveFromObservation = (alert, callbackFn) => {
    if (alert) {
      const deviceId = alert?.asset_id;
      const clientId = alert?.client_id;
      const payload = { deviceId: deviceId, clientId: clientId, type: header };
      if (header === "Alerts") {
        payload.createdAlertTime = alert?.createdAlertTime;
        payload.alertType = type;
      }
      dispatch(
        removeDeviceFromObservation({ ...payload }, (res) => {
          if (res && res?.success) {
            callbackFn();
            fetchAlerts();
          }
        })
      );
    }
  };
  const handleResolveAlert = (alert, callbackFn) => {
    if (alert) {
      const payload = {
        createdAlertTime: alert?.createdAlertTime,
        alertType: type,
        deviceId: alert?.client_id,
      };
      dispatch(
        resolveAlert({ ...payload }, (res) => {
          if (res && res?.success) {
            callbackFn();
            fetchAlerts();
          }
        })
      );
    }
  };
  const parseDetail = (data, index = -1, rowRef) => {
    const result = {};
    const {
      adminName,
      deviceType,
      client_id,
      battery_voltage,
      lockStatus,
      vehicleNo,
      speed,
      tripStatus,
      route_name,
      tripId,
      packet_time,
      latitude,
      longitude,
      geo_name,
      ignition_status,
      createdBy,
    } = data || {};

    const lock_status = data?.lock_status ?? "";
    const alertTime = data?.alertTime ?? "";

    let device_type_label = parse_device_label(deviceType ?? "", data);
    let lock_status_label = parse_lock_status(lockStatus);
    let location;
    let deviceConnectionStatus;

    if (deviceType === "fixedELock" || deviceType === "fixedTracker") {
      deviceConnectionStatus =
        lock_status === "locked"
          ? "External Battery Connected"
          : "External Battery Disconnected";
    } else if (deviceType === "assetTracker") {
      deviceConnectionStatus =
        lock_status === "locked" ? "Attached" : "Deattached";
    }

    if (latitude && longitude) {
      location = [Number(longitude), Number(latitude)];
    }
    if (header === "Alerts") {
      if (createdBy) {
        result["Created By"] = {
          value: createdBy ?? "-",
        };
      }
    } else {
      if (adminName) {
        result["Admin Name"] = {
          value: adminName ?? "-",
        };
      }
    }
    if (device_type_label) {
      result["Device Type"] = {
        value: device_type_label ?? "-",
      };
    }
    if (client_id) {
      result["Device Id"] = {
        value: client_id ?? "-",
        onClick: () => history.push(`/dashboard/devices/${client_id}/map`),
      };
    }
    if (vehicleNo) {
      result["Vehicle No."] = {
        value: vehicleNo ?? "-",
      };
    }
    if (speed) {
      result["Speed"] = {
        value: speed ? `${(Number(speed) / 1000)?.toFixed(2)}km/h` : "-",
      };
    }
    if (tripStatus) {
      result["Trip Status"] = {
        value: tripStatus ?? "-",
      };
    }
    if (route_name) {
      result["Route"] = {
        value: route_name ?? "-",
      };
    }
    if (tripId) {
      result["Trip Id"] = {
        value: tripId ?? "-",
      };
    }
    if (packet_time) {
      result["Packet Time"] = {
        value:
          packet_time
            ? convertIntoGivenTimezone(
                new Date(Number(packet_time * 1000)),
                currentUserDetail?.timeZone?.offset
              )
            : "-",
      };
    }
    result["Location"] = {
      value:
        location && location.length === 2
          ? !location[0] || !location[1]
            ? "-"
            : `${location[1].toFixed(6)}, ${location[0].toFixed(6)}`
          : "-",
      onClick: () => fetchAddress(location, index),
      id: `location${index}`,
    };
    if (geo_name) {
      result["Geofence"] = {
        value: geo_name ?? "-",
      };
    }
    if (ignition_status) {
      result["Status"] = {
        value: ignition_status ?? "-",
      };
    }

    if (deviceType !== "assetTracker" && deviceType !== "fixedTracker") {
      result["Lock Status"] = {
        value: lock_status_label ?? "-",
      };
    }

    if (
      deviceType === "fixedELock" ||
      deviceType === "assetTracker" ||
      deviceType === "fixedTracker"
    ) {
      result["Device Connection Status"] = {
        value: deviceConnectionStatus ?? "-",
      };
    }

    if (deviceType !== "fixedTracker" && deviceType !== "fixedELock") {
      result["Battery %"] = {
        value: battery_voltage
          ? `${Number(battery_voltage)?.toFixed(2)}%`
          : "-",
      };
    }

    if (alertTime) {
      result["Alert Time"] = {
        value: alertTime
          ? convertIntoGivenTimezone(
              alertTime,
              currentUserDetail?.timeZone?.offset
            )
          : "-",
      };
    }
    return result;
  };
  const fetchAddress = (location, index) => {
    setLatLng({
      lat: location[1],
      lng: location[0],
    });
    const payload = {
      latitude: location[1],
      longitude: location[0],
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (document.getElementById(`location${index}`)) {
            document.getElementById(`location${index}`).innerHTML = address;
          }
        }
      })
    );
  };
  function getRowHeight(index) {
    return rowHeights.current[index] + 20 || 340;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  const resetListAfterIndex = (index) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  };
  const handleInitialRender = () => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  };
  function Row({ index, style = {} }) {
    const rowRef = useRef({});
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current?.clientHeight);
      }
    }, [rowRef]);

    const tempAlerts = alerts && alerts?.length > 0 ? alerts[index] : {};
    const unique_id = tempAlerts
      ? `${tempAlerts?._id}__${tempAlerts?.createdAt}`
      : "";

    return (
      <div style={style}>
        <div
          className="detail_panel__card"
          key={index}
          ref={rowRef}
          aria-label="Detail Card"
        >
          <DetailCard
            alert={alerts[index] ? alerts[index] : {}}
            index={index}
            fetchAlerts={fetchAlerts}
            parseDetail={parseDetail}
            themeType={themeType}
            isDark={isDarkThemeEnabledSelector}
            handleAddToObservation={handleAddToObservation}
            handleRemoveFromObservation={handleRemoveFromObservation}
            observationDevices={isUnderObservation}
            rowRef={rowRef}
          >
            {header === "Alerts" && (
              <Box>
                <Box display="flex" justifyContent="center">
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "50px",
                      height: "20px",
                      mt: 0.6,
                    }}
                    onClick={() => {
                      toast.warn(
                        confirmToast({
                          message: `Are you sure you want to resolve alert for ${alerts[index]?.client_id}?`,
                          callback: (closeToastFn) => {
                            if (alerts[index]?.client_id) {
                              handleResolveAlert(alerts[index], closeToastFn);
                            }
                          },
                        }),
                        {
                          position: "bottom-center",
                          closeButton: false,
                          icon: false,
                          className: "confirm-toast-message",
                          bodyStyle: { padding: "0px" },
                          style: {
                            borderColor: themeType.default.themeGray,
                          },
                        }
                      );
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color: isDarkThemeEnabledSelector ? "#000" : "#fff",
                      }}
                    >
                      Resolve
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}

            {/* {isAlertsData && header === "Alerts" && (
              <div className="commentSection__input-wrapper">
                prettier-ignore 
                <div
                  className="viewComments pRel"
                  onClick={(e) =>handleLineDropdown(e, index, unique_id)}
                >
                  <OpenCloseArrow isOpen={isDropDown[`${index}viewComments`]}/>
                  <div className="viewComments__heading">View Comments</div>
                  <Collapse isOpen={!!isDropDown[ `${index}viewComments`]} id="viewCollapse">
                    <div className="comments-wrapper" onClick={(e) =>e.stopPropagation()}>
                      {comments.map(
                        (item, ind) => {
                          return (
                          <Comment isLast={ ind === comments.length - 1 } commentData={ item}/>
                          );
                        }
                      )}
                    </div>
                  </Collapse>
                </div>
                prettier-ignore
                <div
                  className="addComment pRel"
                  onClick={(e) =>handleLineDropdown(e, index, unique_id 	)}
                >
                  <OpenCloseArrow isOpen={isDropDown[`${index}addComment`]}/>
                  
                  <div className="addComment__heading">Add Comment</div>
                  <Collapse isOpen={!!isDropDown[`${index}addComment`]}>
                    <div className="commentBox-wrapper pRel d-flex fCol aItemsEnd gap-one" onClick={(e) =>e.stopPropagation()}>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        rows="5"
                        value={comment}
                        onChange={handleInputChange}
                        placeholder="Add comment..."
                      />
                      <button className="btn-addComment" onClick={(e) =>addComment(alert, index)}>
                        Add 
                      </button>
                    </div>
                  </Collapse>
                </div>
                <div className="d-flex justify-center mt-4">
                  <button
                    className="btn-resolveIssue"
                    onClick={(e) => resolveIssue(alert)}
                  >
                    Resolve Issue
                  </button>
                </div>
              </div>
            )} */}
          </DetailCard>
        </div>
      </div>
    );
  }
  function RowForPagination({ index }) {
    const tempAlerts = alerts && alerts?.length > 0 ? alerts[index] : {};
    const unique_id = tempAlerts
      ? `${tempAlerts?._id}__${tempAlerts?.createdAt}`
      : "";

    return (
      <div style={{ marginBottom: "10px" }}>
        <div
          className="detail_panel__card"
          key={index}
          aria-label="Detail Card"
        >
          <DetailCard
            alert={alerts[index] ? alerts[index] : {}}
            index={index}
            fetchAlerts={fetchAlerts}
            parseDetail={parseDetail}
            themeType={themeType}
            isDark={isDarkThemeEnabledSelector}
            handleAddToObservation={handleAddToObservation}
            handleRemoveFromObservation={handleRemoveFromObservation}
            observationDevices={isUnderObservation}
          >
            {header === "Alerts" && (
              <Box>
                <Box display="flex" justifyContent="center" gap={0.5}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "50px",
                      height: "20px",
                      mt: 0.6,
                    }}
                    onClick={() => {
                      toast.warn(
                        confirmToast({
                          message: `Are you sure you want to resolve alert for ${alerts[index]?.client_id}?`,
                          callback: (closeToastFn) => {
                            if (alerts[index]?.client_id) {
                              handleResolveAlert(alerts[index], closeToastFn);
                            }
                          },
                        }),
                        {
                          position: "bottom-center",
                          closeButton: false,
                          icon: false,
                          className: "confirm-toast-message",
                          bodyStyle: { padding: "0px" },
                          style: {
                            borderColor: themeType.default.themeGray,
                          },
                        }
                      );
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color: isDarkThemeEnabledSelector ? "#000" : "#fff",
                      }}
                    >
                      Resolve
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}

            {/* {isAlertsData && header === "Alerts" && (
              <div className="commentSection__input-wrapper">
                prettier-ignore 
                <div
                  className="viewComments pRel"
                  onClick={(e) =>handleLineDropdown(e, index, unique_id)}
                >
                  <OpenCloseArrow isOpen={isDropDown[`${index}viewComments`]}/>
                  <div className="viewComments__heading">View Comments</div>
                  <Collapse isOpen={!!isDropDown[ `${index}viewComments`]} id="viewCollapse">
                    <div className="comments-wrapper" onClick={(e) =>e.stopPropagation()}>
                      {comments.map(
                        (item, ind) => {
                          return (
                          <Comment isLast={ ind === comments.length - 1 } commentData={ item}/>
                          );
                        }
                      )}
                    </div>
                  </Collapse>
                </div>
                prettier-ignore
                <div
                  className="addComment pRel"
                  onClick={(e) =>handleLineDropdown(e, index, unique_id 	)}
                >
                  <OpenCloseArrow isOpen={isDropDown[`${index}addComment`]}/>
                  
                  <div className="addComment__heading">Add Comment</div>
                  <Collapse isOpen={!!isDropDown[`${index}addComment`]}>
                    <div className="commentBox-wrapper pRel d-flex fCol aItemsEnd gap-one" onClick={(e) =>e.stopPropagation()}>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        rows="5"
                        value={comment}
                        onChange={handleInputChange}
                        placeholder="Add comment..."
                      />
                      <button className="btn-addComment" onClick={(e) =>addComment(alert, index)}>
                        Add 
                      </button>
                    </div>
                  </Collapse>
                </div>
                <div className="d-flex justify-center mt-4">
                  <button
                    className="btn-resolveIssue"
                    onClick={(e) => resolveIssue(alert)}
                  >
                    Resolve Issue
                  </button>
                </div>
              </div>
            )} */}
          </DetailCard>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="detail_panel"
        aria-label="Detail Panel"
        style={{ position: "relative" }}
      >
        <div className="detail_panel__header">
          <div className="detail_panel__header__left">
            <span
              className="detail_panel__header__left__text"
              style={{ borderColor }}
              aria-label="Device Name"
            >
              {detailLoading ? <Skeleton variant="text" width="120px" /> : name}
            </span>
            {detailLoading ? (
              <Skeleton variant="rounded" width="35px" height="32px" />
            ) : (
              <Typography
                className="detail_panel__header__left__value"
                style={{ backgroundColor: borderColor, color: "#ffffff" }}
                aria-label="Device Count"
              >
                {header && header === "Alerts"
                  ? alertCount && alertCount[type]
                    ? alertCount[type]?.toLocaleString("en-US")
                    : 0
                  : count && count?.toLocaleString("en-US")}
              </Typography>
            )}
          </div>
          <div className="detail_panel__header__right">
            <Tooltip placement="bottom" arrow title="Download Excel">
              <span>
                <IconButton color="primary" onClick={handleExcelDownload}>
                  <FileDownload />
                </IconButton>
              </span>
            </Tooltip>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <Box px={2} pr={3}>
          <CustomInput
            label="Search..."
            placeholder="Search..."
            value={tempSearch}
            onChange={(e) => {
              if (e?.target?.value === "") {
                onClearSearch();
              }
              setTempSearch(e?.target?.value);
            }}
            selectStylesOverride={selectStylesOverride(
              isDarkThemeEnabledSelector
            )}
            inputClasses={inputClasses}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box display="flex" gap={0.5}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchValue(tempSearch);
                        onSearchDetails(tempSearch);
                      }}
                    >
                      <Search />
                    </IconButton>
                    {searchValue && (
                      <IconButton size="small" onClick={() => onClearSearch()}>
                        <Close />
                      </IconButton>
                    )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <div className="detail_panel__search_wrap">
          {/* <SearchBar
						placeholder={`Search by Device ID | Vehicle No ${
							dataType === "device" ? "" : "| Trip ID"
						}`}
						ariaLabel="search input"
						onSubmit={onSearch}
						onInputClear={onSearchClear}
					/> */}
        </div>
        {header && header === "Alerts" && (
          <Box display="flex" justifyContent="center" mx={2} my={1}>
            <AlertSwitch
              themeType={themeType}
              alertSwitch={alertSwitch}
              setAlertSwitch={setAlertSwitch}
              handleChange={(alertValue) => {
                const payload = { type, header, getData: alertValue };
                if (currentUserDetail?.adminSpecificPermissions) {
                  payload.userId = activeUserId || "";
                }
                dispatch(
                  getMonitoringAlertDetails(
                    { ...payload, page: 1, limit: 10 },
                    () => {}
                  )
                );
              }}
            />
          </Box>
        )}
        <div
          className="detail_panel__body"
          style={{ paddingBottom: header === "Alerts" ? "100px" : "0px" }}
        >
          {detailLoading ? (
            <Box display="flex" flexDirection="column" gap={1.5} mx={2}>
              <Skeleton variant="rounded" width="100%" height="200px" />
              <Skeleton variant="rounded" width="100%" height="200px" />
              <Skeleton variant="rounded" width="100%" height="200px" />
            </Box>
          ) : (
            <>
              {alerts && alerts?.length ? (
                <>
                  {header === "Alerts" ? (
                    <>
                      {alerts?.map((alert, i) => {
                        return <RowForPagination index={i} key={1} />;
                      })}
                    </>
                  ) : (
                    <AutoSizer>
                      {({ height, width }) => {
                        return (
                          <VariableSizeList
                            height={height}
                            width={width}
                            itemCount={alerts?.length || 0}
                            itemSize={getRowHeight}
                            ref={listRef}
                          >
                            {Row}
                          </VariableSizeList>
                        );
                      }}
                    </AutoSizer>
                  )}
                </>
              ) : (
                <div className="text-center mt-3">No Data Found</div>
              )}
            </>
          )}
        </div>
        {header === "Alerts" && alerts && alerts?.length && (
          <Box
            position="absolute"
            bottom={0}
            left={0}
            width="100%"
            height="auto"
          >
            <TablePagination
              component="div"
              count={monitoringAlertDetailsCount}
              page={pagination?.page - 1}
              labelRowsPerPage="Rows:"
              onPageChange={(e, newPage) => {
                setPagination((v) => ({ ...v, page: newPage + 1 }));
              }}
              rowsPerPage={pagination?.limit}
              onRowsPerPageChange={(e) => {
                setPagination((v) => ({
                  ...v,
                  page: 1,
                  limit: e.target.value,
                }));
              }}
              sx={{
                ...tableClasses.tableHeader,
                ...tableClasses.tablePagination,
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "0px",
                alignItems: "center",
                mt: "0em",
                "& .MuiTablePagination-selectLabel ": {
                  margin: "0",
                },
                "& .MuiTablePagination-displayedRows": {
                  margin: "0",
                },
                width: "100%",
              }}
            />
          </Box>
        )}
      </div>
    </>
  );
}

export function DetailCard({
  index,
  themeType,
  isDark,
  alert,
  fetchAlerts,
  parseDetail,
  handleRemoveFromObservation,
  handleAddToObservation,
  children,
  observationDevices,
  rowRef,
}) {
  const dispatch = useDispatch();
  const dataToRender = parseDetail(
    observationDevices ? alert?.deviceData : alert,
    index,
    rowRef
  );

  const deviceID = observationDevices
    ? alert?.deviceData?.client_id
    : alert?.client_id;

  const isUnderObservation = observationDevices
    ? true
    : alert?.isUnderObservation;

  function fetchAlertsOnSucess(res) {
    if (res.payload.success) {
      fetchAlerts();
    }
  }
  const handleStarMark = () => {
    toast.warn(
      confirmToast({
        message: `Are you sure you want to ${
          isUnderObservation ? "remove" : "keep"
        } this device - ${deviceID} ${
          isUnderObservation ? "from" : "under"
        } observation?`,
        callback: (closeToastFn) => {
          if (deviceID) {
            if (isUnderObservation) {
              handleRemoveFromObservation(
                observationDevices ? alert?.deviceData : alert,
                closeToastFn
              );
            } else {
              handleAddToObservation(
                observationDevices ? alert?.deviceData : alert,
                closeToastFn
              );
            }
          }
        },
      }),
      {
        position: "bottom-center",
        closeButton: false,
        icon: false,
        className: "confirm-toast-message",
        bodyStyle: { padding: "0px" },
        style: {
          borderColor: themeType.default.themeGray,
        },
      }
    );
  };

  const content = Object.keys(dataToRender)?.map((key, index) => {
    const item = dataToRender[key];
    return (
      <div className="detail_panel__row" key={index}>
        <span className="detail_panel__col ">{key}</span>
        <span
          className={`detail_panel__col ${item.onClick ? "highlight" : ""}`}
          id={item.id}
          onClick={item.onClick}
        >
          {item.value}
        </span>
      </div>
    );
  });
  return (
    <>
      <div
        key={index + "device"}
        id={index + "device"}
        data-testid="detail_panel_info"
      >
        <span
          data-testid="star-mark"
          className="star_mark"
          onClick={() => handleStarMark()}
        >
          <Star isFill={isUnderObservation} />
        </span>
        {content}
      </div>
      {children}
    </>
  );
}

export const AlertSwitch = ({
  themeType,
  alertSwitch,
  setAlertSwitch,
  handleChange,
}) => {
  return (
    <div className="alert_switch">
      <button
        style={{
          fontSize: "14px",
          padding: "2px 5px",
          border: `1px solid #f57c00`,
          borderTopLeftRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          backgroundColor:
            alertSwitch === "trip"
              ? themeType?.default?.themeOrange
              : "transparent",
          color:
            alertSwitch === "trip"
              ? themeType?.default?.themeGray
              : themeType?.default?.themeOrange,
        }}
        onClick={() => {
          setAlertSwitch("trip");
          handleChange("trip");
        }}
        disabled={alertSwitch === "trip"}
      >
        {/* <img src={AlerWithTripIcon} alt="" /> */}
        Alerts With Trip
      </button>
      <button
        style={{
          fontSize: "14px",
          padding: "2px 5px",
          border: `1px solid #f57c00`,
          borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
          backgroundColor:
            alertSwitch === "withoutTrip"
              ? themeType?.default?.themeOrange
              : "transparent",
          color:
            alertSwitch === "withoutTrip"
              ? themeType?.default?.themeGray
              : themeType?.default?.themeOrange,
        }}
        onClick={() => {
          setAlertSwitch("withoutTrip");
          handleChange("withoutTrip");
        }}
        disabled={alertSwitch === "withoutTrip"}
      >
        {/* <img src={AlerWithoutTripIcon} alt="" /> */}
        Alerts Without Trip
      </button>
    </div>
  );
};

function Comment({ commentData, isLast }) {
  return (
    <>
      <div className="comment" id="comment" data-is-last={isLast}>
        <div className="comment__datetime">
          <div className="comment__date">
            {moment(commentData.createdAt).format("DD-MM-YYYY ")}
          </div>
          <div className="comment__time">
            {moment(commentData.createdAt).format("hh:mm:ss a")}
          </div>
        </div>

        {/* <div className="comment__line pRel"></div> */}

        <div className={`comment__text ${isLast && "last"}`}>
          {commentData.comment}
          {commentData.userName && (
            <div className="comment__time" style={{ color: "#ff9900" }}>
              ({commentData.userName})
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function OpenCloseArrow({ isOpen }) {
  return isOpen ? (
    <span className="commentSection__dropdown-icon-wrapper">
      <BsFillCaretUpFill className="icons commentSection__dropdown-icon" />
    </span>
  ) : (
    <span className="commentSection__dropdown-icon-wrapper">
      <BsFillCaretDownFill className="icons commentSection__dropdown-icon" />
    </span>
  );
}
function Star({ isFill }) {
  return isFill ? (
    <AiFillStar className="star_mark_icon fill" />
  ) : (
    <AiOutlineStar className="star_mark_icon" />
  );
}
function redirect_to(page = "", id = "") {
  // console.log({page, id})
  const lynktrac_map = {
    trip: "tripmap",
    device: "devicemap",
  };
  var base_url =
    process.env.REACT_APP_ENV === "prod"
      ? "https://lynktrac.io"
      : "https://beta.lynktrac.io";
  let url = `${base_url}/${lynktrac_map[page]}/${id}`;
  // console.log({url})
  window.open(url, "_blank");
}
function parse_device_label(device_type, item) {
  let device_type_label = "";
  switch (device_type) {
    case "assetTracker":
      device_type_label = "Asset Tracker";
      break;
    case "bLock":
      device_type_label = "Block";
      break;
    case "camera":
      device_type_label = "Camera";
      break;
    case "elock":
      device_type_label = "E-Lock";
      break;
    case "fixedTracker":
      if (item.iButton && !item.fuelSensor) {
        device_type_label = "Fixed Tracker (Forklift)";
      } else if (!item.iButton && item.fuelSensor) {
        device_type_label = "Fixed Tracker (Fuelsensor)";
      } else {
        device_type_label = "Fixed Tracker";
      }
      break;
    case "fixedELock":
      device_type_label = "Fixed E-Lock";
      break;
    case "simTracking":
      device_type_label = "Sim Tracking";
      break;
    default:
      device_type_label = device_type;
      break;
  }
  return device_type_label;
}
function parse_lock_status(status) {
  if (!status) return;
  let label = "";
  switch (status) {
    case "locked":
      label = "Locked";
      break;
    case "unlocked":
      label = "Unlocked";
      break;
    case "motorUnlocked":
      label = "Motor Unlocked";
      break;
    case "tampered":
      label = "Tampered";
      break;

    default:
      label = "-";
      break;
  }
  return label;
}
