import { FiberManualRecordTwoTone, Lock } from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { fetchAddressFromCoords } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { convertIntoGivenTimezone } from "../../../../services/functions";
const EventSummaryTimeline = ({ themeType, tripEventsDetails, myMap, infoWindow, newInfoWindow, setInfoWindow, tripDetails,idParam , userDetails}) => {
  const { inputClasses, buttonClasses, textClasses } = themeType;
  const dispatch = useDispatch()
  const [eventDataState, setEventData] = React.useState(null);

  useEffect(() => {
    if (Object.keys(tripEventsDetails).length) {
      setEventData(tripEventsDetails)
    }
  }, [tripEventsDetails])

  const getAddressFromCoords = (item, i) => {
    let temp = [...tripEventsDetails?.eventData]
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (temp[i]) {
            temp[i] = { ...(temp[i]), address: address };
          }
          const updatedData = {
            ...tripEventsDetails,
            eventData: temp,
          };
          setEventData(updatedData);
        }
      })
    );
  }
  // const openInfoWindow = (item) => {
  //   const infoWindowContent = `
  //       <div>
  //           <h3>${item.geo_name}</h3>
  //           <p>Event: ${item.event}</p>
  //           <p>Time: ${item.time}</p>
  //       </div>
  //   `;

  // let position = {
  //   lat: Number(item.lat), lng: Number(item.lng) 
  // }

  //   myMap.panTo(position);

  //   if (infoWindow) {
  //     infoWindow.close();
  //   }

  //   newInfoWindow.setContent(infoWindowContent);
  //   newInfoWindow.setPosition(position);
  //   newInfoWindow.open(myMap);
  //   setInfoWindow(newInfoWindow);
  // }

  // const openInfoWindow = (item) => {

  //   myMap.data.addListener('click', async (event) => {
  //     // let markerObj = {
  //     //   // deviceId: idParam,
  //     //   packet_id: event.feature.getProperty('packet_id'),
  //     //   // status: tripDetails?.tripData?.tripDetails?.status || '',
  //     //   eventAfterTampered: event.feature.getProperty("icon") === 'assets/img/trackingicons/maps_red.png' ? true : false,
  //     //   icon: event.feature.getProperty("icon"),
  //     //   // gps: tripDetails?.tripData?.sealingDetails?.gps || '',
  //     //   // offlineLimit: tripDetails?.tripData?.limitThreshold?.offlineLimit || ''
  //     // }

  //     // await axios.post(`${URLFORTRIPMAP}/getTripMapPacketData`, { ...markerObj }, {
  //     //   headers: {
  //     //     Authorization: JSON.parse(localStorage.getItem("token"))
  //     //   }
  //     // })
  //     //   .then(response => {

  //     //     let data = response?.data?.trackData
  //     //     const lockStatus = data?.lockStatus || '-';
  //     //     const batteryPercent = data?.battery + '%' || '-';
  //     //     const tripStatus = data?.trip_status || '-';
  //     //     const speedVal = data?.speed.toFixed(2) + ' kmph' || '-';
  //     //     const distanceVal = data?.distance || '-';
  //     //     const time = convertIntoGivenTimezone(data?.time, userDetails.timeZone.offset) || '-';
  //     //     const tripEventStatus = data?.event || null
  //     //     const color = data?.color === 'yellow' ? '#FFAA1D' : data?.color
  //     //     const backgroundColor = bgColor(data)
  //     //     const payload = {
  //     //       latitude: data?.latitude,
  //     //       longitude: data?.longitude,
  //     //       appType: "GOOGLE",
  //     //     };

  //     //     axios.post(`${locationUrl}fetchAddress`, payload, {
  //     //       headers: {
  //     //         "Content-Type": "application/json",
  //     //         authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
  //     //       },
  //     //     })
  //     //       .then(response => {
  //     //         let address = response?.data?.data[0]?.address
  //     //         let infoWindowContent = `<div class='infoDiv'>
  //     //       <div class='infoChild1'>
            
            
  //     //       ${tripEventStatus !== '-' ?
  //     //             `<div class='subChild' style="background-color: ${color}" >
  //     //             <img class='lockImg'  src=${truck} alt='' />
  //     //             <span class='deviceLabel'>Trip Event </span>
  //     //             <span class='devicevalue'>${tripEventStatus}</span>
  //     //         </div>`
  //     //             : ''
  //     //           }

  //     //       <div class='subChild' style="background-color: ${backgroundColor?.color}">
  //     //         <img class='lockImg'  src=${lockStatus === 'motorUnlocked' ? unlock : lock} alt='' />
  //     //         <span class='deviceLabel'>Device Status </span>
  //     //         <span class='devicevalue'>${lockStatus === 'motorUnlocked' ? 'Motor Unlocked' : capitalizeSentence(lockStatus)}</span>
  //     //       </div>     
  //     //       <div class='subChild bgblue'>
  //     //           <img class='lockImg'  src=${tag} alt='' />
  //     //           <span class='deviceLabel'> Device Id </span>
  //     //           <span class='devicevalue'> ${idParam}</span>
  //     //       </div>
  //     //     </div>
  //     //     <div class='infoChild2'>

  //     //     <div class='subChild2 '>
  //     //     <img class='imgCss'  src=${battery} alt='' />
  //     //     <span class='infoLabel label2'>Battery </span>
  //     //     <span class='infoValue infoValue2'>${batteryPercent}</span>
  //     //       </div>

    
  //     //       <div class='subChild2 '>
  //     //         <img class='imgCss'  src=${routeIcon} alt='' />
  //     //         <span class='infoLabel label2'>Trip Status</span>
  //     //         <span class='infoValue infoValue2'>${tripStatus}</span>
  //     //       </div>
    
  //     //         <div class='subChild2'>
  //     //           <img class='imgCss'  src=${speed} alt='' />
  //     //           <span class='infoLabel label2'>Speed </span>
  //     //           <span class='infoValue infoValue2'>${speedVal}</span>
  //     //         </div>
    
  //     //       <div class='subChild2'>
  //     //           <img class='imgCss'  src=${distance} alt='' />
  //     //           <span class='infoLabel label2'>Distance </span>
  //     //           <span class='infoValue infoValue2'>${distanceVal}</span>
  //     //       </div>
    
  //     //       <div class='subChild2'>
  //     //         <img class='imgCss'  src=${dateTime} alt='' />
  //     //         <span class='infoLabel label2'>Date & Time</span>
  //     //         <span class='infoValue infoValue2'>${time}</span>
  //     //       </div>
  //     //     </div>
    
  //     //     <div class='infoChild3'>
  //     //       <div class='addressDiv'>
  //     //         <div class='addressImg'>
  //     //         <img class='imgCss' src=${location} alt='' />
  //     //         </div>
  //     //         <div class='addressBox'>
  //     //         <span class='infoLabel'>${address}</span>
  //     //         </div>
  //     //       </div>
            
  //     //     </div>
  //     //     </div>
  //     //   `     
  //     //         let temp = newInfoWindow

  //     //         if (infoWindow) {
  //     //           infoWindow.close();
  //     //         }

  //     //         temp.setContent(infoWindowContent);
  //     //         temp.setPosition(event.latLng);
  //     //         temp.open(myMap);
  //     //         setInfoWindow(temp);
  //     //         setNewInfoWindow(temp)
  //     //       })
  //     //       .catch(error => {
  //     //         console.error('Error:', error);
  //     //       });
  //     //   }
  //     //   )
  //     //   .catch(error => {
  //     //     console.error('Error creating data:', error);
  //     //   });


  //   });

  // }



  return (
    <>
      {/* {eventDataState !== null && eventDataState !== undefined && Object.keys(eventDataState).length ? */}
      <Box p={1} display="flex" flexDirection="column">
        {eventDataState?.eventData?.map((item, i) => {
          return (
            <Box index={i} sx={{ display: "flex", gap: "4px" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <FiberManualRecordTwoTone sx={{ color: "#FF7200" }} />
                <div
                  style={{ background: "#FF7200", width: "2px", height: "100%" }}
                />
              </Box>
              <Box
                pt="3px"
                pb="6px"
                display="flex"
                gap="3px"
                flexDirection="column"
              >
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    fontWeight: 700,
                    color: themeType.default.themeOrange,
                    cursor: 'pointer'
                  }}
                  // onClick={() => openInfoWindow(item)}
                >
                  {item?.event || '-'}
                </Typography>
                {item?.geo_name ?
                  <Typography
                    sx={{ ...textClasses.normalText, fontSize: "13.5px" }}
                  >
                    {item?.geo_name === 'nil' ? 'Out of geofence' : item?.geo_name || '-'}
                  </Typography>
                  : null}
                <Typography sx={{ ...textClasses.normalText, fontSize: "11px" }}>
                  {convertIntoGivenTimezone(item?.date, userDetails.timeZone.offset) || '-'}

                </Typography>
                {!item?.address ? (
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      fontSize: "13.5px",
                      color: themeType.default.themeOrange,
                      cursor: "pointer"
                    }}
                    onClick={() => getAddressFromCoords(item, i)}
                  >
                    Get Address
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        fontSize: "13.5px",
                        color: themeType.default.themeGreen,
                        cursor: "pointer"
                      }}
                    >
                      {item?.address || ''}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* :
        <Typography>No Event Data.</Typography>
       
      } */}
    </>
  );
};

export default EventSummaryTimeline;
{/* <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
          <ThreeDotsLoader />
        </Grid> */}