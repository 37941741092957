import {
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Typography,
  FormControl,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStylesOverride } from "../../../../utils/util";
import { useForm, Controller } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { Cancel } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RouteSection = ({
  _handleSelect,
  handleRemoveSelection,
  routesListing,
  selectedRoute,
  showAllRoute,
  setShowAllRoute,
  routeSize,
  routeRadio,
  searchFields,
  setSearchFields,
  handleSearchRoute,
  setRouteRadio,
  control,
  errors,
  handleSubmit,
  onSubmit,
  setValue,
  title="Assign Route(s)"
}) => {
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;
  return (
    <Grid container>
      <Grid item sm={12} mt={2} ml={0.2}>
        <Accordion disableGutters={true}>
          <AccordionSummary
            sx={{ background: themeType.default.themeOrange }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ ...textClasses.boldTextBlack }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              User can be able to view the activities relates with the assigned
              route. All trips linked with the assigned route will be monitor by
              the user.
            </Typography>
            <Grid
              container
              borderRadius={2}
              border="1px solid #E0E0E0"
              my={0}
              mt={2}
              p={2}
              sx={{ width: "100%" }}
            >
              <Grid item container spacing={2} mb={0} md={12}>
                <Grid item md={4}>
                  <Controller
                    name="radio-buttons-group-route"
                    control={control}
                    // rules={{
                    //     required: {
                    //         value: true,
                    //         message: "Choose 1 radio option.",
                    //     },
                    // }}
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group-route"
                        defaultValue={routeRadio}
                        {...field}
                        // required
                        // errors={errors}
                      >
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          checked={routeRadio === "None"}
                          value="None"
                          label="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          checked={routeRadio === "All"}
                          value="All"
                          label="All"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          checked={routeRadio === "select"}
                          value="select"
                          label="Select Route(s)"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors["radio-buttons-group-route"] && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors["radio-buttons-group-route"].message}
                    </p>
                  )}
                </Grid>
                {routeRadio === "select" ? (
                  <Grid item md={4} mt={"auto"}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                    >
                      <Controller
                        name="routes"
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomMultiSelect
                              selectStylesOverride={selectStylesOverride}
                              value={value}
                              onChange={(e, newVal) => {
                                let idSet = newVal?.map((_) => ({
                                  id: _?._id,
                                  name: _?.routeName,
                                }));
                                onChange(idSet);
                                setValue("routes", idSet);
                              }}
                              name={name}
                              label="Route"
                              searchValue={searchFields?.routes}
                              onSearch={handleSearchRoute}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  if (e?.target?.value?.trim() === "") {
                                    handleSearchRoute(true);
                                  } else {
                                    setSearchFields((prev) => ({
                                      ...prev,
                                      routes: e?.target?.value?.trim(),
                                    }));
                                  }
                                }
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return routesListing?.find(
                                    (_) => _?._id == value
                                  )?.routeName;
                                } else {
                                  return option?.routeName;
                                }
                              }}
                              allSelected={
                                routesListing &&
                                routesListing?.length === selectedRoute?.length
                              }
                              handleToggleSelectAll={(e) => {
                                if (e?.target?.checked) {
                                  let arr =
                                    routesListing &&
                                    routesListing?.map((item) => ({
                                      id: item?._id,
                                      name: item?.routeName,
                                    }));
                                  onChange([...arr]);
                                  setValue("routes", [...arr]);
                                } else {
                                  onChange([]);
                                  setValue("routes", []);
                                }
                              }}
                              labelKey="routeName"
                              options={
                                routesListing &&
                                routesListing.filter(
                                  (route) =>
                                    !selectedRoute.some(
                                      (selected) => selected?.id === route._id
                                    )
                                )
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </AccordionDetails>

          <Grid container sx={{ mb: 2, pl: 3, pr: 3 }} spacing={1}>
            {selectedRoute
              ?.slice(0, showAllRoute ? routeSize : 5)
              ?.map((st, i) => {
                return (
                  <Grid item sm={3} xs={6} key={i}>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        backgroundColor: themeType.default.themeLightOrange,
                        border: `1px solid ${themeType.default.themeOrange}`,
                        borderRadius: "12px",
                        pl: 1,
                        pr: 1,
                        minHeight: "40px",
                      }}
                    >
                      <Grid item sm={10} xs={8}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                            textAlign: "start",
                          }}
                        >
                          {st?.name?.substring(0, 15) + "..."}
                        </Typography>
                      </Grid>
                      {handleRemoveSelection && (
                        <Grid item sm={2} xs={4}>
                          <IconButton
                            sx={{ alignContent: "right" }}
                            disabled={routeRadio === "All"}
                            onClick={() => handleRemoveSelection(i, "route")}
                          >
                            <Cancel
                              sx={{ color: themeType.default.themeOrange }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            {routeSize > 5 && (
              <>
                <Grid
                  container
                  item
                  sm={4}
                  xs={6}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAllRoute(!showAllRoute)}
                  >
                    {showAllRoute ? <>Collapse</> : <>View All ({routeSize})</>}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default RouteSection;
