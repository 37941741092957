import {FETCH_SETTINGS, FETCH_DYNAMIC_KEYS_FOR_TEMPLATES, ORGANISATION_IMAGES, CLEAR_UPLOADED_IMAGES, CLEAR_SETTINGS} from "../types";

const intialState = {
    settingsData : null,
    dynamicKeysForTemplates : [],
    organisationImages: [],
    
};

export default (state = intialState, { type, payload }) => {
    switch (type) {
        case FETCH_SETTINGS: 
        return { ...state, settingsData : payload }
        
        case FETCH_DYNAMIC_KEYS_FOR_TEMPLATES:
            return { ...state, dynamicKeysForTemplates: payload };
        case ORGANISATION_IMAGES: 
        return {...state, organisationImages: payload}
        case CLEAR_UPLOADED_IMAGES: 
        return { ...state, organisationImages: []}
        case CLEAR_SETTINGS: 
        return { ...state, settingsData: []}

        
        default:
            return state;
    }
}