import { ChevronRight, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  useTheme,
  Select,
  OutlinedInput,
  MenuItem,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import { dateTimePickerSlotProps } from "../../../utils/util";
import { DateTimePicker } from "@mui/x-date-pickers";

import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    maxWidth: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

/**
 *
 * @param {ReactElement} children -- children will be the main section
 * @param {Array} filters -- array of objects
 *
 * @returns  JSX element
 */
const FiltersWrapper = ({
  children,
  filters,
  isDarkThemeEnabledSelector,
  selectStylesOverride,
  themeType,
  open,
  handleDrawerToggle,
  handleApplyFilters,
  searchActions,
  additionalActions,
  searchElement,
  additionalFilter,
  multiSelect,
  reportActions,
  reportModal,
  saveFilters,
  defaultFiltersExists = false,
}) => {
  const { inputClasses, tableClasses, buttonClasses, textClasses } = themeType;

  function callme(dropdown, option) {
    if (dropdown.name === "Organisation" || dropdown.name === "User") {
      // let res =  dropdown?.options?.length > 0  ? dropdown?.options?.find((_) => _[dropdown?.valueKey] === option)[dropdown?.labelKey] || "" : "";

      // console.log('res---',res);
    }
  }

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* {React.Children.map(props.children, child => {
        // Cloning the child element and adding extra props
        return React.cloneElement(child, {
          openFilter: open,
          handleDrawerToggle: handleDrawerToggle,
        });
      })} */}
      <Main open={open}>{children}</Main>

      {/* ---Drawer--- */}
      {open && (
        <Drawer
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              background: tableClasses.dark.backgroundColor,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
          data-testid="filter-drawer"
        >
          <Box
            mt={8}
            width="100%"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              size="medium"
              sx={{ marginRight: "auto" }}
              onClick={handleDrawerToggle}
            >
              <ChevronRight />
            </IconButton>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                marginRight: "25px",
                textAlign: "center",
                ...textClasses.themeColor,
              }}
            >
              Apply Filter
            </Typography>
          </Box>
          <Divider />
          {!reportModal ? searchElement : null}
          <Grid container rowSpacing={2} p={2}>
            {filters?.map((dropdown, index) => {
              return !dropdown.reportModal ? (
                <Grid item xs={12} key={index}>
                  {dropdown?.type === "dateTimePicker" ? (
                    <DateTimePicker
                      // value={filterObj?.time ? filterObj?.time : new Date()}
                      value={dropdown?.value || null}
                      onChange={(date) =>
                        dropdown?.onChange ? dropdown?.onChange(date) : null
                      }
                      className="customDatePicker"
                      label={dropdown?.name}
                      sx={{
                        width: "100%",
                        ...selectStylesOverride,
                      }}
                      slotProps={{
                        ...dateTimePickerSlotProps(
                          inputClasses,
                          isDarkThemeEnabledSelector
                        ),
                        actionBar: { actions: ["clear", "accept"] },
                      }}
                    />
                  ) : dropdown?.type === "multiSelect" ? (
                    <FormControl variant="outlined" size="small" fullWidth>
                      <InputLabel sx={{ fontSize: "13px" }}>Route</InputLabel>
                      <Select
                        sx={{
                          ...inputClasses.filterField,
                          height: "36px",
                          width: "100%",
                          ...selectStylesOverride,
                        }}
                        MenuProps={{ autoFocus: false, sx:{maxHeight: "450px"} }}
                        multiple
                        value={dropdown.value}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (id) =>
                                dropdown.options.find((name) => name._id === id)
                                  ?.routeName
                            )
                            .join(", ")
                        }
                        onChange={(data) =>
                          dropdown?.onChange ? dropdown?.onChange(data) : null
                        }
                        // onClose={() => dropdown?.onClearSearchField()}
                        input={<OutlinedInput label="Route" />}
                      >
                        <ListSubheader>
                          <Box display="flex" alignItems="center" gap="3px" sx={{paddingTop: 0.5}}>
                            <TextField
                              size="small"
                              autoFocus
                              placeholder="Type to search..."
                              fullWidth
                              value={dropdown?.searchValue}
                              onChange={(e) => dropdown.onInputChange(e)}
                              onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  // prevents autoselecting item while typing (default Select behaviour)
                                  e.stopPropagation();
                                }
                              }}
                            />
                            <IconButton size="small" onClick={() => {dropdown?.onSearch()}}>
                              <Search />
                            </IconButton>
                          </Box>
                        </ListSubheader>
                        {dropdown.options.map((name) => (
                          <MenuItem
                            sx={{
                              width: "270px",
                              padding: "0px",
                              overflow: "hidden",
                              border: "1px solid #F8F8F8",
                            }}
                            key={name._id}
                            value={name._id}
                          >
                            <Checkbox
                              size="small"
                              checked={dropdown.value.indexOf(name._id) > -1}
                            />
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  style={{ fontSize: "12px" }}
                                >
                                  {name.routeName}
                                </Typography>
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={selectStylesOverride}
                    >
                      <Autocomplete
                        data-testid={dropdown?.testId ?? ""}
                        size="small"
                        options={dropdown?.options ?? []}
                        disabled={dropdown?.disabled}
                        disableClearable={
                          dropdown?.disableClearable ? true : false
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{ ...inputClasses.filterField }}
                            {...params}
                            label={
                              <Typography
                                sx={
                                  isDarkThemeEnabledSelector
                                    ? {
                                        fontSize: "12px",
                                        color: "#808080",
                                        paddingTop: "1px",
                                      }
                                    : { fontSize: "12px", paddingTop: "1px" }
                                }
                              >
                                {dropdown?.name}
                              </Typography>
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {dropdown?.searchValue &&
                                  dropdown?.searchValue?.length > 0 ? (
                                    <IconButton
                                      size="small"
                                      color=""
                                      onClick={
                                        dropdown?.onSearch && dropdown?.onSearch
                                      }
                                    >
                                      <Search fontSize="12px" />
                                    </IconButton>
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                              disableUnderline: true,
                            }}
                          />
                        )}
                        filterOptions={
                          dropdown?.searchValue ? (option) => option : undefined
                        }
                        renderOption={(props, option) => (
                          <li {...props} style={{ fontSize: "13px" }}>
                            {option[dropdown?.labelKey ?? "label"]}
                          </li>
                        )}
                        clearOnBlur={dropdown?.searchValue && false}
                        onChange={(e, newVal) =>
                          dropdown?.onChange ? dropdown?.onChange(newVal) : null
                        }
                        onInputChange={(e) =>
                          dropdown?.onInputChange
                            ? dropdown?.onInputChange(e)
                            : null
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return dropdown?.options?.length > 0
                              ? dropdown?.options?.find(
                                  (_) => _[dropdown?.valueKey] === option
                                )?.[dropdown?.labelKey] || ""
                              : "";
                          } else {
                            return dropdown?.value
                              ? option?.[dropdown?.labelKey]
                              : "";
                          }
                        }}
                        value={dropdown?.value || null}
                        key={dropdown?.value}
                        defaultValue={null}
                      />
                    </FormControl>
                  )}
                </Grid>
              ) : null;
            })}
          </Grid>
          {additionalActions && additionalActions}
          {multiSelect && multiSelect}
          {additionalFilter && additionalFilter}
          {reportModal && reportActions && reportActions}
          {!reportModal ? (
            <Button
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                margin: 2,
                mt: 0,
                mb: 1
              }}
              onClick={handleApplyFilters}
            >
              Apply Filters
            </Button>
          ) : null}
          {!reportModal && saveFilters && (
            <Button
              // variant="contained"
              sx={{
                minHeight: "36px",
                margin: 2,
                mt: 0,
                mb: 1,
                ml: "auto",
                width: "max-content",
                fontWeight: "600",
                textDecoration: "underline",
                textTransform: "capitalize"
              }}
              onClick={saveFilters}
              data-testid="save-filters"
            >
              {`${defaultFiltersExists ? "Update" : "Save"} Filters`}
            </Button>
          )}
         
        </Drawer>
      )}
    </Box>
  );
};

export default FiltersWrapper;
